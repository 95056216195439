import { gql } from 'graphql.macro';

export const updateDesignerWordMutation = gql`
  mutation UpdateDesignerWord($id: ID, $designer_id: Int, $designer_word: String) {
    updateProductTranslation(id: $id, designer_id: $designer_id, designer_word: $designer_word) {
      id
      designer_word
      designer {
        id
        name
        photoClientUrl
      }
    }
  }
`;
