import React from 'react';
import { Controller, Control, useController } from 'react-hook-form';
import { ApolloError } from '@apollo/client/errors';
import { useTranslation } from 'react-i18next';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Creatable from 'react-select/creatable';
import { GroupBase, OptionsOrGroups } from 'react-select';
import useApolloErrorMessage from 'hooks/useApolloErrorMessage';

type SelectCreatableControlProps = {
  control: Control;
  name: string;
  options: OptionsOrGroups<unknown, GroupBase<unknown>>;
  onCreateOption?: (value: string) => void;
  label?: string;
  placeholder?: string;
  loadingOptions?: boolean;
  apolloError?: ApolloError;
  isInactive?: boolean;
  inactiveMessage?: string;
  isClearable?: boolean;
};

const SelectCreatableControl = ({
  control,
  name,
  options,
  onCreateOption,
  label,
  placeholder,
  loadingOptions,
  apolloError,
  isInactive,
  inactiveMessage,
  isClearable,
}: SelectCreatableControlProps) => {
  const { t } = useTranslation();
  const { field, formState } = useController({ name, control });
  const { onChange, value } = field;
  const errorMessage = useApolloErrorMessage({ apolloError });
  const getInactiveMessage = () => {
    return inactiveMessage ? inactiveMessage : t('global.inactive-option');
  };
  const getPlaceholder = () => {
    return placeholder ? placeholder : t('global.form.select');
  };

  return (
    <div>
      <label className="truncate text-sm font-medium text-dark">{label}</label>
      <div className="relative">
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <Creatable
              {...field}
              className="mt-2 w-full"
              styles={
                formState?.errors[name] || isInactive || apolloError
                  ? errorCustomStyle
                  : customStyle
              }
              value={value}
              onChange={onChange}
              options={options}
              placeholder={loadingOptions ? t('global.form.loading') : getPlaceholder()}
              onCreateOption={onCreateOption}
              noOptionsMessage={() => t('global.form.no-option')}
              formatCreateLabel={(inputText) => {
                return `${t('global.actions.create')} : "${inputText}"`;
              }}
              isClearable={isClearable}
              isDisabled={formState.isLoading || loadingOptions}
              isLoading={loadingOptions}
              theme={theme}
            />
          )}
        />
        {(isInactive || apolloError || formState?.errors[name]) && (
          <div className="pointer-events-none absolute right-12 top-2 flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
        {(isInactive || apolloError) && (
          <p className="mt-2 text-sm text-red-700">
            {apolloError ? errorMessage : getInactiveMessage()}
          </p>
        )}
        {formState?.errors[name] && (
          <p className="mt-2 text-sm text-red-700">{formState?.errors[name]?.message as string}</p>
        )}
      </div>
    </div>
  );
};
export default SelectCreatableControl;
