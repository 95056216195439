import React, { useState } from 'react';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';
import { universesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/universes';
import { ProductUniverse } from 'types/Product';
import { Button } from 'components/Common/Button';
import { DropdownMenu } from 'components/Common/DropdownMenu';
import { Action } from 'types/Global';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Badge from 'components/Common/Badge';
import Skeleton from './Skeleton/Skeleton';
import EditUniverseSlide from './EditUniverseSlide';
import ActivationUniverseModal from './ActivationUniverseModal';

type Props = {
  setSelectedUnivers: React.Dispatch<React.SetStateAction<ProductUniverse | null>>;
};

const Universes = ({ setSelectedUnivers }: Props) => {
  const [openSlide, setOpenSlide] = useState<boolean>(false);
  const [openActivationModal, setOpenActivationModal] = useState<boolean>(false);
  const [editUniverse, setEditUniverse] = useState<ProductUniverse | null>(null);

  const { loading, error, data } = useQuery(universesQuery, {
    fetchPolicy: 'network-only',
  });

  if (loading) return <Skeleton />;
  if (error) return <ApolloErrorAlert error={error} />;

  const universes = data?.productUniverses;

  const selectEditUniverse = (universe: ProductUniverse) => {
    setEditUniverse(universe);
    setOpenSlide(true);
  };

  const openModal = (universe: ProductUniverse) => {
    setEditUniverse(universe);
    setOpenActivationModal(true);
  };

  return (
    <>
      <EditUniverseSlide universe={editUniverse} setOpen={setOpenSlide} open={openSlide} />
      <ActivationUniverseModal
        universe={editUniverse}
        open={openActivationModal}
        setOpen={setOpenActivationModal}
      />
      {universes?.map((universe: ProductUniverse) => {
        const actions: Action[] = [
          {
            text: t('settings.taxonomy.actions.edit'),
            onClick: () => selectEditUniverse(universe),
          },
          {
            text: universe.is_active
              ? t('settings.taxonomy.actions.deactivate')
              : t('settings.taxonomy.actions.activate'),
            onClick: () => {
              openModal(universe);
            },
          },
        ];
        return (
          <div
            key={universe.id}
            className="flex justify-between items-center w-full border-b-2 py-4">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-4">
                <p className="text-dark text-sm font-semibold">
                  {universe.label ? universe.label : '-'}
                </p>
                <span>
                  <Badge type={universe.is_active ? 'success' : 'secondary'}>
                    {universe.is_active ? t('global.active') : t('global.inactive')}
                  </Badge>
                </span>
              </div>
              <p className="text-secondary text-sm">
                {universe.productsCount} {t('settings.taxonomy.universe.products')}
              </p>
            </div>
            <div className="flex items-center space-x-8">
              <Button theme="secondary" onClick={() => setSelectedUnivers(universe)}>
                {t('settings.taxonomy.universe.see-categories')}
              </Button>
              <DropdownMenu actions={actions} />
            </div>
          </div>
        );
      })}
    </>
  );
};
export default Universes;
