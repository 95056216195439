import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { createOrUpdate } from 'graphql/Mutations/MarketingIntegration/createOrUpdate';
import { useMutation } from '@apollo/client';
import { FormProvider, useForm } from 'react-hook-form';
import { productsQuery } from 'graphql/Queries/Products/MarketingIntegration/products';
import { FiltersProps } from './Index';
import useNotification from 'hooks/useNotification';
import { Toggle } from 'components/Common/Toggle';
import SlideOver from 'components/Common/SlideOver';
import { Product } from 'types/Product';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product: Product | null;
  page: number;
  orderBy: string;
  direction: string;
  filters: FiltersProps;
};

const FormSlideOver = ({ open, setOpen, product, page, orderBy, direction, filters }: Props) => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();

  const methods = useForm();
  const {
    handleSubmit,
    reset,
    register,
    setValue,
    formState: { isDirty },
  } = methods;

  const [mutation, { loading, error }] = useMutation(createOrUpdate);

  useEffect(() => {
    reset({
      comment: product?.marketingIntegration?.comment,
      validated_integration: product?.marketingIntegration?.validated_integration ?? false,
      photo_comment: product?.marketingIntegration?.photo_comment,
      validated_photo: product?.marketingIntegration?.validated_photo ?? false,
      photo_edition_comment: product?.marketingIntegration?.photo_edition_comment,
      validated_photo_edition: product?.marketingIntegration?.validated_photo_edition ?? false,
    });
  }, [product, reset]);

  const onSubmit = handleSubmit((data) => {
    if (!product) return;
    mutation({
      variables: {
        product_id: product.id,
        comment: data.comment,
        validated_integration: data.validated_integration,
        photo_comment: data.photo_comment,
        validated_photo: data.validated_photo,
        photo_edition_comment: data.photo_edition_comment,
        validated_photo_edition: data.validated_photo_edition,
      },
      refetchQueries: [
        {
          query: productsQuery,
          variables: {
            page: page ?? 1,
            perPage: 5,
            orderBy,
            direction,
            productName: filters.productName,
            photoValidated: filters.photoValidated,
            photoEditionValidated: filters.photoEditionValidated,
            integrationValidated: filters.integrationValidated,
            arrivalDate: filters.arrivalDate,
            completion: filters.completion,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: (data) => {
        if (data.createOrUpdateProductMarketingIntegration.codeEanChanged) {
          setNotification({
            type: 'success',
            title: t('global.success'),
            message: t('marketing-integration.form.code-ean-assigned'),
          });
        } else {
          setNotification({
            type: 'success',
            title: t('global.success'),
            message: t('marketing-integration.form.success'),
          });
        }
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const resetForm = () => {
    reset();
    setOpen(false);
  };

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={t('products.filters.title')}
        buttonText={t('global.actions.validate')}
        onSubmit={onSubmit}
        onCancel={resetForm}
        loading={loading}
        isDirty={isDirty}
        error={error}>
        <FormProvider {...methods}>
          {product && (
            <div className="space-y-4">
              <div>
                <label className="mt-4 block text-sm font-medium text-gray-700">
                  {t('marketing-integration.form.photo')}
                </label>
                <textarea
                  rows={3}
                  className="my-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 disabled:bg-gray-100 sm:text-sm"
                  {...register('photo_comment')}
                />
              </div>
              <Toggle
                enabled={product.marketingIntegration?.validated_photo}
                label={t('marketing-integration.form.toggles.validation')}
                description={t('marketing-integration.form.toggles.description-1')}
                onChange={(e: boolean) => {
                  setValue('validated_photo', e, { shouldDirty: true });
                }}
              />
              <div className="border-t border-gray-300">
                <label className="mt-4 block text-sm font-medium text-gray-700">
                  {t('marketing-integration.form.photo-edition')}
                </label>
                <textarea
                  rows={3}
                  className="my-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 disabled:bg-gray-100 sm:text-sm"
                  {...register('photo_edition_comment')}
                />
              </div>
              <Toggle
                enabled={product.marketingIntegration?.validated_photo_edition}
                label={t('marketing-integration.form.toggles.validation')}
                description={t('marketing-integration.form.toggles.description-2')}
                onChange={(e: boolean) => {
                  setValue('validated_photo_edition', e, { shouldDirty: true });
                }}
              />
              <div className="border-t border-gray-300">
                <label className="mt-4 block text-sm font-medium text-gray-700">
                  {t('marketing-integration.form.integration')}
                </label>
                <textarea
                  rows={3}
                  className="my-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 disabled:bg-gray-100 sm:text-sm"
                  {...register('comment')}
                />
              </div>
              <Toggle
                enabled={product.marketingIntegration?.validated_integration}
                label={t('marketing-integration.form.toggles.validation')}
                description={t('marketing-integration.form.toggles.description-3')}
                onChange={(e: boolean) => {
                  setValue('validated_integration', e, { shouldDirty: true });
                }}
              />
            </div>
          )}
        </FormProvider>
      </SlideOver>
    </>
  );
};

export default FormSlideOver;
