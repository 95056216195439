import { gql } from 'graphql.macro';

export const supplierColorProductsQuery = gql`
  query supplierColorProducts($supplierColor: String!) {
    lastProductsBySupplierColor(supplier_color: $supplierColor) {
      id
      primaryImage {
        clientUrl
      }
      currentTranslation {
        internal_title
      }
    }
  }
`;
