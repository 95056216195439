import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { FieldValues, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import useNotification from 'hooks/useNotification';
import { productFeaturesQuery } from 'graphql/Queries/Product/Features/productFeatures';
import { updateFeatureMutation } from 'graphql/Mutations/updateFeature';
import { FeatureUnit, ProductFeature } from 'types/Product.js';
import SlideOver from 'components/Common/SlideOver';
import { InputTextControl } from 'components/Common/InputTextControl';
import { OptionProps } from 'types/Global.js';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  feature: ProductFeature | null;
  units: FeatureUnit[];
};

const UpdateFeature = ({ open, setOpen, feature, units }: Props) => {
  const { t } = useTranslation();
  const params = useParams();

  const { setNotification } = useNotification();

  const [options, setOptions] = useState<OptionProps[]>([]);

  const schema = yup.object().shape({
    value: yup.string().trim().required(t('form.required')),
    unit_id: yup.number().integer().typeError(t('form.number.integer')),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  const [updateFeature, { loading, error }] = useMutation(updateFeatureMutation);

  const onSubmit = handleSubmit((data) => {
    if (!feature) return;
    updateFeature({
      refetchQueries: [
        {
          query: productFeaturesQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: feature.id,
        value: data.value,
        unit_id: data.unit_id === 0 ? 4 : data.unit_id,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.features.updated'),
          type: 'success',
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    const values = units.map((unit) => {
      return {
        value: unit.id as string,
        label: unit.text,
      };
    });
    setOptions(values);
  }, [units]);

  useEffect(() => {
    if (!feature) return;
    reset({
      value: feature.value.text,
      unit_id: feature.unit?.id,
    });
  }, [feature, reset]);

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.features.edit')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDirty={isDirty}
      loading={loading}
      error={error}>
      <>
        <div className="space-y-6">
          <span className="text-sm text-dark">
            <Trans
              i18nKey="product.features.edit-subtitle"
              values={{ feature: feature?.label?.text }}>
              <span className="font-semibold" />
            </Trans>
          </span>
          <div>
            <InputTextControl
              control={control}
              name="value"
              label={t('product.features.value')}
              select={{
                name: 'unit_id',
                options,
              }}
            />
          </div>
        </div>
      </>
    </SlideOver>
  );
};
export default UpdateFeature;
