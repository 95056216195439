import { gql } from 'graphql.macro';

export const createAssociatedForwarderMutation = gql`
  mutation CreateAssociatedForwarder(
    $label: String!
    $contact_firstname: String
    $contact_lastname: String
    $contact_email: String
  ) {
    createAssociatedForwarder(
      label: $label
      contact_firstname: $contact_firstname
      contact_lastname: $contact_lastname
      contact_email: $contact_email
    ) {
      id
      label
      contact_firstname
      contact_lastname
      contact_email
      active
    }
  }
`;
