import { gql } from 'graphql.macro';

export const updatePortStatusMutation = gql`
  mutation UpdatePortStatus($id: ID!, $active: Boolean) {
    updatePort(id: $id, active: $active) {
      id
      label
      active
      used
    }
  }
`;
