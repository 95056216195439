import React, { Fragment } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ProductProvider } from 'components/Contexts/ProductContext';
import ProductHeader from './Header/ProductHeader';
import ProductLayoutNavigation from './ProductLayoutNavigation';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const Layout = () => {
  const { t } = useTranslation();

  const navigation = ProductLayoutNavigation();

  return (
    <>
      <ProductProvider>
        <div className="flex h-full flex-row">
          <div className="hidden md:block">
            <nav aria-label="Sections" className="fixed h-full w-80 bg-menu p-10">
              <div className="pb-6 font-semibold uppercase text-secondary">
                {t('product.menu.title')}
              </div>
              {navigation.map((item) => {
                return (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      classNames(
                        isActive ? 'font-semibold' : 'hover:font-semibold',
                        'flex p-1 text-dark'
                      )
                    }>
                    {item.name}
                  </NavLink>
                );
              })}
            </nav>
          </div>
          <div className="w-full md:ml-80">
            <ProductHeader />
            <div className="container mx-auto flex-grow p-6">
              <Outlet />
            </div>
          </div>
        </div>
      </ProductProvider>
    </>
  );
};

export default Layout;
