import React from 'react';
import Colors from './Colors';
import Header from './Header';
import Materials from './Materials';

const Index = () => {
  return (
    <>
      <Header></Header>

      <div className="grid grid-cols-2 gap-4 p-4">
        <Materials />
        <Colors />
      </div>
    </>
  );
};

export default Index;
