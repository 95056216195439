import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { productCustomsNomenclatureByCodeQuery } from 'graphql/Queries/Product/CustomsNomenclature/productCustomsNomenclatureByCode';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { ProductCustomsNomenclature } from 'types/Product';
import { ID } from 'types/Global';
import { t } from 'i18next';
import List from './List';

type Props = {
  defaultCode: string;
  searchCode: string;
  customsNomenclatureId: ID | null;
  setCustomsNomenclatureId: React.Dispatch<React.SetStateAction<ID | null>>;
};

const Hierarchy = ({
  defaultCode,
  searchCode,
  customsNomenclatureId,
  setCustomsNomenclatureId,
}: Props) => {
  const [getCustomsNomenclatureQuery, { data, loading, error }] = useLazyQuery(
    productCustomsNomenclatureByCodeQuery
  );

  const [code, setCode] = useState(defaultCode);
  const [hierarchy, setHierarchy] = useState<ProductCustomsNomenclature[] | null>(null);

  useEffect(() => {
    setCode(searchCode ? searchCode : defaultCode);
  }, [defaultCode, searchCode, setCode]);

  useEffect(() => {
    setHierarchy(null);
    setCustomsNomenclatureId(null);
    getCustomsNomenclatureQuery({
      variables: {
        code,
      },
    });
  }, [code, getCustomsNomenclatureQuery, setCustomsNomenclatureId]);

  useEffect(() => {
    if (!data) return;
    let customsNomenclature: ProductCustomsNomenclature | null = null;
    const customsNomenclatures = data.productCustomsNomenclatureByCode;
    if (customsNomenclatures.length > 1) {
      customsNomenclatures.forEach((value: ProductCustomsNomenclature) => {
        if (value.isSelectable) {
          customsNomenclature = value;
        }
      });
    } else if (customsNomenclatures.length === 1) {
      customsNomenclature = customsNomenclatures[0];
    }
    if (customsNomenclature && customsNomenclature.allParents?.length > 0) {
      setHierarchy([...customsNomenclature.allParents].reverse());
    } else {
      setHierarchy([]);
    }
    if (customsNomenclature?.isSelectable) {
      setCustomsNomenclatureId(customsNomenclature.id);
    }
  }, [data, setHierarchy, setCustomsNomenclatureId]);

  if (error) return <ApolloErrorAlert error={error} />;
  if (loading || !data || !hierarchy) return <div className="skeleton h-96 w-full" />;

  return (
    <>
      {hierarchy.length > 0 ? (
        <div className="max-h-96 space-y-1 overflow-y-auto text-sm font-medium">
          <span className="text-dark">
            {`${t('product.customs-nomenclature.chapter', {
              number: hierarchy[0].code.slice(0, 2),
            })} - ${hierarchy[0].description}`}
          </span>
          <List
            hierarchy={hierarchy}
            elements={hierarchy[0].children}
            currentId={customsNomenclatureId}
          />
        </div>
      ) : (
        <div>
          <InformationAlert>{t('product.customs-nomenclature.not-found')}</InformationAlert>
        </div>
      )}
    </>
  );
};

export default Hierarchy;
