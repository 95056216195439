import React, { ChangeEvent, ComponentPropsWithoutRef, ReactElement, useState } from 'react';
import Creatable, { CreatableProps } from 'react-select/creatable';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import {
  ComponentProps,
  UseAsyncPaginateParams,
  withAsyncPaginate,
} from 'react-select-async-paginate';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { allSupplierColorsQuery } from 'graphql/Queries/Product/MaterialsAndColors/allSupplierColors';
import { Control, Controller, useController } from 'react-hook-form';
import { GroupBase } from 'react-select';

//TODO : Do a common component
type SupplierColorsSearch = {
  allSupplierColors: {
    supplierColors: string[];
    currentPage: number;
    lastPage: number;
  };
};

type Additional =
  | {
      page: number;
    }
  | undefined;

type Props = {
  control: Control;
  name: string;
  loading: boolean;
} & ComponentPropsWithoutRef<'select'>;

type AsyncPaginateCreatableProps<
  OptionProps,
  Group extends GroupBase<OptionProps>,
  Additional,
  IsMulti extends boolean,
> = CreatableProps<OptionProps, IsMulti, Group> &
  UseAsyncPaginateParams<OptionProps, Group, Additional> &
  ComponentProps<OptionProps, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  OptionProps,
  Group extends GroupBase<OptionProps>,
  Additional,
  IsMulti extends boolean = false,
>(
  props: AsyncPaginateCreatableProps<OptionProps, Group, Additional, IsMulti>
) => ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

const limit = 30;

const AsyncCreatablePaginate = ({ control, name, loading, ...props }: Props) => {
  const { field } = useController({ name, control });
  const { onChange, value } = field;
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e);
    props.onChange?.(e);
  };

  const [supplierColors, { loading: loadingSupplierColors }] = useLazyQuery(allSupplierColorsQuery);

  const { t } = useTranslation();
  const filterSupplierColor = (inputValue: string, page: number) => {
    return new Promise<SupplierColorsSearch>((resolve) => {
      supplierColors({
        variables: { keyword: inputValue, page, limit },
        onCompleted: (data) => {
          setErrorMessage('');
          resolve(data);
        },
        onError: (error) => {
          setErrorMessage(error.message);
        },
      });
    });
  };

  const loadPageOptions = async (search: string, _: unknown, additional: Additional) => {
    if (errorMessage || !additional) {
      return {
        options: [],
        hasMore: false,
      };
    }
    const page = additional.page;
    const supplierColorsData = await filterSupplierColor(search, page);
    const supplierColors = supplierColorsData.allSupplierColors.supplierColors.map((color) => {
      return {
        label: color ? color : '-',
        value: color,
      };
    });
    return {
      options: supplierColors,
      hasMore:
        supplierColorsData.allSupplierColors.currentPage !==
        supplierColorsData.allSupplierColors.lastPage,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
      <label className="flex justify-between text-sm font-medium text-dark">
        {t('product.materials-colors.supplier-color')}
      </label>
      <div className="mt-2">
        <div className="relative">
          <Controller
            control={control}
            name={name}
            render={() => (
              <CreatableAsyncPaginate
                styles={!errorMessage ? customStyle : errorCustomStyle}
                theme={theme}
                loadOptions={loadPageOptions}
                placeholder={loading ? t('global.form.loading') : t('global.form.select')}
                noOptionsMessage={() => t('global.form.no-option')}
                loadingMessage={() => t('global.form.loading')}
                formatCreateLabel={(inputText: string) => {
                  return `${t('global.actions.create')} : "${inputText}"`;
                }}
                additional={{
                  page: 1,
                }}
                value={value}
                onChange={onChangeSelect}
                isLoading={loading || loadingSupplierColors}
                isDisabled={loading}
                menuPlacement="auto"
              />
            )}
          />
          {errorMessage && (
            <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            </div>
          )}
        </div>
        {errorMessage && <p className="mt-2 h-[20px] text-sm text-red-600">{errorMessage}</p>}
      </div>
    </>
  );
};
AsyncCreatablePaginate.displayName = 'AsyncCreatablePaginate';

export default AsyncCreatablePaginate;
