import React, { ComponentPropsWithoutRef } from 'react';
import { Control, useController } from 'react-hook-form';

type Props = {
  name: string;
  control: Control;
  label: string;
  iso?: string;
} & ComponentPropsWithoutRef<'input'>;

const CheckboxControl = ({ name, control, label, iso, ...props }: Props) => {
  const { field } = useController({ name, control });
  const { onChange } = field;

  return (
    <div className="flex items-center space-x-3">
      <input
        id={`checkbox_${label}`}
        type="checkbox"
        className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
        onChange={onChange}
        checked={field.value}
        {...props}
      />
      {iso && (
        <div
          className={`flex h-6 w-6 items-center justify-center overflow-hidden rounded-full shadow`}>
          <label
            htmlFor={`checkbox_${label}`}
            className={`fi fi-${iso}`}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              backgroundSize: 'cover',
            }}
          />
        </div>
      )}
      <div className="text-sm leading-6">
        <label htmlFor={`checkbox_${label}`} className="font-medium text-dark">
          {label}
        </label>
      </div>
    </div>
  );
};

export default CheckboxControl;
