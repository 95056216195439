import React, { useEffect, useState } from 'react';
import { GroupBase, SingleValueProps, components } from 'react-select';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { editorialQuery } from 'graphql/Queries/Product/Editorial/editorial';
import { ProductTranslation } from 'types/Product';
import { Language, OptionProps } from 'types/Global';
import { Button } from 'components/Common/Button';
import { useProductContextType } from 'components/Contexts/ProductContext';
import TitleEdit from './TitleEdit';
import AssemblyInstructionOption from './AssemblyInstructionOption';
import Description from './Description';
import MaintenanceDescription from './MaintenanceDescription';
import Url from './Url';
import DesignerWord from './DesignerWord';
import TranslationRequest from './TranslationRequest';
import Select from 'components/Common/Select';
import RoundedFlag from 'components/Common/RoundedFlag';
import Title from 'components/Common/Title';
import ImportSlide from './ImportSlide';

type LanguageOption = {
  id: number;
  name: string;
  iso: string;
} & OptionProps;

type LanguageDisplayProps = {
  iso: string;
  label: string;
};

const Index = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [defautValues, setDefaultValues] = useState<ProductTranslation | null>(null);
  const [translations, setTranslations] = useState<ProductTranslation[]>([]);
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);
  const [language, setLanguage] = useState<LanguageOption | null>(null);
  const [languages, setLanguages] = useState<LanguageOption[]>([]);
  const [selectedTabId, setSelectedTabId] = useState<number>(1);
  const [selectedTabIso, setSelectedTabIso] = useState<string>('fr');
  const [translationRequestDisabled, setTranslationRequestDisabled] = useState(true);
  const [importSlide, setImportSlide] = useState<boolean>(false);
  const [hasVariant, setHasVariant] = useState<boolean>(false);

  const { data, loading, error } = useQuery(editorialQuery, {
    fetchPolicy: 'network-only',
    variables: { id: params.productId },
    onCompleted: (data) => {
      setAvailableLanguages(data.product?.availableLanguages);
    },
  });

  useEffect(() => {
    if (!data?.product) return;
    setTranslations(data.product.translations);
  }, [data, setTranslations]);

  useEffect(() => {
    if (!availableLanguages) return;
    let currentLanguage = null;
    setLanguages(
      availableLanguages.map((language: Language) => {
        const value = {
          id: parseInt(language.id as string),
          name: language.name,
          iso: language.iso,
          value: language.id as string,
          label: language.name,
        };
        if (selectedTabId === parseInt(language.id as string)) {
          currentLanguage = value;
        }
        return value;
      })
    );
    setLanguage(currentLanguage);
    setDefaultValues(
      translations.find((translation: ProductTranslation) => {
        return parseInt(translation.lang_id as string) === selectedTabId;
      }) ?? null
    );
  }, [selectedTabId, availableLanguages, translations]);

  const { productExists } = useProductContextType();

  if (!productExists) return <></>;

  if (error) return <ApolloErrorAlert error={error} />;

  const changeTab = (value: OptionProps | null) => {
    if (!value) return;
    const language = value as LanguageOption;
    setSelectedTabId(language.id);
    setSelectedTabIso(language.iso);
    setLanguage(language);
  };

  const LanguageDisplay = ({ iso, label }: LanguageDisplayProps) => (
    <div className="flex items-center space-x-3">
      <RoundedFlag iso={iso} />
      <span>{label}</span>
    </div>
  );

  const SingleValue: React.ComponentType<
    SingleValueProps<OptionProps, false, GroupBase<OptionProps>>
  > = (e) => {
    const data = e.data as LanguageOption;
    return (
      <components.SingleValue {...e}>
        <LanguageDisplay iso={data.iso} label={data.label} />
      </components.SingleValue>
    );
  };

  return (
    <div className="space-y-6">
      <ImportSlide setOpen={setImportSlide} open={importSlide} setHasVariant={setHasVariant} />
      <Title
        title={t('product.menu.editorial')}
        addOnEnd={
          hasVariant && (
            <Button theme="secondary" onClick={() => setImportSlide(true)}>
              <>
                <ArrowDownOnSquareStackIcon className="mr-3 h-6 w-6" />
                <span className="leading-6">{t('global.import')}</span>
              </>
            </Button>
          )
        }
      />
      <div className="xl:flex xl:items-center xl:justify-between">
        <div className="w-full max-w-xs">
          <Select
            label={t('product.editorial.language-choice')}
            value={language}
            options={languages}
            onChange={changeTab}
            loadingOptions={loading}
            addHTML={(option) => {
              const tabOption = option as LanguageOption;
              return <LanguageDisplay iso={tabOption.iso} label={tabOption.label} />;
            }}
            SingleValue={SingleValue}
          />
        </div>
        <div className="mt-3 xl:mt-0">
          <TranslationRequest disabled={translationRequestDisabled} />
        </div>
      </div>
      <TitleEdit
        title={defautValues?.title ?? null}
        translationId={defautValues?.id ?? null}
        isLoading={loading}
        setTranslationRequestDisabled={setTranslationRequestDisabled}
        langId={selectedTabId}
      />
      <Description
        translationId={defautValues?.id ?? null}
        commercialDescription={defautValues?.commercial_description ?? null}
        isLoading={loading}
        setTranslationRequestDisabled={setTranslationRequestDisabled}
        langId={selectedTabId}
      />
      <DesignerWord
        designerWord={defautValues?.designer_word ?? null}
        designer={defautValues?.designer ?? null}
        translationId={defautValues?.id ?? null}
        isLoading={loading}
        setTranslationRequestDisabled={setTranslationRequestDisabled}
        langId={selectedTabId}
      />
      <MaintenanceDescription
        maintenanceDescription={defautValues?.maintenance_description ?? null}
        translationId={defautValues?.id ?? null}
        isLoading={loading}
        setTranslationRequestDisabled={setTranslationRequestDisabled}
        langId={selectedTabId}
      />
      <AssemblyInstructionOption
        defautValues={data?.product?.furnitureAssemblyOption}
        selectedTabIso={selectedTabIso}
      />
      <Url
        url={defautValues?.url ?? null}
        translationId={defautValues?.id ?? null}
        isLoading={loading}
        fullUrl={defautValues?.urlFullPath ?? null}
        langId={selectedTabId}
      />
    </div>
  );
};

export default Index;
