import { gql } from 'graphql.macro';

export const imageStickerQuery = gql`
  query imageSticker($id: ID!) {
    product(id: $id) {
      primaryImage {
        id
        clientUrl
      }
      secondaryImage {
        id
        clientUrl
      }
    }
  }
`;
