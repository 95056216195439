import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import ReactPaginate from 'react-paginate';
import { useQuery } from '@apollo/client';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import Datepicker from 'react-tailwindcss-datepicker';
import { INVALID_DATES } from 'global';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Progression from 'components/Common/Progression';
import { InformationAlert } from 'components/Common/Alerts';
import ProductInfos from 'components/Common/ProductInfos';
import { uncompletedProductsQuery } from 'graphql/Queries/Home/uncompletedProducts';
import { Product } from 'types/Product';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
// import debounce from 'lodash.debounce';

const Dashboard = () => {
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState('DESC');
  const [orderBy, setOrderBy] = useState('id');

  const [productName, setProductName] = useState('');
  const [creationDate, setCreationDate] = useState<DateValueType | null>({
    startDate: null,
    endDate: null,
  });
  const [startCompletion, setStartCompletion] = useState(0);
  const [endCompletion, setEndCompletion] = useState(100);

  const { loading, data, error } = useQuery(uncompletedProductsQuery, {
    fetchPolicy: 'network-only',
    variables: {
      perPage: 5,
      page,
      direction,
      orderBy,
      productName,
      creationDate,
      completion: {
        start: startCompletion,
        end: endCompletion,
      },
    },
  });

  const { t } = useTranslation();

  const handlePageClick = (data: { selected: number }) => {
    setPage(data.selected + 1);
  };

  const changeDirection = () => {
    if (direction === 'ASC') {
      setDirection('DESC');
    } else if (direction === 'DESC') {
      setDirection('ASC');
    }
  };

  const sortBy = (sort: string) => {
    if (orderBy === sort) {
      changeDirection();
    } else {
      setDirection('ASC');
    }
    setOrderBy(sort);
  };

  // const debounceChangeProductName = useMemo(() => debounce(setProductName, 500), []);

  const onChangeStartCompletion = (value: string) => {
    let intValue = parseInt(value);
    if (intValue > endCompletion) {
      intValue = endCompletion;
    }
    setStartCompletion(intValue);
  };

  const onChangeEndCompletion = (value: string) => {
    let intValue = parseInt(value);
    if (intValue < startCompletion) {
      intValue = startCompletion;
    }
    setEndCompletion(intValue);
  };

  const products = data?.uncompletedProducts.data;
  const paginator = data?.uncompletedProducts.paginatorInfo;
  const start: string = paginator?.firstItem;
  const end: string = paginator?.lastItem;
  const max: string = paginator?.total;

  const creationDateFormat = (date: string) => {
    if (INVALID_DATES.includes(date)) {
      return t('global.invalid-date');
    }
    return moment(date).format('LL');
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mx-auto mb-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-900">
          📈 {t('home.dashboard.title')}
        </h1>
      </div>
      <div className="flex flex-col rounded-md bg-white px-5 pb-5">
        <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="grid grid-cols-3 gap-3">
              <div className="">
                <label className="block text-sm font-medium text-gray-700">
                  {t('home.dashboard.by-name')}
                </label>
                <input
                  type="text"
                  className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  onChange={(e) => setProductName(e.target.value)}
                />
              </div>
              <div className="">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('home.dashboard.by-creation-date')}
                </label>
                <Datepicker
                  value={creationDate}
                  onChange={setCreationDate}
                  showShortcuts={true}
                  primaryColor={'blue'}
                  inputClassName="w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                  placeholder={' '}
                />
              </div>
              <div className="">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  {t('home.dashboard.by-completion')}
                </label>
                <div className="flex items-center">
                  <select
                    className="w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    value={startCompletion}
                    onChange={(e) => onChangeStartCompletion(e.target.value)}>
                    {[...Array(11)].map((x, i) => (
                      <option key={i} value={i * 10} disabled={i * 10 > endCompletion}>
                        {i * 10} %
                      </option>
                    ))}
                  </select>
                  <span className="px-3">{t('global.at')}</span>
                  <select
                    className="w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    value={endCompletion}
                    onChange={(e) => onChangeEndCompletion(e.target.value)}>
                    {[...Array(11)].map((x, i) => (
                      <option key={i} value={i * 10} disabled={i * 10 < startCompletion}>
                        {i * 10} %
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            {error && (
              <div className="mt-4">
                <ApolloErrorAlert error={error} />
              </div>
            )}
            {!products?.length && !loading && !error && (
              <div className="mt-4">
                <InformationAlert>{t('home.dashboard.no-product')}</InformationAlert>
              </div>
            )}
            {(products?.length || loading) && (
              <table className="min-w-full table-fixed divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="w-1/2 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                      {t('home.dashboard.name')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="flex items-center">
                        {t('home.dashboard.completion')}
                        {orderBy !== 'completionPercentage' ? (
                          <ChevronUpIcon
                            className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-gray-900"
                            onClick={() => sortBy('completionPercentage')}
                          />
                        ) : direction === 'ASC' ? (
                          <ChevronUpIcon
                            className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200"
                            onClick={() => sortBy('completionPercentage')}
                          />
                        ) : (
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200"
                            onClick={() => sortBy('completionPercentage')}
                          />
                        )}
                      </div>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                      <div className="flex items-center">
                        {t('home.dashboard.creation-date')}
                        {orderBy !== 'id' ? (
                          <ChevronUpIcon
                            className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-gray-900"
                            onClick={() => sortBy('id')}
                          />
                        ) : direction === 'ASC' ? (
                          <ChevronUpIcon
                            className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200"
                            onClick={() => sortBy('id')}
                          />
                        ) : (
                          <ChevronDownIcon
                            className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-gray-900 group-hover:bg-gray-200"
                            onClick={() => sortBy('id')}
                          />
                        )}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {loading &&
                    [...Array(5)].map((e, i) => (
                      <tr key={i}>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="skeleton h-4 w-full" />
                        </td>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="skeleton h-4 w-full" />
                        </td>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                          <div className="skeleton h-4 w-full" />
                        </td>
                      </tr>
                    ))}
                  {products?.map((product: Product) => (
                    <tr key={product.id}>
                      <td className="group w-1/2 whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                        <ProductInfos product={product} />
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        <div className="flex flex-col">
                          <Progression
                            completionPercentage={product.completionPercentage}
                            missingInformations={product.missingInformations}
                          />
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                        {creationDateFormat(product.created_at)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {paginator && products?.length ? (
          <div className="hidden border-t border-gray-300 pt-2 sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div className="text-sm text-gray-700">
              <Trans i18nKey="supplier.index.search.results-count" values={{ start, end, max }}>
                <span className="font-semibold" />
                <span className="font-semibold" />
                <span className="font-semibold" />
              </Trans>
            </div>
            <ReactPaginate
              onPageChange={(e) => handlePageClick(e)}
              pageRangeDisplayed={2}
              marginPagesDisplayed={1}
              pageCount={paginator.lastPage}
              initialPage={paginator.currentPage - 1}
              renderOnZeroPageCount={null}
              containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
              breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
              breakLabel="..."
              previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
              nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              nextLabel={<ChevronRightIcon className="h-5 w-5" />}
              pageLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
              activeLinkClassName="z-10 bg-blue-600 hover:bg-blue-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
            />
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
