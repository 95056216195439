import React from 'react';
import { useTranslation } from 'react-i18next';
import { Disclosure } from '@headlessui/react';
import MultiSelect from './FormUtils/MultiSelect';
import FieldLegend from './FormUtils/FieldLegend';
import { Control } from 'react-hook-form';

type Props = {
  control: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  colorFilterBucket: any;
};

const ColorFilter = ({ control, colorFilterBucket }: Props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const colorsSelect = colorFilterBucket.map((color: any, index: number) => {
    return {
      key: index,
      label: color.color.hits.hits[0]._source.color.label,
      value: color.key,
      minilabel: color.color.hits.hits[0]._source.color.label,
    };
  });
  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div" className={''}>
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.color.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              <MultiSelect name={'color'} control={control} options={colorsSelect} />
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default ColorFilter;
