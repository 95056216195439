import { gql } from 'graphql.macro';

export const productCustomsNomenclatureQuery = gql`
  query productCustomsNomenclature($id: ID!) {
    product(id: $id) {
      id
      customsNomenclature {
        id
        code
        allParents
        parent_id
      }
    }
  }
`;
