import React, { useContext, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Menu, Transition } from '@headlessui/react';
import { UseAuthToken } from '../../AuthToken';
import { UserContext } from 'App';
import { getInitials } from 'utils/string';
import { useGlobalContextType } from 'components/Contexts/GlobalContext';
import IconCollapse from 'IconCollapse';
import SearchBar from './SearchBar';
import ProductLayoutNavigation from '../Product/ProductLayoutNavigation';
import ResponsiveSecondaryMenu from './ResponsiveSecondaryMenu';
import SupplierLayoutNavigation from 'components/Supplier/SupplierLayoutNavigation';
import SettingsLayoutProductNavigation from 'components/Settings/SettingsLayoutProductNavigation';
import SettingsLayoutSupplierNavigation from 'components/Settings/SettingsLayoutSupplierNavigation';

interface TopBarProps {
  setSlideSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TopBar = ({ setSlideSidebarOpen }: TopBarProps) => {
  const { user, setUser } = useContext(UserContext);
  const { setSidebarOpen, sidebarOpen } = useGlobalContextType();
  const { t } = useTranslation();
  const params = useParams();

  let userJson = null;
  if (user) {
    userJson = JSON.parse(user);
  }

  const productNavigation = ProductLayoutNavigation();
  const supplierNavigation = SupplierLayoutNavigation();
  const settingsProductNavigation = SettingsLayoutProductNavigation();
  const settingsSupplierNavigation = SettingsLayoutSupplierNavigation();

  const userFullName = userJson ? userJson.firstname + ' ' + userJson.lastname : 'x';

  const isSettingsPage = window.location.href.includes('/settings/');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, token, removeAuthToken] = UseAuthToken();
  const logoff = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    removeAuthToken();
    localStorage.removeItem('user');
    localStorage.removeItem('sidebarOpen');
    setUser(null);
  };

  const openSidebar = () => {
    setSidebarOpen(true);
    localStorage.setItem('sidebarOpen', 'true');
    setSidebarOpen(true);
  };

  const openSlideSidebar = () => {
    setSlideSidebarOpen(true);
  };

  return (
    <>
      <div className="sticky top-0 z-20">
        <div className=" top-0 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <div
            className={`animate-easing-emphasized-decelerate hidden transition-all duration-700 xl:block ${
              sidebarOpen ? 'w-0' : 'w-20'
            }`}>
            <button
              className={`flex items-center transition-all ease-in-out ${
                sidebarOpen
                  ? 'opacity-0 delay-0 duration-200'
                  : 'opacity-100 delay-500 duration-700'
              }`}
              onClick={openSidebar}>
              <IconCollapse isCollapsed={true} />
            </button>
          </div>
          <div className="animate-easing-emphasized-decelerate flex items-center space-x-4 transition-all duration-700 xl:hidden">
            <button
              className="flex items-center transition-all ease-in-out"
              onClick={openSlideSidebar}>
              <IconCollapse isCollapsed={true} />
            </button>
            {params.productId && (
              <ResponsiveSecondaryMenu
                navigation={productNavigation}
                title={t('product.menu.title')}
              />
            )}
            {params.supplierId && (
              <ResponsiveSecondaryMenu
                navigation={supplierNavigation}
                title={t('supplier.menu.title')}
              />
            )}
            {isSettingsPage && (
              <ResponsiveSecondaryMenu
                navigation={settingsProductNavigation}
                otherNavigation={settingsSupplierNavigation}
                title={t('settings.menu.products.title')}
                type="settings"
              />
            )}
          </div>
          <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />
          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            <SearchBar />
            <div className="group flex items-center gap-x-4 lg:gap-x-6">
              <div
                className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10"
                aria-hidden="true"
              />
              <Menu as="div" className="relative">
                <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">{t('top-bar.sr-only.open-user-menu')}</span>

                  <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-primary-500 uppercase text-white">
                    {getInitials(userFullName)}
                  </span>

                  <span className="hidden lg:flex lg:items-center">
                    <span
                      className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                      aria-hidden="true">
                      {userJson && (
                        <div>
                          {userJson.firstname} {userJson.lastname}
                        </div>
                      )}
                    </span>
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 text-secondary group-hover:text-dark"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95">
                  <Menu.Items className="absolute right-0 mt-3 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                    <Menu.Item>
                      <Link
                        onClick={logoff}
                        className="block px-4 py-2 text-sm text-dark hover:bg-gray-100 hover:text-gray-900"
                        to={'/'}>
                        {t('global.logout')}
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopBar;
