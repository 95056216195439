import React from 'react';
import { Product } from 'types/Product';
import ProductCard from '../Card';

type Props = {
  product: Product;
};

const ConstituentList = ({ product }: Props) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
        {product?.is_combination &&
          product.constituents.map((combination, i) => (
            <ProductCard
              key={i}
              product={combination.child}
              combinationId={combination.id}
              quantity={combination.child_product_qty}
              isModifiable={true}
            />
          ))}
        {product?.isCombined &&
          product.parent.map((combination, i) => (
            <ProductCard
              key={i}
              product={combination.parent}
              combinationId={combination.id}
              quantity={combination.child_product_qty}
            />
          ))}
      </div>
    </div>
  );
};

export default ConstituentList;
