import React, { ReactElement } from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/20/solid';

type Props = {
  open: boolean;
  children: ReactElement | string;
};

const FieldLegend = ({ open, children }: Props) => {
  return (
    <legend className="block text-sm font-medium text-gray-900">
      <Disclosure.Button className="flex w-full items-center justify-between py-2 text-base uppercase text-gray-700 hover:text-gray-900">
        {children}
        <span className="ml-6 flex items-center">
          {open ? <MinusSmallIcon className="w-5" /> : <PlusSmallIcon className="w-5" />}
        </span>
      </Disclosure.Button>
    </legend>
  );
};

export default FieldLegend;
