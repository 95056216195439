import { gql } from 'graphql.macro';

export const supplierHeaderQuery = gql`
  query SupplierHeader($id: ID!) {
    supplier(id: $id) {
      id
      active
      name
      creator {
        firstname
        lastname
      }
      created_at
    }
  }
`;
