import { gql } from 'graphql.macro';

export const allActiveProductMadeInsQuery = gql`
  query allActiveProductMadeIns {
    allActiveProductMadeIns {
      id
      label
      logoFullPath
    }
  }
`;
