import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { createSupplierMutation } from 'graphql/Mutations/Supplier/createSupplier';
import { FieldValues, useForm } from 'react-hook-form';
import { InputTextControl } from 'components/Common/InputTextControl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const CreateSupplier = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const inputRef = useRef(null);
  const navigate = useNavigate();

  const [createSupplier, { loading: createLoading, error }] = useMutation(createSupplierMutation);

  const schema = yup.object().shape({
    billingName: yup.string().trim().min(1, t('form.required')),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      billingName: '',
    },
  });

  const clearInput = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    createSupplier({
      variables: {
        billing_name: data.billingName,
      },
      onCompleted: (data) => {
        setNotification({
          type: 'success',
          title: t('global.success'),
          message: t('suppliers.slide-over.success'),
        });
        navigate(`/suppliers/${data.createSupplier.id}/general-informations`);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('suppliers.slide-over.title')}
      buttonText={t('global.actions.create')}
      onSubmit={onSubmit}
      onCancel={clearInput}
      error={error}
      loading={createLoading}
      isDirty={isDirty}>
      <div className="pt-6">
        <InputTextControl
          control={control}
          label={t('suppliers.form.label')}
          name={'billingName'}
          ref={inputRef}
          isRequired
          isFocused
        />
      </div>
    </SlideOver>
  );
};
export default CreateSupplier;
