import { gql } from 'graphql.macro';

export const classificationQuery = gql`
  query classification($id: ID!) {
    product(id: $id) {
      id
      taxonomy {
        collection {
          id
          label
          active
        }
        style {
          id
          label
          active
        }
      }
      family {
        id
        currentLabelTranslation {
          text
        }
        active
      }
      model {
        id
        name
        active
      }
    }
  }
`;
