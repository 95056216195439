import { gql } from 'graphql.macro';

export const updateActiveCategoryMutation = gql`
  mutation updateActiveCategory($id: ID!, $is_active: Boolean!) {
    updateActiveCategory(id: $id, is_active: $is_active) {
      id
      is_active
    }
  }
`;
