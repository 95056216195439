import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';
import { allSupplierContactsQuery } from 'graphql/Queries/Supplier/Contacts/allSupplierContacts';
import { useSupplierContextType } from 'components/Contexts/SupplierContext';
import { Button } from 'components/Common/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import Title from 'components/Common/Title';
import AllContacts from './AllContacts';
import AddContact from './AddContact';

const Index = () => {
  const { supplierExists } = useSupplierContextType();
  const params = useParams();

  const [openSlide, setOpenSlide] = useState<boolean>(false);

  const { data, loading } = useQuery(allSupplierContactsQuery, {
    variables: { supplier_id: params.supplierId },
    fetchPolicy: 'network-only',
  });

  if (!supplierExists) return <></>;

  if (loading)
    return (
      <>
        <Title title={t('supplier.contacts.title')} />
        <div className="flex h-96 flex-col items-center justify-center space-y-6">
          <div className="skeleton h-6 w-72" />
          <div className="skeleton h-10 w-36" />
        </div>
      </>
    );

  let supplierContacts = [];

  if (data?.allSupplierContacts) {
    supplierContacts = [...data.allSupplierContacts].sort((a, b) => {
      if (a.is_principal !== undefined && b.is_principal !== undefined) {
        return b.is_principal - a.is_principal;
      }
      return 0;
    });
  }

  const hasContact = supplierContacts.length > 0;

  return (
    <div>
      <AddContact setOpen={setOpenSlide} open={openSlide} />

      <Title
        title={t('supplier.contacts.title')}
        addOnEnd={
          hasContact ? (
            <Button type="button" onClick={() => setOpenSlide(true)}>
              <>
                <PlusIcon className="mr-2 h-5 text-white" />
                {t('supplier.contacts.add-contact')}
              </>
            </Button>
          ) : null
        }
      />
      {supplierContacts.length > 0 ? (
        <AllContacts supplierContacts={supplierContacts} />
      ) : (
        <div className="flex h-96 flex-col items-center justify-center space-y-6 text-dark">
          <div>{t('product.purchase-information.informations.no-supplier')}</div>
          <Button type="button" onClick={() => setOpenSlide(true)}>
            <>
              <PlusIcon className="mr-2 h-5 text-white" />
              {t('supplier.contacts.add-contact')}
            </>
          </Button>
        </div>
      )}
    </div>
  );
};
export default Index;
