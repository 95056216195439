import { gql } from 'graphql.macro';

export const importEditorialInformationsMutation = gql`
  mutation ImportEditorialInformations(
    $id: ID!
    $from_product_id: ID!
    $editorial_import_element: Mixed!
  ) {
    importEditorialInformations(
      id: $id
      from_product_id: $from_product_id
      editorial_import_element: $editorial_import_element
    ) {
      id
    }
  }
`;
