import React from 'react';
import Card from 'components/Common/Card';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { historyPurchasePriceQuery } from 'graphql/Queries/Product/Supplier/historyPurchasePrice';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import Infos from './Infos';
import Skeleton from './Skeleton';

const Index = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { data, loading, error } = useQuery(historyPurchasePriceQuery, {
    variables: { id: params.productId },
  });

  const currentSupplying = data?.product?.currentSupplying;

  return (
    <Card title={t('product.purchase-information.history-card.title')}>
      <div>
        {error ? (
          <>
            <ApolloErrorAlert error={error}></ApolloErrorAlert>
          </>
        ) : (
          <table className="min-w-full  divide-y">
            <thead className="divide-y">
              <tr className="text-xs font-semibold text-secondary">
                <th scope="col" className="overflow-hidden whitespace-nowrap px-2 py-4 text-left">
                  {t('product.purchase-information.history-card.user')}
                </th>
                <th
                  scope="col"
                  className="hidden overflow-hidden whitespace-nowrap px-2 text-left 2xl:table-cell">
                  {t('product.purchase-information.history-card.supplier')}
                </th>
                <th
                  scope="col"
                  className="hidden overflow-hidden whitespace-nowrap px-2 text-left 2xl:table-cell">
                  {t('product.purchase-information.history-card.supplier_product_id')}
                </th>
                <th scope="col" className="overflow-hidden whitespace-nowrap px-2 text-left">
                  {t('product.purchase-information.history-card.amount')}
                </th>
                <th
                  scope="col"
                  className="text-lef overflow-hiddent whitespace-nowrap px-2 text-left">
                  {t('product.purchase-information.history-card.reason')}
                </th>
                <th
                  scope="col"
                  className="hidden overflow-hidden whitespace-nowrap px-2 text-right 2xl:table-cell">
                  {t('product.purchase-information.history-card.modified-at')}
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <Skeleton />
              ) : (
                currentSupplying && (
                  <Infos productSupplierHistory={currentSupplying?.productSupplierHistory} />
                )
              )}
            </tbody>
          </table>
        )}
        {!currentSupplying || currentSupplying.productSupplierHistory?.length < 1 ? (
          <div className="mt-6">
            {!loading && (
              <InformationAlert>
                {t('product.purchase-information.history-card.no-history')}
              </InformationAlert>
            )}
          </div>
        ) : null}
      </div>
    </Card>
  );
};

export default Index;
