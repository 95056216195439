import { gql } from 'graphql.macro';

export const updateCollectionMutation = gql`
  mutation UpdateCollection($id: ID!, $label: String) {
    updateProductCollection(id: $id, label: $label) {
      id
      label
      active
      isUsed
    }
  }
`;
