import { gql } from 'graphql.macro';

export const portsQuery = gql`
  query Ports {
    ports {
      id
      label
      active
      used
    }
  }
`;
