import React from 'react';
import { Link } from 'react-router-dom';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { ElasticSearchProduct } from 'types/ElasticSearch';
import useCheckUrlImage from './Hooks/useCheckUrlImage';
import Tippy from '@tippyjs/react';
import { useTranslation } from 'react-i18next';

type Props = {
  product: ElasticSearchProduct;
};

const Card = ({ product }: Props) => {
  const isValidImage = useCheckUrlImage(product._source.preview_image);
  const { t } = useTranslation();

  return (
    <div className=" flex flex-col space-y-4 rounded border border-gray-200 bg-white">
      <div className={'flex h-10 justify-end px-2 pt-1'}>
        {product?._source?.made_in?.label && (
          <div className="flex w-full">
            <img src={product?._source?.made_in?.logo} />
          </div>
        )}

        {product._source.is_novelty && (
          <span className="flex items-center text-sm uppercase">{t('products.new')}</span>
        )}
      </div>

      <Link to={`/products/${product._source.product_id}/`} className="space-y-2">
        {/* Image & discount */}
        <div className="h-48 ">
          <div className="relative mb-2 h-full overflow-hidden">
            {isValidImage ? (
              <div className="flex h-full justify-center ">
                <img src={product._source.preview_image} className="h-full " />
              </div>
            ) : (
              <PhotoIcon className="h-40 w-full text-secondary " />
            )}
            {product._source.percent_discount > 0 && (
              <span className="absolute bottom-0 left-0 inline-flex items-center bg-gray-900 px-2.5 py-0.5 text-sm font-medium text-gray-200">
                -{product._source.percent_discount}%
              </span>
            )}
          </div>
        </div>

        {/* Title & Price */}
        <div className="flex flex-col justify-end space-y-3 px-2 pb-2">
          <Tippy content={product._source.internal_title}>
            <p className="truncate text-base font-light">{product._source.internal_title}</p>
          </Tippy>

          <div className="space-x-3 ">
            <span className="text-lg font-semibold">{product._source.price} €</span>
            {product._source.highest_price > product._source.price && (
              <span className="font-light text-gray-500 line-through">
                {product._source.highest_price} €
              </span>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};

export default Card;
