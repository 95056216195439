import React, { createContext, useContext, ReactNode, useState } from 'react';

interface SupplierContextType {
  supplierExists: boolean;
  setSupplierExists: React.Dispatch<React.SetStateAction<boolean>>;
}

interface SupplierProviderProps {
  children: ReactNode;
}

const SupplierContext = createContext<SupplierContextType | null>(null);

export function useSupplierContextType() {
  const context = useContext(SupplierContext);
  if (!context) {
    throw new Error('useSupplierContextType must be used within a SupplierProvider');
  }
  return context;
}

export function SupplierProvider({ children }: SupplierProviderProps) {
  const [supplierExists, setSupplierExists] = useState(true);

  return (
    <SupplierContext.Provider value={{ supplierExists, setSupplierExists }}>
      {children}
    </SupplierContext.Provider>
  );
}
