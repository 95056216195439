import { useTranslation } from 'react-i18next';

const HelperTextTitle = () => {
  const { t } = useTranslation();
  return (
    <ul role="list" className="list-disc space-y-1 pl-5">
      <li>{t('product.taxonomy.helpers.title-helper1')}</li>
      <li>{t('product.taxonomy.helpers.title-helper2')}</li>
    </ul>
  );
};

export default HelperTextTitle;
