import React, { ReactElement } from 'react';

type BadgeProps = {
  type:
    | 'primary'
    | 'secondary'
    | 'info'
    | 'success'
    | 'warning'
    | 'danger'
    | 'sales'
    | 'promotion'
    | 'rewarded'
    | 'outline';
  children: string | ReactElement;
  withDote?: boolean;
};

const Badge = ({ type, children, withDote = false }: BadgeProps) => {
  const contentClasses = {
    primary: 'bg-primary-500 text-white',
    secondary: 'bg-gray-100 text-gray-600',
    info: 'bg-blue-100 text-blue-700',
    success: 'bg-green-100 text-green-700',
    warning: 'bg-yellow-100 text-yellow-800',
    danger: 'bg-red-100 text-red-700',
    outline: 'bg-white border border-dark text-dark',
    sales: 'bg-[#ffd54c] text-dark',
    promotion: 'bg-[#90c2dd] text-dark',
    rewarded: 'bg-[#aadd90] text-dark',
  };

  const dotClass = {
    primary: 'fill-white',
    secondary: 'fill-gray-400',
    info: 'fill-blue-500',
    success: 'fill-green-500',
    warning: 'fill-yellow-500',
    danger: 'fill-red-500',
    outline: 'fill-dark',
    sales: 'fill-dark',
    promotion: 'fill-dark',
    rewarded: 'fill-dark',
  };

  const classes = `inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium ${contentClasses[type]}`;

  return (
    <span className={classes}>
      {withDote && (
        <svg className={`h-1.5 w-1.5 ${dotClass[type]}`} viewBox="0 0 6 6">
          <circle cx={3} cy={3} r={3} />
        </svg>
      )}
      {children}
    </span>
  );
};

export default Badge;
