import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, NavLink } from 'react-router-dom';
import { SupplierProvider } from 'components/Contexts/SupplierContext';
import Header from './Header/Index';
import SupplierLayoutNavigation from './SupplierLayoutNavigation';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const SupplierLayout = () => {
  const navigation = SupplierLayoutNavigation();
  const { t } = useTranslation();

  return (
    <SupplierProvider>
      <div className="flex h-full flex-row">
        <div className="hidden md:block">
          <nav aria-label="Sections" className="fixed h-full w-80 bg-menu p-10">
            <div className="pb-6 font-semibold uppercase text-secondary">
              {t('supplier.menu.title')}
            </div>
            {navigation.map((item) => {
              return (
                <NavLink
                  key={item.name}
                  to={item.href}
                  className={({ isActive }) =>
                    classNames(
                      isActive ? 'font-semibold' : 'hover:font-semibold',
                      'flex p-1 text-dark'
                    )
                  }>
                  {item.name}
                </NavLink>
              );
            })}
          </nav>
        </div>
        <div className="w-full md:ml-80">
          <Header />
          <div className="container mx-auto flex-grow p-6">
            <Outlet />
          </div>
        </div>
      </div>
    </SupplierProvider>
  );
};

export default SupplierLayout;
