import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { allImagesQuery } from 'graphql/Queries/Product/Images/Types/allImages';
import { updatePositionsMutation } from 'graphql/Mutations/Product/Images/Organizer/updatePositions';
import { Image } from 'types/Product';
import SortableItem from './SortableItem';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';

type PositionsType = { [key: string]: number };
type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  images?: Image[];
};

const OrganisingImage = ({ open, setOpen, images }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [updatePositions, { loading, error }] = useMutation(updatePositionsMutation);

  const [items, setItems] = useState<Image[]>([]);
  const [positions, setPositions] = useState<PositionsType | null>(null);

  const { handleSubmit } = useForm();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  useEffect(() => {
    if (!images) return;
    setItems(images);
  }, [setItems, images]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((currentItems: Image[]) => {
        const oldIndex = currentItems.findIndex((item) => item.id === active.id);
        const newIndex = currentItems.findIndex((item) => item.id === over.id);
        const orderedImages = arrayMove(currentItems, oldIndex, newIndex);
        const positions = getPositions(orderedImages);
        setPositions(positions);

        return orderedImages;
      });
    }
  };

  const getPositions = (organizedImages: Image[]) => {
    const formattedPositions: { [key: string]: number } = {};
    let position = 1;

    organizedImages.map((image) => {
      formattedPositions[image.id] = position;
      position++;
    });

    return formattedPositions;
  };

  const close = () => {
    setOpen(false);
    setPositions(null);
  };

  const onSubmit = handleSubmit(() => {
    updatePositions({
      variables: {
        product_id: params.productId,
        positions: positions,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: allImagesQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.images.notif.success-image-order-update'),
          type: 'success',
        });

        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        size={'max-w-xl'}
        title={t('product.images.organizer.title')}
        buttonText={t('global.actions.save')}
        onCancel={close}
        onSubmit={onSubmit}
        loading={loading}
        isDirty={positions != null}
        error={error}>
        <div className="mt-6 grid w-full grid-cols-3 gap-10 text-sm text-dark">
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}>
            <SortableContext items={items} strategy={rectSortingStrategy}>
              {items.map((image: Image, index: number) => (
                <div key={image.id} className="max-h-26 w-36 rounded-md bg-gray-100">
                  <SortableItem image={image} index={index} />
                </div>
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </SlideOver>
    </>
  );
};
export default OrganisingImage;
