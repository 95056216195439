import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { ImportPackagingQuery } from 'graphql/Queries/Product/Packaging/importPackaging';
import { createImportPackageMutation } from 'graphql/Mutations/Product/Packaging/createImportPackage';
import useNotification from 'hooks/useNotification';
import { FieldValues, useForm } from 'react-hook-form';
import SlideOver from 'components/Common/SlideOver';
import FormContent from './FormContent';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { CURRENCY_EURO_ID } from 'global';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreatePackage = ({ open, setOpen }: Props) => {
  const params = useParams();
  const { t } = useTranslation();
  const { setNotification } = useNotification();

  const [createProductImportPackage, { loading, error }] = useMutation(createImportPackageMutation);

  const schema = yup.object().shape({
    name: yup.string().required(t('form.required')),
    reference: yup.string().required(t('form.required')),
    weight: yup
      .number()
      .integer(t('form.number.integer'))
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr)),
    volume: yup
      .number()
      .min(0.1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr)),
    moq: yup
      .number()
      .integer(t('form.number.integer'))
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr)),
    price: yup
      .number()
      .typeError(t('form.number.positive'))
      .min(0, t('form.number.positive'))
      .test({
        name: 'price',
        test: function (value) {
          if (dirtyFields.currency_id && (value === undefined || value === null)) {
            throw this.createError({
              message: t('form.number.positive'),
            });
          }

          return true;
        },
      })
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    currency_id: yup.string().test({
      name: 'currency_id',
      test: function (value) {
        if (value === '0') {
          return false;
        }
        if (value === '0' && getValues('price')) {
          return false;
        }
        return true;
      },
      message: t('form.must-choose-currency'),
    }),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { isDirty, dirtyFields },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      name: '',
      weight: '',
      volume: '',
      reference: '',
      moq: '',
      price: '',
      currency_id: CURRENCY_EURO_ID,
    },
  });

  const onSubmit = handleSubmit((data) => {
    let priceCurrencyId = null;
    if (data.price) {
      priceCurrencyId = data.currency_id;
    }

    createProductImportPackage({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ImportPackagingQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        product_id: params.productId,
        weight: data.weight,
        volume: data.volume * 1000 * 1000,
        label: data.name,
        label_for_supplier: data.reference,
        moq: data.moq,
        price: data.price,
        price_currency_id: priceCurrencyId,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.import.success'),
          type: 'success',
        });
        setOpen(false);
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    reset();
    setOpen(false);
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.packaging.import.add-title')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDirty={isDirty}
      loading={loading}
      error={error}>
      <FormContent control={control} />
    </SlideOver>
  );
};

export default CreatePackage;
