import React from 'react';
import { useTranslation } from 'react-i18next';
import { VisibilityInformation } from 'types/Product';
import { ApolloError } from '@apollo/client';
import Tippy from '@tippyjs/react';
import RoundedFlag from 'components/Common/RoundedFlag';
import Badge from 'components/Common/Badge';
import VisibilityRadioButton from './VisibilityRadioButton';

type Props = {
  infos: VisibilityInformation;
  setUpdateError: React.Dispatch<React.SetStateAction<ApolloError | null>>;
};

const IS_VISIBLE = 'Visible';

const TableContent = ({ infos, setUpdateError }: Props) => {
  const { t } = useTranslation();

  let hasInvisibleBadgeDisplayed = false;

  const reasons = (statusArray: string[]) => {
    if (statusArray.length === 0 || statusArray[0] === IS_VISIBLE) {
      return '-';
    }

    return statusArray.join(', ');
  };

  return (
    <tr className="border-b">
      <td className="w-fit p-3 text-sm">
        <div className="flex items-center space-x-4 font-medium text-dark">
          <>
            <RoundedFlag iso={infos.iso} />
            <label>{infos.countryName}</label>
          </>
        </div>
      </td>
      <td className=" w-fit  p-3 pr-12 text-sm">
        <div className="min-w-[400px]">
          <VisibilityRadioButton
            currentCountryName={infos.countryName}
            visibility={infos}
            setUpdateError={setUpdateError}
          />
        </div>
      </td>
      <td className="hidden w-fit p-3 text-sm 2xl:table-cell">
        <div>
          {infos?.status.map((info, i) => {
            let badge = null;
            if (info === IS_VISIBLE) {
              badge = <Badge type="success">{t('product.visibility.status.visible')}</Badge>;
            } else if (info !== IS_VISIBLE && !hasInvisibleBadgeDisplayed) {
              hasInvisibleBadgeDisplayed = true;
              badge = <Badge type="secondary">{t('product.visibility.status.invisible')}</Badge>;
            }
            return (
              <div key={i}>
                <div>{badge}</div>
              </div>
            );
          })}
        </div>
      </td>
      <td className="hidden p-3 text-sm 2xl:table-cell">
        <Tippy content={infos?.status.join(', ')}>
          <div className="flex w-fit max-w-[200px]  cursor-default flex-row space-x-2 overflow-hidden ">
            <span className="truncate">{reasons(infos?.status)}</span>
          </div>
        </Tippy>
      </td>
    </tr>
  );
};
export default TableContent;
