import { gql } from 'graphql.macro';

export const productModelHistoryQuery = gql`
  query ProductModelHistory($id: ID!) {
    productModel(id: $id) {
      id
      productModelHistory {
        id
        value
        field
        created_at
        user {
          id
          firstname
          lastname
        }
      }
    }
  }
`;
