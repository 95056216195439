import { gql } from 'graphql.macro';

export const productTagsQuery = gql`
  query ProductTags($id: ID!) {
    product(id: $id) {
      id
      isNovelty
      active
      to_destock
      supply_exception
      force_on_marketplaces
      in_shop_only
    }
  }
`;
