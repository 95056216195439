import React from 'react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PlusIcon } from '@heroicons/react/20/solid';
import { Button } from 'components/Common/Button';
import { WarningAlert } from 'components/Common/Alerts';
import { noticesQuery } from 'graphql/Queries/Product/Documents/Notices/notices';
import { ApolloError, useMutation } from '@apollo/client';
import { deleteProductNoticeMutation } from 'graphql/Mutations/Product/Documents/Notices/deleteProductNotice';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { Document } from 'types/Product';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useForm, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import Skeleton from '../Skeleton';
import AddNoticeModal from './AddNoticeModal';
import Notice from './Notice';
import Modal from 'components/Common/Modal';
import AddCommentForm from './AddCommentForm';

type Props = {
  notices: Document[];
  loading: boolean;
  error: ApolloError | undefined;
};

function Notices({ notices, loading, error }: Props) {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const params = useParams();

  const [openModal, setOpenModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [targetNotice, setTagetNotice] = useState<Document | null>(null);
  const [openSideComment, setOpenSideComment] = useState<boolean>(false);

  const sortedNotices = [...notices].sort((a, b) => b.version - a.version);
  const { handleSubmit } = useForm<FieldValues>({});

  const [removeNotice, { loading: deleteLoading, error: deleteError, reset }] = useMutation(
    deleteProductNoticeMutation
  );

  const handlePressDelete = (notice: Document) => {
    setOpenDeleteModal(true);
    setTagetNotice(notice);
  };

  const handlePressComment = (notice: Document) => {
    setTagetNotice(notice);
  };

  const onCloseConfirmModal = () => {
    setOpenModal(false);
    setOpenDeleteModal(false);
    reset();
  };
  const onSubmit = handleSubmit(() => {
    removeNotice({
      variables: {
        id: targetNotice?.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: noticesQuery,
          variables: {
            product_id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.documents.notices.remove-notice'),
          type: 'success',
        });
        onCloseConfirmModal();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <AddNoticeModal openModal={openModal} onClose={onCloseConfirmModal} />
      {/* Delete modal */}
      <Modal
        theme={'danger'}
        title={t('product.documents.delete')}
        buttonText={t('global.actions.delete')}
        loading={deleteLoading}
        open={openDeleteModal}
        onClose={onCloseConfirmModal}
        error={deleteError}
        onSubmit={onSubmit}
        icon={<TrashIcon className="h-6 w-6" />}>
        <div className="mt-6 flex flex-col space-y-6">
          <Trans
            i18nKey={'product.documents.notices.delete-warning'}
            values={{
              name: targetNotice?.name,
            }}
          />
          <div className="font-medium text-red-600">
            {t('product.documents.notices.delete-confim')}
          </div>
        </div>
      </Modal>
      <>
        {/* Add Notice */}
        <div className="mb-6 sm:flex sm:items-center">
          <div className="flex w-full justify-end">
            <Button type="button" onClick={() => setOpenModal(true)}>
              <div className="flex">
                <PlusIcon className="mr-2 w-5" />
                {t('product.documents.notices.add')}
              </div>
            </Button>
          </div>
        </div>
        {/* Add comment  */}
        <AddCommentForm
          setOpen={setOpenSideComment}
          open={openSideComment}
          document={targetNotice}
        />
        {/* Tableau */}
        <table className="mt-12 min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="w-72 p-3 text-left text-sm font-semibold text-secondary 2xl:table-cell">
                {t('product.documents.filename').toUpperCase()}
              </th>
              <th
                scope="col"
                className="hidden w-28 px-3 py-3.5 text-center text-sm font-semibold text-secondary lg:table-cell">
                {t('product.documents.version').toUpperCase()}
              </th>
              <th
                scope="col"
                className="hidden w-32 p-3 text-left text-sm font-semibold text-secondary 2xl:table-cell">
                {t('product.documents.date').toUpperCase()}
              </th>
              <th
                scope="col"
                className="hidden w-40 p-3 text-left text-sm font-semibold text-secondary 2xl:table-cell">
                {t('product.documents.notices.user').toUpperCase()}
              </th>
              <th
                scope="col"
                className="hidden w-72 p-3 text-left text-sm font-semibold text-secondary xl:table-cell">
                {t('product.documents.notices.comment').toUpperCase()}
              </th>
              <th scope="col" className="relative pl-3 pr-4 sm:pr-0"></th>
            </tr>
          </thead>
          <tbody className="w-full bg-white ">
            {loading ? (
              <Skeleton />
            ) : (
              <>
                {sortedNotices.map((notice) => (
                  <Notice
                    notice={notice}
                    key={notice.id}
                    onPressDelete={() => handlePressDelete(notice)}
                    onPressComment={() => handlePressComment(notice)}
                    setOpenDeleteModal={setOpenDeleteModal}
                    setOpenSideComment={setOpenSideComment}
                  />
                ))}
              </>
            )}
          </tbody>
        </table>
        {!notices.length && !loading && !error && (
          <div className="bg-white">
            <WarningAlert>{t('product.documents.notices.no-notice')}</WarningAlert>
          </div>
        )}
      </>
    </>
  );
}

export default Notices;
