import { gql } from 'graphql.macro';

export const createCollectionMutation = gql`
  mutation CreateCollection($label: String!) {
    createProductCollection(label: $label) {
      id
      label
      active
      isUsed
    }
  }
`;
