import React from 'react';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { productsTypesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productsTypes';
import { updateActiveTypeMutation } from 'graphql/Mutations/Settings/Products/Taxonomy/updateActiveType';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { ProductType } from 'types/Product';
import Modal from 'components/Common/Modal';
import useNotification from 'hooks/useNotification';

type Props = {
  type: ProductType | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ActivationTypeModal = ({ setOpen, open, type }: Props) => {
  const { setNotification } = useNotification();
  const { handleSubmit } = useForm();

  const [updateActiveType, { loading, error }] = useMutation(updateActiveTypeMutation);

  const submit = handleSubmit(() => {
    updateActiveType({
      variables: {
        id: type?.id,
        is_active: !type?.is_active,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: productsTypesQuery, variables: { id: type?.category?.id } }],
      onCompleted: (data) => {
        const message = data.updateActiveType.is_active
          ? t('settings.taxonomy.type.active-success')
          : t('settings.taxonomy.type.deactivate-success');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <Modal
      theme="warning"
      title={t('global.warning')}
      buttonText={type?.is_active ? t('global.actions.deactivate') : t('global.actions.activate')}
      open={open}
      onClose={() => setOpen(false)}
      icon={<ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />}
      onSubmit={submit}
      loading={loading}
      error={error}>
      <div className="mt-6 space-y-6">
        <div className="flex flex-col space-y-4">
          <p>
            {type?.is_active
              ? t('settings.taxonomy.type.deactivate')
              : t('settings.taxonomy.type.activate')}
          </p>
          {!type?.is_active && (
            <p className="font-semibold">{t('settings.taxonomy.type.activation-cascade')}</p>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default ActivationTypeModal;
