import { gql } from 'graphql.macro';

export const updateMaterialAndColorsMutation = gql`
  mutation UpdateMaterialAndColors(
    $id: ID!
    $material1: Mixed
    $material2: Mixed
    $color: Mixed
    $supplier_color: String
  ) {
    updateMaterialAndColors(
      id: $id
      material1: $material1
      material2: $material2
      color: $color
      supplier_color: $supplier_color
    ) {
      id
      material1 {
        id
        label
      }
      material2 {
        id
        label
      }
      color {
        id
        label
        hex_code
      }
      supplier_color
    }
  }
`;
