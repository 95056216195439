import { gql } from 'graphql.macro';

export const ecomobilierSizeQuery = gql`
  query EcomobilierSize($id: Int!) {
    typeSizes(id: $id) {
      sizes {
        id
        code
        label
      }
    }
  }
`;
