import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/Product';
import { DEFAULT_PACKAGE_NAME } from 'global';
import { InformationAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';

type Props = {
  loading: boolean;
  product?: Product;
};

const Packaging = ({ loading, product }: Props) => {
  const { t } = useTranslation();

  return (
    <Card title={t('product.preview.packaging.title')}>
      <>
        {!loading && (!product?.packageSizes || product.packageSizes.length < 1) && (
          <InformationAlert>{t('product.preview.packaging.no-package')}</InformationAlert>
        )}
        <div className="grid grid-cols-2 gap-6">
          {loading && [...Array(2)].map((_, i) => <div key={i} className="skeleton h-36 w-full" />)}
          {product?.packageSizes.map((packageSize, i: number) => (
            <Card key={i}>
              <div className="space-y-6">
                <div className="flex items-center justify-between font-medium">
                  <span className="text-base uppercase text-dark">
                    {packageSize.package ? packageSize.package.name : DEFAULT_PACKAGE_NAME}
                  </span>
                  <span className="text-sm text-primary-500">
                    {t('product.preview.packaging.package', { i: i + 1 })}
                  </span>
                </div>
                <div className="space-y-1">
                  <p className="text-2xl font-medium text-secondary">
                    {packageSize.weight >= 1000
                      ? `${packageSize.weight / 1000} kg`
                      : `${packageSize.weight ?? 0} g`}
                  </p>
                  <p className="text-sm font-medium text-dark">
                    {`${packageSize.depth} cm x ${packageSize.width} cm x ${packageSize.height} cm`}
                  </p>
                </div>
              </div>
            </Card>
          ))}
        </div>
      </>
    </Card>
  );
};
export default Packaging;
