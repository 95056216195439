import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { NotificationProvider } from 'components/Contexts/NotificationContext';
import { GlobalProvider } from 'components/Contexts/GlobalContext';
import ScrollToTop from 'scrollToTop';
import App from './App';
import CustomApolloProvider from './apollo';
import reportWebVitals from './reportWebVitals';
import Notifications from 'components/Common/Notifications';
import moment from 'moment';
import './assets/main.css';
import 'flag-icons/css/flag-icons.css';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import 'moment/locale/fr';
import './i18n';
import 'react-quill/dist/quill.snow.css';

moment.locale('fr');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <CustomApolloProvider>
    <GlobalProvider>
      <React.StrictMode>
        <BrowserRouter>
          <ScrollToTop />
          <NotificationProvider>
            <Notifications />
            <App />
          </NotificationProvider>
        </BrowserRouter>
      </React.StrictMode>
    </GlobalProvider>
  </CustomApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
