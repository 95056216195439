import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/Product';
import { InformationAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';
import moment from 'moment';
import Badge from 'components/Common/Badge';

type Props = {
  loading: boolean;
  product?: Product;
};

const Arrivals = ({ loading, product }: Props) => {
  const { t } = useTranslation();

  const getTotal = (product?: Product) => {
    let total = 0;
    product?.detailedArrivals.map((arrival) => {
      total += arrival.quantity;
    });
    return total;
  };

  return (
    <Card title={t('product.preview.arrivals.title')}>
      <>
        {!loading && (!product?.detailedArrivals || product?.detailedArrivals.length < 1) ? (
          <InformationAlert>{t('product.preview.arrivals.no-arrival')}</InformationAlert>
        ) : (
          <>
            <div>
              <p className="text-sm font-semibold text-dark">
                {t('product.preview.arrivals.total')}
              </p>
              {loading ? (
                <div className="skeleton h-8 w-44" />
              ) : (
                <p className="text-2xl font-medium text-primary-500">{getTotal(product)}</p>
              )}
            </div>
            <div className="-mx-4 mt-8 sm:-mx-0">
              <table className="min-w-full divide-y divide-gray-300 text-sm">
                <thead className="text-left font-semibold uppercase text-secondary">
                  <tr>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.preview.arrivals.quantity')}
                    </th>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.preview.arrivals.date')}
                    </th>
                    <th scope="col" className="truncate px-3 py-3.5">
                      {t('product.preview.arrivals.po')}
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 xl:table-cell">
                      {t('product.preview.arrivals.po-status')}
                    </th>
                    <th scope="col" className="truncate px-3 py-3.5">
                      {t('product.preview.arrivals.transit')}
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 2xl:table-cell">
                      {t('product.preview.arrivals.transit-status')}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 text-left font-medium text-dark">
                  {loading && (
                    <tr>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                    </tr>
                  )}
                  {product?.detailedArrivals
                    .slice()
                    .sort((a, b) => moment(a.date).diff(moment(b.date)))
                    .map((arrival, i) => {
                      return (
                        <tr key={i}>
                          <td className="px-3 py-3.5">
                            <span>{arrival.quantity}</span>
                          </td>
                          <td className="px-3 py-3.5">
                            <span>{moment(arrival.date).format('DD/MM/YYYY')}</span>
                          </td>
                          <td className="px-3 py-3.5">
                            <Link to={arrival.purchaseOrder.legacyUrl} target="_blank">
                              <span className="flex w-full text-sm text-blue-600 hover:text-blue-700 hover:underline sm:rounded-b-lg">
                                {arrival.purchaseOrder.label}
                              </span>
                            </Link>
                          </td>
                          <td className="hidden px-3 py-3.5 xl:table-cell">
                            <Badge type="secondary">{arrival.purchaseOrder.status.label}</Badge>
                          </td>
                          <td className="px-3 py-3.5">
                            {arrival.transit ? (
                              <span className="text-dark">{arrival.transit.label}</span>
                            ) : (
                              '-'
                            )}
                          </td>
                          <td className="hidden px-3 py-3.5 2xl:table-cell">
                            {arrival.transit ? (
                              <Badge type="secondary">{arrival.transit.status.label}</Badge>
                            ) : (
                              '-'
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </>
        )}
      </>
    </Card>
  );
};

export default Arrivals;
