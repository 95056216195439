import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteColor from './DeleteColor';
import { allColorsQuery } from 'graphql/Queries/Product/MaterialsAndColors/allColors';
import { createColorMutation } from 'graphql/Mutations/Product/MaterialsAndColors/createColor';
import { updateColorMutation } from 'graphql/Mutations/Product/MaterialsAndColors/updateColor';
import { updateColorStatusMutation } from 'graphql/Mutations/Product/MaterialsAndColors/updateColorStatus';
import { OptionProps } from 'types/Global';
import { ProductColor } from 'types/Product';
import { InformationAlert } from 'components/Common/Alerts';
import ColorPickerModal from './ColorPickerModal';
import { InputTextControl } from 'components/Common/InputTextControl';
import SelectCreatable from 'components/Common/SelectCreatable';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';

type ColorOption = {
  label_gb: string;
  hex_code: string;
  parent: OptionProps | null;
  children: ProductColor[];
  isActive: boolean;
  isUsed: boolean;
} & OptionProps;

const Colors = () => {
  const { t } = useTranslation();

  const [showEditColor, setShowEditColor] = useState(false);
  const [showNewColor, setShowNewColor] = useState(false);
  const [allColors, setAllColors] = useState<ColorOption[]>([]);
  const [color, setColor] = useState<ColorOption | null>(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [activationChanging, setActivationChanging] = useState(false);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [hexColor, setHexColor] = useState<string | null>(null);

  const [getAllColors, { data: dataColors, loading: loadingColors, error: errorColors }] =
    useLazyQuery(allColorsQuery, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (!dataColors) return;
    setAllColors(
      dataColors.colors?.map((color: ProductColor) => {
        return {
          key: color.id,
          value: color.id,
          label: color.label,
          label_gb: color.label_gb,
          hex_code: color.hex_code,
          parent: color.parent
            ? {
                value: color.parent.id,
                label: color.parent.label,
              }
            : null,
          children: color.children,
          isActive: color.is_active,
          isUsed: color.isUsed,
        };
      })
    );
  }, [dataColors, setAllColors]);

  const [createColor, { loading: createColorLoading }] = useMutation(createColorMutation);

  const [updateColor, { loading: updateColorLoading }] = useMutation(updateColorMutation);
  const [updateColorStatus, { loading: updateColorStatusLoading }] =
    useMutation(updateColorStatusMutation);

  const onChangeColor = (value: unknown) => {
    if (!value) return;
    const typedValue = value as ColorOption;
    setShowEditColor(true);
    setShowNewColor(false);
    setColor(typedValue);
    reset({
      label: typedValue.label,
      label_gb: typedValue.label_gb,
      hex_code: typedValue.hex_code,
      parent: typedValue.parent,
    });
    setHexColor(typedValue.hex_code);
  };

  const onCreateColor = (value: string) => {
    setShowEditColor(false);
    setShowNewColor(true);
    setColor({
      value: value,
      label: value,
      label_gb: '',
      hex_code: '',
      parent: null,
      children: [],
      isActive: true,
      isUsed: false,
    });
    setHexColor(null);
    reset({
      label: value,
      label_gb: '',
      hex_code: '',
      parent: null,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().required(t('form.required')),
    label_gb: yup.string().trim().required(t('form.required')),
    hex_code: yup
      .string()
      .trim()
      .required(t('form.required'))
      .test(
        'is-hex-code',
        t('settings.products.materials-and-colors.colors.error-hex'),
        (value) => {
          // Check the hexadecimal format with a regular expression
          const hexRegex = /^#?([0-9A-Fa-f]{6})$/;
          return hexRegex.test(value);
        }
      ),
    parent: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
  });

  const {
    handleSubmit,
    reset,
    control,
    setValue,
    formState: { isDirty, errors },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      label: '',
    },
  });

  useEffect(() => {
    if (hexColor) setValue('hex_code', hexColor, { shouldDirty: true });
  }, [hexColor, setValue]);

  const onCancel = () => {
    setShowNewColor(false);
    setColor(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = handleSubmit((data) => {
    if (displayColorPicker) return;
    if (showNewColor) {
      createColor({
        refetchQueries: [
          {
            query: allColorsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          label: data.label,
          label_gb: data.label_gb,
          hex_code: data.hex_code,
          parent_id: data.parent?.value ?? null,
        },
        onCompleted: (dataReturn) => {
          const data = dataReturn.createProductColor;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.materials-and-colors.colors.created'),
            type: 'success',
          });
          reset({
            label: data.label,
            label_gb: data.label_gb,
            hex_code: data.hex_code,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
          });
          setColor({
            value: data.id,
            label: data.label,
            label_gb: data.label_gb,
            hex_code: data.hex_code,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
            children: [],
            isActive: true,
            isUsed: false,
          });
          setShowEditColor(true);
          setShowNewColor(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      if (!color) return;
      updateColor({
        refetchQueries: [
          {
            query: allColorsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          id: color.value,
          label: data.label,
          label_gb: data.label_gb,
          hex_code: data.hex_code,
          parent_id: data.parent?.value ?? null,
        },
        onCompleted: (dataReturn) => {
          const data = dataReturn.updateProductColor;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.materials-and-colors.colors.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
            label_gb: data.label_gb,
            hex_code: data.hex_code,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
          });
          setColor({
            value: color.value,
            label: data.label,
            label_gb: data.label_gb,
            hex_code: data.hex_code,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
            children: data.children,
            isActive: data.is_active,
            isUsed: data.isUsed,
          });
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  });

  const resetAfterDelete = () => {
    setColor(null);
    setShowEditColor(false);
    setShowNewColor(false);
  };

  const changeActivation = (value: boolean) => {
    if (!color) return;
    setActivationChanging(true);
    let message = '';
    if (value) {
      message = t('settings.products.materials-and-colors.colors.activate');
    } else {
      message = t('settings.products.materials-and-colors.colors.deactivate');
    }
    updateColorStatus({
      refetchQueries: [
        {
          query: allColorsQuery,
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: color.value,
        is_active: value,
      },
      onCompleted: (dataReturn) => {
        setActivationChanging(false);
        const data = dataReturn.updateProductColor;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
          label_gb: data.label_gb,
          hex_code: data.hex_code,
          parent: data.parent
            ? {
                value: data.parent.id,
                label: data.parent.label,
              }
            : null,
        });
        setColor({
          value: data.id,
          label: data.label,
          label_gb: data.label_gb,
          hex_code: data.hex_code,
          parent: data.parent
            ? {
                value: data.parent.id,
                label: data.parent.label,
              }
            : null,
          children: data.children,
          isActive: data.is_active,
          isUsed: data.isUsed,
        });
      },
      onError: (error) => {
        setActivationChanging(false);
        setError(error);
      },
    });
  };

  const onCancelColorPicker = () => {
    setHexColor(color?.hex_code as string | null);
    setDisplayColorPicker(false);
  };

  return (
    <>
      <DeleteColor
        onCloseModal={(loading) => {
          if (!loading) {
            setDisplayModal(false);
          }
        }}
        isOpen={displayModal}
        color={color}
        reset={resetAfterDelete}
      />
      <ColorPickerModal
        open={displayColorPicker}
        setOpen={setDisplayColorPicker}
        hexColor={hexColor}
        setHexColor={setHexColor}
        onCancel={onCancelColorPicker}
      />
      <div className="col-span-2 h-fit max-h-[45rem] 2xl:col-span-1">
        <Card>
          <div>
            <div className="p-4">
              <h3 className="text-lg font-medium text-blue-gray-900">
                {t('settings.products.materials-and-colors.colors.title')}
              </h3>
              <p className="mt-1 text-sm text-blue-gray-500">
                {t('settings.products.materials-and-colors.colors.subtitle')}
              </p>
            </div>
            <div className="p-4">
              <SelectCreatable
                label={t('settings.products.materials-and-colors.colors.list')}
                onCreateOption={onCreateColor}
                onChange={onChangeColor}
                options={allColors}
                value={color}
                apolloError={errorColors}
                loadingOptions={loadingColors}
                onFocus={() => {
                  if (!allColors || allColors.length === 0) {
                    getAllColors();
                  }
                }}
              />
            </div>
            {(showEditColor || showNewColor) && (
              <form onSubmit={onSubmit}>
                <div className="space-y-4 p-4">
                  <InputTextControl
                    control={control}
                    name="label"
                    label={t('settings.products.materials-and-colors.colors.designation-fr')}
                    isRequired
                  />
                  <InputTextControl
                    control={control}
                    name="label_gb"
                    label={t('settings.products.materials-and-colors.colors.designation-gb')}
                    isRequired
                  />
                  <div>
                    <label className="text-sm font-medium text-gray-700">
                      {t('settings.products.materials-and-colors.colors.hex-code')}
                    </label>
                    <div
                      className={`relative mt-1 inline-block h-9 w-full cursor-pointer rounded-md border ${
                        errors.hex_code ? 'border-red-300' : 'border-gray-300'
                      } bg-white p-2 ${displayColorPicker && 'bg-opacity-100'}`}
                      onClick={() => setDisplayColorPicker(true)}>
                      <div
                        className="h-full w-full rounded-md"
                        style={{ background: `#${hexColor ? hexColor : '000000'}` }}
                      />
                    </div>
                    {errors.hex_code && (
                      <p className="mt-2 text-sm text-red-700">
                        {errors.hex_code.message?.toString()}
                      </p>
                    )}
                  </div>
                  <div>
                    <label className="text-sm font-medium text-gray-700">
                      {t('settings.products.materials-and-colors.materials.group')}
                    </label>
                    <Controller
                      name="parent"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          theme={theme}
                          styles={errors ? customStyle : errorCustomStyle}
                          isDisabled={loadingColors}
                          placeholder={
                            loadingColors ? t('global.form.loading') : t('global.form.select')
                          }
                          options={allColors}
                          noOptionsMessage={() => t('global.form.no-option')}
                          isClearable
                        />
                      )}
                    />
                  </div>
                  {color?.isUsed && (
                    <div className="mt-4">
                      <InformationAlert>
                        {t('settings.products.materials-and-colors.colors.used')}
                      </InformationAlert>
                    </div>
                  )}
                  {color?.isActive && color?.children.length > 0 && (
                    <div className="mt-4">
                      <InformationAlert>
                        {t('settings.products.materials-and-colors.colors.deactivation-warning')}
                      </InformationAlert>
                    </div>
                  )}
                  {color?.isActive === false && color?.parent && (
                    <div className="mt-4">
                      <InformationAlert>
                        {t('settings.products.materials-and-colors.colors.activation-warning')}
                      </InformationAlert>
                    </div>
                  )}
                </div>
                <div className="flex flex-col flex-shrink-0 sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                  {showEditColor && (
                    <>
                      <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                        <Button
                          type="button"
                          theme="outlineDanger"
                          onClick={() => setDisplayModal(true)}
                          disabled={updateColorLoading || color?.isUsed}>
                          {t('global.actions.delete')}
                        </Button>
                        {color?.isActive ? (
                          <Button
                            theme="outlinePrimary"
                            onClick={() => changeActivation(false)}
                            loading={activationChanging}
                            disabled={updateColorLoading || updateColorStatusLoading}>
                            {t('global.actions.deactivate')}
                          </Button>
                        ) : (
                          <Button
                            theme="outlinePrimary"
                            onClick={() => changeActivation(true)}
                            loading={activationChanging}
                            disabled={updateColorLoading || updateColorStatusLoading}>
                            {t('global.actions.activate')}
                          </Button>
                        )}
                        <Button
                          type="submit"
                          theme="primary"
                          loading={updateColorLoading}
                          disabled={!isDirty}>
                          {t('global.actions.save')}
                        </Button>
                      </div>
                    </>
                  )}
                  {showNewColor && (
                    <>
                      <Button theme="transparent" onClick={onCancel} disabled={createColorLoading}>
                        {t('global.actions.cancel')}
                      </Button>
                      <Button
                        type="submit"
                        theme="primary"
                        loading={createColorLoading}
                        disabled={!isDirty || createColorLoading}>
                        {t('global.actions.add')}
                      </Button>
                    </>
                  )}
                </div>
              </form>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Colors;
