import { gql } from 'graphql.macro';

export const suppliersQuery = gql`
  query Suppliers {
    suppliers {
      id
      name
      active
    }
  }
`;
