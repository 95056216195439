import { PaperClipIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  file: File;
  type?: 'button' | 'link';
  icon?: React.ReactElement;
  setShowErrorDownloadFile?: React.Dispatch<React.SetStateAction<string>>;
};

type File = {
  clientUrl: string;
  name: string;
};

const DownloadDocument = ({ file, type = 'button', icon, setShowErrorDownloadFile }: Props) => {
  const { t } = useTranslation();
  const allowedExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'csv'];

  const downloadMedia = async (file: File) => {
    try {
      const a = document.createElement('a');
      const response = await fetch(file.clientUrl);

      const extension = file.name.split('.').pop()?.toLowerCase();

      if (!extension || !allowedExtensions.includes(extension)) {
        setShowErrorDownloadFile?.(t('product.documents.impossible-to-download'));
        return;
      }

      setShowErrorDownloadFile?.('');

      const blob = new Blob([await response.arrayBuffer()], { type: `application/${extension}` });
      a.href = URL.createObjectURL(blob);
      a.download = file.name ? file.name : file.clientUrl.split('/').pop() || 'fichier';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {type === 'button' && <button onClick={() => downloadMedia(file)}>{icon}</button>}
      {type === 'link' && (
        <span
          className="flex cursor-pointer gap-2 italic text-blue-500 hover:text-blue-600 hover:underline"
          onClick={() => downloadMedia(file)}>
          <PaperClipIcon className="w-5" />
          {file.name}
        </span>
      )}
    </>
  );
};

export default DownloadDocument;
