import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { productSupplierQuery } from 'graphql/Queries/Product/Supplier/productSupplier';
import { Button } from 'components/Common/Button';
import { Product } from 'types/Product';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import SelectSupplierSlide from './SelectSupplierSlide';
import AllInformations from './AllInformations';
import Card from 'components/Common/Card';

const Informations = () => {
  const { t } = useTranslation();
  const params = useParams();

  const [openSlide, setOpenSlide] = useState<boolean>(false);

  const { data, loading, error } = useQuery(productSupplierQuery, {
    fetchPolicy: 'network-only',
    variables: { id: params.productId },
  });

  const product: Product | undefined = data?.product;

  if (error) return <ApolloErrorAlert error={error} />;

  return (
    <>
      {!product?.currentSupplying ? (
        <Card title={t('product.purchase-information.informations.no-supplier-title')}>
          <>
            <SelectSupplierSlide
              product={product}
              setOpen={setOpenSlide}
              open={openSlide}
              isNewSupplier={true}
            />
            <div className="flex h-64 flex-col items-center justify-center space-y-6 text-dark">
              {loading ? (
                <div className="flex flex-col items-center justify-center space-y-8">
                  <div className="skeleton h-6 w-72" />
                  <div className="skeleton h-10 w-36" />
                </div>
              ) : (
                <>
                  <div>{t('product.purchase-information.informations.no-supplier')}</div>
                  <Button type="button" onClick={() => setOpenSlide(true)}>
                    {t('product.purchase-information.informations.choose-supplier')}
                  </Button>
                </>
              )}
            </div>
          </>
        </Card>
      ) : (
        <AllInformations product={product} />
      )}
    </>
  );
};
export default Informations;
