import React from 'react';
import { useTranslation } from 'react-i18next';
import { Disclosure } from '@headlessui/react';
import FieldLegend from './FormUtils/FieldLegend';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type Props = {
  register: UseFormRegister<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  activityFilterBucket: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  destockFilterBucket: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  exceptionFilterBucket: any;
};

const StatusFilter = ({
  register,
  activityFilterBucket,
  destockFilterBucket,
  exceptionFilterBucket,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div" className={''}>
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.status.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                activityFilterBucket.filter((elem: any) => elem['key_as_string'] === 'true')[0] &&
                  activityFilterBucket.length !== 0 && (
                    <div>
                      <input
                        id={'active_true'}
                        type="checkbox"
                        {...register('active_true')}
                        className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                      />
                      <label
                        htmlFor={'active_true'}
                        className="ml-3 inline-flex gap-[6px] text-sm text-gray-600">
                        {t('products.filters.status.active')}
                      </label>
                    </div>
                  )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                activityFilterBucket.filter((elem: any) => elem['key_as_string'] === 'false')[0] &&
                  activityFilterBucket.length !== 0 && (
                    <div>
                      <input
                        id={'active_false'}
                        type="checkbox"
                        {...register('active_false')}
                        className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                      />
                      <label
                        htmlFor={'active_false'}
                        className="ml-3 inline-flex gap-[6px] text-sm text-gray-600">
                        {t('products.filters.status.inactive')}
                      </label>
                    </div>
                  )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                destockFilterBucket.filter((elem: any) => elem['key_as_string'] !== 'false')[0] &&
                  destockFilterBucket.length !== 0 && (
                    <div>
                      <input
                        id={'is_to_destock_true'}
                        type="checkbox"
                        {...register('is_to_destock_true')}
                        className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                      />
                      <label
                        htmlFor={'is_to_destock_true'}
                        className="ml-3 inline-flex gap-[6px] text-sm text-gray-600">
                        {t('products.filters.status.destock')}
                      </label>
                    </div>
                  )
              }
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                exceptionFilterBucket.filter((elem: any) => elem['key'] === 1)[0] &&
                  exceptionFilterBucket.length !== 0 && (
                    <div>
                      <input
                        id={'supply_exception_true'}
                        type="checkbox"
                        {...register('supply_exception_true')}
                        className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                      />
                      <label
                        htmlFor={'supply_exception_true'}
                        className="ml-3 inline-flex gap-[6px] text-sm text-gray-600">
                        {t('products.filters.status.supplier-exception')}
                      </label>
                    </div>
                  )
              }
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default StatusFilter;
