import { useMutation, useQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { taxonomyQuery } from 'graphql/Queries/Product/SupplierAndTaxonomy/taxonomy';
import { updateProductTaxonomyMutation } from 'graphql/Mutations/Product/SupplierAndTaxonomy/updateProductTaxonomy';
import { updateProductTitleMutation } from 'graphql/Mutations/Product/SupplierAndTaxonomy/updateProductTitle';
import TitleEditSkeleton from './Skeleton/TitleEdit';
import HelperTextTaxonomie from './Helpers/HelperTaxonomie';
import TaxonomyEdit from './Forms/TaxonomyEdit';
import TitleEdit from './Forms/TitleEdit';
import TaxonomyEditSkeleton from './Skeleton/TaxonomyEdit';
import Helper from 'components/Common/Helper';
import HelperTextTitle from './Helpers/HelperTextTitle';
import { useProductContextType } from 'components/Contexts/ProductContext';

const Index = () => {
  const { t } = useTranslation();
  const params = useParams();
  const [key, setKey] = useState(0);
  const [defautValues, setDefaultValues] = useState(null);
  const [showHelpStatus, setShowHelpStatus] = useState({
    helper1: false,
    helper3: false,
  });

  const {
    data: dataTaxonomy,
    loading: loadingTaxonomy,
    error,
  } = useQuery(taxonomyQuery, {
    fetchPolicy: 'network-only',
    variables: { id: params.productId },
  });

  useEffect(() => {
    if (dataTaxonomy?.product) {
      setDefaultValues(formateDefaultValues(dataTaxonomy.product));
      setKey((key) => key + 1);
    }
  }, [dataTaxonomy, setDefaultValues, setKey]);

  const [updateTaxonomy, { loading: updateTaxonomyLoading }] = useMutation(
    updateProductTaxonomyMutation
  );
  const [updateTitle, { loading: updateTitleLoading }] = useMutation(updateProductTitleMutation);

  const { productExists } = useProductContextType();

  if (!productExists) return <></>;

  return (
    <>
      <div className="space-y-6 sm:px-6 lg:col-span-9 lg:px-0">
        <section role="title-edit">
          <div className="bg-white shadow sm:rounded-md">
            <div className="px-6 py-6 pb-0 sm:pt-6">
              <div className="flex">
                <h2
                  id="payment-details-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t('product.taxonomy.edit-title')}
                </h2>
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowHelpStatus({
                      ...showHelpStatus,
                      helper1: !showHelpStatus.helper1,
                    });
                  }}
                  className=""
                >
                  <InformationCircleIcon className="ml-2 h-5 w-5 text-gray-300 hover:text-gray-600" />
                </button>
              </div>
              <div>
                <Helper
                  isShowing={showHelpStatus.helper1}
                  onClose={() =>
                    setShowHelpStatus({
                      ...showHelpStatus,
                      helper1: !showHelpStatus.helper1,
                    })
                  }
                >
                  <HelperTextTitle />
                </Helper>
              </div>
            </div>
            {error && (
              <div className="my-2 p-2">
                <ApolloErrorAlert error={error}></ApolloErrorAlert>
              </div>
            )}
            {loadingTaxonomy && <TitleEditSkeleton />}
            {!loadingTaxonomy && !error && defautValues && (
              <TitleEdit
                defaultTitle={defautValues.internal_title}
                loading={updateTitleLoading}
                updateTitle={updateTitle}
                productId={params.productId}
              />
            )}
          </div>
        </section>
        <section role="taxonomy-edit">
          <div className="bg-white shadow sm:rounded-md">
            <div className="px-6 py-6 pb-0 sm:pt-6">
              <div className="flex">
                <h2
                  id="payment-details-heading"
                  className="text-lg font-medium leading-6 text-gray-900"
                >
                  {t('product.taxonomy.edit-taxonomy')}
                </h2>
                <button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();
                    setShowHelpStatus({
                      ...showHelpStatus,
                      helper3: !showHelpStatus.helper3,
                    });
                  }}
                  className=""
                >
                  <InformationCircleIcon className="ml-2 h-5 w-5 text-gray-300 hover:text-gray-600" />
                </button>
              </div>
              <div>
                <Helper
                  isShowing={showHelpStatus.helper3}
                  onClose={() =>
                    setShowHelpStatus({
                      ...showHelpStatus,
                      helper3: !showHelpStatus.helper3,
                    })
                  }
                >
                  <HelperTextTaxonomie />
                </Helper>
              </div>
            </div>
            {error && (
              <div className="my-2 p-2">
                <ApolloErrorAlert error={error}></ApolloErrorAlert>
              </div>
            )}
            {loadingTaxonomy && <TaxonomyEditSkeleton />}
            {!loadingTaxonomy && !error && defautValues && (
              <TaxonomyEdit
                key={key}
                defaultProductValues={defautValues}
                loading={updateTaxonomyLoading}
                updateProduct={updateTaxonomy}
                productId={params.productId}
              />
            )}
          </div>
        </section>
      </div>
    </>
  );
};

const formateDefaultValues = (product) => {
  return {
    internal_title: product.currentTranslation?.internal_title ?? '',
    universe: product.taxonomy?.type?.category?.universe
      ? {
          value: product.taxonomy.type.category.universe.id,
          label: product.taxonomy.type.category.universe.label,
          key: product.taxonomy.type.category.universe.id,
          isDisabled: !product.taxonomy.type.category.universe.is_active,
        }
      : null,
    category: product.taxonomy?.type?.category
      ? {
          value: product.taxonomy.type.category.id,
          label: product.taxonomy.type.category.label,
          key: product.taxonomy.type.category.id,
          isDisabled: !product.taxonomy.type.category.is_active,
        }
      : null,
    type: product.taxonomy?.type
      ? {
          value: product.taxonomy.type.id,
          label: product.taxonomy.type.label,
          key: product.taxonomy.type.id,
          isDisabled: !product.taxonomy.type.is_active,
        }
      : null,
  };
};

export default Index;
