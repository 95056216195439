import { gql } from 'graphql.macro';

export const productsTypesQuery = gql`
  query productCategory($id: ID!) {
    productCategory(id: $id) {
      types {
        id
        label
        productsCount
        is_active
        category {
          id
          label
          universe {
            id
            label
          }
        }
      }
    }
  }
`;
