import { gql } from 'graphql.macro';

export const switchProductNoveltyMutation = gql`
  mutation SwitchProductNovelty($id: ID!, $tag_id: Int!) {
    switchProductNovelty(id: $id, tag_id: $tag_id) {
      id
      isNovelty
    }
  }
`;
