import { useState } from 'react';
import { ProductCategory, ProductUniverse } from 'types/Product';

const useHandleCreate = () => {
  const [openCreateUniverseSlide, setOpenCreateUniverseSlide] = useState<boolean>(false);
  const [openCreateCategorySlide, setOpenCreateCategorySlide] = useState<boolean>(false);
  const [openCreateTypeSlide, setOpenCreateTypeSlide] = useState<boolean>(false);

  const handleCreate = (
    selectedUniverse: ProductUniverse | null,
    selectedCategory: ProductCategory | null
  ) => {
    if (!selectedUniverse) {
      setOpenCreateUniverseSlide(true);
    } else if (selectedUniverse && !selectedCategory) {
      setOpenCreateCategorySlide(true);
    } else {
      setOpenCreateTypeSlide(true);
    }
  };

  return {
    openCreateUniverseSlide,
    setOpenCreateUniverseSlide,
    openCreateCategorySlide,
    setOpenCreateCategorySlide,
    openCreateTypeSlide,
    setOpenCreateTypeSlide,
    handleCreate,
  };
};

export default useHandleCreate;
