import { gql } from 'graphql.macro';

export const addNoticeCommentMutation = gql`
  mutation addNoticeComment($id: ID!, $comment: String!) {
    addNoticeComment(id: $id, comment: $comment) {
      id
      name
      comment
    }
  }
`;
