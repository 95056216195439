import { gql } from 'graphql.macro';

export const productCollectionQuery = gql`
  query ProductCollection($limit: Int!, $page: Int!, $keyword: String!) {
    productCollectionByKeyword(limit: $limit, page: $page, keyword: $keyword) {
      collection {
        id
        label
        active
      }
      currentPage
      lastPage
    }
  }
`;
