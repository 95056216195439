import React, { Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { CheckCircleIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/20/solid';
import useNotification from '../../hooks/useNotification';

export type NotificationProps = {
  id?: number;
  type: string;
  title: string;
  message: string;
};

const Notifications = () => {
  const { notifications } = useNotification();
  return (
    <div className="fixed right-0 top-[50px] z-[999] flex flex-col">
      {notifications &&
        notifications.map((notification) => (
          <Notification
            key={notification.id}
            id={notification.id}
            type={notification.type}
            title={notification.title}
            message={notification.message}
          />
        ))}
    </div>
  );
};

const Notification = ({ id, type, title, message }: NotificationProps) => {
  const { removeNotification } = useNotification();

  return (
    <>
      <div
        aria-live="assertive"
        className={
          'pointer-events-none inset-0 z-[999] flex w-[380px] items-end px-4 pt-3 sm:items-start sm:pt-3 ' +
          (type === 'success' ? 'animate-fade' : '')
        }>
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          <Transition
            show={type != ''}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    {type === 'success' && <CheckCircleIcon className="h-6 w-6 text-green-400" />}
                    {type === 'failure' && <XCircleIcon className="h-6 w-6 text-red-400" />}
                    {type === 'warning' && (
                      <ExclamationCircleIcon className="h-6 w-6 text-yellow-400" />
                    )}
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <p className="text-sm font-medium text-gray-900">{title}</p>
                    <p className="mt-1 text-sm text-gray-500">{message}</p>
                  </div>
                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                      onClick={() => {
                        if (typeof id !== 'undefined') removeNotification(id);
                      }}>
                      <span className="sr-only">Close</span>
                      <XCircleIcon className="h-5 w-5" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </>
  );
};

export default Notifications;
