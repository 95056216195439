import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { FeatureUnit, ProductFeature } from 'types/Product';
import Tippy from '@tippyjs/react';
import DeleteProductFeature from './DeleteProductFeature';
import UpdateProductWeight from './UpdateProductWeight';
import Feature from './Feature';
import UpdateFeature from './UpdateFeature';

type Props = {
  features: ProductFeature[] | null;
  units: FeatureUnit[];
  productWeight: number;
};

const Features = ({ features, units, productWeight }: Props) => {
  const { t } = useTranslation();

  const [openUpdateFeature, setOpenUpdateFeature] = useState(false);
  const [openUpdateProductWeight, setOpenUpdateProductWeight] = useState(false);
  const [openDeleteFeature, setOpenDeleteFeature] = useState(false);
  const [featureToUpdate, setFeatureToUpdate] = useState<ProductFeature | null>(null);
  const [featureToDelete, setFeatureToDelete] = useState<ProductFeature | null>(null);

  return (
    <>
      <UpdateFeature
        open={openUpdateFeature}
        setOpen={setOpenUpdateFeature}
        feature={featureToUpdate}
        units={units}
      />
      <UpdateProductWeight
        open={openUpdateProductWeight}
        setOpen={setOpenUpdateProductWeight}
        value={productWeight}
      />
      <DeleteProductFeature
        open={openDeleteFeature}
        setOpen={setOpenDeleteFeature}
        feature={featureToDelete}
      />
      <table className="mt-12 min-w-full divide-y divide-gray-300 text-sm text-dark">
        <thead className="font-semibold uppercase text-secondary">
          <tr>
            <th scope="col" className="table-cell py-3.5 text-left">
              {t('product.features.designation')}
            </th>
            <th scope="col" className="table-cell px-3 py-3.5 text-left">
              {t('product.features.value')}
            </th>
            <th />
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-300">
          {features &&
            features.map((feature) => (
              <Feature
                key={feature.id}
                feature={feature}
                setOpenUpdateFeature={setOpenUpdateFeature}
                setFeatureToUpdate={setFeatureToUpdate}
                setOpenDeleteFeature={setOpenDeleteFeature}
                setFeatureToDelete={setFeatureToDelete}
              />
            ))}
          <tr>
            <td className="w-56 truncate py-3.5 pr-3 font-semibold">
              {t('product.features.product-weight')}
            </td>
            <td className="table-cell px-3  py-3.5">
              {productWeight >= 1000 ? `${productWeight / 1000} kg` : `${productWeight ?? 0} g`}
            </td>
            <td className="flex justify-end gap-3 px-3 py-3.5">
              <Tippy content={t('global.actions.modify')}>
                <PencilSquareIcon
                  className="w-6 cursor-pointer text-secondary outline-none hover:text-dark"
                  onClick={() => setOpenUpdateProductWeight(true)}
                />
              </Tippy>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default Features;
