import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation } from '@apollo/client';
import { SingleValue } from 'react-select';
import { designersQuery } from 'graphql/Queries/Product/Editorial/designers';
import { createDesignerMutation } from 'graphql/Mutations/Settings/Designer/createDesigner';
import { updateDesignerMutation } from 'graphql/Mutations/Settings/Designer/updateDesigner';
import { DesignerType } from 'types/Global';
import { useForm, FieldValues } from 'react-hook-form';
import { InputTextControl } from 'components/Common/InputTextControl';
import { InformationAlert } from 'components/Common/Alerts';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';
import SelectCreatable from 'components/Common/SelectCreatable';
import useNotification from 'hooks/useNotification';
import DeleteDesignerModal from './DeleteDesignerModal';
import Image from './Image';
import * as yup from 'yup';
import { checkDesignerImageSize, checkImagesDesignerExtension } from 'utils/Utils';

const List = () => {
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();

  const [designerOptions, setDesignerOptions] = useState<DesignerType[]>([]);
  const [designer, setDesigner] = useState<DesignerType | null>(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [showEditDesigner, setShowEditDesigner] = useState(false);
  const [showNewDesigner, setShowNewDesigner] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [activationLoading, setActivationLoading] = useState(false);
  const [errorsFile, setErrorsFile] = useState<string[]>([]);

  const schema = yup.object().shape({
    name: yup.string().required(t('form.required')),
    designerImage: designer?.photoClientUrl
      ? yup
          .mixed()
          .test('image-format', '', async function (value) {
            if ((value as FileList)?.length) {
              return checkImagesDesignerExtension(value as FileList);
            }
            return true;
          })
          .test('image-size', '', async function (value) {
            if ((value as FileList)?.length) {
              const verif = await checkDesignerImageSize(value as FileList);
              return verif;
            }
            return true;
          })
      : yup
          .mixed()
          .test('image-required', t('settings.designers.image.required'), function (value) {
            return !!(value as FileList)?.length;
          })
          .test('image-format', '', async function (value) {
            return checkImagesDesignerExtension(value as FileList);
          })
          .test('image-size', '', async function (value) {
            const verif = await checkDesignerImageSize(value as FileList);
            return verif;
          }),
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    formState: { isDirty, errors },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      name: '',
      designerImage: null,
    },
  });

  const [createDesigner] = useMutation(createDesignerMutation);
  const [updateDesigner] = useMutation(updateDesignerMutation);

  const [getAllDesigners, { data, loading }] = useLazyQuery(designersQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data) return;
    setDesignerOptions(
      data.designers?.map((option: DesignerType) => {
        return {
          value: option.id,
          label: option.name,
          photoClientUrl: option.photoClientUrl,
          is_active: option.is_active,
          isUsed: option.isUsed,
        };
      })
    );
  }, [data]);

  const onCancel = () => {
    setShowNewDesigner(false);
    setDesigner(null);
  };

  const onCreateDesigner = (newDesigner: string) => {
    if (!newDesigner) return;
    setShowNewDesigner(true);
    setShowEditDesigner(false);

    setDesigner({
      id: 0,
      value: '',
      label: newDesigner,
      name: newDesigner,
      photoClientUrl: '',
      is_active: false,
      isUsed: false,
    });

    reset({
      name: newDesigner,
    });
  };

  const resetAfterDelete = () => {
    setDesigner(null);
    setShowNewDesigner(false);
    setShowEditDesigner(false);
  };

  const onChangeDesigner = (value: unknown) => {
    if (!value) return;
    const typedValue = value as SingleValue<DesignerType>;
    setShowEditDesigner(true);
    setShowNewDesigner(false);

    setDesigner(typedValue);

    reset({
      name: typedValue?.label,
      designerImage: FileList,
    });
  };

  const changeActivation = (value: boolean) => {
    setActivationLoading(true);
    updateDesigner({
      variables: {
        id: designer?.value,
        is_active: value,
        name: getValues('name'),
        file: getValues('designerImage')[0] ?? null,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: designersQuery,
        },
      ],
      onCompleted: (res) => {
        setNotification({
          title: t('global.success'),
          message: t('settings.designers.mutation.update-success'),
          type: 'success',
        });

        setDesigner({
          id: res.updateDesigner.id,
          value: res.updateDesigner.id,
          label: res.updateDesigner.name,
          name: res.updateDesigner.name,
          photoClientUrl: res.updateDesigner.photoClientUrl,
          is_active: res.updateDesigner.is_active,
          isUsed: res.updateDesigner.isUsed,
        });

        reset({
          label: res.updateDesigner.name,
          name: res.updateDesigner.name,
          designerImage: FileList,
        });

        setShowNewDesigner(false);
        setShowEditDesigner(true);
        setActivationLoading(false);
      },
      onError: (error) => {
        setError(error);
        setActivationLoading(false);
      },
    });
  };

  const onSubmit = handleSubmit((values) => {
    setSaveLoading(true);
    setErrorsFile([]);

    if (showNewDesigner) {
      createDesigner({
        variables: {
          file: getValues('designerImage')[0],
          name: values.name,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: designersQuery,
          },
        ],
        onCompleted: (res) => {
          setNotification({
            title: t('global.success'),
            message: t('settings.designers.mutation.create-success'),
            type: 'success',
          });

          setDesigner({
            id: res.createDesigner.id,
            value: res.createDesigner.id,
            label: res.createDesigner.name,
            name: res.createDesigner.name,
            photoClientUrl: res.createDesigner.photoClientUrl,
            is_active: true,
            isUsed: false,
          });

          reset({
            label: res.createDesigner.name,
            name: res.createDesigner.name,
            designerImage: getValues('designerImage'),
          });

          setShowNewDesigner(false);
          setShowEditDesigner(true);

          setSaveLoading(false);
        },
        onError: (error) => {
          setError(error);
          setSaveLoading(false);
        },
      });
    } else {
      updateDesigner({
        variables: {
          id: designer?.value,
          name: values?.name,
          is_active: designer?.is_active,
          file: getValues('designerImage')[0] ?? null,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: designersQuery,
          },
        ],
        onCompleted: (res) => {
          setNotification({
            title: t('global.success'),
            message: t('settings.designers.mutation.update-success'),
            type: 'success',
          });

          setDesigner({
            id: res.updateDesigner.id,
            value: res.updateDesigner.id,
            label: res.updateDesigner.name,
            name: res.updateDesigner.name,
            photoClientUrl: res.updateDesigner.photoClientUrl,
            is_active: res.updateDesigner.is_active,
            isUsed: res.updateDesigner.isUsed,
          });

          reset({
            label: res.updateDesigner.name,
            name: res.updateDesigner.name,
            designerImage: getValues('designerImage'),
          });

          setSaveLoading(false);
        },
        onError: (error) => {
          setError(error);
          setSaveLoading(false);
        },
      });
    }
  });

  return (
    <div className="space-y-6 p-4">
      <Card>
        <div>
          <DeleteDesignerModal
            isOpen={displayModal}
            reset={resetAfterDelete}
            setDisplayModal={setDisplayModal}
            designer={designer}
          />
          <div className="p-4">
            <h3 className="text-lg font-medium text-blue-gray-900">
              {t('settings.designers.title')}
            </h3>
          </div>
          <div className="p-4">
            <label className="text-sm font-medium text-gray-700">
              {t('settings.designers.select.label')}
            </label>
            <SelectCreatable
              options={designerOptions}
              onCreateOption={onCreateDesigner}
              onChange={onChangeDesigner}
              onFocus={() => {
                if (!designerOptions || designerOptions.length === 0) {
                  getAllDesigners();
                }
              }}
              loadingOptions={loading}
              value={designer}
            />
          </div>
          {(showEditDesigner || showNewDesigner) && (
            <form onSubmit={onSubmit}>
              <div className="relative p-4">
                <InputTextControl
                  label={t('settings.designers.name')}
                  name="name"
                  control={control}
                  isRequired
                />
              </div>

              <Image
                image={designer?.photoClientUrl ?? ''}
                setValue={setValue}
                errorMessage={errors?.designerImage?.message as string}
                errorsFile={errorsFile}
                setErrorsFile={setErrorsFile}
              />

              {designer?.isUsed && (
                <div className="p-4">
                  <InformationAlert>{t('settings.designers.is-used')}</InformationAlert>
                </div>
              )}
              <div className="rounded">
                <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                  {showEditDesigner && (
                    <>
                      <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                        <Button
                          type="button"
                          theme="outlineDanger"
                          disabled={designer?.isUsed}
                          onClick={() => setDisplayModal(true)}>
                          {t('global.actions.delete')}
                        </Button>
                        {designer?.is_active ? (
                          <Button
                            type="button"
                            theme="outlinePrimary"
                            disabled={activationLoading}
                            loading={activationLoading}
                            onClick={() => changeActivation(false)}>
                            {t('global.actions.deactivate')}
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            theme="outlinePrimary"
                            disabled={activationLoading}
                            loading={activationLoading}
                            onClick={() => changeActivation(true)}>
                            {t('global.actions.activate')}
                          </Button>
                        )}
                        <Button type="submit" loading={saveLoading} disabled={!isDirty}>
                          {t('global.actions.save')}
                        </Button>
                      </div>
                    </>
                  )}
                  {showNewDesigner && (
                    <>
                      <Button
                        type="button"
                        theme="transparent"
                        onClick={onCancel}
                        disabled={saveLoading}>
                        {t('global.actions.cancel')}
                      </Button>
                      <Button
                        type="submit"
                        theme="primary"
                        loading={saveLoading}
                        disabled={saveLoading}>
                        {t('global.actions.add')}
                      </Button>
                    </>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </Card>
    </div>
  );
};
export default List;
