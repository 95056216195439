import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { productHistoryQuery } from 'graphql/Queries/Product/Supplier/productHistory';
import { InformationAlert } from 'components/Common/Alerts';
import { ProductMoqHistory } from 'types/Product';
import moment from 'moment';

const ProductHistory = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { loading, data } = useQuery(productHistoryQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  if (data?.product?.productHistory.length < 1) {
    return (
      <div className="m-2">
        <InformationAlert>
          {t('product.purchase-information.informations.no-history-available')}
        </InformationAlert>
      </div>
    );
  }

  return (
    <>
      <table className="min-w-full table-fixed divide-y divide-gray-300 ">
        <thead className="sticky top-0 bg-white">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-dark">
              {t('product.purchase-information.informations.history.moq')}
            </th>
            <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-dark">
              {t('product.purchase-information.informations.history.user')}
            </th>
            <th scope="col" className="py-3.5 pr-4 text-right text-sm font-semibold text-dark">
              {t('product.purchase-information.informations.history.modification-date')}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 ">
          {loading ? (
            <tr className="bg-white">
              <td className="max-w-[200px] truncate whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-secondary">
                <div className="skeleton h-4 w-10"></div>
              </td>
              <td className="max-w-[200px] truncate whitespace-nowrap py-3.5 pr-3 text-left text-sm text-secondary">
                <div className="skeleton w-30 h-4"></div>
              </td>
              <td className="flex justify-end whitespace-nowrap py-3.5 pr-4">
                <div className="skeleton h-4 w-36"></div>
              </td>
            </tr>
          ) : (
            <>
              {data.product.productHistory.map((history: ProductMoqHistory) => {
                return (
                  <tr key={history.id}>
                    <td className="max-w-[200px] truncate whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-gray-500">
                      {history.value}
                    </td>
                    <td className="max-w-[200px] truncate whitespace-nowrap py-3.5 pr-3 text-left text-sm text-gray-500">
                      {history.user?.lastname} {history.user?.firstname}
                    </td>
                    <td className="whitespace-nowrap py-3.5 pr-4 text-right text-sm text-gray-500">
                      {moment(history.created_at).format('Do MMMM YYYY HH:mm')}
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default ProductHistory;
