import React from 'react';
import { useTranslation } from 'react-i18next';
import { Disclosure } from '@headlessui/react';
import MultiSelect from './FormUtils/MultiSelect';
import FieldLegend from './FormUtils/FieldLegend';
import { Control } from 'react-hook-form';

type Props = {
  control: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  brandFilterBucket: any;
};

const BrandFilter = ({ control, brandFilterBucket }: Props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const brandsSelect = brandFilterBucket.map((brand: any, index: number) => {
    return {
      key: index,
      label: brand.branding.hits.hits[0]._source.branding.label,
      value: brand.key,
      minilabel: brand.branding.hits.hits[0]._source.branding.label,
    };
  });
  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div" className={''}>
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.brand.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              <MultiSelect name={'branding'} control={control} options={brandsSelect} />
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default BrandFilter;
