import React, { createContext, useContext, ReactNode, useState } from 'react';

interface SearchContextType {
  searchProduct: string;
  setSearchProduct: (value: string) => void;
}

const SearchProductContext = createContext<SearchContextType | undefined>(undefined);

export function useSearchContextType() {
  const context = useContext(SearchProductContext);
  if (!context) {
    throw new Error('useSearchContextType must be used within a SearchProductProvider');
  }
  return context;
}

interface SearchProviderProps {
  children: ReactNode;
}

export function SearchProvider({ children }: SearchProviderProps) {
  const [searchProduct, setSearchProduct] = useState('');

  return (
    <SearchProductContext.Provider value={{ searchProduct, setSearchProduct }}>
      {children}
    </SearchProductContext.Provider>
  );
}
