import React, { ReactElement } from 'react';

type Props = {
  children: ReactElement;
};

export const BlankLayout = ({ children }: Props) => {
  return (
    <div className="flex h-screen flex-col">
      <div className="flex-grow bg-gray-50">
        <main>{children}</main>
      </div>
    </div>
  );
};
