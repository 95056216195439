import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import SlideOver from 'components/Common/SlideOver';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Datepicker from 'react-tailwindcss-datepicker';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';
import { FiltersProps } from './Index';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setFilters: React.Dispatch<React.SetStateAction<FiltersProps>>;
  defaultValues: FiltersProps;
}

export const Filters = ({ open, setOpen, setFilters, setPage, defaultValues }: Props) => {
  const { t } = useTranslation();

  const [validatedPhotoChecked, setValidatedPhotoChecked] = useState<boolean>(false);
  const [unvalidatedPhotoChecked, setUnvalidatedPhotoChecked] = useState<boolean>(false);
  const [validatedPhotoEditionChecked, setValidatedPhotoEditionChecked] = useState<boolean>(false);
  const [unvalidatedPhotoEditionChecked, setUnvalidatedPhotoEditionChecked] =
    useState<boolean>(false);
  const [validatedIntegrationChecked, setValidatedIntegrationChecked] = useState<boolean>(false);
  const [unvalidatedIntegrationChecked, setUnvalidatedIntegrationChecked] =
    useState<boolean>(false);

  const [arrivalDate, setArrivalDate] = useState<DateValueType>({
    startDate: null,
    endDate: null,
  });

  const [startCompletion, setStartCompletion] = useState<number>(0);
  const [endCompletion, setEndCompletion] = useState<number>(100);

  const onChangeStartCompletion = (value: string) => {
    let start = parseInt(value);
    if (start > endCompletion) {
      start = endCompletion;
    }
    setStartCompletion(start);
  };

  const onChangeEndCompletion = (value: string) => {
    let end = parseInt(value);
    if (end < startCompletion) {
      end = startCompletion;
    }
    setEndCompletion(end);
  };

  useEffect(() => {
    setValidatedPhotoChecked(!!defaultValues.photoValidated);
    setUnvalidatedPhotoChecked(defaultValues.photoValidated === false);
    setValidatedPhotoEditionChecked(!!defaultValues.photoEditionValidated);
    setUnvalidatedPhotoEditionChecked(defaultValues.photoEditionValidated === false);
    setValidatedIntegrationChecked(!!defaultValues.integrationValidated);
    setUnvalidatedIntegrationChecked(defaultValues.integrationValidated === false);
    setArrivalDate(defaultValues.arrivalDate);
    setStartCompletion(defaultValues.completion.start);
    setEndCompletion(defaultValues.completion.end);
  }, [open, defaultValues]);

  const { handleSubmit } = useForm();

  const onSubmit = handleSubmit(() => {
    let photoValidated: boolean | null = null;
    if (validatedPhotoChecked && !unvalidatedPhotoChecked) {
      photoValidated = true;
    } else if (!validatedPhotoChecked && unvalidatedPhotoChecked) {
      photoValidated = false;
    }
    let photoEditionValidated: boolean | null = null;
    if (validatedPhotoEditionChecked && !unvalidatedPhotoEditionChecked) {
      photoEditionValidated = true;
    } else if (!validatedPhotoEditionChecked && unvalidatedPhotoEditionChecked) {
      photoEditionValidated = false;
    }
    let integrationValidated: boolean | null = null;
    if (validatedIntegrationChecked && !unvalidatedIntegrationChecked) {
      integrationValidated = true;
    } else if (!validatedIntegrationChecked && unvalidatedIntegrationChecked) {
      integrationValidated = false;
    }

    setFilters((filters) => {
      return {
        productName: filters.productName,
        photoValidated,
        photoEditionValidated,
        integrationValidated,
        completion: {
          start: startCompletion,
          end: endCompletion,
        },
        arrivalDate,
      } as FiltersProps;
    });
    setOpen(false);
    setPage(1);
  });

  const clearFilters = () => {
    setOpen(false);
  };

  const filters = [
    {
      name: t('marketing-integration.filters.validated-photo'),
      content: (
        <div className="space-y-2">
          <div>
            <input
              type="checkbox"
              id="validated-photo"
              className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              checked={validatedPhotoChecked}
              onChange={() => setValidatedPhotoChecked(!validatedPhotoChecked)}
            />
            <label htmlFor="validated-photo" className="ml-2 text-sm text-gray-600">
              {t('global.validated-f')}
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="unvalidated-photo"
              className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              checked={unvalidatedPhotoChecked}
              onChange={() => setUnvalidatedPhotoChecked(!unvalidatedPhotoChecked)}
            />
            <label htmlFor="unvalidated-photo" className="ml-2 text-sm text-gray-600">
              {t('global.unvalidated-f')}
            </label>
          </div>
        </div>
      ),
      open: defaultValues.photoValidated !== null,
    },
    {
      name: t('marketing-integration.filters.validated-photo-edition'),
      content: (
        <div className="space-y-2">
          <div>
            <input
              type="checkbox"
              id="validated-photo-edition"
              className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              checked={validatedPhotoEditionChecked}
              onChange={() => setValidatedPhotoEditionChecked(!validatedPhotoEditionChecked)}
            />
            <label htmlFor="validated-photo-edition" className="ml-2 text-sm text-gray-600">
              {t('global.validated-f')}
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="unvalidated-photo-edition"
              className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              checked={unvalidatedPhotoEditionChecked}
              onChange={() => setUnvalidatedPhotoEditionChecked(!unvalidatedPhotoEditionChecked)}
            />
            <label htmlFor="unvalidated-photo-edition" className="ml-2 text-sm text-gray-600">
              {t('global.unvalidated-f')}
            </label>
          </div>
        </div>
      ),
      open: defaultValues.photoEditionValidated !== null,
    },
    {
      name: t('marketing-integration.filters.validated-integration'),
      content: (
        <div className="space-y-2">
          <div>
            <input
              type="checkbox"
              id="validated-integration"
              className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              checked={validatedIntegrationChecked}
              onChange={() => setValidatedIntegrationChecked(!validatedIntegrationChecked)}
            />
            <label htmlFor="validated-integration" className="ml-2 text-sm text-gray-600">
              {t('global.validated-f')}
            </label>
          </div>
          <div>
            <input
              type="checkbox"
              id="unvalidated-integration"
              className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
              checked={unvalidatedIntegrationChecked}
              onChange={() => setUnvalidatedIntegrationChecked(!unvalidatedIntegrationChecked)}
            />
            <label htmlFor="unvalidated-integration" className="ml-2 text-sm text-gray-600">
              {t('global.unvalidated-f')}
            </label>
          </div>
        </div>
      ),
      open: defaultValues.integrationValidated !== null,
    },
    {
      name: t('marketing-integration.filters.completion'),
      content: (
        <div className="flex items-center">
          <select
            className="w-full rounded-md border-gray-300 text-dark shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            value={startCompletion}
            onChange={(e) => onChangeStartCompletion(e.target.value)}>
            {[...Array(11)].map((x, i) => (
              <option key={i} value={i * 10} disabled={i * 10 > endCompletion}>
                {i * 10} %
              </option>
            ))}
          </select>
          <span className="px-3">{t('global.at')}</span>
          <select
            className="w-full rounded-md border-gray-300 text-dark shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            value={endCompletion}
            onChange={(e) => onChangeEndCompletion(e.target.value)}>
            {[...Array(11)].map((x, i) => (
              <option key={i} value={i * 10} disabled={i * 10 < startCompletion}>
                {i * 10} %
              </option>
            ))}
          </select>
        </div>
      ),
      open: defaultValues.completion.start !== 0 || defaultValues.completion.end !== 100,
    },
    {
      name: t('marketing-integration.filters.arrival-date'),
      content: (
        <Datepicker
          value={arrivalDate}
          onChange={setArrivalDate}
          showShortcuts
          useRange={false}
          primaryColor={'blue'}
          inputClassName="w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          placeholder={''}
        />
      ),
      open: defaultValues.arrivalDate.startDate !== null,
    },
  ];

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={t('products.filters.title')}
        buttonText={t('global.actions.validate')}
        onSubmit={onSubmit}
        onCancel={clearFilters}>
        <>
          {filters.map((filter) => (
            <Disclosure
              defaultOpen={filter.open}
              key={filter.name}
              as="div"
              className="border-t border-gray-200 px-4 py-6">
              {({ open }) => (
                <>
                  <h3 className="-mx-2 -my-3 flow-root">
                    <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                      <span className="font-medium text-dark">{filter.name}</span>
                      <span className="ml-6 flex items-center">
                        {open ? (
                          <MinusIcon className="h-5 w-5" aria-hidden="true" />
                        ) : (
                          <PlusIcon className="h-5 w-5" aria-hidden="true" />
                        )}
                      </span>
                    </Disclosure.Button>
                  </h3>
                  <Disclosure.Panel className="pt-6">{filter.content}</Disclosure.Panel>
                </>
              )}
            </Disclosure>
          ))}
        </>
      </SlideOver>
    </>
  );
};
