import { useTranslation } from 'react-i18next';

const TitleEditSkeleton = () => {
  const { t } = useTranslation();
  return (
    <>
      <div className="grid grid-cols-1 gap-y-6 bg-white px-4 pb-6 pt-0 sm:grid-cols-6 sm:gap-x-6 sm:p-6 sm:pt-0">
        <div className="sm:col-span-6">
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('product.taxonomy.edit-title-helper')}
          </p>
        </div>
        <div className="sm:col-span-6">
          <div className="skeleton mt-1 h-[38px] w-full "></div>
        </div>
      </div>
      <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
        <button
          disabled
          type="button"
          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-blue-gray-900 shadow-sm hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          {t('global.actions.cancel')}
        </button>
        <button
          disabled
          type="submit"
          className={
            'ml-3 inline-flex justify-center rounded-md border border-transparent bg-blue-200 px-4 py-2 text-sm font-medium text-white shadow-sm'
          }>
          {t('global.actions.save')}
        </button>
      </div>
    </>
  );
};

export default TitleEditSkeleton;
