import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BookOpenIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import { Product } from 'types/Product';
import { Menu, Transition } from '@headlessui/react';
import { TAG_GROUPED_ID, getCurrencySymbol } from 'global';
import Card from 'components/Common/Card';
import ProductModelHistory from './ProductModelHistory';
import SelectSupplierSlide from './SelectSupplierSlide';
import ProductHistory from './ProductHistory';
import Badge from 'components/Common/Badge';
import TagHistory from 'components/Product/TagHistory';

type Props = {
  product: Product;
};

const AllInformations = ({ product }: Props) => {
  const { t } = useTranslation();

  const [openSlide, setOpenSlide] = useState<boolean>(false);

  return (
    <Card
      title={t('product.purchase-information.informations.title')}
      addOnEnd={
        <Tippy content={t('global.actions.modify')}>
          <button type="button" onClick={() => setOpenSlide(true)}>
            <PencilSquareIcon className="w-6 shrink-0 text-secondary hover:outline-none focus:outline-none" />
          </button>
        </Tippy>
      }>
      <>
        <SelectSupplierSlide open={openSlide} product={product} setOpen={setOpenSlide} />
        <div className="line-clamp-1 flex w-fit items-center space-x-4 overflow-hidden font-bold text-dark">
          <div>{product?.currentSupplying?.supplier?.billing_name}</div>
          <div>
            {product.currentSupplying?.supplier?.active ? (
              <Badge type="success">{t('global.active')}</Badge>
            ) : (
              <Badge type="danger">{t('global.inactive')}</Badge>
            )}
          </div>
        </div>
        <div className="flex space-x-1 text-sm text-dark">
          <div>{t('product.purchase-information.informations.manage-by')}</div>
          <div className="flex space-x-1">
            <div>{product?.currentSupplying?.supplier?.supplierManager?.firstname}</div>
            <div className="uppercase">
              {product?.currentSupplying?.supplier?.supplierManager?.lastname}{' '}
            </div>
          </div>
        </div>
        <div className="flex flex-col space-y-3">
          <div className="mt-4 flex space-x-2">
            <div className="w-72 text-sm text-secondary">
              {t('product.purchase-information.informations.price')}
            </div>
            <div className="text-sm text-dark">
              {product?.currentSupplying?.price ? (
                <span className="font-semibold">
                  {`${product.currentSupplying.price} ${getCurrencySymbol(
                    product.currentSupplying.currency?.code
                  )}`}
                </span>
              ) : (
                '-'
              )}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="w-72 text-sm text-secondary">
              {t('product.purchase-information.informations.supplier-name')}
            </div>
            <div className="text-sm font-semibold text-dark">
              {product.currentSupplying?.supplier_product_id
                ? product.currentSupplying?.supplier_product_id
                : '-'}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="w-72 text-sm text-secondary">
              {t('product.purchase-information.informations.replenishment-time')}
            </div>
            <div className="text-sm font-semibold text-dark">
              {product.replenishment_time
                ? product.replenishment_time > 1
                  ? `${product.replenishment_time} ${t('global.days')}`
                  : `${product.replenishment_time} ${t('global.day')}`
                : '-'}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="text-sm text-dark">
              <div className="w-72 ">
                <div className="flex space-x-2 text-sm text-secondary">
                  <span>{t('product.purchase-information.informations.moq-reference')}</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center text-xs italic text-secondary hover:text-sky-950 hover:underline ">
                        <BookOpenIcon className="w-4 text-secondary" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <ProductHistory />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <span className="text-sm font-semibold text-dark">
              {product.minimum_order_quantity ? product.minimum_order_quantity : '-'}
            </span>
          </div>
          <div className="flex space-x-2">
            <div className="text-sm text-dark">
              <div className="w-72 ">
                <div className="flex space-x-2 text-sm text-secondary">
                  <span>{t('product.purchase-information.informations.moq-model')}</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center text-xs italic text-secondary hover:text-sky-950 hover:underline ">
                        <BookOpenIcon className="w-4 text-secondary" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <ProductModelHistory modelId={product?.model?.id.toString()} />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <span className="text-sm font-semibold text-dark">
              {product?.model?.minimum_order_quantity ? product?.model.minimum_order_quantity : '-'}
            </span>
          </div>
          <div className="flex space-x-2">
            <div className="w-72 text-sm text-secondary">
              {t('product.purchase-information.informations.sales-unit')}
            </div>
            <div className="text-sm font-semibold text-dark">
              {product.taxonomy &&
              product.taxonomy.sell_unit !== null &&
              product.taxonomy.sell_unit !== 0
                ? product.taxonomy.sell_unit
                : '-'}
            </div>
          </div>
          <div className="flex space-x-2">
            <span className="w-72 text-sm text-secondary">
              {t('product.purchase-information.informations.buy-sell-unit')}
            </span>
            <div className="text-sm font-semibold text-dark">
              {product.taxonomy &&
              product.taxonomy.buy_unit !== null &&
              product.taxonomy.buy_unit !== 0
                ? product.taxonomy.buy_unit
                : '-'}
            </div>
          </div>
          <div className="flex space-x-2">
            <div className="text-sm text-dark">
              <div className="w-72 ">
                <div className="flex space-x-2 text-sm text-secondary">
                  <span> {t('product.purchase-information.informations.grouped-article')}</span>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center text-xs italic text-secondary hover:text-sky-950 hover:underline ">
                        <BookOpenIcon className="w-4 text-secondary" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <TagHistory tagId={TAG_GROUPED_ID} />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
            <span className="text-sm font-semibold text-dark">
              {product?.currentSupplying?.is_grouped ? t('global.yes') : t('global.no')}
            </span>
          </div>
        </div>
      </>
    </Card>
  );
};

export default AllInformations;
