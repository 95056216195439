import { gql } from 'graphql.macro';

export const createImportPackageMutation = gql`
  mutation CreateImportPackage(
    $product_id: ID!
    $weight: Int!
    $volume: Float!
    $label: String!
    $label_for_supplier: String!
    $moq: Int!
    $price: Float
    $price_currency_id: ID
  ) {
    createProductImportPackage(
      product_id: $product_id
      weight: $weight
      volume: $volume
      label: $label
      label_for_supplier: $label_for_supplier
      moq: $moq
      price: $price
      price_currency_id: $price_currency_id
    ) {
      id
    }
  }
`;
