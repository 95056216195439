import { gql } from 'graphql.macro';

export const productCategoriesQuery = gql`
  query productCategories($id: ID!) {
    productUniverse(id: $id) {
      categories {
        id
        label
        productsCount
        is_active
        universe {
          id
          label
        }
      }
    }
  }
`;
