import { gql } from 'graphql.macro';

export const createColorMutation = gql`
  mutation CreateColor($label: String!, $label_gb: String!, $parent_id: ID, $hex_code: String!) {
    createProductColor(
      label: $label
      label_gb: $label_gb
      parent_id: $parent_id
      hex_code: $hex_code
    ) {
      id
      label
      label_gb
      parent {
        id
        label
      }
      hex_code
    }
  }
`;
