import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import { allActiveProductMadeInsQuery } from 'graphql/Queries/Product/Brand/allActiveProductMadeIns';
import { SelectControl } from 'components/Common/SelectControl';
import { Supplier } from 'types/Supplier';
import { MadeIn } from 'types/Product';
import Tippy from '@tippyjs/react';

type Props = {
  supplierCountry: Supplier[];
};

const SelectMadeIn = ({ supplierCountry }: Props) => {
  const { t } = useTranslation();
  const [supplierCountryData, setSupplierCountryData] = useState<
    Array<{ name: string; iso: string }>
  >([]);
  const { loading, data, error } = useQuery(allActiveProductMadeInsQuery);
  const { control } = useFormContext();

  const getAllSupplierCountry = useCallback(() => {
    const uniqueCountryData = supplierCountry
      ?.map((supplier) => ({
        name: supplier.country?.name || '',
        iso: supplier.country?.iso || '',
      }))
      .filter(
        (country, index, self) =>
          index === self.findIndex((c) => c.name === country.name && c.iso === country.iso)
      );

    setSupplierCountryData(uniqueCountryData);
  }, [supplierCountry]);

  useEffect(() => {
    getAllSupplierCountry();
  }, [getAllSupplierCountry]);

  const options = data?.allActiveProductMadeIns?.map((madeIn: MadeIn) => {
    return {
      value: madeIn.id,
      label: madeIn.label,
      url: madeIn.logoFullPath,
    };
  });

  const getCountries = () => {
    let contries = '';
    supplierCountryData?.map((country, i) => {
      contries += country.name;
      if (i < supplierCountryData.length - 1) {
        contries += ', ';
      }
    });
    return contries;
  };

  return (
    <div>
      <SelectControl
        control={control}
        name="madeIn"
        label={t('product.brand-label.madeIn.title')}
        options={options}
        loadingOptions={loading}
        isClearable
        apolloError={error}
      />

      <div className="my-4 flex w-full text-sm text-dark">
        <p className="whitespace-nowrap">{t('product.brand-label.madeIn.production-country')}</p>

        <div className="ml-2 cursor-default truncate font-medium ">
          <Tippy
            content={
              <ul>{supplierCountryData?.map((country, i) => <li key={i}>{country.name}</li>)}</ul>
            }>
            <span className="truncate">{getCountries()}</span>
          </Tippy>
        </div>
      </div>
    </div>
  );
};

export default SelectMadeIn;
