export const customStyle = {
  input: (base) => ({
    ...base,
    border: 'none !important',
    boxShadow: 'none',
    ':focus': {
      border: 'none !important',
      outline: 'none',
      boxShadow: 'none',
    },
    'input:focus': {
      border: 'none !important',
      outline: 'none',
      boxShadow: 'none',
    },
  }),
};

export const theme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#F4D8D4',
    primary50: '#F4D8D4',
    primary: '#D38377',
  },
});

export const errorCustomStyle = {
  input: (base) => ({
    ...base,
    border: 'none !important',
    boxShadow: 'none',
    ':focus': {
      border: 'none !important',
      outline: 'none',
      boxShadow: 'none',
    },
    'input:focus': {
      border: 'none !important',
      outline: 'none',
      boxShadow: 'none',
    },
  }),
  control: (base) => ({
    ...base,
    borderColor: 'red',
    boxShadow: 'none',
    ':focus': {
      borderColor: 'red',
      outline: 'none',
      boxShadow: 'none',
    },
    ':hover': {
      borderColor: 'red',
      outline: 'none',
    },
  }),
};
