import React, { SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/24/outline';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { allImagesQuery } from 'graphql/Queries/Product/Images/Types/allImages';
import { imageStickerQuery } from 'graphql/Queries/Product/Images/Sticker/image';
import { deleteImageMutation } from 'graphql/Mutations/Product/Images/deleteProductImage';
import { ID } from 'types/Global';
import useNotification from 'hooks/useNotification';
import Modal from 'components/Common/Modal';

type Props = {
  imageId?: ID | null;
  openDeleteModal: boolean;
  imageType?: string;
  setOpenDeleteModal: React.Dispatch<SetStateAction<boolean>>;
};

const DeleteImageModal = ({ openDeleteModal, setOpenDeleteModal, imageType, imageId }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [deleteImage, { loading, error, reset }] = useMutation(deleteImageMutation);

  const { handleSubmit } = useForm<FieldValues>({});

  const onCloseConfirmModal = () => {
    setOpenDeleteModal(false);
    reset();
  };

  const onSubmit = handleSubmit(() => {
    deleteImage({
      variables: {
        id: imageId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: imageStickerQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: allImagesQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.sticker.remove-file.success'),
          type: 'success',
        });
        onCloseConfirmModal();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <Modal
        theme={'danger'}
        title={t('product.sticker.modal.title')}
        buttonText={t('global.actions.delete')}
        loading={loading}
        error={error}
        open={openDeleteModal}
        onClose={onCloseConfirmModal}
        onSubmit={onSubmit}
        icon={<TrashIcon className="h-6 w-6 text-red-600" />}>
        <div className="mt-6 flex flex-col space-y-6">
          <Trans
            i18nKey={'product.sticker.modal.description'}
            values={{
              name: imageType,
            }}
          />
          <div className="font-medium text-red-600">{t('product.sticker.modal.delete-confim')}</div>
        </div>
      </Modal>
    </>
  );
};

export default DeleteImageModal;
