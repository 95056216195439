import { gql } from 'graphql.macro';

export const createIncotermMutation = gql`
  mutation CreateIncoterm($label: String!) {
    createIncoterm(label: $label) {
      id
      label
      active
    }
  }
`;
