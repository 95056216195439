import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import useNotification from 'hooks/useNotification';
import { useParams } from 'react-router-dom';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

const TitleEdit = ({ defaultTitle, loading = false, updateTitle, productId }) => {
  let params = useParams();
  const { setNotification, setError } = useNotification();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    reset,
  } = useForm({
    defaultValues: { internal_title: defaultTitle },
  });

  const onSubmit = (data) => {
    const dirtyValues = getDirtyFields(dirtyFields, data);

    updateTitle({
      variables: {
        id: productId,
        internal_title: dirtyValues.internal_title ? dirtyValues.internal_title : null,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: (data) => {
        const internal_title = data.updateProductTitle.internal_title;
        reset({ internal_title: internal_title });
        data.updateProductTitle.updateStatus.map((msg) =>
          setNotification({
            title: t('product.taxonomy.title'),
            message: msg,
            type: 'success',
          })
        );
      },
      onError: (error) => {
        setError(error);
        reset();
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-1 gap-y-6 bg-white px-4 pb-6 pt-0 sm:grid-cols-6 sm:gap-x-6 sm:p-6 sm:pt-0">
        <div className="sm:col-span-6">
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('product.taxonomy.edit-title-helper')}
          </p>
        </div>
        <div className="sm:col-span-6">
          <label
            htmlFor="internal_title"
            className="block text-sm font-medium text-blue-gray-900"></label>
          <input
            {...register('internal_title')}
            type="text"
            name="internal_title"
            id="internal_title"
            className="mt-1 block w-full rounded-md border-blue-gray-300 text-blue-gray-900 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
          />
        </div>
      </div>
      <div className="flex justify-end bg-gray-50 px-4 py-3 text-right sm:px-6">
        <button
          onClick={() => reset()}
          disabled={loading}
          type="button"
          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-blue-gray-900 shadow-sm hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          {t('global.actions.cancel')}
        </button>
        <button
          disabled={!isDirty || loading}
          type="submit"
          className="ml-3 inline-flex min-w-[110px] justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm 
          font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2
          focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-600 disabled:opacity-30">
          {loading ? (
            <div className="border-white-900 h-5 w-5 animate-spin rounded-full border-b-2" />
          ) : (
            t('global.actions.save')
          )}
        </button>
      </div>
    </form>
  );
};

TitleEdit.propTypes = {
  defaultTitle: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  updateTitle: PropTypes.func.isRequired,
  productId: PropTypes.string.isRequired,
};

const getDirtyFields = (dirtyFields, data) => {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return data;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, getDirtyFields(dirtyFields[key], data[key])])
  );
};
export default TitleEdit;
