import { useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { searchProductsByFiltersQuery } from 'graphql/Queries/Product/Search/searchProductsByFilters';

type ProductResults = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  products: any;
  total: number;
  lastPage: number;
};

const useSearch = () => {
  const [filterBuckets, setFilterBuckets] = useState(null);
  const [page, setPage] = useState(1);
  const [productResults, setProductResults] = useState<ProductResults>({
    products: [],
    total: 0,
    lastPage: 1,
  });

  const getSearchParams = (query: string) => {
    let title = '';
    if (query) {
      const params = JSON.parse(query.slice(0, -1));
      title = params.filter((elem: string[]) => elem[0] === 'search-field')[0][1];
    }
    return title;
  };

  const [getProduct, { loading, error }] = useLazyQuery(searchProductsByFiltersQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setProductResults({
        products: data.searchProductsByFilters.products,
        total: data.searchProductsByFilters.total,
        lastPage: data.searchProductsByFilters.lastPage,
      });
      setFilterBuckets(data.searchProductsByFilters.aggs);
    },
    onError: () => {
      setProductResults({
        products: [],
        total: 0,
        lastPage: 0,
      });
    },
  });

  return {
    getSearchParams,
    getProduct,
    filterBuckets,
    productResults,
    loading,
    error,
    page,
    setPage,
  };
};
export default useSearch;
