import React from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from 'global';
import { ProductImportPackage } from 'types/Product';
import Tippy from '@tippyjs/react';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';

interface PackageProps {
  number: number;
  importPackage: ProductImportPackage;
  setPackage: React.Dispatch<React.SetStateAction<ProductImportPackage | null>>;
  setOpenUpdatePackage: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeletePackage: React.Dispatch<React.SetStateAction<boolean>>;
}

const Package = ({
  number,
  importPackage,
  setPackage,
  setOpenUpdatePackage,
  setOpenDeletePackage,
}: PackageProps) => {
  const { t } = useTranslation();

  const weight =
    importPackage.weight >= 1000
      ? `${importPackage.weight / 1000} kg`
      : `${importPackage.weight} g`;

  const volume = parseFloat((importPackage.volume / 1000 / 1000).toFixed(3));

  return (
    <>
      <td className="px-3 py-3.5">{number}</td>
      <td className="px-3 py-3.5">
        <span className="whitespace-nowrap">{importPackage.label}</span>
      </td>
      <td className="hidden px-3 py-3.5 2xl:table-cell">
        <span className="whitespace-nowrap">{importPackage.label_for_supplier}</span>
      </td>
      <td className="hidden px-3 py-3.5 2xl:table-cell">
        <span>
          {importPackage.price ? `${importPackage.price}` : '-'}{' '}
          {importPackage.currency ? ` ${getCurrencySymbol(importPackage.currency?.code)}` : '-'}
        </span>
      </td>
      <td className="px-3 py-3.5">
        <span>{`${volume} m³`}</span>
      </td>
      <td className="px-3 py-3.5">
        <span>{weight}</span>
      </td>
      <td className="hidden px-3 py-3.5 2xl:table-cell">
        <span>{importPackage.moq}</span>
      </td>
      <td className="px-3 py-3.5">
        <div className="flex justify-end gap-3">
          <Tippy content={t('global.actions.modify')}>
            <PencilSquareIcon
              className="w-6 cursor-pointer text-secondary outline-none hover:text-dark"
              onClick={() => {
                setOpenUpdatePackage(true);
                setPackage(importPackage);
              }}
            />
          </Tippy>
          <Tippy content={t('global.actions.delete')}>
            <TrashIcon
              className="w-6 cursor-pointer text-secondary outline-none hover:text-red-600"
              onClick={() => {
                setOpenDeletePackage(true);
                setPackage(importPackage);
              }}
            />
          </Tippy>
        </div>
      </td>
    </>
  );
};

export default Package;
