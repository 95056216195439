import { gql } from 'graphql.macro';

export const supplierManagersQuery = gql`
  query SupplierManagers {
    userGroup(id: 7) {
      id
      activeUsers {
        id
        firstname
        lastname
      }
    }
  }
`;
