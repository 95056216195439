import { gql } from 'graphql.macro';

export const updateProductComments = gql`
  mutation UpdateProductComments($id: ID!, $comments: String) {
    updateProductComments(id: $id, comments: $comments) {
      id
      comments
    }
  }
`;
