import React from 'react';
import { useTranslation } from 'react-i18next';
import DummySelect from 'components/Common/DummySelect';

function EcomobilierSkeleton() {
  const { t } = useTranslation();

  return (
    <>
      <div className="px-6 xl:grid xl:grid-cols-6 xl:gap-x-6">
        <div className="my-2 sm:col-span-3">
          <DummySelect title={t('product.ecotax.product-type')} />
        </div>
        <div className="my-2 sm:col-span-3">
          <DummySelect title={t('product.ecotax.product-type')} />
        </div>
        <div className="my-2 sm:col-span-3">
          <DummySelect title={t('product.ecotax.materials')} />
        </div>
        <div className="my-2 sm:col-span-3">
          <DummySelect title={t('product.ecotax.size')} />
          <div className="h-[28px]"></div>
        </div>
        <div className="mt-6 sm:col-span-6">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center"></div>
            <div className="skeleton h-5 w-5"></div>
            <div className="ml-3  text-sm">
              <label htmlFor="deee-paidInAdvance" className=" block text-sm font-medium text-dark">
                {t('product.ecotax.paid-in-advance')}
              </label>
            </div>
          </div>
        </div>

        <div className="mt-2 sm:col-span-6">
          <div className="relative flex items-start">
            <div className="flex h-5 items-center"></div>
            <div className="skeleton h-5 w-5"></div>
            <div className="ml-3 text-sm">
              <label htmlFor="deee-enable" className="block text-sm font-medium text-dark">
                {t('product.ecotax.applicable')}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end p-6 text-right sm:px-6">
        <button
          disabled
          className="flex justify-center rounded-md bg-primary-500  px-3 py-2 text-sm font-semibold text-white opacity-30 shadow-sm">
          {t('global.actions.save')}
        </button>
      </div>
    </>
  );
}

export default EcomobilierSkeleton;
