import React, { createContext, useContext, ReactNode, useState } from 'react';

interface GlobalContextType {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface GlobalProviderProps {
  children: ReactNode;
}

const GlobalContext = createContext<GlobalContextType | null>(null);

export function useGlobalContextType() {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error('useProductContextType must be used within a ProductProvider');
  }
  return context;
}

export function GlobalProvider({ children }: GlobalProviderProps) {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>(
    localStorage.getItem('sidebarOpen') ? !(localStorage.getItem('sidebarOpen') === 'false') : true
  );

  return (
    <GlobalContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      {children}
    </GlobalContext.Provider>
  );
}
