import React, { useEffect, useState } from 'react';
import { MutationFunctionOptions, useLazyQuery, useQuery } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { productUniversesQuery } from 'graphql/Queries/Product/SupplierAndTaxonomy/productUniverses';
import { searchProductCategoriesByUniverseIdQuery } from 'graphql/Queries/Product/searchProductCategoriesByUniverseId';
import { searchProductTypesByCategoryIdQuery } from 'graphql/Queries/Product/searchProductTypesByCategoryId';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { ID } from 'types/Global';
import { ProductCategory, ProductType, ProductUniverse } from 'types/Product';
import MutliCreatable from '../FormTools/MultiCreatable';
import useNotification from 'hooks/useNotification';

type DefaultProductValuesProps = {
  universe: {
    value: ID;
    label: string;
    key: ID;
    __isNew__?: boolean;
  } | null;
  category: {
    value: ID;
    label: string;
    key: ID;
    __isNew__?: boolean;
  } | null;
  type: {
    value: ID;
    label: string;
    key: ID;
    __isNew__?: boolean;
  } | null;
};

interface TaxonomyEditProps {
  defaultProductValues: DefaultProductValuesProps;
  loading: boolean;
  updateProduct: (options?: MutationFunctionOptions) => void;
  productId: ID;
}

const CREATED_VALUE = -1;

const TaxonomyEdit = ({
  defaultProductValues,
  loading,
  updateProduct,
  productId,
}: TaxonomyEditProps) => {
  const [universes, setUniverses] = useState([]);
  const [categories, setCategories] = useState([]);
  const [types, setTypes] = useState([]);
  const [validTaxonomy, setValidTaxonomy] = useState(false);
  const { setNotification, setError } = useNotification();
  const { t } = useTranslation();

  const [
    getCategories,
    { loading: loadingCategories, error: errorCategories, refetch: refetchCategories },
  ] = useLazyQuery(searchProductCategoriesByUniverseIdQuery, {
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: { universe_id: defaultProductValues.universe?.value },
    onCompleted: (data) => {
      setCategories(
        data.productUniverse?.categories.map((category: ProductCategory) => {
          return {
            key: category.id,
            value: category.id,
            label: category.label,
            isDisabled: !category.is_active,
          };
        })
      );
    },
  });

  const [getTypes, { loading: loadingTypes, error: errorType, refetch: refetchTypes }] =
    useLazyQuery(searchProductTypesByCategoryIdQuery, {
      variables: { category_id: defaultProductValues.category?.value },
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      onCompleted: (data) => {
        setTypes(
          data.productCategory?.types.map((type: ProductType) => {
            return {
              key: type.id,
              value: type.id,
              label: type.label,
              isDisabled: !type.is_active,
            };
          })
        );
      },
    });

  const {
    loading: loadingUniverses,
    error: errorUniverses,
    refetch: refetchUniverses,
  } = useQuery(productUniversesQuery, {
    nextFetchPolicy: 'network-only',
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setUniverses(
        data.productUniverses.map((universe: ProductUniverse) => {
          return {
            value: universe.id,
            key: universe.id,
            label: universe.label ? universe.label : '-',
            isDisabled: !universe.is_active,
          };
        })
      );
    },
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty, dirtyFields },
    setValue,
    reset,
    getValues,
  } = useForm<DefaultProductValuesProps>({ defaultValues: defaultProductValues });

  const fetchNewCategories = () => {
    const universe = getValues('universe');
    if (!universe) {
      return;
    }
    setValidTaxonomy(false);
    setValue('category', null);
    setValue('type', null);
    if (universe.__isNew__) {
      setCategories([]);
    } else {
      getCategories({ variables: { universe_id: universe.value } });
      setTypes([]);
    }
  };

  const fetchNewTypes = () => {
    const category = getValues('category');
    if (!category) {
      return;
    }
    setValidTaxonomy(false);
    setValue('type', null);
    if (category.__isNew__) {
      setTypes([]);
    } else {
      getTypes({ variables: { category_id: category.value } });
    }
  };

  useEffect(() => {
    if (getValues('universe')) {
      getCategories({ variables: { universe_id: getValues('universe')?.value } });
    }

    if (getValues('category')) {
      getTypes({ variables: { category_id: getValues('category')?.value } });
    }
  }, [getValues, getCategories, getTypes]);

  const onSubmit = handleSubmit((data) => {
    updateProduct({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: productId,
          },
        },
      ],
      variables: {
        id: productId,
        universe:
          validTaxonomy && data.universe
            ? {
                id: !data.universe.__isNew__ ? data.universe.value : CREATED_VALUE,
                label: data.universe.label,
              }
            : null,
        category:
          validTaxonomy && data.category
            ? {
                id: !data.category.__isNew__ ? data.category.value : CREATED_VALUE,
                label: data.category.label,
              }
            : null,
        type:
          validTaxonomy && data.type
            ? {
                id: !data.type.__isNew__ ? data.type.value : -1,
                label: data.type.label,
              }
            : null,
      },
      onCompleted: (data) => {
        const product = data.updateProductTaxonomy.product;
        reset({
          universe: product.taxonomy?.type?.category?.universe
            ? {
                value: product.taxonomy.type.category.universe.id,
                label: product.taxonomy.type.category.universe.label,
                key: product.taxonomy.type.category.universe.id,
              }
            : null,
          category: product.taxonomy?.type?.category
            ? {
                value: product.taxonomy.type.category.id,
                label: product.taxonomy.type.category.label,
                key: product.taxonomy.type.category.id,
              }
            : null,
          type: product.taxonomy?.type
            ? {
                value: product.taxonomy.type.id,
                label: product.taxonomy.type.label,
                key: product.taxonomy.type.id,
              }
            : null,
        });

        data.updateProductTaxonomy.updateStatus.map((status: string) =>
          setNotification({
            title: t('product.taxonomy.title'),
            message: status,
            type: 'success',
          })
        );
        refetchUniverses();
        refetchCategories({
          universe_id: product.taxonomy?.type?.category?.universe?.id ?? '',
        });
        refetchTypes({
          category_id: product.taxonomy?.type?.category?.id ?? '',
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  const onCancel = () => {
    reset();
    setCategories([]);
    setTypes([]);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="grid grid-cols-1 gap-y-6 bg-white px-4 pb-6 pt-0 sm:grid-cols-6 sm:gap-x-6 sm:p-6 sm:pt-0">
        <div className="sm:col-span-6">
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('product.taxonomy.edit-taxonomy-helper')}
          </p>
        </div>
        <div className="sm:col-span-3">
          <div className="">
            <label htmlFor="universe" className="block text-sm font-medium text-blue-gray-900">
              {t('product.taxonomy.form.universe')}
            </label>
            <div className="mt-1">
              <MutliCreatable
                value={getValues('universe')}
                name={'universe'}
                control={control}
                options={universes}
                loading={loadingUniverses}
                onChange={() => fetchNewCategories()}
                error={errorUniverses}
              />
            </div>
          </div>

          <div className="mt-3">
            <label htmlFor="category" className="block text-sm font-medium text-blue-gray-900">
              {t('product.taxonomy.form.category')}
            </label>
            <div className="mt-1">
              <MutliCreatable
                value={getValues('category')}
                name={'category'}
                control={control}
                options={categories}
                loading={loadingCategories}
                onChange={() => fetchNewTypes()}
                error={
                  errorCategories && (defaultProductValues.universe?.value || dirtyFields.universe)
                    ? errorCategories
                    : null
                }
              />
            </div>
          </div>

          <div className="mt-3">
            <label htmlFor="type" className="block text-sm font-medium text-blue-gray-900">
              {t('product.taxonomy.form.type')}
            </label>
            <div className="relative mt-1">
              <MutliCreatable
                value={getValues('type')}
                name={'type'}
                control={control}
                options={types}
                loading={loadingTypes}
                onChange={() => setValidTaxonomy(true)}
                error={
                  errorType && (defaultProductValues.category?.value || dirtyFields.category)
                    ? errorType
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-end bg-gray-50 px-4 py-3 text-right sm:px-6">
        <button
          onClick={() => onCancel()}
          disabled={loading}
          type="button"
          className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-blue-gray-900 shadow-sm hover:bg-blue-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2">
          {t('global.actions.cancel')}
        </button>
        <button
          disabled={!isDirty || loading}
          type="submit"
          className="ml-3 inline-flex min-w-[110px] justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm 
          font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 
        focus:ring-blue-500 focus:ring-offset-2 disabled:bg-blue-600 disabled:opacity-30">
          {loading ? (
            <div className="border-white-900 h-5 w-5 animate-spin rounded-full border-b-2" />
          ) : (
            t('global.actions.save')
          )}
        </button>
      </div>
    </form>
  );
};

export default TaxonomyEdit;
