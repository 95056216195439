import { gql } from 'graphql.macro';

export const productsQuery = gql`
  query Products(
    $page: Int!
    $perPage: Int!
    $orderBy: String!
    $direction: String!
    $supplierId: ID
    $productName: String
    $departureDate: Mixed
    $arrivalDate: Mixed
    $completion: Mixed!
  ) {
    allProductsWithArrival(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      direction: $direction
      supplierId: $supplierId
      productName: $productName
      departureDate: $departureDate
      arrivalDate: $arrivalDate
      completion: $completion
    ) {
      data {
        id
        currentTranslation {
          id
          internal_title
        }
        created_at
        supplier {
          id
          name
        }
        primaryImage {
          clientUrl
        }
        missingInformations
        completionPercentage
        firstArrivalPurchaseOrder {
          id
          label
          legacyUrl
        }
        firstDepartureDate
        firstArrivalDate
      }
      paginatorInfo
    }
  }
`;
