import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { allVariantsQuery } from 'graphql/Queries/Product/Editorial/allvariants';
import { Product } from 'types/Product';
import { ID, OptionProps } from 'types/Global';
import { importEditorialInformationsMutation } from 'graphql/Mutations/Product/Editorial/importEditorialInformations';
import { editorialQuery } from 'graphql/Queries/Product/Editorial/editorial';
import SlideOver from 'components/Common/SlideOver';
import Select from 'components/Common/Select';
import Checkbox from 'components/Common/Checkbox';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setHasVariant: React.Dispatch<SetStateAction<boolean>>;
};

type ImportElement = {
  [key: string]: boolean;
};

type VariantProps = {
  id: ID;
  hasEditorialElements: ImportElement;
} & OptionProps;

const ImportSlide = ({ open, setOpen, setHasVariant }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [importElements, setImportElements] = useState<ImportElement | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<VariantProps | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { data, error, loading } = useQuery(allVariantsQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  const [importEditorialInformations, { loading: mutationLoading, error: mutationError }] =
    useMutation(importEditorialInformationsMutation);

  const { handleSubmit } = useForm();

  const close = () => {
    setSelectedVariant(null);
    setOpen(false);
  };

  useEffect(() => {
    const checkIfAnyCheckboxIsChecked = () => {
      return !!importElements && Object.values(importElements).some((value) => value);
    };
    setIsDirty(checkIfAnyCheckboxIsChecked());
  }, [importElements]);

  useEffect(() => {
    if (data?.product?.allVariants.length > 0) {
      setHasVariant(true);
    }
  }, [setHasVariant, data]);

  const options = data?.product?.allVariants.map((variant: Product) => {
    return {
      value: variant.id,
      label: variant.currentTranslation?.internal_title,
      hasEditorialElements: variant.hasEditorialElements,
    };
  });

  const onChangeCheckbox = (value: boolean, key: string) => {
    setImportElements((prevElements: ImportElement | null) => {
      return {
        ...prevElements,
        [key]: value,
      };
    });
  };

  useEffect(() => {
    if (!selectedVariant) return;
    setImportElements(selectedVariant.hasEditorialElements);
  }, [selectedVariant, setImportElements]);

  const onSubmit = handleSubmit(() => {
    if (!importElements) return;

    const filteredImportElements: { [key: string]: boolean } = {};
    Object.keys(importElements).forEach((key) => {
      if (isNaN(Number(key))) {
        filteredImportElements[key] = importElements[key];
      }
    });

    importEditorialInformations({
      variables: {
        id: params.productId,
        from_product_id: selectedVariant?.value,
        editorial_import_element: filteredImportElements,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: editorialQuery, variables: { id: params.productId } }],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.editorial.import.success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={t('product.editorial.import.title')}
        buttonText={t('global.actions.import')}
        onCancel={close}
        onSubmit={onSubmit}
        isDirty={isDirty}
        loading={mutationLoading}
        error={mutationError}>
        <div className="mt-6">
          <Select
            label={t('product.editorial.import.label')}
            options={options}
            loadingOptions={loading}
            apolloError={error}
            isClearable
            onChange={(e) => {
              setSelectedVariant(e as VariantProps);
            }}
            value={selectedVariant}
          />
          <div>
            {selectedVariant && (
              <>
                <div className="mb-6 mt-8 w-full border-t border-gray-300" />
                <div className="mb-6 font-semibold text-dark">
                  {t('global.informations-choice')}
                </div>
              </>
            )}
            {selectedVariant && (
              <div className="mt-6 space-y-2">
                {importElements &&
                  Object.entries(importElements).map(([key], index) => {
                    const variantData = data?.product?.allVariants.find(
                      (variant: VariantProps) => variant.id === selectedVariant.value
                    );
                    const isDisabled = variantData.hasEditorialElements[key] === false;
                    return (
                      <Checkbox
                        key={index}
                        label={t(`product.editorial.import.checkbox.${key}`)}
                        defaultChecked={!isDisabled}
                        disabled={isDisabled}
                        onChange={(e) => onChangeCheckbox(e.target.checked, key)}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </SlideOver>
    </>
  );
};
export default ImportSlide;
