import { gql } from 'graphql.macro';

export const updatePurchaseInformationsMutation = gql`
  mutation UpdatePurchaseInformations(
    $id: ID!
    $incoterm_id: String
    $associated_forwarder_id: String
    $port_id: String
    $payment_method_id: String
    $franco: String
    $replenishment_time: Int
    $supplier_manager_id: Int
  ) {
    updateSupplierPurchaseInformations(
      id: $id
      incoterm_id: $incoterm_id
      associated_forwarder_id: $associated_forwarder_id
      port_id: $port_id
      payment_method_id: $payment_method_id
      franco: $franco
      replenishment_time: $replenishment_time
      supplier_manager_id: $supplier_manager_id
    ) {
      id
    }
  }
`;
