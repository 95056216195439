import { gql } from 'graphql.macro';

export const productTagLogByProductAndTagQuery = gql`
  query ProductTagLogByProductAndTag($product_id: ID!, $tag_id: ID!) {
    productTagLogByProductAndTag(product_id: $product_id, tag_id: $tag_id) {
      id
      tag_value
      user {
        id
        lastname
        firstname
      }
      created_at
    }
  }
`;
