import { gql } from 'graphql.macro';

export const productCustomsNomenclatureByCodeQuery = gql`
  query ProductCustomsNomenclatureByCode($code: String!) {
    productCustomsNomenclatureByCode(code: $code) {
      id
      allParents
      isSelectable
    }
  }
`;
