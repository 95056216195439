import { gql } from 'graphql.macro';

export const createContactTypeMutation = gql`
  mutation CreateContactType($label: String!) {
    createSupplierContactType(label: $label) {
      id
      label
      active
    }
  }
`;
