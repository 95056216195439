import React, { useRef } from 'react';
import { useMutation } from '@apollo/client';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { createProductMutation } from 'graphql/Mutations/Product/createProduct';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';
import { InputTextControl } from 'components/Common/InputTextControl';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const CreateProduct = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();
  const navigate = useNavigate();

  const inputRef = useRef(null);

  const [createProduct, { loading }] = useMutation(createProductMutation);

  const schema = yup.object().shape({
    internalTitle: yup.string().trim().min(1, t('form.required')),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      internalTitle: '',
    },
  });

  const clearInput = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    createProduct({
      variables: {
        title: data.internalTitle,
      },
      onCompleted: (data) => {
        navigate(`/products/${data.createProduct.id}/preview`);
        setNotification({
          title: t('global.success'),
          message: t('product.create-product.notif.success'),
          type: 'success',
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={t('products.create')}
        buttonText={t('global.actions.create')}
        onSubmit={onSubmit}
        loading={loading}
        isDirty={isDirty}
        onCancel={clearInput}>
        <div className="pt-6">
          <InputTextControl
            control={control}
            label={t('product.create-product.name')}
            name={'internalTitle'}
            ref={inputRef}
            isFocused
          />
        </div>
      </SlideOver>
    </>
  );
};

export default CreateProduct;
