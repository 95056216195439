import { gql } from 'graphql.macro';

export const searchProductsByFiltersQuery = gql`
  query ($title: String, $search_after: Mixed, $filter: Mixed, $page: Int) {
    searchProductsByFilters(
      title: $title
      search_after: $search_after
      filter: $filter
      page: $page
    ) {
      products
      total
      lastPage
      aggs
    }
  }
`;
