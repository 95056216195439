import { gql } from 'graphql.macro';

export const createFurnitureAssemblyOptionMutation = gql`
  mutation createFurnitureAssemblyOption($label: String!) {
    createFurnitureAssemblyOption(label: $label) {
      id
      active
      keyword
      currentTranslation {
        id
        text
      }
    }
  }
`;
