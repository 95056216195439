import React from 'react';
import { useQuery } from '@apollo/client';
import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { theme, customStyle, errorCustomStyle } from 'components/Style/customStyle';
import { ecomobilierCategoriesQuery } from 'graphql/Queries/Ecotax/ecomobilierCategories';
import { SelectType } from 'types/Global';
import Select from 'react-select';

type Props = {
  control: Control;
  value: PropsValue;
  handleSelect: (value: SelectType) => void;
};

type PropsValue = {
  value: number;
  code: string;
};

type CategorySelect = {
  ecomobilierCategories?: SelectType[];
};

function EcomobilierCategorySelect({ control, value, handleSelect }: Props) {
  const { t } = useTranslation();

  const { loading, data, error } = useQuery<CategorySelect>(ecomobilierCategoriesQuery);

  const categories = data?.ecomobilierCategories?.map((category, index) => {
    return {
      value: category.id,
      code: category.code,
      label: `${category.code} - ${category.label.replaceAll('_', ' ')}`,
      padding: category.padding,
      key: index,
    };
  });

  return (
    <>
      <div className="mt-3 sm:col-span-3">
        <label className="mb-2 block text-sm font-medium leading-6 text-dark">
          {t('product.ecotax.category-type')}
          <span className="text-red-700">*</span>
        </label>
        <div className="relative mt-1">
          <Controller
            name="category"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                theme={theme}
                styles={error ? errorCustomStyle : customStyle}
                isSearchable={false}
                noOptionsMessage={() => t('global.no-option')}
                className="block w-full min-w-0  flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm"
                options={categories}
                value={categories?.find((opt) => value?.code === opt.code)}
                isDisabled={loading}
                loadingMessage={() => t('global.loading')}
                placeholder={loading ? t('global.loading') : t('global.form.select')}
                onChange={(e) => {
                  field.onChange(e);
                  handleSelect(e as SelectType);
                }}
              />
            )}
          />
          <div className="pointer-events-none absolute right-12 top-2 flex items-center">
            {error && <ExclamationCircleIcon className="h-5 w-5 text-red-500" />}
          </div>
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-700">
            {
              ((error.graphQLErrors[0].extensions as { custom: string }).custom
                ? error
                : t('global.error-msg')) as string
            }
          </p>
        )}
      </div>
    </>
  );
}

export default EcomobilierCategorySelect;
