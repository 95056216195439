export const getInitials = (name: string) => {
  const words = name.split(' ');
  if (words.length < 2) {
    return name.slice(0, 2);
  }
  return words
    .slice(0, 2)
    .map((word) => word[0])
    .join('');
};
