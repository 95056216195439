import React, { ReactElement, ComponentPropsWithoutRef, forwardRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

type InputTextProps = {
  icon?: ReactElement;
  label?: string;
  type?: 'text' | 'time';
  errorText?: string;
  error?: boolean;
  isRequired?: boolean;
} & ComponentPropsWithoutRef<'input'>;

const InputText = forwardRef<HTMLInputElement, InputTextProps>(
  ({ label, icon, type = 'text', isRequired = false, error, errorText, ...props }, ref) => {
    return (
      <div>
        {label && (
          <label htmlFor="email" className="mb-2 block text-sm font-medium leading-6 text-dark">
            {label}
            {isRequired && <span className="ml-1 text-red-700">*</span>}
          </label>
        )}
        <div className="relative">
          {icon && (
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
              {icon}
            </div>
          )}
          <input
            ref={ref}
            type={type}
            className={`
            ${icon && 'pl-10'}
            ${
              error
                ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500'
            }
            block w-full rounded-md py-1.5 text-dark shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6`}
            {...props}
          />
          {error && (
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            </div>
          )}
        </div>
        {error && <p className="mt-2 text-sm text-red-700">{errorText}</p>}
      </div>
    );
  }
);
InputText.displayName = 'InputText';

export default InputText;
