import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteContactType from './DeleteContactType';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { InformationAlert } from 'components/Common/Alerts';
import { allContactTypesQuery } from 'graphql/Queries/Supplier/Contacts/allContactTypes';
import { createContactTypeMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/createContactType';
import { updateContactTypeMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updateContactType';
import { updateContactTypeStatusMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updateContactTypeStatus';
import SelectCreatable from 'components/Common/SelectCreatable';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const ContactTypes = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditContactType, setShowEditContactType] = useState(false);
  const [showNewContactType, setShowNewContactType] = useState(false);
  const [allContactTypes, setAllContactTypes] = useState([]);
  const [contactType, setContactType] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const [getAllContact, { data, loading: loadingContactTypes }] = useLazyQuery(
    allContactTypesQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (data) {
      setAllContactTypes(
        data.allContactTypes?.map((contactType) => {
          return {
            label: contactType.label,
            value: contactType,
            key: contactType.id,
          };
        })
      );
    }
  }, [data]);

  const [createContactType, { loading: createContactTypeLoading }] =
    useMutation(createContactTypeMutation);

  const [updateContactType, { loading: updateContactTypeLoading }] =
    useMutation(updateContactTypeMutation);

  const [updateContactTypeStatus, { loading: updateContactTypeStatusLoading }] = useMutation(
    updateContactTypeStatusMutation
  );

  const onChangeContactType = (e) => {
    setShowEditContactType(true);
    setShowNewContactType(false);
    setContactType(e.value);
    reset({
      label: e.value.label,
    });
  };

  const onCreateContactType = (value) => {
    setShowEditContactType(false);
    setShowNewContactType(true);
    setContactType({
      value: value,
      label: value,
    });
    reset({
      label: value,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
    },
  });

  const onCancel = () => {
    setShowNewContactType(false);
    setContactType(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewContactType) {
      createContactType({
        refetchQueries: [
          {
            query: allContactTypesQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.createSupplierContactType;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.contacts.contact-types.created'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setContactType(data);
          setShowEditContactType(true);
          setShowNewContactType(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updateContactType({
        refetchQueries: [
          {
            query: allContactTypesQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          id: contactType.id,
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.updateSupplierContactType;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.contacts.contact-types.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setContactType(data);
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const changeActivation = (value) => {
    let message = '';
    if (value) {
      message = t('settings.suppliers.contacts.contact-types.activate');
    } else {
      message = t('settings.suppliers.contacts.contact-types.deactivate');
    }
    updateContactTypeStatus({
      refetchQueries: [
        {
          query: allContactTypesQuery,
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: contactType.id,
        active: value,
      },
      onCompleted: (dataReturn) => {
        let data = dataReturn.updateSupplierContactType;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
        });
        setContactType(data);
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  const resetAfterDelete = () => {
    setContactType(null);
    setShowEditContactType(false);
    setShowNewContactType(false);
    getAllContact();
  };

  return (
    <>
      <Card>
        <DeleteContactType
          onCloseModal={(loading) => {
            if (!loading) {
              setDisplayModal(false);
            }
          }}
          isOpen={displayModal}
          contactType={contactType}
          reset={resetAfterDelete}
        />
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.suppliers.contacts.contact-types.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.suppliers.contacts.contact-types.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.suppliers.contacts.contact-types.list')}
          </label>
          <SelectCreatable
            loadingOptions={loadingContactTypes}
            options={allContactTypes}
            onCreateOption={onCreateContactType}
            onChange={onChangeContactType}
            value={contactType}
            onFocus={() => {
              if (!allContactTypes || allContactTypes.length === 0) {
                getAllContact();
              }
            }}
          />
        </div>
        {(showEditContactType || showNewContactType) && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-4">
                <label className="text-sm font-medium text-gray-700">
                  {t('settings.suppliers.contacts.label')}
                </label>
                <div className="relative">
                  <input
                    type="text"
                    className={classNames(
                      errors.label
                        ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                      'mt-1 block h-9 w-full flex-1 rounded'
                    )}
                    {...register('label')}
                  />
                  {errors.label && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                    </div>
                  )}
                </div>
                {errors.label && <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>}
                {contactType.used && (
                  <div className="mt-4">
                    <InformationAlert>
                      {t('settings.suppliers.contacts.contact-types.used')}
                    </InformationAlert>
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                {showEditContactType && (
                  <>
                    <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                      <Button
                        type="button"
                        onClick={() => setDisplayModal(true)}
                        theme="outlineDanger"
                        disabled={updateContactTypeLoading || contactType.used}>
                        {t('global.actions.delete')}
                      </Button>
                      {contactType.active ? (
                        <Button
                          type="button"
                          onClick={() => changeActivation(false)}
                          theme="outlinePrimary"
                          disabled={updateContactTypeLoading || updateContactTypeStatusLoading}
                          loading={updateContactTypeStatusLoading}>
                          {t('global.actions.deactivate')}
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          onClick={() => changeActivation(true)}
                          theme="outlinePrimary"
                          disabled={updateContactTypeLoading || updateContactTypeStatusLoading}
                          loading={updateContactTypeStatusLoading}>
                          {t('global.actions.activate')}
                        </Button>
                      )}
                      <Button
                        disabled={
                          updateContactTypeLoading || updateContactTypeStatusLoading || !isDirty
                        }
                        type="submit"
                        theme="primary"
                        loading={updateContactTypeLoading}>
                        {t('global.actions.save')}
                      </Button>
                    </div>
                  </>
                )}
                {showNewContactType && (
                  <>
                    <Button
                      type="button"
                      onClick={onCancel}
                      theme="transparent"
                      disabled={createContactTypeLoading}>
                      {t('global.actions.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      theme="primary"
                      loading={createContactTypeLoading}
                      disabled={createContactTypeLoading}>
                      {t('global.actions.add')}
                    </Button>
                  </>
                )}
              </div>
            </form>
          </>
        )}
      </Card>
    </>
  );
};

export default ContactTypes;
