import { gql } from 'graphql.macro';

export const updateProductWeightMutation = gql`
  mutation updateProductWeight($id: ID!, $product_weight: Int!) {
    updateProductWeight(id: $id, product_weight: $product_weight) {
      id
      product_weight
    }
  }
`;
