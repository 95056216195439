import { gql } from 'graphql.macro';

export const updateAllProductDisplayPreferencesMutation = gql`
  mutation UpdateAllProductDisplayPreferences(
    $id: ID!
    $displayPreference: Int!
    $comment: String
  ) {
    updateAllProductDisplayPreferences(
      id: $id
      displayPreference: $displayPreference
      comment: $comment
    )
  }
`;
