import { gql } from 'graphql.macro';

export const updateModelStatusMutation = gql`
  mutation UpdateModel($id: ID!, $active: Boolean) {
    updateProductModel(id: $id, active: $active) {
      id
      name
      active
      isUsed
    }
  }
`;
