import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { supplierPurchaseInformationsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/purchaseInformations';
import { incotermsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/incoterms';
import { associatedForwardersQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/associatedForwarders';
import { portsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/ports';
import { paymentMethodsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/paymentMethods';
import { supplierManagersQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/supplierManagers';
import { updatePurchaseInformationsMutation } from 'graphql/Mutations/Supplier/GeneralInformations/updatePurchaseInformations';
import useNotification from 'hooks/useNotification';
import { InputTextControl } from 'components/Common/InputTextControl';
import { SelectControl } from 'components/Common/SelectControl';
import SlideOver from 'components/Common/SlideOver';
import { OptionProps } from 'types/Global';
import {
  AssociatedForwarder,
  Incoterm,
  PaymentMethod,
  Port,
  Supplier,
  SupplierManager,
} from 'types/Supplier';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supplier?: Supplier;
};

const Edit = ({ open, setOpen, supplier }: Props) => {
  const params = useParams();
  const { setNotification } = useNotification();

  const [
    updatePurchaseInformations,
    { loading: mutationLoading, error: mutationError, reset: resetMutation },
  ] = useMutation(updatePurchaseInformationsMutation);

  const schema = yup.object().shape({
    supplier_manager: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required(t('form.required')),
    incoterm: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    associated_forwarder: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    port: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    payment_method: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
    franco: yup
      .string()
      .max(60, t('form.max-x-characters', { x: 60 }))
      .nullable(),
    replenishment_time: yup
      .number()
      .integer(t('form.type-error.integer'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
  });

  const {
    reset,
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  const {
    loading: loadingSupplierManagers,
    error: errorSupplierManagers,
    data: dataSupplierManagers,
  } = useQuery(supplierManagersQuery);

  const supplierManagers = dataSupplierManagers?.userGroup?.activeUsers
    ?.map((supplierManager: SupplierManager) => ({
      value: supplierManager.id,
      label: `${supplierManager.firstname} ${supplierManager.lastname}`,
    }))
    .sort((a: OptionProps, b: OptionProps) => a.label.localeCompare(b.label));

  const {
    loading: loadingIncoterms,
    error: errorIncoterms,
    data: dataIncoterms,
  } = useQuery(incotermsQuery);

  const incoterms = dataIncoterms?.incoterms?.map((incoterm: Incoterm) => {
    return {
      value: incoterm.id,
      label: incoterm.label,
      isDisabled: !incoterm.active,
    };
  });

  const {
    loading: loadingAssociatedForwarders,
    error: errorAssociatedForwarders,
    data: dataAssociatedForwarders,
  } = useQuery(associatedForwardersQuery);

  const associatedForwarders = dataAssociatedForwarders?.associatedForwarders?.map(
    (associatedForwarder: AssociatedForwarder) => {
      return {
        value: associatedForwarder.id,
        label: associatedForwarder.label,
        isDisabled: !associatedForwarder.active,
      };
    }
  );

  const { loading: loadingPorts, error: errorPorts, data: dataPorts } = useQuery(portsQuery);

  const ports = dataPorts?.ports?.map((port: Port) => {
    return {
      value: port.id,
      label: port.label,
      isDisabled: !port.active,
    };
  });

  const {
    loading: loadingPaymentMethods,
    error: errorPaymentMethods,
    data: dataPaymentMethods,
  } = useQuery(paymentMethodsQuery);

  const paymentMethods = dataPaymentMethods?.supplierPaymentMethods?.map(
    (paymentMethod: PaymentMethod) => {
      return {
        value: paymentMethod.id,
        label: paymentMethod.label,
        isDisabled: !paymentMethod.active,
      };
    }
  );

  useEffect(() => {
    if (!supplier) return;
    reset({
      supplier_manager: supplier.supplierManager
        ? {
            value: supplier.supplierManager.id,
            label: `${supplier.supplierManager.firstname} ${supplier.supplierManager.lastname}`,
          }
        : null,
      incoterm: supplier.incoterm
        ? {
            value: supplier.incoterm.id,
            label: supplier.incoterm.label,
          }
        : null,
      associated_forwarder: supplier.associatedForwarder
        ? {
            value: supplier.associatedForwarder.id,
            label: supplier.associatedForwarder.label,
          }
        : null,
      port: supplier.port
        ? {
            value: supplier.port.id,
            label: supplier.port.label,
          }
        : null,
      payment_method: supplier.paymentMethod
        ? {
            value: supplier.paymentMethod.id,
            label: supplier.paymentMethod.label,
          }
        : null,
      franco: supplier.franco ?? '',
      replenishment_time: supplier.replenishment_time ?? '',
    });
  }, [supplier, reset]);

  const onCancel = () => {
    setOpen(false);
    resetMutation();
    reset();
  };

  const onSubmit = handleSubmit((data) => {
    updatePurchaseInformations({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: supplierPurchaseInformationsQuery,
          variables: { id: params.supplierId },
        },
      ],
      variables: {
        id: params.supplierId,
        supplier_manager_id: data.supplier_manager ? parseInt(data.supplier_manager.value) : null,
        incoterm_id: data.incoterm ? data.incoterm.value : null,
        associated_forwarder_id: data.associated_forwarder ? data.associated_forwarder.value : null,
        port_id: data.port ? data.port.value : null,
        payment_method_id: data.payment_method ? data.payment_method.value : null,
        franco: data.franco,
        replenishment_time: data.replenishment_time,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.purchase-infos.success'),
          type: 'success',
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('supplier.purchase-infos.edit')}
      buttonText={t('global.actions.save')}
      onCancel={onCancel}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={mutationLoading}
      error={mutationError}>
      <div className="space-y-6">
        <SelectControl
          control={control}
          name="supplier_manager"
          label={t('supplier.purchase-infos.supplier-manager')}
          options={supplierManagers}
          loadingOptions={loadingSupplierManagers}
          apolloError={errorSupplierManagers}
          isRequired
        />
        <SelectControl
          control={control}
          name="incoterm"
          label={t('supplier.purchase-infos.incoterm')}
          options={incoterms}
          loadingOptions={loadingIncoterms}
          apolloError={errorIncoterms}
          isClearable
        />
        <SelectControl
          control={control}
          name="associated_forwarder"
          label={t('supplier.purchase-infos.associated-forwarder')}
          options={associatedForwarders}
          loadingOptions={loadingAssociatedForwarders}
          apolloError={errorAssociatedForwarders}
          isClearable
        />
        <SelectControl
          control={control}
          name="port"
          label={t('supplier.purchase-infos.port')}
          options={ports}
          loadingOptions={loadingPorts}
          apolloError={errorPorts}
          isClearable
        />
        <SelectControl
          control={control}
          name="payment_method"
          label={t('supplier.purchase-infos.payment-method')}
          options={paymentMethods}
          loadingOptions={loadingPaymentMethods}
          apolloError={errorPaymentMethods}
          isClearable
        />
        <InputTextControl
          control={control}
          name="franco"
          label={t('supplier.purchase-infos.franco')}
        />
        <InputTextControl
          control={control}
          name="replenishment_time"
          label={t('supplier.purchase-infos.replenishment-time')}
          addOnEnd={t('global.days')}
        />
      </div>
    </SlideOver>
  );
};

export default Edit;
