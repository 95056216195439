import { gql } from 'graphql.macro';

export const productsQuery = gql`
  query Products(
    $page: Int!
    $perPage: Int!
    $orderBy: String!
    $direction: String!
    $productName: String
    $photoValidated: Boolean
    $photoEditionValidated: Boolean
    $integrationValidated: Boolean
    $arrivalDate: Mixed
    $completion: Mixed!
  ) {
    allProductsMarketingIntegration(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      direction: $direction
      productName: $productName
      photoValidated: $photoValidated
      photoEditionValidated: $photoEditionValidated
      integrationValidated: $integrationValidated
      arrivalDate: $arrivalDate
      completion: $completion
    ) {
      data {
        id
        currentTranslation {
          id
          internal_title
        }
        primaryImage {
          clientUrl
        }
        created_at
        supplier {
          id
          name
        }
        missingInformations
        completionPercentage
        firstArrivalPurchaseOrder {
          id
          label
          legacyUrl
        }
        firstArrivalDate
        weeksUntilArrival
        physicalStock
        marketingIntegration {
          id
          comment
          validated_photo
          photo_comment
          validated_photo_edition
          photo_edition_comment
          validated_integration
        }
      }
      paginatorInfo
    }
  }
`;
