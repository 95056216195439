import { gql } from 'graphql.macro';

export const createSupplierMutation = gql`
  mutation CreateSupplier($billing_name: String!) {
    createSupplier(billing_name: $billing_name) {
      id
      name
      billing_name
    }
  }
`;
