import { gql } from 'graphql.macro';

export const updateMaterialMutation = gql`
  mutation UpdateMaterial($id: ID!, $label: String, $parent_id: ID) {
    updateProductMaterial(id: $id, label: $label, parent_id: $parent_id) {
      id
      label
      parent {
        id
        label
      }
      children {
        id
      }
      is_active
      isUsed
    }
  }
`;
