import { gql } from 'graphql.macro';

export const furnitureAssemblyOptionsQuery = gql`
  query furnitureAssemblyOptions {
    furnitureAssemblyOptions {
      id
      keyword
      active
      isUsed
      currentTranslation {
        id
        text
      }
      translations {
        id
        lang
        text
      }
    }
  }
`;
