import React, { useState } from 'react';
import { t } from 'i18next';
import Title from 'components/Common/Title';
import { useQuery } from '@apollo/client';
import { productActiveOffersQuery } from 'graphql/Queries/Product/Offers/activeOffers';
import { useParams } from 'react-router-dom';
import { ProductQuery } from 'types/Query';
import Offer from './Offer';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Edit from './Edit';
import { Product, ProductOffer } from 'types/Product';
import { Button } from 'components/Common/Button';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import Import from './Import';
import { FRANCE_ID } from 'global';
import { useProductContextType } from 'components/Contexts/ProductContext';

const Index = () => {
  const params = useParams();
  const { productExists } = useProductContextType();

  const [openEdit, setOpenEdit] = useState(false);
  const [offerToEdit, setOfferToEdit] = useState<ProductOffer | null>(null);
  const [hasVariant, setHasVariant] = useState<boolean>(false);
  const [openImport, setOpenImport] = useState<boolean>(false);

  const { data, loading, error } = useQuery<ProductQuery>(productActiveOffersQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId,
    },
  });

  const product = data?.product;

  const isRewardedForWaiting = (product?: Product, countryId?: number) => {
    if (!product || !countryId) {
      return false;
    }
    return countryId === FRANCE_ID
      ? product.is_rewarded_for_waiting
      : product.is_rewarded_for_waiting_foreign;
  };

  if (!productExists) return <></>;

  return (
    <>
      <Edit
        ecotax={product?.ecotax}
        offer={offerToEdit}
        isRewardedForWaiting={isRewardedForWaiting(
          product,
          parseInt(offerToEdit?.country.id as string)
        )}
        open={openEdit}
        setOpen={setOpenEdit}
      />
      <Import setOpen={setOpenImport} open={openImport} setHasVariant={setHasVariant} />
      <Title
        title={t('product.offers.title')}
        addOnEnd={
          hasVariant && (
            <Button theme="secondary" onClick={() => setOpenImport(true)}>
              <>
                <ArrowDownOnSquareStackIcon className="mr-3 h-6 w-6" />
                <span className="leading-6">{t('global.import')}</span>
              </>
            </Button>
          )
        }
      />
      {error ? (
        <ApolloErrorAlert error={error} />
      ) : (
        <div className="-mx-4 mt-8 sm:-mx-0">
          <table className="min-w-full divide-y divide-gray-300 text-sm">
            <thead className="text-left font-semibold uppercase text-secondary">
              <tr>
                <th scope="col" className="px-3 py-3.5">
                  {t('product.offers.table.country')}
                </th>
                <th scope="col" className="px-3 py-3.5">
                  {t('product.offers.table.price')}
                </th>
                <th scope="col" className="truncate px-3 py-3.5">
                  {t('product.offers.table.discount')}
                </th>
                <th scope="col" className="hidden px-3 py-3.5 lg:table-cell">
                  {t('product.offers.table.discount-type')}
                </th>
                <th scope="col" className="hidden px-3 py-3.5 2xl:table-cell">
                  {t('product.offers.table.period')}
                </th>
                <th />
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 text-left font-medium text-dark">
              {loading ? (
                <tr>
                  <td className="px-3 py-3.5">
                    <div className="skeleton h-6" />
                  </td>
                  <td className="px-3 py-3.5">
                    <div className="skeleton h-6" />
                  </td>
                  <td className="px-3 py-3.5">
                    <div className="skeleton h-6" />
                  </td>
                  <td className="hidden px-3 py-3.5 lg:table-cell">
                    <div className="skeleton h-6" />
                  </td>
                  <td className="hidden px-3 py-3.5 2xl:table-cell">
                    <div className="skeleton h-6" />
                  </td>
                  <td />
                </tr>
              ) : (
                product?.activeOffers.map((offer, i) => (
                  <Offer
                    key={i}
                    offer={offer}
                    setOpenEdit={setOpenEdit}
                    setOfferToEdit={setOfferToEdit}
                    isRewardedForWaiting={isRewardedForWaiting(
                      product,
                      parseInt(offer.country.id as string)
                    )}
                  />
                ))
              )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default Index;
