import { gql } from 'graphql.macro';

export const deleteCollectionMutation = gql`
  mutation DeleteCollection($id: ID!) {
    deleteProductCollection(id: $id) {
      id
      label
    }
  }
`;
