import { gql } from 'graphql.macro';

export const updateCollectionStatusMutation = gql`
  mutation UpdateCollection($id: ID!, $active: Boolean) {
    updateProductCollection(id: $id, active: $active) {
      id
      label
      active
      isUsed
    }
  }
`;
