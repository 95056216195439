import { gql } from 'graphql.macro';

export const allVariantsQuery = gql`
  query allVariants($id: ID!) {
    product(id: $id) {
      id
      allVariants {
        id
        hasEditorialElements
        currentTranslation {
          title
          internal_title
        }
      }
    }
  }
`;
