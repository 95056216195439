import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { productSupplierQuery } from 'graphql/Queries/Product/Supplier/productSupplier';
import { historyPurchasePriceQuery } from 'graphql/Queries/Product/Supplier/historyPurchasePrice';
import { updateInfosMutation } from 'graphql/Mutations/Product/PurchaseInformation/updateInfos';
import { Product } from 'types/Product';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import InformationsForm from './InformationsForm';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  open: boolean;
  product?: Product;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isNewSupplier?: boolean;
};

const SelectSupplierSlide = ({ open, setOpen, product, isNewSupplier = false }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const close = () => {
    setOpen(false);
    reset();
  };

  const [updateInfos, { loading: mutationLoading, error: mutationError }] =
    useMutation(updateInfosMutation);

  const schema = yup.object().shape({
    supplier: yup.object().required(t('form.required')).typeError(t('form.number.positive')),
    price: yup
      .number()
      .typeError(t('form.number.positive'))
      .min(0, t('form.number.positive'))
      .test({
        name: 'requiredIfDirtyCurrencyCode',
        test: function (value) {
          if (dirtyFields.currency_code && (value === undefined || value === null)) {
            throw this.createError({
              message: t('form.number.positive'),
            });
          }

          return true;
        },
      })
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    buy_unit: yup
      .number()
      .integer(t('form.number.integer'))
      .typeError(t('form.number.integer'))
      .min(0, t('form.number.positive'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    sell_unit: yup
      .number()
      .integer(t('form.number.integer'))
      .typeError(t('form.number.integer'))
      .min(0, t('form.number.positive'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    minimum_order_quantity: yup
      .number()
      .integer(t('form.number.integer'))
      .typeError(t('form.number.integer'))
      .min(0, t('form.number.positive'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    model_minimum_order_quantity: yup
      .number()
      .integer(t('form.number.integer'))
      .typeError(t('form.number.integer'))
      .min(0, t('form.number.positive'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    replenishment_time: yup
      .number()
      .integer(t('form.number.integer'))
      .typeError(t('form.number.integer'))
      .min(0, t('form.number.positive'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    currency_code: yup.string().test({
      name: 'currency_code',
      test: function (value) {
        if (value === '0' && dirtyFields.price) {
          return false;
        }
        if (value === '0' && getValues('price')) {
          return false;
        }
        return true;
      },
      message: t('form.must-choose-currency'),
    }),
    comment: yup
      .string()
      .test({
        name: 'comment',
        test: function (value) {
          if (
            !isNewSupplier &&
            (dirtyFields.price || dirtyFields.supplier || dirtyFields.currency_code)
          ) {
            return !!value;
          }
          return true;
        },
        message: t('form.required-change-price-supplier'),
      })
      .nullable(),
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    register,
    setValue,
    formState: { isDirty, dirtyFields },
  } = useForm<FieldValues>({ resolver: yupResolver<FieldValues>(schema) });

  useEffect(() => {
    if (!product) return;
    reset({
      buy_unit: product.taxonomy?.buy_unit ? `${product.taxonomy.buy_unit}` : '',
      currency_code: product.currentSupplying?.currency?.code ?? '0',
      factory_pn: product.currentSupplying?.supplier_product_id
        ? product.currentSupplying?.supplier_product_id
        : '',
      is_grouped: product.currentSupplying?.is_grouped ?? false,
      model_minimum_order_quantity: product.model?.minimum_order_quantity
        ? `${product.model?.minimum_order_quantity}`
        : '',
      minimum_order_quantity: product.minimum_order_quantity
        ? `${product.minimum_order_quantity}`
        : '',
      price: product.currentSupplying?.price ? `${product.currentSupplying.price}` : '',
      replenishment_time: product.replenishment_time ? `${product.replenishment_time}` : '',
      sell_unit: product.taxonomy?.sell_unit ? `${product.taxonomy.sell_unit}` : '',
      supplier: product.currentSupplying?.supplier
        ? {
            value: product.currentSupplying.supplier.id,
            label: product.currentSupplying.supplier.name,
            isDisabled: !product.currentSupplying.supplier.active,
          }
        : null,
      comment: '',
    });
  }, [reset, product, getValues]);

  const onSubmit = handleSubmit((data) => {
    updateInfos({
      variables: {
        id: params.productId,
        params: data,
        supplier_id: data.supplier.value,
      },
      refetchQueries: [
        {
          query: productSupplierQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: historyPurchasePriceQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.purchase-information.informations.success-update'),
          type: 'success',
        });

        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.purchase-information.informations.choose-supplier')}
      buttonText={t('global.actions.save')}
      size="max-w-2xl"
      onCancel={close}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={mutationLoading}
      error={mutationError}>
      <>
        <InformationsForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          register={register}
          dirtyFields={dirtyFields}
          hasModel={!!product?.model}
          hasSupplier={!!product?.currentSupplying}
        />
      </>
    </SlideOver>
  );
};
export default SelectSupplierSlide;
