import React, { useState } from 'react';

interface IconCollapseProps {
  isCollapsed: boolean;
}

const IconCollapse = ({ isCollapsed }: IconCollapseProps) => {
  const [hover, setHover] = useState(false);

  const handleMouseEnter = () => {
    setHover(true);
  };

  const handleMouseLeave = () => {
    setHover(false);
  };

  return (
    <div className="cursor-pointer" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {!hover ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="38"
          height="38"
          viewBox="0 0 38 38"
          className="fill-none">
          <path
            id="Content"
            d="M34,38H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h30c2.2,0,4,1.8,4,4v30C38,36.2,36.2,38,34,38z"
          />
          <g id="Icon" transform="translate(4.835 4)">
            <path
              className={`${isCollapsed ? 'stroke-[#5a5755]' : 'stroke-white'} stroke-[1.5]`}
              d="M22.3,24.2h-16c-0.7,0-1.2-0.6-1.2-1.2V7c0-0.7,0.6-1.2,1.2-1.2h16c0.7,0,1.2,0.6,1.2,1.2v16C23.6,23.7,23,24.2,22.3,24.2z"
            />
            <path
              className={`${isCollapsed ? 'stroke-[#5a5755]' : 'stroke-white'} stroke-[1.5]`}
              d="M10.3,24.2h-4c-0.7,0-1.2-0.6-1.2-1.2V7c0-0.7,0.6-1.2,1.2-1.2h4c0.7,0,1.2,0.6,1.2,1.2v16C11.6,23.7,11,24.2,10.3,24.2z"
            />
          </g>
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38">
          <path
            id="Content"
            className={`${isCollapsed ? 'opacity-25' : ''} fill-primary-300`}
            d="M34,38H4c-2.2,0-4-1.8-4-4V4c0-2.2,1.8-4,4-4h30c2.2,0,4,1.8,4,4v30C38,36.2,36.2,38,34,38z"
          />
          <g id="Icon" transform="translate(4.835 4)">
            <path
              className={`${
                isCollapsed ? 'stroke-[#5a5755]' : 'stroke-white'
              } fill-none stroke-[1.5]`}
              d="M22.3,24.2h-16c-0.7,0-1.2-0.6-1.2-1.2V7c0-0.7,0.6-1.2,1.2-1.2h16c0.7,0,1.2,0.6,1.2,1.2v16
		C23.6,23.7,23,24.2,22.3,24.2z"
            />
            <path
              className={`${isCollapsed ? 'fill-[#5a5755]' : 'fill-white'} stroke-[1.5]`}
              d="M10.3,24.2h-4c-0.7,0-1.2-0.6-1.2-1.2V7c0-0.7,0.6-1.2,1.2-1.2h4c0.7,0,1.2,0.6,1.2,1.2v16
		C11.6,23.7,11,24.2,10.3,24.2z"
            />
          </g>
        </svg>
      )}
    </div>
  );
};

export default IconCollapse;
