import { gql } from 'graphql.macro';

export const importPackagingInformationsMutation = gql`
  mutation ImportPackagingInformations(
    $id: ID!
    $from_product_id: ID!
    $packaging_import_element: Mixed!
  ) {
    importPackagingInformations(
      id: $id
      from_product_id: $from_product_id
      packaging_import_element: $packaging_import_element
    ) {
      id
    }
  }
`;
