import { gql } from 'graphql.macro';

export const switchIsFragileProductMutation = gql`
  mutation SwitchIsFragileProduct($id: ID!, $is_fragile: Boolean!, $tag_id: Int!) {
    switchIsFragileProduct(id: $id, is_fragile: $is_fragile, tag_id: $tag_id) {
      id
      is_fragile
    }
  }
`;
