import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateProductWeightMutation } from 'graphql/Mutations/Product/updateProductWeight';
import SlideOver from 'components/Common/SlideOver';
import { InputTextControl } from 'components/Common/InputTextControl';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  value: number;
};

const UpdateProductWeight = ({ open, setOpen, value }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();

  const [updateProductWeigth, { loading, error }] = useMutation(updateProductWeightMutation);

  const schema = yup.object().shape({
    productWeight: yup
      .number()
      .integer(t('form.type-error.integer'))
      .min(1, t('form.number.positive'))
      .typeError(t('form.type-error.number')),
  });

  const {
    formState: { isDirty },
    reset,
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      productWeight: value ?? null,
    },
  });

  useEffect(() => {
    reset({ productWeight: value });
  }, [value, reset]);

  const onSubmit = handleSubmit((data) => {
    updateProductWeigth({
      variables: {
        id: params.productId,
        product_weight: data.productWeight,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        reset({ productWeight: data.productWeight }),
          setNotification({
            title: t('global.success'),
            message: t('product.features.updated'),
            type: 'success',
          });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    reset();
    setOpen(false);
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.features.edit')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDirty={isDirty}
      loading={loading}
      error={error}>
      <>
        <div className="space-y-6">
          <span className="text-sm text-dark">
            <Trans
              i18nKey="product.features.edit-subtitle"
              values={{ feature: t('product.features.product-weight') }}>
              <span className="font-semibold" />
            </Trans>
          </span>
          <div>
            <InputTextControl
              control={control}
              name="productWeight"
              label={t('product.features.value')}
              addOnEnd={'g'}
            />
          </div>
        </div>
      </>
    </SlideOver>
  );
};
export default UpdateProductWeight;
