import { gql } from 'graphql.macro';

export const productHeaderQuery = gql`
  query productHeader($id: ID!) {
    product(id: $id) {
      id
      created_at
      orderableQuantity
      primaryImage {
        clientUrl
      }
      temporaryImage {
        id
        url
      }
      active
      creator {
        firstname
        lastname
      }
      currentTranslation {
        internal_title
      }
      missingInformations
      completionPercentage
      visibleActiveSiteURLs
      firstOnlineInfos {
        online_date
        user_name
      }
      exportableElements
    }
  }
`;
