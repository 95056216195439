import { gql } from 'graphql.macro';

export const switchIsGroupableProductMutation = gql`
  mutation SwitchIsGroupableProduct($id: ID!, $is_groupable: Boolean!, $tag_id: Int!) {
    switchIsGroupableProduct(id: $id, is_groupable: $is_groupable, tag_id: $tag_id) {
      id
      is_groupable
    }
  }
`;
