import { gql } from 'graphql.macro';

export const switchProductDestockMutation = gql`
  mutation SwitchProductDestock($id: ID!, $isDestock: Boolean!, $tag_id: Int!) {
    switchProductDestock(id: $id, isDestock: $isDestock, tag_id: $tag_id) {
      id
      to_destock
      active
    }
  }
`;
