import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { FieldValues, useForm } from 'react-hook-form';
import { updateTitleMutation } from 'graphql/Mutations/Product/Editorial/updateTitle';
import { ID } from 'types/Global';
import { editorialQuery } from 'graphql/Queries/Product/Editorial/editorial';
import { useParams } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import { createProductTranslationMutation } from 'graphql/Mutations/Product/Editorial/createProductTranslation';
import Card from 'components/Common/Card';
import { InputTextControl } from 'components/Common/InputTextControl';
import { Button } from 'components/Common/Button';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

interface TitleEditProps {
  title: string | null;
  translationId: ID | null;
  isLoading: boolean;
  setTranslationRequestDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  langId: ID;
}

const TitleEdit = ({
  title,
  translationId,
  isLoading,
  setTranslationRequestDisabled,
  langId,
}: TitleEditProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification, setError } = useNotification();

  const [createProductTranslation, { loading: createProductTranslationLoading }] = useMutation(
    createProductTranslationMutation
  );
  const [updateTitle, { loading: updateTitleLoading }] = useMutation(updateTitleMutation);

  const mutationLoading = createProductTranslationLoading || updateTitleLoading;

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    control,
  } = useForm<FieldValues>();

  useEffect(() => {
    if (title) {
      setTranslationRequestDisabled(false);
    }
    reset({ title: title ?? '' });
  }, [title, reset, setTranslationRequestDisabled]);

  const onSubmit = handleSubmit(async (data) => {
    let translationIdToUpdate = translationId;
    if (!translationIdToUpdate) {
      await new Promise<void>((resolve, reject) => {
        createProductTranslation({
          variables: {
            product_id: params.productId,
            lang_id: langId,
          },
          onCompleted: (res) => {
            translationIdToUpdate = res.createProductTranslation.id;
            resolve();
          },
          onError: (error) => {
            setError(error);
            reject(error);
          },
        });
      });
    }
    setTranslationRequestDisabled(true);
    updateTitle({
      refetchQueries: [
        {
          query: editorialQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: translationIdToUpdate,
        title: data.title,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.editorial.title-edit.notif-success'),
          type: 'success',
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Card title={t('product.editorial.title-edit.title')}>
        <div className="space-y-6">
          <InputTextControl
            control={control}
            name="title"
            label={t('product.editorial.title-edit.label')}
            placeholder={t('product.editorial.title-edit.placeholder')}
            isLoading={isLoading}
          />
          <div className="flex justify-end">
            <Button
              loading={mutationLoading}
              disabled={!isDirty || isLoading || mutationLoading}
              type="submit">
              {t('global.actions.save')}
            </Button>
          </div>
        </div>
      </Card>
    </form>
  );
};

export default TitleEdit;
