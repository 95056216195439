import { gql } from 'graphql.macro';

export const stylesQuery = gql`
  query Styles {
    productStyles {
      id
      label
      active
      isUsed
    }
  }
`;
