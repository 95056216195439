import { gql } from 'graphql.macro';

export const updateColorStatusMutation = gql`
  mutation UpdateColorStatus(
    $id: ID!
    $parent_id: ID
    $label_gb: String
    $hex_code: String
    $is_active: Boolean
  ) {
    updateProductColor(
      id: $id
      parent_id: $parent_id
      label_gb: $label_gb
      hex_code: $hex_code
      is_active: $is_active
    ) {
      id
      label
      label_gb
      parent {
        id
        label
      }
      children {
        id
      }
      hex_code
      is_active
      isUsed
    }
  }
`;
