import { gql } from 'graphql.macro';

export const ecomobilierPaddingMaterialsQuery = gql`
  query {
    ecomobilierPaddingMaterials {
      id
      code
      label
    }
  }
`;
