import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeletePort from './DeletePort';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { InformationAlert } from 'components/Common/Alerts';
import { portsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/ports';
import { createPortMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/createPort';
import { updatePortMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updatePort';
import { updatePortStatusMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updatePortStatus';
import SelectCreatable from 'components/Common/SelectCreatable';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const Ports = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditPort, setShowEditPort] = useState(false);
  const [showNewPort, setShowNewPort] = useState(false);
  const [allPorts, setAllPorts] = useState([]);
  const [port, setPort] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const [getAllPorts, { data, loading: loadingPorts }] = useLazyQuery(portsQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setAllPorts(
        data.ports?.map((port) => {
          return {
            label: port.label,
            value: port,
            key: port.id,
          };
        })
      );
    }
  }, [data]);

  const [createPort, { loading: createPortLoading }] = useMutation(createPortMutation);

  const [updatePort, { loading: updatePortLoading }] = useMutation(updatePortMutation);
  const [updatePortStatus, { loading: updatePortStatusLoading }] =
    useMutation(updatePortStatusMutation);

  const onChangePort = (e) => {
    setShowEditPort(true);
    setShowNewPort(false);
    setPort(e.value);
    reset({
      label: e.value.label,
    });
  };

  const onCreatePort = (value) => {
    setShowEditPort(false);
    setShowNewPort(true);
    setPort({
      value,
      label: value,
    });
    reset({
      label: value,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
    },
  });

  const onCancel = () => {
    setShowNewPort(false);
    setPort(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewPort) {
      createPort({
        refetchQueries: [
          {
            query: portsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.createPort;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.ports.created'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setPort(data);
          setShowEditPort(true);
          setShowNewPort(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updatePort({
        refetchQueries: [
          {
            query: portsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          id: port.id,
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.updatePort;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.ports.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setPort(data);
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const resetAfterDelete = () => {
    setPort(null);
    setShowEditPort(false);
    setShowNewPort(false);
  };

  const changeActivation = (value) => {
    let message = '';
    if (value) {
      message = t('settings.suppliers.purchase-infos.ports.activate');
    } else {
      message = t('settings.suppliers.purchase-infos.ports.deactivate');
    }
    updatePortStatus({
      refetchQueries: [
        {
          query: portsQuery,
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: port.id,
        active: value,
      },
      onCompleted: (dataReturn) => {
        let data = dataReturn.updatePort;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
        });
        setPort(data);
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  return (
    <>
      <Card>
        <DeletePort
          onCloseModal={(loading) => {
            if (!loading) {
              setDisplayModal(false);
            }
          }}
          isOpen={displayModal}
          port={port}
          reset={resetAfterDelete}
        />
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.suppliers.purchase-infos.ports.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.suppliers.purchase-infos.ports.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.suppliers.purchase-infos.ports.list')}
          </label>
          <SelectCreatable
            loadingOptions={loadingPorts}
            options={allPorts}
            onCreateOption={onCreatePort}
            onChange={onChangePort}
            value={port}
            onFocus={() => {
              if (!allPorts || allPorts.length === 0) {
                getAllPorts();
              }
            }}
          />
        </div>
        {(showEditPort || showNewPort) && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-4">
                <label className="text-sm font-medium text-gray-700">
                  {t('settings.suppliers.purchase-infos.name')}
                </label>
                <div className="relative">
                  <input
                    type="text"
                    className={classNames(
                      errors.label
                        ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                      'mt-1 block h-9 w-full flex-1 rounded'
                    )}
                    {...register('label')}
                  />
                  {errors.label && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                    </div>
                  )}
                </div>
                {errors.label && <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>}
                {port.used && (
                  <div className="mt-4">
                    <InformationAlert>
                      {t('settings.suppliers.purchase-infos.ports.used')}
                    </InformationAlert>
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                {showEditPort && (
                  <>
                    <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                      <Button
                        type="button"
                        theme="outlineDanger"
                        onClick={() => setDisplayModal(true)}
                        disabled={updatePortLoading || port.used}>
                        {t('global.actions.delete')}
                      </Button>
                      {port.active ? (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          onClick={() => changeActivation(false)}
                          loading={updatePortStatusLoading}
                          disabled={updatePortLoading}>
                          {t('global.actions.deactivate')}
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          onClick={() => changeActivation(true)}
                          loading={updatePortStatusLoading}
                          disabled={updatePortLoading}>
                          {t('global.actions.activate')}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        theme="primary"
                        disabled={updatePortLoading || !isDirty}
                        loading={updatePortLoading}>
                        {t('global.actions.save')}
                      </Button>
                    </div>
                  </>
                )}
                {showNewPort && (
                  <>
                    <Button
                      type="button"
                      theme="transparent"
                      onClick={onCancel}
                      disabled={createPortLoading}>
                      {t('global.actions.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      theme="primary"
                      disabled={createPortLoading}
                      loading={createPortLoading}>
                      {t('global.actions.add')}
                    </Button>
                  </>
                )}
              </div>
            </form>
          </>
        )}
      </Card>
    </>
  );
};

export default Ports;
