import { gql } from 'graphql.macro';

export const productUniversesQuery = gql`
  query ProductUniverses {
    productUniverses {
      id
      label
      is_active
    }
  }
`;
