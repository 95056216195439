import React from 'react';
import { useForm } from 'react-hook-form';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSearchContextType } from '../Contexts/SearchContext';

const SearchBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { register, handleSubmit, reset } = useForm();
  const { setSearchProduct } = useSearchContextType();

  const onSubmit = handleSubmit((values) => {
    if (values['search-field'].length) {
      setSearchProduct(values['search-field']);

      navigate({
        pathname: '/search',
      });
    }
    reset({ 'search-field': '' });
  });
  return (
    <form className="relative flex flex-1" onSubmit={onSubmit}>
      <label htmlFor="search-field" className="sr-only">
        {t('global.search')}
      </label>
      <MagnifyingGlassIcon
        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
        aria-hidden="true"
      />
      <input
        id="search-field"
        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
        placeholder={t('global.search')}
        type="search"
        {...register('search-field')}
      />
    </form>
  );
};

export default SearchBar;
