import React from 'react';
import { Navigate, Routes, Route, Outlet } from 'react-router-dom';
import { UseAuthToken } from './AuthToken';
import Home from './components/Home/Index';
import FutureArrivals from './components/FutureArrivals/Index';
import MarketingIntegration from './components/MarketingIntegration/Index';
// product
import ProductLayout from './components/Product/ProductLayout';
import ProductIndex from './components/Product/Index';
import ProductOffers from './components/Product/Offers/Index';
import ProductFeatures from './components/Product/Features/Index';
import ProductImagesTypes from './components/Product/Images/OtherImage/Index';
import ProductImageSticker from './components/Product/Images/Sticker/Index';
import ProductDocuments from './components/Product/Documents/Index';
import ProductCustomsNomenclature from './components/Product/CustomsNomenclature/Index';
import ProductEcotax from './components/Product/Ecotax/Index';
import ProductPackaging from './components/Product/Packaging/Index';
import ProductMaterialsAndColors from './components/Product/MaterialsAndColors/Index';
import ProductVisibility from './components/Product/Visibility/Index';
import ProductPurchaseInformation from 'components/Product/PurchaseInformation/Index';
import ProductTaxonomy from './components/Product/Taxonomy/Index';
import ProductClassification from './components/Product/Classification/Index';
import ProductsIndex from './components/Products/Index';
import BrandIndex from 'components/Product/Brand/Index';
import CombinationIndex from './components/Product/ProductCombination/Index';
import Editorial from './components/Product/Editorial/Index';
// supplier
import SupplierLayout from 'components/Supplier/SupplierLayout';
import SupplierIndex from 'components/Suppliers/Index';
import SupplierGeneralInformations from 'components/Supplier/GeneralInformations/Index';
import ContactIndex from 'components/Supplier/Contacts/Index';
import ProductionSiteIndex from 'components/Supplier/ProductionSites/Index';
// product settings
import SettingsLayout from 'components/Settings/SettingsLayout';
import DesignersSettings from 'components/Settings/Designers/Index';
import TaxonomySettings from 'components/Settings/Taxonomy/Index';
import ModelsSettings from 'components/Settings/Products/ModelsAndCollections/Index';
import MaterialsAndColorsSettings from 'components/Settings/Products/MaterialsAndColors/Index';
import AssemblyInstructionsSettings from 'components/Settings/Products/AssemblyInstructions/Index';
import PackagingSettings from 'components/Settings/Products/Packagings/Index';
// import GoalsIndexSettings from 'components/Settings/Products/Goals/Index';
// supplier settings
import ContactSettings from 'components/Settings/Suppliers/Contacts/Index';
import PurchaseInformationSettings from 'components/Settings/Suppliers/PurchaseInformations/Index';
// others
import Login from 'components/Login/Login';
import NotFound from 'NotFound';
//? Uncomment this to use maintenance page
// import Maintenance from 'Maintenance';
import { DefaultLayout } from 'components/Layout/DefaultLayout';
import { BlankLayout } from 'components/Layout/BlankLayout';

const AppRoutes = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, token, removeAuthToken, isLogged] = UseAuthToken();

  return (
    <Routes>
      <Route
        path="/login"
        element={
          isLogged() ? (
            <Navigate to="/" />
          ) : (
            <BlankLayout>
              <Login />
            </BlankLayout>
          )
        }
      />
      <Route
        path="/"
        element={
          isLogged() ? (
            <DefaultLayout>
              <Home />
            </DefaultLayout>
          ) : (
            <Navigate to="/login" />
          )
        }
      />

      {isLogged() ? (
        <>
          <Route
            path="products"
            element={
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            }>
            <Route path=":productId" element={<ProductLayout />}>
              <Route index element={<ProductIndex />} />
              <Route path="preview" element={<ProductIndex />} />
              <Route path="taxonomy" element={<ProductTaxonomy />} />
              <Route path="model-style" element={<ProductClassification />} />
              <Route path="visibility" element={<ProductVisibility />} />
              <Route path="photos" element={<ProductImagesTypes />} />
              <Route path="sticker" element={<ProductImageSticker />} />
              <Route path="features" element={<ProductFeatures />} />
              <Route path="materials-and-colors" element={<ProductMaterialsAndColors />} />
              <Route path="documents" element={<ProductDocuments />} />
              <Route path="customs-nomenclature" element={<ProductCustomsNomenclature />} />
              <Route path="ecotax" element={<ProductEcotax />} />
              <Route path="offers" element={<ProductOffers />} />
              <Route path="combinations" element={<CombinationIndex />} />
              <Route path="brand" element={<BrandIndex />} />
              <Route path="packaging" element={<ProductPackaging />} />
              <Route path="purchase-information" element={<ProductPurchaseInformation />} />
              <Route path="editorial" element={<Editorial />} />
            </Route>
          </Route>
          <Route
            path="suppliers"
            element={
              <DefaultLayout>
                <Outlet />
              </DefaultLayout>
            }>
            <Route index element={<SupplierIndex />} />
            <Route path=":supplierId" element={<SupplierLayout />}>
              <Route index element={<SupplierGeneralInformations />} />
              <Route path="general-informations" element={<SupplierGeneralInformations />} />
              <Route path="contacts" element={<ContactIndex />} />
              <Route path="production-sites" element={<ProductionSiteIndex />} />
            </Route>
          </Route>
          <Route
            path="future-arrivals"
            element={
              <DefaultLayout>
                <FutureArrivals />
              </DefaultLayout>
            }
          />
          <Route
            path="marketing-integration"
            element={
              <DefaultLayout>
                <MarketingIntegration />
              </DefaultLayout>
            }
          />
          <Route
            path="settings"
            element={
              <DefaultLayout>
                <SettingsLayout />
              </DefaultLayout>
            }>
            <Route index element={<TaxonomySettings />} />
            <Route path="taxonomy" element={<TaxonomySettings />} />
            <Route path="model-style" element={<ModelsSettings />} />
            <Route path="materials-and-colors" element={<MaterialsAndColorsSettings />} />
            <Route path="assembly-instructions" element={<AssemblyInstructionsSettings />} />
            <Route path="designer" element={<DesignersSettings />} />
            <Route path="packagings" element={<PackagingSettings />} />
            <Route path="contact" element={<ContactSettings />} />
            <Route path="purchase-infos" element={<PurchaseInformationSettings />} />
            {/* <Route path="creation-objectives" element={<GoalsIndexSettings />} /> */}
          </Route>
          <Route
            path="search/:query"
            element={
              <DefaultLayout>
                <ProductsIndex />
              </DefaultLayout>
            }
          />
          <Route
            path="search"
            element={
              <DefaultLayout>
                <ProductsIndex />
              </DefaultLayout>
            }
          />
        </>
      ) : (
        <Route path="*" element={<Navigate to="/login" />} />
      )}
      <Route
        path="*"
        element={
          <BlankLayout>
            <NotFound />
          </BlankLayout>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
