import { gql } from 'graphql.macro';

export const mainMaterialProductsQuery = gql`
  query mainMaterialProducts($id: ID!) {
    material(id: $id) {
      lastProducts1 {
        id
        primaryImage {
          clientUrl
        }
        currentTranslation {
          internal_title
        }
      }
    }
  }
`;
