import { gql } from 'graphql.macro';

export const allSupplierContactsQuery = gql`
  query allSupplierContacts($supplier_id: ID!) {
    allSupplierContacts(supplier_id: $supplier_id) {
      id
      name
      email
      phone
      mobile
      comment
      is_principal
      type {
        id
        label
        active
      }
    }
  }
`;
