import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { allSupplierContactsQuery } from 'graphql/Queries/Supplier/Contacts/allSupplierContacts';
import { updatePrincipalContactMutation } from 'graphql/Mutations/Supplier/GeneralInformations/updatePrincipalContact';
import { SupplierContact } from 'types/Supplier';
import { SelectControl } from 'components/Common/SelectControl';
import SlideOver from 'components/Common/SlideOver';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  contact: SupplierContact | null;
};

const UpdatePrincipalContact = ({ open, setOpen, contact }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const { data, loading, error } = useQuery(allSupplierContactsQuery, {
    variables: { supplier_id: params.supplierId },
    fetchPolicy: 'network-only',
  });

  const [updateContact, { error: errorMutation, loading: loadingMutation }] = useMutation(
    updatePrincipalContactMutation
  );

  const options = data?.allSupplierContacts?.map((contact: SupplierContact) => {
    return {
      value: contact.id,
      label: contact.name,
    };
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm();

  useEffect(() => {
    reset({
      principal_contact: contact
        ? {
            value: contact.id,
            label: contact.name,
          }
        : null,
    });
  }, [reset, contact]);

  const close = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    updateContact({
      variables: {
        supplier_id: params.supplierId,
        new_principal_contact: data?.principal_contact?.value,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: allSupplierContactsQuery,
          variables: { supplier_id: params.supplierId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.contacts.form.update-principal-contact-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('supplier.contacts.change-principal-contact')}
      buttonText={t('global.actions.import')}
      onCancel={close}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={loadingMutation}
      error={errorMutation}>
      <div className="mt-6">
        <SelectControl
          label={t('supplier.contacts.principal-contact')}
          control={control}
          name="principal_contact"
          options={options}
          loadingOptions={loading}
          apolloError={error}
        />
      </div>
    </SlideOver>
  );
};
export default UpdatePrincipalContact;
