/**
 * Function to check if 2 array are equals
 * in any order
 *
 * @param array1 unknown[]
 * @param array2 unknown[]
 *
 * @returns boolean
 */
export const areEquals = (array1: unknown[], array2: unknown[]) => {
  if (array1.length === array2.length) {
    array1.sort();
    array2.sort();
    return array1.every((element, index) => {
      if (element === array2[index]) {
        return true;
      }

      return false;
    });
  }

  return false;
};
