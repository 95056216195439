import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@apollo/client';
import { productCombinationQuery } from 'graphql/Queries/Product/ProductCombination/productCombination';
import { Button } from 'components/Common/Button';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { ErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { Product } from 'types/Product';
import ConstituentList from './ConstituentList';
import Title from 'components/Common/Title';
import AddPack from './AddPack';
import SkeletonConstituent from './SkeletonConstituent';

export type QueryType = {
  product: Product;
};

function CombinationIndex() {
  const params = useParams();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery<QueryType>(productCombinationQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  const [openSLide, setOpenSlide] = useState<boolean>(false);

  if (error) {
    <div className="bg-white p-3">
      <ErrorAlert>{error.message}</ErrorAlert>
    </div>;
  }

  const { productExists } = useProductContextType();
  if (!productExists) return <></>;

  const product: Product | undefined = data?.product;

  return (
    <div>
      <AddPack setOpen={setOpenSlide} open={openSLide} />
      <Title
        title={t('product.product-combination.title')}
        addOnEnd={
          !product?.isCombined && (
            <Button
              onClick={() => {
                setOpenSlide(true);
              }}
              disabled={loading}>
              <>
                <PlusIcon className="mr-3 h-6 w-6" />
                <span className="leading-6">{t('product.product-combination.add-product')}</span>
              </>
            </Button>
          )
        }
      />
      {!product?.is_combination && !product?.isCombined && !loading ? (
        <InformationAlert>{t('product.product-combination.no-constituent')}</InformationAlert>
      ) : !loading && product?.is_combination ? (
        <div className="mb-6 text-sm font-semibold text-dark">
          {t('product.product-combination.pack-content')}
        </div>
      ) : !loading ? (
        <div className="mb-6 text-sm font-semibold text-dark">
          {t('product.product-combination.belongs-to')}
        </div>
      ) : null}
      {product && <ConstituentList product={product} />}
      {loading && <SkeletonConstituent />}
    </div>
  );
}

export default CombinationIndex;
