import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import { ApolloErrorAlert, ErrorAlert } from 'components/Common/Alerts';
import { CubeIcon, DocumentTextIcon, GlobeAltIcon } from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon, PhotoIcon } from '@heroicons/react/24/outline';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { Product } from 'types/Product';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { INVALID_DATES } from 'global';
import moment from 'moment';
import Avatars from './Avatars';
import Badge from 'components/Common/Badge';
import ProductHeaderSkeleton from './ProductHeaderSkeleton';
import Activation from './Activation';
import Tippy from '@tippyjs/react';
import RoundedFlag from 'components/Common/RoundedFlag';
import Duplication from './Duplication';
import AddTemporaryImage from './AddTemporaryImage';

const ProductHeader = () => {
  const params = useParams();
  const { t } = useTranslation();

  const [loadingTemporaryImage, setLoadingTemporaryImage] = useState<boolean>(false);

  const { setProductExists } = useProductContextType();
  const { loading, error, data } = useQuery(productHeaderQuery, {
    fetchPolicy: 'network-only',
    variables: { id: params.productId },
    onCompleted: (data) => {
      if (!data?.product) {
        setProductExists(false);
      } else {
        setProductExists(true);
      }
    },
    onError: () => {
      setProductExists(false);
    },
  });

  if (loading) return <ProductHeaderSkeleton />;

  const product: Product = data?.product;

  if (error || !product) {
    return (
      <div className="cursor-default bg-gray-50 p-6">
        <div className="flex items-center">
          <div className="flex w-full flex-col items-center gap-3 lg:flex-row">
            <div className="flex-1">
              {error ? (
                <ApolloErrorAlert error={error} />
              ) : (
                <ErrorAlert>{t('product.error')}</ErrorAlert>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="sticky top-16 z-10 flex bg-gray-50 p-6">
      <div className="flex w-full flex-col items-center space-x-3 lg:flex-row ">
        <div className="flex-shrink-0">
          <div className="group relative flex h-24 w-24 items-center justify-center overflow-hidden rounded-full bg-white shadow-lg ">
            {product.primaryImage ? (
              <img
                className="h-20 w-20 rounded-full object-contain italic text-secondary"
                src={product.primaryImage.clientUrl}
                alt={t('product.header.image-not-found')}
              />
            ) : (
              <>
                {loadingTemporaryImage ? (
                  <div className="m-auto h-6 w-6 animate-spin rounded-full border-b-2 border-gray-500" />
                ) : (
                  <>
                    {product.temporaryImage ? (
                      <img
                        className="h-20 w-20 rounded-full object-contain italic text-secondary transition-transform group-hover:scale-0"
                        src={product.temporaryImage.url}
                        alt={t('product.header.image-not-found')}
                      />
                    ) : (
                      <div className="flex h-16 w-16 transform items-center justify-center rounded-full text-secondary transition-transform group-hover:scale-0">
                        <PhotoIcon className="feather feather-image" />
                      </div>
                    )}
                    <AddTemporaryImage setLoadingTemporaryImage={setLoadingTemporaryImage} />
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <div className="w-96 flex-1">
          <div className={`flex w-full items-center  justify-start `}>
            <div className={`mt-2 w-full  space-y-3 xl:mt-0 `}>
              <div className="flex w-full cursor-default space-x-3 ">
                <Tippy content={product.currentTranslation?.internal_title}>
                  <h1 className=" truncate text-xl font-bold text-dark ">
                    {product.currentTranslation
                      ? product.currentTranslation.internal_title
                        ? product.currentTranslation.internal_title
                        : t('global.no-title')
                      : t('global.no-translation')}
                  </h1>
                </Tippy>
                <div className="hidden items-center xl:flex">
                  <Badge type="info">{`#${params.productId}`}</Badge>
                </div>
              </div>
              <div className="mt-6 flex cursor-default flex-col 2xl:flex-row">
                <div className="hidden lg:block">
                  <Tippy
                    content={
                      <span className="text-sm  font-medium text-white">
                        {t('global.created-by-at', {
                          user: product.creator
                            ? `${product.creator.firstname} ${product.creator.lastname}`
                            : t('global.unknown'),
                          date: product.created_at
                            ? moment(product.created_at).format('DD/MM/YYYY')
                            : t('global.unknown-date'),
                        })}{' '}
                        {product.created_at &&
                          `${t('global.at')} ${moment(product.created_at).format('HH:mm')}`}
                      </span>
                    }>
                    <dd className="flex items-center truncate text-sm font-medium text-secondary md:mr-6">
                      <DocumentTextIcon className="-ml-1 mr-1 w-6 flex-shrink-0" />
                      {t('global.created-by-at', {
                        user: product.creator
                          ? `${product.creator.firstname} ${product.creator.lastname}`
                          : t('global.unknown'),
                        date: product.created_at
                          ? moment(product.created_at).format('DD/MM/YYYY')
                          : t('global.unknown-date'),
                      })}
                    </dd>
                  </Tippy>
                </div>
                <div className="hidden lg:block">
                  {product.firstOnlineInfos?.online_date &&
                    !INVALID_DATES.includes(product.firstOnlineInfos?.online_date) && (
                      <Tippy
                        content={
                          <span className="w-full truncate text-sm font-medium text-white">
                            {t('global.online-by-at', {
                              user: product.firstOnlineInfos.user_name ?? t('global.unknown'),
                              date: moment(product.firstOnlineInfos.online_date).format(
                                'Do MMMM YYYY'
                              ),
                            })}{' '}
                            {`${t('global.at')} ${moment(product.created_at).format('HH:mm')}`}
                          </span>
                        }>
                        <dd className="flex  items-center truncate text-sm font-medium capitalize text-secondary md:mr-6">
                          <GlobeAltIcon className="-ml-1 mr-1 w-6 flex-shrink-0" />
                          {product.firstOnlineInfos.user_name ? (
                            <>{product.firstOnlineInfos.user_name},</>
                          ) : (
                            <>{t('global.unknown')}</>
                          )}{' '}
                          {moment(product.firstOnlineInfos.online_date).format('DD/MM/YYYY')}
                        </dd>
                      </Tippy>
                    )}
                </div>
                <div className="mr-6 hidden w-full items-center truncate text-sm font-medium text-secondary lg:flex">
                  <CubeIcon className="-ml-1 mr-1 w-6 flex-shrink-0" />
                  {product.orderableQuantity}{' '}
                  {product.orderableQuantity > 1
                    ? t('product.orderable-stocks')
                    : t('product.orderable-stock')}
                </div>
              </div>
              <div className="hidden lg:block">
                {product.completionPercentage < 100 ? (
                  <Tippy
                    content={
                      <div className="space-y-3 p-3 text-sm">
                        <span className="font-bold text-red-500">
                          {product.missingInformations.length}{' '}
                          {product.missingInformations.length > 1
                            ? t('product.missing-informations')
                            : t('product.missing-information')}
                        </span>
                        <ul className="font-medium text-dark">
                          {product.missingInformations?.map((information) => (
                            <li key={information}>
                              {t(`home.dashboard.missing-informations.${information}`)}
                            </li>
                          ))}
                        </ul>
                      </div>
                    }
                    placement="bottom"
                    interactive
                    theme="light">
                    <div className="w-fit cursor-default">
                      <Badge type="warning">{`Complétion ${product.completionPercentage}%`}</Badge>
                    </div>
                  </Tippy>
                ) : product.visibleActiveSiteURLs?.length > 0 ? (
                  <Tippy
                    maxWidth={1000}
                    content={
                      <div className="w-fit space-y-3 p-3 text-sm">
                        <span className="font-bold text-dark">
                          {product.visibleActiveSiteURLs.length > 1
                            ? t('product.visible-on-sites')
                            : t('product.visible-on-site')}
                        </span>
                        <div className="grid grid-cols-2 gap-3">
                          {product.visibleActiveSiteURLs.map((site, i) => (
                            <div key={i} className="flex space-x-3">
                              <RoundedFlag iso={site.iso} />
                              <div className="text-sm leading-6">
                                <label className="font-medium text-dark">{site.countryName}</label>
                              </div>
                              <Link to={site.url} target="_blank">
                                <ArrowTopRightOnSquareIcon className="h-6 w-6 text-secondary hover:text-blue-600" />
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    }
                    placement="bottom"
                    interactive
                    theme="light">
                    <div className="w-fit cursor-default">
                      <Badge type="success">{t('global.visible')}</Badge>
                      <div className="grid grid-cols-2 gap-3"></div>
                    </div>
                  </Tippy>
                ) : (
                  <Badge type="secondary">{t('global.invisible')}</Badge>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="mt-2 flex items-center justify-end gap-3 xl:mt-0 ">
          <div className="hidden xl:block">
            <Duplication exportableElements={product.exportableElements} />
          </div>
          <div>
            <Activation active={product.active} />
          </div>
        </div>
      </div>
      <Avatars />
    </div>
  );
};

export default ProductHeader;
