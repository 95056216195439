import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { CubeTransparentIcon, PlusIcon } from '@heroicons/react/24/outline';
import { ImportPackagingQuery } from 'graphql/Queries/Product/Packaging/importPackaging';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { Button } from 'components/Common/Button';
import { ProductImportPackage } from 'types/Product';
import CreatePackage from './CreatePackage';
import Package from './Package';
import UpdatePackage from './UpdatePackage';
import Card from 'components/Common/Card';
import DeletePackageModal from './DeletePackageModal';
import PackagingOptions from './PackagingOptions';

function Index() {
  const params = useParams();
  const { t } = useTranslation();
  const [openCreatePackage, setOpenCreatePackage] = useState(false);
  const [openUpdatePackage, setOpenUpdatePackage] = useState(false);
  const [openDeletePackage, setOpenDeletePackage] = useState(false);
  const [openPackagingOptions, setOpenPackagingOptions] = useState(false);
  const [importPackage, setImportPackage] = useState<ProductImportPackage | null>(null);

  const { loading, data, error } = useQuery(ImportPackagingQuery, {
    variables: { id: params.productId },
  });

  if (error) return <ApolloErrorAlert error={error} />;

  let totalWeight = 0;
  let totalVolume = 0;

  const product = data?.product;

  product?.importPackages?.map((importPackage: ProductImportPackage) => {
    totalWeight += importPackage.weight;
    totalVolume += importPackage.volume / 1000 / 1000;
  });

  const stats = [
    {
      id: 1,
      name: t('product.packaging.import.total-weight'),
      stat: totalWeight >= 1000 ? `${totalWeight / 1000} kg` : `${totalWeight} g`,
    },
    {
      id: 2,
      name: t('product.packaging.import.total-volume'),
      stat: `${parseFloat(totalVolume.toFixed(3))} m³`,
    },
    {
      id: 3,
      name: t('product.packaging.import.package-number'),
      stat: product?.importPackages.length,
    },
  ];

  return (
    <>
      {product && (
        <PackagingOptions
          product={product}
          open={openPackagingOptions}
          setOpen={setOpenPackagingOptions}
        />
      )}
      <CreatePackage open={openCreatePackage} setOpen={setOpenCreatePackage} />
      <UpdatePackage
        importPackage={importPackage}
        open={openUpdatePackage}
        setOpen={setOpenUpdatePackage}
      />
      <DeletePackageModal
        importPackage={importPackage}
        open={openDeletePackage}
        setOpen={setOpenDeletePackage}
      />
      <Card title={t('product.packaging.import.title')}>
        <>
          <div className="flex flex-col-reverse gap-5 2xl:flex-row 2xl:justify-between">
            <div className="grid w-full grid-cols-3 gap-6">
              {stats.map((item) => (
                <div key={item.id} className="">
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">{item.name}</p>
                  </dt>
                  <dd className="flex items-baseline">
                    {loading ? (
                      <div className="skeleton mt-2 h-6 w-full"></div>
                    ) : (
                      <p className="text-2xl font-semibold text-primary-500">{item.stat}</p>
                    )}
                  </dd>
                </div>
              ))}
            </div>
            <div className="flex w-full items-center justify-end gap-4">
              <Button
                theme="secondary"
                onClick={() => setOpenPackagingOptions(true)}
                disabled={loading}>
                <div className="flex items-center gap-2">
                  <CubeTransparentIcon className="w-6" />
                  <span>{t('product.packaging.import.options')}</span>
                </div>
              </Button>
              <Button onClick={() => setOpenCreatePackage(true)}>
                <div className="flex items-center gap-2">
                  <PlusIcon className="w-6" />
                  <span>{t('product.packaging.add')}</span>
                </div>
              </Button>
            </div>
          </div>

          {!data?.product?.importPackages?.length && !loading ? (
            <div className="mt-6">
              <InformationAlert>{t('product.packaging.no-package')}</InformationAlert>
            </div>
          ) : (
            <div className="-mx-4 mt-8 sm:-mx-0">
              <table className="min-w-full divide-y divide-gray-300 text-sm">
                <thead className="text-left font-semibold uppercase text-secondary">
                  <tr>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.packaging.import.package')}
                    </th>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.packaging.import.name')}
                    </th>
                    <th scope="col" className="hidden truncate px-3 py-3.5 2xl:table-cell">
                      {t('product.packaging.import.reference')}
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 2xl:table-cell">
                      {t('product.packaging.import.price')}
                    </th>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.packaging.import.volume')}
                    </th>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.packaging.import.weight')}
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 2xl:table-cell">
                      {t('product.packaging.import.moq')}
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 text-left font-medium text-dark">
                  {data?.product?.importPackages.map(
                    (importPackage: ProductImportPackage, i: number) => {
                      return (
                        <tr key={i}>
                          <Package
                            number={i + 1}
                            importPackage={importPackage}
                            setPackage={setImportPackage}
                            setOpenUpdatePackage={setOpenUpdatePackage}
                            setOpenDeletePackage={setOpenDeletePackage}
                          />
                        </tr>
                      );
                    }
                  )}
                  {loading && (
                    <tr>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </>
      </Card>
    </>
  );
}

export default Index;
