import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { Transition, Popover } from '@headlessui/react';
import { Navigation } from 'types/Global';
import { t } from 'i18next';
import clsx from 'clsx';

type Props = {
  navigation: Navigation[];
  otherNavigation?: Navigation[];
  title: string;
  type?: string;
};

const ResponsiveSecondaryMenu = ({ navigation, title, type = '', otherNavigation = [] }: Props) => {
  return (
    <div className="md:hidden">
      <Popover className="relative">
        <Popover.Button className="inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-dark focus:outline-none">
          <Bars3Icon className="mt-1 h-6 w-6 text-dark focus:outline-none" />
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1">
          <Popover.Panel className="absolute left-7 z-10 ml-8 mt-5 flex w-screen max-w-min -translate-x-1/2  px-4">
            <div className="w-60 shrink rounded bg-menu p-4 text-sm leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
              <div className="pb-4 font-semibold uppercase text-secondary">{title}</div>
              {navigation.map((item) => {
                return (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }) =>
                      clsx(isActive ? 'font-semibold' : 'font-normal', 'flex p-1 text-dark')
                    }>
                    {item.name}
                  </NavLink>
                );
              })}
              {type === 'settings' && (
                <>
                  <div className="pb-4 pt-12 font-semibold uppercase text-secondary">
                    {t('settings.menu.suppliers.title')}
                  </div>
                  {otherNavigation.map((item) => {
                    return (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) =>
                          clsx(isActive ? 'font-semibold' : 'font-normal', 'flex p-1 text-dark')
                        }>
                        {item.name}
                      </NavLink>
                    );
                  })}
                </>
              )}
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </div>
  );
};
export default ResponsiveSecondaryMenu;
