import React, { useContext, useLayoutEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ActiveTaxContext } from '../Context/ActiveTaxContext';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { dEEEQuery } from 'graphql/Queries/Ecotax/dEEE';
import { deeeAmountQuery } from 'graphql/Queries/Ecotax/deeeAmount';
import { ID } from 'types/Global';
import DEEE from './DEEE';
import DEEESkeleton from './DEEESkeleton';
import Badge from 'components/Common/Badge';
import clsx from 'clsx';
import { Product } from 'types/Product';

type Props = {
  productId: ID;
};

const Index = ({ productId }: Props) => {
  const { t } = useTranslation();
  const { DEEEStatus, setDEEE } = useContext(ActiveTaxContext);
  const [estimateDEEE, setEstimateDEEE] = useState<{ status: string; value: number } | null>(null);

  const [loadAmount, { loading: loadingAmount, error: errorAmount }] = useLazyQuery(
    deeeAmountQuery,
    {
      variables: { product_id: parseInt(String(productId)) },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data.productEstimatedDeeeAmount) {
          setEstimateDEEE({
            status: 'success',
            value: data.productEstimatedDeeeAmount.amount,
          });
        }
      },
    }
  );

  const {
    loading: loadingQuery,
    data: dataQuery,
    error: errorQuery,
  } = useQuery(dEEEQuery, {
    variables: { id: parseInt(String(productId)) },
    fetchPolicy: 'network-only',
    onCompleted: () => {
      loadAmount();
    },
  });

  const product: Product | undefined = dataQuery?.product;

  const isConstituent = (product?.constituents?.length ?? 0) > 0;

  useLayoutEffect(() => {
    if (product && product.deee) {
      setDEEE(product.deee.enable);
    } else {
      setDEEE(null);
    }
  }, [product, setDEEE]);

  let isEnable = '';
  if (DEEEStatus) {
    isEnable = 'border-2 border-primary-500';
  } else {
    isEnable = '';
  }

  return (
    <div className={clsx('flex flex-col bg-white shadow sm:rounded-md', isEnable)}>
      <div className="mb-5 h-24 border-b-2 px-6 py-4">
        <div className="flex justify-between">
          <h2 className="text-lg font-medium leading-6 text-dark">
            {t('product.ecotax.title-deee')}
          </h2>
          {errorAmount && <Badge type="danger">{errorAmount.message}</Badge>}
          {!errorAmount && !loadingAmount && DEEEStatus !== null ? (
            <Badge type={DEEEStatus ? 'success' : 'warning'}>
              {DEEEStatus ? t('product.ecotax.notify.active') : t('product.ecotax.notify.inactive')}
            </Badge>
          ) : (
            !errorAmount &&
            !loadingQuery &&
            !loadingAmount && <Badge type="danger">{t('product.ecotax.notify.estimate')}</Badge>
          )}
        </div>
        <div className="mt-1 flex w-full items-center justify-between gap-5">
          <div className="flex items-baseline text-2xl font-semibold text-primary-500">
            {!loadingQuery && !loadingAmount ? (
              product?.deee?.amount.amount ? (
                `${product?.deee?.amount.amount} €`
              ) : (
                `${estimateDEEE?.value ?? 0} €`
              )
            ) : (
              <div className="skeleton h-[32px] w-[48px]"></div>
            )}
          </div>
          {isConstituent && (
            <div className="mt-1 flex w-64  justify-end">
              <div className="truncate text-sm text-dark">{t('product.ecotax.total-pack')} :</div>
              <div className="ml-1 flex-shrink-0 text-sm font-semibold text-dark">
                {product?.totalChildrenDEEEAmount} €
              </div>
            </div>
          )}
        </div>
      </div>
      {errorQuery && (
        <div className="my-2 p-2">
          <ApolloErrorAlert error={errorQuery} />
        </div>
      )}
      {!loadingQuery && !errorQuery && product && (
        <DEEE productId={String(productId)} DEEEdata={product.deee} errorAmount={errorAmount} />
      )}
      {loadingQuery && <DEEESkeleton />}
    </div>
  );
};

export default Index;
