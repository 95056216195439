import { gql } from 'graphql.macro';

export const updatePurchaseOrderCommentMutation = gql`
  mutation UpdateComments($id: ID!, $purchase_order_comment: String) {
    updateSupplier(id: $id, purchase_order_comment: $purchase_order_comment) {
      id
      purchase_order_comment
    }
  }
`;
