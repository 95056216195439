import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/Product';
import { FRANCE_ID, PERCENTAGE_REWARD_FOR_WAITING } from 'global';
import clsx from 'clsx';
import Badge from 'components/Common/Badge';
import Card from 'components/Common/Card';
import RoundedFlag from 'components/Common/RoundedFlag';
import moment from 'moment';
import usePriceFormatter from 'hooks/usePriceFormatter';

type Props = {
  loading: boolean;
  product?: Product;
};

const OffersAndVisibilities = ({ loading, product }: Props) => {
  const { t } = useTranslation();
  const { formatPrice } = usePriceFormatter();

  const isRewardedForWaiting = useCallback((product?: Product, countryId?: number) => {
    if (!product || !countryId) {
      return false;
    }
    return countryId === FRANCE_ID
      ? product.is_rewarded_for_waiting
      : product.is_rewarded_for_waiting_foreign;
  }, []);

  return (
    <Card title={t('product.preview.offers-visibilities.title')}>
      <div className="space-y-6">
        <div className="flex items-center justify-end gap-2">
          {loading ? (
            <div className="skeleton h-8 w-40" />
          ) : (
            <>
              <span className="text-sm font-semibold text-dark">
                {t('product.preview.offers-visibilities.ecotax')}
                {' : '}
              </span>
              <span className="text-2xl font-medium text-primary-500">{`${
                product?.ecotax ?? 0
              } €`}</span>
            </>
          )}
        </div>
        <div className="grid grid-cols-2 gap-6 2xl:grid-cols-3">
          {loading
            ? [...Array(6)].map((_, i) => <div key={i} className="skeleton h-24 w-full" />)
            : product?.offersAndVisibilityInformations.map((offerInformations, i: number) => {
                let isDiscount = offerInformations.offer.isDiscount;

                if (offerInformations.offer.futurePriceWithEcotax) {
                  isDiscount = true;
                }

                let priceWithEcotax = offerInformations.offer.priceWithEcotax;
                const countryId = +offerInformations.country.id;

                if (isRewardedForWaiting(product, countryId)) {
                  priceWithEcotax -= priceWithEcotax * ((PERCENTAGE_REWARD_FOR_WAITING ?? 0) / 100);
                  isDiscount = true;
                }

                return (
                  <Card key={i}>
                    <div
                      className={clsx('space-y-3', !offerInformations.visible ? 'opacity-50' : '')}>
                      <div className="space-y-3 xl:flex xl:items-center xl:justify-between xl:space-y-0">
                        <div className="flex items-center gap-2">
                          <RoundedFlag iso={offerInformations.country.iso} size={4} />
                          <span className="text-sm font-medium text-dark">
                            {offerInformations.country.name}
                          </span>
                        </div>
                        {isDiscount ? (
                          <div className="flex space-x-2">
                            <p className="font-semibold">{`${
                              offerInformations.offer.futurePriceWithEcotax
                                ? formatPrice(offerInformations.offer.futurePriceWithEcotax)
                                : formatPrice(priceWithEcotax)
                            } €`}</p>
                            <p className="text-secondary line-through">{`${offerInformations.offer.highestPrice} €`}</p>
                          </div>
                        ) : (
                          <span className="font-semibold">{`${
                            offerInformations.offer.price_without_ecotax ? priceWithEcotax : '-'
                          } €`}</span>
                        )}
                      </div>
                      <div className="flex flex-col-reverse gap-3 xl:flex-row xl:items-center xl:justify-between">
                        {offerInformations.visible ? (
                          <Badge type="success">{t('global.visible')}</Badge>
                        ) : (
                          <Badge type="secondary">{t('global.invisible')}</Badge>
                        )}
                        {offerInformations.offer?.startDiscountDate && (
                          <div className="text-sm font-medium text-dark xl:flex xl:justify-end">
                            <span className="truncate">
                              {t('global.from-to', {
                                start: moment(offerInformations.offer.startDiscountDate).format(
                                  'DD/MM/YY'
                                ),
                                end: moment(offerInformations.offer.endDiscountDate).format(
                                  'DD/MM/YY'
                                ),
                              })}
                            </span>
                          </div>
                        )}
                        {!offerInformations.offer?.startDiscountDate &&
                          offerInformations.offer?.endDiscountDate && (
                            <div className="text-sm font-medium text-dark xl:flex xl:justify-end">
                              <span className="truncate">
                                {t('global.ends')}{' '}
                                {moment(offerInformations.offer.endDiscountDate).format('DD/MM/YY')}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </Card>
                );
              })}
        </div>
      </div>
    </Card>
  );
};

export default OffersAndVisibilities;
