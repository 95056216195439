import { gql } from 'graphql.macro';

export const editorialQuery = gql`
  query Editorial($id: ID!) {
    product(id: $id) {
      id
      availableLanguages {
        id
        iso
        name
      }
      furnitureAssemblyOption {
        id
        active
        translations {
          id
          lang
          text
        }
      }
      translations {
        id
        lang_id
        title
        url
        urlFullPath
        commercial_description
        maintenance_description
        designer_word
        designer_id
        designer {
          id
          name
          photoClientUrl
          is_active
        }
      }
    }
  }
`;
