import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { datasheetsQuery } from 'graphql/Queries/Product/Documents/Datasheet/datasheets';
import { noticesQuery } from 'graphql/Queries/Product/Documents/Notices/notices';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { useTranslation } from 'react-i18next';
import Header from './Header';
import Card from 'components/Common/Card';
import Notices from './Notices/Notices';
import Datasheets from './Datasheets/Datasheets';

export default function Index() {
  const params = useParams();
  const { t } = useTranslation();

  const { loading, error, data } = useQuery(datasheetsQuery, {
    variables: { product_id: params.productId },
    fetchPolicy: 'network-only',
  });

  const {
    loading: loadingNotice,
    error: errorNotice,
    data: dataNotice,
  } = useQuery(noticesQuery, {
    variables: { product_id: params.productId },
    fetchPolicy: 'network-only',
  });

  const { productExists } = useProductContextType();

  if (!productExists) return <></>;

  return (
    <>
      <Header />
      <div className="space-y-6">
        <Card title={t('product.documents.notices.title')}>
          <Notices
            notices={dataNotice?.product ? dataNotice?.product?.notices : []}
            loading={loadingNotice}
            error={errorNotice}
          />
        </Card>
        <Card title={t('product.documents.datasheets.title')}>
          <Datasheets
            datasheets={data?.product ? data?.product?.datasheets : []}
            loading={loading}
            error={error}
          />
        </Card>
      </div>
    </>
  );
}
