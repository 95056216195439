import { gql } from 'graphql.macro';

export const updateColorMutation = gql`
  mutation UpdateColor(
    $id: ID!
    $label: String
    $label_gb: String
    $parent_id: ID
    $hex_code: String
  ) {
    updateProductColor(
      id: $id
      label: $label
      label_gb: $label_gb
      parent_id: $parent_id
      hex_code: $hex_code
    ) {
      id
      label
      label_gb
      parent {
        id
        label
      }
      children {
        id
      }
      hex_code
      is_active
      isUsed
    }
  }
`;
