import { gql } from 'graphql.macro';

export const paymentMethodsQuery = gql`
  query PaymentMethods {
    supplierPaymentMethods {
      id
      label
      sort
      active
      used
    }
  }
`;
