import { gql } from 'graphql.macro';

export const findProductCollectionsByTextQuery = gql`
  query FindProductCollectionsByText($text: String!) {
    findProductCollectionsByText(text: $text) {
      id
      label
      active
      isUsed
    }
  }
`;
