import { gql } from 'graphql.macro';

export const updateDesignerMutation = gql`
  mutation updateDesigner($id: ID!, $name: String, $file: Upload, $is_active: Boolean) {
    updateDesigner(id: $id, file: $file, name: $name, is_active: $is_active) {
      id
      name
      photoClientUrl
      is_active
      isUsed
    }
  }
`;
