import { Image, AllImages } from 'types/Product';
import { TFunction } from 'i18next';

export const getBadgeText = (image: Image, data: AllImages, t: TFunction) => {
  if (data?.product) {
    if (data.product.miscellaneousImages.includes(image)) {
      return t('product.images.type.miscellaneous');
    }
    if (data.product.ambianceImages.includes(image)) {
      return t('product.images.type.ambiance');
    }
    if (data.product.dimensionsImage === image) {
      return t('product.images.type.dimensions');
    }
    if (data.product.energyClassImage === image) {
      return t('product.images.type.energy');
    }
  }
  return '';
};
