import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { loginQuery } from 'graphql/Queries/login';
import { yupResolver } from '@hookform/resolvers/yup';
import { UserData } from 'components/Login/Login.jsx';
import { InputTextControl } from 'components/Common/InputTextControl';
import { ApolloErrorAlert } from '../Common/Alerts';
import { Button } from 'components/Common/Button';
import * as yup from 'yup';

type Props = {
  handler: (data: UserData) => void;
};

const LoginForm = ({ handler }: Props) => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    email: yup.string().required(t('form.login.email-required')).email(t('form.invalid-email')),
    password: yup.string().required(t('form.login.password-required')),
  });

  const { handleSubmit, control } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const [login, { loading, error }] = useMutation(loginQuery);

  const onSubmit = handleSubmit((data: FieldValues) => {
    login({
      variables: {
        email: data.email,
        password: data.password,
      },
      onCompleted: ({ login }) => {
        handler(login);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <div className="pb-6">{error && <ApolloErrorAlert error={error} />}</div>
      <form className="align-items-center flex w-96 flex-col justify-between" onSubmit={onSubmit}>
        <h1 className="mb-12 text-center text-5xl text-dark">
          {t('sign-in.title', { lng: 'en' })}
        </h1>
        <div className="space-y-6">
          <InputTextControl
            label={t('sign-in.email', { lng: 'en' })}
            name="email"
            control={control}
            isRequired
          />
          <InputTextControl
            label={t('sign-in.password', { lng: 'en' })}
            name="password"
            control={control}
            isRequired
            type="password"
          />
          <Button type="submit" className="w-full" disabled={loading} loading={loading}>
            {t('sign-in.title', { lng: 'en' })}
          </Button>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
