import React, { SetStateAction, useCallback, useEffect, useState } from 'react';
import { FieldValues, UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { checkImagesDesignerExtension, checkDesignerImageSize } from 'utils/Utils';
import DesignerImage from './DesignerImage';

type ImageProps = {
  image: string;
  setValue: UseFormSetValue<FieldValues>;
  errorMessage?: string;
  errorsFile: string[];
  setErrorsFile: React.Dispatch<SetStateAction<string[]>>;
};

const Image = ({ image, setValue, errorMessage, setErrorsFile, errorsFile }: ImageProps) => {
  const { t } = useTranslation();

  const [imagePreview, setImagePreview] = useState<string>('');

  useEffect(() => {
    setImagePreview(image);
  }, [setImagePreview, image]);

  const onDrop = useCallback(
    async (files: File[]) => {
      const formatIsValid = checkImagesDesignerExtension(files);
      const sizeIsValid = await checkDesignerImageSize(files);

      let formatError = '';
      let sizeError = '';

      if (!formatIsValid) {
        formatError = t('settings.designers.image.format-not-valid');
      }
      if (!sizeIsValid) {
        sizeError = t('settings.designers.image.size-not-valid');
      }

      if (!formatIsValid || !sizeIsValid) {
        setErrorsFile([formatError, sizeError]);
        setValue('designerImage', []);
      } else {
        setErrorsFile([]);
        if (files.length > 0) {
          loadImage(files[0]);
          setValue('designerImage', files, { shouldDirty: true });
        }
      }
    },
    [setErrorsFile, t, setValue]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const loadImage = (file: File) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      setImagePreview(reader.result as string);
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className="h-full w-full px-4 pb-4 pt-2">
      <label htmlFor="email" className="mb-1 block text-sm font-medium leading-6 text-dark">
        {t('settings.designers.image.add-image')}
        <span className="ml-1 text-red-700">*</span>
      </label>
      <div
        {...getRootProps()}
        className={`mt-1 flex h-full cursor-pointer items-center justify-center rounded-md border-2 border-dashed  bg-white px-6 pb-6 pt-5  text-left
          ${errorMessage || errorsFile.length > 0 ? 'border-red-700' : 'border-gray-300'}
          ${isDragActive ? 'bg-blue-100' : ''}`}>
        <input {...getInputProps()} name="designerImage" accept=".jpg, image/jpeg" />

        {imagePreview ? (
          <DesignerImage image={imagePreview} />
        ) : (
          <div className="w-full space-y-1  text-center">
            <div className="space-y-1  text-center">
              <input id="designerImage" type="file" accept=".jpg, image/jpeg" className="sr-only" />
              <svg
                className={`mx-auto h-12 w-12 ${
                  errorMessage || errorsFile.length > 0 ? 'text-red-700' : 'text-gray-400'
                } `}
                stroke="currentColor"
                fill="none"
                viewBox="0 0 48 48">
                <path
                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <span
                className={`mt-2 block text-sm font-semibold ${
                  errorMessage || errorsFile.length > 0 ? 'text-red-700' : 'text-gray-900'
                } `}>
                {t('settings.designers.image.add-image')}
              </span>
              <p
                className={`text-xs ${
                  errorMessage || errorsFile.length > 0 ? 'text-red-700' : 'text-gray-500'
                } `}>
                {t('settings.designers.image.label-input')}
              </p>
            </div>
          </div>
        )}
      </div>
      {errorMessage && <p className="mt-2 text-sm text-red-700">{errorMessage}</p>}
      {!errorMessage && errorsFile.length > 0 && (
        <>
          {errorsFile.map((error, i) => {
            return (
              <p className="mt-2 text-sm text-red-700" key={i}>
                {error}
              </p>
            );
          })}
        </>
      )}
    </div>
  );
};
export default Image;
