import { gql } from 'graphql.macro';

export const incotermsQuery = gql`
  query Incoterms {
    incoterms {
      id
      label
      active
      used
    }
  }
`;
