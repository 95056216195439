import React from 'react';
import { useTranslation } from 'react-i18next';
import { Control } from 'react-hook-form';
import { CURRENCIES } from 'global';
import { InputTextControl } from 'components/Common/InputTextControl';

type Props = {
  control: Control;
};
const FormContent = ({ control }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="space-y-6">
      <InputTextControl control={control} name="name" label={t('product.packaging.import.name')} />
      <InputTextControl
        control={control}
        name="reference"
        label={t('product.packaging.import.reference')}
      />
      <InputTextControl
        control={control}
        name="price"
        label={t('product.packaging.import.price')}
        select={{
          name: 'currency_id',
          options: CURRENCIES.map((currency) => ({
            value: `${currency.value}`,
            label: currency.label,
          })),
        }}
      />
      <InputTextControl
        control={control}
        name="weight"
        label={t('product.packaging.import.weight')}
        addOnEnd="g"
      />
      <InputTextControl
        control={control}
        name="volume"
        label={t('product.packaging.import.volume')}
        addOnEnd="m³"
      />
      <InputTextControl control={control} name="moq" label={t('product.packaging.import.moq')} />
    </div>
  );
};

export default FormContent;
