import { useTranslation } from 'react-i18next';
import { Transition } from '@headlessui/react';
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/20/solid';
import PropTypes from 'prop-types';

function Helper({ children, isShowing, onClose }) {
  const { t } = useTranslation();
  return (
    <Transition
      show={isShowing}
      enter="transition ease-in-out duration-200 transform"
      enterFrom="scale-50 opacity-0"
      enterTo="scale-100 opacity-100"
      leave="transition ease-in-out duration-100 transform"
      leaveFrom="scale-100 opacity-100"
      leaveTo="scale-50 opacity-0">
      <div className="relative my-4 rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon className="h-5 w-5 text-blue-400" />
          </div>
          <button type="button" className="absolute right-3 top-3" onClick={() => onClose()}>
            <XMarkIcon className="h-5 w-5 text-blue-400" />
          </button>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-blue-800">{t('global.help-title')}</h3>
            <div className="mt-2 text-sm text-blue-700">{children}</div>
          </div>
        </div>
      </div>
    </Transition>
  );
}

Helper.propTypes = {
  children: PropTypes.object,
  isShowing: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Helper;
