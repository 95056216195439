import { gql } from 'graphql.macro';

export const palletTypesQuery = gql`
  query PalletTypes {
    palletTypes {
      id
      label
      legacy_code
    }
  }
`;
