import { gql } from 'graphql.macro';

export const allColorsQuery = gql`
  query AllColors {
    colors {
      id
      label
      label_gb
      parent {
        id
        label
      }
      children {
        id
      }
      hex_code
      is_active
      isUsed
    }
  }
`;
