import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  CogIcon,
  HomeIcon,
  TruckIcon,
  Squares2X2Icon,
  RocketLaunchIcon,
  MegaphoneIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import IconCollapse from 'IconCollapse';
import MilibooLogo from 'MilibooLogo';
import Footer from './Footer';
import { useGlobalContextType } from 'components/Contexts/GlobalContext';

interface SidebarContentProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleMouseEnter?: () => void;
  isSlide?: boolean;
}

const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};

const SidebarContent = ({
  open,
  setOpen,
  handleMouseEnter,
  isSlide = false,
}: SidebarContentProps) => {
  const { t } = useTranslation();
  const { setSidebarOpen } = useGlobalContextType();

  const sidebarNavigation = [
    {
      name: t('main-menu.home'),
      href: '/',
      icon: HomeIcon,
    },
    {
      name: t('main-menu.products'),
      href: '/search',
      icon: Squares2X2Icon,
      special: true,
    },
    {
      name: t('main-menu.future-arrivals'),
      href: '/future-arrivals',
      icon: RocketLaunchIcon,
      special: false,
    },
    {
      name: t('main-menu.marketing-integration'),
      href: '/marketing-integration',
      icon: MegaphoneIcon,
      special: false,
    },
    {
      name: t('main-menu.suppliers'),
      href: '/suppliers',
      icon: TruckIcon,
      special: true,
    },
    {
      name: t('main-menu.settings'),
      href: '/settings/taxonomy',
      icon: CogIcon,
      special: true,
    },
  ];

  const closeSidebar = () => {
    setOpen(false);
    localStorage.setItem('sidebarOpen', 'false');
    setSidebarOpen(false);
  };

  return (
    <div
      className="h-full w-72 shrink-0 bg-primary-500"
      onMouseEnter={() => {
        if (!open) handleMouseEnter?.();
      }}>
      <div className="flex h-full w-full flex-col ">
        <div className="flex w-full items-center justify-between pl-6 pt-2">
          <MilibooLogo />
          <div className=" mr-6" onClick={closeSidebar}>
            <IconCollapse isCollapsed={false} />
          </div>
        </div>
        <div className=" -mt-2 pl-6 text-light">{t('global.app-title')}</div>
        <div
          className={`mt-20 flex h-full w-full flex-col justify-between pl-3 ${
            open && 'hover:z-10'
          } `}>
          <div className="flex-1 space-y-1">
            {sidebarNavigation.map((item) => (
              <NavLink
                className={({ isActive }) =>
                  classNames(
                    isActive ? 'border-r-8 border-white bg-primary-400' : '',
                    'flex w-full items-center rounded-l-lg px-6 py-3 pr-0 text-base font-semibold text-white outline-none transition-all duration-200 ease-in-out hover:bg-primary-400'
                  )
                }
                to={item.href}
                key={item.name}
                onClick={() => {
                  if (isSlide) closeSidebar();
                }}>
                <div className="relative">
                  <item.icon className="mr-6 h-6 w-6 text-white" />
                </div>
                {item.name}
              </NavLink>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default SidebarContent;
