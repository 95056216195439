import { gql } from 'graphql.macro';

export const materialsAndColorsQuery = gql`
  query MaterialsAndColors($id: ID!) {
    product(id: $id) {
      material1 {
        id
        label
        is_active
      }
      material2 {
        id
        label
        is_active
      }
      color {
        id
        label
        hex_code
        is_active
      }
      supplier_color
    }
  }
`;
