import { gql } from 'graphql.macro';

export const createDesignerMutation = gql`
  mutation createDesigner($name: String!, $file: Upload!) {
    createDesigner(file: $file, name: $name) {
      id
      name
      photoClientUrl
    }
  }
`;
