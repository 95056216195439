import { ProductCategory, ProductUniverse } from 'types/Product';

type UseCreateButtonLabelProps = {
  selectedUniverse: ProductUniverse | null;
  selectedCategory: ProductCategory | null;
  t: (key: string) => string;
};

const useCreateButtonLabel = ({
  selectedUniverse,
  selectedCategory,
  t,
}: UseCreateButtonLabelProps): string => {
  if (!selectedUniverse) {
    return t('settings.taxonomy.actions.create-universe');
  } else if (selectedUniverse && !selectedCategory) {
    return t('settings.taxonomy.actions.create-category');
  } else {
    return t('settings.taxonomy.actions.create-type');
  }
};

export default useCreateButtonLabel;
