import React, { useEffect, useState } from 'react';
import { t } from 'i18next';
import { useMutation, useQuery } from '@apollo/client';
import { universesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/universes';
import { productCategoriesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productCategories';
import { editProductCategoryMutation } from 'graphql/Mutations/Settings/Products/Taxonomy/editProductCategory';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProductCategory, ProductUniverse } from 'types/Product';
import { InputTextControl } from 'components/Common/InputTextControl';
import { OptionProps } from 'types/Global';
import { SelectControl } from 'components/Common/SelectControl';
import SlideOver from 'components/Common/SlideOver';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  category: ProductCategory | null;
};

const EditCategorySlide = ({ setOpen, open, category }: Props) => {
  const { setNotification } = useNotification();

  const [universeOptions, setUniverseOptions] = useState<OptionProps[]>([]);

  const [
    editProductCategory,
    { error: errorMutation, loading: loadingMutation, reset: resetMutation },
  ] = useMutation(editProductCategoryMutation);

  const schema = yup.object().shape({
    label: yup.string().max(50, t('form.string.max')),
  });

  const { loading, error, data } = useQuery(universesQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data) return;
    setUniverseOptions(
      data.productUniverses?.map((universe: ProductUniverse) => {
        return {
          value: universe.id,
          label: universe.label ? universe.label : '-',
          isDisabled: !universe.is_active,
        };
      })
    );
  }, [data]);

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    reset({
      label: category?.label,
      universe: category?.universe
        ? {
            value: category.universe.id,
            label: category.universe.label,
          }
        : null,
    });
  }, [reset, category, getValues]);

  const close = () => {
    setOpen(false);
    reset();
    resetMutation();
  };

  const onSubmit = handleSubmit((data) => {
    editProductCategory({
      variables: {
        id: category?.id,
        label: data.label,
        universe_id: getValues('universe')?.value,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: productCategoriesQuery, variables: { id: category?.universe.id } }],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('settings.taxonomy.category.update-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('settings.taxonomy.actions.edit-category')}
      buttonText={t('global.actions.modify')}
      isDirty={isDirty}
      onSubmit={onSubmit}
      onCancel={close}
      loading={loadingMutation}
      error={errorMutation}>
      <div className="flex flex-col space-y-8">
        <SelectControl
          name="universe"
          control={control}
          label={t('settings.taxonomy.actions.choose-universe')}
          options={universeOptions}
          loadingOptions={loading}
          apolloError={error}
        />
        <div className="space-y-6">
          <InputTextControl label={t('global.name')} name="label" control={control} isRequired />
        </div>
      </div>
    </SlideOver>
  );
};
export default EditCategorySlide;
