import { gql } from 'graphql.macro';

export const datasheetsQuery = gql`
  query Datasheets($product_id: ID!) {
    product(id: $product_id) {
      id
      datasheets {
        id
        name
        version
        created_at
        product_id
        isLatest
        clientUrl
        comment
        user {
          id
          firstname
          lastname
        }
        created_at
      }
    }
  }
`;
