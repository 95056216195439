import React, { createContext, useCallback, useState, ReactNode } from 'react';

interface TaxStatus {
  ecoMobilier: boolean | null;
  DEEE: boolean | null;
}

interface ActiveTaxContextProps {
  DEEEStatus: boolean | null;
  ecoMobilierStatus: boolean | null;
  setEcomobilier: (value: boolean | null) => void;
  setDEEE: (value: boolean | null) => void;
}

const ActiveTaxContext = createContext<ActiveTaxContextProps>({
  DEEEStatus: null,
  ecoMobilierStatus: null,
  setEcomobilier: () => {
    return;
  },
  setDEEE: () => {
    return;
  },
});

interface ActiveTaxProviderProps {
  children: ReactNode;
}

const ActiveTaxProvider: React.FC<ActiveTaxProviderProps> = ({ children }) => {
  const [taxStatus, setTaxStatus] = useState<TaxStatus>({ ecoMobilier: null, DEEE: null });

  const setEcomobilier = useCallback((value: boolean | null): void => {
    setTaxStatus((taxStatus) => ({ DEEE: taxStatus.DEEE, ecoMobilier: value }));
  }, []);

  const setDEEE = useCallback((value: boolean | null): void => {
    setTaxStatus((taxStatus) => ({
      ecoMobilier: taxStatus.ecoMobilier,
      DEEE: value,
    }));
  }, []);

  return (
    <ActiveTaxContext.Provider
      value={{
        DEEEStatus: taxStatus.DEEE,
        ecoMobilierStatus: taxStatus.ecoMobilier,
        setDEEE,
        setEcomobilier,
      }}>
      {children}
    </ActiveTaxContext.Provider>
  );
};

export { ActiveTaxProvider, ActiveTaxContext };
