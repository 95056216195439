import { gql } from 'graphql.macro';

export const createMaterialMutation = gql`
  mutation CreateMaterial($label: String!, $parent_id: ID) {
    createProductMaterial(label: $label, parent_id: $parent_id) {
      id
      label
      parent {
        id
        label
      }
    }
  }
`;
