import { gql } from 'graphql.macro';

export const createPackagingOptionMutation = gql`
  mutation CreatePackagingOption($label_fr: String!, $label_gb: String!) {
    createPackagingOption(label_fr: $label_fr, label_gb: $label_gb) {
      id
      label_fr
      label_gb
      is_active
      isUsed
    }
  }
`;
