import { gql } from 'graphql.macro';

export const findProductModelsByOffsetQuery = gql`
  query FindProductModelsByOffset($number: Int!, $offset: Int!) {
    findProductModelsByOffset(number: $number, offset: $offset) {
      id
      name
      active
      isUsed
    }
  }
`;
