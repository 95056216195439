import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useNotification from 'hooks/useNotification';
import { updateProductAssemblyInstructionMutation } from 'graphql/Mutations/Settings/Products/Furniture/updateProductAssemblyInstruction';
import { createFurnitureAssemblyOptionMutation } from 'graphql/Mutations/Settings/Products/Furniture/createFurnitureAssemblyOption';
import { Translation } from 'types/Global';
import { furnitureAssemblyOptionsQuery } from 'graphql/Queries/Settings/Products/Furniture/furnitureAssemblyOptions';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';
import SelectCreatableControl from 'components/Common/SelectCreatableControl';
import { SelectControl } from 'components/Common/SelectControl';

interface AssemblyInstruction {
  id: string | number;
  translations: Translation[];
  active: boolean;
  isUsed: boolean;
}

interface AssemblyInstructionOptionProps {
  defautValues: AssemblyInstruction;
  selectedTabIso: string;
}

const AssemblyInstructionOption = ({
  defautValues,
  selectedTabIso,
}: AssemblyInstructionOptionProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification, setError } = useNotification();

  const [options, setOptions] = useState([]);

  const [updateProductAssemblyInstruction, { loading: updateInstructionLoading }] = useMutation(
    updateProductAssemblyInstructionMutation
  );

  const [createInstruction, { loading: createInstructionLoading }] = useMutation(
    createFurnitureAssemblyOptionMutation,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: furnitureAssemblyOptionsQuery,
        },
      ],
    }
  );

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
    getValues,
  } = useForm();

  const getLabel = useCallback(
    (translations: Translation[]) => {
      const translation =
        translations.find((translation: Translation) => {
          return translation.lang === selectedTabIso;
        }) ?? null;
      return translation?.text;
    },
    [selectedTabIso]
  );

  const [getOptions, { loading }] = useLazyQuery(furnitureAssemblyOptionsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setOptions(
        data.furnitureAssemblyOptions?.map((option: AssemblyInstruction) => {
          return {
            label: getLabel(option.translations) ?? t('global.no-translation'),
            value: option.id,
            active: option.active,
            isUsed: option.isUsed,
          };
        })
      );
    },
  });

  useEffect(() => {
    getOptions();
    reset({
      instruction: defautValues
        ? {
            value: defautValues.id,
            label: getLabel(defautValues.translations) ?? t('global.no-translation'),
            active: defautValues.active,
          }
        : null,
    });
  }, [reset, defautValues, selectedTabIso, getLabel, getOptions, t]);

  const onSubmit = handleSubmit(async (data) => {
    if (data.instruction?.__isNew__) {
      await new Promise<void>((resolve, reject) => {
        createInstruction({
          variables: {
            label: data.instruction.label,
          },
          onCompleted: (res) => {
            const data = res.createFurnitureAssemblyOption;
            reset({
              instruction: {
                value: data.id,
                label: data.currentTranslation?.text,
                active: data.active,
              },
            });

            setNotification({
              title: t('global.success'),
              message: t('product.editorial.instruction.add-instruction'),
              type: 'success',
            });
            resolve();
          },
          onError: (error) => {
            setError(error);
            reject(error);
          },
        });
      });
    }

    updateProductAssemblyInstruction({
      variables: {
        id: params.productId,
        furniture_assembly_option_id: getValues('instruction')?.value,
      },
      onCompleted: (res) => {
        const data = res?.updateProductAssemblyInstruction;
        setNotification({
          title: t('global.success'),
          message: t('product.editorial.instruction.update-success'),
          type: 'success',
        });
        reset({
          instruction: data?.furnitureAssemblyOption
            ? {
                value: data.furnitureAssemblyOption.id,
                label: data.furnitureAssemblyOption.currentTranslation?.text,
                active: data.furnitureAssemblyOption.active,
              }
            : null,
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  return (
    <Card title={t('product.editorial.instruction.title')}>
      <form onSubmit={onSubmit}>
        {selectedTabIso === 'fr' ? (
          <SelectCreatableControl
            control={control}
            name="instruction"
            label={t('product.editorial.instruction.label')}
            options={options}
            loadingOptions={loading}
            isInactive={getValues('instruction')?.active === false}
          />
        ) : (
          <SelectControl
            control={control}
            name="instruction"
            label={t('product.editorial.instruction.label')}
            options={options}
            loadingOptions={loading}
            isInactive={getValues('instruction')?.active === false}
          />
        )}

        <div className="mt-6 flex justify-end">
          <Button
            loading={updateInstructionLoading || createInstructionLoading}
            disabled={!isDirty || updateInstructionLoading || createInstructionLoading}
            type="submit">
            {t('global.actions.save')}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default AssemblyInstructionOption;
