import { gql } from 'graphql.macro';

export const importOffersInformationsMutation = gql`
  mutation ImportOffersInformations($id: ID!, $from_product_id: ID!, $country_ids: Mixed!) {
    importOffersInformations(
      id: $id
      from_product_id: $from_product_id
      country_ids: $country_ids
    ) {
      id
    }
  }
`;
