import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { Product } from 'types/Product';
import useCheckUrlImage from '../../Products/Hooks/useCheckUrlImage';

type Props = {
  product: Product;
  queryLoading: boolean;
};

const ResultView = ({ product, queryLoading }: Props) => {
  const [logo, setLogo] = useState(null);
  const isValidImage = useCheckUrlImage(product?.primaryImage?.clientUrl ?? '');

  const { getValues, watch } = useFormContext();

  watch('madeIn', null);

  useEffect(() => {
    const subscription = watch(() => {
      if (getValues('madeIn')) {
        setLogo(getValues('madeIn').url);
        return;
      } else if (getValues('brand')) {
        setLogo(getValues('brand').url);
      } else {
        setLogo(null);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch, getValues]);

  return (
    <div className="p-6">
      <div className="flex h-10 justify-end">
        {logo && (
          <div className="flex w-full">
            <img src={logo} />
          </div>
        )}
      </div>
      <div className="h-48 ">
        <div className="mb-2 h-full">
          {queryLoading ? (
            <div className="flex h-full justify-center">
              <div className="skeleton h-60 w-80" />
            </div>
          ) : isValidImage ? (
            <div className="flex h-full justify-center">
              <img src={product?.primaryImage?.clientUrl} className="h-full" />
            </div>
          ) : (
            <PhotoIcon className="h-40 w-full text-secondary" />
          )}
        </div>
      </div>
    </div>
  );
};

export default ResultView;
