import { gql } from 'graphql.macro';

export const updateImportPackageMutation = gql`
  mutation UpdateImportPackage(
    $id: ID!
    $weight: Int!
    $volume: Float!
    $label: String!
    $label_for_supplier: String!
    $moq: Int!
    $price: Float
    $price_currency_id: ID
  ) {
    updateProductImportPackage(
      id: $id
      weight: $weight
      volume: $volume
      label: $label
      label_for_supplier: $label_for_supplier
      moq: $moq
      price: $price
      price_currency_id: $price_currency_id
    ) {
      id
    }
  }
`;
