import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { debounce } from 'lodash';
import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import InputText from 'components/Common/InputText';
import { Button } from 'components/Common/Button';
import { productsQuery } from 'graphql/Queries/Products/FutureArrivals/products';
import { Product } from 'types/Product';
import { ID, OptionProps } from 'types/Global';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import ProductInfos from 'components/Common/ProductInfos';
import ProgressionBadge from 'components/Common/ProgressionBadge';
import Filters from './Filters';
import Pagination from 'components/Common/Pagination';

type DatePicker = {
  startDate: string | null;
  endDate: string | null;
};

type Completion = {
  start: number;
  end: number;
};

export type FiltersProps = {
  productName: string;
  supplierId: ID;
  supplier: OptionProps | null;
  departureDate: DatePicker;
  arrivalDate: DatePicker;
  completion: Completion;
};

const Index = () => {
  const { t } = useTranslation();

  const [openFiltersSlide, setOpenFiltersSlide] = useState(false);
  const [filters, setFilters] = useState<FiltersProps>({
    productName: '',
    supplierId: 0,
    supplier: null,
    departureDate: {
      startDate: null,
      endDate: null,
    },
    arrivalDate: {
      startDate: null,
      endDate: null,
    },
    completion: {
      start: 0,
      end: 100,
    },
  });

  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState('ASC');
  const [orderBy, setOrderBy] = useState('id');

  const [searchProduct, { data, loading, error }] = useLazyQuery(productsQuery);

  const setProductName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((filters) => {
      return {
        productName: e.target.value,
        supplierId: filters.supplierId,
        supplier: filters.supplier,
        departureDate: filters.departureDate,
        arrivalDate: filters.arrivalDate,
        completion: filters.completion,
      } as FiltersProps;
    });
    setPage(1);
  };

  const debounceChangeProductName = useMemo(() => debounce(setProductName, 500), []);

  useEffect(() => {
    searchProduct({
      variables: {
        page: page ?? 1,
        perPage: 5,
        direction,
        orderBy,
        productName: filters.productName,
        supplierId: filters.supplierId,
        departureDate: filters.departureDate,
        arrivalDate: filters.arrivalDate,
        completion: filters.completion,
      },
    });
  }, [page, filters, direction, orderBy, searchProduct]);

  const products = data?.allProductsWithArrival?.data;
  const paginator = data?.allProductsWithArrival?.paginatorInfo;

  const changeDirection = () => {
    if (direction === 'ASC') {
      setDirection('DESC');
    } else if (direction === 'DESC') {
      setDirection('ASC');
    }
  };

  const sortBy = (sort: string) => {
    if (orderBy === sort) {
      changeDirection();
    } else {
      setDirection('ASC');
    }
    setOrderBy(sort);
  };

  return (
    <>
      <Filters
        open={openFiltersSlide}
        setOpen={setOpenFiltersSlide}
        setPage={setPage}
        setFilters={setFilters}
        defaultValues={filters}
      />
      <div className="container mx-auto">
        <div className="space-y-6 p-6 lg:space-y-12 lg:p-12">
          {/* Title + create button */}
          <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 text-dark sm:truncate sm:text-3xl sm:tracking-tight">
                {t('products.future-arrivals.title')}
              </h2>
            </div>
          </div>
          {/* Search + filters button */}
          <div className="space-y-4 md:flex md:items-center md:justify-start md:space-x-3 md:space-y-0">
            <div className="w-full max-w-lg">
              <InputText
                icon={<MagnifyingGlassIcon className="h-5 w-5 shrink-0 text-secondary" />}
                placeholder={t('global.search')}
                onChange={debounceChangeProductName}
              />
            </div>
            <div className="flex w-full items-center justify-between ">
              <Button
                type="button"
                theme="secondary"
                disabled={loading}
                onClick={() => setOpenFiltersSlide(true)}>
                <div className="flex space-x-2">
                  <AdjustmentsHorizontalIcon className="w-5" />
                  <span>{t('products.future-arrivals.filters.title')}</span>
                </div>
              </Button>
              {paginator && paginator.total > 0 && (
                <div className="italic text-dark">
                  {paginator.total} {t('products.future-arrivals.results')}
                </div>
              )}
            </div>
          </div>
          {/* Result */}
          {error && <ApolloErrorAlert error={error} />}
          {!error && !loading && !products?.length && (
            <InformationAlert>{t('products.no-results')}</InformationAlert>
          )}
          {(loading || products?.length > 0) && (
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-dark">
                    <div className="flex items-center">
                      {t('products.future-arrivals.product')}
                      {orderBy !== 'id' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-dark"
                          onClick={() => sortBy('id')}
                        />
                      ) : direction === 'ASC' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('id')}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('id')}
                        />
                      )}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark 2xl:table-cell">
                    {t('products.future-arrivals.supplier')}
                  </th>
                  <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-dark">
                    <div className="text-right md:text-left">
                      {t('products.future-arrivals.purchase-order')}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark xl:table-cell">
                    <div className="flex items-center">
                      {t('products.future-arrivals.departure-date')}
                      {orderBy !== 'firstDepartureDate' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-dark"
                          onClick={() => sortBy('firstDepartureDate')}
                        />
                      ) : direction === 'ASC' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('firstDepartureDate')}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('firstDepartureDate')}
                        />
                      )}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="hidden justify-end px-3 py-3.5 text-left text-sm font-semibold text-dark md:flex 2xl:table-cell">
                    <div className="flex items-center">
                      {t('products.future-arrivals.arrival-date')}
                      {orderBy !== 'firstArrivalDate' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-dark"
                          onClick={() => sortBy('firstArrivalDate')}
                        />
                      ) : direction === 'ASC' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('firstArrivalDate')}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('firstArrivalDate')}
                        />
                      )}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="hidden justify-end px-3 py-3.5 text-sm font-semibold text-dark 2xl:flex">
                    <div className="flex items-center">
                      {t('products.future-arrivals.completion')}
                      {orderBy !== 'completionPercentage' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-dark"
                          onClick={() => sortBy('completionPercentage')}
                        />
                      ) : direction === 'ASC' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('completionPercentage')}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('completionPercentage')}
                        />
                      )}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {loading &&
                  [...Array(5)].map((e, i) => (
                    <tr key={i}>
                      <td className="py-5 pl-4 pr-3">
                        <div className="flex items-center">
                          <div className="skeleton h-16 w-16" />
                          <div className="ml-4">
                            <div className="skeleton h-4 w-16" />
                            <div className="skeleton mt-1 h-4 w-40 md:w-60 xl:w-80" />
                            <div className="skeleton mt-1 h-4 w-20 md:w-40" />
                          </div>
                        </div>
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-4 w-full" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-4 w-full" />
                      </td>
                      <td className="hidden px-3 py-3.5 xl:table-cell">
                        <div className="skeleton h-4 w-full" />
                      </td>
                      <td className="hidden px-3 py-3.5 md:table-cell">
                        <div className="skeleton h-4 w-full" />
                      </td>
                      <td className="hidden justify-end px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-4 w-full" />
                      </td>
                    </tr>
                  ))}
                {products?.map((product: Product) => (
                  <tr key={product.id}>
                    <td className="max-w-[250px] py-3.5 pl-4 pr-3 text-sm sm:max-w-xs">
                      <ProductInfos product={product} />
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 2xl:table-cell">
                      <Tippy content={product.supplier?.name}>
                        <Link to={`/suppliers/${product.supplier?.id}`} className="flex w-fit">
                          <div className="max-w-xs truncate text-blue-600 hover:text-blue-700">
                            {product.supplier?.name}
                          </div>
                        </Link>
                      </Tippy>
                    </td>
                    <td className="px-3 py-3.5 text-sm text-gray-500">
                      <div className="flex justify-end md:justify-start">
                        <Link
                          to={product.firstArrivalPurchaseOrder?.legacyUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="flex w-fit">
                          <div className="truncate text-blue-600 hover:text-blue-700">
                            {product.firstArrivalPurchaseOrder?.label}
                          </div>
                        </Link>
                      </div>
                    </td>
                    <td className="hidden truncate px-3 py-3.5 text-sm text-gray-500 xl:table-cell">
                      {product.firstDepartureDate
                        ? moment(product.firstDepartureDate).format('Do MMMM YYYY')
                        : t('global.unknown-date')}
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell ">
                      <div className="truncate text-right 2xl:text-left">
                        {product.firstArrivalDate
                          ? moment(product.firstArrivalDate).format('Do MMMM YYYY')
                          : t('global.unknown-date')}
                      </div>
                    </td>
                    <td className="hidden truncate px-3 py-3.5 text-sm text-gray-500 2xl:table-cell">
                      <ProgressionBadge
                        completionPercentage={product.completionPercentage}
                        missingInformations={product.missingInformations}
                        isEnd
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
              {paginator && products?.length > 0 && (
                <tfoot>
                  <tr>
                    <td className="py-3.5" colSpan={6}>
                      <Pagination paginator={paginator} setPage={setPage} />
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
