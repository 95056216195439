import React, { useEffect, useState } from 'react';
import { MODULES, FORMAT } from 'global';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import ReactQuill, { Value } from 'react-quill';
import useNotification from 'hooks/useNotification';
import { updateConsultingMaintenanceMutation } from 'graphql/Mutations/Product/Editorial/updateConsultingMaintenance';
import { ID } from 'types/Global';
import { editorialQuery } from 'graphql/Queries/Product/Editorial/editorial';
import { useParams } from 'react-router-dom';
import { createProductTranslationMutation } from 'graphql/Mutations/Product/Editorial/createProductTranslation';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

interface MaintenanceDescriptionProps {
  maintenanceDescription: string | null;
  translationId: ID | null;
  isLoading: boolean;
  setTranslationRequestDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  langId: ID;
}

const MaintenanceDescription = ({
  maintenanceDescription,
  translationId,
  isLoading,
  setTranslationRequestDisabled,
  langId,
}: MaintenanceDescriptionProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification, setError } = useNotification();
  const [wordCount, setWordCount] = useState(0);

  const [createProductTranslation, { loading: createProductTranslationLoading }] = useMutation(
    createProductTranslationMutation
  );
  const [updateMaintenanceDescription, { loading: updateMaintenanceDescriptionLoading }] =
    useMutation(updateConsultingMaintenanceMutation);

  const mutationLoading = createProductTranslationLoading || updateMaintenanceDescriptionLoading;

  const {
    formState: { isDirty },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      maintenance: maintenanceDescription,
    },
  });

  const handleEditorChange = (value: string) => {
    const text = value.replace(/<[^>]+>/g, '');
    const words = text.trim().split(/\s+/);
    setWordCount(words.length);

    setValue('maintenance', value);
  };

  useEffect(() => {
    if (maintenanceDescription?.replace(/(<([^>]+)>)/gi, '')) {
      setTranslationRequestDisabled(false);
    }
    reset({
      maintenance: maintenanceDescription,
    });

    if (maintenanceDescription) {
      const text = maintenanceDescription.replace(/<[^>]+>/g, '');
      const words = text.trim().split(/\s+/);
      setWordCount(words.length);
    }
  }, [maintenanceDescription, reset, setTranslationRequestDisabled]);

  const onSubmit = handleSubmit(async (data) => {
    let translationIdToUpdate = translationId;
    if (!translationIdToUpdate) {
      await new Promise<void>((resolve, reject) => {
        createProductTranslation({
          variables: {
            product_id: params.productId,
            lang_id: langId,
          },
          onCompleted: (res) => {
            translationIdToUpdate = res.createProductTranslation.id;
            resolve();
          },
          onError: (error) => {
            setError(error);
            reject(error);
          },
        });
      });
    }
    setTranslationRequestDisabled(true);
    updateMaintenanceDescription({
      refetchQueries: [
        {
          query: editorialQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: translationIdToUpdate,
        maintenance_description: data.maintenance,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.editorial.maintenance-description.notif-success'),
          type: 'success',
        });
        reset(data);
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  return (
    <Card title={t('product.editorial.maintenance-description.title')}>
      <div className="space-y-2">
        <div className="ml-4 flex justify-end text-sm text-secondary">
          {t('product.editorial.word-count')}{' '}
          {isLoading ? <div className="skeleton ml-2 mt-1 h-[15px] w-[18px]"></div> : wordCount}
        </div>
        <form onSubmit={onSubmit}>
          {isLoading ? (
            <div className="skeleton h-[393px]"></div>
          ) : (
            <Controller
              control={control}
              name="maintenance"
              render={({ field }) => (
                <ReactQuill
                  style={{ height: '350px' }}
                  theme="snow"
                  value={field.value as Value}
                  modules={MODULES}
                  formats={FORMAT}
                  onChange={(value) => {
                    field.onChange(value);
                    handleEditorChange(value);
                  }}
                  readOnly={isLoading}
                />
              )}
            />
          )}
          <div className={`${isLoading ? 'mt-6' : 'mt-16'} flex justify-end`}>
            <Button
              loading={mutationLoading}
              disabled={!isDirty || isLoading || mutationLoading}
              type="submit">
              {t('global.actions.save')}
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default MaintenanceDescription;
