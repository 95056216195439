import { gql } from 'graphql.macro';

export const supplierPurchaseOrderCommentQuery = gql`
  query SupplierPurchaseOrderComment($id: ID!) {
    supplier(id: $id) {
      id
      purchase_order_comment
    }
  }
`;
