import { gql } from 'graphql.macro';

export const sendTranslationRequestsMutation = gql`
  mutation SendTranslationRequests($product_id: ID!, $languages: [ID]!, $modificationIds: [Int]!) {
    sendTranslationRequests(
      product_id: $product_id
      languages: $languages
      modificationIds: $modificationIds
    )
  }
`;
