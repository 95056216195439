import { gql } from 'graphql.macro';

export const searchProductTypesByCategoryIdQuery = gql`
  query SearchProductTypesByCategoryId($category_id: ID!) {
    productCategory(id: $category_id) {
      id
      types {
        id
        label
        is_active
        productCreationGoals {
          id
          fiscal_year_id
          goal
          user {
            id
            firstname
            lastname
          }
          updated_at
        }
      }
    }
  }
`;
