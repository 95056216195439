import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Avatar from 'react-avatar';
import { UserContext } from 'App';

// TODO: Move in interface file (user.ts for example)
interface User {
  id: string | number;
  fullname: string;
}

const Avatars = () => {
  const params = useParams();
  const [connectedUsers, setConnectedUsers] = useState<User[]>([]);
  const { user } = useContext(UserContext);

  useEffect(() => {
    window.Echo.join(`onlineProduct.${params.productId}`)
      .here((users: User[]) => {
        setConnectedUsers(users);
      })
      // When on Strict Mode, when user join, it gets added twice, dispite the condition .. .. .. 💩
      .joining((user: User) => {
        setConnectedUsers((oldUsers) => [...oldUsers, user]);
      })
      .leaving((user: User) => {
        setConnectedUsers((oldUsers) => oldUsers.filter((u) => u.id !== user.id));
      })
      .error((error: string) => {
        console.error(error);
      });

    return () => {
      window.Echo.leave(`onlineProduct.${params.productId}`);
    };
  }, [params]);

  const noDoppelgangersConnectedUsers = () => {
    const uniqueIds: (string | number)[] = [];

    const unique = connectedUsers.filter((element) => {
      const isDuplicate = uniqueIds.includes(element.id);
      if (!isDuplicate) {
        uniqueIds.push(element.id);
        return true;
      }
      return false;
    });
    return unique;
  };

  const showAvatars = () => {
    const persons = noDoppelgangersConnectedUsers();

    const index = persons.findIndex(
      (person: User) => parseInt(person.id as string) === parseInt(JSON.parse(user as string)?.id)
    );
    persons.splice(index, 1);
    const filteredPersons = [];
    if (persons.length > 3) {
      for (let index = 0; index < 3; index++) {
        const person = persons[index];
        filteredPersons.push(person);
      }
      return filteredPersons;
    }
    return persons;
  };

  const getHoverNames = () => {
    const persons = noDoppelgangersConnectedUsers();
    let names = '';
    for (let index = 3; index < persons.length; index++) {
      const person = persons[index];
      if (index == 3) {
        names += person.fullname;
      } else {
        names += '\r\n' + person.fullname;
      }
    }
    return names;
  };

  return (
    <div>
      {showAvatars().map((connectedUser) => (
        <Avatar
          key={connectedUser.id}
          name={connectedUser.fullname}
          round
          size="40"
          className="mx-1"
        />
      ))}
      {noDoppelgangersConnectedUsers().length > 3 && (
        <Avatar
          value={'+' + (noDoppelgangersConnectedUsers().length - 3).toString()}
          round
          size="40"
          title={getHoverNames()}
          className="mx-1"
        />
      )}
    </div>
  );
};

export default Avatars;
