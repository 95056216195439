import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@apollo/client';
import { updateImportPackageMutation } from 'graphql/Mutations/Product/Packaging/updateImportPackage';
import { ImportPackagingQuery } from 'graphql/Queries/Product/Packaging/importPackaging';
import { ProductImportPackage } from 'types/Product';
import { CURRENCY_EURO_ID } from 'global';
import SlideOver from 'components/Common/SlideOver';
import FormContent from './FormContent';
import useNotification from 'hooks/useNotification';
import * as yup from 'yup';

interface UpdatePackageProps {
  importPackage: ProductImportPackage | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const UpdatePackage = ({ importPackage, setOpen, open }: UpdatePackageProps) => {
  const params = useParams();
  const { t } = useTranslation();
  const { setNotification } = useNotification();

  const [updateProductPackage, { loading, error }] = useMutation(updateImportPackageMutation);

  const schema = yup.object().shape({
    name: yup.string().required(t('form.required')),
    reference: yup.string().required(t('form.required')),
    weight: yup
      .number()
      .integer(t('form.number.integer'))
      .min(1, t('form.required'))
      .required(t('form.required')),
    volume: yup.number().min(0.1, t('form.required')).required(t('form.required')),
    moq: yup
      .number()
      .integer(t('form.number.integer'))
      .min(1, t('form.required'))
      .required(t('form.required')),
    price: yup
      .number()
      .typeError(t('form.number.positive'))
      .min(0, t('form.number.positive'))
      .test({
        name: 'requiredIfDirtyCurrencyCode',
        test: function (value) {
          if (dirtyFields.currency_id && (value === undefined || value === null)) {
            throw this.createError({
              message: t('form.number.positive'),
            });
          }

          return true;
        },
      })
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr)),
    currency_id: yup.string().test({
      name: 'currency_id',
      test: function (value) {
        if (value === '0') {
          return false;
        }
        return true;
      },
      message: t('form.must-choose-currency'),
    }),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty, dirtyFields },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    if (!importPackage) return;
    reset({
      name: importPackage?.label,
      weight: importPackage?.weight,
      volume: importPackage?.volume / 1000 / 1000,
      reference: importPackage?.label_for_supplier,
      moq: importPackage?.moq,
      price: importPackage?.price,
      currency_id: importPackage?.currency?.id ?? CURRENCY_EURO_ID,
    });
  }, [importPackage, reset]);

  const onSubmit = handleSubmit((data) => {
    let priceCurrencyId = null;
    if (data.price) {
      priceCurrencyId = data.currency_id;
    }

    updateProductPackage({
      variables: {
        id: importPackage?.id,
        weight: data.weight,
        volume: data.volume * 1000 * 1000,
        label: data.name,
        label_for_supplier: data.reference,
        moq: data.moq,
        price: data.price,
        price_currency_id: priceCurrencyId,
      },
      refetchQueries: [
        {
          query: ImportPackagingQuery,
          variables: { id: params.productId },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setOpen(false);
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.import.success'),
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    reset();
    setOpen(false);
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.packaging.import.update-title')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDirty={isDirty}
      loading={loading}
      error={error}>
      <FormContent control={control} />
    </SlideOver>
  );
};

export default UpdatePackage;
