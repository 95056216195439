import { gql } from 'graphql.macro';

export const productActiveOffersQuery = gql`
  query ProductActiveOffers($id: ID!) {
    product(id: $id) {
      id
      ecotax
      is_rewarded_for_waiting
      is_rewarded_for_waiting_foreign
      activeOffers {
        id
        country {
          id
          iso
          name
          salesPeriod {
            start
            end
          }
          isDuringSales
        }
        priceWithEcotax
        price_without_ecotax
        highestPrice
        futurePriceWithEcotax
        percentDiscount
        is_on_sales
        isPromotion
        markdown
        startDiscountDate
        endDiscountDate
        isDiscount
        history {
          id
          user {
            id
            firstname
            lastname
          }
          logs
          created_at
        }
      }
    }
  }
`;
