import { ApolloError } from '@apollo/client';
import { TRANSPARENT_ERRORS } from 'global';
import { useTranslation } from 'react-i18next';

type Props = {
  apolloError?: ApolloError | null;
};

const useApolloErrorMessage = ({ apolloError }: Props) => {
  const { t } = useTranslation();
  if (!apolloError) return '';

  let message = '';
  if (TRANSPARENT_ERRORS.includes(apolloError.graphQLErrors[0]?.extensions?.category as string)) {
    message = apolloError.graphQLErrors[0].message;
  } else if (apolloError.graphQLErrors[0]?.extensions?.category === 'authentication') {
    message = t('global.auth-error');
  } else {
    message = t('global.error-msg');
  }
  return message;
};

export default useApolloErrorMessage;
