import { SparklesIcon } from '@heroicons/react/20/solid';
import React, { ReactElement } from 'react';
import { Button } from './Button';

type Props = {
  title?: string;
  secondaryTitle?: string;
  children?: ReactElement;
  addOnEnd?: ReactElement;
  aiButtonText?: string;
  loading?: boolean;
  onAiButtonClick?: () => void;
};

const Card = ({
  title,
  secondaryTitle,
  children,
  addOnEnd,
  aiButtonText,
  loading,
  onAiButtonClick,
}: Props) => {
  return (
    <div className="h-full rounded-md border bg-white">
      {title && (
        <div className="flex justify-between items-center border-b border-gray-200 px-4 py-5 sm:px-6">
          <div className="flex w-full justify-between">
            <h3 className="text-base font-semibold leading-6 text-dark">{title}</h3>
            {addOnEnd}
          </div>
          <div className="flex gap-2 justify-end w-full">
            {secondaryTitle && <p className="text-sm text-secondary">{secondaryTitle}</p>}
            {aiButtonText && (
              <Button type="button" onClick={onAiButtonClick} disabled={loading}>
                <div className="flex items-center space-x-3">
                  <SparklesIcon className="w-6 text-white" />
                  <span>{aiButtonText}</span>
                </div>
              </Button>
            )}{' '}
          </div>
        </div>
      )}
      <div className="h-full px-4 py-5 sm:px-6">{children}</div>
    </div>
  );
};

export default Card;
