import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { uploadImageMutation } from 'graphql/Mutations/Product/Header/AddTemporaryImage';
import { useMutation } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { IMAGE_TYPE } from 'utils/imageType';
import useNotification from 'hooks/useNotification';

type Props = {
  setLoadingTemporaryImage: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddTemporaryImage = ({ setLoadingTemporaryImage }: Props) => {
  const { setNotification, setError } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [uploadTemporaryImage] = useMutation(uploadImageMutation);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setLoadingTemporaryImage(true);
      uploadTemporaryImage({
        variables: {
          file: acceptedFiles[0],
          product_id: params.productId,
          type_id: IMAGE_TYPE.TEMPORARY,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: productHeaderQuery,
            variables: {
              id: params.productId,
            },
          },
        ],
        onCompleted: () => {
          setNotification({
            title: t('global.success'),
            message: t('product.images.notif.add-temporary-image-success'),
            type: 'success',
          });
          setLoadingTemporaryImage(false);
        },
        onError: (e) => {
          setLoadingTemporaryImage(false);
          setError(e);
        },
      });
    },
    [uploadTemporaryImage, params, setNotification, setLoadingTemporaryImage, setError, t]
  );

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        className="absolute left-1/2 top-1/2 flex h-full w-full -translate-x-1/2 -translate-y-1/2 transform cursor-pointer items-center justify-center rounded-full bg-gray-200 p-2 opacity-0 transition-opacity group-hover:opacity-100">
        <input {...getInputProps()} id="fileInput" accept={'image/*'} />
        <div className="flex items-center justify-center">{t('product.header.edit')}</div>
      </div>
    </div>
  );
};

export default AddTemporaryImage;
