import React, { ReactElement } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { ApolloErrorAlert } from './Alerts';
import { Button } from './Button';

type Props = {
  children: ReactElement;
  title: string;
  buttonText: string;
  loading?: boolean;
  error?: ApolloError;
  isDirty?: boolean;
  open: boolean;
  size?: string;
  setOpen: (isOpen: boolean) => void;
  onSubmit?: () => void;
  onCancel: () => void;
};

const SlideOver = ({
  children,
  title,
  buttonText,
  loading,
  error,
  isDirty = true,
  open,
  size = 'max-w-lg',
  setOpen,
  onSubmit,
  onCancel,
}: Props) => {
  const { t } = useTranslation();

  const onClose = () => {
    if (loading) return;
    setOpen(false);
    onCancel();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel className={`pointer-events-auto w-screen ${size}`}>
                  <form className="flex h-full flex-col bg-white shadow-xl" onSubmit={onSubmit}>
                    <div className="flex min-h-0 flex-1 flex-col pt-6">
                      <div className="px-4 sm:px-6">
                        <div className="flex items-start justify-between">
                          <Dialog.Title className="text-xl font-semibold leading-6 text-primary-500">
                            {title}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500"
                              onClick={onClose}>
                              <span className="absolute -inset-2.5" />
                              <span className="sr-only">{t('slide-over.close-panel')}</span>
                              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-6 flex-1 overflow-y-auto px-4 pb-6 sm:px-6">
                        {children}
                      </div>
                    </div>
                    {error && (
                      <div className="p-4">
                        <ApolloErrorAlert error={error} />
                      </div>
                    )}
                    <div className="flex flex-shrink-0 justify-end space-x-3 px-4 py-4">
                      <Button
                        type="button"
                        onClick={onCancel}
                        theme={'secondary'}
                        disabled={loading}>
                        {t('global.actions.cancel')}
                      </Button>
                      <Button type="submit" loading={loading} disabled={!isDirty || loading}>
                        {buttonText}
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default SlideOver;
