import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { productsTypesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productsTypes';
import { createProductTypeMutation } from 'graphql/Mutations/Settings/Products/Taxonomy/createProductType';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';
import * as yup from 'yup';
import { InputTextControl } from 'components/Common/InputTextControl';
import { ProductCategory } from 'types/Product';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedCategory: ProductCategory | null;
};

const CreateTypeSlide = ({ open, setOpen, selectedCategory }: Props) => {
  const { setNotification } = useNotification();

  const [createType, { error, loading, reset: resetMutation }] =
    useMutation(createProductTypeMutation);

  const schema = yup.object().shape({
    label: yup.string().max(50, t('form.string.max')),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    reset({
      label: '',
    });
  }, [reset]);

  const close = () => {
    setOpen(false);
    reset();
    resetMutation();
  };

  const onSubmit = handleSubmit((data) => {
    createType({
      variables: {
        label: data.label,
        category_id: selectedCategory?.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: productsTypesQuery, variables: { id: selectedCategory?.id } }],

      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('settings.taxonomy.type.create-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('settings.taxonomy.actions.create-type')}
      buttonText={t('global.actions.create')}
      isDirty={isDirty}
      onSubmit={onSubmit}
      onCancel={close}
      loading={loading}
      error={error}>
      <div className="space-y-6">
        <InputTextControl label={t('global.name')} name="label" control={control} isRequired />
      </div>
    </SlideOver>
  );
};
export default CreateTypeSlide;
