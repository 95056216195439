import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import Checkbox from 'components/Common/Checkbox';
import { InputTextControl } from 'components/Common/InputTextControl';
import SlideOver from 'components/Common/SlideOver';
import { ExportableElements } from 'types/Product';
import { useMutation } from '@apollo/client';
import { duplicateProductMutation } from 'graphql/Mutations/Product/duplicateProduct';
import { useNavigate, useParams } from 'react-router-dom';
import useNotification from 'hooks/useNotification';

type Props = {
  exportableElements: ExportableElements;
};

const Duplication = ({ exportableElements }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const navigate = useNavigate();

  const { setNotification } = useNotification();

  const [open, setOpen] = useState(false);
  const [elementsToDuplicate, setElementsToDuplicate] =
    useState<ExportableElements>(exportableElements);

  const inputRef = useRef(null);

  const [duplicateProduct, { loading, error }] = useMutation(duplicateProductMutation);

  const { handleSubmit, reset, control } = useForm<FieldValues>({
    defaultValues: {
      title: '',
    },
  });

  useEffect(() => {
    setElementsToDuplicate(exportableElements);
  }, [exportableElements, setElementsToDuplicate]);

  const onSubmit = handleSubmit((data) => {
    duplicateProduct({
      variables: {
        id: params.productId,
        title: data.title,
        elementsToDuplicate,
      },
      onCompleted: (data) => {
        navigate(`/products/${data.duplicateProduct.id}/preview`);
        setNotification({
          title: t('global.success'),
          message: t('product.duplicate.success'),
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    reset();
    setOpen(false);
  };

  const onChangeCheckbox = (value: boolean, key: string) => {
    setElementsToDuplicate((prevElements) => {
      return {
        ...prevElements,
        [key]: value,
      };
    });
  };

  return (
    <>
      <Tippy content={t('global.actions.duplicate')}>
        <button
          type="button"
          className="rounded-full bg-white p-3 text-dark shadow hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
          onClick={() => setOpen(true)}>
          <DocumentDuplicateIcon className="w-6" aria-hidden="true" />
        </button>
      </Tippy>
      <SlideOver
        title={t('product.duplicate.title')}
        open={open}
        setOpen={setOpen}
        buttonText={t('global.actions.duplicate')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        error={error}
        loading={loading}>
        <div className="space-y-6">
          <InputTextControl
            control={control}
            name="title"
            label={t('product.duplicate.label-title')}
            ref={inputRef}
            isFocused
          />
          <label className="block text-sm font-medium leading-6 text-dark">
            {t('product.duplicate.label-elements')}
          </label>
          <div className="space-y-3">
            <Checkbox
              label={t('product.duplicate.editorial')}
              defaultChecked={exportableElements?.editorial}
              disabled={!exportableElements?.editorial}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'editorial')}
            />
            <Checkbox
              label={t('product.duplicate.taxonomy')}
              defaultChecked={exportableElements?.taxonomy}
              disabled={!exportableElements?.taxonomy}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'taxonomy')}
            />
            <Checkbox
              label={t('product.duplicate.model-style')}
              defaultChecked={exportableElements?.modelStyle}
              disabled={!exportableElements?.modelStyle}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'modelStyle')}
            />
            <Checkbox
              label={t('product.duplicate.features')}
              defaultChecked={exportableElements?.features}
              disabled={!exportableElements?.features}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'features')}
            />
            <Checkbox
              label={t('product.duplicate.customs-nomenclature')}
              defaultChecked={exportableElements?.customsNomenclature}
              disabled={!exportableElements?.customsNomenclature}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'customsNomenclature')}
            />
            <Checkbox
              label={t('product.duplicate.brand')}
              defaultChecked={exportableElements?.brand}
              disabled={!exportableElements?.brand}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'brand')}
            />
            <Checkbox
              label={t('product.duplicate.offers')}
              defaultChecked={exportableElements?.offers}
              disabled={!exportableElements?.offers}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'offers')}
            />
            <Checkbox
              label={t('product.duplicate.ecotax')}
              defaultChecked={exportableElements?.ecotax}
              disabled={!exportableElements?.ecotax}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'ecotax')}
            />
            <Checkbox
              label={t('product.duplicate.packaging')}
              defaultChecked={exportableElements?.packaging}
              disabled={!exportableElements?.packaging}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'packaging')}
            />
            <Checkbox
              label={t('product.duplicate.supply')}
              defaultChecked={exportableElements?.supply}
              disabled={!exportableElements?.supply}
              onChange={(e) => onChangeCheckbox(e.target.checked, 'supply')}
            />
          </div>
        </div>
      </SlideOver>
    </>
  );
};

export default Duplication;
