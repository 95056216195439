import { gql } from 'graphql.macro';

export const searchProductCategoriesByUniverseIdQuery = gql`
  query SearchProductCategoriesByUniverseId($universe_id: ID!) {
    productUniverse(id: $universe_id) {
      id
      categories {
        id
        label
        is_active
      }
    }
  }
`;
