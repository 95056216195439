import React from 'react';
import { Fragment, useCallback, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import { useMutation } from '@apollo/client';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { verifyFileSizeDocument, verifyFileExtensionsDocument } from 'utils/Utils';
import { ApolloErrorAlert, ErrorAlert } from 'components/Common/Alerts';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { uploadProductNoticeMutation } from 'graphql/Mutations/Product/Documents/Notices/uploadProductNotice';
import { noticesQuery } from 'graphql/Queries/Product/Documents/Notices/notices';
import { Button } from 'components/Common/Button';
import useNotification from 'hooks/useNotification';

type Props = {
  onClose: () => void;
  openModal: boolean;
};

function AddNoticeModal({ onClose, openModal }: Props) {
  const params = useParams();
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const cancelButtonRef = useRef(null);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadError, setUploadError] = useState<string[]>([]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      const fileIsValid = verifyFileSizeDocument(acceptedFiles);
      const extensionsIsValid = verifyFileExtensionsDocument(acceptedFiles);

      if (!fileIsValid) {
        setUploadError([t('product.documents.file-too-large')]);
        return;
      }

      if (!extensionsIsValid) {
        setUploadError([t('product.documents.prohibited-file-type')]);
        return;
      }

      setSelectedFile(acceptedFiles[0]);
      setIsFilePicked(true);
      setUploadError([]);
    },
    [t]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [uploadNotice, { loading, error, reset }] = useMutation(uploadProductNoticeMutation);

  const closeModalHandler = () => {
    clearUploadFile();
    onClose();
  };

  const clearUploadFile = () => {
    setSelectedFile(null);
    setIsFilePicked(false);
    setUploadError([]);
    reset();
  };

  const saveHandler = () => {
    uploadNotice({
      variables: {
        file: selectedFile,
        product_id: params.productId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: noticesQuery,
          variables: {
            product_id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.documents.notices.add-notice'),
          type: 'success',
        });
        closeModalHandler();
      },
    });
  };

  const clearAll = () => {
    onClose();
  };

  return (
    <Transition.Root show={openModal} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => clearAll}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="text-left">
                    <Dialog.Title as="h3" className="pb-4 text-lg font-medium leading-6 text-dark">
                      {t('product.documents.notices.add')}
                    </Dialog.Title>

                    {isFilePicked ? (
                      <div className="py-4">
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0">
                          <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                            <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                              <div className="flex w-0 flex-1 items-center">
                                <PaperClipIcon className="h-5 w-5 shrink-0 text-gray-400" />
                                <span className="ml-2 w-0 flex-1 truncate text-gray-500">
                                  {selectedFile?.name}
                                </span>
                              </div>
                              <div className="ml-4 shrink-0">
                                <button
                                  type="button"
                                  disabled={loading}
                                  className="font-medium text-primary-500 hover:text-primary-400"
                                  onClick={() => {
                                    clearUploadFile();
                                  }}>
                                  {t('global.actions.modify')}
                                </button>
                              </div>
                            </li>
                          </ul>
                        </dd>
                      </div>
                    ) : (
                      <div {...getRootProps()}>
                        <input
                          {...getInputProps()}
                          accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv"
                        />

                        <div className="mt-1 cursor-pointer sm:col-span-2 sm:mt-0">
                          <div
                            className={
                              'flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6  pt-5 ' +
                              (isDragActive ? 'bg-blue-100' : '')
                            }>
                            <div className="space-y-1 text-center">
                              <svg
                                className="mx-auto h-12 w-12 text-gray-400"
                                stroke="currentColor"
                                fill="none"
                                viewBox="0 0 48 48">
                                <path
                                  d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              <div className="flex items-center justify-center text-sm text-gray-600">
                                <p className="cursor-pointer rounded-md font-medium text-primary-500  hover:text-primary-400">
                                  {t('global.select-file-dnd.file')}
                                </p>
                                <p className="pl-1">{t('global.select-file-dnd.dnd')}</p>
                              </div>
                              <p className="text-xs text-gray-500">
                                {t('product.documents.notices.max-size')}
                              </p>
                              <p className="text-xs text-gray-500">
                                {t('product.documents.good-file-type')}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {error && (
                  <div className="">
                    <ApolloErrorAlert error={error} />
                  </div>
                )}
                {uploadError.length > 0 && (
                  <div className="mt-4">
                    <ErrorAlert>{uploadError.join(', ')}</ErrorAlert>
                  </div>
                )}
                <div>
                  <div className="mt-4 flex justify-end gap-4">
                    <Button
                      type="button"
                      theme="secondary"
                      disabled={loading}
                      onClick={() => closeModalHandler()}>
                      {t('global.actions.cancel')}
                    </Button>
                    <Button
                      type="button"
                      disabled={!isFilePicked || loading}
                      loading={loading}
                      onClick={() => saveHandler()}>
                      {t('global.actions.save')}
                    </Button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default AddNoticeModal;
