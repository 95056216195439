import React, { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { productsQuery } from 'graphql/Queries/Products/MarketingIntegration/products';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { useTranslation } from 'react-i18next';
import InputText from 'components/Common/InputText';
import {
  AdjustmentsHorizontalIcon,
  ChatBubbleLeftEllipsisIcon,
  MagnifyingGlassIcon,
  PencilSquareIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'components/Common/Button';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { Product } from 'types/Product';
import ProductInfos from 'components/Common/ProductInfos';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import Pagination from 'components/Common/Pagination';
import ProgressionBadge from 'components/Common/ProgressionBadge';
import { Filters } from './Filters';
import { debounce } from 'lodash';
import FormSlideOver from './FormSlideOver';
import Badge from 'components/Common/Badge';

type Completion = {
  start: number;
  end: number;
};

type DatePicker = {
  startDate: string | null;
  endDate: string | null;
};

export type FiltersProps = {
  productName: string;
  completion: Completion;
  arrivalDate: DatePicker;
  photoValidated: boolean | null;
  photoEditionValidated: boolean | null;
  integrationValidated: boolean | null;
};

const Index = () => {
  const { t } = useTranslation();
  const [openFiltersSlide, setOpenFiltersSlide] = useState(false);
  const [openFormSlide, setOpenFormSlide] = useState(false);
  const [product, setProduct] = useState<Product | null>(null);
  const [filters, setFilters] = useState<FiltersProps>({
    productName: '',
    completion: {
      start: 0,
      end: 100,
    },
    arrivalDate: {
      startDate: null,
      endDate: null,
    },
    photoValidated: null,
    photoEditionValidated: null,
    integrationValidated: null,
  });
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState('ASC');
  const [orderBy, setOrderBy] = useState<string>('weeksUntilArrival');

  const [searchProduct, { data, loading, error }] = useLazyQuery(productsQuery);

  const setProductName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters((filters) => {
      return {
        productName: e.target.value,
        photoValidated: filters.photoValidated,
        photoEditionValidated: filters.photoEditionValidated,
        integrationValidated: filters.integrationValidated,
        completion: filters.completion,
        arrivalDate: filters.arrivalDate,
      } as FiltersProps;
    });
    setPage(1);
  };

  const debounceChangeProductName = useMemo(() => debounce(setProductName, 500), []);

  useEffect(() => {
    searchProduct({
      fetchPolicy: 'network-only',
      variables: {
        page: page ?? 1,
        perPage: 5,
        orderBy,
        direction,
        productName: filters.productName,
        photoValidated: filters.photoValidated,
        photoEditionValidated: filters.photoEditionValidated,
        integrationValidated: filters.integrationValidated,
        arrivalDate: filters.arrivalDate,
        completion: filters.completion,
      },
    });
  }, [page, orderBy, direction, filters, searchProduct]);

  const changeDirection = () => {
    if (direction === 'ASC') {
      setDirection('DESC');
    } else if (direction === 'DESC') {
      setDirection('ASC');
    }
  };

  const sortBy = (sort: string) => {
    if (orderBy === sort) {
      changeDirection();
    } else {
      setDirection('ASC');
    }
    setOrderBy(sort);
  };

  const products = data?.allProductsMarketingIntegration?.data;
  const paginator = data?.allProductsMarketingIntegration?.paginatorInfo;

  const openForm = (product: Product) => {
    setOpenFormSlide(true);
    setProduct(product);
  };

  return (
    <>
      <Filters
        open={openFiltersSlide}
        setOpen={setOpenFiltersSlide}
        setPage={setPage}
        setFilters={setFilters}
        defaultValues={filters}
      />
      <FormSlideOver
        open={openFormSlide}
        setOpen={setOpenFormSlide}
        product={product}
        page={page}
        orderBy={orderBy}
        direction={direction}
        filters={filters}
      />
      <div className="container mx-auto">
        <div className="space-y-6 p-6 lg:space-y-12 lg:p-12">
          {/* Title + create button */}
          <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 text-dark sm:truncate sm:text-3xl sm:tracking-tight">
                {t('marketing-integration.title')}
              </h2>
            </div>
          </div>
          {/* Search + filters button */}
          <div className="space-y-4 md:flex md:items-center md:justify-start md:space-x-3 md:space-y-0">
            <div className="w-full max-w-lg">
              <InputText
                icon={<MagnifyingGlassIcon className="h-5 w-5 shrink-0 text-secondary" />}
                placeholder={t('global.search')}
                onChange={debounceChangeProductName}
              />
            </div>
            <div className="flex w-full items-center justify-between ">
              <Button type="button" theme="secondary" onClick={() => setOpenFiltersSlide(true)}>
                <div className="flex space-x-2">
                  <AdjustmentsHorizontalIcon className="w-5" />
                  <span>{t('marketing-integration.filters.title')}</span>
                </div>
              </Button>
              {paginator && paginator.total > 0 && (
                <div className="italic text-dark">
                  {paginator.total} {t('marketing-integration.results')}
                </div>
              )}
            </div>
          </div>
          {/* Result */}
          {error && <ApolloErrorAlert error={error} />}
          {!error && !loading && !products?.length && (
            <InformationAlert>{t('products.no-results')}</InformationAlert>
          )}
          {(loading || products?.length > 0) && (
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-dark">
                    <div className="flex items-center">
                      {t('marketing-integration.table.header.product')}
                      {orderBy !== 'id' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-dark"
                          onClick={() => sortBy('id')}
                        />
                      ) : direction === 'ASC' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('id')}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('id')}
                        />
                      )}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark md:table-cell">
                    <div className="flex items-center">
                      <span className="truncate">
                        {t('marketing-integration.table.header.purchase-order')}
                      </span>
                      {orderBy !== 'weeksUntilArrival' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-dark"
                          onClick={() => sortBy('weeksUntilArrival')}
                        />
                      ) : direction === 'ASC' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('weeksUntilArrival')}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('weeksUntilArrival')}
                        />
                      )}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-right text-sm font-semibold text-dark 2xl:table-cell">
                    <div className="flex items-center">
                      {t('marketing-integration.table.header.completion')}
                      {orderBy !== 'completionPercentage' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded text-gray-400 hover:text-dark"
                          onClick={() => sortBy('completionPercentage')}
                        />
                      ) : direction === 'ASC' ? (
                        <ChevronUpIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('completionPercentage')}
                        />
                      ) : (
                        <ChevronDownIcon
                          className="ml-2 h-5 w-5 flex-none cursor-pointer rounded bg-gray-100 text-dark group-hover:bg-gray-200"
                          onClick={() => sortBy('completionPercentage')}
                        />
                      )}
                    </div>
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark 2xl:table-cell">
                    {t('marketing-integration.table.header.validated-photo')}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark 2xl:table-cell">
                    {t('marketing-integration.table.header.validated-photo-edition')}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark xl:table-cell">
                    {t('marketing-integration.table.header.validated-integration')}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {loading &&
                  !products?.length &&
                  [...Array(5)].map((e, i) => (
                    <tr key={i}>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-5 w-36"></div>
                      </td>
                      <td className="hidden px-3 py-3.5 md:table-cell">
                        <div className="skeleton h-5 w-36"></div>
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-5 w-36"></div>
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-5 w-36"></div>
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-5 w-36"></div>
                      </td>
                      <td className="hidden px-3 py-3.5 xl:table-cell">
                        <div className="skeleton h-5 w-36"></div>
                      </td>
                      <td></td>
                    </tr>
                  ))}
                {!loading && !products?.length && (
                  <tr>
                    <td className="p-3" colSpan={6}>
                      {error ? (
                        <ApolloErrorAlert error={error} />
                      ) : (
                        <InformationAlert>
                          {t('marketing-integration.table.no-product')}
                        </InformationAlert>
                      )}
                    </td>
                  </tr>
                )}
                {products?.map((product: Product) => (
                  <tr key={product.id}>
                    <td className="max-w-[200px] py-3.5 pl-4 pr-3 text-sm md:max-w-[280px]">
                      <ProductInfos product={product} />
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 md:table-cell">
                      <div className="w-fit font-medium text-blue-600 hover:text-blue-700">
                        <Link
                          to={product.firstArrivalPurchaseOrder?.legacyUrl}
                          target="_blank"
                          rel="noopener noreferrer">
                          {product.firstArrivalPurchaseOrder?.label}
                        </Link>
                      </div>
                      <div className="w-fit max-w-[250px] truncate text-blue-600 hover:text-blue-700">
                        <Tippy content={product.supplier?.name}>
                          <Link to={`/suppliers/${product.supplier?.id}`}>
                            {product.supplier?.name}
                          </Link>
                        </Tippy>
                      </div>
                      <Tippy content={moment(product.firstArrivalDate).format('Do MMMM YYYY')}>
                        <div>
                          {t('marketing-integration.table.header.weeks-until-arrival')} :{' '}
                          <span className="font-bold">{product.weeksUntilArrival}</span>
                        </div>
                      </Tippy>
                      <div>
                        {t('marketing-integration.table.header.physical-stock')} :{' '}
                        <span className="font-bold">{product.physicalStock}</span>
                      </div>
                    </td>
                    <td className="hidden px-3 py-3.5 text-sm text-gray-500 2xl:table-cell">
                      <div className="flex flex-col justify-center">
                        <ProgressionBadge
                          completionPercentage={product.completionPercentage}
                          missingInformations={product.missingInformations}
                        />
                      </div>
                    </td>
                    <td className="hidden px-3 py-4 2xl:table-cell">
                      <div className="flex whitespace-nowrap">
                        {product.marketingIntegration?.validated_photo ? (
                          <Badge type="success">{t('global.validated-f')}</Badge>
                        ) : (
                          <Badge type="danger">{t('global.unvalidated-f')}</Badge>
                        )}
                        {product.marketingIntegration?.photo_comment && (
                          <Tippy content={product.marketingIntegration.photo_comment}>
                            <ChatBubbleLeftEllipsisIcon className="ml-1 h-5 w-5 text-gray-500" />
                          </Tippy>
                        )}
                      </div>
                    </td>
                    <td className="hidden px-3 py-4 2xl:table-cell">
                      <div className="flex whitespace-nowrap">
                        {product.marketingIntegration?.validated_photo_edition ? (
                          <Badge type="success">{t('global.validated-f')}</Badge>
                        ) : (
                          <Badge type="danger">{t('global.unvalidated-f')}</Badge>
                        )}
                        {product.marketingIntegration?.photo_edition_comment && (
                          <Tippy content={product.marketingIntegration.photo_edition_comment}>
                            <ChatBubbleLeftEllipsisIcon className="ml-1 h-5 w-5 text-gray-500" />
                          </Tippy>
                        )}
                      </div>
                    </td>
                    <td className="hidden px-3 py-4 xl:table-cell">
                      <div className="flex whitespace-nowrap">
                        {product.marketingIntegration?.validated_integration ? (
                          <Badge type="success">{t('global.validated-f')}</Badge>
                        ) : (
                          <Badge type="danger">{t('global.unvalidated-f')}</Badge>
                        )}
                        {product.marketingIntegration?.comment && (
                          <Tippy content={product.marketingIntegration.comment}>
                            <ChatBubbleLeftEllipsisIcon className="ml-1 h-5 w-5 text-gray-500" />
                          </Tippy>
                        )}
                      </div>
                    </td>
                    <td className="p-6">
                      <div className="flex items-center justify-end">
                        <PencilSquareIcon
                          className="h-6 w-6 cursor-pointer text-gray-500 hover:text-dark"
                          onClick={() => openForm(product)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
              {paginator && products?.length > 0 && (
                <tfoot>
                  <tr>
                    <td colSpan={7} className="py-3.5">
                      <Pagination paginator={paginator} setPage={setPage} />
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
