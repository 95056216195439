import { gql } from 'graphql.macro';

export const supplierCommentQuery = gql`
  query SupplierComment($id: ID!) {
    supplier(id: $id) {
      id
      comment
    }
  }
`;
