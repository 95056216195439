import { gql } from 'graphql.macro';

export const updateClassificationMutation = gql`
  mutation UpdateClassification(
    $id: ID!
    $collection: Mixed
    $styleId: ID
    $familyId: ID
    $modelId: ID
  ) {
    updateClassification(
      id: $id
      collection: $collection
      styleId: $styleId
      familyId: $familyId
      modelId: $modelId
    ) {
      id
      taxonomy {
        collection {
          id
          label
          active
        }
        style {
          id
          label
          active
        }
      }
      family {
        id
        currentLabelTranslation {
          text
        }
        active
      }
      model {
        id
        name
        active
      }
    }
  }
`;
