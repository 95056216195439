import { gql } from 'graphql.macro';

export const updateModelMutation = gql`
  mutation UpdateModel($id: ID!, $name: String) {
    updateProductModel(id: $id, name: $name) {
      id
      name
      active
      isUsed
    }
  }
`;
