import React, { useState } from 'react';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';
import { productCategoriesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productCategories';
import { ProductCategory, ProductUniverse } from 'types/Product';
import { Action } from 'types/Global';
import { Button } from 'components/Common/Button';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { DropdownMenu } from 'components/Common/DropdownMenu';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import Badge from 'components/Common/Badge';
import Skeleton from './Skeleton/Skeleton';
import EditCategorySlide from './EditCategorySlide';
import ActivationCategoryModal from './ActivationCategoryModal';

type Props = {
  selectedUniverse: ProductUniverse | null;
  setSelectedUniverse: React.Dispatch<React.SetStateAction<ProductUniverse | null>>;
  setSelectedCategory: React.Dispatch<React.SetStateAction<ProductCategory | null>>;
};

const Categories = ({ selectedUniverse, setSelectedUniverse, setSelectedCategory }: Props) => {
  const [openSlide, setOpenSlide] = useState<boolean>(false);
  const [editCategory, setEditCategory] = useState<ProductCategory | null>(null);
  const [openActivationModal, setOpenActivationModal] = useState<boolean>(false);

  const { loading, error, data } = useQuery(productCategoriesQuery, {
    fetchPolicy: 'network-only',
    variables: { id: selectedUniverse?.id },
  });

  if (error) return <ApolloErrorAlert error={error} />;

  const categories = data?.productUniverse?.categories;

  const backToUniverses = () => {
    setSelectedUniverse(null);
  };

  const selectEditCategory = (category: ProductCategory) => {
    setEditCategory(category);
    setOpenSlide(true);
  };

  const openModal = (category: ProductCategory) => {
    setEditCategory(category);
    setOpenActivationModal(true);
  };

  return (
    <>
      <EditCategorySlide category={editCategory} setOpen={setOpenSlide} open={openSlide} />
      <ActivationCategoryModal
        category={editCategory}
        open={openActivationModal}
        setOpen={setOpenActivationModal}
      />
      <div className="flex items-center space-x-2 border-b-2 py-4 text-dark text-sm">
        <button onClick={backToUniverses}>{t('settings.taxonomy.category.back-to-univers')}</button>
        <ChevronRightIcon className="h-4 w-4 text-dark" />
        <p className="font-semibold">{selectedUniverse?.label ? selectedUniverse?.label : '-'}</p>
      </div>
      {loading && <Skeleton />}
      {categories?.map((categorie: ProductCategory) => {
        const actions: Action[] = [
          {
            text: t('settings.taxonomy.actions.edit'),
            onClick: () => selectEditCategory(categorie),
          },
          {
            text: categorie.is_active
              ? t('settings.taxonomy.actions.deactivate')
              : t('settings.taxonomy.actions.activate'),
            onClick: () => {
              openModal(categorie);
            },
          },
        ];
        return (
          <div
            key={categorie.id}
            className="flex justify-between items-center w-full border-b-2 py-4">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-4">
                <p className="text-dark text-sm font-semibold">
                  {categorie.label ? categorie.label : '-'}
                </p>
                <span>
                  <Badge type={categorie.is_active ? 'success' : 'secondary'}>
                    {categorie.is_active ? t('global.active') : t('global.inactive')}
                  </Badge>
                </span>
              </div>
              <p className="text-secondary text-sm">
                {categorie.productsCount} {t('settings.taxonomy.universe.products')}
              </p>
            </div>
            <div className="flex items-center space-x-8">
              <Button theme="secondary" onClick={() => setSelectedCategory(categorie)}>
                {t('settings.taxonomy.category.see-types')}
              </Button>
              <DropdownMenu actions={actions} />
            </div>
          </div>
        );
      })}
    </>
  );
};
export default Categories;
