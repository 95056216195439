import React from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@apollo/client';
import { deleteImportPackageMutation } from 'graphql/Mutations/Product/Packaging/deleteImportPackage';
import { ImportPackagingQuery } from 'graphql/Queries/Product/Packaging/importPackaging';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { ProductImportPackage } from 'types/Product';
import useNotification from 'hooks/useNotification';
import Modal from 'components/Common/Modal';

type Props = {
  importPackage: ProductImportPackage | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeletePackageModal = ({ importPackage, open, setOpen }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [deletePackage, { loading, error }] = useMutation(deleteImportPackageMutation);

  const { handleSubmit } = useForm();

  const handleDelete = handleSubmit(() => {
    if (!importPackage) return;
    deletePackage({
      variables: {
        id: importPackage.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ImportPackagingQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.import.delete-success'),
          type: 'success',
        });
        setOpen(false);
      },
    });
  });

  return (
    <Modal
      theme="danger"
      title={t('product.packaging.delete-title')}
      buttonText={t('global.actions.delete')}
      open={open}
      loading={loading}
      error={error}
      onClose={() => setOpen(false)}
      icon={<TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
      onSubmit={handleDelete}>
      <div className="mt-6 space-y-6">
        <div className="flex flex-col">
          <span>{t('product.packaging.delete-description-1')}</span>
          <span className="font-semibold">{importPackage?.label}</span>
        </div>
        <div className="font-medium text-red-600">
          {t('product.packaging.delete-description-2')}
        </div>
      </div>
    </Modal>
  );
};

export default DeletePackageModal;
