import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { InformationAlert } from 'components/Common/Alerts';
import { customStyle } from 'components/Style/customStyle';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';
import DeleteModal from './DeleteModal';
import { furnitureAssemblyOptionsQuery } from 'graphql/Queries/Settings/Products/Furniture/furnitureAssemblyOptions';
import { updateFurnitureAssemblyOptionMutation } from 'graphql/Mutations/Settings/Products/Furniture/updateFurnitureAssemblyOption';
import { updateFurnitureAssemblyOptionStatusMutation } from 'graphql/Mutations/Settings/Products/Furniture/updateFurnitureAssemblyOptionStatus';
import { createFurnitureAssemblyOptionMutation } from 'graphql/Mutations/Settings/Products/Furniture/createFurnitureAssemblyOption';
import SelectCreatable from 'components/Common/SelectCreatable';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const FurnitureAssemblyOption = () => {
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();

  const [options, setOptions] = useState([]);
  const [showEditInstruction, setShowEditInstruction] = useState(false);
  const [showNewInstruction, setShowNewInstruction] = useState(false);
  const [instruction, setInstruction] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [getAllFurnitureAssemblyOptions, { data, loading }] = useLazyQuery(
    furnitureAssemblyOptionsQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!data) return;
    setOptions(
      data?.furnitureAssemblyOptions?.map((option) => {
        return {
          label: option.currentTranslation
            ? option.currentTranslation?.text
            : t('settings.products.instructions.furniture-assembly-option.no-trad'),
          value: option.id,
          active: option.active,
          isUsed: option.isUsed,
        };
      })
    );
  }, [data]);

  const [createInstruction, { loading: createInstructionLoading }] = useMutation(
    createFurnitureAssemblyOptionMutation,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: furnitureAssemblyOptionsQuery,
        },
      ],
    }
  );

  const [updateInstructionOptions, { loading: updateInstructionLoading }] = useMutation(
    updateFurnitureAssemblyOptionMutation
  );

  const [updateInstructionOptionsStatus, { loading: updateInstructionStatusLoading }] = useMutation(
    updateFurnitureAssemblyOptionStatusMutation
  );

  const schema = yup.object().shape({
    label: yup.string().trim().min(1),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
    },
  });

  const onChangeInstruction = (value) => {
    setShowEditInstruction(true);
    setInstruction(value);
    reset({
      label: value.label,
    });
  };

  const changeActivation = (value) => {
    let message = '';
    if (value) {
      message = t('settings.products.instructions.furniture-assembly-option.activate');
    } else {
      message = t('settings.products.instructions.furniture-assembly-option.deactivate');
    }

    updateInstructionOptionsStatus({
      variables: {
        id: instruction.value,
        active: value,
      },

      onCompleted: (res) => {
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });

        setInstruction({
          value: res.updateFurnitureAssemblyOption.id,
          active: res.updateFurnitureAssemblyOption.active,
          label: instruction.label,
          isUsed: res.updateFurnitureAssemblyOption.isUsed,
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  const resetAfterDelete = () => {
    setInstruction(null);
    setShowEditInstruction(false);
  };

  const onCreateInstruction = (value) => {
    setShowEditInstruction(false);
    setShowNewInstruction(true);

    setInstruction({
      value: value,
      label: value,
      active: true,
      isUsed: false,
    });

    reset({
      label: value,
    });
  };

  const onCancel = () => {
    setShowNewInstruction(false);
    setInstruction(null);
  };

  const onSubmit = (data) => {
    if (showNewInstruction) {
      createInstruction({
        variables: {
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.createFurnitureAssemblyOption;

          setNotification({
            title: t('global.success'),
            message: t('product.editorial.instruction.add-instruction'),
            type: 'success',
          });
          reset({
            label: data.currentTranslation.text,
          });
          setInstruction({
            value: data.id,
            label: data.currentTranslation.text,
            active: data.active,
            isUsed: data.isUsed,
          });
          setShowEditInstruction(true);
          setShowNewInstruction(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updateInstructionOptions({
        variables: {
          id: instruction.value,
          label: data.label,
        },

        onCompleted: (res) => {
          setNotification({
            title: t('global.success'),
            message: t('settings.products.instructions.furniture-assembly-option.updated'),
            type: 'success',
          });

          reset({
            label: data.currentTranslation?.text,
          });

          setInstruction({
            value: res.updateFurnitureAssemblyOption.id,
            active: res.updateFurnitureAssemblyOption.active,
            label: res.updateFurnitureAssemblyOption.currentTranslation?.text,
            isUsed: res.updateFurnitureAssemblyOption.isUsed,
          });
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  return (
    <Card>
      <div className="p-4">
        <h3 className="text-lg font-medium text-blue-gray-900">
          {t('settings.products.instructions.furniture-assembly-option.title')}
        </h3>
        <p className="mt-1 text-sm text-blue-gray-500">
          {t('settings.products.instructions.furniture-assembly-option.subtitle')}
        </p>
      </div>
      <div className="p-4">
        <label className="text-sm font-medium text-gray-700">
          {t('settings.products.instructions.furniture-assembly-option.list')}
        </label>
        <SelectCreatable
          styles={customStyle}
          formatCreateLabel={(inputValue) =>
            `${t(
              'settings.products.instructions.furniture-assembly-option.create'
            )} : "${inputValue}"`
          }
          options={options}
          loadingOptions={loading}
          onChange={onChangeInstruction}
          onCreateOption={onCreateInstruction}
          value={instruction}
          onFocus={() => {
            if (!options || options.length === 0) {
              getAllFurnitureAssemblyOptions();
            }
          }}
        />
      </div>
      {(showEditInstruction || showNewInstruction) && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-4">
            <label className="text-sm font-medium text-gray-700">
              {t('settings.products.models-collections-styles.styles.name')}
            </label>
            <div className="relative">
              <input
                type="text"
                className={classNames(
                  errors.label
                    ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                    : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                  'mt-1 block h-9 w-full flex-1 rounded'
                )}
                {...register('label')}
              />
              {errors.label && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                </div>
              )}
            </div>
            {errors.label && <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>}
            {instruction?.isUsed && (
              <div className="mt-4">
                <InformationAlert>
                  {t('settings.products.instructions.furniture-assembly-option.used')}
                </InformationAlert>
              </div>
            )}
          </div>

          <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
            {showEditInstruction && (
              <>
                <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                  <Button
                    type="button"
                    theme="outlineDanger"
                    disabled={updateInstructionLoading || instruction?.isUsed}
                    onClick={() => setDisplayModal(true)}
                  >
                    {t('global.actions.delete')}
                  </Button>
                  {instruction.active ? (
                    <Button
                      type="button"
                      theme="outlinePrimary"
                      disabled={updateInstructionLoading || updateInstructionStatusLoading}
                      loading={updateInstructionStatusLoading}
                      onClick={() => changeActivation(false)}
                    >
                      {t('global.actions.deactivate')}
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      theme="outlinePrimary"
                      disabled={updateInstructionLoading || updateInstructionStatusLoading}
                      loading={updateInstructionStatusLoading}
                      onClick={() => changeActivation(true)}
                    >
                      {t('global.actions.activate')}
                    </Button>
                  )}
                  <Button
                    type="submit"
                    theme="primary"
                    disabled={updateInstructionLoading || !isDirty}
                    loading={updateInstructionLoading}
                  >
                    {t('global.actions.save')}
                  </Button>
                </div>
              </>
            )}
            {showNewInstruction && (
              <>
                <Button
                  type="button"
                  theme="transparent"
                  onClick={onCancel}
                  disabled={createInstructionLoading}
                >
                  {t('global.actions.cancel')}
                </Button>
                <Button
                  type="submit"
                  theme="primary"
                  disabled={createInstructionLoading}
                  loading={createInstructionLoading}
                >
                  {t('global.actions.add')}
                </Button>
              </>
            )}
          </div>
        </form>
      )}

      <DeleteModal
        onCloseModal={() => setDisplayModal(false)}
        isOpen={displayModal}
        instruction={instruction}
        reset={resetAfterDelete}
      />
    </Card>
  );
};

export default FurnitureAssemblyOption;
