import { gql } from 'graphql.macro';

export const updateEcomobilierMutation = gql`
  mutation updateEcomobilier(
    $product_id: Int!
    $eco_mobilier_category_id: Int!
    $eco_mobilier_product_type_id: Int!
    $eco_mobilier_material_id: Int!
    $eco_mobilier_size_id: Int!
    $payed_before: Int!
    $enable: Int!
    $forced: Boolean
  ) {
    updateEcomobilier(
      product_id: $product_id
      eco_mobilier_category_id: $eco_mobilier_category_id
      eco_mobilier_product_type_id: $eco_mobilier_product_type_id
      eco_mobilier_material_id: $eco_mobilier_material_id
      eco_mobilier_size_id: $eco_mobilier_size_id
      payed_before: $payed_before
      enable: $enable
      forced: $forced
    )
  }
`;
