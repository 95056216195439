import React from 'react';

const ProductHeaderSkeleton = () => {
  return (
    <div className="sticky top-16 z-10 flex w-full flex-1 flex-col items-center space-y-3 rounded-md bg-gray-50 p-6 xl:flex-row xl:justify-between xl:space-y-0">
      <div className="skeleton h-24 w-24 shrink-0 rounded-full"></div>
      <div className="ml-6 w-full space-y-3">
        <div className="flex w-full justify-between">
          <div className="skeleton h-[30px] w-full xl:w-[300px]"></div>
        </div>
        <div className="skeleton mb-3 h-[20px] w-full xl:w-[200px]" />
        <div className="skeleton mb-3 h-[20px] w-[100px]" />
      </div>
    </div>
  );
};

export default ProductHeaderSkeleton;
