import { gql } from 'graphql.macro';

export const allVariantsQuery = gql`
  query allVariants($id: ID!) {
    product(id: $id) {
      id
      allVariants {
        id
        activeOffers {
          id
          country {
            id
            iso
            name
            salesPeriod {
              start
              end
            }
            isDuringSales
          }
          priceWithEcotax
          price_without_ecotax
          highestPrice
          futurePriceWithEcotax
          percentDiscount
          is_on_sales
          isPromotion
          markdown
          startDiscountDate
          endDiscountDate
          isDiscount
        }
        currentTranslation {
          title
          internal_title
        }
      }
    }
  }
`;
