import React, { useState } from 'react';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';
import { productsTypesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productsTypes';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { ProductCategory, ProductType, ProductUniverse } from 'types/Product';
import { Action } from 'types/Global';
import { DropdownMenu } from 'components/Common/DropdownMenu';
import Skeleton from './Skeleton/Skeleton';
import Badge from 'components/Common/Badge';
import EditTypeSlide from './EditTypeSlide';
import ActivationTypeModal from './ActivationTypeModal';

type Props = {
  selectedCategory: ProductCategory | null;
  selectedUniverse: ProductUniverse | null;
  setSelectedCategory: React.Dispatch<React.SetStateAction<ProductCategory | null>>;
  setSelectedUniverse: React.Dispatch<React.SetStateAction<ProductUniverse | null>>;
};

const Types = ({
  selectedCategory,
  selectedUniverse,
  setSelectedCategory,
  setSelectedUniverse,
}: Props) => {
  const [openSlide, setOpenSlide] = useState<boolean>(false);
  const [editType, setEditType] = useState<ProductType | null>(null);
  const [openActivationModal, setOpenActivationModal] = useState<boolean>(false);

  const { loading, error, data } = useQuery(productsTypesQuery, {
    fetchPolicy: 'network-only',
    variables: { id: selectedCategory?.id },
  });

  if (error) return <ApolloErrorAlert error={error} />;

  const types = data?.productCategory?.types;

  const backToCategories = () => {
    setSelectedCategory(null);
  };

  const backToUniverses = () => {
    setSelectedCategory(null);
    setSelectedUniverse(null);
  };

  const selecteEditType = (type: ProductType) => {
    setEditType(type);
    setOpenSlide(true);
  };

  const openModal = (type: ProductType) => {
    setEditType(type);
    setOpenActivationModal(true);
  };

  return (
    <>
      <EditTypeSlide type={editType} setOpen={setOpenSlide} open={openSlide} />
      <ActivationTypeModal
        type={editType}
        open={openActivationModal}
        setOpen={setOpenActivationModal}
      />
      <div className="flex items-center space-x-2 border-b-2 py-4 text-dark text-sm">
        <button onClick={backToUniverses}>{t('settings.taxonomy.category.back-to-univers')}</button>
        <ChevronRightIcon className="h-4 w-4 text-dark" />
        <button onClick={backToCategories}>
          {selectedUniverse?.label ? selectedUniverse?.label : '-'}
        </button>
        <ChevronRightIcon className="h-4 w-4 text-dark" />
        <p className="font-semibold">{selectedCategory?.label ? selectedCategory?.label : '-'}</p>
      </div>
      {loading && <Skeleton />}
      {types?.map((type: ProductType) => {
        const actions: Action[] = [
          {
            text: t('settings.taxonomy.actions.edit'),
            onClick: () => selecteEditType(type),
          },
          {
            text: type.is_active
              ? t('settings.taxonomy.actions.deactivate')
              : t('settings.taxonomy.actions.activate'),
            onClick: () => {
              openModal(type);
            },
          },
        ];
        return (
          <div key={type.id} className="flex justify-between items-center w-full border-b-2 py-4">
            <div className="flex flex-col space-y-1">
              <div className="flex items-center space-x-4">
                <p className="text-dark text-sm font-semibold">{type.label ? type.label : '-'}</p>
                <span>
                  <Badge type={type.is_active ? 'success' : 'secondary'}>
                    {type.is_active ? t('global.active') : t('global.inactive')}
                  </Badge>
                </span>
              </div>
              <p className="text-secondary text-sm">
                {type.productsCount} {t('settings.taxonomy.universe.products')}
              </p>
            </div>
            <div className="flex items-center space-x-8">
              <DropdownMenu actions={actions} />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Types;
