import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { classificationQuery } from 'graphql/Queries/Product/ModelAndStyle/classification';
import { updateClassificationMutation } from 'graphql/Mutations/Product/ModelAndStyle/updateClassification';
import { collectionProductsQuery } from 'graphql/Queries/Product/ModelAndStyle/collectionProducts';
import { styleProductsQuery } from 'graphql/Queries/Product/ModelAndStyle/styleProducts';
import { familyProductsQuery } from 'graphql/Queries/Product/ModelAndStyle/familyProducts';
import { modelProductsQuery } from 'graphql/Queries/Product/ModelAndStyle/modelProducts';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { Product } from 'types/Product';
import { ID, OptionProps } from 'types/Global';
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import useNotification from 'hooks/useNotification';
import SimpleCard from '../SimpleCard';
import Card from 'components/Common/Card';
import Title from 'components/Common/Title';
import Form from './Form';

export const DisplayProducts = {
  collection: 'product.model-style.collection',
  style: 'product.model-style.style',
  family: 'product.model-style.family',
  model: 'product.model-style.model',
};

export type FormatedValues = {
  collection: OptionProps | null;
  style: OptionProps | null;
  family: OptionProps | null;
  model: OptionProps | null;
};

export type DisplayProductsProps = {
  id: ID;
  type: string;
  elementLabel: string;
} | null;

const Index = () => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const params = useParams();
  const [defaultValues, setDefaultValues] = useState<FormatedValues>({
    collection: null,
    style: null,
    family: null,
    model: null,
  });
  const [displayProducts, setDisplayProducts] = useState<DisplayProductsProps>(null);
  const [products, setProducts] = useState<Product[]>([]);

  const { loading, error } = useQuery(classificationQuery, {
    variables: { id: params.productId },
    onCompleted: (data) => {
      setDefaultValues(makeDefaultValues(data.product));
    },
  });

  const [getCollectionProductsQuery, { loading: collectionLoading, error: collectionError }] =
    useLazyQuery(collectionProductsQuery);
  const [getStyleProductsQuery, { loading: styleLoading, error: styleError }] =
    useLazyQuery(styleProductsQuery);
  const [getFamilyProductsQuery, { loading: familyLoading, error: familyError }] =
    useLazyQuery(familyProductsQuery);
  const [getModelProductsQuery, { loading: modelLoading, error: modelError }] =
    useLazyQuery(modelProductsQuery);

  const [updateClassification, { loading: mutationLoading, error: mutationError }] = useMutation(
    updateClassificationMutation
  );

  const updateProduct = (data: FormatedValues) => {
    updateClassification({
      variables: {
        id: params.productId,
        collection: data.collection
          ? {
              id: data.collection.__isNew__ ? 0 : data.collection.value,
              label: data.collection.label,
              isNew: !!data.collection.__isNew__,
            }
          : null,
        styleId: data.style?.value,
        familyId: data.family?.value,
        modelId: data.model?.value,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: (data) => {
        setDefaultValues(makeDefaultValues(data.updateClassification));
        setNotification({
          type: 'success',
          title: t('global.success'),
          message: t('product.model-style.success'),
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const getCollectionProducts = useCallback(
    (id: ID) => {
      setProducts([]);
      getCollectionProductsQuery({
        variables: {
          id,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.collection) {
            setProducts(
              data.productCollection.lastProducts
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getCollectionProductsQuery]
  );
  const getStyleProducts = useCallback(
    (id: ID) => {
      setProducts([]);
      getStyleProductsQuery({
        variables: {
          id,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.style) {
            setProducts(
              data.productStyle.lastProducts
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getStyleProductsQuery]
  );
  const getFamilyProducts = useCallback(
    (id: ID) => {
      setProducts([]);
      getFamilyProductsQuery({
        variables: {
          id,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.family) {
            setProducts(
              data.family.lastProducts
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getFamilyProductsQuery]
  );
  const getModelProducts = useCallback(
    (id: ID) => {
      setProducts([]);
      getModelProductsQuery({
        variables: {
          id,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.model) {
            setProducts(
              data.productModel.lastProducts
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getModelProductsQuery]
  );

  useEffect(() => {
    if (!displayProducts) return;

    switch (displayProducts.type) {
      case DisplayProducts.collection:
        getCollectionProducts(displayProducts.id);
        break;
      case DisplayProducts.style:
        getStyleProducts(displayProducts.id);
        break;
      case DisplayProducts.family:
        getFamilyProducts(displayProducts.id);
        break;
      case DisplayProducts.model:
        getModelProducts(displayProducts.id as string);
        break;
    }
  }, [
    displayProducts,
    getCollectionProducts,
    getStyleProducts,
    getFamilyProducts,
    getModelProducts,
  ]);

  const { productExists } = useProductContextType();

  let queryError = collectionError;
  if (styleError) {
    queryError = styleError;
  } else if (familyError) {
    queryError = familyError;
  } else if (modelError) {
    queryError = modelError;
  }

  if (!productExists) return <></>;

  return (
    <>
      <Title title={t('product.model-style.title')} />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 xl:col-span-1">
          <Card>
            <>
              {error ? (
                <div className="my-2 p-2">
                  <ApolloErrorAlert error={error}></ApolloErrorAlert>
                </div>
              ) : (
                <Form
                  loading={loading}
                  defaultValues={defaultValues}
                  setDisplayProducts={setDisplayProducts}
                  updateProduct={updateProduct}
                  mutationLoading={mutationLoading}
                  mutationError={mutationError}
                />
              )}
            </>
          </Card>
        </div>
        <div className="col-span-3 xl:col-span-2">
          {displayProducts ? (
            <Card title={t(displayProducts.type)} secondaryTitle={displayProducts.elementLabel}>
              <>
                {queryError ? (
                  <ApolloErrorAlert error={queryError} />
                ) : collectionLoading || styleLoading || familyLoading || modelLoading ? (
                  <div className="grid grid-cols-3 gap-6">
                    {[...Array(6)].map((_, i) => (
                      <div key={i} className="skeleton h-56 w-full" />
                    ))}
                  </div>
                ) : products.length > 0 ? (
                  <div className="grid grid-cols-2 gap-6 lg:grid-cols-3">
                    {products.map((product, i) => (
                      <SimpleCard key={i} product={product} />
                    ))}
                  </div>
                ) : (
                  <InformationAlert>{t('product.materials-colors.no-product')}</InformationAlert>
                )}
              </>
            </Card>
          ) : (
            <Card>
              <div className="mx-auto flex h-full w-full max-w-xs items-center text-center text-dark">
                <div className="flex flex-col items-center ">
                  <CursorArrowRaysIcon className="w-10 stroke-1" />
                  <span>{t('product.materials-colors.select')}</span>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

const makeDefaultValues = (product: Product) => {
  return {
    collection: product.taxonomy?.collection
      ? {
          value: product.taxonomy.collection.id as string,
          label: product.taxonomy.collection.label,
          isActive: product.taxonomy.collection.active,
        }
      : null,
    style: product.taxonomy?.style
      ? {
          value: product.taxonomy.style.id as string,
          label: product.taxonomy.style.label,
          isActive: product.taxonomy.style.active,
        }
      : null,
    family: product.family
      ? {
          value: product.family.id as string,
          label: product.family.currentLabelTranslation?.text ?? '',
          isActive: product.family.active,
        }
      : null,
    model: product.model
      ? {
          value: product.model.id as string,
          label: product.model.name,
          isActive: product.model.active,
        }
      : null,
  };
};

export default Index;
