import { gql } from 'graphql.macro';

export const updateProductPackagingOptionMutation = gql`
  mutation UpdateProductPackagingOption(
    $id: ID!
    $packaging_option_id: ID!
    $quantity_by_import_package: Int!
  ) {
    updateProductImportPackagingOption(
      id: $id
      packaging_option_id: $packaging_option_id
      quantity_by_import_package: $quantity_by_import_package
    ) {
      id
      packagingOption {
        id
        label_fr
        label_gb
        is_active
      }
      quantity_by_import_package
    }
  }
`;
