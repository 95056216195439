import Header from './Header';
import Incoterms from './Incoterms';
import Ports from './Ports';
import AssociatedForwarders from './AssociatedForwarders';
import PaymentMethods from './PaymentMethods';

const Index = () => {
  return (
    <>
      <Header></Header>
      <div className="space-y-6 p-4">
        <Incoterms />
        <Ports />
        <AssociatedForwarders />
        <PaymentMethods />
      </div>
    </>
  );
};

export default Index;
