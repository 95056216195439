import { gql } from 'graphql.macro';

export const updateMaterialStatusMutation = gql`
  mutation UpdateMaterialStatus($id: ID!, $parent_id: ID, $is_active: Boolean) {
    updateProductMaterial(id: $id, parent_id: $parent_id, is_active: $is_active) {
      id
      label
      parent {
        id
        label
      }
      children {
        id
      }
      is_active
      isUsed
    }
  }
`;
