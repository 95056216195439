import { Controller } from 'react-hook-form';
import { customStyle, theme } from 'components/Style/customStyle';
import { useTranslation } from 'react-i18next';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';

const MultiValue = (props) => (
  <components.MultiValue {...props}>{props.data.minilabel}</components.MultiValue>
);

MultiValue.propTypes = {
  data: PropTypes.object,
  test: PropTypes.string,
};
function MultiSelect({ name, control, options = [], loading = false }) {
  const { t } = useTranslation();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <Select
          styles={customStyle}
          placeholder={loading ? t('global.form.loading') : t('global.form.select')}
          isSearchable={true}
          autoFocus={false}
          name={name}
          isMulti={true}
          menuPosition={'relative'}
          captureMenuScroll={false}
          closeMenuOnSelect={false}
          closeMenuOnScroll={false}
          menuShouldScrollIntoView={false}
          {...field}
          options={options}
          components={{ MultiValue }}
          noOptionsMessage={() => {
            return t('global.form.no-option');
          }}
          theme={theme}
        />
      )}
    />
  );
}

MultiSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object,
  options: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.bool,
};

export default MultiSelect;
