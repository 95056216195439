import React from 'react';
import { useQuery } from '@apollo/client';
import { Controller, Control, FieldErrors, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ecomobilierCategoryQuery } from 'graphql/Queries/Ecotax/ecomobilierCategory';
import { ID, SelectType } from 'types/Global';
import { theme, customStyle, errorCustomStyle } from 'components/Style/customStyle';
import Select from 'react-select';

type Props = {
  control: Control;
  categoryId: ID;
  value: ValueType;
  disabled: boolean;
  handleSelect: (e: SelectType) => void;
  errors: FieldErrors<FieldValues>;
};

type EcomobilierCategoryData = {
  ecomobilierCategory: {
    productTypes: ProductType[];
  };
};

type ProductType = {
  id: number;
  code: string;
  label: string;
};

type ValueType = {
  value: string;
  code: string;
};

function ProductTypeSelect({ control, categoryId, value, disabled, handleSelect }: Props) {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery<EcomobilierCategoryData>(ecomobilierCategoryQuery, {
    fetchPolicy: 'cache-and-network',
    variables: { id: parseInt(categoryId as string) },
  });

  const productTypes = data?.ecomobilierCategory?.productTypes.map((productType, index) => {
    return {
      label: `${productType.code} - ${productType.label}`,
      value: productType.id,
      code: productType.code,
      key: index,
    };
  });

  return (
    <>
      <div className="mt-3 sm:col-span-3">
        <label className="mb-2 block text-sm font-medium leading-6 text-dark">
          {t('product.ecotax.product-type')}
          <span className="text-red-700">*</span>
        </label>
        <div className="relative mt-1">
          <Controller
            name="type"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                theme={theme}
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                isDisabled={disabled || loading}
                styles={error ? errorCustomStyle : customStyle}
                isSearchable={false}
                noOptionsMessage={() => t('global.no-option')}
                placeholder={loading ? t('global.loading') : t('global.form.select')}
                options={productTypes}
                value={productTypes?.find((option) => option.code === value?.code)}
                onChange={(e) => handleSelect(e as SelectType)}
              />
            )}
          />
          <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
            {error && <ExclamationCircleIcon className="h-5 w-5 text-red-500" />}
          </div>
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-700">
            {
              ((error.graphQLErrors[0].extensions as { custom: string }).custom
                ? error
                : t('global.error-msg')) as string
            }
          </p>
        )}
      </div>
    </>
  );
}

export default ProductTypeSelect;
