import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { TrashIcon } from '@heroicons/react/24/outline';
import { deleteProductFeatureMutation } from 'graphql/Mutations/Product/deleteProductFeature';
import { productFeaturesQuery } from 'graphql/Queries/Product/Features/productFeatures';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { ProductFeature } from 'types/Product';
import useNotification from 'hooks/useNotification';
import Modal from 'components/Common/Modal';

type Props = {
  feature: ProductFeature | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteProductFeature = ({ feature, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const params = useParams();

  const { setNotification } = useNotification();

  const [deleteFeature, { loading, error }] = useMutation(deleteProductFeatureMutation);

  const onClose = () => {
    if (loading) return;
    setOpen(false);
  };

  const { handleSubmit } = useForm();

  const onConfirmDelete = handleSubmit(() => {
    if (!feature) return;
    deleteFeature({
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productFeaturesQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: feature.id,
      },
      onCompleted: () => {
        onClose();
        setNotification({
          title: t('global.success'),
          message: t('product.features.deleted'),
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <Modal
      theme="danger"
      title={t('product.features.delete-title')}
      buttonText={t('global.actions.delete')}
      open={open}
      loading={loading}
      error={error}
      onClose={() => setOpen(false)}
      icon={<TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
      onSubmit={onConfirmDelete}>
      <div className="mt-6 space-y-6">
        <div className="flex flex-col">
          <span>{t('product.features.delete-description-1')}</span>
          <span className="font-semibold">{feature?.label?.text}</span>
        </div>
        <div className="font-medium text-red-600">{t('product.features.delete-description-2')}</div>
      </div>
    </Modal>
  );
};
export default DeleteProductFeature;
