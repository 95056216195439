import React from 'react';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import useNotification from 'hooks/useNotification';
import { useTranslation } from 'react-i18next';
import { DEFAULT_PACKAGE_NAME } from 'global';
import { deletePackageSizeMutation } from 'graphql/Mutations/Product/deletePackageSize';
import { productPackagingQuery } from 'graphql/Queries/Product/Packaging/productPackaging';
import Modal from 'components/Common/Modal';
import { Product, ProductPackageSize } from 'types/Product';
import { useForm } from 'react-hook-form';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { TrashIcon } from '@heroicons/react/24/outline';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  packageSize: ProductPackageSize | null;
  product?: Product;
};

const DeletePackageSizeModal = ({ open, setOpen, packageSize, product }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [deletePackageSize, { loading, error }] = useMutation(deletePackageSizeMutation);

  const { handleSubmit } = useForm();

  const handleDelete = handleSubmit(() => {
    if (!packageSize) return;
    deletePackageSize({
      variables: {
        id: params.productId,
        packageSizeId: packageSize.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productPackagingQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.export.delete-success'),
          type: 'success',
        });
        setOpen(false);
      },
    });
  });

  let name;
  if (product?.isSinglePackage) {
    name = DEFAULT_PACKAGE_NAME;
  } else {
    name = packageSize?.package?.name;
  }

  return (
    <Modal
      theme="danger"
      title={t('product.packaging.delete-title')}
      buttonText={t('global.actions.delete')}
      open={open}
      loading={loading}
      error={error}
      onClose={() => setOpen(false)}
      icon={<TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
      onSubmit={handleDelete}>
      <div className="mt-6 space-y-6">
        <div className="flex flex-col">
          <span>{t('product.packaging.delete-description-1')}</span>
          <span className="font-semibold">{name}</span>
        </div>
        <div className="font-medium text-red-600">
          {t('product.packaging.delete-description-2')}
        </div>
      </div>
    </Modal>
  );
};

export default DeletePackageSizeModal;
