import React, { useState } from 'react';
import { t } from 'i18next';
import { Button } from 'components/Common/Button';
import { ProductCategory, ProductUniverse } from 'types/Product';
import Title from 'components/Common/Title';
import Universes from './Universes';
import Categories from './Categories';
import Types from './Types';
import useTitleTranslation from './Hook/useTitleTranslation';
import useCreateButtonLabel from './Hook/UseCreateButtonLabelProps';
import CreateUniverseSlide from './CreateUniverseSlide';
import CreateCategorySlide from './CreateCategorySlide';
import CreateTypeSlide from './CreateTypeSlide';
import useHandleCreate from './Hook/useHandleCreate';

const Index = () => {
  const [selectedUniverse, setSelectedUniverse] = useState<ProductUniverse | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<ProductCategory | null>(null);

  const createButtonLabel = useCreateButtonLabel({ selectedUniverse, selectedCategory, t });
  const titleTranslation = useTitleTranslation({ selectedUniverse, selectedCategory, t });

  const {
    openCreateUniverseSlide,
    setOpenCreateUniverseSlide,
    openCreateCategorySlide,
    setOpenCreateCategorySlide,
    openCreateTypeSlide,
    setOpenCreateTypeSlide,
    handleCreate,
  } = useHandleCreate();

  return (
    <>
      <CreateUniverseSlide setOpen={setOpenCreateUniverseSlide} open={openCreateUniverseSlide} />
      <CreateCategorySlide
        setOpen={setOpenCreateCategorySlide}
        open={openCreateCategorySlide}
        selectedUniverse={selectedUniverse}
      />
      <CreateTypeSlide
        setOpen={setOpenCreateTypeSlide}
        open={openCreateTypeSlide}
        selectedCategory={selectedCategory}
      />
      <Title
        title={titleTranslation}
        withBorder={false}
        addOnEnd={
          <Button onClick={() => handleCreate(selectedUniverse, selectedCategory)}>
            <>
              <span className="leading-6">{createButtonLabel}</span>
            </>
          </Button>
        }
      />
      {!selectedUniverse && <Universes setSelectedUnivers={setSelectedUniverse} />}
      {selectedUniverse && !selectedCategory && (
        <Categories
          selectedUniverse={selectedUniverse}
          setSelectedUniverse={setSelectedUniverse}
          setSelectedCategory={setSelectedCategory}
        />
      )}
      {selectedCategory && (
        <Types
          selectedCategory={selectedCategory}
          selectedUniverse={selectedUniverse}
          setSelectedCategory={setSelectedCategory}
          setSelectedUniverse={setSelectedUniverse}
        />
      )}
    </>
  );
};
export default Index;
