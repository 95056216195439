import { gql } from 'graphql.macro';

export const switchSupplyExceptionMutation = gql`
  mutation SwitchSupplyException($id: ID!, $supply_exception: Boolean!, $tag_id: Int!) {
    switchSupplyException(id: $id, supply_exception: $supply_exception, tag_id: $tag_id) {
      id
      supply_exception
    }
  }
`;
