import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { CursorArrowRaysIcon } from '@heroicons/react/24/outline';
import { mainMaterialProductsQuery } from 'graphql/Queries/Product/MaterialsAndColors/mainMaterialProducts';
import { secondaryMaterialProductsQuery } from 'graphql/Queries/Product/MaterialsAndColors/secondaryMaterialProducts';
import { colorProductsQuery } from 'graphql/Queries/Product/MaterialsAndColors/colorProducts';
import { supplierColorProductsQuery } from 'graphql/Queries/Product/MaterialsAndColors/supplierColorProducts';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { materialsAndColorsQuery } from 'graphql/Queries/Product/MaterialsAndColors/materialsAndColors';
import { updateMaterialAndColorsMutation } from 'graphql/Mutations/Product/MaterialsAndColors/updateMaterialAndColors';
import { Product } from 'types/Product';
import { ID, OptionProps } from 'types/Global';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import SimpleCard from '../SimpleCard';
import Title from 'components/Common/Title';
import MaterialAndColorEdit from './MaterialAndColorEdit';
import useNotification from 'hooks/useNotification';
import Card from 'components/Common/Card';

export const DisplayProducts = {
  mainMaterial: 'product.materials-colors.main-material',
  secondaryMaterial: 'product.materials-colors.secondary-material',
  color: 'product.materials-colors.color',
  supplierColor: 'product.materials-colors.supplier-color',
};

export type FormatedValues = {
  material1: OptionProps | null;
  material2: OptionProps | null;
  color: OptionProps | null;
  supplier_color: OptionProps | null;
};

export type DisplayProductsProps = {
  id: ID;
  type: string;
  elementLabel: string;
} | null;

const Index = () => {
  const { t } = useTranslation();
  const { setNotification } = useNotification();
  const params = useParams();
  const [materialAndColor, setMaterialAndColor] = useState<FormatedValues>({
    material1: null,
    material2: null,
    color: null,
    supplier_color: null,
  });
  const [displayProducts, setDisplayProducts] = useState<DisplayProductsProps>(null);
  const [products, setProducts] = useState<Product[]>([]);

  const { loading, error } = useQuery(materialsAndColorsQuery, {
    variables: { id: params.productId },
    onCompleted: (data) => {
      setMaterialAndColor(makeDefaultValues(data.product));
    },
  });

  const [getMainMaterialProductsQuery, { loading: mainMaterialLoading, error: mainMaterialError }] =
    useLazyQuery(mainMaterialProductsQuery);
  const [
    getSecondaryMaterialProductsQuery,
    { loading: secondaryMaterialLoading, error: secondaryMaterialError },
  ] = useLazyQuery(secondaryMaterialProductsQuery);
  const [getColorProductsQuery, { loading: colorLoading, error: colorError }] =
    useLazyQuery(colorProductsQuery);
  const [
    getSupplierColorProductsQuery,
    { loading: supplierColorLoading, error: supplierColorError },
  ] = useLazyQuery(supplierColorProductsQuery);

  const [updateMarialColor, { loading: mutationLoading, error: mutationError }] = useMutation(
    updateMaterialAndColorsMutation
  );

  const updateProduct = (formData: FormatedValues) => {
    updateMarialColor({
      variables: {
        id: params.productId,
        material1: formData.material1
          ? {
              id: !formData.material1.__isNew__ ? formData.material1.value : -1,
              label: formData.material1.label,
            }
          : null,
        material2: formData.material2
          ? {
              id: !formData.material2.__isNew__ ? formData.material2.value : -1,
              label: formData.material2.label,
            }
          : null,
        color: formData.color
          ? {
              id: !formData.color.__isNew__ ? formData.color.value : -1,
              label: formData.color.label,
            }
          : null,
        supplier_color: formData.supplier_color ? formData.supplier_color.label : null,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: (data) => {
        setMaterialAndColor(makeDefaultValues(data.updateMaterialAndColors));
        setNotification({
          type: 'success',
          title: t('global.success'),
          message: t('product.materials-colors.success'),
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const getMainMaterialProducts = useCallback(
    (id: ID) => {
      setProducts([]);
      getMainMaterialProductsQuery({
        variables: {
          id,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.mainMaterial) {
            setProducts(
              data.material.lastProducts1
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getMainMaterialProductsQuery]
  );
  const getSecondaryMaterialProducts = useCallback(
    (id: ID) => {
      setProducts([]);
      getSecondaryMaterialProductsQuery({
        variables: {
          id,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.secondaryMaterial) {
            setProducts(
              data.material.lastProducts2
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getSecondaryMaterialProductsQuery]
  );
  const getColorProducts = useCallback(
    (id: ID) => {
      setProducts([]);
      getColorProductsQuery({
        variables: {
          id,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.color) {
            setProducts(
              data.color.lastProducts
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getColorProductsQuery]
  );
  const getSupplierColorProducts = useCallback(
    (supplierColor: string) => {
      setProducts([]);
      getSupplierColorProductsQuery({
        variables: {
          supplierColor,
        },
        onCompleted: (data) => {
          if (displayProducts?.type === DisplayProducts.supplierColor) {
            setProducts(
              data.lastProductsBySupplierColor
                .filter((product: Product) => product.id !== params.productId)
                .slice(0, 6)
            );
          }
        },
      });
    },
    [displayProducts, params, getSupplierColorProductsQuery]
  );

  useEffect(() => {
    if (!displayProducts) return;

    switch (displayProducts.type) {
      case DisplayProducts.mainMaterial:
        getMainMaterialProducts(displayProducts.id);
        break;
      case DisplayProducts.secondaryMaterial:
        getSecondaryMaterialProducts(displayProducts.id);
        break;
      case DisplayProducts.color:
        getColorProducts(displayProducts.id);
        break;
      case DisplayProducts.supplierColor:
        getSupplierColorProducts(displayProducts.id as string);
        break;
    }
  }, [
    displayProducts,
    getMainMaterialProducts,
    getSecondaryMaterialProducts,
    getColorProducts,
    getSupplierColorProducts,
  ]);

  const { productExists } = useProductContextType();

  let queryError = mainMaterialError;
  if (secondaryMaterialError) {
    queryError = secondaryMaterialError;
  } else if (colorError) {
    queryError = colorError;
  } else if (supplierColorError) {
    queryError = supplierColorError;
  }

  if (!productExists) return <></>;

  return (
    <>
      <Title title={t('product.materials-colors.title')} />
      <div className="grid grid-cols-3 gap-6">
        <div className="col-span-3 xl:col-span-1">
          <Card>
            <>
              {error ? (
                <div className="my-2 p-2">
                  <ApolloErrorAlert error={error}></ApolloErrorAlert>
                </div>
              ) : (
                <MaterialAndColorEdit
                  loading={loading}
                  mutationError={mutationError}
                  defaultValues={materialAndColor}
                  updateProduct={updateProduct}
                  mutationLoading={mutationLoading}
                  setDisplayProducts={setDisplayProducts}
                />
              )}
            </>
          </Card>
        </div>
        <div className="col-span-3 xl:col-span-2">
          {displayProducts ? (
            <Card title={t(displayProducts.type)} secondaryTitle={displayProducts.elementLabel}>
              <>
                {queryError ? (
                  <ApolloErrorAlert error={queryError} />
                ) : mainMaterialLoading ||
                  secondaryMaterialLoading ||
                  colorLoading ||
                  supplierColorLoading ? (
                  <div className="grid grid-cols-3 gap-6">
                    {[...Array(6)].map((_, i) => (
                      <div key={i} className="skeleton h-56 w-full" />
                    ))}
                  </div>
                ) : products.length > 0 ? (
                  <div className="grid grid-cols-2 gap-6 lg:grid-cols-3">
                    {products.map((product, i) => (
                      <SimpleCard key={i} product={product} />
                    ))}
                  </div>
                ) : (
                  <InformationAlert>{t('product.materials-colors.no-product')}</InformationAlert>
                )}
              </>
            </Card>
          ) : (
            <Card>
              <div className="mx-auto flex h-full w-full max-w-xs items-center text-center text-dark">
                <div className="flex flex-col items-center ">
                  <CursorArrowRaysIcon className="w-10 stroke-1" />
                  <span>{t('product.materials-colors.select')}</span>
                </div>
              </div>
            </Card>
          )}
        </div>
      </div>
    </>
  );
};

const makeDefaultValues = (product: Product) => {
  return {
    material1: product.material1
      ? {
          value: product.material1.id as string,
          label: product.material1.label,
          isActive: product.material1.is_active,
        }
      : null,
    material2: product.material2
      ? {
          value: product.material2.id as string,
          label: product.material2.label,
          isActive: product.material2.is_active,
        }
      : null,
    color: product.color
      ? {
          value: product.color.id as string,
          label: product.color.label,
          isActive: product.color.is_active,
          hex_code: product.color.hex_code,
        }
      : null,
    supplier_color: product.supplier_color
      ? {
          value: product.supplier_color,
          label: product.supplier_color,
        }
      : null,
  };
};

export default Index;
