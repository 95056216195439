import React, { Fragment } from 'react';
import { t } from 'i18next';
import { BookOpenIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { ProductOffer } from 'types/Product';
import { Menu, Transition } from '@headlessui/react';
import { PERCENTAGE_REWARD_FOR_WAITING } from 'global';
import Badge from 'components/Common/Badge';
import RoundedFlag from 'components/Common/RoundedFlag';
import History from './History';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import usePriceFormatter from '../../../hooks/usePriceFormatter';

type Props = {
  offer: ProductOffer;
  setOpenEdit: React.Dispatch<React.SetStateAction<boolean>>;
  setOfferToEdit: React.Dispatch<React.SetStateAction<ProductOffer | null>>;
  isRewardedForWaiting: boolean;
};

const Offer = ({ offer, setOpenEdit, setOfferToEdit, isRewardedForWaiting }: Props) => {
  const { formatPrice } = usePriceFormatter();
  let percentDiscount = offer.percentDiscount;
  let isPromotion = offer.isPromotion;

  if (offer.futurePriceWithEcotax) {
    isPromotion = true;
    percentDiscount = Math.round(
      (100 * (offer.highestPrice - offer.futurePriceWithEcotax)) / offer.highestPrice
    );
  }
  let priceWithEcotax = offer.priceWithEcotax;
  if (isRewardedForWaiting) {
    priceWithEcotax -= priceWithEcotax * ((percentDiscount ?? 0) / 100);
  }

  const editOffer = () => {
    setOfferToEdit(offer);
    setOpenEdit(true);
  };

  return (
    <tr>
      <td className="px-3 py-3.5">
        <div className="flex items-center gap-3">
          <RoundedFlag iso={offer.country.iso} />
          <span>{offer.country.name}</span>
        </div>
      </td>
      <td className="px-3 py-3.5">
        {percentDiscount ? (
          <>
            <p className="font-semibold">{`${
              offer.futurePriceWithEcotax
                ? formatPrice(offer.futurePriceWithEcotax)
                : formatPrice(priceWithEcotax)
            } €`}</p>
            <p className="text-secondary line-through">{`${offer.highestPrice} €`}</p>
          </>
        ) : (
          <span className="font-semibold">{`${
            offer.price_without_ecotax ? priceWithEcotax : '-'
          } €`}</span>
        )}
      </td>
      <td className="px-3 py-3.5">
        {isRewardedForWaiting ? (
          <Badge type="rewarded">
            <div className="py-1">{`-${PERCENTAGE_REWARD_FOR_WAITING} %`}</div>
          </Badge>
        ) : percentDiscount ? (
          isPromotion ? (
            <Badge type="promotion">
              <div className="py-1">{`-${percentDiscount} %`}</div>
            </Badge>
          ) : (
            <Badge type="sales">
              <div className="py-1">{`-${percentDiscount} %`}</div>
            </Badge>
          )
        ) : (
          '-'
        )}
      </td>
      <td className="hidden px-3 py-3.5 lg:table-cell">
        {isRewardedForWaiting ? (
          <span className="font-semibold">{t('product.offers.table.rewarded')}</span>
        ) : percentDiscount ? (
          isPromotion ? (
            <span className="font-semibold">{t('product.offers.table.promotion')}</span>
          ) : offer.markdown > 1 ? (
            <span className="font-semibold">
              {t('product.offers.table.sales-2', { markdown: offer.markdown })}
            </span>
          ) : (
            <span className="font-semibold">{t('product.offers.table.sales-1')}</span>
          )
        ) : (
          '-'
        )}
      </td>
      <td className="hidden px-3 py-3.5 2xl:table-cell">
        {offer.country.isDuringSales ? (
          <Badge type="sales">
            <>
              {t('product.offers.table.sales-from-to', {
                startDate: moment(offer.country.salesPeriod?.start).format('DD/MM/YYYY HH:mm'),
                endDate: moment(offer.country.salesPeriod?.end).format('DD/MM/YYYY HH:mm'),
              })}
            </>
          </Badge>
        ) : percentDiscount ? (
          isPromotion ? (
            <Badge type="promotion">
              <>
                {offer.startDiscountDate
                  ? t('product.offers.table.promotion-from-to', {
                      startDate: moment(offer.startDiscountDate).format('DD/MM/YYYY HH:mm'),
                      endDate: moment(offer.endDiscountDate).format('DD/MM/YYYY HH:mm'),
                    })
                  : t('product.offers.table.promotion-until', {
                      date: moment(offer.endDiscountDate).format('DD/MM/YYYY HH:mm'),
                    })}
              </>
            </Badge>
          ) : (
            '-'
          )
        ) : (
          '-'
        )}
      </td>
      <td>
        <div className="flex items-center justify-end gap-3">
          <Tippy content={t('global.history')}>
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="flex items-center">
                  <BookOpenIcon className="w-6 cursor-pointer text-secondary hover:text-dark" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95">
                <Menu.Items className="absolute right-0 z-10 mt-2 max-h-52 w-fit origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <History offer={offer} />
                </Menu.Items>
              </Transition>
            </Menu>
          </Tippy>
          <Tippy content={t('global.actions.modify')}>
            <PencilSquareIcon
              className="w-6 cursor-pointer text-secondary hover:text-dark"
              onClick={editOffer}
            />
          </Tippy>
        </div>
      </td>
    </tr>
  );
};

export default Offer;
