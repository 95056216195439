import React, { Fragment } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { productTagsQuery } from 'graphql/Queries/Product/Header/productTags';
import { visibilityInformationQuery } from 'graphql/Queries/Product/Visibility/visibilityInfo';
import { switchProductActivationMutation } from 'graphql/Mutations/Product/Tags/switchActivation';
import { TAG_ACTIVE_ID } from 'global';
import useNotification from 'hooks/useNotification';
import { Menu, Transition } from '@headlessui/react';
import TagHistory from '../TagHistory';
import { Toggle } from 'components/Common/Toggle';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';

interface ActivationProps {
  active: boolean;
}

const Activation = ({ active }: ActivationProps) => {
  const { t } = useTranslation();
  const params = useParams();

  const { setNotification, setError } = useNotification();

  const toggleText = active ? t('global.active') : t('global.inactive');

  const [switchProductActivation, { loading }] = useMutation(switchProductActivationMutation);

  const onClick = () => {
    switchProductActivation({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productTagsQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: visibilityInformationQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        id: params.productId,
        active: !active,
        tag_id: TAG_ACTIVE_ID,
      },
      onCompleted: () => {
        const message = !active ? t('product.activate') : t('product.deactivate');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  return (
    <div className="flex items-center space-x-3">
      <Toggle enabled={active} onChange={onClick} disabled={loading} />
      <span className="text-sm font-medium text-dark">{toggleText}</span>
      <div className="flex items-center">
        <Menu as="div" className="relative inline-block h-5 text-left">
          <Menu.Button className="inline-flex w-full justify-center text-gray-400 hover:text-dark">
            <Tippy content={t('global.history')}>
              <BookOpenIcon className="w-5" />
            </Tippy>
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95">
            <Menu.Items className="absolute right-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <TagHistory tagId={TAG_ACTIVE_ID} />
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default Activation;
