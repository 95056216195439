import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useQuery } from '@apollo/client';
import { supplierProductionSitesQuery } from 'graphql/Queries/Supplier/ProductionSites/supplierProductionSites';
import { useSupplierContextType } from 'components/Contexts/SupplierContext';
import { Button } from 'components/Common/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ProductionSite } from 'types/Supplier';
import Title from 'components/Common/Title';
import List from './List';
import AddProductionSite from './AddProductionSite';

const Index = () => {
  const { supplierExists } = useSupplierContextType();
  const params = useParams();

  const [openSlide, setOpenSlide] = useState<boolean>(false);

  const { data, loading } = useQuery(supplierProductionSitesQuery, {
    variables: { id: params.supplierId },
    fetchPolicy: 'network-only',
  });

  if (!supplierExists) return <></>;

  if (loading)
    return (
      <>
        <Title title={t('supplier.production-sites.title')} />
        <div className="flex h-96 flex-col items-center justify-center space-y-6 text-dark">
          <div className="skeleton h-6 w-72" />
          <div className="skeleton h-10 w-36" />
        </div>
      </>
    );

  const supplierProductionSites: ProductionSite[] = data?.supplier?.productionSites || [];

  return (
    <div>
      <AddProductionSite setOpen={setOpenSlide} open={openSlide} />

      <Title
        title={t('supplier.production-sites.title')}
        addOnEnd={
          supplierProductionSites.length > 0 ? (
            <Button type="button" onClick={() => setOpenSlide(true)}>
              <>
                <PlusIcon className="mr-2 h-5 text-white" />
                {t('supplier.production-sites.add-site')}
              </>
            </Button>
          ) : null
        }
      />

      {supplierProductionSites.length > 0 ? (
        <List productionSites={supplierProductionSites} />
      ) : (
        <div className="flex h-96 flex-col items-center justify-center space-y-6 text-center text-dark">
          <div>{t('supplier.production-sites.no-production-site')}</div>
          <Button type="button" onClick={() => setOpenSlide(true)}>
            <>
              <PlusIcon className="mr-2 h-5 text-white" />
              {t('supplier.production-sites.add-site')}
            </>
          </Button>
        </div>
      )}
    </div>
  );
};
export default Index;
