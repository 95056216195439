import React from 'react';
import { useParams } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { supplierProductionSitesQuery } from 'graphql/Queries/Supplier/ProductionSites/supplierProductionSites';
import { deleteProductionSiteMutation } from 'graphql/Mutations/Supplier/ProductionSites/deleteProductionSite';
import { TrashIcon } from '@heroicons/react/24/outline';
import { ProductionSite } from 'types/Supplier';
import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import Modal from 'components/Common/Modal';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  site: ProductionSite | null;
};

const DeleteProductionSite = ({ open, setOpen, site }: Props) => {
  const { setNotification } = useNotification();
  const params = useParams();

  const [deleteContact, { loading, error, reset: resetMutation }] = useMutation(
    deleteProductionSiteMutation
  );

  const close = () => {
    setOpen(false);
    resetMutation();
  };

  const { handleSubmit } = useForm();

  const onSubmit = handleSubmit(() => {
    if (!site) return;

    deleteContact({
      variables: {
        id: site.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: supplierProductionSitesQuery,
          variables: {
            id: params.supplierId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.production-sites.delete-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <Modal
      theme="danger"
      title={t('supplier.production-sites.delete-title')}
      buttonText={t('global.actions.delete')}
      open={open}
      loading={loading}
      error={error}
      onClose={() => close()}
      icon={<TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
      onSubmit={onSubmit}>
      <div className="mt-6 space-y-6">
        <Trans
          i18nKey="supplier.production-sites.delete-confirmation"
          values={{
            name: site?.name,
          }}
        />
      </div>
    </Modal>
  );
};
export default DeleteProductionSite;
