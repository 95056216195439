import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { OptionProps } from 'react-select';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { allVariantsQuery } from 'graphql/Queries/Product/Offers/allVariants';
import { productActiveOffersQuery } from 'graphql/Queries/Product/Offers/activeOffers';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { importOffersInformationsMutation } from 'graphql/Mutations/Product/Offers/import';
import { ID } from 'types/Global';
import { Product, ProductOffer } from 'types/Product';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';
import Select from 'components/Common/Select';
import Checkbox from 'components/Common/Checkbox';
import Badge from 'components/Common/Badge';
import moment from 'moment';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setHasVariant: React.Dispatch<SetStateAction<boolean>>;
};

type VariantOption = {
  id: ID;
  label: string;
  value: string;
  activeOffers: ProductOffer[];
} & OptionProps;

const Import = ({ open, setOpen, setHasVariant }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [countryIds, setCountryIds] = useState<ID[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<VariantOption | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { data, error, loading } = useQuery(allVariantsQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  const [importOffers, { loading: mutationLoading, error: mutationError, reset }] = useMutation(
    importOffersInformationsMutation
  );

  useEffect(() => {
    if (data?.product?.allVariants.length > 0) {
      setHasVariant(true);
    }
  }, [setHasVariant, data]);

  useEffect(() => {
    setIsDirty(countryIds.length > 0);
  }, [countryIds]);

  const { handleSubmit } = useForm();

  const close = () => {
    setSelectedVariant(null);
    setOpen(false);
    reset();
  };

  const options = data?.product?.allVariants.map((variant: Product) => {
    return {
      value: variant.id,
      label: variant.currentTranslation?.internal_title,
      activeOffers: variant.activeOffers,
    };
  });

  const onChangeCheckbox = (id: ID, checked: boolean) => {
    setCountryIds((prevElements) => {
      if (checked) {
        return prevElements.includes(id) ? prevElements : [...prevElements, id];
      } else {
        return prevElements.filter((element) => element !== id);
      }
    });
  };

  const onSubmit = handleSubmit(() => {
    if (!countryIds) return;
    importOffers({
      variables: {
        id: params.productId,
        from_product_id: selectedVariant?.value,
        country_ids: countryIds,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productActiveOffersQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.offers.import.success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.offers.import.title')}
      buttonText={t('global.actions.import')}
      onCancel={close}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={mutationLoading}
      error={mutationError}>
      <>
        <Select
          label={t('product.offers.import.label')}
          options={options}
          loadingOptions={loading}
          apolloError={error}
          isClearable
          value={selectedVariant}
          onChange={(e) => {
            setSelectedVariant(e as VariantOption);
            const newVariant = e as VariantOption;
            setSelectedVariant(newVariant);
            const newCountryIds = newVariant.activeOffers
              .filter((offer) => offer.price_without_ecotax > 0)
              .map((offers) => offers.country.id);
            setCountryIds(newCountryIds);
          }}
        />

        <div>
          {selectedVariant && (
            <>
              <div className="mb-6 mt-8 w-full border-t border-gray-300" />
              <div className="mb-6 font-semibold text-dark">{t('global.informations-choice')}</div>
            </>
          )}
          <div className="space-y-6 divide-y divide-gray-300">
            {selectedVariant?.activeOffers.map((offer, i) => {
              let percentDiscount = offer.percentDiscount;
              let isPromotion = offer.isPromotion;
              if (offer.futurePriceWithEcotax) {
                isPromotion = true;
                percentDiscount = Math.round(
                  (100 * (offer.highestPrice - offer.futurePriceWithEcotax)) / offer.highestPrice
                );
              }

              return (
                <div key={i} className="mb-2 flex items-center justify-between pt-6 text-sm">
                  <Checkbox
                    iso={offer.country.iso}
                    label={offer.country.name}
                    defaultChecked={offer.price_without_ecotax > 0}
                    disabled={!offer.price_without_ecotax}
                    onChange={(e) => onChangeCheckbox(offer.country.id, e.target.checked)}
                  />
                  <div className="flex flex-col items-end gap-2">
                    {percentDiscount ? (
                      <div className="flex gap-2">
                        <p className="font-semibold">{`${
                          offer.futurePriceWithEcotax
                            ? offer.futurePriceWithEcotax
                            : offer.priceWithEcotax
                        } €`}</p>
                        <p className="text-secondary line-through">{`${offer.highestPrice} €`}</p>
                      </div>
                    ) : (
                      <span className="font-semibold">{`${
                        offer.price_without_ecotax ? offer.priceWithEcotax : '-'
                      } €`}</span>
                    )}
                    {!!percentDiscount &&
                      (isPromotion ? (
                        <Badge type="promotion">
                          <>
                            {offer.startDiscountDate
                              ? t('product.offers.table.promotion-from-to', {
                                  startDate: moment(offer.startDiscountDate).format(
                                    'DD/MM/YYYY HH:mm'
                                  ),
                                  endDate: moment(offer.endDiscountDate).format('DD/MM/YYYY HH:mm'),
                                })
                              : t('product.offers.table.promotion-until', {
                                  date: moment(offer.endDiscountDate).format('DD/MM/YYYY HH:mm'),
                                })}
                          </>
                        </Badge>
                      ) : (
                        <Badge type="sales">
                          <>
                            {offer.markdown > 1
                              ? t('product.offers.table.sales-2', { markdown: offer.markdown })
                              : t('product.offers.table.sales-1')}
                          </>
                        </Badge>
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    </SlideOver>
  );
};
export default Import;
