import { gql } from 'graphql.macro';

export const findFeatureLabelsByOffsetQuery = gql`
  query FindFeatureLabelsByOffset($offset: Int!, $number: Int!) {
    findFeatureLabelsByOffset(offset: $offset, number: $number) {
      id
      text
    }
  }
`;
