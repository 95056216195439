import React from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { productPurchaseCommentsQuery } from 'graphql/Queries/Product/Supplier/productPurchaseComments';
import { updatePurchaseCommentsMutation } from 'graphql/Mutations/Product/PurchaseInformation/updatePurchaseComments';
import { Button } from 'components/Common/Button';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';
import useNotification from 'hooks/useNotification';

function PurchaseInformation() {
  const params = useParams();
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();

  const { loading, error } = useQuery(productPurchaseCommentsQuery, {
    variables: { id: params.productId },
    onCompleted: (data) => {
      reset({ productPurchaseComments: data.product.purchase_comments });
    },
  });

  const {
    formState: { isDirty },
    reset,
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      productPurchaseComments: '',
    },
  });

  const [updatePurchaseComments, { loading: updatePurchaseCommentsLoading }] = useMutation(
    updatePurchaseCommentsMutation
  );

  const onSubmit = (values: { productPurchaseComments: string }) => {
    updatePurchaseComments({
      variables: {
        id: params.productId,
        purchaseComments: values.productPurchaseComments,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.purchase-information.purchase-comments.success'),
          type: 'success',
        });
        reset(values);
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  if (error) return <ApolloErrorAlert error={error} />;

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col space-y-6">
        <div>
          <h2 className="text-sm font-medium leading-6 text-dark">
            {t('product.purchase-information.purchase-comments.title')}
          </h2>
          {loading ? (
            <div className="skeleton h-52"></div>
          ) : (
            <textarea
              rows={10}
              className="mt-1 block h-52 w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
              placeholder={t('product.purchase-information.purchase-comments.placeholder')}
              {...register('productPurchaseComments')}
            />
          )}
        </div>
        <div className="flex justify-end  ">
          <Button
            type="submit"
            disabled={updatePurchaseCommentsLoading || !isDirty}
            loading={updatePurchaseCommentsLoading}>
            {t('global.actions.save')}
          </Button>
        </div>
      </form>
    </Card>
  );
}

export default PurchaseInformation;
