import React from 'react';
import { Disclosure } from '@headlessui/react';
import MultiSelect from './FormUtils/MultiSelect';
import FieldLegend from './FormUtils/FieldLegend';
import { Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type Props = {
  control: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleFilterBucket: any;
};

const StyleFilter = ({ control, styleFilterBucket }: Props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const styleSelect = styleFilterBucket.map((style: any, index: number) => {
    return {
      key: index,
      label: style.style.hits.hits[0]._source.style.label,
      value: style.key,
      minilabel: style.style.hits.hits[0]._source.style.label,
    };
  });
  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div">
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.style.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              <MultiSelect name={'style'} control={control} options={styleSelect} />
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default StyleFilter;
