import Header from './Header';
import ContactTypes from './ContactTypes';

const Index = () => {
  return (
    <>
      <Header></Header>
      <div className="space-y-6 p-4">
        <ContactTypes />
      </div>
    </>
  );
};

export default Index;
