import { useForm } from 'react-hook-form';

const useFilter = () => {
  const countSelectedFilter = (filters) => {
    let count = 0;
    Object.entries(filters).forEach((elem) => {
      if (typeof elem[1] === 'object' && elem[1]) {
        count += elem[1].length ? elem[1].length : 1;
      } else if (![undefined, false].includes(elem[1]) && elem[1]) {
        count += 1;
      }
    });
    return count;
  };

  const FilterForm = () => {
    const methods = useForm();
    return methods;
  };

  return { countSelectedFilter, FilterForm };
};
export default useFilter;
