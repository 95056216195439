import { useTranslation } from 'react-i18next';

const SettingsLayoutSupplierNavigation = () => {
  const { t } = useTranslation();

  const SupplierNavigation = [
    {
      name: t('settings.menu.suppliers.contact-types'),
      href: '/settings/contact',
    },
    {
      name: t('settings.menu.suppliers.purchase-infos'),
      href: '/settings/purchase-infos',
    },
  ];

  return SupplierNavigation;
};
export default SettingsLayoutSupplierNavigation;
