import React, { useRef } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { InputTextAreaControl } from 'components/Common/InputTextAreaControl';
import RoundedFlag from 'components/Common/RoundedFlag';
import SlideOver from 'components/Common/SlideOver';
import useNotification from 'hooks/useNotification';
import { updateProductDisplayPreferenceMutation } from 'graphql/Mutations/Product/updateProductDisplayPreference';
import { productQuery } from 'graphql/Queries/Product/Visibility/Comments/productComments';
import { visibilityInformationQuery } from 'graphql/Queries/Product/Visibility/visibilityInfo';
import { VISIBILITY_FORBIDDEN_ID } from 'global';

type Props = {
  open: boolean;
  currentCountryName?: string;
  currentCountryIso?: string;
  setOpen: (isOpen: boolean) => void;
};

const AddCommentSlideOver = ({ open, setOpen, currentCountryName, currentCountryIso }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const inputRef = useRef(null);
  const { setNotification } = useNotification();

  const [updateProductDisplayPreference, { loading, error }] = useMutation(
    updateProductDisplayPreferenceMutation
  );

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>();

  const onSubmit = handleSubmit((data) => {
    updateProductDisplayPreference({
      variables: {
        id: params.productId,
        iso: currentCountryIso,
        displayPreference: VISIBILITY_FORBIDDEN_ID,
        comment: data.comment,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: visibilityInformationQuery,
          variables: {
            id: params.productId,
          },
        },
      ],

      onCompleted: () => {
        setNotification({
          type: 'success',
          title: t('product.visibility.notifications.title'),
          message: t('product.visibility.notifications.message'),
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const close = () => {
    reset();
    setOpen(false);
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.visibility.ban-country.title')}
      buttonText={t('global.actions.save')}
      isDirty={isDirty}
      onCancel={close}
      onSubmit={onSubmit}
      loading={loading}
      error={error}>
      <div className="mt-2 text-sm text-dark">
        <div>{t('product.visibility.ban-country.description')}</div>
        <div className="my-10 flex space-x-4">
          <RoundedFlag iso={currentCountryIso as string} />
          <label>{currentCountryName}</label>
        </div>
        <InputTextAreaControl
          control={control}
          ref={inputRef}
          label={t('product.visibility.forbidden.label')}
          name={'comment'}
          rows={7}
          isFocused
          isRequired
        />
      </div>
    </SlideOver>
  );
};
export default AddCommentSlideOver;
