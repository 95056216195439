import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { ProductFeature } from 'types/Product';
import { ChevronUpDownIcon } from '@heroicons/react/24/outline';

type Props = {
  feature: ProductFeature;
};

const SortableItem = ({ feature }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: feature.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="cursor-move rounded-md border bg-white"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}>
      <div className="m-3 flex justify-between">
        <span className="leading-6">{feature.label.text}</span>
        <ChevronUpDownIcon className="h-6 w-6 text-dark" />
      </div>
    </div>
  );
};
export default SortableItem;
