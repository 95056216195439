import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FeatureUnit, ProductFeature } from 'types/Product';
import { InputTextControl } from 'components/Common/InputTextControl';
import { OptionProps } from 'types/Global';
import { useMutation } from '@apollo/client';
import { addFeatureMutation } from 'graphql/Mutations/Product/addFeature';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { productFeaturesQuery } from 'graphql/Queries/Product/Features/productFeatures';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';
import AsyncPaginateFeatureLabels from './AsyncPaginateFeatureLabels';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  features: ProductFeature[];
  units: FeatureUnit[];
};

const AddFeature = ({ open, setOpen, features, units }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();

  const [options, setOptions] = useState<OptionProps[]>([]);

  const [addFeature, { loading, error }] = useMutation(addFeatureMutation);

  const schema = yup.object().shape({
    label: yup
      .object()
      .shape({
        value: yup.string().required(t('form.required')),
        label: yup.string().required(t('form.required')),
      })
      .required(t('form.required')),
    value: yup.string().trim().required(t('form.required')),
    unit_id: yup.number().integer().typeError(t('form.number.integer')),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      label: null,
      value: '',
      unit_id: units[0].id,
    },
  });

  const onSubmit = handleSubmit((data) => {
    const labelOption: OptionProps = data.label;
    addFeature({
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productFeaturesQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        product_id: params.productId,
        label: {
          id: labelOption.__isNew__ ? 0 : labelOption.value,
          text: labelOption.label,
          isNew: labelOption.__isNew__ ?? false,
        },
        value: data.value,
        unit_id: data.unit_id === 0 ? 4 : data.unit_id,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('global.notif.update-success'),
          type: 'success',
        });
        onCancel();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });
  const onCancel = () => {
    reset();
    setOpen(false);
  };

  useEffect(() => {
    const values = units.map((unit) => {
      return {
        value: unit.id as string,
        label: unit.text,
      };
    });
    setOptions(values);
  }, [units]);

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.features.add')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDirty={isDirty}
      loading={loading}
      error={error}>
      <>
        <div className="space-y-6">
          <div>
            <AsyncPaginateFeatureLabels
              control={control}
              name="label"
              features={features}
              loading={loading}
            />
          </div>
          <div>
            <InputTextControl
              control={control}
              name="value"
              label={t('product.features.value')}
              select={{
                name: 'unit_id',
                options,
              }}
            />
          </div>
        </div>
      </>
    </SlideOver>
  );
};

export default AddFeature;
