import { gql } from 'graphql.macro';

export const updatePurchaseCommentsMutation = gql`
  mutation UpdatePurchaseComments($id: ID!, $purchaseComments: String!) {
    updateProductPurchaseComments(id: $id, purchase_comments: $purchaseComments) {
      id
      purchase_comments
    }
  }
`;
