import { gql } from 'graphql.macro';

export const findProductModelsByTextQuery = gql`
  query FindProductModelsByText($text: String!) {
    findProductModelsByText(text: $text) {
      id
      name
      active
      isUsed
    }
  }
`;
