import PropTypes from 'prop-types';

const CancelRangeButton = ({ handle }) => {
  return (
    <button
      className="hover:fill-red group absolute left-2 top-3 rounded-l bg-stone-200 px-1 pb-[3px] pt-[2px] font-bold text-red-200 hover:bg-[#FFBDAD]"
      type="button"
      onClick={handle}>
      <svg
        height="14"
        className="fill-black group-hover:fill-red-700"
        width="14"
        viewBox="0 0 20 20"
        focusable="false">
        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
      </svg>
    </button>
  );
};

CancelRangeButton.propTypes = {
  handle: PropTypes.func.isRequired,
};

export default CancelRangeButton;
