import { gql } from 'graphql.macro';

export const ecomobilierCategoryQuery = gql`
  query ecomobilierCategory($id: Int!) {
    ecomobilierCategory(id: $id) {
      id
      code
      padding
      productTypes {
        id
        code
        label
      }
    }
  }
`;
