import { gql } from 'graphql.macro';

export const productQuery = gql`
  query ProductComments($id: ID!) {
    product(id: $id) {
      id
      comments
    }
  }
`;
