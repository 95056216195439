import React, { useEffect, useState } from 'react';
import { MODULES, FORMAT } from 'global';
import { useMutation, useApolloClient, ApolloError } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import ReactQuill, { Value } from 'react-quill';
import useNotification from 'hooks/useNotification';
import { updateCommercialDescriptionMutation } from 'graphql/Mutations/Product/Editorial/updateCommercialDescription';
import { ID } from 'types/Global';
import { editorialQuery } from 'graphql/Queries/Product/Editorial/editorial';
import { generateProductDescriptionAI } from 'graphql/Queries/Product/Editorial/generateDescriptionAI';
import { useParams } from 'react-router-dom';
import { createProductTranslationMutation } from 'graphql/Mutations/Product/Editorial/createProductTranslation';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

type Props = {
  commercialDescription: string | null;
  translationId: ID | null;
  isLoading: boolean;
  setTranslationRequestDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  langId: ID;
};

const Description = ({
  commercialDescription,
  translationId,
  isLoading,
  setTranslationRequestDisabled,
  langId,
}: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification, setError } = useNotification();
  const [wordCount, setWordCount] = useState(0);

  const [createProductTranslation, { loading: createProductTranslationLoading }] = useMutation(
    createProductTranslationMutation
  );
  const [updateCommercialDescription, { loading: updateCommercialDescriptionLoading }] =
    useMutation(updateCommercialDescriptionMutation);

  const [aiLoading, setAiLoading] = useState(false);

  /**
   * Fetching the AI generated description
   * @returns {void}
   */
  const client = useApolloClient();
  const fetchGenerateDescriptionAI = async () => {
    try {
      setAiLoading(true);
      const { data } = await client.query({
        query: generateProductDescriptionAI,
        variables: { id: params.productId, langId },
        fetchPolicy: 'network-only',
      });
      setValue('description', data.generateProductDescriptionAI.answer);
    } catch (error: unknown) {
      if (error instanceof ApolloError) {
        setError(error);
      } else {
        throw new Error('Error on AI description generation');
      }
    } finally {
      setAiLoading(false);
    }
  };

  const mutationLoading = createProductTranslationLoading || updateCommercialDescriptionLoading;
  const {
    formState: { isDirty },
    control,
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      description: commercialDescription,
    },
  });

  const handleEditorChange = (value: string) => {
    const text = value.replace(/<[^>]+>/g, '');
    const words = text.trim().split(/\s+/);
    setWordCount(words.length);

    setValue('description', value);
  };

  useEffect(() => {
    if (commercialDescription?.replace(/(<([^>]+)>)/gi, '')) {
      setTranslationRequestDisabled(false);
    }
    reset({
      description: commercialDescription,
    });

    if (commercialDescription) {
      const text = commercialDescription.replace(/<[^>]+>/g, '');
      const words = text.trim().split(/\s+/);
      setWordCount(words.length);
    }
  }, [commercialDescription, reset, setTranslationRequestDisabled]);

  const onSubmit = handleSubmit(async (data) => {
    let translationIdToUpdate = translationId;
    if (!translationIdToUpdate) {
      await new Promise<void>((resolve, reject) => {
        createProductTranslation({
          variables: {
            product_id: params.productId,
            lang_id: langId,
          },
          onCompleted: (res) => {
            translationIdToUpdate = res.createProductTranslation.id;
            resolve();
          },
          onError: (error) => {
            setError(error);
            reject(error);
          },
        });
      });
    }
    setTranslationRequestDisabled(true);
    updateCommercialDescription({
      refetchQueries: [
        {
          query: editorialQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: translationIdToUpdate,
        commercial_description: data.description,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.editorial.sales-description.notif-success'),
          type: 'success',
        });
        reset(data);
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  return (
    <Card
      title={t('product.editorial.sales-description.title')}
      aiButtonText={t('global.generate-with-ai')}
      loading={aiLoading}
      onAiButtonClick={fetchGenerateDescriptionAI}>
      <div className="space-y-2">
        <div className="ml-4 flex justify-end text-sm text-secondary">
          {t('product.editorial.word-count')}{' '}
          {isLoading || aiLoading ? (
            <div className="skeleton ml-2 mt-1 h-[15px] w-[18px]"></div>
          ) : (
            wordCount
          )}
        </div>
        <form onSubmit={onSubmit}>
          {isLoading || aiLoading ? (
            <div className="skeleton h-[393px]"></div>
          ) : (
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <ReactQuill
                  style={{ height: '350px' }}
                  theme="snow"
                  value={field.value as Value}
                  modules={MODULES}
                  formats={FORMAT}
                  onChange={(value) => {
                    field.onChange(value);
                    handleEditorChange(value);
                  }}
                  readOnly={isLoading}
                />
              )}
            />
          )}
          <div className={`${isLoading ? 'mt-6' : 'mt-16'} flex justify-end`}>
            <Button
              loading={mutationLoading}
              disabled={!isDirty || isLoading || mutationLoading || aiLoading}
              type="submit">
              {t('global.actions.save')}
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default Description;
