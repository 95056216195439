import React from 'react';
import { useTranslation } from 'react-i18next';
import { InformationAlert } from 'components/Common/Alerts';
import moment from 'moment';
import { ProductOffer } from 'types/Product';

type Props = {
  offer: ProductOffer;
};

const History = ({ offer }: Props) => {
  const { t } = useTranslation();

  if (!offer?.history.length) {
    return (
      <div className="m-2">
        <InformationAlert>
          <span className="whitespace-nowrap">
            {t('product.offers.history.no-history-available')}
          </span>
        </InformationAlert>
      </div>
    );
  }

  return (
    <>
      <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white text-sm">
        <thead className="sticky top-0 bg-white font-semibold text-dark">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left">
              {t('product.offers.history.price')}
            </th>
            <th scope="col" className="py-3.5 pr-3 text-left">
              {t('product.offers.history.user')}
            </th>
            <th scope="col" className="py-3.5 pr-4 text-right text-sm font-semibold">
              {t('product.offers.history.date')}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 text-secondary">
          {offer?.history.map((history) => {
            const logs = JSON.parse(history.logs);
            return (
              <tr key={history.id}>
                <td className="flex truncate whitespace-nowrap py-3.5 pl-4 pr-3">
                  <span>{`${logs.price}€`}</span>
                  {logs.high_price > 0 && (
                    <span className="ml-2 line-through">{`${logs.high_price}€`}</span>
                  )}
                  {logs.second_high_price > 0 && (
                    <span className="ml-2 line-through">{`${logs.second_high_price}€`}</span>
                  )}
                  {logs.third_high_price > 0 && (
                    <span className="ml-2 line-through">{`${logs.third_high_price}€`}</span>
                  )}
                  {logs.future_discount_price > 0 && (
                    <span className="ml-2">
                      {`${t('product.offers.history.futur-discount')} 
                      : ${logs.future_discount_price}€`}
                    </span>
                  )}
                </td>
                <td className="truncate whitespace-nowrap py-3.5 pr-3 text-left">
                  {history.user.lastname} {history.user.firstname}
                </td>
                <td className="whitespace-nowrap py-3.5 pr-4 text-right">
                  {moment(history.created_at).format('Do MMMM YYYY HH:mm')}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default History;
