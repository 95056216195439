import React, { ReactElement, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ApolloError } from '@apollo/client';
import { Button } from 'components/Common/Button';
import { ApolloErrorAlert } from './Alerts';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

type Props = {
  title: string;
  open: boolean;
  loading?: boolean;
  theme?: keyof typeof variants;
  buttonText: string;
  onClose: () => void;
  onSubmit: () => void;
  error?: ApolloError;
  icon?: React.ReactElement;
  children: ReactElement;
};

type ButtonVariant = 'danger' | 'primary';

const variants = {
  info: 'text-blue-600',
  warning: 'text-yellow-600',
  danger: 'text-red-600',
};

const Modal = ({
  theme = 'info',
  title,
  open,
  buttonText,
  onClose,
  error,
  children,
  icon,
  onSubmit,
  loading,
}: Props) => {
  const { t } = useTranslation();

  const clearAll = () => {
    onClose();
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => clearAll}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 py-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-xl sm:p-6">
                <form onSubmit={onSubmit}>
                  <div className="sm:flex sm:items-start">
                    <div className="ml-2">{icon}</div>
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className={clsx('text-base font-semibold leading-6', variants[theme])}>
                        {title}
                      </Dialog.Title>
                      <div className="mt-2">
                        <div className="text-sm text-gray-500">{children}</div>
                      </div>
                    </div>
                  </div>
                  {error && (
                    <div className="pt-4">
                      <ApolloErrorAlert error={error} />
                    </div>
                  )}
                  <div className="mt-10 flex justify-end gap-4">
                    <Button
                      type="button"
                      theme="secondary"
                      disabled={loading}
                      onClick={() => onClose()}>
                      {t('global.actions.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      theme={theme === 'warning' ? 'primary' : (theme as ButtonVariant)}
                      disabled={loading}
                      loading={loading}>
                      {buttonText}
                    </Button>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;
