import { gql } from 'graphql.macro';

export const updateProductionSiteMutation = gql`
  mutation UpdateProductionSite(
    $id: ID!
    $name: String
    $street: String
    $complementaryAddress: String
    $city: String
    $zipcode: String
    $countryId: Int
  ) {
    updateSupplierProductionSite(
      id: $id
      name: $name
      street: $street
      complementary_address: $complementaryAddress
      city: $city
      zipcode: $zipcode
      country_id: $countryId
    ) {
      id
    }
  }
`;
