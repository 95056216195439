import { OptionProps } from 'types/Global';

export const AUTH_TOKEN = 'auth-token';
export const TRANSPARENT_ERRORS: string[] | unknown[] = ['custom', 'validation'];
export const PRODUCT_VISIBILITY = {
  FORCED: 1,
  NEVER: 2,
  IF_STOCK: 3,
};
export const DEFAULT_PACKAGE_NAME = 'Carton_1';

export const CURRENCY_EURO_ID = 7;

export const CURRENCIES: OptionProps[] = [
  { value: '7', label: 'EUR', symbol: '€' },
  { value: '1', label: 'USD', symbol: '$' },
];

export const getCurrencySymbol = (currency: string) => {
  const currencyObj = CURRENCIES.find((c) => c.label === currency);
  return currencyObj ? currencyObj.symbol : '-';
};

export const INVALID_DATES = [
  '-0001-11-30 00:00:00',
  '0000-00-00 00:00:00',
  '-0001-11-30',
  '0000-00-00',
];

export const MODULES = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const FORMAT = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'list',
  'bullet',
  'indent',
  'link',
];

export const TAG_ACTIVE_ID = 1;
export const TAG_DESCTOCK_ID = 2;
export const TAG_NEW_ID = 3;
export const TAG_FORCE_MARKETPLACE_ID = 4;
export const TAG_SUPPPLY_EXCEPTION_ID = 5;
export const TAG_SHOP_ONLY_ID = 6;
export const TAG_FRAGILE_ID = 7;
export const TAG_GROUPED_ID = 8;
export const TAG_GROUPABLE_ID = 9;

export const statuses = {
  false: 'text-gray-400 bg-gray-100/20',
  true: 'text-green-400 bg-green-500/10',
};

export const VISIBILITY_FORBIDDEN_ID = 2;

export const FRANCE_ID = 31;

export const PERCENTAGE_REWARD_FOR_WAITING = 10;
