import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import { useMutation } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { productActiveOffersQuery } from 'graphql/Queries/Product/Offers/activeOffers';
import { updateProductOfferMutation } from 'graphql/Mutations/Product/Offers/updateProductOffer';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { t } from 'i18next';
import { ProductOffer } from 'types/Product';
import { InputTextControl } from 'components/Common/InputTextControl';
import { SelectControl } from 'components/Common/SelectControl';
import { FRANCE_ID, PERCENTAGE_REWARD_FOR_WAITING } from 'global';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SlideOver from 'components/Common/SlideOver';
import useNotification from 'hooks/useNotification';
import clsx from 'clsx';
import moment from 'moment';
import RoundedFlag from 'components/Common/RoundedFlag';
import Badge from 'components/Common/Badge';

type Props = {
  ecotax?: number;
  offer: ProductOffer | null;
  isRewardedForWaiting: boolean;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Edit = ({ ecotax, offer, isRewardedForWaiting, open, setOpen }: Props) => {
  const params = useParams();

  const { setNotification } = useNotification();

  const [percentage, setPercentage] = useState(0);
  const [rewardedPrice, setRewardedPrice] = useState('- €');
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const markdowns = useMemo(
    () => [
      {
        value: '1',
        label: t('product.offers.edit.markdown-1'),
        isDisabled: (offer?.markdown ?? 0) > 1,
      },
      {
        value: '2',
        label: t('product.offers.edit.markdown-n', { n: 2 }),
        isDisabled: (offer?.markdown ?? 0) > 2,
      },
      {
        value: '3',
        label: t('product.offers.edit.markdown-n', { n: 3 }),
      },
    ],
    [offer]
  );

  const today = new Date();

  const [updateProductOffer, { loading, error, reset: resetMutation }] = useMutation(
    updateProductOfferMutation
  );

  const schema = yup.object().shape({
    high_price: yup
      .number()
      .typeError(t('form.number.positive'))
      .min(1, t('form.number.positive'))
      .transform((curr, orig) => (orig === '' ? 0 : curr))
      .required(t('form.required')),
    discount_price: yup
      .number()
      .typeError(t('form.number.positive'))
      .min(1, t('form.number.positive'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .nullable()
      .test('requiredWithDates', t('form.required'), function (value) {
        if (offer?.country.isDuringSales) return true;
        const { start_date, end_date } = this.parent;
        if (start_date || end_date) {
          return !!value;
        }
        return true;
      })
      .test(
        'discountPriceTooHigh',
        t('product.offers.edit.discount-price-too-high'),
        function (value) {
          if (!value) return true;
          const { high_price } = this.parent;
          if (high_price <= value) {
            return false;
          }
          return true;
        }
      ),
    markdown: yup
      .object()
      .shape({
        value: yup.string().required(t('form.required')),
        label: yup.string().required(t('form.required')),
      })
      .required(t('form.required')),
    start_date: yup.string().nullable(),
    start_hour: yup.string().when('start_date', (values, schema) => {
      if (values[0] && !offer?.country.isDuringSales) return schema.required(t('form.required'));
      return schema.nullable();
    }),
    end_date: yup
      .string()
      .when('discount_price', (values, schema) => {
        if (values[0] && !offer?.country.isDuringSales) {
          return schema.required(t('form.required'));
        }
        return schema.nullable();
      })
      .test(
        'startDateHigherThanEndDate',
        t('product.offers.edit.start-date-too-high'),
        function (value) {
          if (!value) return true;
          const { start_date } = this.parent;
          const startDateObj = new Date(start_date);
          const endDateObj = new Date(value);

          if (startDateObj > endDateObj) {
            return false;
          }
          return true;
        }
      ),
    end_hour: yup.string().when('end_date', (values, schema) => {
      if (values[0] && !offer?.country.isDuringSales) return schema.required(t('form.required'));
      return schema.nullable();
    }),
  });

  const {
    handleSubmit,
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isDirty, errors },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      high_price: '',
      discount_price: '',
      markdown: 1,
      start_date: null,
      end_date: null,
      start_hour: '',
      end_hour: '',
    },
  });

  const setPriceRewardedForWaiting = useCallback(() => {
    let rewardedPrice = getValues('high_price');
    if (!rewardedPrice) {
      setRewardedPrice('- €');
      return;
    }
    rewardedPrice -= rewardedPrice * (PERCENTAGE_REWARD_FOR_WAITING / 100);
    setRewardedPrice(`${Math.round(rewardedPrice * 100) / 100} €`);
    return;
  }, [getValues]);

  const calculatesPercentage = useCallback(() => {
    const highPrice = getValues('high_price');
    const discountPrice = getValues('discount_price');
    if (!discountPrice) {
      setPercentage(0);
      return;
    }
    setPercentage(Math.round((100 * (highPrice - discountPrice)) / highPrice));
  }, [getValues, setPercentage]);

  useEffect(() => {
    if (!offer) return;
    const startDiscountDate =
      offer.startDiscountDate && !isRewardedForWaiting ? new Date(offer.startDiscountDate) : null;
    const endDiscountDate =
      offer.endDiscountDate && !isRewardedForWaiting ? new Date(offer.endDiscountDate) : null;

    setStartDate(startDiscountDate);
    setEndDate(endDiscountDate);

    reset({
      high_price: `${offer.highestPrice}`,
      discount_price: isRewardedForWaiting
        ? ''
        : offer.isDiscount
          ? `${offer.priceWithEcotax}`
          : offer.futurePriceWithEcotax
            ? `${offer.futurePriceWithEcotax}`
            : '',
      markdown: offer.markdown ? markdowns[offer.markdown - 1] : markdowns[0],
      start_date: startDiscountDate,
      end_date: endDiscountDate,
      start_hour: startDiscountDate ? moment(startDiscountDate).format('HH:mm') : '',
      end_hour: endDiscountDate ? moment(endDiscountDate).format('HH:mm') : '',
    });
  }, [offer, isRewardedForWaiting, reset, setStartDate, setEndDate, markdowns]);

  useEffect(() => {
    setValue('start_date', startDate, { shouldDirty: true });
    if (startDate && !getValues('start_hour')) {
      setValue('start_hour', '08:00', { shouldDirty: true });
    }
  }, [startDate, setValue, getValues]);

  useEffect(() => {
    setValue('end_date', endDate, { shouldDirty: true });
    if (endDate && !getValues('end_hour')) {
      setValue('end_hour', '23:59', { shouldDirty: true });
    }
  }, [endDate, setValue, getValues]);

  useEffect(() => {
    if (!watch) return;
    calculatesPercentage();
    if (isRewardedForWaiting) {
      setPriceRewardedForWaiting();
    }

    const subscription = watch(() => {
      calculatesPercentage();
      if (isRewardedForWaiting) {
        setPriceRewardedForWaiting();
      }
    });

    return () => subscription.unsubscribe();
  }, [calculatesPercentage, isRewardedForWaiting, setPriceRewardedForWaiting, watch]);

  const onSubmit = handleSubmit((data) => {
    if (!params.productId || !offer) {
      return;
    }
    updateProductOffer({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productActiveOffersQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        product_id: parseInt(params.productId),
        country_id: parseInt(offer.country.id as string),
        high_price: data.high_price,
        discount_price: data.discount_price,
        markdown: parseInt(data.markdown.value),
        start_date:
          data.start_date && data.start_hour
            ? `${moment(data.start_date).format('YYYY-MM-DD')} ${data.start_hour}`
            : null,
        end_date:
          data.end_date && data.end_hour
            ? `${moment(data.end_date).format('YYYY-MM-DD')} ${data.end_hour}`
            : null,
      },
      onCompleted: () => {
        setNotification({
          type: 'success',
          title: t('global.success'),
          message: t('product.offers.edit.success'),
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    reset();
    setOpen(false);
    resetMutation();
  };

  const deleteDiscount = () => {
    setValue('discount_price', '', { shouldDirty: true });
    setValue('start_hour', '', { shouldDirty: true });
    setValue('end_hour', '', { shouldDirty: true });
    setStartDate(null);
    setEndDate(null);
  };

  if (!offer) return <></>;

  const hasEcotax = parseInt(offer.country.id as string) === FRANCE_ID && !!ecotax;

  return (
    <SlideOver
      title={t('product.offers.edit.title')}
      buttonText={t('global.actions.save')}
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      onCancel={onCancel}
      loading={loading}
      error={error}
      isDirty={isDirty}
      size="max-w-xl">
      <div className="space-y-6 divide-y divide-gray-300 border-b border-gray-300">
        <div className="flex items-center gap-3">
          <RoundedFlag iso={offer.country.iso} />
          <span className="text-sm font-medium text-dark">{offer.country.name}</span>
        </div>
        <div className={clsx(hasEcotax ? 'flex gap-6' : '', 'pt-6')}>
          <InputTextControl
            control={control}
            name="high_price"
            label={t('product.offers.edit.high_price')}
            addOnEnd="€"
            isRequired
          />
          {hasEcotax && (
            <div className="flex items-end justify-end">
              <span className="text-sm font-medium text-primary-500">
                {t('product.offers.edit.ecotax', { ecotax })}
              </span>
            </div>
          )}
        </div>
        {isRewardedForWaiting ? (
          <div className="py-6">
            <span className="text-sm font-medium text-dark">
              {t('product.offers.edit.rewarded')}
            </span>
            <div className="mt-2 flex items-center gap-3">
              <Badge type="rewarded">
                <div className="py-1">{`-${PERCENTAGE_REWARD_FOR_WAITING} %`}</div>
              </Badge>
              <span className="text-lg font-medium text-dark">{rewardedPrice}</span>
            </div>
          </div>
        ) : (
          <Disclosure as="div" defaultOpen={offer.isDiscount || !!offer.futurePriceWithEcotax}>
            {({ open }) => (
              <>
                <h3 className="flow-root">
                  <Disclosure.Button className="flex w-full items-center justify-between py-6 text-gray-400 hover:text-gray-500">
                    <span className="text-sm font-medium uppercase text-dark">
                      {offer.country.isDuringSales
                        ? t('product.offers.edit.sales')
                        : t('product.offers.edit.promotion')}
                    </span>
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <MinusIcon className="w-5" aria-hidden="true" />
                      ) : (
                        <PlusIcon className="w-5" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="py-6">
                  <div className="space-y-6">
                    <div className="flex gap-6 pt-6">
                      <InputTextControl
                        control={control}
                        name="discount_price"
                        label={t('product.offers.edit.discount_price')}
                        addOnEnd="€"
                      />
                      {percentage > 0 && (
                        <div className="flex items-end justify-end">
                          <span className="text-sm font-medium text-dark">
                            {t('product.offers.edit.percent-discount', { percentage })}
                          </span>
                        </div>
                      )}
                    </div>
                    {offer.country.isDuringSales ? (
                      <SelectControl
                        control={control}
                        name="markdown"
                        label={t('product.offers.edit.markdown')}
                        options={markdowns}
                      />
                    ) : (
                      <>
                        <div className="flex justify-between gap-3 pt-6">
                          <div>
                            <label className="mb-2 block text-sm font-medium leading-6 text-dark">
                              {t('product.offers.edit.start-date')}
                            </label>
                            <DatePicker
                              className="w-[240px] flex-1 rounded-md border-gray-300 py-1.5 text-dark shadow-sm placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500 sm:text-sm sm:leading-6"
                              selected={startDate}
                              onChange={(date: Date | null) => setStartDate(date)}
                              dateFormat="dd/MM/yyyy"
                              minDate={today}
                              isClearable
                            />
                          </div>
                          <div className="w-40">
                            <InputTextControl
                              control={control}
                              name="start_hour"
                              type="time"
                              label={t('product.offers.edit.start-hour')}
                            />
                          </div>
                        </div>
                        <div className="flex justify-between gap-3 pt-6">
                          <div>
                            <label className="mb-2 block text-sm font-medium leading-6 text-dark">
                              {t('product.offers.edit.end-date')}
                            </label>
                            <DatePicker
                              className={clsx(
                                'block w-[240px] rounded-md  py-1.5 text-dark shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6',
                                errors['end_date']
                                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                                  : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500'
                              )}
                              selected={endDate}
                              onChange={(date: Date | null) => setEndDate(date)}
                              dateFormat="dd/MM/yyyy"
                              minDate={startDate ?? today}
                              isClearable
                            />
                            {errors['end_date'] && (
                              <p className="mt-2 text-sm text-red-700">
                                {errors['end_date'].message as string}
                              </p>
                            )}
                          </div>
                          <div className="w-40">
                            <InputTextControl
                              control={control}
                              name="end_hour"
                              type="time"
                              label={t('product.offers.edit.end-hour')}
                            />
                          </div>
                        </div>
                      </>
                    )}
                    <div className="flex justify-end">
                      <Disclosure.Button onClick={deleteDiscount}>
                        <span className="flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-red-500 shadow-sm ring-1 ring-inset ring-red-500 hover:bg-red-500 hover:text-white">
                          {t('global.actions.delete')}
                        </span>
                      </Disclosure.Button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        )}
      </div>
    </SlideOver>
  );
};

export default Edit;
