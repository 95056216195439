import { gql } from 'graphql.macro';

export const deleteModelMutation = gql`
  mutation DeleteModel($id: ID!) {
    deleteProductModel(id: $id) {
      id
      name
    }
  }
`;
