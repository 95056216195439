import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { t } from 'i18next';
import SettingsLayoutNavigation from './SettingsLayoutProductNavigation';
import SettingsLayoutSupplierNavigation from './SettingsLayoutSupplierNavigation';
import clsx from 'clsx';

const SettingsLayout = () => {
  const productNavigation = SettingsLayoutNavigation();
  const supplierNavigation = SettingsLayoutSupplierNavigation();

  const isActiveLink = (href: string) => {
    return window.location.pathname === href;
  };

  return (
    <>
      <div className="flex h-full flex-row">
        <nav aria-label="Sections" className="hidden md:block fixed h-full w-80 bg-menu p-10">
          <div className="pb-6 font-semibold uppercase text-secondary">
            {t('settings.menu.products.title')}
          </div>
          {productNavigation.map((item) => {
            return (
              <NavLink
                key={item.name}
                to={item.href}
                className={clsx(
                  isActiveLink(item.href) ? 'font-semibold' : 'hover:font-semibold',
                  'flex p-1 text-dark'
                )}>
                {item.name}
              </NavLink>
            );
          })}

          <div className="pb-6 pt-14 font-semibold uppercase text-secondary">
            {t('settings.menu.suppliers.title')}
          </div>
          {supplierNavigation.map((item) => {
            return (
              <NavLink
                key={item.name}
                to={item.href}
                className={clsx(
                  isActiveLink(item.href) ? 'font-semibold' : 'hover:font-semibold',
                  'flex p-1 text-dark'
                )}>
                {item.name}
              </NavLink>
            );
          })}
        </nav>
        <div className="w-full md:ml-80">
          <div className="container mx-auto flex-grow p-6">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};

export default SettingsLayout;
