import { gql } from 'graphql.macro';

export const updatePortMutation = gql`
  mutation UpdatePort($id: ID!, $label: String) {
    updatePort(id: $id, label: $label) {
      id
      label
      active
      used
    }
  }
`;
