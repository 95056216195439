import React, { useContext, useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ActiveTaxContext } from '../Context/ActiveTaxContext';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { Button } from 'components/Common/Button';
import { ecomobilierQuery } from 'graphql/Queries/Ecotax/ecomobilier';
import { updateDEEEMutation } from 'graphql/Mutations/Ecotax/updateDEEE';
import { SubmitHandler } from 'react-hook-form';
import { ApolloError } from '@apollo/client';
import { DEEE as DEEEType } from 'types/Product';
import { ID } from 'types/Global';
import TaxSwitchModal from '../ConfirmTaxSwitch';
import useNotification from 'hooks/useNotification';

type Props = {
  productId: ID;
  DEEEdata: DEEEType;
  errorAmount?: ApolloError;
};

type FormData = {
  data: Partial<DEEEType>;
  forced: boolean;
};

const DEEE = ({ productId, DEEEdata, errorAmount }: Props) => {
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();
  const { DEEEStatus, setDEEE, setEcomobilier } = useContext(ActiveTaxContext);

  const {
    handleSubmit,
    register,
    reset,
    resetField,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      paidInAdvance: DEEEdata?.payed_before ? true : false,
      enable: DEEEdata?.enable ? true : false,
    },
  });

  const [updateDEEE, { loading }] = useMutation(updateDEEEMutation);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onSubmit: SubmitHandler<FormData> = (formData) => {
    updateDEEE({
      variables: {
        id: productId,
        payed_before: formData.data.paidInAdvance ? 1 : 0,
        enable: formData.data.enable ? 1 : 0,
        forced: formData.forced,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: productId,
          },
        },
        {
          query: ecomobilierQuery,
          variables: {
            id: productId,
          },
        },
      ],
      onCompleted: (data) => {
        if (!data.updateDEEE) {
          setShowConfirmationModal(true);
        } else {
          setShowConfirmationModal(false);
          setNotification({
            title: t('global.success'),
            message: t('product.ecotax.notify.deee-update'),
            type: 'success',
          });
          reset({
            paidInAdvance: formData.data.paidInAdvance,
            enable: formData.data.enable,
          });
          setDEEE(formData.data.enable ?? false);
          if (formData.data.enable) {
            setEcomobilier(false);
          }
        }
      },
      onError: (error) => {
        setShowConfirmationModal(false);
        setError(error);
      },
    });
  };

  const handleResponse = (e: string) => {
    if (e === 'close') {
      setShowConfirmationModal(false);
    } else if (e === 'accept') {
      handleSubmit((data) => onSubmit({ data, ...{ forced: true } }))();
    }
  };

  useEffect(() => {
    if (DEEEStatus !== null) {
      resetField('enable', { defaultValue: DEEEStatus });
    }
  }, [DEEEStatus, resetField]);

  return (
    <>
      {showConfirmationModal && (
        <TaxSwitchModal
          type={'DEEE'}
          openModal={showConfirmationModal}
          handleResponse={(e) => handleResponse(e)}
          mutationLoading={loading}
        />
      )}
      <form
        className="flex grow flex-col justify-between"
        onSubmit={handleSubmit((data) => onSubmit({ data, ...{ forced: false } }))}>
        <div className="flex flex-col space-y-6 px-6">
          <div className="mt-6 sm:col-span-6">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="deee-paidInAdvance"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                  disabled={errorAmount !== undefined}
                  {...register('paidInAdvance')}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="deee-paidInAdvance"
                  className=" block text-sm font-medium text-dark">
                  {t('product.ecotax.paid-in-advance')}
                </label>
              </div>
            </div>
          </div>
          <div className="mt-2 sm:col-span-6">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="deee-enable"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                  disabled={errorAmount !== undefined}
                  {...register('enable')}
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="deee-enable" className="block text-sm font-medium text-dark">
                  {t('product.ecotax.applicable')}
                </label>
              </div>
            </div>
          </div>
          <div className="mt-6 sm:col-span-3"></div>
        </div>
        <div className="flex justify-end p-6 text-right sm:px-6">
          <Button type="submit" loading={loading} disabled={loading || !isDirty}>
            {t('global.actions.save')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default DEEE;
