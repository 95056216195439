import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { productTagsQuery } from 'graphql/Queries/Product/Header/productTags';
import { visibilityInformationQuery } from 'graphql/Queries/Product/Visibility/visibilityInfo';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { switchProductNoveltyMutation } from 'graphql/Mutations/Product/Tags/switchNovelty';
import { switchProductDestockMutation } from 'graphql/Mutations/Product/Tags/switchDestock';
import { switchSupplyExceptionMutation } from 'graphql/Mutations/Product/Tags/switchSupplyException';
import { switchForceOnMarketplacesMutation } from 'graphql/Mutations/Product/Tags/switchForcedOnMarketplaces';
import { switchInShopOnlyMutation } from 'graphql/Mutations/Product/Tags/switchInShopOnly';
import { ApolloErrorAlert, WarningAlert } from 'components/Common/Alerts';
import { Toggle } from 'components/Common/Toggle';
import { Product } from 'types/Product';
import { Menu, Transition } from '@headlessui/react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import {
  TAG_DESCTOCK_ID,
  TAG_FORCE_MARKETPLACE_ID,
  TAG_NEW_ID,
  TAG_SHOP_ONLY_ID,
  TAG_SUPPPLY_EXCEPTION_ID,
} from 'global';
import useNotification from 'hooks/useNotification';
import TagHistory from 'components/Product/TagHistory';
import Card from 'components/Common/Card';
import Tippy from '@tippyjs/react';

const Tags = () => {
  const { t } = useTranslation();
  const params = useParams();

  const { setNotification } = useNotification();
  const [key, setKey] = useState<number>(0);

  const { loading, error, data } = useQuery(productTagsQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  const [switchProductActivation, { loading: loadingSwitchNovelty, error: noveltyError }] =
    useMutation(switchProductNoveltyMutation);

  const [switchProductDestock, { loading: loadingSwitchProductDestock, error: destockError }] =
    useMutation(switchProductDestockMutation);

  const [switchSupplyException, { loading: loadingSwitchSupplyException, error: supplyError }] =
    useMutation(switchSupplyExceptionMutation);

  const [
    switchSwitchForceOnMarketplaces,
    { loading: loadingSwitchForceOnMarketplaces, error: marketPlacesError },
  ] = useMutation(switchForceOnMarketplacesMutation);

  const [switchInShopOnly, { loading: loadingSwitchInShopOnly, error: inShopError }] =
    useMutation(switchInShopOnlyMutation);

  const onChangeNovelty = () => {
    switchProductActivation({
      variables: {
        id: params.productId,
        tag_id: TAG_NEW_ID,
      },
      onCompleted: (data) => {
        const message = data.switchProductNovelty.isNovelty
          ? t('product.tags.novelty.activate')
          : t('product.tags.novelty.deactivate');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const onChangeDestock = () => {
    switchProductDestock({
      variables: {
        id: params.productId,
        isDestock: !product.to_destock,
        tag_id: TAG_DESCTOCK_ID,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productTagsQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: visibilityInformationQuery,
          variables: {
            id: params.productId,
          },
        },
      ],

      onCompleted: (data) => {
        const message = data.switchProductDestock.to_destock
          ? t('product.tags.destock.activate')
          : t('product.tags.destock.deactivate');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const onSwitchSupplyException = () => {
    switchSupplyException({
      variables: {
        id: params.productId,
        supply_exception: Boolean(!product.supply_exception),
        tag_id: TAG_SUPPPLY_EXCEPTION_ID,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productTagsQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: (data) => {
        const message = data.switchSupplyException.supply_exception
          ? t('product.tags.supply-exception.activate')
          : t('product.tags.supply-exception.deactivate');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const onSwitchForceOnMarketplaces = () => {
    switchSwitchForceOnMarketplaces({
      variables: {
        id: params.productId,
        force_on_marketplaces: Boolean(!product.force_on_marketplaces),
        tag_id: TAG_FORCE_MARKETPLACE_ID,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productTagsQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: (data) => {
        const message = data.switchForceOnMarketplaces.force_on_marketplaces
          ? t('product.tags.force_on_marketplaces.activate')
          : t('product.tags.force_on_marketplaces.deactivate');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const onSwitchInShopOnly = () => {
    switchInShopOnly({
      variables: {
        id: params.productId,
        in_shop_only: Boolean(!product.in_shop_only),
        tag_id: TAG_SHOP_ONLY_ID,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productTagsQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: (data) => {
        const message = data.switchInShopOnly.in_shop_only
          ? t('product.tags.in-shop-only.activate')
          : t('product.tags.in-shop-only.deactivate');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const refresh = useCallback(() => {
    setKey((key) => key + 1);
  }, [setKey]);

  useEffect(() => {
    refresh();
  }, [data, refresh]);

  const product: Product = data?.product;
  // const productError = !loading && (error || !product);

  return (
    <Card title={t('product.tags.title')}>
      <div className="text-dark">
        <div>
          {[error, noveltyError, destockError, supplyError, marketPlacesError, inShopError].map(
            (err, index) =>
              err && (
                <div key={index} className="mb-4">
                  <ApolloErrorAlert error={err} />
                </div>
              )
          )}
          <div className="space-y-5 p-2">
            {loading ? (
              [...Array(5)].map((_, i) => <div key={i} className="skeleton h-10 w-full" />)
            ) : (
              <>
                <Menu as="div" className="relative ">
                  <Toggle
                    key={key}
                    enabled={product?.isNovelty}
                    icon={
                      <Tippy content={t('global.history')}>
                        <Menu.Button className="justify-center text-gray-400 hover:text-dark">
                          <BookOpenIcon className="w-5" />
                        </Menu.Button>
                      </Tippy>
                    }
                    label={t('product.tags.novelty.label')}
                    description={t('product.tags.novelty.description')}
                    onChange={onChangeNovelty}
                    disabled={loadingSwitchNovelty || !product?.active}
                  />
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute left-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <TagHistory tagId={TAG_NEW_ID} />
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Menu as="div" className="relative">
                  <Toggle
                    enabled={product?.to_destock}
                    label={t('product.tags.destock.label')}
                    icon={
                      <Tippy content={t('global.history')}>
                        <Menu.Button className="justify-center text-gray-400 hover:text-dark">
                          <BookOpenIcon className="w-5" />
                        </Menu.Button>
                      </Tippy>
                    }
                    description={t('product.tags.destock.description')}
                    onChange={onChangeDestock}
                    disabled={loadingSwitchProductDestock || !product?.active}
                  />
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute left-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <TagHistory tagId={TAG_DESCTOCK_ID} />
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Menu as="div" className="relative">
                  <Toggle
                    enabled={product?.supply_exception}
                    label={t('product.tags.supply-exception.label')}
                    icon={
                      <Tippy content={t('global.history')}>
                        <Menu.Button className="justify-center text-gray-400 hover:text-dark">
                          <BookOpenIcon className="w-5" />
                        </Menu.Button>
                      </Tippy>
                    }
                    description={t('product.tags.supply-exception.description')}
                    onChange={onSwitchSupplyException}
                    disabled={loadingSwitchSupplyException || !product?.active}
                  />
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute left-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <TagHistory tagId={TAG_SUPPPLY_EXCEPTION_ID} />
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Menu as="div" className="relative">
                  <Toggle
                    enabled={product?.force_on_marketplaces}
                    label={t('product.tags.force_on_marketplaces.label')}
                    icon={
                      <Tippy content={t('global.history')}>
                        <Menu.Button className="justify-center text-gray-400 hover:text-dark">
                          <BookOpenIcon className="w-5" />
                        </Menu.Button>
                      </Tippy>
                    }
                    description={t('product.tags.force_on_marketplaces.description')}
                    onChange={onSwitchForceOnMarketplaces}
                    disabled={loadingSwitchForceOnMarketplaces || !product?.active}
                  />
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute left-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <TagHistory tagId={TAG_FORCE_MARKETPLACE_ID} />
                    </Menu.Items>
                  </Transition>
                </Menu>
                <Menu as="div" className="relative">
                  <Toggle
                    enabled={product?.in_shop_only}
                    icon={
                      <Tippy content={t('global.history')}>
                        <Menu.Button className="justify-center text-gray-400 hover:text-dark">
                          <BookOpenIcon className="w-5" />
                        </Menu.Button>
                      </Tippy>
                    }
                    label={t('product.tags.in-shop-only.label')}
                    description={t('product.tags.in-shop-only.description')}
                    onChange={onSwitchInShopOnly}
                    disabled={loadingSwitchInShopOnly || !product?.active}
                  />
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95">
                    <Menu.Items className="absolute left-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <TagHistory tagId={TAG_SHOP_ONLY_ID} />
                    </Menu.Items>
                  </Transition>
                </Menu>
                {!product?.active && (
                  <WarningAlert>
                    {t('product.tags.product-must-be-active-for-any-modification')}
                  </WarningAlert>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};
export default Tags;
