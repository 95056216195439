import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMutation, useQuery } from '@apollo/client';
import { countriesQuery } from 'graphql/Queries/countries';
import { supplierCoordinatesQuery } from 'graphql/Queries/Supplier/GeneralInformations/coordinates';
import { updateCoordinatesMutation } from 'graphql/Mutations/Supplier/GeneralInformations/updateCoordinates';
import useNotification from 'hooks/useNotification';
import { InputTextControl } from 'components/Common/InputTextControl';
import { SelectControl } from 'components/Common/SelectControl';
import SlideOver from 'components/Common/SlideOver';
import { Country } from 'types/Global';
import { Supplier } from 'types/Supplier';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  supplier?: Supplier;
};

const Edit = ({ open, setOpen, supplier }: Props) => {
  const params = useParams();
  const { setNotification } = useNotification();

  const [
    updateCoordinates,
    { loading: mutationLoading, error: mutationError, reset: resetMutation },
  ] = useMutation(updateCoordinatesMutation);

  const {
    data: dataCountries,
    loading: loadingCountries,
    error: errorCountries,
  } = useQuery(countriesQuery);

  const countries = dataCountries?.countries?.map((country: Country) => {
    return {
      value: country.id,
      label: country.name,
    };
  });

  const schema = yup.object().shape({
    billing_name: yup.string().required(t('form.required')),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty },
  } = useForm({ resolver: yupResolver<FieldValues>(schema) });

  useEffect(() => {
    if (!supplier) return;
    reset({
      billing_name: supplier.billing_name ?? '',
      street: supplier.street ?? '',
      complementary_address: supplier.complementary_address ?? '',
      city: supplier.city ?? '',
      zipcode: supplier.zipcode ?? '',
      vat_number: supplier.vat_number ?? '',
      country: supplier.country
        ? {
            value: supplier.country.id,
            label: supplier.country.name,
          }
        : null,
    });
  }, [supplier, reset]);

  const onCancel = () => {
    setOpen(false);
    resetMutation();
  };

  const onSubmit = handleSubmit((data) => {
    updateCoordinates({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: supplierCoordinatesQuery,
          variables: { id: params.supplierId },
        },
      ],
      variables: {
        id: params.supplierId,
        billing_name: data.billing_name,
        street: data.street,
        complementary_address: data.complementary_address,
        city: data.city,
        zipcode: data.zipcode,
        vat_number: data.vat_number,
        country_id: data.country ? +data.country.value : null,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.coordinates.success'),
          type: 'success',
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('supplier.coordinates.edit')}
      buttonText={t('global.actions.save')}
      onCancel={onCancel}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={mutationLoading}
      error={mutationError}>
      <div className="space-y-6">
        <InputTextControl
          control={control}
          name="billing_name"
          label={t('supplier.coordinates.billing_name')}
          isRequired
        />
        <InputTextControl
          control={control}
          name="vat_number"
          label={t('supplier.coordinates.vat_number')}
        />
        <InputTextControl
          control={control}
          name="street"
          label={t('supplier.coordinates.street')}
        />
        <InputTextControl
          control={control}
          name="complementary_address"
          label={t('supplier.coordinates.complementary_address')}
        />
        <div className="grid grid-cols-3 gap-6">
          <InputTextControl
            control={control}
            name="zipcode"
            label={t('supplier.coordinates.zipcode')}
          />
          <div className="col-span-2">
            <InputTextControl
              control={control}
              name="city"
              label={t('supplier.coordinates.city')}
            />
          </div>
        </div>
        <SelectControl
          control={control}
          name="country"
          label={t('supplier.coordinates.country')}
          options={countries}
          loadingOptions={loadingCountries}
          apolloError={errorCountries}
        />
      </div>
    </SlideOver>
  );
};

export default Edit;
