import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';
import { LazyQueryExecFunction, OperationVariables } from '@apollo/client';
import { useSearchContextType } from '../../Contexts/SearchContext';
import StatusFilter from './FilterSection/StatusFilter';
import NoveltyFilter from './FilterSection/NoveltyFilter';
import SupplierFilter from './FilterSection/SupplierFilter';
import ColorFilter from './FilterSection/ColorFilter';
import MaterialFilter from './FilterSection/MaterialFilter';
import TaxonomyFilter from './FilterSection/TaxonomyFilter';
import StyleFilter from './FilterSection/StyleFilter';
import PriceRangeFilter from './FilterSection/PriceRangeFilter';
import BrandFilter from './FilterSection/BrandFilter';
import MadeInFilter from './FilterSection/MadeInFilter';
import SlideOver from 'components/Common/SlideOver';
import useValidatePrice from '../Hooks/UseValidateInput';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  methods: UseFormReturn<FieldValues>;
  loading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterBuckets: any;
  getProduct: LazyQueryExecFunction<unknown, OperationVariables>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  children: ReactElement;
};

const Index = ({
  open,
  setOpen,
  methods,
  filterBuckets,
  getProduct,
  page,
  setPage,
  children,
}: Props) => {
  const { t } = useTranslation();
  const control = methods.control;
  const register = methods.register;
  const { searchProduct } = useSearchContextType();

  const validation = useValidatePrice();

  const canBeSupplierFiltered = () => {
    return (
      filterBuckets === null ||
      (filterBuckets &&
        filterBuckets.supplier &&
        filterBuckets.supplier.buckets &&
        filterBuckets.supplier.buckets.length)
    );
  };

  const canBeColorFiltered = () => {
    return (
      filterBuckets === null ||
      (filterBuckets &&
        filterBuckets.color &&
        filterBuckets.color.buckets &&
        filterBuckets.color.buckets.length)
    );
  };

  const canBeBrandFiltered = () => {
    return (
      filterBuckets === null ||
      (filterBuckets &&
        filterBuckets.branding &&
        filterBuckets.branding.buckets &&
        filterBuckets.branding.buckets.length)
    );
  };

  const canBeMadeInFiltered = () => {
    return (
      filterBuckets === null ||
      (filterBuckets &&
        filterBuckets.made_in &&
        filterBuckets.made_in.buckets &&
        filterBuckets.made_in.buckets.length)
    );
  };

  const canBeMaterialFiltered = () => {
    return (
      filterBuckets === null ||
      (filterBuckets &&
        filterBuckets.materials &&
        filterBuckets.materials.buckets &&
        filterBuckets.materials.buckets.length)
    );
  };

  const canBeTaxonomyFitlered = () => {
    return (
      filterBuckets === null ||
      (filterBuckets &&
        filterBuckets.type &&
        filterBuckets.type.buckets &&
        (filterBuckets.type.buckets.length ||
          filterBuckets.universe.buckets.length ||
          filterBuckets.category.buckets.length))
    );
  };

  const canBeStyleFiltered = () => {
    return (
      filterBuckets === null ||
      (filterBuckets &&
        filterBuckets.style &&
        filterBuckets.style.buckets &&
        filterBuckets.style.buckets.length)
    );
  };

  const onSubmit = methods.handleSubmit(() => {
    if (
      (methods.getValues('min_price') &&
        !validation.validateMinPrice(methods.getValues('min_price'))) ||
      (methods.getValues('max_price') &&
        !validation.validateMaxPrice(
          methods.getValues('min_price'),
          methods.getValues('max_price')
        ))
    ) {
      if (methods.getValues('min_price') < 0) {
        methods.setValue('min_price', 0);
      }
    }
    if (page === 1) {
      getProduct({
        variables: {
          title: searchProduct,
          filter: Object.entries(methods.getValues()),
          page: 1,
        },
      });
    } else {
      setPage(1);
    }
    setOpen(false);
  });

  const clearFilters = () => {
    methods.reset();
    onSubmit();
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('products.filters.title')}
      buttonText={t('global.actions.validate')}
      onSubmit={onSubmit}
      onCancel={clearFilters}>
      <FormProvider {...methods}>
        <div className={'transition-[margin] duration-300 '}>
          {children}
          <div className="px-4">
            {
              <StatusFilter
                register={register}
                activityFilterBucket={
                  filterBuckets && filterBuckets.active && filterBuckets.active.buckets
                    ? filterBuckets.active.buckets
                    : []
                }
                destockFilterBucket={
                  filterBuckets &&
                  filterBuckets.is_to_destock &&
                  filterBuckets.is_to_destock.buckets
                    ? filterBuckets.is_to_destock.buckets
                    : []
                }
                exceptionFilterBucket={
                  filterBuckets &&
                  filterBuckets.supply_exception &&
                  filterBuckets.supply_exception.buckets
                    ? filterBuckets.supply_exception.buckets
                    : []
                }
              />
            }
            <NoveltyFilter
              register={register}
              noveltyFilterBucket={filterBuckets ? filterBuckets.is_novelty.buckets : []}
            />

            {canBeSupplierFiltered() ? (
              <SupplierFilter
                control={control}
                supplierFilterBucket={filterBuckets ? filterBuckets.supplier.buckets : []}
              />
            ) : (
              ''
            )}
            {canBeColorFiltered() ? (
              <ColorFilter
                control={control}
                colorFilterBucket={filterBuckets ? filterBuckets.color.buckets : []}
              />
            ) : (
              ''
            )}
            {canBeMaterialFiltered() ? (
              <MaterialFilter
                control={control}
                materialFilterBucket={filterBuckets ? filterBuckets.materials.buckets : []}
              />
            ) : (
              ''
            )}
            {canBeTaxonomyFitlered() ? (
              <TaxonomyFilter
                control={control}
                typeFilterBucket={filterBuckets ? filterBuckets.type.buckets : []}
                categoryFilterBucket={filterBuckets ? filterBuckets.category.buckets : []}
                universeFilterBucket={filterBuckets ? filterBuckets.universe.buckets : []}
              />
            ) : (
              ''
            )}
            {canBeStyleFiltered() ? (
              <StyleFilter
                control={control}
                styleFilterBucket={filterBuckets ? filterBuckets.style.buckets : []}
              />
            ) : (
              ''
            )}
            {canBeBrandFiltered() ? (
              <BrandFilter
                control={control}
                brandFilterBucket={filterBuckets ? filterBuckets.branding.buckets : []}
              />
            ) : (
              ''
            )}
            {canBeMadeInFiltered() ? (
              <MadeInFilter
                control={control}
                madeInFilterBucket={filterBuckets ? filterBuckets.made_in.buckets : []}
              />
            ) : (
              ''
            )}
            <PriceRangeFilter register={register} />
          </div>
        </div>
      </FormProvider>
    </SlideOver>
  );
};

export default Index;
