import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react/headless';

const Header = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="p-4">
        <div className="flex items-center">
          <h2 className="text-xl font-medium text-blue-gray-900">
            {t('settings.suppliers.purchase-infos.title')}
          </h2>
          <Tippy
            render={(attrs) => (
              <div className="p-3 bg-black rounded-md w-80" {...attrs}>
                <p className="text-white font-medium">
                  {t('settings.suppliers.purchase-infos.helper')}
                </p>
              </div>
            )}>
            <InformationCircleIcon className="ml-2 h-5 w-5 text-gray-300 hover:text-gray-600" />
          </Tippy>
        </div>
        <p className="mt-1 text-sm text-blue-gray-500">
          {t('settings.suppliers.purchase-infos.subtitle')}
        </p>
      </div>
    </>
  );
};

export default Header;
