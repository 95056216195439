import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIncoterm from './DeleteIncoterm';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { InformationAlert } from 'components/Common/Alerts';
import { incotermsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/incoterms';
import { createIncotermMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/createIncoterm';
import { updateIncotermMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updateIncoterm';
import { updateIncotermStatusMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updateIncotermStatus';
import SelectCreatable from 'components/Common/SelectCreatable';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const Incoterms = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditIncoterm, setShowEditIncoterm] = useState(false);
  const [showNewIncoterm, setShowNewIncoterm] = useState(false);
  const [allIncoterms, setAllIncoterms] = useState([]);
  const [incoterm, setIncoterm] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [activationChanging, setActivationChanging] = useState(false);

  const [getAllIncoterms, { data, loading: loadingIncoterms }] = useLazyQuery(incotermsQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setAllIncoterms(
        data.incoterms?.map((incoterm) => {
          return {
            label: incoterm.label,
            value: incoterm,
            key: incoterm.id,
          };
        })
      );
    }
  }, [data]);

  const [createIncoterm, { loading: createIncotermLoading }] = useMutation(createIncotermMutation);

  const [updateIncoterm, { loading: updateIncotermLoading }] = useMutation(updateIncotermMutation);

  const [updateIncotermStatus, { loading: updateIncotermStatusLoading }] = useMutation(
    updateIncotermStatusMutation
  );

  const onChangeIncoterm = (e) => {
    setShowEditIncoterm(true);
    setShowNewIncoterm(false);
    setIncoterm(e.value);
    reset({
      label: e.value.label,
    });
  };

  const onCreateIncoterm = (value) => {
    setShowEditIncoterm(false);
    setShowNewIncoterm(true);
    setIncoterm({
      value,
      label: value,
    });
    reset({
      label: value,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1).max(5),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
    },
  });

  const onCancel = () => {
    setShowNewIncoterm(false);
    setIncoterm(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewIncoterm) {
      createIncoterm({
        refetchQueries: [
          {
            query: incotermsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.createIncoterm;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.incoterms.created'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setIncoterm(data);
          setShowEditIncoterm(true);
          setShowNewIncoterm(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updateIncoterm({
        refetchQueries: [
          {
            query: incotermsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          id: incoterm.id,
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.updateIncoterm;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.incoterms.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setIncoterm(data);
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const resetAfterDelete = () => {
    setIncoterm(null);
    setShowEditIncoterm(false);
    setShowNewIncoterm(false);
  };

  const changeActivation = (value) => {
    setActivationChanging(true);
    let message = '';
    if (value) {
      message = t('settings.suppliers.purchase-infos.incoterms.activate');
    } else {
      message = t('settings.suppliers.purchase-infos.incoterms.deactivate');
    }
    updateIncotermStatus({
      refetchQueries: [
        {
          query: incotermsQuery,
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: incoterm.id,
        active: value,
      },
      onCompleted: (dataReturn) => {
        setActivationChanging(false);
        let data = dataReturn.updateIncoterm;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
        });
        setIncoterm(data);
      },
      onError: (error) => {
        setActivationChanging(false);
        setError(error);
      },
    });
  };

  return (
    <>
      <Card>
        <DeleteIncoterm
          onCloseModal={(loading) => {
            if (!loading) {
              setDisplayModal(false);
            }
          }}
          isOpen={displayModal}
          incoterm={incoterm}
          reset={resetAfterDelete}
        />
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.suppliers.purchase-infos.incoterms.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.suppliers.purchase-infos.incoterms.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.suppliers.purchase-infos.incoterms.list')}
          </label>
          <SelectCreatable
            loadingOptions={loadingIncoterms}
            options={allIncoterms}
            onCreateOption={onCreateIncoterm}
            onChange={onChangeIncoterm}
            value={incoterm}
            onFocus={() => {
              if (!allIncoterms || allIncoterms.length === 0) {
                getAllIncoterms();
              }
            }}
          />
        </div>
        {(showEditIncoterm || showNewIncoterm) && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="p-4">
                <label className="text-sm font-medium text-gray-700">
                  {t('settings.suppliers.purchase-infos.designation')}
                </label>
                <div className="relative">
                  <input
                    type="text"
                    className={classNames(
                      errors.label
                        ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                        : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                      'mt-1 block h-9 w-full flex-1 rounded'
                    )}
                    {...register('label')}
                  />
                  {errors.label && (
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                    </div>
                  )}
                </div>
                {errors.label && (
                  <p className="mt-2 text-sm text-red-700">
                    {t('settings.suppliers.purchase-infos.incoterms.error')}
                  </p>
                )}
                {incoterm.used && (
                  <div className="mt-4">
                    <InformationAlert>
                      {t('settings.suppliers.purchase-infos.incoterms.used')}
                    </InformationAlert>
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                {showEditIncoterm && (
                  <>
                    <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                      <Button
                        type="button"
                        theme="outlineDanger"
                        onClick={() => setDisplayModal(true)}
                        disabled={updateIncotermLoading || incoterm.used}>
                        {t('global.actions.delete')}
                      </Button>
                      {incoterm.active ? (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          disabled={updateIncotermLoading || updateIncotermStatusLoading}
                          loading={activationChanging}
                          onClick={() => changeActivation(false)}>
                          {t('global.actions.deactivate')}
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          disabled={updateIncotermLoading || updateIncotermStatusLoading}
                          loading={activationChanging}
                          onClick={() => changeActivation(true)}>
                          {t('global.actions.activate')}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        theme="primary"
                        disabled={updateIncotermLoading || !isDirty}
                        loading={updateIncotermLoading}>
                        {t('global.actions.save')}
                      </Button>
                    </div>
                  </>
                )}
                {showNewIncoterm && (
                  <>
                    <Button type="button" theme="transparent" onClick={onCancel}>
                      {t('global.actions.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      theme="primary"
                      loading={createIncotermLoading}
                      disabled={createIncotermLoading}>
                      {t('global.actions.add')}
                    </Button>
                  </>
                )}
              </div>
            </form>
          </>
        )}
      </Card>
    </>
  );
};

export default Incoterms;
