import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { InformationAlert } from 'components/Common/Alerts';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { productTagLogByProductAndTagQuery } from 'graphql/Queries/Product/productTagLog';
import { ID } from 'types/Global';
import { ProductTagLog } from 'types/Product';
import Badge from 'components/Common/Badge';

type TagHistoryProps = {
  tagId: ID;
};

const TagHistory = ({ tagId }: TagHistoryProps) => {
  const { t } = useTranslation();
  const params = useParams();

  const { loading, data } = useQuery(productTagLogByProductAndTagQuery, {
    fetchPolicy: 'network-only',
    variables: {
      product_id: params.productId,
      tag_id: tagId,
    },
  });

  if (data?.productTagLogByProductAndTag?.length < 1) {
    return (
      <div className="m-2">
        <InformationAlert>
          {t('product.purchase-information.informations.no-history-available')}
        </InformationAlert>
      </div>
    );
  }

  return (
    <>
      <table className="min-w-full table-fixed divide-y divide-gray-300">
        <thead className="sticky top-0 bg-white">
          <tr>
            <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-dark">
              {t('product.tags.history.value')}
            </th>
            <th scope="col" className="py-3.5 pr-3 text-left text-sm font-semibold text-dark">
              {t('product.tags.history.user')}
            </th>
            <th scope="col" className="py-3.5 pr-4 text-right text-sm font-semibold text-dark">
              {t('product.tags.history.modified-at')}
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 ">
          {loading ? (
            <tr className="bg-white">
              <td className="max-w-[210px] truncate whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-secondary">
                <div className="skeleton h-4 w-10"></div>
              </td>
              <td className="max-w-[200px] truncate whitespace-nowrap py-3.5 pr-3 text-left text-sm text-secondary">
                <div className="skeleton w-30 h-4"></div>
              </td>
              <td className="flex justify-end whitespace-nowrap py-3.5 pr-4">
                <div className="skeleton h-4 w-36"></div>
              </td>
            </tr>
          ) : (
            <>
              {data.productTagLogByProductAndTag.map((log: ProductTagLog) => {
                return (
                  <tr key={log.id}>
                    <td className="max-w-[210px] truncate whitespace-nowrap py-3.5 pl-4 pr-3 text-sm text-secondary">
                      {log.tag_value ? (
                        <Badge type="success">{t('global.active')}</Badge>
                      ) : (
                        <Badge type="danger">{t('global.inactive')}</Badge>
                      )}
                    </td>
                    <td className="max-w-[200px] truncate whitespace-nowrap py-3.5 pr-3 text-left text-sm text-secondary">
                      {log.user?.lastname} {log.user?.firstname}
                    </td>
                    <td className="whitespace-nowrap py-3.5 pr-4 text-right text-sm text-secondary">
                      {moment(log.created_at).format('Do MMMM YYYY HH:mm')}
                    </td>
                  </tr>
                );
              })}
            </>
          )}
        </tbody>
      </table>
    </>
  );
};

export default TagHistory;
