import { gql } from 'graphql.macro';

export const updateIncotermMutation = gql`
  mutation UpdateIncoterm($id: ID!, $label: String) {
    updateIncoterm(id: $id, label: $label) {
      id
      label
      active
      used
    }
  }
`;
