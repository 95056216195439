import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { countriesQuery } from 'graphql/Queries/countries';
import { updateProductionSiteMutation } from 'graphql/Mutations/Supplier/ProductionSites/updateProductionSite';
import { supplierProductionSitesQuery } from 'graphql/Queries/Supplier/ProductionSites/supplierProductionSites';
import { FieldValues, useForm } from 'react-hook-form';
import { InputTextControl } from 'components/Common/InputTextControl';
import { SelectControl } from 'components/Common/SelectControl';
import { ProductionSite } from 'types/Supplier';
import { yupResolver } from '@hookform/resolvers/yup';
import { Country } from 'types/Global';
import * as yup from 'yup';
import SlideOver from 'components/Common/SlideOver';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  site: ProductionSite | null;
};

const UpdateProductionSite = ({ open, setOpen, site }: Props) => {
  const { setNotification } = useNotification();
  const params = useParams();

  const {
    data: dataCountries,
    loading: loadingCountries,
    error: errorCountries,
  } = useQuery(countriesQuery);

  const [
    editProductionSite,
    { loading: mutationLoading, error: mutationError, reset: resetMutation },
  ] = useMutation(updateProductionSiteMutation);

  const countries = dataCountries?.countries?.map((country: Country) => {
    return {
      value: country.id,
      label: country.name,
    };
  });

  const schema = yup.object().shape({
    name: yup.string().required(t('form.required')),
    country: yup.object().required(t('form.required')),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    reset({
      name: site?.name ?? '',
      street: site?.street ?? '',
      complementary_address: site?.complementary_address ?? '',
      zipcode: site?.zipcode ?? '',
      city: site?.city ?? '',
      country: site?.country
        ? {
            value: site?.country.id,
            label: site?.country.name,
          }
        : null,
    });
  }, [reset, site]);

  const close = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    if (!site) return;

    editProductionSite({
      variables: {
        id: site.id,
        name: data.name,
        street: data.street,
        complementaryAddress: data.complementary_address,
        city: data.city,
        zipcode: data.zipcode,
        countryId: +data.country?.value,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: supplierProductionSitesQuery,
          variables: { id: params.supplierId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.production-sites.update-success'),
          type: 'success',
        });
        setOpen(false);
        resetMutation();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('supplier.production-sites.edit-production-site')}
      buttonText={t('global.actions.save')}
      onCancel={close}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={mutationLoading}
      error={mutationError}>
      <div className="flex flex-col space-y-8">
        <InputTextControl
          control={control}
          name="name"
          label={t('supplier.production-sites.production-site-name')}
          isRequired
        />
        <InputTextControl
          control={control}
          name="street"
          label={t('supplier.production-sites.address')}
        />
        <InputTextControl
          control={control}
          name="complementary_address"
          label={t('supplier.production-sites.complementary-address')}
        />
        <div className="grid grid-cols-3 space-x-6">
          <div className="col-span-1">
            <InputTextControl
              control={control}
              name="zipcode"
              label={t('supplier.production-sites.zipcode')}
            />
          </div>
          <div className="col-span-2">
            <InputTextControl
              control={control}
              name="city"
              label={t('supplier.production-sites.city')}
            />
          </div>
        </div>
        <SelectControl
          control={control}
          name="country"
          label={t('supplier.coordinates.country')}
          options={countries}
          loadingOptions={loadingCountries}
          apolloError={errorCountries}
          isRequired
        />
      </div>
    </SlideOver>
  );
};

export default UpdateProductionSite;
