import React, { useState } from 'react';
import { t } from 'i18next';
import { SupplierContact } from 'types/Supplier';
import {
  PencilSquareIcon,
  EnvelopeIcon,
  TrashIcon,
  DevicePhoneMobileIcon,
  PhoneIcon,
} from '@heroicons/react/24/outline';
import { getInitials } from 'utils/string';
import { useGlobalContextType } from 'components/Contexts/GlobalContext';
import Badge from 'components/Common/Badge';
import Tippy from '@tippyjs/react';
import UpdateContact from './UpdateContact';
import clsx from 'clsx';
import DeleteContact from './DeleteContact';

type Props = {
  supplierContacts: SupplierContact[] | undefined;
};

type SelectedContact = {
  selectedContact: SupplierContact;
};

const AllContacts = ({ supplierContacts }: Props) => {
  const { sidebarOpen } = useGlobalContextType();

  const [openSlide, setOpenSlide] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [contact, setContact] = useState<SupplierContact | null>(null);

  const selectContactToUpdate = (contact: SupplierContact) => {
    setContact(contact);
    setOpenSlide(true);
  };

  const selectContactToDelete = (contact: SupplierContact) => {
    setContact(contact);
    setOpenModal(true);
  };

  const EditButton = ({ selectedContact }: SelectedContact) => {
    return (
      <div className="flex w-0 flex-1">
        <button
          onClick={() => selectContactToUpdate(selectedContact)}
          className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl border border-transparent py-4 text-sm font-semibold text-gray-900">
          <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </button>
      </div>
    );
  };

  return (
    <>
      <UpdateContact setOpen={setOpenSlide} open={openSlide} contact={contact} />
      <DeleteContact setOpen={setOpenModal} open={openModal} contact={contact} />

      <ul
        role="list"
        className={clsx(
          sidebarOpen ? 'lg:grid-cols-2' : 'lg:grid-cols-2 xl:grid-cols-3',
          `grid grid-cols-1 gap-6`
        )}>
        {supplierContacts?.map((contact) => {
          return (
            <li
              key={contact.id}
              className="col-span-1 flex h-[430px] flex-col divide-y divide-gray-200 rounded bg-white text-center shadow">
              <div className="flex flex-1 flex-col space-y-8 p-8">
                <div className="scpace-x-4 flex items-center justify-start space-x-6 text-left">
                  <span
                    className={`${
                      contact.is_principal ? 'relative' : ''
                    } inline-flex h-20 w-20 items-center  justify-center rounded-full bg-light`}>
                    <span className="text-3xl font-medium leading-none text-dark">
                      {getInitials(contact.name)}
                    </span>
                    {contact.is_principal && (
                      <span className="absolute top-14">
                        <Badge type="primary">{t('supplier.contacts.is-principal')}</Badge>
                      </span>
                    )}
                  </span>
                  <div>
                    <div>{contact.name}</div>
                    <Badge type="info">{contact.type.label}</Badge>
                  </div>
                </div>
                <div className=" flex flex-col space-y-3">
                  <div className="flex items-center space-x-4">
                    <EnvelopeIcon className="h-6 w-6 text-secondary" />
                    <Tippy content={contact.email}>
                      <div className="truncate text-sm text-dark">
                        {contact.email ? contact.email : '-'}
                      </div>
                    </Tippy>
                  </div>
                  <div>
                    <div className="flex items-center space-x-4">
                      <DevicePhoneMobileIcon className="h-6 w-6 text-secondary" />
                      <Tippy content={contact.mobile}>
                        <div className="truncate text-sm text-dark">
                          {contact.mobile ? contact.mobile : '-'}
                        </div>
                      </Tippy>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center space-x-4">
                      <PhoneIcon className="h-6 w-6 text-secondary" />
                      <Tippy content={contact.phone}>
                        <div className="truncate text-sm text-dark">
                          {contact.phone ? contact.phone : '-'}
                        </div>
                      </Tippy>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col space-y-2 text-left">
                  <div className="truncate text-sm font-semibold text-dark">
                    {t('supplier.contacts.more-information')}
                  </div>
                  {contact.comment ? (
                    <Tippy content={contact.comment}>
                      <div className="line-clamp-2 w-fit text-sm text-dark">{contact.comment}</div>
                    </Tippy>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  {contact.is_principal ? (
                    <EditButton selectedContact={contact} />
                  ) : (
                    <>
                      <EditButton selectedContact={contact} />
                      <div className="-ml-px flex w-0 flex-1">
                        <button
                          onClick={() => selectContactToDelete(contact)}
                          className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br border border-transparent py-4 text-sm font-semibold text-gray-900">
                          <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default AllContacts;
