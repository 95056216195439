import { gql } from 'graphql.macro';

export const updateFurnitureAssemblyOptionMutation = gql`
  mutation updateFurnitureAssemblyOption($id: ID!, $label: String) {
    updateFurnitureAssemblyOption(id: $id, label: $label) {
      id
      active
      isUsed
      currentTranslation {
        text
      }
    }
  }
`;
