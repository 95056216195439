import { gql } from 'graphql.macro';

export const productFeaturesQuery = gql`
  query productFeatures($id: ID!) {
    product(id: $id) {
      id
      features {
        id
        position
        label {
          id
          text
        }
        value {
          id
          text
        }
        unit {
          id
          text
        }
      }
      product_weight
    }
  }
`;
