import { gql } from 'graphql.macro';

export const updateProductOfferMutation = gql`
  mutation UpdateProductOffer(
    $product_id: Int!
    $country_id: Int!
    $high_price: Float!
    $discount_price: Float
    $markdown: Int!
    $start_date: String
    $end_date: String
  ) {
    updateProductOffer(
      product_id: $product_id
      country_id: $country_id
      high_price: $high_price
      discount_price: $discount_price
      markdown: $markdown
      start_date: $start_date
      end_date: $end_date
    ) {
      id
    }
  }
`;
