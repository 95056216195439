import React from 'react';
import { useTranslation } from 'react-i18next';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import { ApolloError } from '@apollo/client/errors';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import Creatable from 'react-select/creatable';
import { PropsValue, OptionsOrGroups, GroupBase } from 'react-select';
import useApolloErrorMessage from 'hooks/useApolloErrorMessage';
import { OptionProps } from 'types/Global';

type SelectCreatableProps = {
  value: PropsValue<OptionProps>;
  options: OptionsOrGroups<OptionProps, GroupBase<OptionProps>>;
  onCreateOption: (value: string) => void;
  onChange: (newValue: OptionProps | null) => void;
  label?: string;
  placeholder?: string;
  loadingOptions?: boolean;
  apolloError?: ApolloError;
  isInactive?: boolean;
  inactiveMessage?: string;
  isClearable?: boolean;
  onFocus?: () => void;
};

const SelectCreatable = ({
  value,
  options,
  onCreateOption,
  onChange,
  label,
  placeholder,
  loadingOptions,
  apolloError,
  isInactive,
  inactiveMessage,
  isClearable,
  onFocus,
}: SelectCreatableProps) => {
  const { t } = useTranslation();
  const errorMessage = useApolloErrorMessage({ apolloError });
  const getInactiveMessage = () => {
    return inactiveMessage ? inactiveMessage : t('global.inactive-option');
  };

  const getPlaceholder = () => {
    if (loadingOptions) {
      return t('global.form.loading');
    }
    if (options.length === 0) {
      return t('global.form.load');
    }
    return placeholder ? placeholder : t('global.form.select');
  };

  return (
    <>
      <label className="text-sm font-medium text-dark">{label}</label>
      <div className="relative">
        <Creatable
          className="mt-2 w-full"
          styles={isInactive || apolloError ? errorCustomStyle : customStyle}
          value={value}
          onChange={onChange}
          options={options}
          onCreateOption={onCreateOption}
          onFocus={onFocus}
          placeholder={getPlaceholder()}
          noOptionsMessage={() => t('global.form.no-option')}
          formatCreateLabel={(inputText) => {
            return `${t('global.actions.create')} : "${inputText}"`;
          }}
          isClearable={isClearable}
          isDisabled={loadingOptions}
          isLoading={loadingOptions}
          theme={theme}
        />
        {(isInactive || apolloError) && (
          <div className="pointer-events-none absolute right-12 top-2 flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
        {(isInactive || apolloError) && (
          <p className="mt-2 text-sm text-red-700">
            {isInactive ? getInactiveMessage() : errorMessage}
          </p>
        )}
      </div>
    </>
  );
};
export default SelectCreatable;
