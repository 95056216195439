import { gql } from 'graphql.macro';

export const ecomobilierQuery = gql`
  query ecomobilier($id: ID!) {
    product(id: $id) {
      id
      totalChildrenEcomobilierAmount
      constituents {
        id
      }
      ecoMobilier {
        enable
        category {
          id
          code
        }
        productType {
          id
          code
        }
        material {
          id
          code
        }
        size {
          id
          code
        }
        payed_before
        amount {
          including_taxes_value
        }
      }
    }
  }
`;
