import { gql } from 'graphql.macro';

export const ecomobilierCategoriesQuery = gql`
  query {
    ecomobilierCategories {
      id
      code
      label
      padding
    }
  }
`;
