import { gql } from 'graphql.macro';

export const familiesQuery = gql`
  query Families {
    families {
      id
      active
      currentLabelTranslation {
        text
      }
    }
  }
`;
