import React, { SetStateAction } from 'react';
import { useParams } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { supplierHeaderQuery } from 'graphql/Queries/Supplier/supplierHeader';
import { editSupplierActivationMutation } from 'graphql/Mutations/Supplier/Header/editActivation';
import useNotification from 'hooks/useNotification';
import Modal from 'components/Common/Modal';
import { NoSymbolIcon } from '@heroicons/react/20/solid';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  name?: string;
};

const DeactivateModal = ({ open, setOpen, name }: Props) => {
  const { setNotification } = useNotification();
  const params = useParams();

  const [deactivateSupplier, { loading, error, reset }] = useMutation(
    editSupplierActivationMutation
  );

  const { handleSubmit } = useForm<FieldValues>({});

  const onClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = handleSubmit(() => {
    deactivateSupplier({
      variables: {
        id: params.supplierId,
        active: false,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: supplierHeaderQuery,
          variables: {
            id: params.supplierId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.header.deactivated'),
          type: 'success',
        });
        onClose();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <Modal
        theme={'danger'}
        title={t('supplier.header.modal-title')}
        buttonText={t('global.actions.deactivate')}
        loading={loading}
        error={error}
        open={open}
        onClose={onClose}
        onSubmit={onSubmit}
        icon={<NoSymbolIcon className="h-6 w-6 text-red-600" />}>
        <div className="mt-6 flex flex-col space-y-6">
          <div className="flex flex-col gap-2">
            <span>{t('supplier.header.modal-description')}</span>
            <span className="font-semibold text-dark">{name}</span>
          </div>
          <div className="font-medium text-red-600">{t('supplier.header.modal-confirme')}</div>
        </div>
      </Modal>
    </>
  );
};

export default DeactivateModal;
