import React from 'react';
import { useQuery } from '@apollo/client';
import { Control, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ecomobilierPaddingMaterialsQuery } from 'graphql/Queries/Ecotax/ecomobilierPaddingMaterials';
import { ecoMobilierProductTypeQuery } from 'graphql/Queries/Ecotax/ecoMobilierProductType';
import { theme, customStyle, errorCustomStyle } from 'components/Style/customStyle';
import Select from 'react-select';
import DummySelect from 'components/Common/DummySelect';
import PropTypes from 'prop-types';
import { ID } from 'types/Global';

type Props = {
  control: Control;
  typeId: ID;
  hasPadding: boolean;
  defaultMaterial: MaterialType;
  handleSelect: (e: SelectType) => void;
};

type SelectType = {
  key: number;
  code: string;
  label: string;
  padding: boolean;
  value: number;
};

type MaterialsData = {
  ecomobilierPaddingMaterials?: {
    materials: MaterialType[];
  };
};

type MaterialType = {
  id: ID;
  code: string;
  label: string;
};

function MaterialSelect({
  control,
  hasPadding = false,
  typeId,
  defaultMaterial,
  handleSelect,
}: Props) {
  const { t } = useTranslation();
  const {
    loading: loadPad,
    data: dataPad,
    error: errorPad,
  } = useQuery<MaterialsData>(ecomobilierPaddingMaterialsQuery, {
    skip: !hasPadding,
  });

  const {
    loading: loadMat,
    data: dataMat,
    error: errorMat,
  } = useQuery(ecoMobilierProductTypeQuery, {
    variables: { id: parseInt(typeId as string) },
    skip: hasPadding,
  });

  if (loadPad || loadMat) {
    return <DummySelect title={t('product.ecotax.materials')} />;
  }

  const materials = hasPadding
    ? dataPad?.ecomobilierPaddingMaterials
    : dataMat?.typeMaterials?.materials;
  const materialTypes = materials?.map((material: MaterialType, index: number) => {
    return {
      label: `${material.code} - ${material.label}`,
      value: material.id,
      code: material.code,
      key: index,
    };
  });

  return (
    <>
      <div className="mt-3 sm:col-span-3">
        <label className="mb-2 block text-sm font-medium leading-6 text-dark">
          {`${t('product.ecotax.materials')}`}
          <span className="text-red-700">*</span>
        </label>
        <div className="relative mt-1">
          <Controller
            name="material"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                theme={theme}
                styles={errorMat ? errorCustomStyle : customStyle}
                isSearchable={false}
                isDisabled={loadMat}
                placeholder={loadMat ? t('global.loading') : t('global.form.select')}
                noOptionsMessage={() => t('global.no-option')}
                className="block w-full min-w-0 flex-1  rounded-none rounded-r-md border-gray-300 focus:border-blue-500 
                focus:ring-blue-500 sm:text-sm"
                options={materialTypes}
                value={materialTypes?.filter((option: SelectType) =>
                  option.code === defaultMaterial?.code ? option : ''
                )}
                onChange={(e) => {
                  handleSelect(e);
                }}
              />
            )}
          />
          <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
            {((errorPad && hasPadding) || (errorMat && !hasPadding)) && (
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            )}
          </div>
        </div>
        {((errorPad && hasPadding) || (errorMat && !hasPadding)) && (
          <p className="mt-2 text-sm text-red-700">
            {
              (errorMat && errorMat.graphQLErrors[0]?.extensions?.custom
                ? errorMat.graphQLErrors[0]?.message
                : errorMat && t('global.error-msg')) as string
            }
            {
              (errorPad && errorPad.graphQLErrors[0]?.extensions?.custom
                ? errorPad.graphQLErrors[0]?.message
                : errorPad && t('global.error-msg')) as string
            }
          </p>
        )}
      </div>
    </>
  );
}
MaterialSelect.propTypes = {
  control: PropTypes.object,
  hasPadding: PropTypes.bool.isRequired,
  typeId: PropTypes.string.isRequired,
  defaultMaterial: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    code: PropTypes.string,
  }).isRequired,
  handleSelect: PropTypes.func.isRequired,
};
export default MaterialSelect;
