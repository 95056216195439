import React, { ReactElement, ComponentPropsWithoutRef, forwardRef, useEffect, Ref } from 'react';
import { Control, Controller, useController } from 'react-hook-form';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import Tippy from '@tippyjs/react';
import { OptionProps } from 'types/Global';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

type SelectProps = {
  name: string;
  options: OptionProps[];
  symbol?: string;
};

type InputTextControlProps = {
  name: string;
  control: Control;
  type?: string;
  label?: string;
  disabledText?: string;
  icon?: ReactElement;
  select?: SelectProps;
  addOnEnd?: string;
  isLoading?: boolean;
  isRequired?: boolean;
  isFocused?: boolean;
  currencyCode?: boolean;
  disabled?: boolean;
} & ComponentPropsWithoutRef<'input'>;

export const InputTextControl = forwardRef(
  (
    {
      name,
      control,
      type = 'text',
      label,
      icon,
      select,
      addOnEnd,
      isLoading,
      isRequired,
      currencyCode,
      isFocused,
      disabledText,
      ...props
    }: InputTextControlProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const { field, formState } = useController({ name, control });
    const { onChange } = field;

    const { field: fieldSelect } = useController({
      name: select?.name ?? '',
      control,
    });
    const { onChange: onChangeSelect } = fieldSelect;

    useEffect(() => {
      if (isFocused && ref && 'current' in ref && ref.current) {
        ref.current.focus();
      }
    }, [ref, isFocused]);

    return (
      <div>
        <div className="flex items-center">
          {label && (
            <label htmlFor={name} className="mb-1 block text-sm font-medium leading-6 text-dark">
              {label}
              {isRequired && <span className="ml-1 text-red-700">*</span>}
            </label>
          )}

          {props.disabled && (
            <Tippy content={disabledText}>
              <InformationCircleIcon className="mb-2 ml-2 h-5 w-5 text-gray-300 hover:text-gray-600" />
            </Tippy>
          )}
        </div>
        {isLoading ? (
          <div className="skeleton h-8" />
        ) : (
          <>
            <div className="flex">
              <div className="relative w-full">
                {icon && (
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3 ">
                    {icon}
                  </div>
                )}
                <input
                  ref={ref}
                  type={type}
                  id={name}
                  value={field.value}
                  name={name}
                  onChange={onChange}
                  className={`
                  ${props.disabled && 'bg-gray-100'}
                  ${icon && 'pl-10'}
                  ${select && 'pr-24'}
                  ${addOnEnd ? 'rounded-l-md' : 'rounded-md'}
                  ${
                    formState?.errors[name] || (select && formState?.errors[select.name])
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500'
                  }
                  block w-full py-1.5 text-dark shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6`}
                  {...props}
                />
                {formState?.errors[name] && !select && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                  </div>
                )}
                {select && select.options.length > 0 && (
                  <div className="absolute inset-y-0 right-0 flex items-center">
                    <Controller
                      control={control}
                      name={select.name}
                      render={() => (
                        <select
                          className="h-full w-24 rounded-md border-0 bg-transparent py-0 pl-2 text-dark focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:text-sm"
                          value={fieldSelect.value}
                          onChange={onChangeSelect}>
                          <option value="0">-</option>
                          {select.options.map((option, i) => (
                            <option key={i} value={currencyCode ? option.label : option.value}>
                              {option.label}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </div>
                )}
              </div>
              {addOnEnd && (
                <span className="inline-flex items-center rounded-r-md border border-l-0 border-gray-300 px-3 text-dark sm:text-sm">
                  {addOnEnd}
                </span>
              )}
            </div>
          </>
        )}
        {formState?.errors[name] && (
          <p className="mt-2 text-sm text-red-700">{formState?.errors[name]?.message as string}</p>
        )}
        {select?.name && (
          <p className="mt-2 text-sm text-red-700">
            {formState?.errors[select.name]?.message as string}
          </p>
        )}
      </div>
    );
  }
);
InputTextControl.displayName = 'InputTextControl';
