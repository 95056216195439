import { gql } from 'graphql.macro';

export const productPurchaseCommentsQuery = gql`
  query productPurchaseComments($id: ID!) {
    product(id: $id) {
      id
      purchase_comments
    }
  }
`;
