import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { customStyle, theme } from 'components/Style/customStyle';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteModel from './DeleteModel';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { withAsyncPaginate } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import { InformationAlert } from 'components/Common/Alerts';
import { findProductModelsByOffsetQuery } from 'graphql/Queries/Product/ModelsAndCollections/findProductModelsByOffset';
import { findProductModelsByTextQuery } from 'graphql/Queries/Product/ModelsAndCollections/findProductModelsByText';
import { createModelMutation } from 'graphql/Mutations/Product/ModelsAndCollections/createModel';
import { updateModelMutation } from 'graphql/Mutations/Product/ModelsAndCollections/updateModel';
import { updateModelStatusMutation } from 'graphql/Mutations/Product/ModelsAndCollections/updateModelStatus';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const Models = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditModel, setShowEditModel] = useState(false);
  const [showNewModel, setShowNewModel] = useState(false);
  const [model, setModel] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(0);
  const [activationChanging, setActivationChanging] = useState(false);
  const [key, setKey] = useState(0);

  const [findModelsByOffset] = useLazyQuery(findProductModelsByOffsetQuery);
  const [getProductModelsByText] = useLazyQuery(findProductModelsByTextQuery);

  const [createModel, { loading: createModelLoading }] = useMutation(createModelMutation);

  const [updateModel, { loading: updateModelLoading }] = useMutation(updateModelMutation);

  const [updateModelStatus, { loading: updateModelStatusLoading }] =
    useMutation(updateModelStatusMutation);

  const onChangeModel = (value) => {
    setShowEditModel(true);
    setShowNewModel(false);
    setModel(value);
    reset({
      name: value.label,
    });
  };

  const onCreateModel = (value) => {
    setShowEditModel(false);
    setShowNewModel(true);
    setModel({
      value: value,
      label: value,
      active: true,
      isUsed: false,
    });
    reset({
      name: value,
    });
  };

  const schema = yup.object().shape({
    name: yup.string().trim().min(1),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: '',
    },
  });

  const onCancel = () => {
    setShowNewModel(false);
    setModel(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewModel) {
      createModel({
        variables: {
          name: data.name,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.createProductModel;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.models-collections-styles.models.created'),
            type: 'success',
          });
          reset({
            name: data.name,
          });
          setModel({
            value: data.id,
            label: data.name,
            active: data.active,
            isUsed: data.isUsed,
          });
          setShowEditModel(true);
          setShowNewModel(false);
          resetSelect();
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updateModel({
        variables: {
          id: model.value,
          name: data.name,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.updateProductModel;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.models-collections-styles.models.updated'),
            type: 'success',
          });
          reset({
            name: data.name,
          });
          setModel({
            value: model.value,
            label: data.name,
            active: data.active,
            isUsed: data.isUsed,
          });
          resetSelect();
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const resetSelect = () => {
    setOptionsLoaded(0);
    setKey(key + 1);
  };

  const resetAfterDelete = () => {
    setModel(null);
    setShowEditModel(false);
    setShowNewModel(false);
    resetSelect();
  };

  const loadOptions = async (search) => {
    if (search) {
      const response = await getProductModelsByText({
        fetchPolicy: 'network-only',
        variables: {
          text: search,
        },
        onCompleted: (data) => {
          return Promise.resolve(data);
        },
        onError: () => {
          return Promise.resolve(null);
        },
      });

      if (!response.data) {
        return {
          options: [],
          hasMore: false,
        };
      }

      setOptionsLoaded(0);

      return {
        options: formatProductModelOptions(response.data.findProductModelsByText),
        hasMore: false,
      };
    } else {
      const response = await findModelsByOffset({
        fetchPolicy: 'network-only',
        variables: {
          offset: optionsLoaded,
          number: 50,
        },
        onCompleted: (data) => {
          return Promise.resolve(data);
        },
        onError: () => {
          return Promise.resolve(null);
        },
      });
      if (!response.data) {
        return {
          options: [],
          hasMore: false,
        };
      }

      let responseResult = formatProductModelOptions(response.data.findProductModelsByOffset);
      setOptionsLoaded((optionsLoaded) => optionsLoaded + 50);

      return {
        options: responseResult,
        hasMore: responseResult.length > 0,
      };
    }
  };

  const formatProductModelOptions = (allProductModel) => {
    let allProductModelOptions = [];
    allProductModel.map((productModel) => {
      allProductModelOptions = [
        ...allProductModelOptions,
        {
          value: productModel.id,
          label: productModel.name,
          active: productModel.active,
          isUsed: productModel.isUsed,
        },
      ];
    });
    return allProductModelOptions;
  };

  const changeActivation = (value) => {
    setActivationChanging(true);
    let message = '';
    if (value) {
      message = t('settings.products.models-collections-styles.models.activate');
    } else {
      message = t('settings.products.models-collections-styles.models.deactivate');
    }
    updateModelStatus({
      variables: {
        id: model.value,
        active: value,
      },
      onCompleted: (dataReturn) => {
        setActivationChanging(false);
        let data = dataReturn.updateProductModel;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          name: data.name,
        });
        setModel({
          value: data.id,
          label: data.name,
          active: data.active,
          isUsed: data.isUsed,
        });
      },
      onError: (error) => {
        setActivationChanging(false);
        setError(error);
      },
    });
  };

  return (
    <>
      <DeleteModel
        onCloseModal={() => setDisplayModal(false)}
        isOpen={displayModal}
        model={model}
        reset={resetAfterDelete}
      />
      <Card>
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.products.models-collections-styles.models.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.products.models-collections-styles.models.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.products.models-collections-styles.models.list')}
          </label>
          <CreatableAsyncPaginate
            placeholder={t('settings.products.models-collections-styles.models.placeholder')}
            loadOptions={loadOptions}
            loadingMessage={() => t('global.form.loading')}
            noOptionsMessage={() => t('global.no-option')}
            debounceTimeout={500}
            className="mt-1 block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm"
            styles={customStyle}
            theme={theme}
            formatCreateLabel={(inputValue) =>
              `${t('settings.products.models-collections-styles.models.create')} : "${inputValue}"`
            }
            onCreateOption={onCreateModel}
            onChange={onChangeModel}
            value={model}
            key={key}
            cacheUniq={key}
            menuPlacement="auto"
          />
        </div>
        {(showEditModel || showNewModel) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-4 sm:rounded-b-md">
              <label className="text-sm font-medium text-gray-700">
                {t('settings.products.models-collections-styles.models.name')}
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={classNames(
                    errors.name
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                    'mt-1 block h-9 w-full flex-1 rounded'
                  )}
                  {...register('name')}
                />
                {errors.name && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                  </div>
                )}
              </div>
              {errors.name && <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>}
              {model?.isUsed && (
                <div className="mt-4">
                  <InformationAlert>
                    {t('settings.products.models-collections-styles.models.used')}
                  </InformationAlert>
                </div>
              )}
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
              {showEditModel && (
                <>
                  <div className="grid grid-cols-3 gap-4">
                    <Button
                      type="button"
                      theme="outlineDanger"
                      onClick={() => setDisplayModal(true)}
                      disabled={updateModelLoading || model?.isUsed}>
                      {t('global.actions.delete')}
                    </Button>
                    {model.active ? (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(false)}
                        loading={activationChanging}
                        disabled={updateModelLoading || updateModelStatusLoading}>
                        {t('global.actions.deactivate')}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(true)}
                        loading={activationChanging}
                        disabled={updateModelLoading || updateModelStatusLoading}>
                        {t('global.actions.activate')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      loading={updateModelLoading}
                      disabled={updateModelLoading || updateModelStatusLoading || !isDirty}>
                      {t('global.actions.save')}
                    </Button>
                  </div>
                </>
              )}
              {showNewModel && (
                <>
                  <Button
                    type="button"
                    theme="transparent"
                    onClick={() => onCancel()}
                    disabled={createModelLoading}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    theme="primary"
                    disabled={createModelLoading}
                    loading={createModelLoading}>
                    {t('global.actions.add')}
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </Card>
    </>
  );
};

export default Models;
