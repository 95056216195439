import React from 'react';

function Skeleton() {
  return (
    <>
      {[...Array(3)].map((_, i) => (
        <tr key={i}>
          <td>
            <div className="mt-4 w-28 pr-4">
              <div className="skeleton h-6 "></div>
            </div>
          </td>
          <td className="hidden 2xl:table-cell">
            <div className=" mt-4 w-48 pr-4">
              <div className="skeleton h-6 "></div>
            </div>
          </td>
          <td className="hidden 2xl:table-cell">
            <div className=" mt-4 w-20 pr-4">
              <div className="skeleton h-6 "></div>
            </div>
          </td>
          <td>
            <div className="mt-4 w-40 pr-4">
              <div className="skeleton h-6 "></div>
            </div>
          </td>
          <td>
            <div className="mt-4 w-40">
              <div className="skeleton h-6 "></div>
            </div>
          </td>
          <td className="hidden 2xl:flex 2xl:justify-end">
            <div className="mt-4 w-40">
              <div className="skeleton h-6"></div>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

export default Skeleton;
