import { gql } from 'graphql.macro';

export const createOrUpdate = gql`
  mutation CreateOrUpdateProductMarketingIntegration(
    $product_id: ID!
    $comment: String
    $validated_photo: Boolean
    $photo_comment: String
    $validated_photo_edition: Boolean
    $photo_edition_comment: String
    $validated_integration: Boolean
  ) {
    createOrUpdateProductMarketingIntegration(
      product_id: $product_id
      comment: $comment
      validated_photo: $validated_photo
      photo_comment: $photo_comment
      validated_photo_edition: $validated_photo_edition
      photo_edition_comment: $photo_edition_comment
      validated_integration: $validated_integration
    )
  }
`;
