const useValidatePrice = () => {
  const validateMinPrice = (minPrice) => {
    if (!minPrice) {
      return true;
    }
    return parseInt(minPrice) >= 0;
  };
  const validateMaxPrice = (minPrice, maxPrice) => {
    if (!minPrice || !maxPrice) {
      return true;
    }
    return parseInt(minPrice) <= parseInt(maxPrice);
  };
  return { validateMinPrice, validateMaxPrice };
};
export default useValidatePrice;
