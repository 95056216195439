import { gql } from 'graphql.macro';

export const ImportPackagingQuery = gql`
  query ImportPackaging($id: ID!) {
    product(id: $id) {
      id
      packagingOption {
        id
        label_fr
        is_active
      }
      importPackages {
        id
        weight
        volume
        label
        label_for_supplier
        moq
        price
        currency {
          id
          code
        }
      }
      quantity_by_import_package
    }
  }
`;
