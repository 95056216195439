import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useLazyQuery } from '@apollo/client';
import { searchProductsForCombinationQuery } from 'graphql/Queries/Product/ProductCombination/searchProductsForCombination';
import { Product } from 'types/Product';
import ProductInfos from 'components/Common/ProductInfos';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import clsx from 'clsx';

type Props = {
  selection: Product | null;
  setSelection: React.Dispatch<React.SetStateAction<Product | null>>;
};

export const SearchProduct = ({ selection, setSelection }: Props) => {
  const { t } = useTranslation();

  const listRef = useRef<HTMLUListElement>(null);

  const [keyword, setKeyword] = useState('');
  const [showList, setShowList] = useState(false);
  const [products, setProducts] = useState<Product[]>([]);

  const [getProduct, { loading, data, error }] = useLazyQuery(searchProductsForCombinationQuery);

  useEffect(() => {
    if (!keyword) {
      setShowList(false);
      return;
    }
    setProducts([]);
    setShowList(true);
    getProduct({
      variables: { keyword, page: 1 },
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        setProducts(data.searchProductsForCombination.products);
      },
    });
  }, [getProduct, keyword]);

  const handleScroll = (data: {
    searchProductsForCombination: {
      page: number;
      products: Product[];
    };
  }) => {
    if (!data) return;
    const ref = listRef.current;
    if (ref && ref.scrollHeight - ref.scrollTop === ref.clientHeight) {
      getProduct({
        variables: {
          keyword,
          page: data.searchProductsForCombination.page + 1,
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          setProducts((products) => [...products, ...data.searchProductsForCombination.products]);
        },
      });
    }
  };

  const deleteInputSelection = () => {
    setKeyword('');
  };

  const setProductInfos = (infos: Product) => {
    setShowList(false);
    setSelection(infos);
    setKeyword('');
  };

  return (
    <div className="relative w-full">
      <div className="flex  justify-start  ">
        <div className="w-full">
          <label className="block text-sm font-medium text-dark">
            {t('product.product-combination.choose-product')}
          </label>
          <div className="relative mt-2 rounded-md shadow-sm">
            <input
              type="text"
              name="product"
              placeholder={t('product.product-combination.select-product-placeholder')}
              className="block w-full rounded-md border-gray-300   focus:border-primary-500 focus:ring-primary-500 sm:text-sm "
              value={selection?.currentTranslation?.internal_title || keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
            <div className=" absolute inset-y-0 right-0 flex items-center pr-3">
              {keyword && (
                <span
                  onClick={deleteInputSelection}
                  className="cursor-pointer text-gray-500 sm:text-sm"
                  id="price-currency">
                  <XMarkIcon className="h-5 w-5" />
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      {showList && (
        <ul
          ref={listRef}
          onScroll={() => handleScroll(data)}
          className={`absolute z-10 mt-2 max-h-80 w-full overflow-auto rounded-md bg-white shadow-md ${
            products.length > 0 && 'border'
          }`}>
          {error && (
            <div className="p-1">
              <ApolloErrorAlert error={error} />
            </div>
          )}
          {products.length === 0 && !loading && !error && (
            <li className="m-auto cursor-pointer bg-gray-50 p-4 text-gray-500 hover:bg-gray-200">
              {t('products.no-results')}
            </li>
          )}
          {products?.map((product, index) => {
            return (
              <li
                key={index}
                className={clsx(
                  'cursor-pointer border-b border-gray-200 p-2 hover:bg-primary-100',
                  index % 2 === 0 ? 'bg-gray-100' : 'bg-white'
                )}
                onClick={() => setProductInfos(product)}>
                <ProductInfos product={product} withoutLink />
              </li>
            );
          })}
          {loading && (
            <li className="p-4">
              <div className="m-auto h-6 w-6 animate-spin rounded-full border-b-2 border-gray-500"></div>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
