import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import useNotification from 'hooks/useNotification';
import { InputTextAreaControl } from 'components/Common/InputTextAreaControl';
import { useMutation, useQuery } from '@apollo/client';
import { supplierPurchaseOrderCommentQuery } from 'graphql/Queries/Supplier/GeneralInformations/purchaseOrderComment';
import { updatePurchaseOrderCommentMutation } from 'graphql/Mutations/Supplier/GeneralInformations/updatePurchaseOrderComment';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const PurchaseOrderComment = () => {
  const params = useParams();
  const { setNotification } = useNotification();
  const [updateComments, { loading: loadingMutation, error: errorMutation }] = useMutation(
    updatePurchaseOrderCommentMutation
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FieldValues>({
    defaultValues: {
      purchase_order_comment: '',
    },
  });

  const { loading, error, data } = useQuery(supplierPurchaseOrderCommentQuery, {
    variables: { id: params.supplierId },
  });

  useEffect(() => {
    if (!data) return;
    const supplier = data.supplier;
    reset({
      purchase_order_comment: supplier.purchase_order_comment ?? '',
    });
  }, [data, reset]);

  const onSubmit = handleSubmit((data) => {
    updateComments({
      variables: {
        id: params.supplierId,
        purchase_order_comment: data.purchase_order_comment,
      },
      onCompleted: (data) => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.purchase-order-comment.updated'),
          type: 'success',
        });
        reset({
          purchase_order_comment: data.updateSupplier.purchase_order_comment,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <Card>
      <form onSubmit={onSubmit} className="space-y-6">
        {error && <ApolloErrorAlert error={error} />}
        <InputTextAreaControl
          control={control}
          name="purchase_order_comment"
          label={t('supplier.purchase-order-comment.label')}
          isLoading={loading}
          rows={6}
          placeholder={t('supplier.purchase-order-comment.placeholder')}
        />
        {errorMutation && <ApolloErrorAlert error={errorMutation} />}
        <div className="flex justify-end">
          <Button type="submit" loading={loadingMutation} disabled={!isDirty || loadingMutation}>
            {t('global.actions.save')}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default PurchaseOrderComment;
