import { gql } from 'graphql.macro';

export const familyProductsQuery = gql`
  query familyProducts($id: ID!) {
    family(id: $id) {
      lastProducts {
        id
        primaryImage {
          clientUrl
        }
        currentTranslation {
          internal_title
        }
      }
    }
  }
`;
