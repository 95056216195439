import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { PlusIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Common/Button';
import { ErrorAlert } from 'components/Common/Alerts';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { allFeatureUnitsQuery } from 'graphql/Queries/Product/Features/allFeatureUnits';
import { productFeaturesQuery } from 'graphql/Queries/Product/Features/productFeatures';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import Features from './Features';
import Title from 'components/Common/Title';
import AddFeature from './AddFeature';
import OrganiseFeatures from './OrganiseFeatures';
import ImportSlide from './ImportSlide';

const Index = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { productExists } = useProductContextType();

  const [openAddFeature, setOpenAddFeature] = useState<boolean>(false);
  const [openOrganiseFeatures, setOpenOrganiseFeatures] = useState<boolean>(false);
  const [importSlide, setImportSlide] = useState<boolean>(false);
  const [hasVariant, setHasVariant] = useState<boolean>(false);

  const {
    loading: productLoading,
    error: productError,
    data: productData,
  } = useQuery(productFeaturesQuery, {
    variables: { id: params.productId },
  });

  const {
    loading: featureUnitsLoading,
    error: featureUnitsError,
    data: featureUnitsData,
  } = useQuery(allFeatureUnitsQuery);

  if (!productExists) return <></>;

  if (productError) {
    return <ErrorAlert>{productError.message}</ErrorAlert>;
  }
  if (featureUnitsError) {
    return <ErrorAlert>{featureUnitsError.message}</ErrorAlert>;
  }

  const getUnitType = (unit: string) => {
    if (!unit) {
      return 6;
    } else if (['m', 'cm', 'mm'].includes(unit)) {
      return 1;
    } else if (['g', 'kg'].includes(unit)) {
      return 2;
    } else if (unit === 'kg/m3') {
      return 3;
    } else if (unit === 'watts') {
      return 4;
    } else {
      return 5;
    }
  };

  const featureUnits = [...(featureUnitsData?.allFeatureUnits ?? [])].sort((a, b) => {
    const aType = getUnitType(a.text);
    const bType = getUnitType(b.text);
    if (aType < bType) {
      return -1;
    }
    if (aType > bType) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <OrganiseFeatures
        open={openOrganiseFeatures}
        setOpen={setOpenOrganiseFeatures}
        features={productData?.product?.features}
      />
      <ImportSlide setOpen={setImportSlide} open={importSlide} setHasVariant={setHasVariant} />
      <Title
        title={t('product.features.title')}
        addOnEnd={
          hasVariant && (
            <Button theme="secondary" onClick={() => setImportSlide(true)}>
              <>
                <ArrowDownOnSquareStackIcon className="mr-3 h-6 w-6" />
                <span className="leading-6">{t('global.import')}</span>
              </>
            </Button>
          )
        }
      />
      <div className="flex justify-end space-x-4">
        <Button
          theme="secondary"
          disabled={productData?.product?.features.length <= 1 || productLoading}
          onClick={() => setOpenOrganiseFeatures(true)}>
          <>
            <ArrowsUpDownIcon className="mr-3 h-6 w-6" />
            <span className="leading-6">{t('product.features.organise-features')}</span>
          </>
        </Button>
        <Button
          onClick={() => setOpenAddFeature(true)}
          disabled={productLoading || featureUnitsLoading}>
          <div className="flex items-center gap-3">
            <PlusIcon className="w-6" />
            <span className="leading-6">{t('product.features.add')}</span>
          </div>
        </Button>
      </div>
      {productLoading || featureUnitsLoading ? (
        <table className="mt-12 min-w-full divide-y divide-gray-300 text-sm text-dark">
          <thead className="font-semibold uppercase text-secondary">
            <tr>
              <th scope="col" className="table-cell py-3.5 text-left">
                {t('product.features.designation')}
              </th>
              <th scope="col" className="table-cell px-3 py-3.5 text-left">
                {t('product.features.value')}
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4"></th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-300">
            {[...Array(5)].map((_, i) => (
              <tr key={i}>
                <td className="w-56 truncate py-3.5 pr-3 font-semibold">
                  <div className="skeleton h-6 w-40" />
                </td>
                <td className="table-cell px-3 py-3.5">
                  <div className="skeleton h-6 w-20" />
                </td>
                <td className="flex justify-end gap-3 px-3 py-3.5" />
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <>
          <AddFeature
            open={openAddFeature}
            setOpen={setOpenAddFeature}
            features={productData?.product?.features}
            units={featureUnits}
          />
          <Features
            features={productData?.product?.features}
            units={featureUnits}
            productWeight={productData?.product?.product_weight}
          />
        </>
      )}
    </>
  );
};

export default Index;
