import React, { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { supplierHeaderQuery } from 'graphql/Queries/Supplier/supplierHeader';
import { editSupplierNameMutation } from 'graphql/Mutations/Supplier/Header/editName';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputTextControl } from 'components/Common/InputTextControl';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  name?: string;
};

const Edit = ({ open, setOpen, name }: Props) => {
  const params = useParams();

  const [editName, { loading, error, reset: resetMutation }] =
    useMutation(editSupplierNameMutation);

  const schema = yup.object().shape({
    name: yup.string().trim().required(t('form.required')),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm({ resolver: yupResolver<FieldValues>(schema) });

  useEffect(() => {
    if (!name) return;
    reset({ name });
  }, [name, reset]);

  const onSubmit = handleSubmit((data) => {
    editName({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: supplierHeaderQuery,
          variables: { id: params.supplierId },
        },
      ],
      variables: {
        id: params.supplierId,
        name: data.name,
      },
      onCompleted: () => {
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });
  const onCancel = () => {
    reset();
    resetMutation();
  };
  return (
    <SlideOver
      title={t('supplier.header.edit-name')}
      open={open}
      setOpen={setOpen}
      onSubmit={onSubmit}
      onCancel={onCancel}
      buttonText={t('global.actions.save')}
      isDirty={isDirty}
      error={error}
      loading={loading}>
      <>
        <InputTextControl control={control} name="name" label={t('supplier.header.name')} />
      </>
    </SlideOver>
  );
};

export default Edit;
