import React, {
  useState,
  useEffect,
  ReactElement,
  ComponentPropsWithoutRef,
  ChangeEvent,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, useController } from 'react-hook-form';
import { GroupBase } from 'react-select';
import Creatable, { CreatableProps } from 'react-select/creatable';
import {
  ComponentProps,
  UseAsyncPaginateParams,
  withAsyncPaginate,
} from 'react-select-async-paginate';
import { ApolloError, useLazyQuery } from '@apollo/client';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import useApolloErrorMessage from 'hooks/useApolloErrorMessage';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import { findFeatureLabelsByOffsetQuery } from 'graphql/Queries/Product/Features/findFeatureLabelsByOffset';
import { featureLabelsByTextQuery } from 'graphql/Queries/Product/Features/featureLabelsByText';
import { FeatureLabel, ProductFeature } from 'types/Product';
import { ID, OptionProps } from 'types/Global';

type AsyncPaginateCreatableProps<
  OptionProps,
  Group extends GroupBase<OptionProps>,
  Additional,
  IsMulti extends boolean,
> = CreatableProps<OptionProps, IsMulti, Group> &
  UseAsyncPaginateParams<OptionProps, Group, Additional> &
  ComponentProps<OptionProps, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  OptionProps,
  Group extends GroupBase<OptionProps>,
  Additional,
  IsMulti extends boolean = false,
>(
  props: AsyncPaginateCreatableProps<OptionProps, Group, Additional, IsMulti>
) => ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

type Props = {
  control: Control;
  name: string;
  features: ProductFeature[];
  loading: boolean;
} & ComponentPropsWithoutRef<'select'>;

const AsyncPaginateFeatureLabels = ({ control, name, features, loading, ...props }: Props) => {
  const { t } = useTranslation();
  const { field, formState } = useController({ name, control });
  const { onChange, value } = field;
  const [error, setError] = useState<ApolloError | null>(null);

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e);
    props.onChange?.(e);
  };

  const [getFindFeatureLabelsByOffset, { loading: loadingByOffset }] = useLazyQuery(
    findFeatureLabelsByOffsetQuery
  );
  const [getFeatureLabelsByText, { loading: loadingByText }] =
    useLazyQuery(featureLabelsByTextQuery);

  const [optionsLoaded, setOptionsLoaded] = useState(0);
  const [featureLabelIds, setFeatureLabelIds] = useState<ID[]>([]);

  useEffect(() => {
    setFeatureLabelIds(features.map((feature) => feature.label?.id));
  }, [features]);

  const loadOptions = async (search: string) => {
    if (search) {
      const response = await getFeatureLabelsByText({
        variables: {
          text: search,
        },
        onCompleted: (data) => {
          setError(null);
          return Promise.resolve(data);
        },
        onError: (error) => {
          setError(error);
        },
      });

      setOptionsLoaded(0);

      return {
        options: formatFeatureLabelOptions(response.data.featureLabelsByText, true),
        hasMore: false,
      };
    } else {
      const response = await getFindFeatureLabelsByOffset({
        variables: {
          offset: optionsLoaded,
          number: 50,
        },
        onCompleted: (data) => {
          setError(null);
          return Promise.resolve(data);
        },
        onError: (error) => {
          setError(error);
        },
      });

      const responseResult = formatFeatureLabelOptions(response.data.findFeatureLabelsByOffset);

      setOptionsLoaded((optionsLoaded) => optionsLoaded + 50);

      return {
        options: responseResult,
        hasMore: responseResult.length > 0,
      };
    }
  };

  const formatFeatureLabelOptions = (allFeatureLabels: FeatureLabel[], search = false) => {
    let allFeatureLabelOptions: OptionProps[] = [];
    allFeatureLabels.map((featureLabel: FeatureLabel) => {
      if (
        search &&
        !featureLabelIds.includes(`${featureLabel.crt_des_id}`) &&
        featureLabel.trd_texte
      ) {
        allFeatureLabelOptions = [
          ...allFeatureLabelOptions,
          {
            value: featureLabel.crt_des_id as string,
            label: featureLabel.trd_texte,
          },
        ];
      } else if (!search && !featureLabelIds.includes(featureLabel.id) && featureLabel.text) {
        allFeatureLabelOptions = [
          ...allFeatureLabelOptions,
          {
            value: featureLabel.id as string,
            label: featureLabel.text,
          },
        ];
      }
    });
    return allFeatureLabelOptions;
  };

  const errorMessage = useApolloErrorMessage({ apolloError: error });

  return (
    <>
      <label className="flex justify-between text-sm font-medium text-dark">
        {t('product.features.choose-feature-label')}
      </label>
      <div className="mt-2">
        <div className="relative">
          <Controller
            control={control}
            name={name}
            render={() => (
              <CreatableAsyncPaginate
                styles={!errorMessage && !formState.errors[name] ? customStyle : errorCustomStyle}
                value={value}
                theme={theme}
                loadOptions={loadOptions}
                onChange={onChangeSelect}
                placeholder={t('global.select.placeholder')}
                noOptionsMessage={() => t('global.no-option')}
                loadingMessage={() => t('global.loading')}
                formatCreateLabel={(inputText) => `${t('global.actions.create')} : "${inputText}"`}
                debounceTimeout={500}
                isLoading={loading || loadingByOffset || loadingByText}
              />
            )}
          />
          {(errorMessage || formState.errors[name]) && (
            <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
              <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
            </div>
          )}
        </div>
        {errorMessage && <p className="mt-2 text-sm text-red-600">{errorMessage}</p>}
        {formState.errors[name] && (
          <p className="mt-2 text-sm text-red-600">{formState.errors[name]?.message?.toString()}</p>
        )}
      </div>
    </>
  );
};

export default AsyncPaginateFeatureLabels;
