import React from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { productPreviewQuery } from 'graphql/Queries/Product/Preview/product';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { Product } from 'types/Product';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Main from './Main';
import Stock from './Stock';
import Arrivals from './Arrivals';
import Packaging from './Packaging';
import Supplying from './Supplying';
import OffersAndVisibilities from './OffersAndVisibilities';
import Variants from './Variants';
import Collection from './Collection';
import Pack from './Pack';

const PreviewIndex = () => {
  const { productExists } = useProductContextType();
  const params = useParams();

  const { data, loading, error } = useQuery(productPreviewQuery, {
    variables: {
      id: params.productId,
    },
  });

  const product: Product | undefined = data?.product;

  if (!productExists) return <></>;
  if (error) return <ApolloErrorAlert error={error} />;

  return (
    <div className="space-y-6">
      <Main loading={loading} product={product} />
      {product?.isCombination && <Pack product={product} />}
      <Stock loading={loading} product={product} />
      {!product?.isCombination && (
        <>
          <Arrivals loading={loading} product={product} />
          <Packaging loading={loading} product={product} />
          <Supplying loading={loading} product={product} />
        </>
      )}
      <OffersAndVisibilities loading={loading} product={product} />
      {!product?.isCombination && <Variants loading={loading} product={product} />}
      <Collection loading={loading} product={product} />
    </div>
  );
};

export default PreviewIndex;
