import { gql } from 'graphql.macro';

export const countriesQuery = gql`
  query Countries {
    countries {
      id
      name
    }
  }
`;
