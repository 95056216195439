import React from 'react';
import { Link } from 'react-router-dom';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PhotoIcon } from '@heroicons/react/24/outline';

function Thumbnail({
  id,
  previewImage = '',
  title = '',
  price = 0,
  supply_exception = false,
  is_to_destock = false,
}) {
  const { t } = useTranslation();
  return (
    <div className="xxl-basis-[25%] group relative basis-[100%] rounded-lg bg-white p-5 lg:basis-[33%]">
      <div className="min-h-80 relative flex aspect-[1/1] w-full items-center overflow-hidden rounded-md group-hover:opacity-75 lg:h-80">
        {previewImage !== '' ? (
          <img
            alt={t('product.header.image-not-found')}
            src={previewImage}
            className="mx-auto h-auto w-full"
          />
        ) : (
          <PhotoIcon className="feather feather-image w-[64px]" />
        )}
        {supply_exception && (
          <div className="absolute left-0 top-0 flex h-[20px] items-center rounded-full bg-yellow-200 px-2 text-sm text-primary-500">
            {t('products.filters.status.supplier-exception')}
          </div>
        )}
        {is_to_destock && (
          <div className="absolute right-0 top-0 flex h-[20px] items-center rounded-full bg-yellow-200 px-2 text-sm text-primary-500">
            {' '}
            {t('products.filters.status.destock')}{' '}
          </div>
        )}
      </div>
      <div className="">
        <h3 className="">
          <Link to={`/products/${id}/preview`}>
            <span className="absolute inset-0" />
            {title ? title : t('product.thumbnail.no-title')}
          </Link>
        </h3>

        <p className="text-sm font-bold text-gray-900">
          {price && (
            <NumericFormat value={price} displayType={'text'} suffix={' €'} decimalScale={2} />
          )}
        </p>
      </div>
    </div>
  );
}

Thumbnail.propTypes = {
  id: PropTypes.number.isRequired,
  previewImage: PropTypes.string,
  title: PropTypes.string,
  price: PropTypes.number,
  supply_exception: PropTypes.bool,
  is_to_destock: PropTypes.bool,
};

export default Thumbnail;
