import { gql } from 'graphql.macro';

export const updateSupplierContactMutation = gql`
  mutation updateSupplierContact(
    $id: ID!
    $name: String!
    $supplier_id: ID!
    $type_id: Int!
    $email: String
    $phone: String
    $mobile: String
    $comment: String
  ) {
    updateSupplierContact(
      id: $id
      name: $name
      supplier_id: $supplier_id
      type_id: $type_id
      email: $email
      phone: $phone
      mobile: $mobile
      comment: $comment
    ) {
      id
    }
  }
`;
