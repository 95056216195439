import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { Button } from 'components/Common/Button';
import { PlusIcon } from '@heroicons/react/20/solid';
import { ElasticSearchProduct } from 'types/ElasticSearch';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { useSearchContextType } from '../Contexts/SearchContext';
import InputText from 'components/Common/InputText';
import { AdjustmentsHorizontalIcon, MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { debounce } from 'lodash';
import Pagination from 'components/Common/Pagination';
import Filters from './Filters/Index';
import FilterPills from './Filters/FilterPills/FilterPills';
import useSearch from './Hooks/UseSearch';
import useFilter from './Hooks/UseFilter';
import Card from './Card';
import CreateProduct from './CreateProduct';

export default function Search() {
  const { t } = useTranslation();
  const { searchProduct, setSearchProduct } = useSearchContextType();

  const methods = useForm();

  const [openCreateSlide, setOpenCreateSlide] = useState(false);
  const [openFiltersSlide, setOpenFiltersSlide] = useState(false);
  const [filters, setFilters] = useState<FieldValues>({});
  const [firstTime, setFirstTime] = useState(true);

  const { getProduct, filterBuckets, productResults, error, loading, page, setPage } = useSearch();
  const { watch, getValues, setValue } = methods;

  useEffect(() => {
    const delayedGetProduct = debounce((variables) => {
      getProduct(variables);
      setFirstTime(false);
    }, 500);

    delayedGetProduct({
      variables: { title: searchProduct, filter: Object.entries(getValues()), page },
    });

    return () => {
      delayedGetProduct.cancel();
    };
  }, [getProduct, getValues, page, searchProduct]);

  useEffect(() => {
    const subscription = watch(() => {
      setFilters(getValues());
    });
    return () => subscription.unsubscribe();
  }, [watch, getValues, setValue, getProduct]);

  useEffect(() => {
    setPage(1);
  }, [searchProduct, setPage]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchProduct(e.target.value);
  };

  return (
    <>
      <CreateProduct open={openCreateSlide} setOpen={setOpenCreateSlide} />
      <Filters
        open={openFiltersSlide}
        setOpen={setOpenFiltersSlide}
        methods={methods}
        loading={loading}
        filterBuckets={filterBuckets}
        getProduct={getProduct}
        page={page}
        setPage={setPage}>
        <>
          {useFilter().countSelectedFilter(methods.getValues()) > 0 && (
            <div className="flex flex-row flex-wrap gap-2 px-2 py-4">
              <FilterPills filters={filters} methods={methods} />
            </div>
          )}
        </>
      </Filters>

      <div className="container mx-auto">
        <div className="space-y-6 p-6 lg:space-y-12 lg:p-12">
          {/* Title + create button */}
          <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 text-dark sm:truncate sm:text-3xl sm:tracking-tight">
                {t('products.title')}
              </h2>
            </div>
            <div className="mt-4 flex md:ml-4 md:mt-0">
              <Button type="button" onClick={() => setOpenCreateSlide(true)}>
                <div className="flex space-x-2">
                  <PlusIcon className="w-5" />
                  <span>{t('products.create')}</span>
                </div>
              </Button>
            </div>
          </div>
          {/* Search + filters button */}
          <div className="space-y-4 md:flex md:items-center md:justify-start md:space-x-3 md:space-y-0">
            <div className="w-full max-w-lg">
              <InputText
                value={searchProduct}
                onChange={handleSearchChange}
                icon={<MagnifyingGlassIcon className="h-5 w-5 shrink-0 text-secondary" />}
                placeholder={t('global.search')}
              />
            </div>
            <div className="flex w-full items-center justify-between ">
              <Button type="button" theme="secondary" onClick={() => setOpenFiltersSlide(true)}>
                <div className="flex space-x-2">
                  <AdjustmentsHorizontalIcon className="w-5" />
                  <span>{t('products.filters.title')}</span>
                </div>
              </Button>
              <div className="italic text-dark">
                {productResults.total} {t('products.results_other')}
              </div>
            </div>
          </div>
          {/* Result */}
          {error && <ApolloErrorAlert error={error} />}
          {!error && !loading && !productResults.products.length && !firstTime && (
            <InformationAlert>{t('products.no-results')}</InformationAlert>
          )}
          <div className={'grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 '}>
            {loading || firstTime ? (
              <>
                {[...Array(12)].map((x, i) => (
                  <div
                    key={i}
                    className=" flex flex-col space-y-4 rounded border  border-gray-200 bg-white shadow">
                    <div className="skeleton h-96 overflow-hidden rounded  px-4 pb-10"></div>
                  </div>
                ))}
              </>
            ) : (
              <>
                {productResults.products.map((product: ElasticSearchProduct, i: number) => (
                  <Card key={i} product={product} />
                ))}
              </>
            )}
          </div>
          {!loading && !error && (
            <Pagination
              paginator={{
                total: productResults.total,
                lastPage: productResults.lastPage,
                currentPage: page,
              }}
              setPage={setPage}
            />
          )}
        </div>
      </div>
    </>
  );
}
