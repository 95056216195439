import { gql } from 'graphql.macro';

export const updateContactTypeStatusMutation = gql`
  mutation UpdateContactTypeStatus($id: ID!, $active: Boolean) {
    updateSupplierContactType(id: $id, active: $active) {
      id
      label
      active
      used
    }
  }
`;
