import { gql } from 'graphql.macro';

export const allVariantsQuery = gql`
  query allVariants($id: ID!) {
    product(id: $id) {
      id
      allVariants {
        id
        product_weight
        features {
          id
          label {
            id
            text
          }
          value {
            id
            text
          }
          unit {
            id
            text
          }
        }
        hasFeaturesElements
        currentTranslation {
          title
          internal_title
        }
      }
    }
  }
`;
