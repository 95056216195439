import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, ApolloError } from '@apollo/client';
import { visibilityInformationQuery } from 'graphql/Queries/Product/Visibility/visibilityInfo';
import { VisibilityInformation } from 'types/Query';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import TableContent from './TableContent';
import SkeletonRow from './SkeletonRow';
import { VISIBILITY_FORBIDDEN_ID } from 'global';

type Props = {
  setDisabledAllForbiddenCountry: React.Dispatch<React.SetStateAction<boolean>>;
};

const VisibilityTable = ({ setDisabledAllForbiddenCountry }: Props) => {
  const { t } = useTranslation();
  const params = useParams();

  const [updateError, setUpdateError] = useState<ApolloError | null>(null);

  const { loading, data, error } = useQuery<VisibilityInformation>(visibilityInformationQuery, {
    fetchPolicy: 'network-only',
    variables: {
      id: params.productId,
    },
  });

  useEffect(() => {
    if (!data) return;
    let disabled = true;
    data.product?.visibilityInformation.map((info) => {
      if (info.displayPreference !== VISIBILITY_FORBIDDEN_ID) {
        disabled = false;
      }
    });
    setDisabledAllForbiddenCountry(disabled);
  }, [data, setDisabledAllForbiddenCountry]);

  if (error) return <ApolloErrorAlert error={error} />;

  return (
    <>
      {updateError && <ApolloErrorAlert error={updateError} />}
      <table className="mt-16 w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th scope="col" className=" p-3 text-left text-sm font-semibold text-secondary">
              {t('product.visibility.table.country').toUpperCase()}
            </th>
            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-secondary">
              {t('product.visibility.table.type').toUpperCase()}
            </th>
            <th
              scope="col"
              className="hidden p-3 text-left text-sm font-semibold text-secondary 2xl:table-cell">
              {t('product.visibility.table.status').toUpperCase()}
            </th>
            <th
              scope="col"
              className="hidden p-3 text-left text-sm font-semibold text-secondary 2xl:table-cell">
              {t('product.visibility.table.details').toUpperCase()}
            </th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <SkeletonRow />
          ) : (
            data?.product?.visibilityInformation.map((infos, i) => (
              <TableContent key={i} infos={infos} setUpdateError={setUpdateError} />
            ))
          )}
        </tbody>
      </table>
    </>
  );
};
export default VisibilityTable;
