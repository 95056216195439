import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { customStyle, errorCustomStyle } from 'components/Style/customStyle';
import { createFilter } from 'react-select';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import CreatableSelect from 'react-select/creatable';
import PropTypes from 'prop-types';

function MutliCreatable({ value, options, name, control, loading, onChange, error }) {
  const { t } = useTranslation();

  return (
    <>
      <div className="relative">
        <Controller
          as={name}
          name={name}
          control={control}
          value={value}
          render={({ field }) => (
            <CreatableSelect
              isDisabled={loading}
              placeholder={loading ? t('global.form.loading') : t('global.form.select')}
              className="mt-1 block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm"
              styles={value?.isDisabled ? errorCustomStyle : customStyle}
              filterOption={createFilter({ ignoreAccents: false })}
              isSearchable={true}
              name={name}
              menuPosition={'relative'}
              formatCreateLabel={(inputValue) => `${t('global.form.new-item')}: "${inputValue}"`}
              {...field}
              noOptionsMessage={() => t('global.form.no-option')}
              options={options}
              onChange={(e) => {
                field.onChange(e);
                onChange ? onChange() : '';
              }}
            />
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
          {error && <ExclamationCircleIcon className="h-5 w-5 text-red-500" />}
        </div>
        <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
          {value?.isDisabled && (
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
          )}
        </div>
      </div>
      {value?.isDisabled && (
        <p className="mt-1 text-sm text-red-600"> {t('product.taxonomy.form.inactive-choice')}</p>
      )}
      {error && error.graphQLErrors[0]?.extensions?.category === 'custom' ? (
        <p className="mt-2 h-[20px] text-sm text-red-600">{error.graphQLErrors[0].message}</p>
      ) : (
        error && <p className="mt-2 h-[20px] text-sm text-red-600">{t('global.error-msg')}</p>
      )}
    </>
  );
}

MutliCreatable.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  control: PropTypes.object,
  options: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.object,
};

export default MutliCreatable;
