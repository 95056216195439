import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PhotoIcon, TrashIcon, PencilSquareIcon } from '@heroicons/react/24/outline';
import { Product } from 'types/Product';
import { ID } from 'types/Global';
import Tippy from '@tippyjs/react';
import Badge from 'components/Common/Badge';
import useCheckUrlImage from 'components/Products/Hooks/useCheckUrlImage';
import DeleteConstituent from './ProductCombination/DeleteConstituent';
import EditConstituent from './ProductCombination/EditConstituent';

type Props = {
  product?: Product | undefined;
  quantity?: number;
  withVariants?: boolean;
  isModifiable?: boolean;
  combinationId?: ID;
};

const Card = ({
  product,
  quantity,
  withVariants = false,
  isModifiable = false,
  combinationId,
}: Props) => {
  const isValidImage = useCheckUrlImage(product?.primaryImage?.clientUrl);
  const { t } = useTranslation();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  if (!product) return null;

  const title = product.currentTranslation
    ? product.currentTranslation.internal_title
      ? product.currentTranslation.internal_title
      : t('global.no-title')
    : t('global.no-translation');

  const additionalVariantsCount =
    withVariants && product?.allVariants.length > 4 ? product?.allVariants.length - 4 : 0;
  const additionalVariants =
    withVariants && additionalVariantsCount > 0 ? product?.allVariants.slice(4) : [];

  return (
    <div className="rounded border border-gray-200 bg-white">
      <DeleteConstituent
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        constituentProductId={product.id}
        combinationId={combinationId}
        img={product.primaryImage?.clientUrl}
        title={title}
      />
      <EditConstituent
        open={openEditModal}
        setOpen={setOpenEditModal}
        combinationId={combinationId}
        constituentProductId={product.id}
        img={product.primaryImage?.clientUrl}
        quantity={quantity}
        title={title}
      />
      <div className="flex h-full flex-col justify-between">
        <div className="group relative">
          <Link to={`/products/${product.id}`}>
            <div className="absolute inset-0 z-10 bg-gray-300 opacity-0 transition-opacity duration-300 group-hover:opacity-50"></div>
          </Link>
          {isModifiable && (
            <div>
              <button type="button" onClick={() => setOpenEditModal(true)}>
                <PencilSquareIcon className="absolute right-2 top-2 z-50 w-10 cursor-pointer rounded-full border border-gray-200 bg-white p-2 opacity-0 outline-none transition-opacity duration-300 hover:text-blue-600 group-hover:opacity-100" />
              </button>
              <button type="button" onClick={() => setOpenDeleteModal(true)}>
                <TrashIcon className="absolute right-2 top-14 z-50 w-10 cursor-pointer rounded-full border border-gray-200 bg-white p-2 opacity-0 outline-none transition-opacity duration-300 hover:text-red-600 group-hover:opacity-100" />
              </button>
            </div>
          )}

          <div className="relative flex h-48 flex-col justify-center overflow-hidden p-4">
            <Link to={`/products/${product.id}/`} target="_blank">
              {isValidImage ? (
                <img src={product.primaryImage?.clientUrl} className="object-cover" />
              ) : (
                <PhotoIcon className="h-40 w-full text-secondary" />
              )}
            </Link>
          </div>

          <div className="relative z-20 mt-2">
            {quantity && (
              <div className="z-20 flex justify-center pb-4">
                <Badge type="outline">{`${t(
                  'product.product-combination.quantity'
                )} : ${quantity}`}</Badge>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-end space-y-1 p-3">
          <Tippy content={title}>
            <p className="mb-2 line-clamp-2 text-sm text-dark">{title}</p>
          </Tippy>
          <div className="flex text-sm font-medium ">
            <span className="mr-1 truncate font-bold text-dark">
              {product.currentLanguageOffer
                ? `${
                    product.currentLanguageOffer.priceWithEcotax
                      ? product.currentLanguageOffer.priceWithEcotax.toFixed(2)
                      : '-'
                  } €`
                : '-'}
            </span>
            {product.currentLanguageOffer?.isDiscount && (
              <span className="truncate text-secondary line-through">
                {`${
                  product.currentLanguageOffer.highestPrice
                    ? product.currentLanguageOffer.highestPrice.toFixed(2)
                    : '-'
                } €`}
              </span>
            )}
          </div>

          <div className="text-sm text-dark">
            {product.ecoMobilier?.enable ? (
              <span className="truncate">
                {product.ecoMobilier?.amount?.including_taxes_value
                  ? t('product.product-combination.ecoMobilier', {
                      ecoMobilier: product.ecoMobilier?.amount?.including_taxes_value,
                    })
                  : t('product.preview.pack.no-ecotax')}
              </span>
            ) : (
              <span className="truncate ">
                {product.deee?.enable && product.deee?.amount?.amount
                  ? t('product.product-combination.deee', {
                      deee: product.deee.amount.amount,
                    })
                  : t('product.preview.pack.no-ecotax')}
              </span>
            )}
          </div>
          {withVariants && (
            <div className="flex gap-1">
              {product.allVariants.slice(0, 4).map(
                (variant, i) =>
                  variant.color && (
                    <Link key={i} to={`/products/${variant.id}/`} target="_blank">
                      <div
                        className="h-6 w-6 rounded-full border"
                        style={{ backgroundColor: `#${variant.color.hex_code}` }}
                      />
                    </Link>
                  )
              )}
              {additionalVariantsCount > 0 && (
                <Tippy
                  interactive
                  placement="bottom"
                  theme="light"
                  content={
                    <div className="grid grid-cols-4 gap-2 p-1">
                      {additionalVariants.map((variant, i) => (
                        <Link key={i} to={`/products/${variant.id}/`} target="_blank">
                          <div
                            className="h-6 w-6 rounded-full border"
                            style={{ backgroundColor: `#${variant.color.hex_code}` }}
                          />
                        </Link>
                      ))}
                    </div>
                  }>
                  <div className="ml-2 flex h-6 w-6 cursor-pointer items-center justify-center text-sm text-dark ">
                    +{additionalVariantsCount}
                  </div>
                </Tippy>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Card;
