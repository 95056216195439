import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { updateProductPackagingOptionMutation } from 'graphql/Mutations/Product/Packaging/updateProductPackagingOption';
import { packagingOptionsQuery } from 'graphql/Queries/Product/Packaging/packagingOptions';
import { PackagingOption, Product } from 'types/Product';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputTextControl } from 'components/Common/InputTextControl';
import { SelectControl } from 'components/Common/SelectControl';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  product: Product;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const PackagingOptions = ({ product, open, setOpen }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();

  const [packagingOptions, setPackagingOptions] = useState([]);

  const {
    data,
    loading: loadingPackagingOptions,
    error: errorPackagingOptions,
  } = useQuery(packagingOptionsQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!data) return;
    setPackagingOptions(
      data.packagingOptions?.map((packagingOption: PackagingOption) => {
        return {
          label: packagingOption.label_fr,
          label_gb: packagingOption.label_gb,
          value: packagingOption.id,
          key: packagingOption.id,
          active: packagingOption.is_active,
          isDisabled: !packagingOption.is_active,
        };
      })
    );
  }, [setPackagingOptions, data]);

  const [
    updateProductPackagingOption,
    { loading: updateProductPackagingOptionLoading, error: updateProductPackagingOptionError },
  ] = useMutation(updateProductPackagingOptionMutation);

  const schema = yup.object().shape({
    packaging_option: yup
      .object()
      .shape({
        value: yup.string().required(t('form.required')),
        label: yup.string().required(t('form.required')),
      })
      .required(t('form.required'))
      .typeError(t('form.required')),
    quantity_by_import_package: yup
      .number()
      .integer(t('form.number.integer'))
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.number.integer')),
  });

  const {
    control,
    handleSubmit,
    formState: { isDirty },
    reset,
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    if (!product) return;
    reset({
      packaging_option: product.packagingOption
        ? {
            value: product.packagingOption.id as string,
            label: product.packagingOption.label_fr,
            active: product.packagingOption.is_active,
          }
        : null,
      quantity_by_import_package: product.quantity_by_import_package ?? '',
      label: product.packagingOption?.label_fr || '',
      label_gb: product.packagingOption?.label_gb || '',
    });
  }, [product, reset]);

  const onCancel = () => {
    setOpen(false);
    if (product.packagingOption) {
      reset({
        packaging_option: product.packagingOption
          ? {
              value: product.packagingOption.id as string,
              label: product.packagingOption.label_fr,
              active: product.packagingOption.is_active,
            }
          : null,
        quantity_by_import_package: product.quantity_by_import_package ?? '',
        label: product.packagingOption.label_fr || '',
        label_gb: product.packagingOption.label_gb || '',
      });
    } else {
      reset();
    }
  };

  const onSubmit = handleSubmit(async (data) => {
    const packagingOptionId = data?.packaging_option.value;
    updateProductPackagingOption({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        id: params.productId,
        packaging_option_id: packagingOptionId,
        quantity_by_import_package: data.quantity_by_import_package,
      },
      onCompleted: (res) => {
        setOpen(false);
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.import.packaging-option-updated'),
          type: 'success',
        });
        const product = res.updateProductImportPackagingOption;

        reset({
          packaging_option: product.packagingOption
            ? {
                value: product.packagingOption.id as string,
                label: product.packagingOption.label_fr,
                active: product.packagingOption.is_active,
              }
            : null,
          quantity_by_import_package: product.quantity_by_import_package ?? '',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={t('product.packaging.import.options')}
        buttonText={t('global.actions.save')}
        onSubmit={onSubmit}
        onCancel={onCancel}
        isDirty={isDirty}
        loading={updateProductPackagingOptionLoading}
        error={updateProductPackagingOptionError}>
        <div className="space-y-6">
          <SelectControl
            control={control}
            name="packaging_option"
            label={t('product.packaging.import.packaging')}
            options={packagingOptions}
            loadingOptions={loadingPackagingOptions}
            apolloError={errorPackagingOptions}
          />
          <InputTextControl
            label={t('product.packaging.import.quantity-by-import-package')}
            name="quantity_by_import_package"
            control={control}
          />
        </div>
      </SlideOver>
    </>
  );
};

export default PackagingOptions;
