import { gql } from 'graphql.macro';

export const packagingOptionsQuery = gql`
  query PackagingOptions {
    packagingOptions {
      id
      label_fr
      label_gb
      isUsed
      is_active
    }
  }
`;
