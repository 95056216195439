import { gql } from 'graphql.macro';

export const taxonomyQuery = gql`
  query taxonomy($id: ID!) {
    product(id: $id) {
      id
      currentTranslation {
        internal_title
      }
      taxonomy {
        type {
          id
          label
          is_active
          category {
            id
            label
            is_active
            universe {
              id
              label
              is_active
            }
          }
        }
      }
    }
  }
`;
