import { gql } from 'graphql.macro';

export const supplierBankQuery = gql`
  query SupplierBank($id: ID!) {
    supplier(id: $id) {
      id
      local_bank_beneficiary_name
      local_bank_name
      local_bank_street
      local_bank_complementary_address
      local_bank_city
      local_bank_account
      local_bank_swift
      localBankCountry {
        id
        name
      }
      local_bank_zipcode
    }
  }
`;
