import { gql } from 'graphql.macro';

export const deletePackageSizeMutation = gql`
  mutation DeletePackageSize($id: ID!, $packageSizeId: ID!) {
    deleteProductPackageSize(id: $id, packageSizeId: $packageSizeId) {
      weight
      volume
      packageSizes {
        id
        height
        width
        depth
        type {
          label
          legacy_code
        }
        quantity_by_pallet
        to_repack
        number
      }
      packages {
        number
        supplierProductLabel
        name
        weight
        volume
      }
    }
  }
`;
