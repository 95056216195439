import { gql } from 'graphql.macro';

export const createPortMutation = gql`
  mutation CreatePort($label: String!) {
    createPort(label: $label) {
      id
      label
      active
    }
  }
`;
