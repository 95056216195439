import { gql } from 'graphql.macro';

export const updateProductCombinationQuantityMutation = gql`
  mutation updateProductCombinationQuantity($id: ID!, $child_product_qty: Int!) {
    updateProductCombinationQuantity(id: $id, child_product_qty: $child_product_qty) {
      id
      parent_product_id
      child_product_id
      child_product_qty
    }
  }
`;
