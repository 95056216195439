import { gql } from 'graphql.macro';

export const importFeatureInformationsMutation = gql`
  mutation ImportFeatureInformations(
    $id: ID!
    $from_product_id: ID!
    $features_import_element: Mixed!
  ) {
    importFeatureInformations(
      id: $id
      from_product_id: $from_product_id
      features_import_element: $features_import_element
    ) {
      id
    }
  }
`;
