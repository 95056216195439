import { gql } from 'graphql.macro';

export const searchSuppliersQuery = gql`
  query searchSuppliers(
    $page: Int!
    $perPage: Int!
    $orderBy: String!
    $direction: String!
    $keyword: String
    $isActive: Boolean
    $countryId: Int
  ) {
    searchSuppliers(
      page: $page
      perPage: $perPage
      orderBy: $orderBy
      direction: $direction
      keyword: $keyword
      isActive: $isActive
      countryId: $countryId
    ) {
      data {
        id
        name
        billing_name
        active
        country {
          iso
          name
        }
      }
      paginatorInfo
    }
  }
`;
