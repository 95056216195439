import React from 'react';
import clsx from 'clsx';

type Props = {
  title: string;
  addOnEnd?: React.ReactNode;
  withBorder?: boolean;
};

const Title = ({ title, addOnEnd, withBorder = true }: Props) => {
  return (
    <div
      className={clsx(
        'mb-6 flex w-full items-center  justify-between bg-gray-50 pb-5',
        withBorder && 'border-b border-gray-200'
      )}>
      <h2 className="text-base font-semibold leading-6 text-dark">{title}</h2>
      <div>{addOnEnd}</div>
    </div>
  );
};

export default Title;
