import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { allSupplierContactsQuery } from 'graphql/Queries/Supplier/Contacts/allSupplierContacts';
import { allContactTypesQuery } from 'graphql/Queries/Supplier/Contacts/allContactTypes';
import { addSupplierContactMutation } from 'graphql/Mutations/Supplier/Contacts/addSupplierContact';
import { FieldValues, useForm } from 'react-hook-form';
import { InputTextControl } from 'components/Common/InputTextControl';
import { SelectControl } from 'components/Common/SelectControl';
import { SupplierContactType } from 'types/Supplier';
import { InputTextAreaControl } from 'components/Common/InputTextAreaControl';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import SlideOver from 'components/Common/SlideOver';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddContact = ({ open, setOpen }: Props) => {
  const { setNotification } = useNotification();
  const params = useParams();

  const { loading, error, data } = useQuery(allContactTypesQuery);
  const [addContact, { error: addError, loading: addLoading }] = useMutation(
    addSupplierContactMutation
  );

  const options = data?.allContactTypes?.map((type: SupplierContactType) => {
    return {
      value: type.id,
      label: type.label,
      isDisabled: !type.active,
    };
  });

  const schema = yup.object().shape({
    name: yup.string().required(t('form.required')),
    type: yup.object().required(t('form.required')),
    email: yup
      .string()
      .matches(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}|)$/, t('form.invalid-email'))
      .nullable(),
  });

  const { handleSubmit, control, reset } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    reset({
      name: '',
      type: null,
      email: '',
      mobile: '',
      phone: '',
      comment: '',
    });
  }, [reset]);

  const close = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    addContact({
      variables: {
        supplier_id: params.supplierId,
        name: data.name,
        type_id: +data?.type?.value,
        email: data.email,
        phone: data.phone,
        mobile: data.mobile,
        comment: data.comment,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: allSupplierContactsQuery,
          variables: { supplier_id: params.supplierId },
        },
      ],
      onCompleted: () => {
        close();
        setNotification({
          title: t('global.success'),
          message: t('supplier.contacts.form.add-contact-success'),
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('supplier.contacts.add-contact')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={close}
      loading={addLoading}
      error={addError}>
      <div className="flex flex-col space-y-8">
        <InputTextControl
          control={control}
          name="name"
          label={t('supplier.contacts.form.name')}
          isRequired
        />
        <SelectControl
          control={control}
          name="type"
          label={t('supplier.contacts.form.type')}
          options={options}
          loadingOptions={loading}
          apolloError={error}
          isRequired
        />
        <InputTextControl
          control={control}
          name="email"
          label={t('supplier.contacts.form.email')}
        />
        <InputTextControl
          control={control}
          name="mobile"
          label={t('supplier.contacts.form.mobile')}
        />
        <InputTextControl
          control={control}
          name="phone"
          label={t('supplier.contacts.form.phone')}
        />
        <InputTextAreaControl
          control={control}
          name={'comment'}
          label={t('supplier.contacts.form.more-information')}
          rows={3}
        />
      </div>
    </SlideOver>
  );
};
export default AddContact;
