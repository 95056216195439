import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ProductFeature } from 'types/Product';
import Tippy from '@tippyjs/react';

type Props = {
  feature: ProductFeature;
  setOpenUpdateFeature: React.Dispatch<React.SetStateAction<boolean>>;
  setFeatureToUpdate: React.Dispatch<React.SetStateAction<ProductFeature | null>>;
  setOpenDeleteFeature: React.Dispatch<React.SetStateAction<boolean>>;
  setFeatureToDelete: React.Dispatch<React.SetStateAction<ProductFeature | null>>;
};

const Feature = ({
  feature,
  setOpenUpdateFeature,
  setFeatureToUpdate,
  setOpenDeleteFeature,
  setFeatureToDelete,
}: Props) => {
  const { t } = useTranslation();

  const openUpdateFeature = () => {
    setOpenUpdateFeature(true);
    setFeatureToUpdate(feature);
  };
  const openDeleteFeature = () => {
    setOpenDeleteFeature(true);
    setFeatureToDelete(feature);
  };

  return (
    <tr>
      <td className="py-3.5 pr-3 font-semibold">
        <div className="w-fit ">
          <Tippy content={feature.label?.text}>
            <div className="max-w-[50px] truncate md:max-w-[100px] lg:max-w-[150px] xl:max-w-xs 2xl:max-w-sm">
              {feature.label?.text}
            </div>
          </Tippy>
        </div>
      </td>
      <td className="table-cell px-3 py-3.5">
        <div className="w-fit ">
          <Tippy content={`${feature.value?.text} ${feature.unit?.text}`}>
            <div className="max-w-[50px] truncate md:max-w-[100px] lg:max-w-[150px] xl:max-w-xs 2xl:max-w-sm">
              {feature.value?.text} {feature.unit?.text}
            </div>
          </Tippy>
        </div>
      </td>
      <td className="flex justify-end gap-3 px-3 py-3.5">
        <Tippy content={t('global.actions.modify')}>
          <PencilSquareIcon
            className="w-6 cursor-pointer text-secondary outline-none hover:text-dark"
            onClick={openUpdateFeature}
          />
        </Tippy>
        <Tippy content={t('global.actions.delete')}>
          <TrashIcon
            className="w-6 cursor-pointer text-secondary outline-none hover:text-red-600"
            onClick={openDeleteFeature}
          />
        </Tippy>
      </td>
    </tr>
  );
};
export default Feature;
