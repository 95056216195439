import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import SelectCreatable from 'components/Common/SelectCreatable';
import { useTranslation } from 'react-i18next';
import { useMutation, useLazyQuery } from '@apollo/client';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { theme, customStyle, errorCustomStyle } from 'components/Style/customStyle';
import useNotification from 'hooks/useNotification';
import DeleteMaterial from './DeleteMaterial';
import { allMaterialsQuery } from 'graphql/Queries/Product/MaterialsAndColors/allMaterials';
import { createMaterialMutation } from 'graphql/Mutations/Product/MaterialsAndColors/createMaterial';
import { ProductMaterial } from 'types/Product';
import { OptionProps } from 'types/Global';
import { updateMaterialMutation } from 'graphql/Mutations/Product/MaterialsAndColors/updateMaterial';
import { updateMaterialStatusMutation } from 'graphql/Mutations/Product/MaterialsAndColors/updateMaterialStatus';
import { InformationAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';

type MaterialOption = {
  parent: OptionProps | null;
  children: ProductMaterial[];
  isActive: boolean;
  isUsed: boolean;
} & OptionProps;

type FormProps = {
  label: string;
  parent: OptionProps | null;
};

const Materials = () => {
  const { t } = useTranslation();

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditMaterial, setShowEditMaterial] = useState(false);
  const [showNewMaterial, setShowNewMaterial] = useState(false);
  const [allMaterials, setAllMaterials] = useState<MaterialOption[]>([]);
  const [material, setMaterial] = useState<MaterialOption | null>(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [activationChanging, setActivationChanging] = useState(false);

  const [getAllMaterials, { data: dataMaterials, loading: loadingMaterials }] = useLazyQuery(
    allMaterialsQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (!dataMaterials) return;
    setAllMaterials(
      dataMaterials.materials?.map((material: ProductMaterial) => {
        return {
          key: material.id,
          value: material.id,
          label: material.label,
          parent: material.parent
            ? {
                value: material.parent.id,
                label: material.parent.label,
              }
            : null,
          children: material.children,
          isActive: material.is_active,
          isUsed: material.isUsed,
        };
      })
    );
  }, [dataMaterials, setAllMaterials]);

  const [createMaterial, { loading: createMaterialLoading }] = useMutation(createMaterialMutation);

  const [updateMaterial, { loading: updateMaterialLoading }] = useMutation(updateMaterialMutation);
  const [updateMaterialStatus, { loading: updateMaterialStatusLoading }] = useMutation(
    updateMaterialStatusMutation
  );

  const onChangeMaterial = (value: unknown) => {
    if (!value) return;
    const typedValue = value as MaterialOption;
    setShowEditMaterial(true);
    setShowNewMaterial(false);
    setMaterial(typedValue);
    reset({
      label: typedValue.label,
      parent: typedValue.parent,
    });
  };

  const onCreateMaterial = (value: string) => {
    setShowEditMaterial(false);
    setShowNewMaterial(true);
    setMaterial({
      value: value,
      label: value,
      parent: null,
      children: [],
      isActive: true,
      isUsed: false,
    });
    reset({
      label: value,
      parent: null,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1).required(t('form.required')),
    parent: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .nullable(),
  });

  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { isDirty, errors },
  } = useForm<FormProps>({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
      parent: null,
    },
  });

  const onCancel = () => {
    setShowNewMaterial(false);
    setMaterial(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = handleSubmit((data) => {
    if (showNewMaterial) {
      createMaterial({
        refetchQueries: [
          {
            query: allMaterialsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          label: data.label,
          parent_id: data.parent?.value,
        },
        onCompleted: (dataReturn) => {
          const data = dataReturn.createProductMaterial;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.materials-and-colors.materials.created'),
            type: 'success',
          });
          reset({
            label: data.label,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
          });
          setMaterial({
            value: data.id,
            label: data.label,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
            children: [],
            isActive: true,
            isUsed: false,
          });
          setShowEditMaterial(true);
          setShowNewMaterial(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      if (!material) return;
      updateMaterial({
        refetchQueries: [
          {
            query: allMaterialsQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          id: material.value,
          label: data.label,
          parent_id: data.parent?.value ?? null,
        },
        onCompleted: (dataReturn) => {
          const data = dataReturn.updateProductMaterial;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.materials-and-colors.materials.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
          });
          setMaterial({
            value: data.id,
            label: data.label,
            parent: data.parent
              ? {
                  value: data.parent.id,
                  label: data.parent.label,
                }
              : null,
            children: data.children,
            isActive: data.is_active,
            isUsed: data.isUsed,
          });
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  });

  const resetAfterDelete = () => {
    setMaterial(null);
    setShowEditMaterial(false);
    setShowNewMaterial(false);
  };

  const changeActivation = (value: boolean) => {
    if (!material) return;
    setActivationChanging(true);
    let message = '';
    if (value) {
      message = t('settings.products.materials-and-colors.materials.activate');
    } else {
      message = t('settings.products.materials-and-colors.materials.deactivate');
    }
    updateMaterialStatus({
      refetchQueries: [
        {
          query: allMaterialsQuery,
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: material.value,
        is_active: value,
      },
      onCompleted: (dataReturn) => {
        setActivationChanging(false);
        const data = dataReturn.updateProductMaterial;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
          parent: data.parent
            ? {
                value: data.parent.id,
                label: data.parent.label,
              }
            : null,
        });
        setMaterial({
          value: data.id,
          label: data.label,
          parent: data.parent
            ? {
                value: data.parent.id,
                label: data.parent.label,
              }
            : null,
          children: data.children,
          isActive: data.is_active,
          isUsed: data.isUsed,
        });
      },
      onError: (error) => {
        setActivationChanging(false);
        setError(error);
      },
    });
  };

  const getPlaceHolder = () => {
    if (loadingMaterials) {
      return t('global.form.loading');
    }
    if (allMaterials.length === 0) {
      return t('global.form.load');
    }
    return t('global.form.select');
  };

  return (
    <>
      <DeleteMaterial
        onCloseModal={(loading: boolean) => {
          if (!loading) {
            setDisplayModal(false);
          }
        }}
        isOpen={displayModal}
        material={material}
        reset={resetAfterDelete}
      />
      <div className="col-span-2 h-fit max-h-[45rem] 2xl:col-span-1">
        <Card>
          <div>
            <div className="p-4">
              <h3 className="text-lg font-medium text-blue-gray-900">
                {t('settings.products.materials-and-colors.materials.title')}
              </h3>
              <p className="mt-1 text-sm text-blue-gray-500">
                {t('settings.products.materials-and-colors.materials.subtitle')}
              </p>
            </div>
            <div className="p-4">
              <label className="text-sm font-medium text-gray-700">
                {t('settings.products.materials-and-colors.materials.list')}
              </label>
              <SelectCreatable
                loadingOptions={loadingMaterials}
                placeholder={getPlaceHolder()}
                options={allMaterials}
                onCreateOption={onCreateMaterial}
                onChange={onChangeMaterial}
                value={material}
                onFocus={() => {
                  if (!allMaterials || allMaterials.length === 0) {
                    getAllMaterials();
                  }
                }}
              />
            </div>
            {(showEditMaterial || showNewMaterial) && (
              <form onSubmit={onSubmit}>
                <div className="space-y-4 p-4">
                  <div>
                    <label className="text-sm font-medium text-gray-700">
                      {t('settings.products.materials-and-colors.designation')}
                    </label>
                    <div className="relative">
                      <input
                        type="text"
                        className={classNames(
                          errors.label
                            ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                            : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                          'mt-1 block h-9 w-full flex-1 rounded'
                        )}
                        {...register('label')}
                      />
                      {errors.label && (
                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                          <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                        </div>
                      )}
                    </div>
                    {errors.label && (
                      <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>
                    )}
                  </div>

                  <div>
                    <label className="text-sm font-medium text-gray-700">
                      {t('settings.products.materials-and-colors.materials.group')}
                    </label>
                    <Controller
                      name="parent"
                      control={control}
                      render={({ field }) => (
                        <Select
                          {...field}
                          theme={theme}
                          className="mt-1"
                          styles={errors ? customStyle : errorCustomStyle}
                          isDisabled={loadingMaterials}
                          placeholder={
                            loadingMaterials ? t('global.form.loading') : t('global.form.select')
                          }
                          options={allMaterials}
                          isClearable
                          noOptionsMessage={() => t('global.form.no-option')}
                        />
                      )}
                    />
                  </div>

                  {material?.isUsed && (
                    <div className="mt-4">
                      <InformationAlert>
                        {t('settings.products.materials-and-colors.materials.used')}
                      </InformationAlert>
                    </div>
                  )}
                  {material?.isActive && material?.children.length > 0 && (
                    <div className="mt-4">
                      <InformationAlert>
                        {t('settings.products.materials-and-colors.materials.deactivation-warning')}
                      </InformationAlert>
                    </div>
                  )}
                  {material?.isActive === false && material?.parent && (
                    <div className="mt-4">
                      <InformationAlert>
                        {t('settings.products.materials-and-colors.materials.activation-warning')}
                      </InformationAlert>
                    </div>
                  )}
                </div>
                <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                  {showEditMaterial && (
                    <>
                      <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                        <Button
                          type="button"
                          disabled={updateMaterialLoading || material?.isUsed}
                          onClick={() => setDisplayModal(true)}
                          theme="outlineDanger">
                          {t('global.actions.delete')}
                        </Button>
                        {material?.isActive ? (
                          <Button
                            type="button"
                            theme="outlinePrimary"
                            disabled={updateMaterialLoading || updateMaterialStatusLoading}
                            loading={activationChanging}
                            onClick={() => changeActivation(false)}>
                            {t('global.actions.deactivate')}
                          </Button>
                        ) : (
                          <Button
                            type="button"
                            theme="outlinePrimary"
                            disabled={updateMaterialLoading || updateMaterialStatusLoading}
                            loading={activationChanging}
                            onClick={() => changeActivation(true)}>
                            {t('global.actions.activate')}
                          </Button>
                        )}
                        <Button
                          disabled={updateMaterialLoading || !isDirty}
                          type="submit"
                          theme="primary"
                          loading={updateMaterialLoading}>
                          {t('global.actions.save')}
                        </Button>
                      </div>
                    </>
                  )}
                  {showNewMaterial && (
                    <>
                      <Button
                        type="button"
                        theme="transparent"
                        disabled={createMaterialLoading}
                        onClick={onCancel}>
                        {t('global.actions.cancel')}
                      </Button>
                      <Button
                        disabled={createMaterialLoading}
                        type="submit"
                        theme="primary"
                        loading={createMaterialLoading}>
                        {t('global.actions.add')}
                      </Button>
                    </>
                  )}
                </div>
              </form>
            )}
          </div>
        </Card>
      </div>
    </>
  );
};

export default Materials;
