import React from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { PhotoIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@apollo/client';
import { productCombinationQuery } from 'graphql/Queries/Product/ProductCombination/productCombination';
import { deleteProductCombinationMutation } from 'graphql/Mutations/Product/ProductCombination/deleteProductCombination';
import { ID } from 'types/Global';
import Modal from 'components/Common/Modal';
import useCheckUrlImage from 'components/Products/Hooks/useCheckUrlImage';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  img?: string;
  title: string;
  constituentProductId: ID;
  combinationId?: ID;
};

const DeleteConstituent = ({
  setOpen,
  open,
  img,
  title,
  constituentProductId,
  combinationId,
}: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();

  const [deleteProductCombination, { loading, error, reset }] = useMutation(
    deleteProductCombinationMutation
  );

  const isValidImage = useCheckUrlImage(img);

  const { handleSubmit } = useForm<FieldValues>();

  const close = () => {
    if (!loading) {
      setOpen(false);
      reset();
    }
  };

  const onSubmit = handleSubmit(() => {
    deleteProductCombination({
      variables: {
        id: combinationId,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productCombinationQuery,
          variables: { id: params.productId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.product-combination.notif.delete-success'),
          type: 'success',
        });
        close();
      },
    });
  });
  return (
    <Modal
      theme="danger"
      title={t('product.product-combination.delete')}
      buttonText={t('global.actions.delete')}
      open={open}
      loading={loading}
      error={error}
      icon={<TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
      onClose={() => setOpen(false)}
      onSubmit={onSubmit}>
      <div className="mt-6">
        <span>{t('product.product-combination.watch-out-deleting-product')}</span>
        <div className="my-6 flex items-center justify-start ">
          {isValidImage ? (
            <img src={img} className="mr-4 h-10 object-cover" />
          ) : (
            <PhotoIcon className="mr-4 h-10 w-10 text-secondary" />
          )}
          <span className="w-full font-semibold text-dark">
            {title} - {constituentProductId}
          </span>
        </div>
        <span className="text-red-600">{t('product.product-combination.confirm-delete')}</span>
      </div>
    </Modal>
  );
};
export default DeleteConstituent;
