import { gql } from 'graphql.macro';

export const updateCoordinatesMutation = gql`
  mutation UpdateCoordinates(
    $id: ID!
    $billing_name: String!
    $street: String
    $complementary_address: String
    $city: String
    $zipcode: String
    $country_id: Int
    $vat_number: String
  ) {
    updateSupplier(
      id: $id
      billing_name: $billing_name
      street: $street
      complementary_address: $complementary_address
      city: $city
      zipcode: $zipcode
      country_id: $country_id
      vat_number: $vat_number
    ) {
      id
      billing_name
      street
      complementary_address
      city
      zipcode
      vat_number
      country {
        id
        name
      }
    }
  }
`;
