import { gql } from 'graphql.macro';

export const updatePaymentMethodWithSortMutation = gql`
  mutation UpdatePaymentMethodWithSort($id: ID!, $label: String!, $sort: Int!) {
    updateSupplierPaymentMethodWithSort(id: $id, label: $label, sort: $sort) {
      id
      label
      sort
      active
    }
  }
`;
