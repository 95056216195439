import React from 'react';
import { useTranslation } from 'react-i18next';
import { useProductContextType } from 'components/Contexts/ProductContext';
import HistoryPurchasePrice from './HistoryPurchasePrice/Index';
import SupplierInformations from './Informations/SupplierInformations';
import Title from 'components/Common/Title';
import PurchaseInformation from './Comment/PurchaseInformation';

function ProductPurchaseInformation() {
  const { t } = useTranslation();

  const { productExists } = useProductContextType();
  if (!productExists) return <></>;

  return (
    <>
      <Title title={t('product.purchase-information.title')} />
      <div className="flex flex-col space-y-6">
        <SupplierInformations />
        <PurchaseInformation />
        <HistoryPurchasePrice />
      </div>
    </>
  );
}

export default ProductPurchaseInformation;
