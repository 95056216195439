import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/Product';
import clsx from 'clsx';
import Card from 'components/Common/Card';

type Props = {
  loading: boolean;
  product?: Product;
};

const Stock = ({ loading, product }: Props) => {
  const { t } = useTranslation();

  const getTextColor = (value?: number) => {
    if (!value) return 'text-secondary';
    if (value < 0) return 'text-red-600';
    if (value > 0) return 'text-green-500';
  };

  return (
    <Card title={t('product.preview.stock.title')}>
      <div className="grid grid-cols-1 gap-6 xl:grid-cols-3">
        {!product?.isCombination && (
          <>
            <Card>
              <div>
                <p className="text-sm font-semibold text-dark">
                  {t('product.preview.stock.warehouse')}
                </p>
                {loading ? (
                  <div className="skeleton h-8 w-44" />
                ) : (
                  <p className={clsx('text-2xl font-medium', getTextColor(product?.physicalStock))}>
                    {product?.physicalStock ?? '-'}
                  </p>
                )}
              </div>
            </Card>
            <Card>
              <div>
                <p className="text-sm font-semibold text-dark">
                  {t('product.preview.stock.order-in-progress')}
                </p>
                {loading ? (
                  <div className="skeleton h-8 w-44" />
                ) : (
                  <p
                    className={clsx(
                      'text-2xl font-medium',
                      getTextColor(product?.reservedQuantity)
                    )}>
                    {product?.reservedQuantity ?? '-'}
                  </p>
                )}
              </div>
            </Card>
          </>
        )}
        <div className={`${product?.isCombination && 'col-span-3'}`}>
          <Card>
            <div>
              <p className="text-sm font-semibold text-dark">
                {t('product.preview.stock.available-stock')}
              </p>
              {loading ? (
                <div className="skeleton h-8 w-44" />
              ) : (
                <p className={clsx('text-2xl font-medium', getTextColor(product?.virtualStock))}>
                  {product?.virtualStock ?? '-'}
                </p>
              )}
            </div>
          </Card>
        </div>
      </div>
    </Card>
  );
};

export default Stock;
