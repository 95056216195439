import React, { useContext, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ecomobilierQuery } from 'graphql/Queries/Ecotax/ecomobilier';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { ActiveTaxContext } from '../Context/ActiveTaxContext';
import { EcomobilierData, Product } from 'types/Product';
import Badge from 'components/Common/Badge';
import Ecomobilier from './EcomobilierForm';
import EcomobilierSkeleton from './EcomobilierSkeleton';
import clsx from 'clsx';

type Props = {
  productId: string;
};

const Index = ({ productId }: Props) => {
  const { ecoMobilierStatus, setEcomobilier } = useContext(ActiveTaxContext);
  const { t } = useTranslation();

  const [futureEcoMobilierAmount, setFutureEcoMobilierAmount] = useState<number | null>(null);
  const [futureEcoMobilierLoading, setFutureEcoMobilierLoading] = useState(false);

  const { loading, data, error } = useQuery(ecomobilierQuery, {
    variables: { id: parseInt(productId) },
    fetchPolicy: 'network-only',
  });

  const product: Product | undefined = data?.product;

  let ecomobilierData: EcomobilierData | null = null;
  if (!loading && product) {
    ecomobilierData = {
      category: product.ecoMobilier?.category
        ? {
            value: product.ecoMobilier.category.id,
            code: product.ecoMobilier.category.code,
          }
        : { value: null, code: null },
      type: product.ecoMobilier?.productType
        ? {
            value: product.ecoMobilier.productType.id,
            code: product.ecoMobilier.productType.code,
          }
        : { value: null, code: null },
      material: product.ecoMobilier?.material
        ? {
            value: product.ecoMobilier.material.id,
            code: product.ecoMobilier.material.code,
          }
        : { value: null, code: null },
      padding: product.ecoMobilier?.material.isStuffing ?? false,
      size: product.ecoMobilier?.size
        ? {
            value: product.ecoMobilier?.size.id,
            code: product.ecoMobilier?.size.code,
          }
        : { value: null, code: null },
      enable: product.ecoMobilier?.enable,
      paidInAdvance: product.ecoMobilier?.payed_before,
    };
  }

  const isConstituent = (product?.constituents?.length ?? 0) > 0;

  useLayoutEffect(() => {
    if (data && ecomobilierData !== undefined && ecomobilierData?.enable !== undefined) {
      if (ecomobilierData.enable !== ecoMobilierStatus) {
        setEcomobilier(ecomobilierData.enable);
      }
    }
  }, [data, ecomobilierData, ecoMobilierStatus, setEcomobilier]);

  const changeFutureEcoMobilier = (
    value: number | null,
    submitPossible: boolean,
    isDirty: boolean
  ) => {
    if (!submitPossible || !isDirty) {
      value = null;
    }
    setFutureEcoMobilierAmount(value);
    setFutureEcoMobilierLoading(false);
  };

  const changeFutureEcoMobilierLoading = (value: boolean) => {
    setFutureEcoMobilierLoading(value);
  };

  const isEnable = ecomobilierData?.enable ? 'border-2 border-primary-500' : '';

  return (
    <div className={clsx('bg-white shadow sm:rounded-md', isEnable)}>
      <div className="mb-5 h-24 border-b-2 px-6 py-4">
        <div className="flex justify-between">
          <h2 className="text-lg font-medium leading-6 text-dark">
            {t('product.ecotax.title-eco')}
          </h2>
          {!loading && ecoMobilierStatus !== null ? (
            <Badge type={ecoMobilierStatus ? 'success' : 'warning'}>
              {ecoMobilierStatus
                ? t('product.ecotax.notify.active')
                : t('product.ecotax.notify.inactive')}
            </Badge>
          ) : (
            !loading &&
            ecoMobilierStatus === null && (
              <Badge type="danger">{t('product.ecotax.notify.inexist')}</Badge>
            )
          )}
        </div>
        <div className="mt-2 grid w-full">
          <div className="flex items-center gap-5 ">
            <div className="flex w-full items-center gap-4 text-2xl font-semibold text-primary-500">
              {!loading && `${product?.ecoMobilier?.amount?.including_taxes_value ?? 0} €`}
              {loading && <div className="skeleton h-[32px] w-[48px]"></div>}
              {futureEcoMobilierAmount !== null && !futureEcoMobilierLoading && (
                <>
                  <div className="flex  items-center">
                    <Badge type="secondary">
                      <div className="flex gap-2">
                        <div>{t('product.ecotax.notify.estimate')}</div>
                        <div className="font-semibold">{`${futureEcoMobilierAmount} €`}</div>
                      </div>
                    </Badge>
                  </div>
                </>
              )}
              {futureEcoMobilierLoading && (
                <div className="flex justify-center">
                  <div className="skeleton h-5 w-28"></div>
                </div>
              )}
            </div>
            {isConstituent && (
              <div className="flex w-64 justify-end">
                <div className="truncate text-sm text-dark">{t('product.ecotax.total-pack')} :</div>
                <div className="ml-1 flex-shrink-0 text-sm font-semibold text-dark">
                  {product?.totalChildrenEcomobilierAmount} €
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {error && (
        <div className="my-2 p-2">
          <ApolloErrorAlert error={error} />
        </div>
      )}
      {!loading && !error && (
        <Ecomobilier
          productId={productId}
          initialFormValues={ecomobilierData}
          changeFutureEcoMobilier={changeFutureEcoMobilier}
          changeFutureEcoMobilierLoading={changeFutureEcoMobilierLoading}
        />
      )}
      {loading && <EcomobilierSkeleton />}
    </div>
  );
};

export default Index;
