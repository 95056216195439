import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const ProductLayoutNavigation = () => {
  const { t } = useTranslation();
  const params = useParams();

  const navigation = [
    {
      name: t('product.menu.preview'),
      href: `/products/${params.productId}/preview`,
    },
    {
      name: t('product.menu.editorial'),
      href: `/products/${params.productId}/editorial`,
    },
    {
      name: t('product.menu.taxonomy'),
      href: `/products/${params.productId}/taxonomy`,
    },
    {
      name: t('product.menu.model-style'),
      href: `/products/${params.productId}/model-style`,
    },
    {
      name: t('product.menu.sticker'),
      href: '/products/' + params.productId + '/sticker',
    },
    {
      name: t('product.menu.images'),
      href: `/products/${params.productId}/photos`,
    },
    {
      name: t('product.menu.materials-and-colors'),
      href: `/products/${params.productId}/materials-and-colors`,
    },
    {
      name: t('product.menu.features'),
      href: `/products/${params.productId}/features`,
    },
    {
      name: t('product.menu.documents'),
      href: `/products/${params.productId}/documents`,
    },
    {
      name: t('product.menu.visibility'),
      href: `/products/${params.productId}/visibility`,
    },
    {
      name: t('product.menu.customs-nomenclature'),
      href: `/products/${params.productId}/customs-nomenclature`,
    },
    {
      name: t('product.menu.brand'),
      href: `/products/${params.productId}/brand`,
    },
    {
      name: t('product.menu.offers'),
      href: `/products/${params.productId}/offers`,
    },

    {
      name: t('product.menu.ecotax'),
      href: `/products/${params.productId}/ecotax`,
    },
    {
      name: t('product.menu.product-combination'),
      href: `/products/${params.productId}/combinations`,
    },
    {
      name: t('product.menu.packaging'),
      href: `/products/${params.productId}/packaging`,
    },

    {
      name: t('product.menu.purchase-information'),
      href: `/products/${params.productId}/purchase-information`,
    },
  ];

  return navigation;
};
export default ProductLayoutNavigation;
