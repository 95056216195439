import React, { forwardRef, useEffect, Ref, ComponentPropsWithRef } from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { Control, useController } from 'react-hook-form';

type Props = {
  control: Control;
  label?: string;
  name: string;
  isLoading?: boolean;
  rows?: number;
  isRequired?: boolean;
  isFocused?: boolean;
} & ComponentPropsWithRef<'textarea'>;

export const InputTextAreaControl = forwardRef(
  (
    { control, label, name, rows = 3, isLoading, isRequired, isFocused, ...props }: Props,
    ref: Ref<HTMLTextAreaElement>
  ) => {
    const { field, formState } = useController({ name, control });
    const { onChange } = field;

    useEffect(() => {
      if (isFocused && ref && 'current' in ref && ref.current) {
        ref.current.focus();
      }
    }, [ref, isFocused]);

    return (
      <div>
        {label && (
          <label htmlFor={name} className="mb-2  block text-sm font-medium leading-6 text-dark">
            {label}
            {isRequired && <span className="ml-1 text-red-700">*</span>}
          </label>
        )}
        {isLoading ? (
          <div className="skeleton h-20" />
        ) : (
          <div className="relative">
            <textarea
              ref={ref}
              id={name}
              value={field.value}
              rows={rows}
              name={name}
              onChange={onChange}
              className={`              
                ${
                  formState?.errors[name]
                    ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                    : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500'
                }
                block w-full rounded-md py-1.5 text-dark shadow-sm placeholder:text-gray-400 sm:text-sm sm:leading-6`}
              {...props}
            />
            {formState?.errors[name] && (
              <div className="pointer-events-none absolute inset-y-0 right-0 top-0 flex items-center pr-3">
                <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
              </div>
            )}
          </div>
        )}
        {formState?.errors[name] && (
          <p className="mt-2 text-sm text-red-700">{formState?.errors[name]?.message as string}</p>
        )}
      </div>
    );
  }
);
InputTextAreaControl.displayName = 'InputTextAreaControl';
