import React, { createContext, useContext, ReactNode, useState } from 'react';

interface ProductContextType {
  productExists: boolean;
  setProductExists: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ProductProviderProps {
  children: ReactNode;
}

const ProductContext = createContext<ProductContextType | null>(null);

export function useProductContextType() {
  const context = useContext(ProductContext);
  if (!context) {
    throw new Error('useProductContextType must be used within a ProductProvider');
  }
  return context;
}

export function ProductProvider({ children }: ProductProviderProps) {
  const [productExists, setProductExists] = useState(true);

  return (
    <ProductContext.Provider value={{ productExists, setProductExists }}>
      {children}
    </ProductContext.Provider>
  );
}
