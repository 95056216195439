import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { Image } from 'types/Product';
import { allImagesQuery } from 'graphql/Queries/Product/Images/Types/allImages';
import { IMAGE_TYPE } from 'utils/imageType';
import { uploadProductImageTypeMutation } from 'graphql/Mutations/Product/Images/updateProductImageType';
import SlideOver from 'components/Common/SlideOver';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  image?: Image | null;
  alreadyEnergyImage: boolean;
  alreadyDimensionImage: boolean;
};

const TagImage = ({ open, setOpen, image, alreadyEnergyImage, alreadyDimensionImage }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [uploadProductImageType, { loading, error }] = useMutation(uploadProductImageTypeMutation);

  const { handleSubmit } = useForm();
  const close = () => {
    setOpen(false);
  };

  const [selectedType, setSelectedType] = useState<number | null>(null);

  const requiredImageTypeIds = [4, 3, 5, 6];

  const imageTypeToTranslationKey = {
    [IMAGE_TYPE.AMBIANCE_IMAGE]: 'ambiance',
    [IMAGE_TYPE.DIMENSIONS_IMAGE]: 'dimensions',
    [IMAGE_TYPE.ENERGY_CLASS_IMAGE]: 'energy',
    [IMAGE_TYPE.MISCELLANEOUS_IMAGE]: 'miscellaneous',
  };

  const determineCheckedType = (image: Image) => {
    if (image.is_dimensions) return IMAGE_TYPE.DIMENSIONS_IMAGE;
    if (image.is_ambiance) return IMAGE_TYPE.AMBIANCE_IMAGE;
    if (image.is_energy_class) return IMAGE_TYPE.ENERGY_CLASS_IMAGE;
    if (image.is_miscellaneous) return IMAGE_TYPE.MISCELLANEOUS_IMAGE;
    return null;
  };

  useEffect(() => {
    if (!image) return;
    setSelectedType(determineCheckedType(image));
  }, [image]);

  const onSubmit = handleSubmit(() => {
    uploadProductImageType({
      variables: {
        id: image?.id,
        type_id: selectedType,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: allImagesQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.images.notif.update-image-tag-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <>
      <SlideOver
        open={open}
        setOpen={setOpen}
        title={t('product.images.image-information')}
        buttonText={t('global.actions.save')}
        onSubmit={onSubmit}
        onCancel={close}
        loading={loading}
        error={error}>
        <>
          <div className="group relative flex h-56 items-center justify-center overflow-hidden rounded-md bg-white p-4">
            <img
              src={image?.clientUrl}
              alt={`Image ${image?.id}`}
              className="h-full w-auto object-cover object-center transition-opacity duration-300"
            />
          </div>
          <fieldset className="mt-16">
            <legend className="mb-6 block text-sm font-medium leading-6 text-dark">
              {t('product.images.choose-image-type')}
            </legend>

            <div className="space-y-2">
              {requiredImageTypeIds.map((typeId, index) => {
                return (
                  <div className="relative flex items-start" key={index}>
                    <div className="flex h-6 items-center">
                      <input
                        id={`type-${typeId}`}
                        name="imageType"
                        type="radio"
                        className="h-4 w-4 border-gray-300 text-primary-500 focus:ring-primary-500"
                        value={typeId}
                        checked={typeId === selectedType}
                        onChange={() => {
                          setSelectedType(typeId);
                        }}
                        disabled={
                          (typeId === IMAGE_TYPE.ENERGY_CLASS_IMAGE && alreadyEnergyImage) ||
                          (typeId === IMAGE_TYPE.DIMENSIONS_IMAGE && alreadyDimensionImage)
                        }
                      />
                    </div>
                    <div className="ml-3 text-sm leading-6">
                      <label
                        htmlFor={`type-${typeId}`}
                        className={`font-normal ${
                          (typeId === IMAGE_TYPE.ENERGY_CLASS_IMAGE && alreadyEnergyImage) ||
                          (typeId === IMAGE_TYPE.DIMENSIONS_IMAGE && alreadyDimensionImage)
                            ? 'text-light'
                            : 'text-dark'
                        } `}>
                        {t(`product.images.type.${imageTypeToTranslationKey[typeId] || ''}`)}
                      </label>
                    </div>
                  </div>
                );
              })}
            </div>
          </fieldset>
        </>
      </SlideOver>
    </>
  );
};
export default TagImage;
