import { gql } from 'graphql.macro';

export const updateCommercialDescriptionMutation = gql`
  mutation UpdateCommercialDescription($id: ID!, $commercial_description: String) {
    updateProductTranslation(id: $id, commercial_description: $commercial_description) {
      id
      commercial_description
    }
  }
`;
