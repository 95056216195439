import React from 'react';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import Badge from 'components/Common/Badge';
import { DEFAULT_PACKAGE_NAME } from 'global';
import { Product, ProductPackageSize } from 'types/Product';

type Props = {
  number: number;
  product: Product;
  packageSize: ProductPackageSize;
  setPackageSize: React.Dispatch<React.SetStateAction<ProductPackageSize | null>>;
  setOpenUpdatePackageSize: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDeletePackageSize: React.Dispatch<React.SetStateAction<boolean>>;
};

const PackageSize = ({
  number,
  product,
  packageSize,
  setPackageSize,
  setOpenUpdatePackageSize,
  setOpenDeletePackageSize,
}: Props) => {
  const { t } = useTranslation();

  let name;
  if (product.isSinglePackage) {
    name = DEFAULT_PACKAGE_NAME;
  } else {
    name = packageSize.package?.name;
  }

  let weight;
  if (product.isSinglePackage) {
    weight = product.weight;
  } else {
    weight = packageSize.package?.weight;
  }
  weight = weight >= 1000 ? `${weight / 1000} kg` : `${weight} g`;

  let volume: number | string = (
    (packageSize.depth / 100) *
    (packageSize.width / 100) *
    (packageSize.height / 100)
  ).toFixed(3);
  volume = parseFloat(volume);

  const category = packageSize.type ? packageSize.type.label : '-';

  return (
    <>
      <td className="px-3 py-3.5">{number}</td>
      <td className="px-3 py-3.5">{name}</td>
      <td className="hidden px-3 py-3.5 xl:table-cell">
        {`${packageSize.depth} cm x ${packageSize.width} cm x ${packageSize.height} cm`}
      </td>
      <td className="px-3 py-3.5">{`${volume} m³`}</td>
      <td className="px-3 py-3.5">{weight}</td>
      <td className="hidden px-3 py-3.5 2xl:table-cell">
        <p>{category}</p>
        {packageSize.quantity_by_pallet !== null && (
          <p className="font-normal italic">
            {t('global.qty')}
            {` : ${packageSize.quantity_by_pallet}`}
          </p>
        )}
      </td>
      <td className="hidden px-3 py-6 2xl:table-cell">
        {packageSize.to_repack ? (
          <Badge type="success">{t('global.yes')}</Badge>
        ) : (
          <Badge type="danger">{t('global.no')}</Badge>
        )}
      </td>

      <td className="px-3 py-3.5">
        <div className="flex justify-end gap-3">
          <Tippy content={t('global.actions.modify')}>
            <PencilSquareIcon
              className="w-6 cursor-pointer text-secondary outline-none hover:text-dark"
              onClick={() => {
                setOpenUpdatePackageSize(true);
                setPackageSize(packageSize);
              }}
            />
          </Tippy>
          <Tippy content={t('global.actions.delete')}>
            <TrashIcon
              className="w-6 cursor-pointer text-secondary outline-none hover:text-red-600"
              onClick={() => {
                setOpenDeletePackageSize(true);
                setPackageSize(packageSize);
              }}
            />
          </Tippy>
        </div>
      </td>
    </>
  );
};

export default PackageSize;
