import { useTranslation } from 'react-i18next';

const HelperTextTaxonomie = () => {
  const { t } = useTranslation();
  return (
    <ul role="list" className="list-disc space-y-1 pl-5">
      <li>{t('product.taxonomy.helpers.taxonomy-help1')}</li>
      <li>{t('product.taxonomy.helpers.taxonomy-help2')}</li>
      <li>{t('product.taxonomy.helpers.taxonomy-help3')}</li>
      <li>{t('product.taxonomy.helpers.taxonomy-help4')}</li>
      <li>{t('product.taxonomy.helpers.taxonomy-help5')}</li>
      <li>{t('product.taxonomy.helpers.taxonomy-help6')}</li>
    </ul>
  );
};

export default HelperTextTaxonomie;
