import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

const SupplierLayoutNavigation = () => {
  const { t } = useTranslation();
  const params = useParams();

  const navigation = [
    {
      name: t('supplier.menu.general-informations'),
      href: `/suppliers/${params.supplierId}/general-informations`,
    },
    {
      name: t('supplier.menu.contacts'),
      href: `/suppliers/${params.supplierId}/contacts`,
    },
    {
      name: t('supplier.menu.production-sites'),
      href: `/suppliers/${params.supplierId}/production-sites`,
    },
  ];

  return navigation;
};
export default SupplierLayoutNavigation;
