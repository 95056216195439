import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { customStyle, theme } from 'components/Style/customStyle';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteCollection from './DeleteCollection';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { withAsyncPaginate } from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';
import { InformationAlert } from 'components/Common/Alerts';
import { findProductCollectionsByOffsetQuery } from 'graphql/Queries/Product/ModelsAndCollections/findProductCollectionsByOffset';
import { findProductCollectionsByTextQuery } from 'graphql/Queries/Product/ModelsAndCollections/findProductCollectionsByText';
import { createCollectionMutation } from 'graphql/Mutations/Product/ModelsAndCollections/createCollection';
import { updateCollectionMutation } from 'graphql/Mutations/Product/ModelsAndCollections/updateCollection';
import { updateCollectionStatusMutation } from 'graphql/Mutations/Product/ModelsAndCollections/updateCollectionStatus';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const Collections = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditCollection, setShowEditCollection] = useState(false);
  const [showNewCollection, setShowNewCollection] = useState(false);
  const [collection, setCollection] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);
  const [optionsLoaded, setOptionsLoaded] = useState(0);
  const [activationChanging, setActivationChanging] = useState(false);
  const [key, setKey] = useState(0);

  const [findCollectionsByOffset] = useLazyQuery(findProductCollectionsByOffsetQuery);
  const [getProductCollectionsByText] = useLazyQuery(findProductCollectionsByTextQuery);

  const [createCollection, { loading: createCollectionLoading }] =
    useMutation(createCollectionMutation);

  const [updateCollection, { loading: updateCollectionLoading }] =
    useMutation(updateCollectionMutation);

  const [updateStatusCollection, { loading: updateCollectionStatusLoading }] = useMutation(
    updateCollectionStatusMutation
  );

  const onChangeCollection = (value) => {
    setShowEditCollection(true);
    setShowNewCollection(false);
    setCollection(value);
    reset({
      label: value.label,
    });
  };

  const onCreateCollection = (value) => {
    setShowEditCollection(false);
    setShowNewCollection(true);
    setCollection({
      value: value,
      label: value,
      active: true,
      isUsed: false,
    });
    reset({
      label: value,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
    },
  });

  const onCancel = () => {
    setShowNewCollection(false);
    setCollection(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewCollection) {
      createCollection({
        variables: {
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.createProductCollection;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.models-collections-styles.collections.created'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setCollection({
            value: data.id,
            label: data.label,
            active: data.active,
            isUsed: data.isUsed,
          });
          setShowEditCollection(true);
          setShowNewCollection(false);
          resetSelect();
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updateCollection({
        variables: {
          id: collection.value,
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.updateProductCollection;
          setNotification({
            title: t('global.success'),
            message: t('settings.products.models-collections-styles.collections.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setCollection({
            value: collection.value,
            label: data.label,
            active: data.active,
            isUsed: data.isUsed,
          });
          resetSelect();
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const resetSelect = () => {
    setOptionsLoaded(0);
    setKey(key + 1);
  };

  const resetAfterDelete = () => {
    setCollection(null);
    setShowEditCollection(false);
    setShowNewCollection(false);
    resetSelect();
  };

  const loadOptions = async (search) => {
    if (search) {
      const response = await getProductCollectionsByText({
        fetchPolicy: 'network-only',
        variables: {
          text: search,
        },
        onCompleted: (data) => {
          return Promise.resolve(data);
        },
        onError: () => {
          return Promise.resolve(null);
        },
      });

      if (!response.data) {
        return {
          options: [],
          hasMore: false,
        };
      }

      setOptionsLoaded(0);

      return {
        options: formatProductCollectionOptions(response.data.findProductCollectionsByText),
        hasMore: false,
      };
    } else {
      const response = await findCollectionsByOffset({
        fetchPolicy: 'network-only',
        variables: {
          offset: optionsLoaded,
          number: 50,
        },
        onCompleted: (data) => {
          return Promise.resolve(data);
        },
        onError: () => {
          return Promise.resolve(null);
        },
      });
      if (!response.data) {
        return {
          options: [],
          hasMore: false,
        };
      }

      let responseResult = formatProductCollectionOptions(
        response.data.findProductCollectionsByOffset
      );
      setOptionsLoaded((optionsLoaded) => optionsLoaded + 50);

      return {
        options: responseResult,
        hasMore: responseResult.length > 0,
      };
    }
  };

  const formatProductCollectionOptions = (allProductCollection) => {
    let allProductCollectionOptions = [];
    allProductCollection.map((productCollection) => {
      allProductCollectionOptions = [
        ...allProductCollectionOptions,
        {
          value: productCollection.id,
          label: productCollection.label ? productCollection.label : '-',
          active: productCollection.active,
          isUsed: productCollection.isUsed,
        },
      ];
    });
    return allProductCollectionOptions;
  };

  const changeActivation = (value) => {
    setActivationChanging(true);
    let message = '';
    if (value) {
      message = t('settings.products.models-collections-styles.collections.activate');
    } else {
      message = t('settings.products.models-collections-styles.collections.deactivate');
    }
    updateStatusCollection({
      variables: {
        id: collection.value,
        active: value,
      },
      onCompleted: (dataReturn) => {
        setActivationChanging(false);
        let data = dataReturn.updateProductCollection;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
        });
        setCollection({
          value: data.id,
          label: data.label,
          active: data.active,
          isUsed: data.isUsed,
        });
      },
      onError: (error) => {
        setActivationChanging(false);
        setError(error);
      },
    });
  };

  return (
    <>
      <DeleteCollection
        onCloseModal={() => setDisplayModal(false)}
        isOpen={displayModal}
        collection={collection}
        reset={resetAfterDelete}
      />
      <Card>
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.products.models-collections-styles.collections.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.products.models-collections-styles.collections.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.products.models-collections-styles.collections.list')}
          </label>
          <CreatableAsyncPaginate
            placeholder={t('settings.products.models-collections-styles.collections.placeholder')}
            loadOptions={loadOptions}
            loadingMessage={() => t('global.form.loading')}
            noOptionsMessage={() => t('global.no-option')}
            debounceTimeout={500}
            className="mt-1 block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm"
            styles={customStyle}
            theme={theme}
            formatCreateLabel={(inputValue) =>
              `${t(
                'settings.products.models-collections-styles.collections.create'
              )} : "${inputValue}"`
            }
            onCreateOption={onCreateCollection}
            onChange={onChangeCollection}
            value={collection}
            key={key}
            cacheUniq={key}
            menuPlacement="auto"
          />
        </div>
        {(showEditCollection || showNewCollection) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-4 sm:rounded-b-md">
              <label className="text-sm font-medium text-gray-700">
                {t('settings.products.models-collections-styles.collections.name')}
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={classNames(
                    errors.label
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                    'mt-1 block h-9 w-full flex-1 rounded'
                  )}
                  {...register('label')}
                />
                {errors.label && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                  </div>
                )}
              </div>
              {errors.label && <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>}
              {collection?.isUsed && (
                <div className="mt-4">
                  <InformationAlert>
                    {t('settings.products.models-collections-styles.collections.used')}
                  </InformationAlert>
                </div>
              )}
            </div>
            <div className="flex flex-shrink-0 flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
              {showEditCollection && (
                <>
                  <div className="grid grid-cols-3 gap-4">
                    <Button
                      type="button"
                      theme="outlineDanger"
                      onClick={() => setDisplayModal(true)}
                      disabled={updateCollectionLoading || collection?.isUsed}>
                      {t('global.actions.delete')}
                    </Button>
                    {collection.active ? (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(false)}
                        loading={updateCollectionStatusLoading}
                        disabled={updateCollectionLoading}>
                        {t('global.actions.deactivate')}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(true)}
                        loading={updateCollectionStatusLoading}
                        disabled={updateCollectionLoading}>
                        {t('global.actions.activate')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      loading={updateCollectionLoading}
                      disabled={!isDirty || updateCollectionLoading}>
                      {t('global.actions.save')}
                    </Button>
                  </div>
                </>
              )}
              {showNewCollection && (
                <>
                  <Button
                    type="button"
                    theme="transparent"
                    onClick={onCancel}
                    disabled={createCollectionLoading}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    loading={createCollectionLoading}
                    disabled={createCollectionLoading}>
                    {t('global.actions.add')}
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </Card>
    </>
  );
};

export default Collections;
