import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Image } from 'types/Product';

type Props = {
  image: Image;
  index: number;
};

const SortableItem = ({ image }: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: image.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div
      className="relative w-full rounded-md bg-white"
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}>
      <img
        src={image?.clientUrl}
        alt={'Image'}
        className="h-auto max-h-24 w-full max-w-full rounded-md border object-contain p-4 hover:border-white hover:ring-2 hover:ring-primary-500"
      />
      <span className="absolute left-0 top-[-23px] text-dark">{image.position}</span>
    </div>
  );
};

export default SortableItem;
