import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import ReactPaginate from 'react-paginate';

export interface Paginator {
  total: number;
  lastPage: number;
  currentPage: number;
}

interface PaginationProps {
  paginator: Paginator;
  setPage: (page: number) => void;
}

const Pagination = ({ paginator, setPage }: PaginationProps) => {
  const handlePageClick = (page: number) => {
    setPage(page + 1);
  };

  return (
    <div className="flex justify-center sm:justify-end">
      <ReactPaginate
        onPageChange={(e) => handlePageClick(e.selected)}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={paginator.lastPage}
        initialPage={paginator.currentPage - 1}
        renderOnZeroPageCount={null}
        containerClassName="isolate inline-flex -space-x-px rounded-md shadow-sm"
        breakLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-700 ring-1 ring-inset ring-gray-300 focus:outline-offset-0"
        breakLabel="..."
        previousLinkClassName="relative inline-flex items-center rounded-l-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
        nextLinkClassName="relative inline-flex items-center rounded-r-md px-2 py-2 text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        nextLabel={<ChevronRightIcon className="h-5 w-5" />}
        pageLinkClassName="relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
        activeLinkClassName="z-10 bg-primary-500 hover:bg-primary-500 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
      />
    </div>
  );
};

export default Pagination;
