import { gql } from 'graphql.macro';

export const productTagsQuery = gql`
  query ProductTags($id: ID!) {
    product(id: $id) {
      id
      is_fragile
      is_groupable
      active
    }
  }
`;
