import { gql } from 'graphql.macro';

export const updateProductDisplayPreferenceMutation = gql`
  mutation UpdateProductDisplayPreference(
    $id: ID!
    $iso: String!
    $displayPreference: Int!
    $comment: String
  ) {
    updateProductDisplayPreference(
      id: $id
      iso: $iso
      displayPreference: $displayPreference
      comment: $comment
    )
  }
`;
