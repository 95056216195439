import { gql } from 'graphql.macro';

export const updatePackagingOptionMutation = gql`
  mutation updatePackagingOption(
    $id: ID!
    $is_active: Boolean
    $label_fr: String!
    $label_gb: String!
  ) {
    updatePackagingOption(
      id: $id
      is_active: $is_active
      label_fr: $label_fr
      label_gb: $label_gb
    ) {
      id
      label_fr
      label_gb
      isUsed
      is_active
    }
  }
`;
