import React from 'react';
import Pill from './Pill';
import { useTranslation } from 'react-i18next';
import useFilter from '../../Hooks/UseFilter';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { OptionProps } from 'types/Global';

const formIdToLabel = {
  active_true: 'products.filters.status.active',
  active_false: 'products.filters.status.inactive',
  is_to_destock_true: 'products.filters.status.destock',
  supply_exception_true: 'products.filters.status.supplier-exception',
  is_novelty_true: 'products.filters.novelty.filter-label',
  color: 'products.filters.color.filter-label',
  supplier: 'products.filters.supplier.filter-label',
  materials: 'products.filters.material.filter-material',
  category: 'products.filters.taxonomy.filter-category',
  universe: 'products.filters.taxonomy.filter-universe',
  types: 'products.filters.taxonomy.filter-type',
  style: 'products.filters.style.filter-label',
  min_price: 'products.filters.price.filter-min',
  max_price: 'products.filters.price.filter-max',
};

type Props = {
  filters: FieldValues;
  methods: UseFormReturn<FieldValues>;
};

const FiltersPills = ({ filters, methods }: Props) => {
  const { t } = useTranslation();
  const reset = methods.reset;
  const resetField = (e: string) => methods.resetField(e);
  const setValue = methods.setValue;

  const filterMethodes = useFilter();
  const handleRemove = (field: string) => {
    resetField(field);
  };

  const handleComplexRemove = (field: string, value: string, options: OptionProps[]) => {
    setValue(
      field,
      options.filter((elem) => elem.value !== value)
    );
  };

  const globalReset = () => {
    reset();
    Object.entries(filters).forEach((filter) => {
      setValue(filter[0], null);
    });
  };

  return (
    <>
      {Object.entries(filters).map((filter, index) => {
        if (
          (filter[0] === 'max_price' || filter[0] === 'min_price') &&
          filter[1] &&
          filter[1].length > 0 &&
          parseInt(filter[1], 10) >= 0
        ) {
          return (
            <div className="flex items-center gap-[4px]" key={index}>
              <Pill formId={filter[0]} handleRemove={(formId) => handleRemove(formId)}>
                {`${t(formIdToLabel[filter[0]])}: ${parseInt(filter[1], 10)} €`}
              </Pill>
            </div>
          );
        }
        if (filter[1] && typeof filter[1] === 'object') {
          return filter[1].map((option: OptionProps, subindex: number) => {
            return (
              <div className="flex items-center" key={subindex}>
                <Pill
                  formId={filter[0]}
                  handleRemove={(formId) => handleComplexRemove(formId, option.value, filter[1])}>
                  {option.label.indexOf('(') !== -1
                    ? option.label.substr(0, option.label.indexOf('('))
                    : option.label}
                </Pill>
              </div>
            );
          });
        }
        if (filter[1]) {
          return (
            <div className="flex items-center" key={index}>
              <Pill formId={filter[0]} handleRemove={(formId) => handleRemove(formId)}>
                {t(formIdToLabel[filter[0] as keyof typeof formIdToLabel])}
              </Pill>
            </div>
          );
        }
      })}
      {filterMethodes.countSelectedFilter(filters) > 1 && (
        <div className="flex basis-[100%] justify-end">
          <button
            className="relative flex items-center gap-[4px] p-2 text-sm underline"
            onClick={() => globalReset()}>
            {t('products.filters.remove-filters')}
          </button>
        </div>
      )}
    </>
  );
};

export default FiltersPills;
