const fileExtensions = [
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];
const imagesExtension = ['image/jpeg', 'image/png'];
const imagesDesignerExtension = ['image/jpeg'];
const maxFileSize = 20971520; // 20 MB
const maxImageFileSize = 15728640; // 15 MB

export const checkImagesDesignerExtension = (files) => {
  if (!files?.length) return false;
  if (!imagesDesignerExtension.includes(files[0].type)) {
    return false;
  }
  return true;
};

export const checkDesignerImageSize = (files) => {
  if (!files?.length) return Promise.resolve(false);
  if (files && files[0] instanceof File && files[0].type.startsWith('image/')) {
    const image = new Image();
    image.src = URL.createObjectURL(files[0]);

    let isValidSize = false;

    return new Promise((resolve) => {
      image.onload = () => {
        const width = image.width;
        const height = image.height;

        isValidSize = width === 200 && height === 200;

        resolve(isValidSize);
      };

      image.onerror = () => {
        resolve(false);
      };
    });
  } else {
    return Promise.resolve(false);
  }
};

export const verifyFileSizeImage = (file) => {
  if (file[0].size > maxImageFileSize) {
    return false;
  }
  return true;
};

export const verifyFileExtensionsImage = (file) => {
  if (!imagesExtension.includes(file[0].type)) {
    return false;
  }
  return true;
};

export const getWidthSize = async (imagefile) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function () {
      resolve(parseFloat(this.naturalWidth));
    };

    img.src = URL.createObjectURL(imagefile);
  });
};

export const getHeightSize = async (imagefile) => {
  return new Promise((resolve) => {
    const img = new Image();

    img.onload = function () {
      resolve(parseFloat(this.naturalHeight));
    };

    img.src = URL.createObjectURL(imagefile);
  });
};

export const verifyFileExtensionsDocument = (file) => {
  if (!fileExtensions.includes(file[0].type)) {
    return false;
  }
  return true;
};

export const verifyFileSizeDocument = (file) => {
  if (file[0].size > maxFileSize) {
    return false;
  }
  return true;
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

export const convertJSDateToPHPDateTime = (date) => {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join(
      '-'
    ) +
    ' ' +
    [
      padTo2Digits(date.getHours()),
      padTo2Digits(date.getMinutes()),
      padTo2Digits(date.getSeconds()),
    ].join(':')
  );
};

const validateImage = async (file, setUploadError, t) => {
  const fileSizeIsValide = verifyFileSizeImage(file);
  const extensions = verifyFileExtensionsImage(file);

  let status = false;

  if (!extensions) {
    setUploadError((uploadError) => [
      ...uploadError,
      t('product.images.errors.prohibited-file-type'),
    ]);
    status = true;
  }

  if (!fileSizeIsValide) {
    setUploadError((uploadError) => [...uploadError, t('product.images.errors.image-too-large')]);
    status = true;
  }

  if (status) {
    return status;
  }

  const width = await getWidthSize(file[0]).then((w) => w);
  const height = await getHeightSize(file[0]).then((w) => w);

  if (height > 1200) {
    setUploadError([t('product.images.errors.height-is-to-large')]);
    return true;
  }

  if (height < 600) {
    setUploadError([t('product.images.errors.height-is-to-small')]);
    return true;
  }

  if (width > 2000) {
    setUploadError([t('product.images.errors.width-is-to-large')]);
    return true;
  }

  if (width < 1000) {
    setUploadError([t('product.images.errors.width-is-to-small')]);
    return true;
  }

  return false;
};

export { validateImage };
