import React from 'react';
import Tippy from '@tippyjs/react';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { useTranslation } from 'react-i18next';

interface ProgressionProps {
  completionPercentage?: number;
  missingInformations?: string[];
  isEnd?: boolean;
}

const Progression = ({ completionPercentage, missingInformations, isEnd }: ProgressionProps) => {
  const { t } = useTranslation();
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }
  const color = () => {
    if (!completionPercentage) return;
    if (completionPercentage < 50) return 'bg-red-500';
    else if (completionPercentage < 70) return 'bg-orange-300';
    else if (completionPercentage < 100) return 'bg-yellow-300';
    else return 'bg-green-400';
  };
  return (
    <>
      <div className={classNames(isEnd ? 'justify-end' : '', 'flex items-center gap-2')}>
        <div className="min-w-[200px] overflow-hidden rounded-full bg-gray-200">
          <div
            className={`${color()} h-2 rounded-full`}
            style={{ width: `${completionPercentage}%` }}
          />
        </div>
        <div className="whitespace-nowrap">{completionPercentage} %</div>
      </div>
      {missingInformations && missingInformations.length > 0 && (
        <div className={classNames(isEnd ? 'justify-end' : '', 'mt-1 flex text-gray-500')}>
          {t('home.dashboard.missing-informations.title')} :{' '}
          <span className="ml-1 font-bold">{missingInformations.length}</span>
          <Tippy
            content={
              <ul className="list-disc pl-3">
                {missingInformations?.map((information) => (
                  <li key={information}>
                    {t(`home.dashboard.missing-informations.${information}`)}
                  </li>
                ))}
              </ul>
            }>
            <InformationCircleIcon className="ml-2 h-5 w-5 text-gray-300 hover:text-gray-600" />
          </Tippy>
        </div>
      )}
    </>
  );
};

export default Progression;
