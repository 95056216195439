import { gql } from 'graphql.macro';

export const allContactTypesQuery = gql`
  query allContactTypes {
    allContactTypes {
      id
      label
      active
      used
    }
  }
`;
