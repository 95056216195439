import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <>
      <main className="grid min-h-full place-items-center bg-gray-50 px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-primary-500">404</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-dark sm:text-5xl">
            {t('global.notfound')}
          </h1>
          <p className="mt-6 text-base leading-7 text-dark">
            {t('global.we-could-not-find-the-page')}
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              className="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
              to={'/'}>
              {t('main-menu.home')}
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};
export default NotFound;
