import { gql } from 'graphql.macro';

export const productCombinationQuery = gql`
  query productCombination($id: ID!) {
    product(id: $id) {
      id
      is_combination
      isCombined
      parent {
        id
        child_product_qty
        parent {
          id
          ecoMobilier {
            enable
            amount {
              id
              including_taxes_value
            }
          }
          deee {
            enable
            amount {
              id
              amount
            }
          }
          primaryImage {
            clientUrl
          }
          currentLanguageOffer {
            priceWithEcotax
            highestPrice
            isDiscount
          }
          currentTranslation {
            internal_title
          }
        }
      }
      constituents {
        id
        parent_product_id
        child_product_id
        child_product_qty
        child {
          id
          ecoMobilier {
            enable
            amount {
              id
              including_taxes_value
            }
          }
          deee {
            enable
            amount {
              id
              amount
            }
          }
          primaryImage {
            clientUrl
          }
          currentLanguageOffer {
            priceWithEcotax
            highestPrice
            isDiscount
          }
          currentTranslation {
            internal_title
          }
        }
      }
    }
  }
`;
