import React, { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { universesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/universes';
import { productsTypesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productsTypes';
import { productCategoriesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productCategories';
import { editProductTypeMutation } from 'graphql/Mutations/Settings/Products/Taxonomy/editProductType';
import { yupResolver } from '@hookform/resolvers/yup';
import { InputTextControl } from 'components/Common/InputTextControl';
import { ProductCategory, ProductType, ProductUniverse } from 'types/Product';
import { SelectControl } from 'components/Common/SelectControl';
import { OptionProps } from 'types/Global';
import SlideOver from 'components/Common/SlideOver';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  type: ProductType | null;
};

const EditTypeSlide = ({ setOpen, open, type }: Props) => {
  const { setNotification } = useNotification();

  const [universeOptions, setUniverseOptions] = useState<OptionProps[]>([]);
  const [typeOptions, setTypeOptions] = useState<OptionProps[]>([]);

  const schema = yup.object().shape({
    label: yup.string().max(50, t('form.string.max')),
    universe: yup.object().required(t('settings.taxonomy.type.universe-required')),
    category: yup.object().required(t('settings.taxonomy.type.category-required')),
  });

  const {
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  const {
    loading: loadingUniverse,
    error: errorUniverse,
    data: dataUniverse,
  } = useQuery(universesQuery, {
    fetchPolicy: 'network-only',
  });

  const [
    editProductType,
    { error: errorMutation, loading: loadingMutation, reset: resetMutation },
  ] = useMutation(editProductTypeMutation);

  const [
    getCategoryFromUniverseId,
    { loading: loadingCategory, error: errorCategory, data: dataCategory },
  ] = useLazyQuery(productCategoriesQuery, { fetchPolicy: 'network-only' });

  // get universe option for the select
  useEffect(() => {
    if (!dataUniverse) return;
    setUniverseOptions(
      dataUniverse.productUniverses?.map((universe: ProductUniverse) => {
        return {
          value: universe.id,
          label: universe.label ? universe.label : '-',
          isDisabled: !universe.is_active,
        };
      })
    );
  }, [dataUniverse]);

  // get category option for the select the first time
  useEffect(() => {
    if (getValues('universe')?.value) return;

    getCategoryFromUniverseId({
      variables: { id: type?.category?.universe.id },
    });
  }, [dataCategory, type, getCategoryFromUniverseId, getValues]);

  // get category option for the select
  useEffect(() => {
    if (!dataCategory) return;
    setTypeOptions(
      dataCategory.productUniverse?.categories?.map((category: ProductCategory) => {
        return {
          value: category.id,
          label: category.label ? category.label : '-',
          isDisabled: !category.is_active,
        };
      })
    );
  }, [dataCategory, setTypeOptions]);

  // Reset form for good values in the form
  useEffect(() => {
    reset({
      label: type?.label,
      universe: type?.category?.universe
        ? {
            value: type?.category?.universe.id,
            label: type?.category?.universe.label,
          }
        : null,
      category: type?.category
        ? {
            value: type?.category.id,
            label: type?.category.label,
          }
        : null,
    });
  }, [type, reset]);

  const close = () => {
    setOpen(false);
    reset();
    resetMutation();
    reset({
      label: type?.label,
      universe: type?.category?.universe
        ? {
            value: type?.category?.universe.id,
            label: type?.category?.universe.label,
          }
        : null,
      category: type?.category
        ? {
            value: type?.category.id,
            label: type?.category.label,
          }
        : null,
    });
  };

  // We change universe and get the category for the select
  const changeUniverse = () => {
    getCategoryFromUniverseId({
      variables: { id: getValues('universe')?.value },
    });

    if (!dataCategory || loadingCategory) return;
    setTypeOptions(
      dataCategory.productUniverse?.categories?.map((category: ProductCategory) => {
        return {
          value: category.id,
          label: category.label ? category.label : '-',
          isDisabled: !category.is_active,
        };
      })
    );

    reset({
      label: getValues('label'),
      universe: getValues('universe')
        ? {
            value: getValues('universe')?.value,
            label: getValues('universe')?.label,
          }
        : null,
      category: null,
    });
  };

  const onSubmit = handleSubmit((data) => {
    editProductType({
      variables: {
        id: type?.id,
        label: data.label,
        universe_id: getValues('universe')?.value,
        category_id: getValues('category')?.value,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: productsTypesQuery, variables: { id: type?.category?.id } }],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('settings.taxonomy.type.update-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('settings.taxonomy.actions.edit-type')}
      buttonText={t('global.actions.modify')}
      isDirty={isDirty}
      onSubmit={onSubmit}
      onCancel={close}
      loading={loadingMutation}
      error={errorMutation}>
      <div className="flex flex-col space-y-8">
        <SelectControl
          name="universe"
          control={control}
          label={t('settings.taxonomy.actions.choose-universe')}
          options={universeOptions}
          onChange={() => changeUniverse()}
          loadingOptions={loadingUniverse}
          apolloError={errorUniverse}
        />
        <SelectControl
          name="category"
          control={control}
          label={t('settings.taxonomy.actions.choose-category')}
          options={typeOptions}
          loadingOptions={loadingCategory}
          apolloError={errorCategory}
        />
        <div className="space-y-6">
          <InputTextControl label={t('global.name')} name="label" control={control} isRequired />
        </div>
      </div>
    </SlideOver>
  );
};
export default EditTypeSlide;
