import React from 'react';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import FieldLegend from './FormUtils/FieldLegend';
import { FieldValues, UseFormRegister } from 'react-hook-form';

type Props = {
  register: UseFormRegister<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  noveltyFilterBucket: any;
};

const NoveltyFilter = ({ register, noveltyFilterBucket }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div" className={''}>
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.novelty.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                noveltyFilterBucket.filter((elem: any) => elem['key_as_string'] !== 'false')[0] &&
                  noveltyFilterBucket.lenght !== 0 && (
                    <div>
                      <input
                        id={'is_novelty_true'}
                        type="checkbox"
                        {...register('is_novelty_true')}
                        className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                      />
                      <label
                        htmlFor={'is_novelty_true'}
                        className="ml-3 inline-flex gap-[6px] text-sm text-gray-600">
                        {t('products.filters.novelty.filter-label')}
                      </label>
                    </div>
                  )
              }
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default NoveltyFilter;
