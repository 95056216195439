import { gql } from 'graphql.macro';

export const createPaymentMethodMutation = gql`
  mutation CreatePaymentMethod($label: String!) {
    createSupplierPaymentMethod(label: $label) {
      id
      label
      sort
      active
    }
  }
`;
