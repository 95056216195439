import React from 'react';

type DesignerImageProps = {
  image: string | null;
};

const DesignerImage = ({ image }: DesignerImageProps) => {
  return (
    <img
      alt="designer-image"
      src={
        image
          ? image
          : 'https://image.shutterstock.com/image-vector/picture-vector-icon-no-image-600w-1514905736.jpg'
      }
      className="h-40 rounded-full"
    />
  );
};

export default DesignerImage;
