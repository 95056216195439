import React from 'react';
import { useTranslation } from 'react-i18next';
import Tippy from '@tippyjs/react';
import Badge from './Badge';
import { InformationCircleIcon } from '@heroicons/react/20/solid';

interface Props {
  completionPercentage?: number;
  missingInformations?: string[];
  isEnd?: boolean;
}

const ProgressionBadge = ({ completionPercentage, missingInformations, isEnd }: Props) => {
  const { t } = useTranslation();

  const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ');
  };
  const type = () => {
    if (!completionPercentage) return 'danger';
    if (completionPercentage < 50) return 'danger';
    else if (completionPercentage < 100) return 'warning';
    else return 'success';
  };

  return (
    <>
      <div className={classNames(isEnd ? 'justify-end' : '', 'flex items-center gap-2')}>
        <Badge type={type()}>{`${completionPercentage} %`}</Badge>
        {missingInformations && missingInformations.length > 0 && (
          <Tippy
            content={
              <ul className="list-disc pl-3">
                {missingInformations?.map((information) => (
                  <li key={information}>
                    {t(`home.dashboard.missing-informations.${information}`)}
                  </li>
                ))}
              </ul>
            }>
            <InformationCircleIcon className="w-5 text-divider hover:text-secondary" />
          </Tippy>
        )}
      </div>
    </>
  );
};

export default ProgressionBadge;
