import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { IMAGE_TYPE } from 'utils/imageType';
import { allImagesQuery } from 'graphql/Queries/Product/Images/Types/allImages';
import { validateImage } from 'utils/Utils';
import { uploadProductImageMutation } from 'graphql/Mutations/Product/Images/uploadProductImage';
import useNotification from 'hooks/useNotification';
import { ApolloErrorAlert, ErrorAlert } from 'components/Common/Alerts';

const AddImageInput = () => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [uploadError, setUploadError] = useState<string[]>([]);

  const [uploadImage, { loading, error, reset }] = useMutation(uploadProductImageMutation);

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      reset();
      setUploadError([]);
      const status = await validateImage(acceptedFiles, setUploadError, t);
      if (status) return;

      uploadImage({
        variables: {
          file: acceptedFiles[0],
          product_id: params.productId,
          type_id: IMAGE_TYPE.MISCELLANEOUS_IMAGE,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: allImagesQuery,
            variables: {
              id: params.productId,
            },
          },
        ],
        onCompleted: () => {
          setNotification({
            title: t('global.success'),
            message: t('product.images.notif.add-image-success'),
            type: 'success',
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onError: () => {},
      });
    },
    [uploadImage, setUploadError, t, params, setNotification, reset]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={
        `flex h-56 w-full cursor-pointer justify-center rounded-md border-2 border-dashed border-gray-300 p-5` +
        (isDragActive && 'bg-blue-100')
      }>
      {loading ? (
        <div className="flex items-center justify-center">
          <div className="h-7 w-7 animate-spin rounded-full border-b-2 border-dark " />
        </div>
      ) : (
        <>
          <input {...getInputProps()} accept={'image/*'} disabled={loading} />
          <div className="space-y-1 text-center">
            <PhotoIcon className="mt-3 h-24 w-full text-secondary" />
            <div>
              <p className="text-s cursor-pointer rounded-md text-blue-500 hover:text-blue-400">
                {t('product.sticker.card.select-file')}
              </p>
            </div>
            {error ? (
              <ApolloErrorAlert error={error} />
            ) : uploadError.length > 0 ? (
              <div className="w-full ">
                <ErrorAlert>
                  <ul className="ml-2 list-disc text-left text-xs">
                    {uploadError.map((error, i) => (
                      <li key={i}>{error}</li>
                    ))}
                  </ul>
                </ErrorAlert>
              </div>
            ) : (
              <>
                <p className="text-xs text-dark">{t('product.sticker.card.dnd')}</p>
                <p className="text-xs text-secondary">{t('product.sticker.card.accept-file')}</p>
                <p className="text-xs text-secondary">{t('product.sticker.card.max-size')}</p>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};
export default AddImageInput;
