import { gql } from 'graphql.macro';

export const updateProductAssemblyInstructionMutation = gql`
  mutation updateProductAssemblyInstruction($id: ID!, $furniture_assembly_option_id: ID) {
    updateProductAssemblyInstruction(
      id: $id
      furniture_assembly_option_id: $furniture_assembly_option_id
    ) {
      id
      furniture_assembly_option_id
      furnitureAssemblyOption {
        id
        keyword
        active
        currentTranslation {
          id
          text
        }
      }
    }
  }
`;
