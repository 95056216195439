import { gql } from 'graphql.macro';

export const loginQuery = gql`
  mutation Login($email: String!, $password: String!) {
    login(input: { username: $email, password: $password }) {
      access_token
      refresh_token
      expires_in
      token_type
      user {
        id
        language_iso_code
        firstname
        lastname
      }
    }
  }
`;
