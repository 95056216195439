import { gql } from 'graphql.macro';

export const allMaterialsQuery = gql`
  query AllMaterials {
    materials {
      id
      label
      is_active
      isUsed
      parent {
        id
        label
      }
      children {
        id
      }
    }
  }
`;
