import { useCookies } from 'react-cookie';

import { AUTH_TOKEN } from './global';

// custom hook to handle authToken - we use compositon to decouple the auth system and it's storage
export const UseAuthToken = () => {
  //we use react-cookies to access our cookies
  const [cookies, setCookie, removeCookie] = useCookies([AUTH_TOKEN]);

  // this function allows to save any string in our cookies, under the key "authToken"
  const setAuthToken = (authToken) => setCookie(AUTH_TOKEN, authToken, { path: '/' });

  //this function removes the key "authToken" from our cookies. Useful to logout
  const removeAuthToken = () => removeCookie(AUTH_TOKEN, { path: '/' });

  const isLogged = () => {
    if (cookies[AUTH_TOKEN]) {
      return true;
    } else {
      return false;
    }
  };

  return [cookies[AUTH_TOKEN], setAuthToken, removeAuthToken, isLogged];
};
