import { gql } from 'graphql.macro';

export const historyPurchasePriceQuery = gql`
  query HistoryPurchasePrice($id: ID!) {
    product(id: $id) {
      id
      currentSupplying {
        id
        productSupplierHistory {
          id
          price
          currency {
            id
            code
          }
          comment
          created_at
          user_id
          user {
            lastname
            firstname
          }
          supplier {
            id
            name
          }
          supplier_product_id
        }
      }
    }
  }
`;
