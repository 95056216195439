import React, { ReactElement, useEffect, useState } from 'react';
import { Switch } from '@headlessui/react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

interface ToggleProps {
  enabled: boolean;
  label?: string;
  icon?: ReactElement;
  description?: string;
  onChange?: (e: boolean) => void;
  disabled?: boolean;
}

export function Toggle({ enabled, label, description, onChange, disabled, icon }: ToggleProps) {
  const [checked, setChecked] = useState<boolean>(false);

  useEffect(() => {
    setChecked(enabled);
  }, [setChecked, enabled]);

  const onToggleChange = (e: boolean) => {
    setChecked(e);
    if (onChange) onChange(e);
  };
  return (
    <Switch.Group as="div" className="flex w-full items-center justify-between">
      {label && description && (
        <span className="flex flex-grow flex-col">
          <Switch.Label
            as="span"
            className="flex space-x-2 text-sm font-medium leading-6 text-gray-900"
            passive>
            <div className="flex space-x-2">
              <label className="w-full">{label}</label>
              <div>{icon}</div>
            </div>
          </Switch.Label>
          <Switch.Description as="span" className="-mt-1 text-sm text-gray-500">
            {description}
          </Switch.Description>
        </span>
      )}
      <Switch
        checked={checked}
        disabled={disabled}
        onChange={onToggleChange}
        className={classNames(
          checked ? 'bg-primary-500' : 'bg-gray-200',
          'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-default disabled:opacity-30'
        )}>
        <span
          className={classNames(
            checked ? 'translate-x-5' : 'translate-x-0',
            'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
          )}
        />
      </Switch>
    </Switch.Group>
  );
}
