import { gql } from 'graphql.macro';

export const associatedForwardersQuery = gql`
  query AssociatedForwarders {
    associatedForwarders {
      id
      label
      contact_firstname
      contact_lastname
      contact_email
      active
      used
    }
  }
`;
