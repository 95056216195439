import { gql } from 'graphql.macro';

export const productPreviewQuery = gql`
  query product($id: ID!) {
    product(id: $id) {
      is_rewarded_for_waiting
      is_rewarded_for_waiting_foreign
      primaryImage {
        clientUrl
      }
      taxonomy {
        collection {
          label
        }
        type {
          label
          category {
            label
            universe {
              label
            }
          }
        }
        style {
          label
        }
      }
      family {
        currentLabelTranslation {
          text
        }
      }
      model {
        name
      }
      customsNomenclature {
        code
      }
      ean_code
      notice {
        name
        clientUrl
      }
      datasheet {
        name
        clientUrl
      }
      physicalStock
      reservedQuantity
      virtualStock
      detailedArrivals
      packageSizes {
        height
        width
        depth
        weight
        package {
          name
        }
      }
      currentSupplying {
        supplier_product_id
        supplier {
          id
          name
        }
        price
        currency {
          code
        }
      }
      minimum_order_quantity
      model {
        minimum_order_quantity
      }
      offersAndVisibilityInformations
      ecotax
      allVariants {
        id
        currentTranslation {
          internal_title
        }
        primaryImage {
          clientUrl
        }
        currentLanguageOffer {
          priceWithEcotax
          highestPrice
          isDiscount
        }
        ecoMobilier {
          enable
          amount {
            including_taxes_value
          }
        }
        deee {
          enable
          amount {
            amount
          }
        }
      }
      productsCollectionWhitoutVariants(limit: 4) {
        id
        currentTranslation {
          internal_title
        }
        primaryImage {
          clientUrl
        }
        currentLanguageOffer {
          priceWithEcotax
          highestPrice
          isDiscount
        }
        allVariants {
          id
          color {
            hex_code
          }
        }
        ecoMobilier {
          enable
          amount {
            including_taxes_value
          }
        }
        deee {
          enable
          amount {
            amount
          }
        }
      }
      totalConstituentsQuantity
      isCombination
      constituents {
        child_product_qty
        child {
          id
          ecoMobilier {
            enable
            amount {
              including_taxes_value
            }
          }
          deee {
            enable
            amount {
              amount
            }
          }
          currentTranslation {
            internal_title
          }
          primaryImage {
            clientUrl
          }
          currentLanguageOffer {
            priceWithEcotax
            highestPrice
            isDiscount
          }
        }
      }
    }
  }
`;
