import { gql } from 'graphql.macro';

export const updatePackageSizeMutation = gql`
  mutation UpdatePackageSize($id: ID!, $packageSize: Mixed!) {
    updateProductPackageSize(id: $id, packageSize: $packageSize) {
      weight
      volume
      packageSizes {
        id
        height
        width
        depth
        type {
          label
          legacy_code
        }
        quantity_by_pallet
        to_repack
        number
      }
      packages {
        number
        supplierProductLabel
        name
        weight
        volume
      }
    }
  }
`;
