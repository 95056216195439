import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { Button } from 'components/Common/Button';
import { NoSymbolIcon } from '@heroicons/react/24/outline';
import Tags from './Tags';
import Title from 'components/Common/Title';
import BanAllCountriesSlide from 'components/Product/Visibility/BanAllCountriesSlide';
import VisibilityTable from './VisibilityTable';
import VisibilityComments from './VisibilityComments';

const Visibilities = () => {
  const { productExists } = useProductContextType();
  const { t } = useTranslation();

  const [openAllForbiddenCountry, setOpenAllForbiddenCountry] = useState(false);
  const [disabledAllForbiddenCountry, setDisabledAllForbiddenCountry] = useState(true);

  if (!productExists) return <></>;

  return (
    <>
      <Title title={t('product.visibility.title')} />
      <div className="mb-4 flex w-full justify-end">
        <Button
          type="button"
          onClick={() => setOpenAllForbiddenCountry(true)}
          disabled={disabledAllForbiddenCountry}>
          <div className="flex">
            <NoSymbolIcon className="mr-2 h-5" />
            {t('product.visibility.forbidden.title')}
          </div>
        </Button>
      </div>
      <BanAllCountriesSlide open={openAllForbiddenCountry} setOpen={setOpenAllForbiddenCountry} />
      <div className="space-y-8">
        <VisibilityTable setDisabledAllForbiddenCountry={setDisabledAllForbiddenCountry} />
        <VisibilityComments />
        <Tags />
      </div>
    </>
  );
};

export default Visibilities;
