import { gql } from 'graphql.macro';

export const updateActiveTypeMutation = gql`
  mutation updateActiveType($id: ID!, $is_active: Boolean!) {
    updateActiveType(id: $id, is_active: $is_active) {
      id
      is_active
    }
  }
`;
