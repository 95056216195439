import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TrashIcon, PencilSquareIcon, ArrowsUpDownIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@apollo/client';
import { allImagesQuery } from 'graphql/Queries/Product/Images/Types/allImages';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { Button } from 'components/Common/Button';
import { Image, AllImages } from 'types/Product';
import { getBadgeText } from '../Component/Utils';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import AddImageInput from '../Component/AddImageInput';
import Title from 'components/Common/Title';
import Badge from 'components/Common/Badge';
import DeleteImageModal from '../Component/DeleteImageModal';
import TagImage from './TagImage';
import OrganisingImage from './OrganisingImage';

const Index = () => {
  const { t } = useTranslation();
  const params = useParams();

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [selectedImageId, setSelectedImageId] = useState<number | null>(null);
  const [imageType, setImageType] = useState<string>();
  const [openEditImage, setOpenEditImage] = useState<boolean>(false);
  const [image, setImage] = useState<Image | null>(null);
  const [organisingImage, setOrganisingImage] = useState<boolean>(false);

  const { data, loading, error } = useQuery<AllImages>(allImagesQuery, {
    variables: { id: params.productId },
  });

  const { productExists } = useProductContextType();
  if (!productExists) return <></>;

  let allImages: Image[] = [];

  if (data?.product) {
    if (data.product.miscellaneousImages) {
      allImages = allImages.concat(data.product.miscellaneousImages);
    }
    if (data.product.ambianceImages) {
      allImages = allImages.concat(data.product.ambianceImages);
    }
    if (data.product.dimensionsImage) {
      allImages = allImages.concat(data.product.dimensionsImage);
    }
    if (data.product.energyClassImage) {
      allImages = allImages.concat(data.product.energyClassImage);
    }
    allImages.sort((a, b) => a.position - b.position);
  }

  const alreadyEnergyImage = allImages.some((img) => img.is_energy_class === true);
  const alreadyDimensionImage = allImages.some((img) => img.is_dimensions === true);

  return (
    <>
      <Title
        title={t('product.images.title')}
        addOnEnd={
          loading ? (
            <div className="skeleton h-10 w-44" />
          ) : (
            <Button theme="secondary" onClick={() => setOrganisingImage(true)}>
              <>
                <ArrowsUpDownIcon className="mr-3 h-6 w-6" />
                <span className="leading-6">{t('product.images.images-order')}</span>
              </>
            </Button>
          )
        }
      />
      {error && (
        <div className="mb-6">
          <ApolloErrorAlert error={error} />
        </div>
      )}
      <OrganisingImage setOpen={setOrganisingImage} open={organisingImage} images={allImages} />
      <DeleteImageModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        imageType={imageType}
        imageId={selectedImageId}
      />
      <TagImage
        setOpen={setOpenEditImage}
        open={openEditImage}
        alreadyEnergyImage={alreadyEnergyImage}
        alreadyDimensionImage={alreadyDimensionImage}
        image={image ? image : null}
      />
      <div className="grid grid-cols-1 gap-6 xl:grid-cols-2 2xl:grid-cols-3">
        <AddImageInput />
        {loading
          ? [...Array(2)].map((e, i) => <div key={i} className="skeleton w-full"></div>)
          : allImages.map((image) => (
              <div
                key={image.id}
                className="group relative flex h-56 items-center justify-center overflow-hidden rounded-md border bg-white p-4">
                <img
                  src={image.clientUrl}
                  alt={`Image ${image.id}`}
                  className="h-full w-auto object-cover object-center transition-opacity duration-300"
                />
                <span className="absolute right-0 top-2 mr-4">
                  {data && <Badge type={'secondary'}>{getBadgeText(image, data, t)}</Badge>}
                </span>
                {data && (
                  <>
                    <PencilSquareIcon
                      className="absolute right-2 top-2 z-50 w-10 cursor-pointer rounded-full border border-gray-200 bg-white p-2 opacity-0 outline-none transition-opacity duration-300 hover:text-blue-600 group-hover:opacity-100"
                      onClick={() => {
                        setOpenEditImage(true);
                        setImage(image);
                      }}
                    />
                    <TrashIcon
                      className="absolute right-2 top-14 z-50 w-10 cursor-pointer rounded-full border border-gray-200 bg-white p-2 opacity-0 outline-none transition-opacity duration-300 hover:text-red-600 group-hover:opacity-100"
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setImageType(getBadgeText(image, data, t));
                        setSelectedImageId(parseInt(image.id));
                      }}
                    />
                  </>
                )}
                <div className="absolute inset-0 bg-gray-300 opacity-0 transition-opacity duration-300 group-hover:opacity-50"></div>
              </div>
            ))}
      </div>
    </>
  );
};

export default Index;
