import React from 'react';
import { useQuery } from '@apollo/client';
import { Controller, Control } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { theme, customStyle, errorCustomStyle } from 'components/Style/customStyle';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { ID, SelectType } from 'types/Global';
import { ecomobilierSizeQuery } from 'graphql/Queries/Ecotax/ecomobilierSize';
import Select from 'react-select';

type Props = {
  control: Control;
  typeId: ID;
  defaultSize: SizeType;
  handleSelect: (e: SelectType) => void;
};

type SizeType = {
  value: string;
  code: string;
};

type Size = {
  id: number;
  code: string;
  label: string;
};

type TypeSizes = {
  sizes: Size[];
};

type SizeQueryData = {
  typeSizes: TypeSizes;
};

function SizeSelect({ control, typeId, defaultSize, handleSelect }: Props) {
  const { t } = useTranslation();
  const { loading, data, error } = useQuery<SizeQueryData>(ecomobilierSizeQuery, {
    variables: { id: parseInt(typeId as string) },
  });

  const sizeTypes = data?.typeSizes?.sizes.map((size, index) => {
    return {
      label: `${size.code} - ${size.label}`,
      value: size.id,
      code: size.code,
      key: index,
    };
  });

  return (
    <>
      <div className="mt-3 sm:col-span-3">
        <label className="mb-2 block text-sm font-medium leading-6 text-dark">
          {`${t('product.ecotax.size')}`}
          <span className="text-red-700">*</span>
        </label>
        <div className="relative mt-1">
          <Controller
            name="size"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                theme={theme}
                styles={error ? errorCustomStyle : customStyle}
                placeholder={loading ? t('global.loading') : t('global.form.select')}
                noOptionsMessage={() => t('global.no-option')}
                isDisabled={loading}
                isSearchable={false}
                className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-blue-500 
                focus:ring-blue-500 sm:text-sm"
                options={sizeTypes}
                value={sizeTypes?.filter((option) =>
                  option.code === defaultSize?.code ? option : ''
                )}
                onChange={(e) => {
                  handleSelect(e as SelectType);
                }}
              />
            )}
          />
          <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
            {error && <ExclamationCircleIcon className="h-5 w-5 text-red-500" />}
          </div>
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-700">
            {
              ((error.graphQLErrors[0].extensions as { custom: string }).custom
                ? error
                : t('global.error-msg')) as string
            }
          </p>
        )}
      </div>
    </>
  );
}

export default SizeSelect;
