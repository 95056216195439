import React, { useState } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@apollo/client';
import { supplierCoordinatesQuery } from 'graphql/Queries/Supplier/GeneralInformations/coordinates';
import { Supplier } from 'types/Supplier';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';
import Tippy from '@tippyjs/react';
import Edit from './Edit';

const Index = () => {
  const params = useParams();

  const [openEdit, setOpenEdit] = useState(false);

  const { loading, error, data } = useQuery(supplierCoordinatesQuery, {
    variables: { id: params.supplierId },
    fetchPolicy: 'network-only',
  });

  const supplier: Supplier | undefined = data?.supplier;

  return (
    <>
      <Edit open={openEdit} setOpen={setOpenEdit} supplier={supplier} />
      <Card
        title={t('supplier.coordinates.title')}
        addOnEnd={
          supplier && (
            <Tippy content={t('global.actions.modify')}>
              <button type="button" onClick={() => setOpenEdit(true)}>
                <PencilSquareIcon className="w-6 shrink-0 text-secondary hover:text-dark hover:outline-none focus:outline-none" />
              </button>
            </Tippy>
          )
        }>
        <>
          {error && <ApolloErrorAlert error={error} />}
          {loading && (
            <div className="space-y-2">
              <div className="skeleton h-6 w-96" />
              <div className="skeleton h-4 w-80" />
              <div className="skeleton h-4 w-40" />
            </div>
          )}
          {supplier && (
            <div className="space-y-3 text-sm text-dark">
              <p className="text-xl font-semibold">
                {supplier.billing_name ? supplier.billing_name : '-'}
              </p>
              <div>
                <p className="text-secondary">{t('supplier.coordinates.vat_number')}</p>
                <p className="text-primary-500">
                  {supplier.vat_number ? supplier.vat_number : '-'}
                </p>
              </div>
              <div>
                <p className="text-secondary">{t('supplier.coordinates.street')}</p>
                <p>{supplier.street ? supplier.street : '-'}</p>
                {supplier.complementary_address ? <p>{supplier.complementary_address}</p> : <></>}
                <p>
                  {supplier.zipcode ? `${supplier.zipcode}, ` : ''}
                  {supplier.city ? `${supplier.city} - ` : ''}
                  {supplier.country ? supplier.country.name : ''}
                </p>
              </div>
            </div>
          )}
        </>
      </Card>
    </>
  );
};

export default Index;
