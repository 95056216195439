import { gql } from 'graphql.macro';

export const updateFurnitureAssemblyOptionStatusMutation = gql`
  mutation updateFurnitureAssemblyOptionStatus($id: ID!, $active: Boolean) {
    updateFurnitureAssemblyOption(id: $id, active: $active) {
      id
      active
      isUsed
      currentTranslation {
        text
      }
    }
  }
`;
