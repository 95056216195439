import { gql } from 'graphql.macro';

export const updatePaymentMethodStatusMutation = gql`
  mutation UpdatePaymentStatusMethod($id: ID!, $active: Boolean) {
    updateSupplierPaymentMethod(id: $id, active: $active) {
      id
      label
      sort
      active
      used
    }
  }
`;
