import React from 'react';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { allActiveBrandQuery } from 'graphql/Queries/Product/Brand/allActiveBrand';
import { SelectControl } from 'components/Common/SelectControl';
import { Brand } from 'types/Product';

const SelectBrand = () => {
  const { t } = useTranslation();
  const { loading, data, error } = useQuery(allActiveBrandQuery);
  const { control } = useFormContext();

  const options = data?.allActiveBrand.map((brand: Brand) => {
    return {
      value: brand.id,
      label: brand.label,
      url: brand.logoFullPath,
    };
  });

  return (
    <div>
      <SelectControl
        control={control}
        name="brand"
        label={t('product.brand-label.brand.title')}
        options={options}
        loadingOptions={loading}
        isClearable
        apolloError={error}
      />
    </div>
  );
};

export default SelectBrand;
