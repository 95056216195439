import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { imageStickerQuery } from 'graphql/Queries/Product/Images/Sticker/image';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Title from 'components/Common/Title';
import Card from 'components/Common/Card';
import PrimaryCard from './PrimaryCard';
import SecondaryCard from './SecondaryCard';
import { useProductContextType } from 'components/Contexts/ProductContext';

const Index = () => {
  const { productExists } = useProductContextType();
  const { t } = useTranslation();
  const params = useParams();

  const { data, loading, error } = useQuery(imageStickerQuery, {
    variables: { id: params.productId },
  });

  if (!productExists) return <></>;

  return (
    <>
      <Title title={t('product.sticker.title')} />
      {error ? (
        <ApolloErrorAlert error={error} />
      ) : (
        <div className="space-y-6 2xl:flex 2xl:space-x-6 2xl:space-y-0 ">
          <div className="w-96">
            <Card title={t('product.sticker.card.primary-title')}>
              <PrimaryCard image={data?.product?.primaryImage} loading={loading} />
            </Card>
          </div>
          <div className="w-96">
            <Card title={t('product.sticker.card.secondary-title')}>
              <SecondaryCard image={data?.product?.secondaryImage} loading={loading} />
            </Card>
          </div>
        </div>
      )}
    </>
  );
};
export default Index;
