import { gql } from 'graphql.macro';

export const universesQuery = gql`
  query Universes {
    productUniverses {
      id
      label
      productsCount
      is_active
    }
  }
`;
