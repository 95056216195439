import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { Trans, useTranslation } from 'react-i18next';
import { Button } from 'components/Common/Button';

type Props = {
  type: string;
  openModal: boolean;
  mutationLoading?: boolean;
  handleResponse: (response: string) => void;
};

function TaxSwitchModal({ type, openModal, handleResponse, mutationLoading = false }: Props) {
  const { t } = useTranslation();

  const newTax = type;
  const oldTax = type == 'DEEE' ? 'EcoMobilier' : 'DEEE';

  const onSubmit = () => {
    handleResponse('accept');
  };

  const clearAll = () => {
    handleResponse('close');
  };

  return (
    <>
      <Transition.Root show={openModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => clearAll}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-6 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-6 py-6 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <form onSubmit={onSubmit}>
                    <div className="sm:flex sm:items-start">
                      <div className="ml-2">
                        {<ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" />}
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className={'text-base font-semibold leading-6 text-yellow-600'}>
                          {t('product.ecotax.modale.title')}
                        </Dialog.Title>
                        <div className="mt-2">
                          <div className="text-sm text-gray-500">
                            <div className="mt-4 space-y-4">
                              <div className="">
                                <Trans i18nKey="product.ecotax.modale.warning" values={{ oldTax }}>
                                  <span className="font-bold text-dark" />
                                </Trans>
                              </div>
                              <div className="space-y-4">
                                <p>
                                  <Trans
                                    i18nKey="product.ecotax.modale.info"
                                    values={{ newTax, oldTax }}>
                                    <span className="font-bold text-primary-500" />
                                    <span className="font-bold text-primary-500" />
                                    <span className="font-bold text-dark" />
                                    <span className="font-bold text-dark" />
                                  </Trans>
                                </p>
                                <p className="text-dark">{t('product.ecotax.modale.confirm')}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-10 flex justify-end gap-4">
                      <Button
                        type="button"
                        theme="secondary"
                        disabled={mutationLoading}
                        onClick={() => clearAll()}>
                        {t('global.actions.cancel')}
                      </Button>
                      <Button
                        type="button"
                        disabled={mutationLoading}
                        loading={mutationLoading}
                        onClick={() => onSubmit()}>
                        {t('global.actions.save')}
                      </Button>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default TaxSwitchModal;
