import React, { useState } from 'react';
import { t } from 'i18next';
import { useGlobalContextType } from 'components/Contexts/GlobalContext';
import { ProductionSite } from 'types/Supplier';
import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import UpdateProductionSite from './UpdateProductionSite';
import DeleteProductionSite from './DeleteProductionSite';

type Props = {
  productionSites: ProductionSite[];
};

const List = ({ productionSites }: Props) => {
  const { sidebarOpen } = useGlobalContextType();

  const [openSlide, setOpenSlide] = useState<boolean>(false);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [site, setSite] = useState<ProductionSite | null>(null);

  const editSite = (site: ProductionSite) => {
    setOpenSlide(true);
    setSite(site);
  };

  const deleteSite = (site: ProductionSite) => {
    setOpenModal(true);
    setSite(site);
  };

  return (
    <>
      <UpdateProductionSite setOpen={setOpenSlide} open={openSlide} site={site} />
      <DeleteProductionSite setOpen={setOpenModal} open={openModal} site={site} />

      <ul
        role="list"
        className={clsx(
          sidebarOpen ? 'lg:grid-cols-2' : 'lg:grid-cols-2 xl:grid-cols-3',
          'grid grid-cols-1 gap-6'
        )}>
        {productionSites?.map((site, i) => {
          return (
            <li
              key={site.id}
              className="col-span-1 flex h-80 flex-col divide-y divide-gray-200 rounded bg-white text-dark shadow">
              <div className="flex flex-1 flex-col space-y-6">
                <div className="flex justify-between border-b border-gray-200 px-4 py-5 sm:px-6">
                  <h3 className="text-base font-semibold leading-6 text-dark">
                    {`${t('supplier.production-sites.site-number')} ${i + 1}`}
                  </h3>
                </div>
                <div className="flex h-full flex-col space-y-4 px-6">
                  {site.name ? (
                    <Tippy content={site.name}>
                      <span className="w-fit font-semibold line-clamp-1 text-primary-500">
                        {site.name}
                      </span>
                    </Tippy>
                  ) : (
                    <span className="font-semibold text-primary-500">-</span>
                  )}
                  {site.street ? (
                    <Tippy content={site.street}>
                      <span className="line-clamp-2 w-fit text-sm">{site.street}</span>
                    </Tippy>
                  ) : (
                    <span className="text-sm">-</span>
                  )}
                  {site.complementary_address ? (
                    <Tippy content={site.complementary_address}>
                      <span className="line-clamp-2 w-fit text-sm">
                        {site.complementary_address}
                      </span>
                    </Tippy>
                  ) : (
                    <span className="text-sm">-</span>
                  )}
                  <div className="flex space-x-1">
                    {site.zipcode && (
                      <Tippy content={site.zipcode}>
                        <span className="line-clamp-1 text-sm ">{`${site.zipcode},`}</span>
                      </Tippy>
                    )}
                    {site.city && (
                      <Tippy content={site.city}>
                        <span className="line-clamp-1 text-sm">{site.city + ' -'}</span>
                      </Tippy>
                    )}
                    {site.country && (
                      <Tippy content={site.country.name}>
                        <span className="line-clamp-1 text-sm">{site.country.name}</span>
                      </Tippy>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    <button
                      onClick={() => editSite(site)}
                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl border border-transparent py-4 text-sm font-semibold text-gray-900">
                      <PencilSquareIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="-ml-px flex w-0 flex-1">
                    <button
                      onClick={() => deleteSite(site)}
                      className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br border border-transparent py-4 text-sm font-semibold text-gray-900">
                      <TrashIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};
export default List;
