import { gql } from 'graphql.macro';

export const updateUrlMutation = gql`
  mutation UpdateUrl($id: ID!, $url: String) {
    updateProductTranslation(id: $id, url: $url) {
      id
      urlFullPath
    }
  }
`;
