import { gql } from 'graphql.macro';

export const supplierPurchaseInformationsQuery = gql`
  query SupplierPurchaseInformations($id: ID!) {
    supplier(id: $id) {
      id
      incoterm {
        id
        label
        active
      }
      associatedForwarder {
        id
        label
        active
      }
      port {
        id
        label
        active
      }
      paymentMethod {
        id
        label
        active
      }
      franco
      replenishment_time
      supplierManager {
        id
        firstname
        lastname
      }
    }
  }
`;
