import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FieldValues } from 'react-hook-form';
import { useLazyQuery, useMutation } from '@apollo/client';
import { packagingOptionsQuery } from 'graphql/Queries/Product/Packaging/packagingOptions';
import { updatePackagingOptionMutation } from 'graphql/Mutations/Settings/Products/Packaging/updatePackagingOption';
import { createPackagingOptionMutation } from 'graphql/Mutations/Settings/Products/Packaging/createPackagingOption';
import { PackagingOption } from 'types/Product';
import { InformationAlert } from 'components/Common/Alerts';
import { yupResolver } from '@hookform/resolvers/yup';
import { PackagingOptions } from 'types/Global';
import { InputTextControl } from 'components/Common/InputTextControl';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';
import DeletePackagingModal from './DeletePackagingModal';
import SelectCreatable from 'components/Common/SelectCreatable';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const Form = () => {
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();

  const [packagingOptions, setPackagingOptions] = useState<PackagingOptions[]>([]);
  const [instruction, setInstruction] = useState<PackagingOptions | null>(null);
  const [showNewInstruction, setShowNewInstruction] = useState(false);
  const [showEditInstruction, setShowEditInstruction] = useState(false);
  const [activateLoading, setActivateLoading] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [displayModal, setDisplayModal] = useState(false);

  const [updatePackagingOption] = useMutation(updatePackagingOptionMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: packagingOptionsQuery,
      },
    ],
  });

  const [createPackagingOption, { loading: createPackagingOptionLoading }] = useMutation(
    createPackagingOptionMutation,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: packagingOptionsQuery,
        },
      ],
    }
  );

  const [getAllInstructions, { data: dataInstruction, loading: loadingPackagingOptions }] =
    useLazyQuery(packagingOptionsQuery, {
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    if (!dataInstruction) return;
    setPackagingOptions(
      dataInstruction.packagingOptions?.map((packagingOption: PackagingOption) => {
        return {
          value: packagingOption.id,
          label: packagingOption.label_fr,
          label_gb: packagingOption.label_gb,
          isUsed: packagingOption.isUsed,
          is_active: packagingOption.is_active,
        };
      })
    );
  }, [setPackagingOptions, dataInstruction]);

  const onCancel = () => {
    setShowNewInstruction(false);
    setInstruction(null);
  };

  const schema = yup.object().shape({
    value: yup.string(),
    label: yup.string().required(t('form.required')),
    label_gb: yup.string().required(t('form.required')),
    is_active: yup.boolean(),
    isUsed: yup.boolean(),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({ resolver: yupResolver<FieldValues>(schema) });

  const onCreateInstruction = (value: string) => {
    if (!value) return;
    setShowEditInstruction(false);
    setShowNewInstruction(true);

    setInstruction({
      value: value,
      label: value,
      label_gb: '',
      isUsed: false,
      is_active: instruction?.is_active || false,
    });

    reset({
      label: value,
      label_gb: '',
      isUsed: false,
    });
  };

  const resetAfterDelete = () => {
    setInstruction(null);
    setShowEditInstruction(false);
    setShowNewInstruction(false);
  };

  const onChangeInstruction = (value: unknown) => {
    const typedValue = value as PackagingOptions;
    if (!value) return;
    setShowEditInstruction(true);
    setShowNewInstruction(false);

    setInstruction(typedValue);

    reset({
      label: typedValue.label,
      label_gb: typedValue.label_gb,
    });
  };

  const changeActivation = (value: boolean) => {
    let message = '';
    if (value) {
      message = t('settings.products.packagings.notif.deactivate');
    } else {
      message = t('settings.products.packagings.notif.activate');
    }

    setActivateLoading(true);
    updatePackagingOption({
      variables: {
        id: instruction?.value,
        is_active: !value,
        label_fr: instruction?.label,
        label_gb: instruction?.label_gb,
      },

      onCompleted: (res) => {
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });

        setInstruction({
          value: res.updatePackagingOption.id,
          label: res.updatePackagingOption.label_fr,
          label_gb: res.updatePackagingOption.label_gb,
          isUsed: res.updatePackagingOption.isUsed,
          is_active: res.updatePackagingOption.is_active,
        });
        setActivateLoading(false);
      },
      onError: (error) => {
        setError(error);
        setActivateLoading(false);
      },
    });
  };

  const onSubmit = handleSubmit((values) => {
    setUpdateLoading(true);

    if (showNewInstruction) {
      createPackagingOption({
        variables: {
          label_fr: values.label,
          label_gb: values.label_gb,
        },
        onCompleted: (res) => {
          setNotification({
            title: t('global.success'),
            message: t('product.packaging.import.packaging-option-created'),
            type: 'success',
          });

          reset({
            value: res.createPackagingOption.id,
            label: res.createPackagingOption.label_fr,
            label_gb: res.createPackagingOption.label_gb,
            is_active: res.createPackagingOption.is_active,
            isUsed: res.createPackagingOption.isUsed,
          });
          setInstruction({
            value: res.createPackagingOption.id,
            label: res.createPackagingOption.label_fr,
            label_gb: res.createPackagingOption.label_gb,
            is_active: res.createPackagingOption.is_active,
            isUsed: res.createPackagingOption.isUsed,
          });

          setShowEditInstruction(true);
          setShowNewInstruction(false);
          setUpdateLoading(false);
        },
        onError: (error) => {
          setError(error);
          setUpdateLoading(false);
        },
      });
    } else {
      if (!instruction) return;
      updatePackagingOption({
        variables: {
          id: instruction?.value,
          label_fr: values.label,
          label_gb: values.label_gb,
        },

        onCompleted: (res) => {
          setNotification({
            title: t('global.success'),
            message: t('settings.products.packagings.notif.update'),
            type: 'success',
          });

          reset({
            value: res.updatePackagingOption.id,
            label: res.updatePackagingOption.label_fr,
            label_gb: res.updatePackagingOption.label_gb,
            isUsed: res.updatePackagingOption.isUsed,
            is_active: res.updatePackagingOption.is_active,
          });

          setInstruction({
            value: res.updatePackagingOption.id,
            label: res.updatePackagingOption.label_fr,
            label_gb: res.updatePackagingOption.label_gb,
            isUsed: res.updatePackagingOption.isUsed,
            is_active: res.updatePackagingOption.is_active,
          });
          setUpdateLoading(false);
        },

        onError: (error) => {
          setError(error);
          setUpdateLoading(false);
        },
      });
    }
  });

  return (
    <Card>
      <div>
        <div className="space-y-4 p-4 ">
          <div className="sm:flex-auto">
            <h1 className="text-lg font-medium leading-6 text-gray-900">
              {t('settings.products.packagings.title')}
            </h1>
            <p className="mt-2 text-sm text-gray-500">
              {t('settings.products.packagings.subtitle')}
            </p>
          </div>
        </div>
        <DeletePackagingModal
          isOpen={displayModal}
          reset={resetAfterDelete}
          setDisplayModal={setDisplayModal}
          instruction={instruction}
        />
        <div className="p-4">
          <label className="truncate text-sm font-medium text-gray-500">
            {t('settings.products.packagings.form.label')}
          </label>

          <SelectCreatable
            options={packagingOptions}
            onChange={onChangeInstruction}
            onCreateOption={onCreateInstruction}
            loadingOptions={loadingPackagingOptions}
            value={instruction}
            onFocus={() => {
              if (!packagingOptions || packagingOptions.length === 0) {
                getAllInstructions();
              }
            }}
          />
        </div>
        {(showEditInstruction || showNewInstruction) && (
          <form onSubmit={onSubmit}>
            <div className="relative p-4">
              <InputTextControl
                label={t('settings.products.packagings.form.input-french-label')}
                name="label"
                control={control}
                isRequired
              />
            </div>
            <div className="relative p-4">
              <InputTextControl
                label={t('settings.products.packagings.form.input-english-label')}
                name="label_gb"
                control={control}
                isRequired
              />
            </div>
            {instruction?.isUsed && (
              <div className="p-4">
                <InformationAlert>
                  {t('settings.products.packagings.form.is-used')}
                </InformationAlert>
              </div>
            )}
            <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
              {showEditInstruction && (
                <>
                  <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                    <Button
                      type="button"
                      theme="outlineDanger"
                      disabled={instruction?.isUsed}
                      onClick={() => setDisplayModal(true)}>
                      {t('global.actions.delete')}
                    </Button>
                    {instruction?.is_active ? (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(true)}
                        loading={activateLoading}
                        disabled={updateLoading && activateLoading}>
                        {t('global.actions.deactivate')}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(false)}
                        loading={activateLoading}
                        disabled={updateLoading && activateLoading}>
                        {t('global.actions.activate')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      theme="primary"
                      disabled={!isDirty || updateLoading}
                      loading={updateLoading}>
                      {t('global.actions.save')}
                    </Button>
                  </div>
                </>
              )}
              {showNewInstruction && (
                <>
                  <Button
                    type="button"
                    theme="transparent"
                    disabled={createPackagingOptionLoading}
                    onClick={onCancel}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    theme="primary"
                    disabled={createPackagingOptionLoading}
                    loading={createPackagingOptionLoading}>
                    {t('global.actions.add')}
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </div>
    </Card>
  );
};
export default Form;
