import React, { useEffect } from 'react';
import { useForm, FieldValues } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { designersQuery } from 'graphql/Queries/Product/Editorial/designers';
import { updateDesignerWordMutation } from 'graphql/Mutations/Product/Editorial/updateDesignerWord';
import { Designer, ID, OptionProps } from 'types/Global';
import { editorialQuery } from 'graphql/Queries/Product/Editorial/editorial';
import { SelectControl } from 'components/Common/SelectControl';
import { createProductTranslationMutation } from 'graphql/Mutations/Product/Editorial/createProductTranslation';
import useNotification from 'hooks/useNotification';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';
import { GroupBase, SingleValueProps, components } from 'react-select';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

type DesignerOption = {
  photoClientUrl: string;
} & OptionProps;

type DesignerDisplayProps = {
  label: string;
  photoClientUrl: string;
};

type Props = {
  designerWord: string | null;
  translationId: ID | null;
  isLoading: boolean;
  designer: Designer | null;
  setTranslationRequestDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  langId: ID;
};

const DesignerWord = ({
  designerWord,
  translationId,
  isLoading,
  designer,
  setTranslationRequestDisabled,
  langId,
}: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification, setError } = useNotification();

  const { data, error: apolloError } = useQuery(designersQuery);

  const [createProductTranslation, { loading: createProductTranslationLoading }] = useMutation(
    createProductTranslationMutation
  );
  const [updateDesignerWord, { loading: updateDesignerWordLoading }] = useMutation(
    updateDesignerWordMutation
  );

  const mutationLoading = createProductTranslationLoading || updateDesignerWordLoading;

  const designerOptions = data?.designers.map((designer: Designer, index: number) => {
    return {
      key: index,
      value: designer.id,
      label: designer.name,
      photoClientUrl: designer.photoClientUrl,
      isDisabled: !designer.is_active,
    };
  });

  const {
    handleSubmit,
    formState: { isDirty },
    control,
    reset,
    getValues,
    register,
  } = useForm<FieldValues>({
    defaultValues: {
      designer: null,
      designerWord: null,
    },
  });

  useEffect(() => {
    if (designerWord) {
      setTranslationRequestDisabled(false);
    }

    reset({
      designer: designer
        ? {
            value: designer.id,
            label: designer.name,
            isActive: designer.is_active,
            photoClientUrl: designer.photoClientUrl,
          }
        : null,
      designerWord: designerWord ? designerWord : null,
    });
  }, [designer, designerWord, reset, setTranslationRequestDisabled]);

  const DesignerDisplay = ({ label, photoClientUrl }: DesignerDisplayProps) => (
    <div className="flex items-center">
      <img src={photoClientUrl} alt={label} className="w-9 flex-shrink-0 rounded-full" />
      <span className="ml-2">{label}</span>
    </div>
  );

  const SingleValue: React.ComponentType<
    SingleValueProps<OptionProps, false, GroupBase<OptionProps>>
  > = (e) => {
    const data = e.data as DesignerOption;
    return (
      <components.SingleValue {...e}>
        <div className="py-2">
          <DesignerDisplay label={data.label} photoClientUrl={data.photoClientUrl} />
        </div>
      </components.SingleValue>
    );
  };

  const onSubmit = handleSubmit(async (data) => {
    let translationIdToUpdate = translationId;
    if (!translationIdToUpdate) {
      await new Promise<void>((resolve, reject) => {
        createProductTranslation({
          variables: {
            product_id: params.productId,
            lang_id: langId,
          },
          onCompleted: (res) => {
            translationIdToUpdate = res.createProductTranslation.id;
            resolve();
          },
          onError: (error) => {
            setError(error);
            reject(error);
          },
        });
      });
    }
    setTranslationRequestDisabled(true);
    updateDesignerWord({
      refetchQueries: [
        {
          query: editorialQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: translationIdToUpdate,
        designer_id: data.designer ? parseInt(data.designer.value as string) : null,
        designer_word: data.designerWord,
      },
      onCompleted: (res) => {
        setNotification({
          title: t('global.success'),
          message: t('product.editorial.designer-word.notif-success'),
          type: 'success',
        });

        reset({
          designer: res.updateProductTranslation.designer
            ? {
                value: res.updateProductTranslation.designer.id,
                label: res.updateProductTranslation.designer.name,
                isActive: res.updateProductTranslation.designer.is_active,
                photoClientUrl: res.updateProductTranslation.designer.photoClientUrl,
              }
            : null,
          designerWord: res.updateProductTranslation.designer_word,
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  return (
    <Card title={t('product.editorial.designer-word.title')}>
      <form onSubmit={onSubmit}>
        <SelectControl
          control={control}
          name="designer"
          label={t('product.editorial.designer-word.label')}
          apolloError={apolloError}
          placeholder={t('global.actions.select')}
          options={designerOptions}
          isInactive={getValues('designer')?.isActive === false}
          loadingOptions={isLoading}
          addHTML={(option) => {
            const designerOption = option as DesignerOption;
            return (
              <DesignerDisplay
                label={designerOption.label}
                photoClientUrl={designerOption.photoClientUrl}
              />
            );
          }}
          SingleValue={SingleValue}
        />
        <textarea
          rows={10}
          className="mt-6 block w-full rounded-md border-gray-300 py-1.5 text-dark shadow-sm placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500 sm:text-sm sm:leading-6"
          placeholder={t('product.editorial.designer-word.title')}
          {...register('designerWord')}
        />
        <div className="mt-6 flex justify-end">
          <Button
            loading={mutationLoading}
            disabled={!isDirty || isLoading || mutationLoading}
            type="submit">
            {t('global.actions.save')}
          </Button>
        </div>
      </form>
    </Card>
  );
};

export default DesignerWord;
