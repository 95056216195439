import React, { ReactElement } from 'react';
import clsx from 'clsx';

const variants = {
  primary: [
    'text-white bg-primary-500 hover:bg-primary-600 focus-visible:outline',
    'focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500',
    'disabled:bg-primary-500 disabled:opacity-50 shadow-sm',
  ],
  outlinePrimary: [
    'text-dark bg-white hover:bg-gray-50 ring-1 ring-inset ring-primary-500 disabled:bg-gray-100',
    'disabled:opacity-30 focus-visible:outline focus-visible:outline-2',
    'focus-visible:outline-offset-2 focus-visible:outline-primary-500 disabled:opacity-70',
    'shadow-sm',
  ],
  secondary: [
    'text-dark bg-white hover:bg-gray-50 disabled:bg-gray-100 disabled:opacity-30',
    'focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2',
    'focus-visible:outline-primary-500 shadow-sm',
  ],
  danger: [
    'text-white bg-red-500 hover:bg-red-400 focus-visible:outline focus-visible:outline-2',
    'focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:bg-red-600',
    'disabled:opacity-30 shadow-sm',
  ],
  outlineDanger: [
    'text-red-500 bg-white hover:bg-red-500 hover:text-white',
    'ring-1 ring-inset ring-red-500 shadow-sm',
    'disabled:ring-gray-400 disabled:text-gray-400 disabled:opacity-70 disabled:hover:bg-white',
  ],
  transparent: 'text-dark bg-transparent',
};

type ButtonProps = {
  children: ReactElement | string;
  theme?: keyof typeof variants;
  className?: string | undefined;
  loading?: boolean;
} & React.ComponentPropsWithoutRef<'button'>;

export function Button({ children, theme = 'primary', className, loading, ...props }: ButtonProps) {
  let loadingClass = '';

  if (theme === 'secondary' || theme === 'outlinePrimary' || theme === 'outlineDanger') {
    loadingClass = 'border-dark';
  } else {
    loadingClass = 'border-white';
  }

  className = clsx(
    'flex justify-center rounded-md px-3 py-2 text-sm font-semibold',
    variants[theme],
    className
  );

  return (
    <button className={className} {...props}>
      {loading ? (
        <div
          className={clsx(
            'h-5 w-5 animate-spin rounded-full border-b-2 border-dark',
            loadingClass
          )}></div>
      ) : (
        children
      )}
    </button>
  );
}
