import { gql } from 'graphql.macro';

export const allSupplierColorsQuery = gql`
  query allSupplierColors($keyword: String!, $limit: Int!, $page: Int!) {
    allSupplierColors(page: $page, keyword: $keyword, limit: $limit) {
      supplierColors
      currentPage
      lastPage
    }
  }
`;
