import React, { useState } from 'react';
import {
  TrashIcon,
  ArrowDownTrayIcon,
  ChatBubbleBottomCenterTextIcon,
} from '@heroicons/react/24/outline';
import { Document } from 'types/Product';
import moment from 'moment';
import DownloadDocument from 'components/Common/DownloadDocument';
import Tippy from '@tippyjs/react';

type Props = {
  datasheet: Document;
  onPressDelete: () => void;
  onPressComment: () => void;
  setOpenDeleteModal: (value: boolean) => void;
  setOpenSideComment: (isOpen: boolean) => void;
};

function Datasheet({
  datasheet,
  onPressDelete,
  onPressComment,
  setOpenDeleteModal,
  setOpenSideComment,
}: Props) {
  const [showErrorDownloadFile, setShowErrorDownloadFile] = useState<string>('');

  const deleteDatasheet = () => {
    setOpenDeleteModal(true);
    onPressDelete();
  };

  const addComment = () => {
    setOpenSideComment(true);
    onPressComment();
  };

  return (
    <>
      <tr className="border-b" key={datasheet.id}>
        <td className="w-72  p-3 text-sm font-medium text-dark">
          <div className="flex whitespace-nowrap">{datasheet.name}</div>
          {showErrorDownloadFile && (
            <span className="text-xs text-red-600">{showErrorDownloadFile}</span>
          )}
        </td>
        <td className="hidden w-28 p-3 text-sm text-dark lg:table-cell">
          <div className="flex justify-center">
            {datasheet.isLatest ? (
              <div className="text-md inline-flex rounded-full bg-green-500 px-3 py-1 leading-5  text-white xl:table-cell">
                {datasheet.version}
              </div>
            ) : (
              <div className="text-md inline-flex rounded-full bg-gray-300 px-3 py-1 leading-5 xl:table-cell">
                {datasheet.version}
              </div>
            )}
          </div>
        </td>
        <td className="hidden w-32 p-3 text-sm text-dark 2xl:table-cell">
          {moment(datasheet.created_at).format('DD/MM/YYYY')}
        </td>
        <td className="hidden w-40 p-3 text-sm text-dark 2xl:table-cell">
          {datasheet.user ? (
            <Tippy content={datasheet.user.firstname + ' ' + datasheet.user.lastname}>
              <div className="cursor-default truncate">
                {datasheet.user.firstname} {datasheet.user.lastname.charAt(0)}
              </div>
            </Tippy>
          ) : (
            '-'
          )}
        </td>
        <td className="hidden w-72 p-3 text-sm text-dark xl:table-cell">
          {datasheet.comment ? (
            <Tippy content={datasheet.comment}>
              <div className="w-52 cursor-default truncate">{datasheet.comment}</div>
            </Tippy>
          ) : (
            '-'
          )}
        </td>
        <td className="w-40">
          <div className="flex h-full items-center justify-end gap-6 p-3.5 text-sm">
            <ChatBubbleBottomCenterTextIcon
              className="h-6 cursor-pointer text-secondary hover:text-blue-500"
              onClick={() => addComment()}
            />
            <DownloadDocument
              file={datasheet}
              setShowErrorDownloadFile={setShowErrorDownloadFile}
              icon={
                <ArrowDownTrayIcon className="h-6 cursor-pointer text-secondary hover:text-green-500" />
              }
            />
            <TrashIcon
              className="h-6 cursor-pointer text-secondary hover:text-red-500"
              onClick={() => deleteDatasheet()}
            />
          </div>
        </td>
      </tr>
    </>
  );
}

export default Datasheet;
