import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/Product';
import Card from 'components/Common/Card';
import ProductCard from '../Card';

type Props = {
  product: Product;
};

const Pack = ({ product }: Props) => {
  const { t } = useTranslation();
  return (
    <Card title={t('product.preview.pack.title')}>
      <div className="space-y-6">
        <p className="text-right text-sm font-semibold text-dark">
          {t('product.preview.pack.content', { number: product.totalConstituentsQuantity })}
        </p>
        <div className="grid gap-6 lg:grid-cols-2 2xl:grid-cols-4">
          {product.constituents.map((combination, i) => (
            <ProductCard
              key={i}
              product={combination.child}
              quantity={combination.child_product_qty}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Pack;
