import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { productCustomsNomenclatureQuery } from 'graphql/Queries/Product/CustomsNomenclature/productCustomsNomenclature';
import { updateCustomsNomenclatureMutation } from 'graphql/Mutations/Product/updateCustomsNomenclature';
import { ApolloErrorAlert, ErrorAlert } from 'components/Common/Alerts';
import { Product } from 'types/Product';
import { Button } from 'components/Common/Button';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { ID } from 'types/Global';
import InputText from 'components/Common/InputText';
import Card from 'components/Common/Card';
import Title from 'components/Common/Title';
import useNotification from 'hooks/useNotification';
import Hierarchy from './Hierarchy';
import { CUSTOMS_SITE_URL } from 'utils/Urls';

const Index = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();
  const [searchCode, setSearchCode] = useState('');
  const [customsNomenclatureId, setCustomsNomenclatureId] = useState<ID | null>(null);

  const {
    loading: loadingProduct,
    error: errorProduct,
    data: dataProduct,
  } = useQuery(productCustomsNomenclatureQuery, {
    variables: { id: params.productId },
  });

  const [updateCustomsNomenclature, { loading: loadingMutation, error: errorMutation }] =
    useMutation(updateCustomsNomenclatureMutation);

  const onChangeCodeToSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchCode(e.target.value.replace(/[^0-9]/g, ''));
  };

  const { productExists } = useProductContextType();
  const product: Product | undefined = dataProduct?.product;

  const onSubmit = () => {
    if (!customsNomenclatureId) return;
    updateCustomsNomenclature({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productCustomsNomenclatureQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        productId: params.productId,
        customsNomenclatureId,
      },
      onCompleted: () => {
        setNotification({
          type: 'success',
          title: t('global.success'),
          message: t('product.customs-nomenclature.success'),
        });

        setSearchCode('');
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  if (!productExists) return <></>;

  return (
    <>
      <Title title={t('product.customs-nomenclature.title')} />
      {errorProduct ? (
        <ApolloErrorAlert error={errorProduct} />
      ) : !loadingProduct && !product ? (
        <ErrorAlert>{t('product.error')}</ErrorAlert>
      ) : (
        <Card>
          <div className="space-y-6">
            <div className="flex items-center justify-between">
              <div className="xl:flex xl:items-end xl:gap-2">
                <InputText
                  label={t('product.customs-nomenclature.code')}
                  placeholder={t('product.customs-nomenclature.placeholder')}
                  value={searchCode}
                  onChange={onChangeCodeToSearch}
                  disabled={loadingMutation}
                />
                {(customsNomenclatureId || product?.customsNomenclature?.code) && (
                  <Link
                    className="text-sm font-medium text-blue-600 hover:text-blue-700 hover:underline"
                    to={CUSTOMS_SITE_URL}
                    target="_blank">
                    {t('product.customs-nomenclature.go-to-site')}
                  </Link>
                )}
              </div>
              <div>
                <p className="font-medium text-dark">
                  {t('product.customs-nomenclature.current-code')}
                </p>
                {loadingProduct ? (
                  <div className="skeleton h-8 w-40" />
                ) : (
                  <p className="text-2xl font-semibold text-primary-500">
                    {product?.customsNomenclature?.code ?? '-'}
                  </p>
                )}
              </div>
            </div>
            {loadingProduct ? (
              <div className="skeleton h-96 w-full" />
            ) : product?.customsNomenclature?.code || searchCode ? (
              <Hierarchy
                defaultCode={product?.customsNomenclature?.code ?? ''}
                searchCode={searchCode}
                customsNomenclatureId={customsNomenclatureId}
                setCustomsNomenclatureId={setCustomsNomenclatureId}
              />
            ) : (
              <div className="flex h-96 flex-col items-center justify-center gap-6">
                <p className="text-center font-medium text-dark">
                  {t('product.customs-nomenclature.no-code')}
                </p>
                <Link
                  className="text-sm font-medium text-blue-600 hover:text-blue-700 hover:underline"
                  to={CUSTOMS_SITE_URL}
                  target="_blank">
                  {t('product.customs-nomenclature.go-to-site')}
                </Link>
              </div>
            )}
            {errorMutation && <ApolloErrorAlert error={errorMutation} />}
            <div className="flex justify-end">
              <Button
                disabled={
                  !customsNomenclatureId ||
                  customsNomenclatureId == product?.customsNomenclature?.id ||
                  loadingMutation
                }
                onClick={onSubmit}
                loading={loadingMutation}>
                {t('global.actions.save')}
              </Button>
            </div>
          </div>
        </Card>
      )}
    </>
  );
};

export default Index;
