import React, { useEffect } from 'react';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { universesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/universes';
import { editProductUniverseMutation } from 'graphql/Mutations/Settings/Products/Taxonomy/editUniverseName';
import { ProductUniverse } from 'types/Product';
import { InputTextControl } from 'components/Common/InputTextControl';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import SlideOver from 'components/Common/SlideOver';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  universe: ProductUniverse | null;
};

const EditUniverseSlide = ({ universe, setOpen, open }: Props) => {
  const { setNotification } = useNotification();

  const [editUniverseName, { error, loading, reset: resetMutation }] = useMutation(
    editProductUniverseMutation
  );

  const schema = yup.object().shape({
    label: yup.string().max(50, t('form.string.max')),
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    reset({
      label: universe?.label,
    });
  }, [reset, universe]);

  const close = () => {
    setOpen(false);
    reset();
    resetMutation();
  };

  const onSubmit = handleSubmit((data) => {
    editUniverseName({
      variables: {
        id: universe?.id,
        label: data.label,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: universesQuery }],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('settings.taxonomy.universe.update-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('settings.taxonomy.actions.edit-universe')}
      buttonText={t('global.actions.modify')}
      isDirty={isDirty}
      onSubmit={onSubmit}
      onCancel={close}
      loading={loading}
      error={error}>
      <div className="space-y-6">
        <InputTextControl label={t('global.name')} name="label" control={control} isRequired />
      </div>
    </SlideOver>
  );
};
export default EditUniverseSlide;
