import React from 'react';
import { useTranslation } from 'react-i18next';
import { Disclosure } from '@headlessui/react';
import MultiSelect from './FormUtils/MultiSelect';
import FieldLegend from './FormUtils/FieldLegend';
import { Control } from 'react-hook-form';

type Props = {
  control: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  materialFilterBucket: any;
};

const MaterialFilter = ({ control, materialFilterBucket }: Props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const materialSelect = materialFilterBucket.map((materials: any, index: number) => {
    return {
      key: index,
      label: materials.materials.hits.hits[0]._source.materials.filter(
        (elem: { id: number }) => elem.id === materials.key
      )[0].label,
      value: materials.key,
      minilabel: materials.materials.hits.hits[0]._source.materials.filter(
        (elem: { id: number }) => elem.id === materials.key
      )[0].label,
    };
  });

  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div" className={''}>
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.material.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              {materialSelect.length != 0 && (
                <>
                  <div className="mb-1 mt-3 pl-1">
                    {t('products.filters.material.filter-material')}
                  </div>
                  <MultiSelect name={'materials'} control={control} options={materialSelect} />
                </>
              )}
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default MaterialFilter;
