import { gql } from 'graphql.macro';

export const allFeatureUnitsQuery = gql`
  query AllFeatureUnits {
    allFeatureUnits {
      id
      text
    }
  }
`;
