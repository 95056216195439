import React, { useEffect, useState } from 'react';
import { suppliersQuery } from 'graphql/Queries/Product/Supplier/suppliers';
import { useQuery } from '@apollo/client';
import { Supplier } from 'types/Supplier';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { OptionProps } from 'types/Global';
import { FiltersProps } from './Index';
import Datepicker from 'react-tailwindcss-datepicker';
import Select from 'components/Common/Select';
import SlideOver from 'components/Common/SlideOver';
import { DateValueType } from 'react-tailwindcss-datepicker/dist/types';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setFilters: React.Dispatch<React.SetStateAction<FiltersProps>>;
  defaultValues: FiltersProps;
};

const Filters = ({ open, setOpen, setPage, setFilters, defaultValues }: Props) => {
  const { t } = useTranslation();

  const [suppliers, setSuppliers] = useState([]);
  const [supplier, setSupplier] = useState<OptionProps | null>(defaultValues.supplier);
  const [departureDate, setDepartureDate] = useState<DateValueType>(defaultValues.departureDate);
  const [arrivalDate, setArrivalDate] = useState<DateValueType>(defaultValues.arrivalDate);
  const [startCompletion, setStartCompletion] = useState(defaultValues.completion.start);
  const [endCompletion, setEndCompletion] = useState(defaultValues.completion.end);

  const { handleSubmit } = useForm();

  useEffect(() => {
    setSupplier(defaultValues.supplier);
    setDepartureDate(defaultValues.departureDate);
    setArrivalDate(defaultValues.arrivalDate);
    setStartCompletion(defaultValues.completion.start);
    setEndCompletion(defaultValues.completion.end);
  }, [open, defaultValues]);

  const onSubmit = handleSubmit(() => {
    setFilters((filters) => {
      return {
        productName: filters.productName,
        supplierId: supplier?.value ?? null,
        supplier: supplier,
        departureDate,
        arrivalDate,
        completion: {
          start: startCompletion,
          end: endCompletion,
        },
      } as FiltersProps;
    });
    setOpen(false);
    setPage(1);
  });

  const clearFilters = () => {
    setOpen(false);
  };

  const { loading, error } = useQuery(suppliersQuery, {
    onCompleted: (data) => {
      setSuppliers(
        data.suppliers.map((supplier: Supplier) => {
          return {
            value: supplier.id,
            key: supplier.id,
            label: supplier.name,
          };
        })
      );
    },
  });

  const onChangeSupplier = (data: OptionProps | null) => {
    setSupplier(data);
  };

  const onChangeStartCompletion = (value: string) => {
    let intValue = parseInt(value);
    if (intValue > endCompletion) {
      intValue = endCompletion;
    }
    setStartCompletion(intValue);
  };

  const onChangeEndCompletion = (value: string) => {
    let intValue = parseInt(value);
    if (intValue < startCompletion) {
      intValue = startCompletion;
    }
    setEndCompletion(intValue);
  };

  const filters = [
    {
      name: t('products.future-arrivals.filters.supplier'),
      content: (
        <Select
          value={supplier}
          onChange={onChangeSupplier}
          options={suppliers}
          loadingOptions={loading}
          apolloError={error}
          isClearable
        />
      ),
      open: !!defaultValues.supplierId,
    },
    {
      name: t('products.future-arrivals.filters.departure-date'),
      content: (
        <Datepicker
          value={departureDate}
          onChange={setDepartureDate}
          primaryColor={'blue'}
          inputClassName="w-full rounded-md border-gray-300 py-1.5 text-dark shadow-sm placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500 sm:text-sm sm:leading-6"
          placeholder={''}
          showShortcuts
          useRange={false}
        />
      ),
      open: !!defaultValues.departureDate.startDate,
    },
    {
      name: t('products.future-arrivals.filters.arrival-date'),
      content: (
        <Datepicker
          value={arrivalDate}
          onChange={setArrivalDate}
          primaryColor={'blue'}
          inputClassName="w-full rounded-md border-gray-300 py-1.5 text-dark shadow-sm placeholder:text-gray-400 focus:border-primary-500 focus:ring-primary-500 sm:text-sm sm:leading-6"
          placeholder={''}
          showShortcuts
          useRange={false}
        />
      ),
      open: !!defaultValues.arrivalDate.startDate,
    },
    {
      name: t('products.future-arrivals.filters.completion'),
      content: (
        <div className="flex items-center">
          <select
            className="w-full rounded-md border-gray-300 text-dark shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            value={startCompletion}
            onChange={(e) => onChangeStartCompletion(e.target.value)}>
            {[...Array(11)].map((x, i) => (
              <option key={i} value={i * 10} disabled={i * 10 > endCompletion}>
                {i * 10} %
              </option>
            ))}
          </select>
          <span className="px-3">{t('global.at')}</span>
          <select
            className="w-full rounded-md border-gray-300 text-dark shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
            value={endCompletion}
            onChange={(e) => onChangeEndCompletion(e.target.value)}>
            {[...Array(11)].map((x, i) => (
              <option key={i} value={i * 10} disabled={i * 10 < startCompletion}>
                {i * 10} %
              </option>
            ))}
          </select>
        </div>
      ),
      open: defaultValues.completion.start !== 0 || defaultValues.completion.end !== 100,
    },
  ];

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('products.future-arrivals.filters.title')}
      buttonText={t('global.actions.validate')}
      onSubmit={onSubmit}
      onCancel={clearFilters}>
      <>
        {filters.map((filter) => (
          <Disclosure
            defaultOpen={filter.open}
            key={filter.name}
            as="div"
            className="border-t border-gray-200 px-4 py-6">
            {({ open }) => (
              <>
                <h3 className="-mx-2 -my-3 flow-root">
                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                    <span className="font-medium text-dark">{filter.name}</span>
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <MinusIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="pt-6">{filter.content}</Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </>
    </SlideOver>
  );
};

export default Filters;
