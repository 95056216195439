import React from 'react';
import WebExpertLogo from 'WebExpertLogo';

const Footer = () => {
  return (
    <footer>
      <div className="p-6">
        <a href="https://webexpertbusiness.com/">
          <span className="sr-only">Web Expert Business</span>
          <WebExpertLogo />
        </a>
      </div>
    </footer>
  );
};
export default Footer;
