import React from 'react';
import Coordinates from './Coordinates/Index';
import PurchaseInformations from './PurchaseInformations/Index';
import Comment from './Comment';
import PurchaseOrderComment from './PurchaseOrderComment';
import Bank from './Bank/Index';
import Title from 'components/Common/Title';
import PrincipalContact from './PrincipalContact';
import { useSupplierContextType } from 'components/Contexts/SupplierContext';
import { t } from 'i18next';

const Index = () => {
  const { supplierExists } = useSupplierContextType();

  if (!supplierExists) return <></>;

  return (
    <div className="space-y-6">
      <Title title={t('supplier.general-information')} />
      <div className="grid gap-6 2xl:grid-cols-5">
        <div className="2xl:col-span-3">
          <Coordinates />
        </div>
        <div className="2xl:col-span-2">
          <PrincipalContact />
        </div>
      </div>
      <Comment />
      <PurchaseInformations />
      <PurchaseOrderComment />
      <Bank />
    </div>
  );
};
export default Index;
