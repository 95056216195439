import React from 'react';
import { getInitials } from 'utils/string';

interface InitialAvatarProps {
  name: string;
}

const InitialAvatar = ({ name }: InitialAvatarProps) => {
  return (
    <span className="inline-flex h-24 w-24 items-center justify-center rounded-full bg-white shadow-lg">
      <span className="text-3xl font-semibold uppercase leading-none text-dark">
        {getInitials(name)}
      </span>
    </span>
  );
};

export default InitialAvatar;
