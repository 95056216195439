import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Control,
  FieldNamesMarkedBoolean,
  FieldValues,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { suppliersQuery } from 'graphql/Queries/Product/Supplier/suppliers';
import { Supplier } from 'types/Supplier';
import { OptionProps } from 'types/Global';
import { SelectControl } from 'components/Common/SelectControl';
import { InputTextControl } from 'components/Common/InputTextControl';
import { CURRENCIES } from 'global';
import { Toggle } from 'components/Common/Toggle';
import { InputTextAreaControl } from 'components/Common/InputTextAreaControl';

type Props = {
  control: Control;
  getValues: UseFormGetValues<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  register: UseFormRegister<FieldValues>;
  hasModel?: boolean;
  hasSupplier?: boolean;
  dirtyFields: Partial<Readonly<FieldNamesMarkedBoolean<FieldValues>>>;
};

const InformationsForm = ({
  control,
  getValues,
  setValue,
  hasModel,
  dirtyFields,
  hasSupplier,
}: Props) => {
  const { t } = useTranslation();

  const [suppliers, setSuppliers] = useState<OptionProps[]>([]);

  const { loading, error } = useQuery(suppliersQuery, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setSuppliers(
        data.suppliers.map((supplier: Supplier) => {
          return {
            value: supplier.id,
            label: supplier.name,
            isDisabled: !supplier.active,
          };
        })
      );
    },
  });

  return (
    <div className="flex flex-col space-y-6">
      <SelectControl
        control={control}
        name="supplier"
        label={t('product.brand-label.madeIn.title')}
        options={suppliers}
        loadingOptions={loading}
        apolloError={error}
      />
      <div>
        <div className="mb-4 flex items-center">
          <div className="w-full border-t border-gray-300" />
        </div>

        <div className="flex flex-col space-y-8">
          <InputTextControl
            control={control}
            name="factory_pn"
            label={t('product.purchase-information.informations.supplier-name')}
          />
          <div className="w-1/2">
            <InputTextControl
              control={control}
              name="price"
              label={t('product.purchase-information.informations.price')}
              currencyCode
              select={{
                name: 'currency_code',
                options: CURRENCIES,
              }}
            />
          </div>
          <div className="flex justify-between space-x-8">
            <div className="w-full">
              <InputTextControl
                control={control}
                name="minimum_order_quantity"
                label={t('product.purchase-information.informations.moq-reference')}
              />
            </div>
            <div className="w-full">
              <InputTextControl
                control={control}
                name="model_minimum_order_quantity"
                label={t('product.purchase-information.informations.moq-model')}
                disabled={!hasModel}
                disabledText={t(
                  'product.purchase-information.informations.no-model-for-the-product'
                )}
              />
            </div>
            <div className="w-full">
              <InputTextControl
                control={control}
                name="replenishment_time"
                label={t('product.purchase-information.informations.replenishment-time-short')}
                addOnEnd={t('global.days')}
              />
            </div>
          </div>
          <div className="flex justify-between space-x-6">
            <div className="w-1/2">
              <InputTextControl
                control={control}
                name="buy_unit"
                label={t('product.purchase-information.informations.buy-sell-unit')}
              />
            </div>
            <div className="w-1/2">
              <InputTextControl
                control={control}
                name="sell_unit"
                label={t('product.purchase-information.informations.sales-unit')}
              />
            </div>
          </div>
          <Toggle
            enabled={getValues('is_grouped')}
            label={t('product.packaging.tag.grouped-article.label')}
            description={t('product.purchase-information.informations.description')}
            onChange={(e: boolean) => {
              setValue('is_grouped', e, { shouldDirty: true });
            }}
          />
          {(dirtyFields.supplier || dirtyFields.price || dirtyFields.currency_code) &&
            hasSupplier && (
              <InputTextAreaControl
                control={control}
                label={t('product.purchase-information.informations.reason-of-modification')}
                name={'comment'}
                rows={3}
                isRequired
              />
            )}
        </div>
      </div>
    </div>
  );
};
export default InformationsForm;
