import { gql } from 'graphql.macro';

export const switchInShopOnlyMutation = gql`
  mutation SwitchInShopOnly($id: ID!, $in_shop_only: Boolean!, $tag_id: Int!) {
    switchInShopOnly(id: $id, in_shop_only: $in_shop_only, tag_id: $tag_id) {
      id
      in_shop_only
    }
  }
`;
