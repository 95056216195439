import { useTranslation } from 'react-i18next';

const SettingsLayoutProductNavigation = () => {
  const { t } = useTranslation();

  const ProductNavigation = [
    {
      name: t('settings.menu.products.taxonomie'),
      href: '/settings/taxonomy',
    },
    {
      name: t('settings.menu.products.models-styles'),
      href: '/settings/model-style',
    },
    {
      name: t('settings.menu.products.materials-and-colors'),
      href: '/settings/materials-and-colors',
    },
    {
      name: t('settings.menu.products.instructions'),
      href: '/settings/assembly-instructions',
    },
    {
      name: t('settings.menu.products.designer'),
      href: '/settings/designer',
    },
    {
      name: t('settings.menu.products.packagings'),
      href: '/settings/packagings',
    },
    // {
    //   name: t('settings.menu.products.creation-objectives'),
    //   href: '/settings/creation-objectives',
    // },
  ];

  return ProductNavigation;
};
export default SettingsLayoutProductNavigation;
