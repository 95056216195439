import { gql } from 'graphql.macro';

export const dEEEQuery = gql`
  query DEEE($id: ID!) {
    product(id: $id) {
      id
      totalChildrenDEEEAmount
      constituents {
        id
      }
      deee {
        id
        amount_id
        payed_before
        enable
        created_at
        updated_at
        product {
          id
        }
        amount {
          id
          code
          amount
        }
      }
    }
  }
`;
