import React from 'react';

const SkeletonRow = () => (
  <>
    {[...Array(6)].map((x, i) => (
      <tr key={i} className="border-b">
        <td className="w-fit p-3 text-sm">
          <div className="flex items-center space-x-4 font-medium text-dark">
            <div className="skeleton h-6 w-24" />
          </div>
        </td>
        <td className=" w-fit  p-3 pr-12 text-sm">
          <div className="flex space-x-2">
            <div className="skeleton h-10 w-32" />
            <div className="skeleton h-10 w-32" />
            <div className="skeleton h-10 w-32" />
          </div>
        </td>
        <td className="hidden w-fit p-3 text-sm 2xl:table-cell">
          <div>
            <div className="skeleton h-6 w-24 " />
          </div>
        </td>
        <td className="hidden w-fit p-3 text-sm 2xl:table-cell">
          <div className="skeleton h-6 w-32" />
        </td>
      </tr>
    ))}
  </>
);

export default SkeletonRow;
