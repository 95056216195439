import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { allSupplierContactsQuery } from 'graphql/Queries/Supplier/Contacts/allSupplierContacts';
import { EnvelopeIcon, DevicePhoneMobileIcon, PhoneIcon } from '@heroicons/react/24/outline';
import { getInitials } from 'utils/string';
import { Button } from 'components/Common/Button';
import { SupplierContact } from 'types/Supplier';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import Badge from 'components/Common/Badge';
import Card from 'components/Common/Card';
import UpdatePrincipalContact from './UpdatePrincipalContact';

const PrincipalContact = () => {
  const { t } = useTranslation();
  const params = useParams();

  const [principalContact, setPrincipalContact] = useState<SupplierContact | null>(null);

  const [openSlide, setOpenSlide] = useState<boolean>(false);

  const { data, loading } = useQuery(allSupplierContactsQuery, {
    variables: { supplier_id: params.supplierId },
    fetchPolicy: 'network-only',
  });

  const contacts: SupplierContact[] | undefined = data?.allSupplierContacts;

  const getPrincipalContact = useCallback(() => {
    if (!contacts) return;
    const principal = contacts.find((contact: SupplierContact) => contact.is_principal);

    if (!principal) return;
    setPrincipalContact(principal);
  }, [contacts]);

  useEffect(() => {
    getPrincipalContact();
  }, [getPrincipalContact]);

  return (
    <div className="h-full">
      <UpdatePrincipalContact setOpen={setOpenSlide} open={openSlide} contact={principalContact} />
      <Card>
        <div className="h-full">
          {loading ? (
            <div className="flex h-full flex-col items-center justify-center space-y-6">
              <div className="skeleton h-6 w-72" />
              <div className="skeleton h-4 w-36" />
            </div>
          ) : principalContact ? (
            <div className="flex flex-col space-y-10">
              <div className="flex items-center space-x-10">
                <span
                  className={clsx(
                    principalContact.is_principal ? 'relative' : '',
                    `inline-flex h-20 w-20 items-center  justify-center rounded-full bg-light`
                  )}>
                  <span className="text-3xl font-medium leading-none text-dark">
                    {getInitials(principalContact.name)}
                  </span>
                  {principalContact.is_principal && (
                    <span className="absolute top-14">
                      <Badge type="primary">{t('supplier.contacts.is-principal')}</Badge>
                    </span>
                  )}
                </span>

                <div className="space-y-2">
                  <div>{principalContact.name}</div>
                  <Badge type="info">{principalContact.type.label}</Badge>
                </div>
              </div>

              <div className="flex flex-col space-y-4">
                <div className="flex items-center space-x-4">
                  <EnvelopeIcon className="h-6 w-6 text-secondary" />
                  <Tippy content={principalContact.email}>
                    <div className="truncate text-sm text-dark">
                      {principalContact.email ? principalContact.email : '-'}
                    </div>
                  </Tippy>
                </div>
                <div>
                  <div className="flex items-center space-x-4">
                    <DevicePhoneMobileIcon className="h-6 w-6 text-secondary" />
                    <div className="text-sm text-dark">
                      <Tippy content={principalContact.mobile}>
                        <div className="truncate text-sm text-dark">
                          {principalContact.mobile ? principalContact.mobile : '-'}
                        </div>
                      </Tippy>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="flex items-center space-x-4">
                    <PhoneIcon className="h-6 w-6 text-secondary" />
                    <Tippy content={principalContact.phone}>
                      <div className="truncate text-sm text-dark">
                        {principalContact.phone ? principalContact.phone : '-'}
                      </div>
                    </Tippy>
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button onClick={() => setOpenSlide(true)}>
                    {t('supplier.contacts.change-contact')}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div
              className={clsx(
                contacts && contacts?.length > 0 ? 'space-y-6' : '',
                'flex h-full flex-col items-center justify-center text-center text-sm'
              )}>
              <div>
                {contacts && contacts?.length > 0 ? (
                  <div className="text-dark">{t('supplier.contacts.no-principal-contact')}</div>
                ) : (
                  <div className="text-dark">{t('supplier.contacts.no-contact')}</div>
                )}
              </div>
              <div className="flex justify-center">
                {contacts && contacts?.length > 0 && (
                  <Button onClick={() => setOpenSlide(true)}>
                    {t('supplier.contacts.change-contact')}
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};
export default PrincipalContact;
