import React from 'react';
import { useForm } from 'react-hook-form';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { updateActiveCategoryMutation } from 'graphql/Mutations/Settings/Products/Taxonomy/updateActiveCategory';
import { productCategoriesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/productCategories';
import { ProductCategory } from 'types/Product';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import Modal from 'components/Common/Modal';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  category: ProductCategory | null;
};

const ActivationCategoryModal = ({ setOpen, open, category }: Props) => {
  const { setNotification } = useNotification();
  const { handleSubmit } = useForm();

  const [updateActiveCategory, { loading, error }] = useMutation(updateActiveCategoryMutation);

  const submit = handleSubmit(() => {
    updateActiveCategory({
      variables: {
        id: category?.id,
        is_active: !category?.is_active,
      },
      awaitRefetchQueries: true,
      refetchQueries: [{ query: productCategoriesQuery, variables: { id: category?.universe.id } }],
      onCompleted: (data) => {
        const message = data.updateActiveCategory.is_active
          ? t('settings.taxonomy.category.active-success')
          : t('settings.taxonomy.category.deactivate-success');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <Modal
      theme="warning"
      title={t('global.warning')}
      buttonText={
        category?.is_active ? t('global.actions.deactivate') : t('global.actions.activate')
      }
      open={open}
      onClose={() => setOpen(false)}
      icon={<ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />}
      onSubmit={submit}
      loading={loading}
      error={error}>
      <div className="mt-6 space-y-6">
        <div className="flex flex-col space-y-4">
          <p>
            {category?.is_active
              ? t('settings.taxonomy.category.deactivate')
              : t('settings.taxonomy.category.activate')}
          </p>
          {category?.is_active ? (
            <span className="font-semibold">
              {t('settings.taxonomy.category.deacticate-cascade')}
            </span>
          ) : (
            <span className="font-semibold">
              {t('settings.taxonomy.category.activation-cascade')}
            </span>
          )}
        </div>
      </div>
    </Modal>
  );
};
export default ActivationCategoryModal;
