import React from 'react';
import { useTranslation } from 'react-i18next';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { Product } from 'types/Product';
import Card from 'components/Common/Card';
import DownloadDocument from 'components/Common/DownloadDocument';

type Props = {
  loading: boolean;
  product?: Product;
};

const Main = ({ loading, product }: Props) => {
  const { t } = useTranslation();
  return (
    <Card>
      <div className="grid grid-cols-1 gap-6 2xl:grid-cols-3">
        {loading ? (
          <div className="skeleton h-full" />
        ) : (
          <Card>
            <div className="flex h-full items-center justify-center">
              <div className="w-3/4">
                {product?.primaryImage?.clientUrl ? (
                  <img
                    className="italic text-gray-400"
                    src={product.primaryImage.clientUrl}
                    alt={t('product.header.image-not-found')}
                  />
                ) : (
                  <PhotoIcon className="feather feather-image text-dark" />
                )}
              </div>
            </div>
          </Card>
        )}
        <div className="flex items-center justify-center gap-2 text-sm">
          <div className="space-y-2 text-right font-medium text-secondary">
            <p className="truncate">{t('product.preview.main.universe')}</p>
            <p className="truncate">{t('product.preview.main.category')}</p>
            <p className="truncate">{t('product.preview.main.type')}</p>
            <p className="truncate">{t('product.preview.main.family')}</p>
            <p className="truncate">{t('product.preview.main.collection')}</p>
            <p className="truncate">{t('product.preview.main.style')}</p>
            <p className="truncate">{t('product.preview.main.model')}</p>
          </div>
          <div className="space-y-2 text-left font-semibold uppercase text-dark 2xl:w-2/3">
            {loading ? (
              <>
                <div className="skeleton h-5 w-40" />
                <div className="skeleton h-5 w-40" />
                <div className="skeleton h-5 w-40" />
                <div className="skeleton h-5 w-40" />
                <div className="skeleton h-5 w-40" />
                <div className="skeleton h-5 w-40" />
                <div className="skeleton h-5 w-40" />
              </>
            ) : (
              <>
                <p className="max-w-fit truncate">
                  {product?.taxonomy?.type?.category.universe.label
                    ? product.taxonomy.type.category.universe.label
                    : '-'}
                </p>
                <p className="truncate">
                  {product?.taxonomy?.type?.category.label
                    ? product.taxonomy.type.category.label
                    : '-'}
                </p>
                <p className="truncate">
                  {product?.taxonomy?.type?.label ? product.taxonomy.type.label : '-'}
                </p>
                <p className="truncate">
                  {product?.family?.currentLabelTranslation?.text
                    ? product.family.currentLabelTranslation.text
                    : '-'}
                </p>
                <p className="truncate">
                  {product?.taxonomy?.collection?.label ? product.taxonomy.collection.label : '-'}
                </p>
                <p className="truncate">
                  {product?.taxonomy?.style?.label ? product.taxonomy.style.label : '-'}
                </p>
                <p className="truncate">{product?.model?.name ? product.model.name : '-'}</p>
              </>
            )}
          </div>
        </div>
        <div className="space-y-6">
          {!product?.isCombination && (
            <>
              <div>
                <p className="text-sm font-semibold text-dark">
                  {t('product.preview.main.customs-nomenclature')}
                </p>
                {loading ? (
                  <div className="skeleton h-8 w-44" />
                ) : (
                  <p className="text-2xl font-medium text-primary-500">
                    {product?.customsNomenclature?.code ?? '-'}
                  </p>
                )}
              </div>
              <div>
                <p className="text-sm font-semibold text-dark">{t('product.preview.main.ean')}</p>

                {loading ? (
                  <div className="skeleton h-8 w-44" />
                ) : (
                  <p className="text-2xl font-medium text-secondary">
                    {product?.ean_code ? product.ean_code : '-'}
                  </p>
                )}
              </div>
              <div className="space-y-2">
                <p className="pb-1 text-sm font-semibold text-dark">
                  {t('product.preview.main.documents')}
                </p>
                {loading ? (
                  <>
                    <div className="skeleton h-5 w-60" />
                    <div className="skeleton h-5 w-60" />
                  </>
                ) : (
                  <>
                    {!product?.notice && !product?.datasheet && '-'}
                    {product?.notice && <DownloadDocument type="link" file={product.notice} />}
                    {product?.datasheet && (
                      <DownloadDocument type="link" file={product.datasheet} />
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </Card>
  );
};

export default Main;
