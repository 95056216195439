import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { InputTextAreaControl } from 'components/Common/InputTextAreaControl';
import { FieldValues, useForm } from 'react-hook-form';
import { productQuery } from 'graphql/Queries/Product/Visibility/Comments/productComments';
import { updateProductComments } from 'graphql/Mutations/Product/Visibility/Comments/updateProductComments';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';
import useNotification from 'hooks/useNotification';

const VisibilityComments = () => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [mutation, { loading: mutationLoading, error: mutationError }] =
    useMutation(updateProductComments);
  const { data, loading, error } = useQuery(productQuery, {
    variables: {
      id: params.productId,
    },
    fetchPolicy: 'network-only',
  });

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<FieldValues>();

  useEffect(() => {
    if (!data) return;
    reset({
      comment: data.product?.comments ?? '',
    });
  }, [reset, data]);

  const onSubmit = handleSubmit((data) => {
    mutation({
      variables: {
        id: params.productId,
        comments: data.comment,
      },
      onCompleted: (data) => {
        setNotification({
          title: t('global.success'),
          message: t('product.visibility.comments.success'),
          type: 'success',
        });
        reset({
          comment: data.updateProductComments.comments,
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <form onSubmit={onSubmit}>
      <Card>
        <div className="p-4">
          {error || mutationError ? (
            <div className="mb-4">
              <ApolloErrorAlert error={error || (mutationError as ApolloError)} />
            </div>
          ) : null}
          <div>
            {loading ? (
              <div className="skeleton h-48 w-full" />
            ) : (
              <InputTextAreaControl
                control={control}
                label={t('product.visibility.comment.comment-area')}
                name={'comment'}
                rows={7}
              />
            )}
            <div className="mt-6 flex justify-end">
              <Button disabled={!isDirty || mutationLoading} loading={mutationLoading}>
                {t('global.actions.save')}
              </Button>
            </div>
          </div>
        </div>
      </Card>
    </form>
  );
};
export default VisibilityComments;
