import React from 'react';
import { t } from 'i18next';
import { useMutation } from '@apollo/client';
import { universesQuery } from 'graphql/Queries/Settings/Products/Taxonomy/universes';
import { updateActiveUniverseMutation } from 'graphql/Mutations/Settings/Products/Taxonomy/updateActiveUniverse';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { useForm } from 'react-hook-form';
import { ProductUniverse } from 'types/Product';
import Modal from 'components/Common/Modal';
import useNotification from 'hooks/useNotification';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  universe: ProductUniverse | null;
};

const ActivationUniverseModal = ({ open, setOpen, universe }: Props) => {
  const { setNotification } = useNotification();
  const { handleSubmit } = useForm();

  const [updateActiveUniverse, { loading, error }] = useMutation(updateActiveUniverseMutation);

  const submit = handleSubmit(() => {
    updateActiveUniverse({
      variables: {
        id: universe?.id,
        is_active: !universe?.is_active,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: universesQuery,
        },
      ],
      onCompleted: (data) => {
        const message = data.updateActiveUniverse.is_active
          ? t('settings.taxonomy.universe.active-success')
          : t('settings.taxonomy.universe.deactivate-success');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <Modal
      theme="warning"
      title={t('global.warning')}
      buttonText={
        universe?.is_active ? t('global.actions.deactivate') : t('global.actions.activate')
      }
      open={open}
      onClose={() => setOpen(false)}
      icon={<ExclamationTriangleIcon className="h-6 w-6 text-yellow-600" aria-hidden="true" />}
      onSubmit={submit}
      loading={loading}
      error={error}>
      <div className="mt-6 space-y-6">
        <div className="flex flex-col space-y-4">
          <p>
            {universe?.is_active
              ? t('settings.taxonomy.universe.deactivate')
              : t('settings.taxonomy.universe.activate')}
          </p>
          <span className="font-semibold">
            {universe?.is_active ? t('settings.taxonomy.universe.deacticate-cascade') : ''}
          </span>
        </div>
      </div>
    </Modal>
  );
};
export default ActivationUniverseModal;
