import { gql } from 'graphql.macro';

export const ecoMobilierProductTypeQuery = gql`
  query EcoMobilierProductType($id: Int!) {
    typeMaterials(id: $id) {
      materials {
        id
        code
        label
      }
    }
  }
`;
