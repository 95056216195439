import { gql } from 'graphql.macro';

export const createProductCombinationMutation = gql`
  mutation createProductCombination(
    $child_product_id: ID!
    $parent_product_id: ID!
    $child_product_qty: Int!
  ) {
    createProductCombination(
      child_product_id: $child_product_id
      parent_product_id: $parent_product_id
      child_product_qty: $child_product_qty
    ) {
      parent_product_id
      child_product_id
    }
  }
`;
