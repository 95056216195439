import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { SketchPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

type ColorPickerModalProps = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  hexColor: string | null;
  setHexColor: React.Dispatch<React.SetStateAction<string | null>>;
  onCancel: () => void;
};

const ColorPickerModal = ({
  open,
  setOpen,
  hexColor,
  setHexColor,
  onCancel,
}: ColorPickerModalProps) => {
  const { t } = useTranslation();
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <SketchPicker
                    className="w-fit"
                    width="" //Important for w-fit
                    color={`#${hexColor}`}
                    onChange={(color) => {
                      setHexColor(color.hex.substring(1));
                    }}
                  />
                  <div className="mt-10 sm:flex sm:flex-row-reverse">
                    {/* TODO: Use common Buttons with loading and disabled */}
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-primary-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-300 sm:ml-3 sm:w-auto"
                      onClick={() => setOpen(false)}>
                      {t('global.actions.validate')}
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={onCancel}>
                      {t('global.actions.cancel')}
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default ColorPickerModal;
