import { gql } from 'graphql.macro';

export const updateProductTaxonomyMutation = gql`
  mutation UpdateProductTaxonomy($id: ID!, $universe: Mixed, $category: Mixed, $type: Mixed) {
    updateProductTaxonomy(id: $id, universe: $universe, category: $category, type: $type) {
      product {
        id
        taxonomy {
          type {
            id
            label
            category {
              id
              label
              universe {
                id
                label
              }
            }
          }
        }
      }
      updateStatus
    }
  }
`;
