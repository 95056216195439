import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ApolloError, useQuery } from '@apollo/client';
import { stylesQuery } from 'graphql/Queries/Product/ModelsAndCollections/styles';
import { familiesQuery } from 'graphql/Queries/Product/SupplierAndTaxonomy/families';
import { DisplayProducts, DisplayProductsProps, FormatedValues } from './Index';
import { ProductFamily, ProductStyle } from 'types/Product';
import { SelectControl } from 'components/Common/SelectControl';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { Button } from 'components/Common/Button';
import ModelSelect from './ModelSelect';
import CollectionSelectCreatable from './CollectionSelectCreatable';

type Props = {
  loading: boolean;
  defaultValues: FormatedValues;
  updateProduct: (data: FormatedValues) => void;
  setDisplayProducts: React.Dispatch<React.SetStateAction<DisplayProductsProps>>;
  mutationError?: ApolloError;
  mutationLoading: boolean;
};

const Form = ({
  loading,
  defaultValues,
  updateProduct,
  mutationError,
  mutationLoading,
  setDisplayProducts,
}: Props) => {
  const [allStyles, setAllStyles] = useState([]);
  const [allFamilies, setAllFamilies] = useState([]);
  const { t } = useTranslation();

  const { loading: stylesLoading, error: stylesError } = useQuery(stylesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAllStyles(
        data.productStyles?.map((style: ProductStyle) => {
          return {
            key: style.id,
            value: style.id,
            label: style.label ? style.label : '-',
            isDisabled: !style.active,
          };
        })
      );
    },
  });

  const { loading: familiesLoading, error: familiesError } = useQuery(familiesQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAllFamilies(
        data.families?.map((family: ProductFamily) => {
          return {
            label: family.currentLabelTranslation?.text
              ? family.currentLabelTranslation?.text
              : '-',
            value: family.id,
            key: family.id,
            isDisabled: !family.active,
          };
        })
      );
    },
  });

  const {
    formState: { isDirty },
    reset,
    getValues,
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = handleSubmit((values) => {
    updateProduct(values as FormatedValues);
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const updateDisplayProductOnCollection = () => {
    setDisplayProducts({
      id: getValues('collection').value,
      type: DisplayProducts.collection,
      elementLabel: getValues('collection').label,
    });
  };
  const updateDisplayProductOnStyle = () => {
    setDisplayProducts({
      id: getValues('style').value,
      type: DisplayProducts.style,
      elementLabel: getValues('style').label,
    });
  };
  const updateDisplayProductOnFamily = () => {
    setDisplayProducts({
      id: getValues('family').value,
      type: DisplayProducts.family,
      elementLabel: getValues('family').label,
    });
  };
  const updateDisplayProductOnModel = () => {
    setDisplayProducts({
      id: getValues('model')?.value,
      type: DisplayProducts.model,
      elementLabel: getValues('model')?.label,
    });
  };

  return (
    <form className="flex h-full flex-col justify-between" onSubmit={onSubmit}>
      <div className="space-y-6">
        <div>
          <CollectionSelectCreatable
            control={control}
            loading={loading}
            updateDisplayProduct={updateDisplayProductOnCollection}
            isInactive={getValues('collection')?.isActive === false}
          />
          {getValues('collection') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnCollection}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
        <div>
          <SelectControl
            control={control}
            name={'style'}
            label={t('product.model-style.style')}
            options={allStyles}
            loadingOptions={stylesLoading || loading}
            apolloError={stylesError}
            isInactive={getValues('style')?.isActive === false}
            onChange={updateDisplayProductOnStyle}
            inactiveMessage={t('product.taxonomy.form.style-inactive')}
          />
          {getValues('style') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnStyle}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
        <div>
          <SelectControl
            control={control}
            name={'family'}
            label={t('product.model-style.family')}
            options={allFamilies}
            loadingOptions={familiesLoading || loading}
            apolloError={familiesError}
            isInactive={getValues('family')?.isActive === false}
            onChange={updateDisplayProductOnFamily}
            inactiveMessage={t('product.taxonomy.form.family-inactive')}
          />
          {getValues('family') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnFamily}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
        <div>
          <ModelSelect
            control={control}
            loading={loading}
            updateDisplayProduct={updateDisplayProductOnModel}
            isInactive={getValues('model')?.isActive === false}
          />
          {getValues('model') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnModel}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
        {mutationError && <ApolloErrorAlert error={mutationError} />}

        <div className="flex flex-col justify-end">
          <div className="flex justify-end">
            <Button
              type="submit"
              loading={mutationLoading}
              disabled={
                !isDirty ||
                mutationLoading ||
                getValues('material1')?.isActive === false ||
                getValues('material2')?.isActive === false
              }>
              {t('global.actions.save')}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Form;
