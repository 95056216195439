import { Controller, useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeletePaymentMethod from './DeletePaymentMethod';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { InformationAlert } from 'components/Common/Alerts';
import { paymentMethodsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/paymentMethods';
import { createPaymentMethodMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/createPaymentMethod';
import { updatePaymentMethodStatusMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updatePaymentMethodStatus';
import { updatePaymentMethodWithSortMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updatePaymentMethodWithSort';
import SelectCreatable from 'components/Common/SelectCreatable';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const PaymentMethods = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditPaymentMethod, setShowEditPaymentMethod] = useState(false);
  const [showNewPaymentMethod, setShowNewPaymentMethod] = useState(false);
  const [allPaymentMethods, setAllPaymentMethods] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const [getAllPaymentMethods, { data, loading: loadingPaymentMethods, refetch }] = useLazyQuery(
    paymentMethodsQuery,
    {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (data) {
      setAllPaymentMethods(
        data.supplierPaymentMethods?.map((paymentMethod) => {
          return {
            label: paymentMethod.label,
            value: paymentMethod,
            key: paymentMethod.id,
          };
        })
      );
    }
  }, [data]);

  const [createPaymentMethod, { loading: createPaymentMethodLoading }] = useMutation(
    createPaymentMethodMutation
  );

  const [updatePaymentMethodStatus, { loading: updatePaymentMethodStatusLoading }] = useMutation(
    updatePaymentMethodStatusMutation
  );

  const [updatePaymentMethodWithSort, { loading: updatePaymentMethodWithSortLoading }] =
    useMutation(updatePaymentMethodWithSortMutation);

  const onChangePaymentMethod = (e) => {
    setShowEditPaymentMethod(true);
    setShowNewPaymentMethod(false);
    setPaymentMethod(e.value);
    reset({
      label: e.value.label,
      sort: e.value.sort,
    });
  };

  const onCreatePaymentMethod = (value) => {
    setShowEditPaymentMethod(false);
    setShowNewPaymentMethod(true);
    setPaymentMethod({
      value: value,
      label: value,
    });
    reset({
      label: value,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1),
  });

  const {
    control,
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
      sort: 1,
    },
  });

  const onCancel = () => {
    setShowNewPaymentMethod(false);
    setPaymentMethod(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewPaymentMethod) {
      createPaymentMethod({
        variables: { label: data.label },
        onCompleted: async (createdData) => {
          let newPaymentMethod = createdData.createSupplierPaymentMethod;
          setPaymentMethod(newPaymentMethod);
          const { data } = await refetch();
          setAllPaymentMethods(
            data.supplierPaymentMethods?.map((paymentMethod) => {
              return {
                label: paymentMethod.label,
                value: paymentMethod,
                key: paymentMethod.id,
              };
            })
          );
          reset({
            label: newPaymentMethod.label,
            sort: newPaymentMethod.sort,
          });
          setShowEditPaymentMethod(true);
          setShowNewPaymentMethod(false);
          setPaymentMethod(newPaymentMethod);
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.payment-methods.created'),
            type: 'success',
          });
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updatePaymentMethodWithSort({
        variables: {
          id: paymentMethod.id,
          label: data.label,
          sort: parseInt(data.sort),
        },
        onCompleted: async (updateData) => {
          let updatePaymentMethod = updateData.updateSupplierPaymentMethodWithSort;
          setPaymentMethod(updatePaymentMethod);
          const { data } = await refetch();
          setAllPaymentMethods(
            data.supplierPaymentMethods?.map((paymentMethod) => {
              return {
                label: paymentMethod.label,
                value: paymentMethod,
                key: paymentMethod.id,
              };
            })
          );
          reset({
            label: updatePaymentMethod.label,
            sort: updatePaymentMethod.sort,
          });
          setPaymentMethod(updatePaymentMethod);
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.payment-methods.updated'),
            type: 'success',
          });
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const resetAfterDelete = () => {
    setPaymentMethod(null);
    setShowEditPaymentMethod(false);
    setShowNewPaymentMethod(false);
    getAllPaymentMethods();
  };

  const changeActivation = (value) => {
    let message = '';
    if (value) {
      message = t('settings.suppliers.purchase-infos.payment-methods.activate');
    } else {
      message = t('settings.suppliers.purchase-infos.payment-methods.deactivate');
    }
    updatePaymentMethodStatus({
      refetchQueries: [
        {
          query: paymentMethodsQuery,
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: paymentMethod.id,
        active: value,
      },
      onCompleted: (dataReturn) => {
        let data = dataReturn.updateSupplierPaymentMethod;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
          sort: data.sort,
        });
        setPaymentMethod(data);
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  return (
    <>
      <Card>
        <DeletePaymentMethod
          onCloseModal={(loading) => {
            if (!loading) {
              setDisplayModal(false);
            }
          }}
          isOpen={displayModal}
          paymentMethod={paymentMethod}
          reset={resetAfterDelete}
        />
        {loadingPaymentMethods}
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.suppliers.purchase-infos.payment-methods.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.suppliers.purchase-infos.payment-methods.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.suppliers.purchase-infos.payment-methods.list')}
          </label>
          <SelectCreatable
            loadingOptions={loadingPaymentMethods}
            options={allPaymentMethods}
            onCreateOption={onCreatePaymentMethod}
            onChange={onChangePaymentMethod}
            value={paymentMethod}
            onFocus={() => {
              if (!allPaymentMethods || allPaymentMethods.length === 0) {
                getAllPaymentMethods();
              }
            }}
          />
        </div>
        {(showEditPaymentMethod || showNewPaymentMethod) && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="space-y-4 p-4">
                <div>
                  <label className="text-sm font-medium text-gray-700">
                    {t('settings.suppliers.purchase-infos.designation')}
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={classNames(
                        errors.label
                          ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                          : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                        'mt-1 block h-9 w-full flex-1 rounded'
                      )}
                      {...register('label')}
                    />
                    {errors.label && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                      </div>
                    )}
                  </div>
                  {errors.label && (
                    <p className="mt-2 text-sm text-red-500">{t('form.required')}</p>
                  )}
                </div>
                {showEditPaymentMethod && (
                  <div>
                    <label className="text-sm font-medium text-gray-700">
                      {t('settings.suppliers.purchase-infos.sort')}
                    </label>
                    <Controller
                      name="sort"
                      control={control}
                      render={({ field }) => (
                        <select
                          className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-primary-500 sm:max-w-xs sm:leading-6"
                          name="sort"
                          {...field}>
                          {allPaymentMethods.map((element, index) => (
                            <option key={index} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>
                      )}
                    />
                  </div>
                )}
                {paymentMethod.used && (
                  <div className="mt-4">
                    <InformationAlert>
                      {t('settings.suppliers.purchase-infos.payment-methods.used')}
                    </InformationAlert>
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                {showEditPaymentMethod && (
                  <>
                    <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                      <Button
                        type="button"
                        theme="outlineDanger"
                        onClick={() => setDisplayModal(true)}
                        disabled={
                          loadingPaymentMethods ||
                          updatePaymentMethodStatusLoading ||
                          updatePaymentMethodWithSortLoading ||
                          paymentMethod.used
                        }>
                        {t('global.actions.delete')}
                      </Button>
                      {paymentMethod.active ? (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          onClick={() => changeActivation(false)}
                          loading={updatePaymentMethodStatusLoading}
                          disabled={
                            loadingPaymentMethods ||
                            updatePaymentMethodStatusLoading ||
                            updatePaymentMethodWithSortLoading
                          }>
                          {t('global.actions.deactivate')}
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          onClick={() => changeActivation(true)}
                          loading={updatePaymentMethodStatusLoading}
                          disabled={
                            loadingPaymentMethods ||
                            updatePaymentMethodStatusLoading ||
                            updatePaymentMethodWithSortLoading
                          }>
                          {t('global.actions.activate')}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        theme="primary"
                        disabled={
                          loadingPaymentMethods ||
                          updatePaymentMethodStatusLoading ||
                          updatePaymentMethodWithSortLoading ||
                          !isDirty
                        }
                        loading={loadingPaymentMethods || updatePaymentMethodWithSortLoading}>
                        {t('global.actions.save')}
                      </Button>
                    </div>
                  </>
                )}
                {showNewPaymentMethod && (
                  <>
                    <Button
                      type="button"
                      theme="transparent"
                      onClick={onCancel}
                      disabled={loadingPaymentMethods || createPaymentMethodLoading}>
                      {t('global.actions.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      theme="primary"
                      loading={createPaymentMethodLoading}
                      disabled={createPaymentMethodLoading || loadingPaymentMethods}>
                      {t('global.actions.add')}
                    </Button>
                  </>
                )}
              </div>
            </form>
          </>
        )}
      </Card>
    </>
  );
};

export default PaymentMethods;
