import React from 'react';
import { useTranslation } from 'react-i18next';

function DEEESkeleton() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-6 px-6">
      <div className="mt-6 sm:col-span-6">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center"></div>
          <div className="skeleton h-5 w-5"></div>
          <div className="ml-3  text-sm">
            <label htmlFor="deee-paidInAdvance" className=" block text-sm font-medium text-dark">
              {t('product.ecotax.paid-in-advance')}
            </label>
          </div>
        </div>
      </div>
      <div className="mt-2 sm:col-span-6">
        <div className="relative flex items-start">
          <div className="flex h-5 items-center"></div>
          <div className="skeleton h-5 w-5"></div>
          <div className="ml-3 text-sm">
            <label htmlFor="deee-enable" className="block text-sm font-medium text-dark">
              {t('product.ecotax.applicable')}
            </label>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DEEESkeleton;
