import { gql } from 'graphql.macro';

export const productSupplierQuery = gql`
  query ProductSupplier($id: ID!) {
    product(id: $id) {
      id
      replenishment_time
      active
      minimum_order_quantity
      model {
        id
        minimum_order_quantity
      }
      taxonomy {
        id
        buy_unit
        sell_unit
      }
      currentSupplying {
        id
        supplier {
          id
          name
          active
          billing_name
          active
          supplierManager {
            id
            firstname
            lastname
          }
        }
        supplier_product_id
        supplier_id
        is_grouped
        price
        currency {
          id
          code
        }
      }
    }
  }
`;
