import React, { useState } from 'react';
import { t } from 'i18next';
import { useParams } from 'react-router-dom';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@apollo/client';
import { supplierPurchaseInformationsQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/purchaseInformations';
import { Supplier } from 'types/Supplier';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';
import Tippy from '@tippyjs/react';
import Edit from './Edit';

const Index = () => {
  const params = useParams();

  const [openEdit, setOpenEdit] = useState(false);

  const { loading, error, data } = useQuery(supplierPurchaseInformationsQuery, {
    variables: { id: params.supplierId },
    fetchPolicy: 'network-only',
  });

  const supplier: Supplier | undefined = data?.supplier;

  return (
    <>
      <Edit open={openEdit} setOpen={setOpenEdit} supplier={supplier} />
      <Card
        title={t('supplier.purchase-infos.title')}
        addOnEnd={
          supplier && (
            <Tippy content={t('global.actions.modify')}>
              <button type="button" onClick={() => setOpenEdit(true)}>
                <PencilSquareIcon className="w-6 shrink-0 text-secondary hover:text-dark hover:outline-none focus:outline-none" />
              </button>
            </Tippy>
          )
        }>
        <>
          {error && <ApolloErrorAlert error={error} />}
          {loading && (
            <div className="flex gap-6">
              <div className="flex flex-col gap-6">
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
              </div>
              <div className="flex flex-col gap-6">
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
                <div className="skeleton h-4 w-60" />
              </div>
            </div>
          )}
          {supplier && (
            <div className="flex gap-6 text-sm font-medium">
              <div className="flex flex-col gap-6 text-secondary">
                <span>{t('supplier.purchase-infos.supplier-manager')}</span>
                <span>{t('supplier.purchase-infos.incoterm')}</span>
                <span>{t('supplier.purchase-infos.associated-forwarder')}</span>
                <span>{t('supplier.purchase-infos.port')}</span>
                <span>{t('supplier.purchase-infos.payment-method')}</span>
                <span>{t('supplier.purchase-infos.franco')}</span>
                <span>{t('supplier.purchase-infos.replenishment-time')}</span>
              </div>
              <div className="flex flex-col gap-6 text-dark">
                <span>
                  {supplier.supplierManager
                    ? `${supplier.supplierManager.firstname} ${supplier.supplierManager.lastname}`
                    : '-'}
                </span>
                <span>{supplier.incoterm ? supplier.incoterm.label : '-'}</span>
                <span>
                  {supplier.associatedForwarder ? supplier.associatedForwarder.label : '-'}
                </span>
                <span>{supplier.port ? supplier.port.label : '-'}</span>
                <span>{supplier.paymentMethod ? supplier.paymentMethod.label : '-'}</span>
                <span>{supplier.franco ? supplier.franco : '-'}</span>
                <span>
                  {supplier.replenishment_time
                    ? `${supplier.replenishment_time} ${
                        supplier.replenishment_time > 1 ? t('global.days') : t('global.day')
                      }`
                    : '-'}
                </span>
              </div>
            </div>
          )}
        </>
      </Card>
    </>
  );
};

export default Index;
