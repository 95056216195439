import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { OptionProps } from 'react-select';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { allVariantsQuery } from 'graphql/Queries/Product/Features/allVariants';
import { importFeatureInformationsMutation } from 'graphql/Mutations/Product/importFeatureInformations';
import { productFeaturesQuery } from 'graphql/Queries/Product/Features/productFeatures';
import { ID } from 'types/Global';
import { Product, ProductFeature } from 'types/Product';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';
import Select from 'components/Common/Select';
import Checkbox from 'components/Common/Checkbox';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setHasVariant: React.Dispatch<SetStateAction<boolean>>;
};

type VariantOption = {
  id: ID;
  label: string;
  value: string;
  weight: boolean;
  product_weight: number;
  features: ProductFeature[];
} & OptionProps;

const ImportSlide = ({ open, setOpen, setHasVariant }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [weightElements, setWeightElements] = useState<Record<string, boolean>>({});
  const [featureElements, setFeatureElements] = useState<ID[]>([]);
  const [selectedVariant, setSelectedVariant] = useState<VariantOption | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { data, error, loading } = useQuery(allVariantsQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  const [importFeatures, { loading: mutationLoading, error: mutationError }] = useMutation(
    importFeatureInformationsMutation
  );

  useEffect(() => {
    if (data?.product?.allVariants.length > 0) {
      setHasVariant(true);
    }
  }, [setHasVariant, data]);

  useEffect(() => {
    if (!selectedVariant) return;
    setWeightElements(selectedVariant.weight ? { weight: true } : {});
  }, [selectedVariant, setWeightElements]);

  useEffect(() => {
    const checkIfAnyCheckboxIsChecked = () => {
      const weightChecked = Object.values(weightElements).some((value) => value);
      const featureChecked = featureElements.length > 0;
      return weightChecked || featureChecked;
    };

    setIsDirty(checkIfAnyCheckboxIsChecked());
  }, [weightElements, featureElements]);

  const { handleSubmit } = useForm();

  const close = () => {
    setSelectedVariant(null);
    setOpen(false);
  };

  const options = data?.product?.allVariants.map((variant: Product) => {
    return {
      value: variant.id,
      label: variant.currentTranslation?.internal_title,
      features: variant.features,
      weight: variant.hasFeaturesElements.weight,
      product_weight: variant.product_weight,
    };
  });

  const onChangeCheckbox = (value: boolean, key: string) => {
    setWeightElements((prevElements) => ({
      ...prevElements,
      [key]: value,
    }));
  };

  const onChangeFeatureCheckbox = (featureId: ID, checked: boolean) => {
    setFeatureElements((prevElements) => {
      if (checked) {
        return prevElements.includes(featureId) ? prevElements : [...prevElements, featureId];
      } else {
        return prevElements.filter((id) => id !== featureId);
      }
    });
  };

  const onSubmit = handleSubmit(() => {
    if (!weightElements || !featureElements) return;

    const featuresImportElement = {
      features: featureElements,
      weight: weightElements['weight'] || false,
    };

    importFeatures({
      variables: {
        id: params.productId,
        from_product_id: selectedVariant?.value,
        features_import_element: featuresImportElement,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productFeaturesQuery,
          variables: { id: params.productId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.features.import.success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.editorial.import.title')}
      buttonText={t('global.actions.import')}
      onCancel={close}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={mutationLoading}
      error={mutationError}>
      <>
        <Select
          label={t('product.editorial.import.label')}
          options={options}
          loadingOptions={loading}
          apolloError={error}
          isClearable
          value={selectedVariant}
          onChange={(e) => {
            setSelectedVariant(e as VariantOption);
            const newVariant = e as VariantOption;
            setSelectedVariant(newVariant);
            const newFeatureElements = newVariant.features.map((feature) => feature.id);
            setFeatureElements(newFeatureElements);
          }}
        />

        <div>
          {selectedVariant && (
            <>
              <div className="mb-6 mt-8 w-full border-t border-gray-300" />
              <div className="mb-6 font-semibold text-dark">{t('global.informations-choice')}</div>
            </>
          )}
          {selectedVariant?.features.map((feature, i) => {
            return (
              <div key={i} className="mb-2">
                <Checkbox
                  label={feature.label.text}
                  defaultChecked={true}
                  onChange={(e) => onChangeFeatureCheckbox(feature.id, e.target.checked)}
                />
                <span className="ml-7 text-sm text-secondary">
                  {feature.value.text} {feature.unit?.text}{' '}
                </span>
              </div>
            );
          })}

          {selectedVariant && (
            <>
              <Checkbox
                label={t('product.features.product-weight')}
                checked={weightElements.weight || false}
                disabled={!selectedVariant?.weight}
                onChange={(e) => onChangeCheckbox(e.target.checked, 'weight')}
              />
              <span className="ml-7 text-sm text-secondary">
                {selectedVariant?.product_weight >= 1000
                  ? `${selectedVariant?.product_weight / 1000} kg`
                  : `${selectedVariant?.product_weight ?? 0} g`}
              </span>
            </>
          )}
        </div>
      </>
    </SlideOver>
  );
};
export default ImportSlide;
