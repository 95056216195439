import React, { Fragment, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Dialog, Transition } from '@headlessui/react';
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { deleteDesignerMutation } from 'graphql/Mutations/Settings/Designer/deleteDesigner';
import { designersQuery } from 'graphql/Queries/Product/Editorial/designers';
import { OptionProps } from 'types/Global';
import useNotification from 'hooks/useNotification';
import { Button } from 'components/Common/Button';

type DeleteDesignerProps = {
  isOpen: boolean;
  reset: () => void;
  setDisplayModal: (isOpen: boolean) => void;
  designer: OptionProps | null;
};

const DeleteDesignerModal = ({ isOpen, reset, setDisplayModal, designer }: DeleteDesignerProps) => {
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();

  const cancelButtonRef = useRef(null);

  const clearAll = () => {
    setDisplayModal(false);
  };

  const [deleteDesigner, deleteDesignerReturns] = useMutation(deleteDesignerMutation);

  const onConfirmDelete = () => {
    if (!designer) return;
    deleteDesigner({
      variables: {
        id: designer.value,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: designersQuery,
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('settings.designers.mutation.delete-success'),
          type: 'success',
        });
        reset();
        clearAll();
      },
      onError: (e) => {
        setError(e);
      },
    });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-50"
        initialFocus={cancelButtonRef}
        onClose={() => clearAll}
        static>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationTriangleIcon className="h-6 w-6 text-red-600" />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                      {t('settings.designers.modal.title')}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        {t('settings.designers.modal.subtitle')}
                        <strong>{designer?.label}</strong>
                        {' ?'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 gap-4 flex flex-row justify-center sm:justify-end">
                  <Button
                    type="button"
                    theme="transparent"
                    onClick={clearAll}
                    disabled={deleteDesignerReturns.loading}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button
                    type="button"
                    theme="danger"
                    onClick={onConfirmDelete}
                    loading={deleteDesignerReturns.loading}
                    disabled={deleteDesignerReturns.loading}>
                    {t('global.actions.delete')}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default DeleteDesignerModal;
