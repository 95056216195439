import React from 'react';

function SkeletonConstituent() {
  return (
    <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
      <div className="skeleton h-80 w-full" />
      <div className="skeleton h-80 w-full" />
      <div className="skeleton h-80 w-full" />
      <div className="skeleton h-80 w-full" />
    </div>
  );
}

export default SkeletonConstituent;
