import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect } from 'react';
import { customStyle, theme } from 'components/Style/customStyle';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteStyle from './DeleteStyle';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import Creatable from 'react-select/creatable';
import { InformationAlert } from 'components/Common/Alerts';
import { stylesQuery } from 'graphql/Queries/Product/ModelsAndCollections/styles';
import { createStyleMutation } from 'graphql/Mutations/Product/ModelsAndCollections/createStyle';
import { updateStyleMutation } from 'graphql/Mutations/Product/ModelsAndCollections/updateStyle';
import { updateStyleStatusMutation } from 'graphql/Mutations/Product/ModelsAndCollections/updateStyleStatus';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';

const Styles = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditStyle, setShowEditStyle] = useState(false);
  const [showNewStyle, setShowNewStyle] = useState(false);
  const [style, setStyle] = useState(null);
  const [styles, setStyles] = useState([]);
  const [displayModal, setDisplayModal] = useState(false);
  const [activationChanging, setActivationChanging] = useState(false);

  const { loading, data } = useQuery(stylesQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (data) {
      setStyles(
        data.productStyles?.map((freshStyle) => {
          if (style?.label === freshStyle.label || style?.value === freshStyle.id) {
            setStyle({
              value: freshStyle.id,
              label: freshStyle.label,
              active: freshStyle.active,
              isUsed: freshStyle.isUsed,
            });
          }
          return {
            label: freshStyle.label ? freshStyle.label : '-',
            value: freshStyle.id,
            active: freshStyle.active,
            isUsed: freshStyle.isUsed,
            key: freshStyle.id,
          };
        })
      );
    }
  }, [data]);

  const [createStyle, { loading: createStyleLoading }] = useMutation(createStyleMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: stylesQuery,
      },
    ],
  });

  const [updateStyle, { loading: updateStyleLoading }] = useMutation(updateStyleMutation, {
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: stylesQuery,
      },
    ],
  });

  const [updateStyleStatus, { loading: updateStyleStatusLoading }] = useMutation(
    updateStyleStatusMutation,
    {
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: stylesQuery,
        },
      ],
    }
  );

  const onChangeStyle = (value) => {
    setShowEditStyle(true);
    setShowNewStyle(false);
    setStyle(value);
    reset({
      label: value.label,
    });
  };

  const onCreateStyle = (value) => {
    setShowEditStyle(false);
    setShowNewStyle(true);
    setStyle({
      value: value,
      label: value,
      active: true,
      isUsed: false,
    });
    reset({
      label: value,
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
    },
  });

  const onCancel = () => {
    setShowNewStyle(false);
    setStyle(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewStyle) {
      createStyle({
        variables: {
          label: data.label,
        },
        onCompleted: () => {
          setNotification({
            title: t('global.success'),
            message: t('settings.products.models-collections-styles.styles.created'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
          setShowEditStyle(true);
          setShowNewStyle(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updateStyle({
        variables: {
          id: style.value,
          label: data.label,
        },
        onCompleted: () => {
          setNotification({
            title: t('global.success'),
            message: t('settings.products.models-collections-styles.styles.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
          });
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const resetAfterDelete = () => {
    setStyle(null);
    setShowEditStyle(false);
    setShowNewStyle(false);
  };

  const changeActivation = (value) => {
    setActivationChanging(true);
    let message = '';
    if (value) {
      message = t('settings.products.models-collections-styles.styles.activate');
    } else {
      message = t('settings.products.models-collections-styles.styles.deactivate');
    }
    updateStyleStatus({
      variables: {
        id: style.value,
        active: value,
      },
      onCompleted: (dataReturn) => {
        setActivationChanging(false);
        let data = dataReturn.updateProductStyle;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
        });
        setStyle({
          value: data.id,
          label: data.label,
          active: data.active,
          isUsed: data.isUsed,
        });
      },
      onError: (error) => {
        setActivationChanging(false);
        setError(error);
      },
    });
  };

  return (
    <>
      <DeleteStyle
        onCloseModal={() => setDisplayModal(false)}
        isOpen={displayModal}
        style={style}
        reset={resetAfterDelete}
      />
      <Card>
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.products.models-collections-styles.styles.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.products.models-collections-styles.styles.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.products.models-collections-styles.styles.list')}
          </label>
          <Creatable
            placeholder={t('settings.products.models-collections-styles.styles.placeholder')}
            loadingMessage={() => t('global.form.loading')}
            noOptionsMessage={() => t('global.no-option')}
            className="mt-1 block w-full flex-1 rounded-none rounded-r-md border-gray-300 sm:text-sm"
            styles={customStyle}
            theme={theme}
            formatCreateLabel={(inputValue) =>
              `${t('settings.products.models-collections-styles.styles.create')} : "${inputValue}"`
            }
            onCreateOption={onCreateStyle}
            onChange={onChangeStyle}
            value={style}
            options={styles}
            isLoading={loading}
            menuPlacement="auto"
          />
        </div>
        {(showEditStyle || showNewStyle) && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="p-4 sm:rounded-b-md">
              <label className="text-sm font-medium text-gray-700">
                {t('settings.products.models-collections-styles.styles.name')}
              </label>
              <div className="relative">
                <input
                  type="text"
                  className={classNames(
                    errors.label
                      ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                      : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                    'mt-1 block h-9 w-full flex-1 rounded'
                  )}
                  {...register('label')}
                />
                {errors.label && (
                  <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                    <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                  </div>
                )}
              </div>
              {errors.label && <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>}
              {style?.isUsed && (
                <div className="mt-4">
                  <InformationAlert>
                    {t('settings.products.models-collections-styles.styles.used')}
                  </InformationAlert>
                </div>
              )}
            </div>
            <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
              {showEditStyle && (
                <>
                  <div className="grid grid-cols-3 gap-4">
                    <Button
                      type="button"
                      theme="outlineDanger"
                      disabled={updateStyleLoading || style?.isUsed}
                      onClick={() => setDisplayModal(true)}>
                      {t('global.actions.delete')}
                    </Button>
                    {style.active ? (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(false)}
                        disabled={updateStyleLoading}
                        loading={updateStyleStatusLoading}>
                        {t('global.actions.deactivate')}
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        theme="outlinePrimary"
                        onClick={() => changeActivation(true)}
                        disabled={updateStyleLoading}
                        loading={updateStyleStatusLoading}>
                        {t('global.actions.activate')}
                      </Button>
                    )}
                    <Button
                      type="submit"
                      disabled={updateStyleLoading || !isDirty}
                      loading={updateStyleLoading}>
                      {t('global.actions.save')}
                    </Button>
                  </div>
                </>
              )}
              {showNewStyle && (
                <>
                  <Button
                    type="button"
                    theme="transparent"
                    onClick={onCancel}
                    disabled={createStyleLoading}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button type="submit" disabled={createStyleLoading} loading={createStyleLoading}>
                    {t('global.actions.add')}
                  </Button>
                </>
              )}
            </div>
          </form>
        )}
      </Card>
    </>
  );
};

export default Styles;
