import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { useLazyQuery } from '@apollo/client';
import InputText from 'components/Common/InputText';
import { searchSuppliersQuery } from 'graphql/Queries/Suppliers/searchSuppliers';
import { debounce } from 'lodash';
import {
  MagnifyingGlassIcon,
  PencilSquareIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/react/24/outline';
import { Button } from 'components/Common/Button';
import { Supplier } from 'types/Supplier';
import { PlusIcon } from '@heroicons/react/20/solid';
import Badge from 'components/Common/Badge';
import Pagination from 'components/Common/Pagination';
import CreateSupplier from './CreateSupplier';
import SuppliersFilter from './SuppliersFilter';

const Index = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [searchSuppliers, { loading, error, data }] = useLazyQuery(searchSuppliersQuery);

  const [page, setPage] = useState(1);
  const [supplierKeyword, setSupplierKeyword] = useState<string | null>(null);

  const [isActive, setIsActive] = useState<boolean | null>(null);
  const [countryId, setCountryId] = useState<number | null>(null);

  const [openCreateSlide, setOpenCreateSlide] = useState<boolean>(false);
  const [openFilterSlide, setOpenFilterSlide] = useState<boolean>(false);

  const onChangeSupplierKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(1);
    setSupplierKeyword(event.target.value);
  };

  const debounceChangeSupplierKeyword = useMemo(() => debounce(onChangeSupplierKeyword, 500), []);

  useEffect(() => {
    searchSuppliers({
      variables: {
        page: page ?? 1,
        perPage: 10,
        direction: 'DESC',
        orderBy: 'id',
        keyword: supplierKeyword ?? null,
        isActive: isActive ?? null,
        countryId: countryId ?? null,
      },
      fetchPolicy: 'network-only',
    });
  }, [page, supplierKeyword, searchSuppliers, isActive, countryId]);

  return (
    <>
      <CreateSupplier open={openCreateSlide} setOpen={setOpenCreateSlide} />
      <SuppliersFilter
        open={openFilterSlide}
        setOpen={setOpenFilterSlide}
        setIsActive={setIsActive}
        setCountryId={setCountryId}
        isActive={isActive}
      />

      <div className="container mx-auto">
        <div className="space-y-6 p-6 lg:space-y-12 lg:p-12">
          {/* Title + create button */}
          <div className="md:flex md:items-center md:justify-between">
            <div className="min-w-0 flex-1">
              <h2 className="text-2xl font-bold leading-7 text-dark sm:truncate sm:text-3xl sm:tracking-tight">
                {t('suppliers.title')}
              </h2>
            </div>
            <div className="mt-4 flex md:ml-4 md:mt-0">
              <Button type="button" onClick={() => setOpenCreateSlide(true)}>
                <div className="flex space-x-2">
                  <PlusIcon className="w-5" />
                  <span>{t('suppliers.create-button')}</span>
                </div>
              </Button>
            </div>
          </div>
          <div className="space-y-4 md:flex md:items-center md:justify-start md:space-x-3 md:space-y-0">
            <div className="w-full max-w-lg">
              <InputText
                onChange={debounceChangeSupplierKeyword}
                icon={<MagnifyingGlassIcon className="h-5 w-5 shrink-0 text-secondary" />}
                placeholder={t('global.search')}
              />
            </div>
            <div className="flex w-full items-center justify-between ">
              <Button type="button" theme="secondary" onClick={() => setOpenFilterSlide(true)}>
                <div className="flex space-x-2">
                  <AdjustmentsHorizontalIcon className="w-5" />
                  <span>{t('suppliers.filters')}</span>
                </div>
              </Button>

              <div className="italic text-dark">
                {loading || error ? (
                  '0 ' + t('products.results_other')
                ) : (
                  <>
                    {data?.searchSuppliers?.paginatorInfo.total} {t('products.results_other')}
                  </>
                )}
              </div>
            </div>
          </div>
          {/* Table */}
          {!data?.searchSuppliers?.data?.length && !loading && !error ? (
            <div className="mt-6">
              <InformationAlert>{t('suppliers.no-supplier')}</InformationAlert>
            </div>
          ) : error ? (
            <div className="mt-6">
              <ApolloErrorAlert error={error} />
            </div>
          ) : (
            <table className="mt-12 min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="w-24 py-3.5 pl-4 text-left text-sm font-semibold text-dark sm:pl-0 lg:table-cell">
                    {t('suppliers.id').toUpperCase()}
                  </th>
                  <th
                    scope="col"
                    className="w-24 px-3 py-3.5 text-left text-sm font-semibold text-dark lg:table-cell">
                    {t('suppliers.shipping-name').toUpperCase()}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark lg:table-cell">
                    {t('suppliers.billing-name').toUpperCase()}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark lg:table-cell">
                    {t('suppliers.status').toUpperCase()}
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-dark lg:table-cell">
                    {t('suppliers.country').toUpperCase()}
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0"></th>
                </tr>
              </thead>
              <tbody className="divide-y divide-divider">
                {loading && (
                  <>
                    {[...Array(10)].map((e, i) => (
                      <tr key={i}>
                        <td className="py-3.5 pl-4 pr-3 sm:w-auto sm:pl-0">
                          <div className="skeleton h-5 w-12"></div>
                        </td>
                        <td className="w-[500px] px-3 py-4">
                          <div className="skeleton h-5 w-24 sm:w-60"></div>
                        </td>
                        <td className="3.5 hidden px-3 lg:table-cell">
                          <div className="skeleton h-5 w-60"></div>
                        </td>
                        <td className="3.5 hidden px-3 lg:table-cell">
                          <div className="skeleton h-5 w-12"></div>
                        </td>
                        <td className="3.5 hidden px-3 lg:table-cell">
                          <div className="skeleton  h-8  w-8 rounded-full"></div>
                        </td>
                        <td className="flex items-center justify-end py-3.5 pl-3 pr-2 text-sm font-medium">
                          <div className="skeleton h-8 w-8"></div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
                {!loading &&
                  data?.searchSuppliers?.data.map((supplier: Supplier) => (
                    <tr key={supplier.id}>
                      <td className="w-24 py-3.5 pl-4 pr-3 text-sm font-semibold text-dark  sm:pl-0">
                        #{supplier.id}
                      </td>
                      <td className="w-[96px] max-w-[96px] px-3 py-3.5 text-sm text-gray-500 sm:w-1/2 lg:table-cell xl:w-[500px] xl:max-w-[500px]">
                        <div className="truncate">{supplier.name}</div>
                      </td>

                      <td className="hidden max-w-[200px] px-3 py-3.5  text-sm text-gray-500 lg:table-cell">
                        <div className=" truncate">{supplier.billing_name}</div>
                      </td>
                      <td className="hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                        {supplier.active ? (
                          <Badge type="success">{t('global.active')}</Badge>
                        ) : (
                          <Badge type="warning">{t('global.inactive')}</Badge>
                        )}
                      </td>
                      <td className=" hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                        <div className="flex items-center">
                          <div className="flex h-8 w-8 items-center justify-center overflow-hidden rounded-full border">
                            <div className="bg-gray-300">
                              <span
                                className={`fi text-[33px] fi-${supplier?.country?.iso}`}></span>
                            </div>
                          </div>
                          <span className="ml-2 ">{supplier?.country?.name}</span>
                        </div>
                      </td>
                      <td className="flex items-center justify-end py-3.5 pl-3 pr-2 text-sm font-medium">
                        <PencilSquareIcon
                          className="h-6 w-6 cursor-pointer text-secondary hover:text-dark"
                          onClick={() => navigate(`/suppliers/${supplier.id}/general-informations`)}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
          {!!data?.searchSuppliers?.data?.length &&
            data?.searchSuppliers?.paginatorInfo &&
            !loading && (
              <div>
                <Pagination paginator={data.searchSuppliers.paginatorInfo} setPage={setPage} />
              </div>
            )}
        </div>
      </div>
    </>
  );
};

export default Index;
