import { gql } from 'graphql.macro';

export const updateContactTypeMutation = gql`
  mutation UpdateContactType($id: ID!, $label: String) {
    updateSupplierContactType(id: $id, label: $label) {
      id
      label
      active
      used
    }
  }
`;
