import React, { ReactElement, useState } from 'react';
import { debounce } from 'lodash';
import { useGlobalContextType } from 'components/Contexts/GlobalContext';
import { SearchProvider } from '../Contexts/SearchContext';
import Sidebar from './Sidebar';
import SlideSidebar from './SlideSidebar';
import TopBar from './TopBar';

type Props = {
  children: ReactElement;
};

export const DefaultLayout = ({ children }: Props) => {
  const { sidebarOpen, setSidebarOpen } = useGlobalContextType();

  const [slideSidebarOpen, setSlideSidebarOpen] = useState(false);

  const handleMouseEnter = () => {
    setSlideSidebarOpen(true);
  };

  const debouncedMouseEnter = debounce(() => {
    if (!sidebarOpen) handleMouseEnter?.();
  }, 500);

  const handleCancelDebouncedMouseEnter = () => {
    debouncedMouseEnter.cancel();
  };

  return (
    <div className="flex h-screen flex-col">
      <div
        className="fixed z-50 h-screen w-4"
        onMouseEnter={debouncedMouseEnter}
        onMouseLeave={handleCancelDebouncedMouseEnter}
      />
      <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} handleMouseEnter={handleMouseEnter} />
      <SlideSidebar open={slideSidebarOpen} setOpen={setSlideSidebarOpen} />
      <SearchProvider>
        <div
          className={`animate-easing-emphasized-decelerate flex-grow bg-gray-50 transition-all duration-700 ${
            sidebarOpen && 'xl:pl-72'
          }`}>
          <TopBar setSlideSidebarOpen={setSlideSidebarOpen} />
          <main>{children}</main>
        </div>
      </SearchProvider>
    </div>
  );
};
