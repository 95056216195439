import { gql } from 'graphql.macro';

export const createStyleMutation = gql`
  mutation CreateStyle($label: String!) {
    createProductStyle(label: $label) {
      id
      label
      active
      isUsed
    }
  }
`;
