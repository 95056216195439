import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router-dom';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { TrashIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@apollo/client';
import { uploadProductImageMutation } from 'graphql/Mutations/Product/Images/uploadProductImage';
import { imageStickerQuery } from 'graphql/Queries/Product/Images/Sticker/image';
import { validateImage } from 'utils/Utils';
import { IMAGE_TYPE } from 'utils/imageType';
import { ApolloErrorAlert, ErrorAlert } from 'components/Common/Alerts';
import { ID } from 'types/Global';
import useNotification from 'hooks/useNotification';
import DeleteImageModal from '../Component/DeleteImageModal';

type Props = {
  loading: boolean;
  image: {
    id: ID;
    clientUrl: string;
  };
};

const SecondaryCard = ({ image, loading }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [uploadError, setUploadError] = useState<string[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

  const [uploadImage, { loading: loadingMutation, error: errorMutation }] = useMutation(
    uploadProductImageMutation
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      setUploadError([]);
      const status = await validateImage(acceptedFiles, setUploadError, t);

      if (status) return;

      uploadImage({
        variables: {
          file: acceptedFiles[0],
          product_id: params.productId,
          type_id: IMAGE_TYPE.SECONDARY_IMAGE,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: imageStickerQuery,
            variables: {
              id: params.productId,
            },
          },
        ],
        onCompleted: () => {
          setNotification({
            title: t('global.success'),
            message: t('product.sticker.add-file.success'),
            type: 'success',
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onError: () => {},
      });
    },
    [t, uploadImage, setNotification, params]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="h-auto">
      <DeleteImageModal
        openDeleteModal={openDeleteModal}
        setOpenDeleteModal={setOpenDeleteModal}
        imageType={t('product.sticker.secondary-image')}
        imageId={image?.id}
      />

      {loading ? (
        <div className="skeleton h-64 w-full" />
      ) : (
        <>
          {image ? (
            <div className="group relative flex h-56 items-center justify-center overflow-hidden rounded-md border bg-white p-2">
              <img
                src={image.clientUrl}
                alt={`Image ${image.id}`}
                className="h-full w-auto object-cover object-center transition-opacity duration-300"
              />
              <button type="button" onClick={() => setOpenDeleteModal(true)}>
                <TrashIcon className="absolute right-2 top-2 z-50 w-10 cursor-pointer rounded-full border border-gray-200 bg-white p-2 opacity-0 outline-none transition-opacity duration-300 hover:text-red-600 group-hover:opacity-100" />
              </button>
              <div className="absolute inset-0 bg-gray-300 opacity-0 transition-opacity duration-300 group-hover:opacity-50"></div>
            </div>
          ) : (
            <>
              {loadingMutation ? (
                <div className="flex h-64 items-center justify-center">
                  <div className="m-auto h-8 w-8 animate-spin rounded-full border-b-2 border-gray-500" />
                </div>
              ) : (
                <div className="cursor-pointer p-4 sm:col-span-2 sm:mt-0">
                  {errorMutation && (
                    <div className="mb-3">
                      <ApolloErrorAlert error={errorMutation} />
                    </div>
                  )}
                  <div
                    {...getRootProps()}
                    className={
                      `flex max-w-lg justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5` +
                      (isDragActive && 'bg-blue-100')
                    }>
                    <input {...getInputProps()} accept={'image/*'} />
                    <div className="space-y-1 text-center">
                      <PhotoIcon className="mt-3 h-24 w-full text-secondary" />
                      <div className="hidden lg:block">
                        <p className="text-s cursor-pointer rounded-md text-blue-500 hover:text-blue-400">
                          {t('product.sticker.card.select-file')}
                        </p>
                        <p className="text-xs text-dark">{t('product.sticker.card.dnd')}</p>
                        <p className="text-xs text-secondary">
                          {t('product.sticker.card.accept-file')}
                        </p>
                        <p className="text-xs text-secondary">
                          {t('product.sticker.card.max-size')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
      {uploadError.length > 0 && (
        <div className="w-full ">
          <ErrorAlert>
            <ul className="ml-2 list-disc text-xs">
              {uploadError.map((error, i) => (
                <li key={i}>{error}</li>
              ))}
            </ul>
          </ErrorAlert>
        </div>
      )}
    </div>
  );
};
export default SecondaryCard;
