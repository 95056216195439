import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { productTagsQuery } from 'graphql/Queries/Product/Packaging/productTags';
import { switchIsFragileProductMutation } from 'graphql/Mutations/Product/Packaging/switchIsFragileProduct';
import { switchIsGroupableProductMutation } from 'graphql/Mutations/Product/Packaging/switchIsGroupableProduct';
import { Toggle } from 'components/Common/Toggle';
import { ApolloErrorAlert, ErrorAlert, WarningAlert } from 'components/Common/Alerts';
import { TAG_FRAGILE_ID, TAG_GROUPABLE_ID } from 'global';
import { Menu, Transition } from '@headlessui/react';
import { BookOpenIcon } from '@heroicons/react/24/outline';
import TagHistory from '../TagHistory';
import useNotification from 'hooks/useNotification';
import Card from 'components/Common/Card';
import Tippy from '@tippyjs/react';

const Tags = () => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();

  const { loading, error, data } = useQuery(productTagsQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  const productError = !loading && (error || !data?.product);

  const [
    switchIsFragileProduct,
    { loading: loadingSwitchIsFragileProduct, error: errorIsFragile },
  ] = useMutation(switchIsFragileProductMutation);

  const [
    switchIsGroupableProduct,
    { loading: loadingSwitchIsGroupableProduct, error: errorIsGroupable },
  ] = useMutation(switchIsGroupableProductMutation);

  const onChangeFragileProduct = () => {
    switchIsFragileProduct({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        id: params.productId,
        is_fragile: !data?.product.is_fragile,
        tag_id: TAG_FRAGILE_ID,
      },
      onCompleted: (data) => {
        const message = data.switchIsFragileProduct.is_fragile
          ? t('product.packaging.tag.fragile-product.notif.activated')
          : t('product.packaging.tag.fragile-product.notif.deactivated');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  const onChangeGroupableArticle = () => {
    switchIsGroupableProduct({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        id: params.productId,
        is_groupable: !data?.product.is_groupable,
        tag_id: TAG_GROUPABLE_ID,
      },
      onCompleted: (data) => {
        const message = data.switchIsGroupableProduct.is_groupable
          ? t('product.packaging.tag.groupable-article.notif.activated')
          : t('product.packaging.tag.groupable-article.notif.deactivated');
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  };

  return (
    <Card title={t('product.packaging.tag.title')}>
      <>
        {productError ? (
          error || errorIsFragile || errorIsGroupable ? (
            [error, errorIsFragile, errorIsGroupable].map(
              (e, i) =>
                e && (
                  <div key={i} className="mb-4">
                    <ApolloErrorAlert error={e} />
                  </div>
                )
            )
          ) : (
            <ErrorAlert>{t('product.error')}</ErrorAlert>
          )
        ) : (
          <div className="space-y-5">
            {loading ? (
              [...Array(2)].map((_, i) => <div key={i} className="skeleton h-10 w-full" />)
            ) : (
              <>
                <div>
                  <Menu as="div" className="relative">
                    <Toggle
                      enabled={data?.product.is_fragile}
                      label={t('product.packaging.tag.fragile-product.label')}
                      icon={
                        <Tippy content={t('global.history')}>
                          <Menu.Button className="justify-center text-gray-400 hover:text-dark">
                            <BookOpenIcon className="w-5" />
                          </Menu.Button>
                        </Tippy>
                      }
                      description={t('product.packaging.tag.fragile-product.description')}
                      onChange={onChangeFragileProduct}
                      disabled={loadingSwitchIsFragileProduct || !data?.product?.active}
                    />
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute left-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <TagHistory tagId={TAG_FRAGILE_ID} />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <div>
                  <Menu as="div" className="relative">
                    <Toggle
                      enabled={data?.product.is_groupable}
                      icon={
                        <Tippy content={t('global.history')}>
                          <Menu.Button className="justify-center text-gray-400 hover:text-dark">
                            <BookOpenIcon className="w-5" />
                          </Menu.Button>
                        </Tippy>
                      }
                      label={t('product.packaging.tag.groupable-article.label')}
                      description={t('product.packaging.tag.groupable-article.description')}
                      onChange={onChangeGroupableArticle}
                      disabled={loadingSwitchIsGroupableProduct || !data?.product?.active}
                    />
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute left-0 z-50 mt-2 max-h-52 w-[400px] origin-top-right overflow-y-scroll rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <TagHistory tagId={TAG_GROUPABLE_ID} />
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                {!data?.product?.active && (
                  <WarningAlert>
                    {t('product.tags.product-must-be-active-for-any-modification')}
                  </WarningAlert>
                )}
              </>
            )}
          </div>
        )}
      </>
    </Card>
  );
};
export default Tags;
