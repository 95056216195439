// import Card from 'components/Common/Card';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Product } from 'types/Product';
import { InformationAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';
import ProductCard from '../Card';

type Props = {
  loading: boolean;
  product?: Product;
};

const Collection = ({ loading, product }: Props) => {
  const { t } = useTranslation();

  return (
    <Card title={t('product.preview.collection.title')}>
      {loading ? (
        <div className="grid gap-6 md:grid-cols-2 xl:grid-cols-4">
          {[...Array(4)].map((_, i) => (
            <div key={i} className="skeleton h-80 w-full" />
          ))}
        </div>
      ) : product?.productsCollectionWhitoutVariants.length ?? 0 > 0 ? (
        <div className="space-y-6">
          <span className="flex justify-end text-base font-semibold uppercase text-secondary">
            {product?.taxonomy?.collection?.label}
          </span>
          <div className="grid gap-6 lg:grid-cols-2 2xl:grid-cols-4">
            {product?.productsCollectionWhitoutVariants.map((product, i: number) => (
              <ProductCard key={i} product={product} withVariants />
            ))}
          </div>
        </div>
      ) : (
        <InformationAlert>{t('product.preview.collection.no-product')}</InformationAlert>
      )}
    </Card>
  );
};
export default Collection;
