import React from 'react';
import Form from './Form';

const Index = () => {
  return (
    <>
      <Form />
    </>
  );
};
export default Index;
