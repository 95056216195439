import React from 'react';
import Dashboard from './Dashboard';
import News from './News';

const Home = () => {
  return (
    <div className="container mx-auto">
      <div className="flex flex-grow flex-col gap-10 p-6">
        <Dashboard />
        <News />
      </div>
    </div>
  );
};

export default Home;
