import React, { ReactElement } from 'react';
import {
  InformationCircleIcon,
  XCircleIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/20/solid';
import useApolloErrorMessage from 'hooks/useApolloErrorMessage';
import { ApolloError } from '@apollo/client';

type WarningAlertProps = {
  children: ReactElement | string;
};

export const WarningAlert = ({ children }: WarningAlertProps) => {
  return (
    <div className="rounded-md bg-yellow-50 p-4 shadow-sm">
      <div className="flex">
        <div className="flex-shrink-0">
          <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="text-sm text-yellow-700">{children}</div>
        </div>
      </div>
    </div>
  );
};

type InformationAlertProps = {
  children: ReactElement | string;
};

export const InformationAlert = ({ children }: InformationAlertProps) => {
  return (
    <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="text-sm text-blue-700">{children}</div>
        </div>
      </div>
    </div>
  );
};

type ErrorAlertProps = {
  children: ReactElement | string;
};

export const ErrorAlert = ({ children }: ErrorAlertProps) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="text-sm text-red-700">{children}</div>
        </div>
      </div>
    </div>
  );
};

type ApolloErrorAlertProps = {
  error: ApolloError;
};

export const ApolloErrorAlert = ({ error }: ApolloErrorAlertProps) => {
  return (
    <div className="rounded-md bg-red-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-400" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <div className="text-sm text-red-700">
            {useApolloErrorMessage({ apolloError: error })}
          </div>
        </div>
      </div>
    </div>
  );
};
