import React from 'react';
import { useParams } from 'react-router-dom';
import { ActiveTaxProvider } from './Context/ActiveTaxContext';
import { useTranslation } from 'react-i18next';
import { useProductContextType } from 'components/Contexts/ProductContext';
import EcoMobilier from './EcoMobilier/Index';
import Deee from './DEEE/Index';
import Title from 'components/Common/Title';

const Index = () => {
  const params = useParams();
  const { t } = useTranslation();

  const { productExists } = useProductContextType();

  if (!productExists || !params.productId) return <></>;

  return (
    <>
      <ActiveTaxProvider>
        <>
          <Title title={t('product.ecotax.title')} />
          <div className="grid grid-cols-1 gap-5 2xl:grid-cols-2">
            <EcoMobilier productId={params.productId} />
            <Deee productId={params.productId} />
          </div>
        </>
      </ActiveTaxProvider>
    </>
  );
};

export default Index;
