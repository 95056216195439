import React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { UseAuthToken } from 'AuthToken';
import { UserContext } from 'App';
import { ID } from 'types/Global.js';
import { User } from 'types/User.js';
import LoginForm from './LoginForm';
import Footer from '../Layout/Footer';
import MilibooLogo from 'MilibooLogo';

export type UserData = {
  id: ID;
  access_token: string;
  user: User;
};

const Login = () => {
  const { t } = useTranslation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setAuthToken] = UseAuthToken();
  const { setUser } = useContext(UserContext);

  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleValidLogin = (data: UserData) => {
    const user = JSON.stringify(data.user);
    setAuthToken(data.access_token);
    localStorage.setItem('user', user);
    setUser(user);
    navigate(localStorage.getItem('previousUrl') ?? '/');
  };

  return (
    <>
      <div className="bg-grey-50 absolute left-0 flex h-screen w-full justify-between">
        <div className="hidden w-1/3 xl:block">
          <div className="absolute left-16 top-16">
            <MilibooLogo />
            <div className="text-md -mt-1 w-[200px] leading-4 text-light">
              {t('global.app-full-title')}
            </div>
          </div>
          <img
            className=" h-screen w-full object-cover"
            src={require('assets/images/login-image.jpg')}
            alt="Image"
          />
        </div>
        <div className="relative z-10 flex h-full w-full items-center justify-center xl:w-2/3">
          <div className="mx-auto">
            <LoginForm handler={handleValidLogin} />
          </div>
          <div className="absolute bottom-0 w-full">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
