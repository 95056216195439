import React from 'react';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import MultiSelect from './FormUtils/MultiSelect';
import FieldLegend from './FormUtils/FieldLegend';
import { Control } from 'react-hook-form';

type Props = {
  control: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supplierFilterBucket: any;
};

const SupplierFilter = ({ control, supplierFilterBucket }: Props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectSuppliers = supplierFilterBucket.map((supplier: any, index: number) => {
    return {
      key: index,
      value: supplier.key,
      label: supplier.supplier.hits.hits[0]._source.supplier.name,
      minilabel: supplier.supplier.hits.hits[0]._source.supplier.name,
    };
  });

  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div" className={''}>
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.supplier.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              <MultiSelect name={'supplier'} control={control} options={selectSuppliers} />
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default SupplierFilter;
