import { gql } from 'graphql.macro';

export const updateLocalBankCoordinatesMutation = gql`
  mutation UpdateLocalBankCoordinates(
    $id: ID!
    $local_bank_beneficiary_name: String
    $local_bank_name: String
    $local_bank_account: String
    $local_bank_swift: String
    $local_bank_street: String
    $local_bank_complementary_address: String
    $local_bank_city: String
    $local_bank_zipcode: String
    $local_bank_country_id: Int
  ) {
    updateSupplier(
      id: $id
      local_bank_beneficiary_name: $local_bank_beneficiary_name
      local_bank_name: $local_bank_name
      local_bank_account: $local_bank_account
      local_bank_swift: $local_bank_swift
      local_bank_street: $local_bank_street
      local_bank_complementary_address: $local_bank_complementary_address
      local_bank_city: $local_bank_city
      local_bank_zipcode: $local_bank_zipcode
      local_bank_country_id: $local_bank_country_id
    ) {
      id
    }
  }
`;
