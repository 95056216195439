import React from 'react';

type Props = {
  iso: string;
  size?: number;
};

const RoundedFlag = ({ iso, size = 6 }: Props) => {
  return (
    <div
      className={`flex h-${size} w-${size} items-center justify-center overflow-hidden rounded-full shadow`}>
      <span
        className={`fi fi-${iso}`}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          backgroundSize: 'cover',
        }}
      />
    </div>
  );
};

export default RoundedFlag;
