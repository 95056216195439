import React, { useState, useEffect, useContext } from 'react';
import { useMutation } from '@apollo/client';
import { useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { ActiveTaxContext } from '../Context/ActiveTaxContext';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { updateEcomobilierMutation } from 'graphql/Mutations/Ecotax/updateEcomobilier';
import { ecomobilierQuery } from 'graphql/Queries/Ecotax/ecomobilier';
import { dEEEQuery } from 'graphql/Queries/Ecotax/dEEE';
import { Button } from 'components/Common/Button';
import { ID, SelectType } from 'types/Global';
import { EcomobilierData } from 'types/Product';
import CategorySelect from './CategorySelect';
import ProductTypeSelect from './ProductTypeSelect';
import MaterialSelect from './MaterialSelect';
import SizeSelect from './FeatureSelect';
import TaxSwitchModal from '../ConfirmTaxSwitch';
import useNotification from 'hooks/useNotification';
import EcomobilierAmount from './EcomobilierAmount';
import DummySelect from 'components/Common/DummySelect';
import * as yup from 'yup';

type Props = {
  productId: ID;
  initialFormValues: EcomobilierData;
  changeFutureEcoMobilierLoading: (value: boolean) => void;
  changeFutureEcoMobilier: (
    value: number | null,
    submitPossible: boolean,
    isDirty: boolean
  ) => void;
};

const PADDING_ID = 10;
const Index = ({
  productId,
  initialFormValues,
  changeFutureEcoMobilier,
  changeFutureEcoMobilierLoading,
}: Props) => {
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();
  const { ecoMobilierStatus, setDEEE, setEcomobilier } = useContext(ActiveTaxContext);

  const [updateEcoTax, { loading }] = useMutation(updateEcomobilierMutation);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [findEcomobilierAmount, setFindEcomobilierAmount] = useState(false);
  const [trueIsDirty, setTrueIsDirty] = useState(true);
  const [submitPossible, setSubmitPossible] = useState(false);
  const [futureEcoMobilierAmount, setFutureEcoMobilierAmount] = useState(0);
  const [code, setCode] = useState({
    complete: false,
    value: '',
  });

  const resetMaterialField = () => {
    setFindEcomobilierAmount(false);
    setCode({ ...code, ...{ complete: false } });

    setValue('material', { value: null, code: null });
  };

  const handleNewCategorySelection = (e: SelectType) => {
    setFindEcomobilierAmount(false);
    setValue('category', { value: e.value, code: e.code }, { shouldDirty: true });
    setValue('padding', false);
    setValue('type', { value: null, code: null });
    setValue('material', { value: null, code: null });
    setValue('size', { value: null, code: null });
    setCode({ ...code, ...{ complete: false } });
  };

  const handleNewTypeSelection = (e: SelectType | null) => {
    setFindEcomobilierAmount(false);
    setCode({ ...code, ...{ complete: false } });
    const typeValue = e?.value ?? null;
    const typeCode = e?.code ?? null;
    setValue('type', { value: typeValue, code: typeCode }, { shouldDirty: true });
    setValue('material', { value: null, code: null });
    setValue('size', { value: null, code: null });
  };

  const handleNewMaterialSelected = (e: SelectType) => {
    setFindEcomobilierAmount(false);
    setCode({ ...code, ...{ complete: false } });
    setValue('material', { value: e.value, code: e.code }, { shouldDirty: true });
  };

  const handleNewSizeSelected = (e: SelectType) => {
    setFindEcomobilierAmount(false);
    setCode({ ...code, ...{ complete: false } });
    setValue('size', { value: e.value, code: e.code }, { shouldDirty: true });
  };

  const schema = yup.object().shape({
    category: yup.object().required(t('product.ecotax.form.catergory-required')),
    type: yup.object().required(t('product.ecotax.form.type-required')),
    material: yup.object().required(t('product.ecotax.form.material-required')),
    size: yup.object().required(t('product.ecotax.form.size-required')),
  });

  const {
    handleSubmit,
    control,
    register,
    getValues,
    setValue,
    reset,
    resetField,
    formState: { isDirty, errors },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: initialFormValues || {},
  });

  useEffect(() => {
    if (!code.complete) {
      setSubmitPossible(false);
      if (
        getValues('category')?.code &&
        getValues('type')?.code &&
        getValues('material')?.code &&
        getValues('size')?.code
      ) {
        setCode({
          complete: true,
          value: `${getValues('category').code}${getValues('type').code}${
            getValues('material').code
          }${getValues('size').code}`,
        });
        setFindEcomobilierAmount(true);
        setSubmitPossible(true);
        changeFutureEcoMobilierLoading(true);
      }
    }
  }, [
    code,
    getValues,
    setCode,
    setSubmitPossible,
    setFindEcomobilierAmount,
    changeFutureEcoMobilierLoading,
  ]);

  const onSubmit: SubmitHandler<FieldValues> = (formData) => {
    const vars = {
      product_id: parseInt(productId as string),
      eco_mobilier_category_id: parseInt(String(formData.data?.category.value || '0')),
      eco_mobilier_product_type_id: parseInt(String(formData.data?.type.value || '0')),
      eco_mobilier_material_id: parseInt(String(formData.data?.material.value || '0')),
      eco_mobilier_size_id: parseInt(String(formData.data?.size.value || '0')),
      payed_before: formData.data?.paidInAdvance ? 1 : 0,
      enable: formData.data?.enable ? 1 : 0,
      forced: formData.forced,
    };
    updateEcoTax({
      variables: vars,
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: ecomobilierQuery,
          variables: {
            id: productId,
          },
        },
        {
          query: productHeaderQuery,
          variables: {
            id: productId,
          },
        },
        {
          query: dEEEQuery,
          variables: {
            id: productId,
          },
        },
      ],
      onCompleted: (data) => {
        if (!data.updateEcomobilier) {
          setShowConfirmationModal(true);
        } else {
          setShowConfirmationModal(false);
          setNotification({
            title: t('global.success'),
            message: t('product.ecotax.notify.ecoMobilier-update'),
            type: 'success',
          });
          reset(formData.data || {});
          setEcomobilier(formData.data?.enable || false);
          if (formData.data?.enable) {
            setDEEE(false);
          }
          changeFutureEcoMobilier(null, false, false);
        }
      },
      onError: (error) => {
        setShowConfirmationModal(false);
        setError(error);
      },
    });
  };

  const handleResponse = (e: string) => {
    if (e === 'close') {
      setShowConfirmationModal(false);
    } else if (e === 'accept') {
      handleSubmit((data) => onSubmit({ data, ...{ forced: true } }))();
    }
  };

  useEffect(() => {
    resetField('enable', { defaultValue: ecoMobilierStatus });
  }, [ecoMobilierStatus, resetField]);

  const onChangeEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
    const localIsDirty = initialFormValues?.enable !== e.target.checked || trueIsDirty;
    changeFutureEcoMobilier(futureEcoMobilierAmount, submitPossible, localIsDirty);
    setTrueIsDirty(isDirty);
  };

  const changeFutureEcoMobilierAmount = (amount: number) => {
    setFindEcomobilierAmount(false);
    setFutureEcoMobilierAmount(amount);
    changeFutureEcoMobilier(amount, submitPossible, isDirty);
    setTrueIsDirty(isDirty);
  };

  return (
    <>
      {findEcomobilierAmount && (
        <EcomobilierAmount
          key={code.value}
          code={code.value}
          changeFutureEcoMobilierAmount={changeFutureEcoMobilierAmount}
        />
      )}
      {showConfirmationModal && (
        <TaxSwitchModal
          type={'EcoMobilier'}
          openModal={showConfirmationModal}
          handleResponse={(e) => handleResponse(e)}
          mutationLoading={loading}
        />
      )}
      <form onSubmit={handleSubmit((data) => onSubmit({ data, ...{ forced: false } }))}>
        <div className="px-6 xl:grid xl:grid-cols-6 xl:gap-x-6">
          <div className="mt-2 sm:col-span-3">
            <CategorySelect
              control={control}
              value={getValues('category')}
              handleSelect={(e) => handleNewCategorySelection(e)}
            />
            <p className="mt-2 text-sm text-red-700">{errors?.category?.message as string}</p>
          </div>
          <div className="mt-2 sm:col-span-3">
            {getValues('category')?.value ? (
              <ProductTypeSelect
                control={control}
                errors={errors}
                categoryId={getValues('category').value}
                value={getValues('type')}
                disabled={getValues('category').value === null}
                handleSelect={(e) => handleNewTypeSelection(e)}
              />
            ) : (
              <DummySelect title={t('product.ecotax.product-type')} />
            )}
            <p className="mt-2 text-sm text-red-700">{errors?.category?.message as string}</p>
          </div>
          <div className="mt-2 sm:col-span-6">
            {getValues('category')?.value && getValues('category')?.value === PADDING_ID && (
              <div className="relative flex items-start">
                <div className="flex h-5 items-center">
                  <input
                    className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                    id="padding"
                    type="checkbox"
                    {...register('padding', {
                      onChange: () => resetMaterialField(),
                    })}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label className="mb-2 block text-sm font-medium leading-6 text-dark">
                    {t('product.ecotax.padding')}
                  </label>
                </div>
              </div>
            )}
          </div>
          <div className="mt-2 sm:col-span-3">
            {getValues('type')?.value ? (
              <MaterialSelect
                control={control}
                hasPadding={getValues('padding') ? true : false}
                typeId={getValues('type')?.value}
                defaultMaterial={getValues('material')}
                handleSelect={(e) => handleNewMaterialSelected(e)}
              />
            ) : (
              <DummySelect title={t('product.ecotax.materials')} />
            )}
            <p className="ml-[31%] h-[28px] text-sm text-red-700">
              {errors.materials && (errors.materials.message as string)}
            </p>
          </div>
          <div className="mt-2 sm:col-span-3">
            {getValues('type')?.value ? (
              <SizeSelect
                control={control}
                typeId={getValues('type').value}
                defaultSize={getValues('size')}
                handleSelect={(e) => handleNewSizeSelected(e)}
              />
            ) : (
              <DummySelect title={t('product.ecotax.size')} />
            )}
            <p className="mt-2 h-[28px] text-sm text-red-700">
              {errors.size && (errors.size.message as string)}
            </p>
          </div>
          <div className="mt-2 sm:col-span-6">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="paidInAdvance"
                  type="checkbox"
                  {...register('paidInAdvance')}
                  className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="paidInAdvance"
                  className="mb-2 block text-sm font-medium leading-6 text-dark">
                  {t('product.ecotax.paid-in-advance')}
                </label>
              </div>
            </div>
          </div>
          <div className="mt-2 sm:col-span-6">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="enable"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-primary-500 focus:ring-primary-500"
                  {...register('enable', {
                    onChange: (e) => onChangeEnable(e),
                  })}
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="enable"
                  className="mb-2 block text-sm font-medium leading-6 text-dark">
                  {t('product.ecotax.applicable')}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end p-6 text-right sm:px-6">
          <Button type="submit" loading={loading} disabled={loading || !submitPossible || !isDirty}>
            {t('global.actions.save')}
          </Button>
        </div>
      </form>
    </>
  );
};

export default Index;
