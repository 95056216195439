import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { FormProvider, useForm } from 'react-hook-form';
import { updateProductBrandMadeInMutation } from 'graphql/Mutations/Product/Brand/updateProductBrandMadeIn';
import { productQuery } from 'graphql/Queries/Product/Brand/product';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { Button } from 'components/Common/Button';
import useNotification from 'hooks/useNotification';
import ResultView from './ResultView';
import SelectBrand from './SelectBrand';
import SelectMadeIn from './SelectMadeIn';
import Header from './Header';
import Card from 'components/Common/Card';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

interface FormDefaultValues {
  brand: {
    value: string;
    label: string;
    url: string;
  } | null;
  madeIn: {
    value: string;
    label: string;
    url: string;
  } | null;
}

const BrandIndex = () => {
  const params = useParams();
  const { t } = useTranslation();
  const { setNotification, setError } = useNotification();

  const { data, loading, error } = useQuery(productQuery, {
    variables: { id: params.productId },
  });
  const [update, { loading: mutationLoading }] = useMutation(updateProductBrandMadeInMutation);

  const methods = useForm<FormDefaultValues>({
    defaultValues: {
      brand: null,
      madeIn: null,
    },
  });

  const { formState } = methods;

  useEffect(() => {
    methods.reset({
      madeIn: data?.product?.madeIn
        ? {
            value: data.product.madeIn.id,
            label: data.product.madeIn.label,
            url: data.product.madeIn.logoFullPath,
          }
        : null,
      brand: data?.product?.branding
        ? {
            value: data.product.branding.id,
            label: data.product.branding.label,
            url: data.product.branding.logoFullPath,
          }
        : null,
    });
  }, [data, methods]);

  const onSubmit = () => {
    update({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      variables: {
        id: params.productId,
        made_in_id: methods.getValues('madeIn')?.value ?? 0,
        brand_id: methods.getValues('brand')?.value ?? 0,
      },
      onCompleted: (data) => {
        setNotification({
          title: t('global.success'),
          message: t('global.notif.update-success'),
          type: 'success',
        });
        methods.reset({
          madeIn: data?.updateProductBrandMadeIn.madeIn
            ? {
                value: data.updateProductBrandMadeIn.madeIn.id,
                label: data.updateProductBrandMadeIn.madeIn.label,
                url: methods.getValues('madeIn')?.url ?? '',
              }
            : null,

          brand: data?.updateProductBrandMadeIn.branding
            ? {
                value: data.updateProductBrandMadeIn.branding.id,
                label: data.updateProductBrandMadeIn.branding.label,
                url: methods.getValues('brand')?.url ?? '',
              }
            : null,
        });
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  const { productExists } = useProductContextType();

  if (!productExists) return <></>;

  return (
    <>
      <FormProvider {...methods}>
        <Header />
        {error ? (
          <div className="mt-5">
            <ApolloErrorAlert error={error} />
          </div>
        ) : (
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="grid h-full w-full grid-cols-1 gap-6 2xl:grid-cols-2">
            <Card>
              <div className="flex h-full flex-col justify-between">
                <div>
                  <SelectMadeIn supplierCountry={data?.product?.supplier?.productionSites} />
                  <SelectBrand />
                </div>
                <div className="flex justify-end space-x-3 pt-6">
                  <Button
                    type="button"
                    onClick={() => methods.reset()}
                    theme={'secondary'}
                    disabled={loading || mutationLoading}>
                    {t('global.actions.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    loading={mutationLoading}
                    disabled={mutationLoading || mutationLoading || !formState.isDirty}>
                    {t('global.actions.save')}
                  </Button>
                </div>
              </div>
            </Card>
            <div className="h-80 rounded-md border bg-white">
              <ResultView product={data?.product} queryLoading={loading} />
            </div>
          </form>
        )}
      </FormProvider>
    </>
  );
};

export default BrandIndex;
