import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { Product } from 'types/Product';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import useCheckUrlImage from 'components/Products/Hooks/useCheckUrlImage';

type Props = {
  product: Product;
  withoutLink?: boolean;
  isBlank?: boolean;
};

const ProductInfos = ({ product, withoutLink = false, isBlank = false }: Props) => {
  const { t } = useTranslation();
  const isValidImage = useCheckUrlImage(product?.primaryImage?.url);

  return (
    <div className="mt-1 flex items-center">
      <div className="flex-shrink-0">
        {isValidImage ? (
          <img
            className="w-16"
            src={product?.primaryImage?.url}
            alt={t('product.header.image-not-found')}
          />
        ) : (
          <PhotoIcon className="feather feather-image h-16 w-16" />
        )}
      </div>
      <div className="ml-4 min-w-0">
        {withoutLink ? (
          <div className="font-medium text-gray-900">{product.id}</div>
        ) : (
          <Link target={isBlank ? '_blank' : ''} to={`/products/${product.id}/preview`}>
            <div className="font-medium text-blue-600 hover:text-blue-700">{product.id}</div>
          </Link>
        )}
        <Tippy content={product.currentTranslation?.internal_title}>
          <div className="mt-1 truncate text-gray-900">
            {product.currentTranslation?.internal_title}
          </div>
        </Tippy>
        <div className="mt-1 text-sm text-gray-500">
          {t('global.created-at')}{' '}
          {product.created_at
            ? moment(product.created_at).format('Do MMMM YYYY')
            : t('global.unknown-date')}
        </div>
      </div>
    </div>
  );
};

export default ProductInfos;
