import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { updateAllProductDisplayPreferencesMutation } from 'graphql/Mutations/Product/updateAllProductDisplayPreferences';
import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { productQuery } from 'graphql/Queries/Product/Visibility/Comments/productComments';
import { InputTextAreaControl } from 'components/Common/InputTextAreaControl';
import { visibilityInformationQuery } from 'graphql/Queries/Product/Visibility/visibilityInfo';
import SlideOver from 'components/Common/SlideOver';
import useNotification from 'hooks/useNotification';
import * as yup from 'yup';

const BAN_COUNTRY_ID = 2;

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const BanAllCountriesSlide = ({ open, setOpen }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const inputRef = useRef(null);
  const { setNotification } = useNotification();

  const schema = yup.object().shape({
    comment: yup.string().trim().required(t('form.required')).min(1, t('form.required')),
  });

  const {
    handleSubmit,
    reset,
    control,
    clearErrors,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  const [updateAllProductDisplayPreferences, { loading, error }] = useMutation(
    updateAllProductDisplayPreferencesMutation
  );

  const onSubmit = handleSubmit((data) => {
    updateAllProductDisplayPreferences({
      variables: {
        id: params.productId,
        displayPreference: BAN_COUNTRY_ID,
        comment: data.comment,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: visibilityInformationQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          type: 'success',
          title: t('product.visibility.notifications.title'),
          message: t('product.visibility.notifications.message'),
        });
        closeSlide();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const closeSlide = () => {
    reset();
    setOpen(false);
    clearErrors();
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.visibility.forbidden.title')}
      buttonText={t('global.actions.validate')}
      isDirty={isDirty}
      loading={loading}
      error={error}
      onSubmit={onSubmit}
      onCancel={closeSlide}>
      <>
        <div className="mb-8 mt-4 text-sm text-dark">
          {t('product.visibility.forbidden.subtitle')}
        </div>
        <InputTextAreaControl
          control={control}
          ref={inputRef}
          label={t('product.visibility.forbidden.label')}
          name={'comment'}
          rows={7}
          isFocused
          isRequired
        />
      </>
    </SlideOver>
  );
};
export default BanAllCountriesSlide;
