import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, FieldValues } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import useNotification from 'hooks/useNotification';
import { Product } from 'types/Product';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { productCombinationQuery } from 'graphql/Queries/Product/ProductCombination/productCombination';
import { createProductCombinationMutation } from 'graphql/Mutations/Product/ProductCombination/createProductCombination';
import { SearchProduct } from 'components/Product/ProductCombination/Search';
import { InputTextControl } from 'components/Common/InputTextControl';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const AddPack = ({ setOpen, open }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [addConstituent, { loading, error }] = useMutation(createProductCombinationMutation);

  const [selection, setSelection] = useState<Product | null>(null);

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .integer(t('form.number.integer-positive'))
      .min(1, t('form.number.integer-positive'))
      .typeError(t('form.number.integer-positive'))
      .required(t('product.ecotax.form.catergory-required')),
  });

  const { handleSubmit, reset, control } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
  });

  useEffect(() => {
    reset({
      quantity: 1,
    });
  }, [reset]);

  const close = () => {
    setOpen(false);
    setSelection(null);
  };

  const onSubmit = handleSubmit((data) => {
    if (!selection) return;
    const quantityAsNumber =
      typeof data.quantity === 'string' ? parseInt(data.quantity, 10) : data.quantity;

    addConstituent({
      variables: {
        parent_product_id: params.productId,
        child_product_id: selection.id,
        child_product_qty: quantityAsNumber,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productCombinationQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.product-combination.notif.success'),
          type: 'success',
        });
        reset();
        close();
        setSelection(null);
      },
      onError: () => {
        reset();
        setSelection(null);
      },
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.product-combination.add-pack-product')}
      buttonText={t('global.actions.add')}
      onSubmit={onSubmit}
      onCancel={close}
      loading={loading}
      error={error}
      isDirty={!!selection}>
      <div className="mt-4 flex flex-col space-y-12">
        <SearchProduct selection={selection} setSelection={setSelection} />
        <InputTextControl
          control={control}
          name="quantity"
          label={t('product.product-combination.quantity')}
        />
      </div>
    </SlideOver>
  );
};
export default AddPack;
