import { gql } from 'graphql.macro';

export const ExportPackagingQuery = gql`
  query ExportPackaging($id: ID!) {
    product(id: $id) {
      id
      weight
      volume
      packageSizes {
        id
        height
        width
        depth
        type {
          label
          legacy_code
        }
        quantity_by_pallet
        to_repack
        number
        volume
        weight
        package {
          id
          number
          name
          weight
          volume
        }
      }
      isSinglePackage
    }
  }
`;
