import React from 'react';

const Skeleton = () => {
  return (
    <>
      <div className="flex h-20 justify-between items-center w-full border-b-2">
        <div className="flex flex-col">
          <div className="flex items-center">
            <p className="skeleton w-32 h-6"></p>
          </div>
        </div>
        <div className="flex items-center space-x-8">
          <div className="skeleton h-8 w-32"></div>
          <div className="skeleton h-8 w-8"></div>
        </div>
      </div>
      <div className="flex h-20  justify-between items-center w-full border-b-2">
        <div className="flex flex-col">
          <div className="flex items-center">
            <p className="skeleton w-32 h-6"></p>
          </div>
        </div>
        <div className="flex items-center space-x-8">
          <div className="skeleton h-8 w-32"></div>
          <div className="skeleton h-8 w-8"></div>
        </div>
      </div>
      <div className="flex h-20  justify-between items-center w-full border-b-2">
        <div className="flex flex-col">
          <div className="flex items-center">
            <p className="skeleton w-32 h-6"></p>
          </div>
        </div>
        <div className="flex items-center space-x-8">
          <div className="skeleton h-8 w-32"></div>
          <div className="skeleton h-8 w-8"></div>
        </div>
      </div>
    </>
  );
};
export default Skeleton;
