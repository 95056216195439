import { gql } from 'graphql.macro';

export const updateCommentMutation = gql`
  mutation UpdateComment($id: ID!, $comment: String) {
    updateSupplier(id: $id, comment: $comment) {
      id
      comment
    }
  }
`;
