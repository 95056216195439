import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { ApolloErrorAlert, WarningAlert } from '../Common/Alerts';
import Thumbnail from 'components/Product/Thumbnail';
import ThumbnailSkeleton from '../Product/ThumbnailSkeleton';
import { newsQuery } from 'graphql/Queries/Home/news';
import { Product } from 'types/Product';

const News = () => {
  const { loading, error, data } = useQuery(newsQuery);
  const { t } = useTranslation();

  return (
    <div>
      <header>
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold leading-tight text-gray-900">
            🦋 {t('home.news.title')}
          </h1>
        </div>
      </header>
      <main>
        <div className="mx-auto sm:px-6 lg:px-8">
          <div className="flex justify-center px-4 py-8 sm:px-0">
            {error && <ApolloErrorAlert error={error} />}
            {data && data.News.length < 1 && <WarningAlert>{t('home.news.no-news')}</WarningAlert>}
            <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
              {loading
                ? [...Array(12)].map((e, i) => <ThumbnailSkeleton key={i} />)
                : data &&
                  data.News.length > 0 &&
                  data.News.map((product: Product) => (
                    <Thumbnail
                      key={product.id}
                      id={parseInt(product.id as string)}
                      previewImage={product.previewImage}
                      title={product.currentTranslation?.title}
                      price={product.currentLanguageOffer?.price}
                    />
                  ))}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default News;
