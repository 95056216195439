import React from 'react';
import { Disclosure } from '@headlessui/react';
import FieldLegend from './FormUtils/FieldLegend';
import { useTranslation } from 'react-i18next';
import { FieldValues, useFormContext, UseFormRegister } from 'react-hook-form';
import CancelRangeButton from './FormUtils/CancelRangeButton';

type Props = {
  register: UseFormRegister<FieldValues>;
};

const PriceRangeFilter = ({ register }: Props) => {
  const { resetField, getValues } = useFormContext();
  const { t } = useTranslation();
  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div" className={''}>
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.price.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              <div className="relative mt-3">
                {t('products.filters.price.filter-min')}

                <div className="relative flex w-[210px] justify-between">
                  <input
                    {...register('min_price')}
                    id={'min_price'}
                    className="w-full rounded-md pl-8"
                    type="number"
                    min={0}
                  />
                  {getValues('min_price') && (
                    <CancelRangeButton handle={() => resetField('min_price')} />
                  )}
                </div>
              </div>
              <div className="mt-3 ">
                {t('products.filters.price.filter-max')}

                <div className="relative flex w-[210px] justify-between">
                  <input
                    {...register('max_price')}
                    id={'max_price'}
                    className="w-full rounded-md pl-8"
                    type="number"
                  />
                  {getValues('max_price') && (
                    <CancelRangeButton handle={() => resetField('max_price')} />
                  )}
                </div>
              </div>
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default PriceRangeFilter;
