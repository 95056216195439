import { PhotoIcon } from '@heroicons/react/24/outline';
import Tippy from '@tippyjs/react';
import useCheckUrlImage from 'components/Products/Hooks/useCheckUrlImage';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Product } from 'types/Product';

type Props = {
  product: Product;
};

const SimpleCard = ({ product }: Props) => {
  const { t } = useTranslation();
  const isValidImage = useCheckUrlImage(product.primaryImage?.clientUrl);

  const title = product.currentTranslation
    ? product.currentTranslation.internal_title
      ? product.currentTranslation.internal_title
      : t('global.no-title')
    : t('global.no-translation');

  return (
    <div className="h-56 rounded border border-gray-200 bg-white">
      <Link to={`/products/${product.id}/`} target="_blank" className="space-y-2">
        <div className="flex h-full flex-col justify-between">
          <div className="relative flex h-full flex-col justify-center overflow-hidden">
            {isValidImage ? (
              <img src={product.primaryImage?.clientUrl} className="w-full" />
            ) : (
              <PhotoIcon className="h-40 w-full text-secondary" />
            )}
          </div>
          <div className="flex flex-col justify-end space-y-3 px-2 pb-2">
            <Tippy content={title}>
              <p className="truncate text-sm text-dark">{title}</p>
            </Tippy>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default SimpleCard;
