import { gql } from 'graphql.macro';

export const updateAssociatedForwarderStatusMutation = gql`
  mutation UpdateAssociatedForwarderStatus($id: ID!, $active: Boolean) {
    updateAssociatedForwarder(id: $id, active: $active) {
      id
      label
      contact_firstname
      contact_lastname
      contact_email
      active
      used
    }
  }
`;
