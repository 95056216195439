import React from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { Link, useParams } from 'react-router-dom';
import useNotification from 'hooks/useNotification';
import { updateUrlMutation } from 'graphql/Mutations/Product/Editorial/updateUrl';
import { editorialQuery } from 'graphql/Queries/Product/Editorial/editorial';
import { ID } from 'types/Global';
import { createProductTranslationMutation } from 'graphql/Mutations/Product/Editorial/createProductTranslation';
import Card from 'components/Common/Card';
import { InputTextControl } from 'components/Common/InputTextControl';
import { Button } from 'components/Common/Button';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

interface Props {
  url: string | null;
  fullUrl: string | null;
  translationId: ID | null;
  isLoading: boolean;
  langId: ID;
}

const Url = ({ url, fullUrl, translationId, isLoading, langId }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification, setError } = useNotification();

  const [createProductTranslation, { loading: createProductTranslationLoading }] = useMutation(
    createProductTranslationMutation
  );
  const [updateUrl, { loading: updateUrlLoading }] = useMutation(updateUrlMutation);

  const mutationLoading = createProductTranslationLoading || updateUrlLoading;

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    control,
  } = useForm<FieldValues>({
    defaultValues: { url: url ?? '' },
  });

  const onSubmit = handleSubmit(async (data) => {
    let translationIdToUpdate = translationId;
    if (!translationIdToUpdate) {
      await new Promise<void>((resolve, reject) => {
        createProductTranslation({
          variables: {
            product_id: params.productId,
            lang_id: langId,
          },
          onCompleted: (res) => {
            translationIdToUpdate = res.createProductTranslation.id;
            resolve();
          },
          onError: (error) => {
            setError(error);
            reject(error);
          },
        });
      });
    }
    updateUrl({
      variables: {
        id: translationIdToUpdate,
        url: data.url,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: editorialQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.editorial.url.notif-success'),
          type: 'success',
        });
        reset(data);
      },
      onError: (error) => {
        setError(error);
      },
    });
  });

  return (
    <Card title={t('product.editorial.url.title')}>
      <form onSubmit={onSubmit}>
        {url && fullUrl ? (
          <Link to={fullUrl} target="_blank">
            <span className="flex w-full text-sm text-blue-600 hover:text-blue-700 sm:rounded-b-lg">
              {url}
            </span>
          </Link>
        ) : (
          <div className="space-y-6">
            <InputTextControl
              control={control}
              name="url"
              label={t('product.editorial.url.label')}
              isLoading={isLoading}
            />
            <div className="flex justify-end">
              <Button
                loading={mutationLoading}
                disabled={!isDirty || isLoading || mutationLoading}
                type="submit">
                {t('global.actions.save')}
              </Button>
            </div>
          </div>
        )}
      </form>
    </Card>
  );
};

export default Url;
