const ThumbnailSkeleton = () => {
  return (
    <div className="group relative w-[280px] grow rounded-lg bg-white p-5">
      <div className="min-h-80 aspect-h-1 aspect-w-1 flex w-full items-center overflow-hidden rounded-md lg:aspect-none group-hover:opacity-75 lg:h-80">
        <div
          alt=""
          className="skeleton h-full w-full object-fill object-center lg:h-full lg:w-full"></div>
      </div>
      <div className="mt-4 flex flex-col gap-3">
        <div className="skeleton h-4 w-full"></div>
        <div className="skeleton h-4 w-[50px]"></div>
      </div>
    </div>
  );
};

export default ThumbnailSkeleton;
