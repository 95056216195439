import { useLazyQuery } from '@apollo/client';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import { findProductModelsByOffsetQuery } from 'graphql/Queries/Product/ModelsAndCollections/findProductModelsByOffset';
import { findProductModelsByTextQuery } from 'graphql/Queries/Product/ModelsAndCollections/findProductModelsByText';
import React, { ChangeEvent, ComponentPropsWithoutRef, useState } from 'react';
import { Control, Controller, useController } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AsyncPaginate } from 'react-select-async-paginate';
import { OptionProps } from 'types/Global';
import { ProductModel } from 'types/Product';

type Props = {
  control: Control;
  loading: boolean;
  updateDisplayProduct: () => void;
  isInactive: boolean;
} & ComponentPropsWithoutRef<'select'>;

const ModelSelect = ({ control, loading, updateDisplayProduct, ...props }: Props) => {
  const { t } = useTranslation();
  const { field } = useController({ name: 'model', control });
  const { onChange, value } = field;

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e);
    props.onChange?.(e);
    getStyles(true);
    props.isInactive = false;
    updateDisplayProduct();
  };

  const [optionsLoaded, setOptionsLoaded] = useState(0);

  const [findModelsByOffset, { error: errorQuery1 }] = useLazyQuery(findProductModelsByOffsetQuery);
  const [findModelsByText, { error: errorQuery2 }] = useLazyQuery(findProductModelsByTextQuery);

  const loadOptions = async (search?: string) => {
    if (search) {
      const response = await findModelsByText({
        variables: {
          text: search,
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          return Promise.resolve(data);
        },
        onError: () => {
          return Promise.resolve(null);
        },
      });

      if (!response.data) {
        return {
          options: [],
          hasMore: false,
        };
      }

      setOptionsLoaded(0);

      return {
        options: formatProductModelOptions(response.data.findProductModelsByText, true),
        hasMore: false,
      };
    } else {
      const response = await findModelsByOffset({
        variables: {
          offset: optionsLoaded,
          number: 50,
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
          return Promise.resolve(data);
        },
        onError: () => {
          return Promise.resolve(null);
        },
      });
      if (!response.data) {
        return {
          options: [],
          hasMore: false,
        };
      }

      const responseResult = formatProductModelOptions(response.data.findProductModelsByOffset);
      setOptionsLoaded((optionsLoaded) => optionsLoaded + 50);

      return {
        options: responseResult,
        hasMore: responseResult.length > 0,
      };
    }
  };

  const formatProductModelOptions = (allProductModel: ProductModel[], search = false) => {
    let allProductModelOptions: OptionProps[] = [];
    allProductModel.map((productModel) => {
      if (search) {
        allProductModelOptions = [
          ...allProductModelOptions,
          {
            value: productModel.id as string,
            label: productModel.name,
            isDisabled: !productModel.active,
          },
        ];
      } else {
        allProductModelOptions = [
          ...allProductModelOptions,
          {
            value: productModel.id as string,
            label: productModel.name,
            isDisabled: !productModel.active,
          },
        ];
      }
    });
    return allProductModelOptions;
  };

  const getStyles = (clear = false) => {
    if (clear || errorQuery1 || errorQuery2 || !props.isInactive) {
      return customStyle;
    }
    return errorCustomStyle;
  };

  return (
    <>
      <label className="truncate text-sm font-medium text-dark">
        {t('product.model-style.model')}
      </label>
      <div className="relative">
        <Controller
          name="model"
          control={control}
          render={() => (
            <AsyncPaginate
              className="mt-2"
              isDisabled={loading}
              placeholder={loading ? t('global.form.loading') : t('global.form.select')}
              isClearable
              value={value}
              onChange={onChangeSelect}
              loadOptions={loadOptions}
              loadingMessage={() => t('global.loading')}
              noOptionsMessage={() => t('global.no-option')}
              debounceTimeout={500}
              styles={getStyles()}
              theme={theme}
            />
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
          {(errorQuery1 || errorQuery2 || props.isInactive) && (
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          )}
        </div>
      </div>
      {props.isInactive && (
        <p className="mt-2 text-sm text-red-600">{t('product.taxonomy.form.model-inactive')}</p>
      )}
      {errorQuery1 && errorQuery1.graphQLErrors[0]?.extensions?.category === 'custom' ? (
        <p className="mt-2 text-sm text-red-600">{errorQuery1.graphQLErrors[0].message}</p>
      ) : (
        errorQuery1 && <p className="mt-2 text-sm text-red-600">{t('global.error-msg')}</p>
      )}
      {errorQuery2 && errorQuery2.graphQLErrors[0]?.extensions?.category === 'custom' ? (
        <p className="mt-2 text-sm text-red-600">{errorQuery2.graphQLErrors[0].message}</p>
      ) : (
        errorQuery2 && <p className="mt-2 text-sm text-red-600">{t('global.error-msg')}</p>
      )}
    </>
  );
};

export default ModelSelect;
