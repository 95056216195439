import React, { SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/client';
import { updateFeaturesPositionsMutation } from 'graphql/Mutations/Product/updateFeaturesPositions';
import { productFeaturesQuery } from 'graphql/Queries/Product/Features/productFeatures';
import { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import useNotification from 'hooks/useNotification';
import { ProductFeature } from 'types/Product';
import SortableItem from './SortableItem';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  features: ProductFeature[];
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
};

type PositionsType = { [key: string]: number };

const OrganiseFeatures = ({ open, setOpen, features }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const { handleSubmit } = useForm();
  const [updatePositions, { loading, error, reset }] = useMutation(updateFeaturesPositionsMutation);

  const close = () => {
    setOpen(false);
  };

  const resetSlide = () => {
    reset();
    setItems(features);
    setOpen(false);
  };

  const [items, setItems] = useState<ProductFeature[]>([]);
  const [positions, setPositions] = useState<PositionsType | null>(null);

  useEffect(() => {
    if (!features) return;
    setItems(features);
  }, [setItems, features]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      setItems((currentItems: ProductFeature[]) => {
        const oldIndex = currentItems.findIndex((item) => item.id === active.id);
        const newIndex = currentItems.findIndex((item) => item.id === over.id);
        const orderedPositions = arrayMove(currentItems, oldIndex, newIndex);
        const positions = getPositions(orderedPositions);
        setPositions(positions);

        return orderedPositions;
      });
    }
  };

  const getPositions = (organizedFeatures: ProductFeature[]) => {
    const formattedPositions: { [key: string]: number } = {};
    let position = 1;
    organizedFeatures.forEach((feature) => {
      formattedPositions[feature.id] = position;
      position++;
    });

    return formattedPositions;
  };

  const onSubmit = handleSubmit(() => {
    updatePositions({
      variables: {
        product_id: params.productId,
        positions: positions,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productFeaturesQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.features.organise-features-success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.documents.notices.comments')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={resetSlide}
      loading={loading}
      error={error}>
      <div className="flex flex-col text-sm text-dark ">
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={items} strategy={rectSortingStrategy}>
            {items.map((feature) => {
              return (
                <div key={feature.id} className=" m-3 rounded-md bg-gray-100">
                  <SortableItem feature={feature} />
                </div>
              );
            })}
          </SortableContext>
        </DndContext>
      </div>
    </SlideOver>
  );
};
export default OrganiseFeatures;
