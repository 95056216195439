import { useForm } from 'react-hook-form';
import useNotification from 'hooks/useNotification';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteAssociatedForwarder from './DeleteAssociatedForwarder';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { InformationAlert } from 'components/Common/Alerts';
import { associatedForwardersQuery } from 'graphql/Queries/Supplier/GeneralInformations/PurchaseInformations/associatedForwarders';
import { createAssociatedForwarderMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/createAssociatedForwarder';
import { updateAssociatedForwarderMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updateAssociatedForwarder';
import { updateAssociatedForwarderStatusMutation } from 'graphql/Mutations/Suppliers/ContactsAndPurchaseInformations/updateAssociatedForwarderStatus';
import SelectCreatable from 'components/Common/SelectCreatable';
import { Button } from 'components/Common/Button';
import Card from 'components/Common/Card';

const AssociatedForwarders = () => {
  const { t } = useTranslation();

  const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ');
  };

  const [showEditAssociatedForwarder, setShowEditAssociatedForwarder] = useState(false);
  const [showNewAssociatedForwarder, setShowNewAssociatedForwarder] = useState(false);
  const [allAssociatedForwarders, setAllAssociatedForwarders] = useState([]);
  const [associatedForwarder, setAssociatedForwarder] = useState(null);
  const [displayModal, setDisplayModal] = useState(false);

  const [getAllAssociatedForwarders, { data, loading: loadingAssociatedForwarders }] = useLazyQuery(
    associatedForwardersQuery,
    {
      fetchPolicy: 'network-only',
    }
  );

  useEffect(() => {
    if (data) {
      setAllAssociatedForwarders(
        data.associatedForwarders?.map((associatedForwarder) => {
          return {
            label: associatedForwarder.label,
            value: associatedForwarder,
            key: associatedForwarder.id,
          };
        })
      );
    }
  }, [data]);

  const [createAssociatedForwarder, { loading: createAssociatedForwarderLoading }] = useMutation(
    createAssociatedForwarderMutation
  );

  const [updateAssociatedForwarder, { loading: updateAssociatedForwarderLoading }] = useMutation(
    updateAssociatedForwarderMutation
  );
  const [updateAssociatedForwarderStatus, { loading: updateAssociatedForwarderStatusLoading }] =
    useMutation(updateAssociatedForwarderStatusMutation);

  const onChangeAssociatedForwarder = (e) => {
    setShowEditAssociatedForwarder(true);
    setShowNewAssociatedForwarder(false);
    setAssociatedForwarder(e.value);
    reset({
      label: e.value.label,
      contact_firstname: e.value.contact_firstname,
      contact_lastname: e.value.contact_lastname,
      contact_email: e.value.contact_email,
    });
  };

  const onCreateAssociatedForwarder = (value) => {
    setShowEditAssociatedForwarder(false);
    setShowNewAssociatedForwarder(true);
    setAssociatedForwarder({
      value,
      label: value,
    });
    reset({
      label: value,
      contact_firstname: '',
      contact_lastname: '',
      contact_email: '',
    });
  };

  const schema = yup.object().shape({
    label: yup.string().trim().min(1),
    contact_email: yup.string().email(t('form.invalid-email')).nullable(),
  });

  const {
    handleSubmit,
    register,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      label: '',
      contact_firstname: '',
      contact_lastname: '',
      contact_email: '',
    },
  });

  const onCancel = () => {
    setShowNewAssociatedForwarder(false);
    setAssociatedForwarder(null);
  };

  const { setNotification, setError } = useNotification();

  const onSubmit = (data) => {
    if (showNewAssociatedForwarder) {
      createAssociatedForwarder({
        refetchQueries: [
          {
            query: associatedForwardersQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          label: data.label,
          contact_firstname: data.contact_firstname,
          contact_lastname: data.contact_lastname,
          contact_email: data.contact_email,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.createAssociatedForwarder;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.associated-forwarders.created'),
            type: 'success',
          });
          reset({
            label: data.label,
            contact_firstname: data.contact_firstname,
            contact_lastname: data.contact_lastname,
            contact_email: data.contact_email,
          });
          setAssociatedForwarder(data);
          setShowEditAssociatedForwarder(true);
          setShowNewAssociatedForwarder(false);
        },
        onError: (error) => {
          setError(error);
        },
      });
    } else {
      updateAssociatedForwarder({
        refetchQueries: [
          {
            query: associatedForwardersQuery,
          },
        ],
        awaitRefetchQueries: true,
        variables: {
          id: associatedForwarder.id,
          contact_firstname: data.contact_firstname,
          contact_lastname: data.contact_lastname,
          contact_email: data.contact_email,
          label: data.label,
        },
        onCompleted: (dataReturn) => {
          let data = dataReturn.updateAssociatedForwarder;
          setNotification({
            title: t('global.success'),
            message: t('settings.suppliers.purchase-infos.associated-forwarders.updated'),
            type: 'success',
          });
          reset({
            label: data.label,
            contact_firstname: data.contact_firstname,
            contact_lastname: data.contact_lastname,
            contact_email: data.contact_email,
          });
          setAssociatedForwarder(data);
        },
        onError: (error) => {
          setError(error);
        },
      });
    }
  };

  const resetAfterDelete = () => {
    setAssociatedForwarder(null);
    setShowEditAssociatedForwarder(false);
    setShowNewAssociatedForwarder(false);
  };

  const changeActivation = (value) => {
    let message = '';
    if (value) {
      message = t('settings.suppliers.purchase-infos.associated-forwarders.activate');
    } else {
      message = t('settings.suppliers.purchase-infos.associated-forwarders.deactivate');
    }
    updateAssociatedForwarderStatus({
      refetchQueries: [
        {
          query: associatedForwardersQuery,
        },
      ],
      awaitRefetchQueries: true,
      variables: {
        id: associatedForwarder.id,
        active: value,
      },
      onCompleted: (dataReturn) => {
        let data = dataReturn.updateAssociatedForwarder;
        setNotification({
          title: t('global.success'),
          message,
          type: 'success',
        });
        reset({
          label: data.label,
        });
        setAssociatedForwarder(data);
      },
      onError: (error) => {
        setError(error);
      },
    });
  };

  return (
    <>
      <Card>
        <DeleteAssociatedForwarder
          onCloseModal={(loading) => {
            if (!loading) {
              setDisplayModal(false);
            }
          }}
          isOpen={displayModal}
          associatedForwarder={associatedForwarder}
          reset={resetAfterDelete}
        />
        <div className="p-4">
          <h3 className="text-lg font-medium text-blue-gray-900">
            {t('settings.suppliers.purchase-infos.associated-forwarders.title')}
          </h3>
          <p className="mt-1 text-sm text-blue-gray-500">
            {t('settings.suppliers.purchase-infos.associated-forwarders.subtitle')}
          </p>
        </div>
        <div className="p-4">
          <label className="text-sm font-medium text-gray-700">
            {t('settings.suppliers.purchase-infos.associated-forwarders.list')}
          </label>
          <SelectCreatable
            loadingOptions={loadingAssociatedForwarders}
            options={allAssociatedForwarders}
            onCreateOption={onCreateAssociatedForwarder}
            onChange={onChangeAssociatedForwarder}
            value={associatedForwarder}
            onFocus={() => {
              if (!allAssociatedForwarders || allAssociatedForwarders.length === 0) {
                getAllAssociatedForwarders();
              }
            }}
          />
        </div>
        {(showEditAssociatedForwarder || showNewAssociatedForwarder) && (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="grid grid-cols-2 gap-4 p-4">
                <div className="col-span-2">
                  <label className="text-sm font-medium text-gray-700">
                    {t('settings.suppliers.purchase-infos.name')}
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={classNames(
                        errors.label
                          ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                          : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                        'mt-1 block h-9 w-full flex-1 rounded'
                      )}
                      {...register('label')}
                    />
                    {errors.label && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                      </div>
                    )}
                  </div>
                  {errors.label && (
                    <p className="mt-2 text-sm text-red-700">{t('form.required')}</p>
                  )}
                </div>
                <div>
                  <label className="mt-2 text-sm font-medium text-gray-700">
                    {t('settings.suppliers.purchase-infos.firstname')}
                  </label>
                  <input
                    type="text"
                    className="mt-1 block h-9 w-full flex-1 rounded border-gray-300 focus:border-primary-500 focus:ring-primary-500"
                    {...register('contact_firstname')}
                  />
                </div>
                <div>
                  <label className="mt-2 text-sm font-medium text-gray-700">
                    {t('settings.suppliers.purchase-infos.lastname')}
                  </label>
                  <input
                    type="text"
                    className="mt-1 block h-9 w-full flex-1 rounded border-gray-300 focus:border-primary-500 focus:ring-primary-500"
                    {...register('contact_lastname')}
                  />
                </div>
                <div className="col-span-2">
                  <label className="mt-2 text-sm font-medium text-gray-700">
                    {t('settings.suppliers.purchase-infos.email')}
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      className={classNames(
                        errors.contact_email
                          ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                          : 'border-gray-300 focus:border-primary-500 focus:ring-primary-500',
                        'mt-1 block h-9 w-full flex-1 rounded'
                      )}
                      {...register('contact_email')}
                    />
                    {errors.contact_email && (
                      <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                        <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
                      </div>
                    )}
                  </div>
                  {errors.contact_email && (
                    <p className="mt-2 text-sm text-red-700">{errors.contact_email.message}</p>
                  )}
                </div>
                {associatedForwarder.used && (
                  <div className="col-span-2 mt-4">
                    <InformationAlert>
                      {t('settings.suppliers.purchase-infos.associated-forwarders.used')}
                    </InformationAlert>
                  </div>
                )}
              </div>
              <div className="flex flex-col sm:flex-row sm:justify-end px-4 py-3 gap-4 sm:rounded-b-md sm:px-6">
                {showEditAssociatedForwarder && (
                  <>
                    <div className="grid grid-rows-3 sm:grid-rows-1 frid-cols_1 sm:grid-cols-3 gap-4">
                      <Button
                        type="button"
                        theme="outlineDanger"
                        disabled={updateAssociatedForwarderLoading || associatedForwarder.used}
                        onClick={() => setDisplayModal(true)}>
                        {t('global.actions.delete')}
                      </Button>
                      {associatedForwarder.active ? (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          disabled={
                            updateAssociatedForwarderLoading ||
                            updateAssociatedForwarderStatusLoading
                          }
                          loading={updateAssociatedForwarderStatusLoading}
                          onClick={() => changeActivation(false)}>
                          {t('global.actions.deactivate')}
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          theme="outlinePrimary"
                          disabled={
                            updateAssociatedForwarderLoading ||
                            updateAssociatedForwarderStatusLoading
                          }
                          loading={updateAssociatedForwarderStatusLoading}
                          onClick={() => changeActivation(true)}>
                          {t('global.actions.activate')}
                        </Button>
                      )}
                      <Button
                        type="submit"
                        theme="primary"
                        disabled={
                          updateAssociatedForwarderLoading ||
                          updateAssociatedForwarderStatusLoading ||
                          !isDirty
                        }
                        loading={updateAssociatedForwarderLoading}>
                        {t('global.actions.save')}
                      </Button>
                    </div>
                  </>
                )}
                {showNewAssociatedForwarder && (
                  <>
                    <Button type="button" theme="transparent" onClick={onCancel}>
                      {t('global.actions.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      theme="primary"
                      loading={createAssociatedForwarderLoading}
                      disabled={createAssociatedForwarderLoading}>
                      {t('global.actions.add')}
                    </Button>
                  </>
                )}
              </div>
            </form>
          </>
        )}
      </Card>
    </>
  );
};

export default AssociatedForwarders;
