import { gql } from 'graphql.macro';

export const findProductCollectionsByOffsetQuery = gql`
  query FindProductCollectionsByOffset($number: Int!, $offset: Int!) {
    findProductCollectionsByOffset(number: $number, offset: $offset) {
      id
      label
      active
      isUsed
    }
  }
`;
