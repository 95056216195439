import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { RadioGroup } from '@headlessui/react';
import { updateProductDisplayPreferenceMutation } from 'graphql/Mutations/Product/updateProductDisplayPreference';
import { ApolloError, useMutation } from '@apollo/client';
import { VisibilityInformation } from 'types/Product';
import { visibilityInformationQuery } from 'graphql/Queries/Product/Visibility/visibilityInfo';
import useNotification from 'hooks/useNotification';
import AddCommentSlideOver from './AddCommentSlideOver';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  visibility?: VisibilityInformation;
  loading?: boolean;
  currentCountryName?: string;
  setUpdateError: React.Dispatch<React.SetStateAction<ApolloError | null>>;
};

type VisibilityOption = {
  name: string;
  value: number;
};

const VisibilityRadioButton = ({
  visibility,
  loading,
  currentCountryName,
  setUpdateError,
}: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [updateProductDisplayPreference, { loading: mutationLoading }] = useMutation(
    updateProductDisplayPreferenceMutation
  );

  const visibilityType: VisibilityOption[] = useMemo(
    () => [
      { name: t('product.visibility.visibility-type.forced'), value: 1 },
      { name: t('product.visibility.visibility-type.by-time'), value: 3 },
      { name: t('product.visibility.visibility-type.forbidden'), value: 2 },
    ],
    [t]
  );

  useEffect(() => {
    if (visibility) {
      const selectedVisibilityOption = visibilityType.find(
        (option) => option.value === visibility.displayPreference
      );
      if (selectedVisibilityOption) {
        setSelectedOption(selectedVisibilityOption);
      }
    }
  }, [visibility, visibilityType]);

  const [selectedOption, setSelectedOption] = useState<VisibilityOption | null>(null);
  const [openSlideOver, setOpenSlideOver] = useState<boolean>(false);

  const isOptionActive = (option: VisibilityOption) => option.value === selectedOption?.value;

  const handleOptionChange = (option: VisibilityOption) => {
    setUpdateError(null);
    if (option.value === 2) {
      setOpenSlideOver(true);
    } else {
      updateProductDisplayPreference({
        variables: {
          id: params.productId,
          iso: visibility?.iso,
          displayPreference: option.value,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: visibilityInformationQuery,
            variables: {
              id: params.productId,
            },
          },
        ],
        onCompleted: () => {
          setNotification({
            type: 'success',
            title: t('product.visibility.notifications.title'),
            message: t('product.visibility.notifications.message'),
          });
        },
        onError: (e) => {
          setUpdateError(e);
        },
      });
    }
  };

  return (
    <div>
      <AddCommentSlideOver
        open={openSlideOver}
        currentCountryName={currentCountryName}
        currentCountryIso={visibility?.iso}
        setOpen={setOpenSlideOver}
      />
      {loading ? (
        <div className="flex space-x-4">
          <div className="skeleton h-10 w-32" />
          <div className="skeleton h-10 w-32" />
          <div className="skeleton h-10 w-32" />
        </div>
      ) : (
        <>
          {mutationLoading ? (
            <div className="flex space-x-4">
              <div className="skeleton h-10 cursor-pointer flex-nowrap items-center justify-center rounded-md border p-2 font-semibold focus:outline-none sm:flex-1" />
              <div className="skeleton h-10 cursor-pointer flex-nowrap items-center justify-center rounded-md border p-2 font-semibold focus:outline-none sm:flex-1" />
              <div className="skeleton h-10 cursor-pointer flex-nowrap items-center justify-center rounded-md border p-2 font-semibold focus:outline-none sm:flex-1" />
            </div>
          ) : (
            <RadioGroup value={selectedOption} onChange={handleOptionChange} className="mt-2">
              <div className="flex max-w-lg space-x-4">
                {visibilityType.map((option) => (
                  <RadioGroup.Option
                    key={option.name}
                    value={option}
                    className={({ active }) =>
                      classNames(
                        active || isOptionActive(option) ? 'border-2 border-primary-500' : '',
                        'text-dark',
                        'flex cursor-pointer flex-nowrap items-center justify-center rounded-md border p-2 font-semibold focus:outline-none sm:flex-1'
                      )
                    }>
                    <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
                  </RadioGroup.Option>
                ))}
              </div>
            </RadioGroup>
          )}
        </>
      )}
    </div>
  );
};

export default VisibilityRadioButton;
