import React, { ChangeEvent, ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, Controller, useController } from 'react-hook-form';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import { ApolloError } from '@apollo/client/errors';
import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import ReactSelect, {
  GroupBase,
  OptionsOrGroups,
  PropsValue,
  SingleValueProps,
  components,
} from 'react-select';
import useApolloErrorMessage from 'hooks/useApolloErrorMessage';
import { OptionProps } from 'types/Global';

type SelectControlProps = {
  control: Control;
  name: string;
  options: OptionsOrGroups<OptionProps, GroupBase<OptionProps>>;
  label?: string;
  placeholder?: string;
  loadingOptions?: boolean;
  apolloError?: ApolloError;
  isInactive?: boolean;
  inactiveMessage?: string;
  isClearable?: boolean;
  isRequired?: boolean;
  addHTML?: ((option: PropsValue<unknown>) => JSX.Element) | false;
  SingleValue?:
    | React.ComponentType<SingleValueProps<OptionProps, false, GroupBase<OptionProps>>>
    | undefined;
} & ComponentPropsWithoutRef<'select'>;

export const SelectControl = ({
  control,
  name,
  options,
  label,
  placeholder,
  loadingOptions,
  apolloError,
  isInactive,
  inactiveMessage,
  isClearable,
  addHTML = false,
  isRequired = false,
  SingleValue = (e) => <components.SingleValue {...e}>{e.data.label}</components.SingleValue>,
  ...props
}: SelectControlProps) => {
  const { t } = useTranslation();
  const { field, formState } = useController({ name, control });
  const { onChange, value } = field;
  const errorMessage = useApolloErrorMessage({ apolloError });
  const getInactiveMessage = () => {
    return inactiveMessage ? inactiveMessage : t('global.inactive-option');
  };
  const getPlaceholder = () => {
    return placeholder ? placeholder : t('global.form.select');
  };

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e);
    props.onChange?.(e);
  };

  return (
    <div>
      <label className="truncate text-sm font-medium text-dark">
        {label}
        {isRequired && <span className="ml-1 text-red-600">*</span>}
      </label>
      <div className="relative">
        <Controller
          control={control}
          name={name}
          render={({ field }) => (
            <ReactSelect
              {...field}
              className={`mt-2 text-sm`}
              styles={
                formState.errors[name] || isInactive || apolloError ? errorCustomStyle : customStyle
              }
              value={value}
              onChange={onChangeSelect}
              options={options}
              placeholder={loadingOptions ? t('global.form.loading') : getPlaceholder()}
              formatOptionLabel={(option, { context }) =>
                context === 'menu' && addHTML ? addHTML(option) : option.label
              }
              noOptionsMessage={() => t('global.form.no-option')}
              isClearable={isClearable}
              isDisabled={formState.isLoading || loadingOptions}
              isLoading={loadingOptions}
              theme={theme}
              components={{ SingleValue }}
            />
          )}
        />
        {formState?.errors[name] && (
          <div className="pointer-events-none absolute inset-y-0 right-12 top-0 flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
        {(isInactive || apolloError) && (
          <div className="pointer-events-none absolute right-12 top-2 flex items-center">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          </div>
        )}
        {(isInactive || apolloError) && (
          <p className="mt-2 text-sm text-red-600">
            {apolloError ? errorMessage : getInactiveMessage()}
          </p>
        )}
      </div>
      {formState?.errors[name] && (
        <p className="mt-2 text-sm text-red-600">{formState?.errors[name]?.message as string}</p>
      )}
    </div>
  );
};
