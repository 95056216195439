import { gql } from 'graphql.macro';

export const allImagesQuery = gql`
  query allImages($id: ID!) {
    product(id: $id) {
      miscellaneousImages {
        id
        clientUrl
        position
        is_miscellaneous
      }
      ambianceImages {
        id
        clientUrl
        position
        is_ambiance
      }
      dimensionsImage {
        id
        clientUrl
        position
        is_dimensions
      }
      energyClassImage {
        id
        clientUrl
        position
        is_energy_class
      }
    }
  }
`;
