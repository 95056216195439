import { gql } from 'graphql.macro';

export const switchForceOnMarketplacesMutation = gql`
  mutation SwitchForceOnMarketplaces($id: ID!, $force_on_marketplaces: Boolean!, $tag_id: Int!) {
    switchForceOnMarketplaces(
      id: $id
      force_on_marketplaces: $force_on_marketplaces
      tag_id: $tag_id
    ) {
      id
      force_on_marketplaces
    }
  }
`;
