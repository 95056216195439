import { gql } from 'graphql.macro';

export const newsQuery = gql`
  query {
    News {
      id
      previewImage
      currentTranslation {
        title
      }
      currentLanguageOffer {
        price
      }
    }
  }
`;
