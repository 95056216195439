import { gql } from 'graphql.macro';

export const deleteProductNoticeMutation = gql`
  mutation DeleteProductNotice($id: ID!) {
    deleteProductNotice(id: $id) {
      id
      name
    }
  }
`;
