import { gql } from 'graphql.macro';

export const supplierProductionSitesQuery = gql`
  query SupplierProductionSites($id: ID!) {
    supplier(id: $id) {
      id
      productionSites {
        id
        name
        street
        complementary_address
        city
        zipcode
        country {
          id
          iso
          name
        }
      }
    }
  }
`;
