import React, { Fragment, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { supplierHeaderQuery } from 'graphql/Queries/Supplier/supplierHeader';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ApolloErrorAlert, ErrorAlert } from 'components/Common/Alerts';
import { useSupplierContextType } from 'components/Contexts/SupplierContext';
import InitialAvatar from 'components/Common/InitialAvatar';
import Badge from 'components/Common/Badge';
import { DocumentTextIcon, EllipsisVerticalIcon } from '@heroicons/react/20/solid';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import EditName from './EditName';
import { Supplier } from 'types/Supplier';
import DeactivateModal from './DeactivateModal';
import { editSupplierActivationMutation } from 'graphql/Mutations/Supplier/Header/editActivation';
import useNotification from 'hooks/useNotification';

const Index = () => {
  const { setNotification } = useNotification();
  const params = useParams();
  const { t } = useTranslation();
  const { supplierExists, setSupplierExists } = useSupplierContextType();

  const [openEdit, setOpenEdit] = useState(false);
  const [openDeactivateModal, setOpenDeactivateModal] = useState(false);

  const { loading, error, data } = useQuery(supplierHeaderQuery, {
    variables: { id: params.supplierId },
    onCompleted: (data) => {
      if (!data?.supplier) {
        setSupplierExists(false);
      } else {
        setSupplierExists(true);
      }
    },
    onError: () => {
      setSupplierExists(false);
    },
  });

  const [activateSupplier, { loading: loadingActivation, error: errorActivation }] = useMutation(
    editSupplierActivationMutation
  );

  const supplier: Supplier | undefined = data?.supplier;

  const switchActivation = () => {
    if (supplier?.active) {
      setOpenDeactivateModal(true);
    } else {
      activateSupplier({
        variables: {
          id: params.supplierId,
          active: true,
        },
        awaitRefetchQueries: true,
        refetchQueries: [
          {
            query: supplierHeaderQuery,
            variables: {
              id: params.supplierId,
            },
          },
        ],
        onCompleted: () => {
          setNotification({
            title: t('global.success'),
            message: t('supplier.header.activated'),
            type: 'success',
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onError: () => {},
      });
    }
  };

  return (
    <>
      <DeactivateModal
        open={openDeactivateModal}
        setOpen={setOpenDeactivateModal}
        name={supplier?.name}
      />
      <EditName open={openEdit} setOpen={setOpenEdit} name={supplier?.name} />
      <div className="cursor-default bg-gray-50 p-6">
        <div className="flex items-center">
          <div className="flex w-full flex-col items-center gap-3 lg:flex-row">
            {!supplierExists && (
              <div className="w-full">
                <ErrorAlert>{t('supplier.index.error')}</ErrorAlert>
              </div>
            )}
            {supplierExists && error && (
              <div className="flex-1">
                <ApolloErrorAlert error={error} />
              </div>
            )}
            {supplierExists && errorActivation && (
              <div className="flex-1">
                <ApolloErrorAlert error={errorActivation} />
              </div>
            )}
            {(loading || loadingActivation) && (
              <>
                <div className="skeleton h-24 w-24 rounded-full" />
                <div className="space-y-3">
                  <div className="skeleton h-8 w-96" />
                  <div className="skeleton h-6 w-60" />
                </div>
              </>
            )}
            {!loading && !loadingActivation && supplier && (
              <>
                <div className="flex-shrink-0">
                  {supplier.name && <InitialAvatar name={supplier.name} />}
                </div>
                <div className="w-96 flex-1 space-y-3">
                  <div className="flex space-x-3">
                    <h1 className="truncate text-xl font-bold text-dark">
                      {supplier.name ? supplier.name : t('global.no-title')}
                    </h1>
                    <div className="flex items-center">
                      <Badge type="info">{`#${supplier.id}`}</Badge>
                    </div>
                  </div>
                  <div className="flex h-full items-start space-x-3">
                    {supplier?.active ? (
                      <Badge type="success" withDote>
                        {t('global.active')}
                      </Badge>
                    ) : (
                      <Badge type="secondary" withDote>
                        {t('global.inactive')}
                      </Badge>
                    )}
                    <Tippy
                      content={
                        <span className="text-sm  font-medium text-white">
                          {t('global.created-by-at', {
                            user: supplier.creator
                              ? `${supplier.creator.firstname} ${supplier.creator.lastname}`
                              : t('global.unknown'),
                            date: supplier.created_at
                              ? moment(supplier.created_at).format('DD/MM/YYYY')
                              : t('global.unknown-date'),
                          })}{' '}
                          {supplier.created_at &&
                            `${t('global.at')} ${moment(supplier.created_at).format('HH:mm')}`}
                        </span>
                      }>
                      <div className="flex items-center truncate text-sm font-medium text-secondary md:mr-6">
                        <DocumentTextIcon className="-ml-1 mr-1 w-6 flex-shrink-0" />
                        {t('global.created-by-at', {
                          user: supplier.creator
                            ? `${supplier.creator.firstname} ${supplier.creator.lastname}`
                            : t('global.unknown'),
                          date: supplier.created_at
                            ? moment(supplier.created_at).format('DD/MM/YYYY')
                            : t('global.unknown-date'),
                        })}
                      </div>
                    </Tippy>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="flex items-center rounded-full text-secondary hover:text-dark focus:outline-none">
                        <EllipsisVerticalIcon className="w-6" aria-hidden="true" />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95">
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={clsx(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block w-full px-4 py-2 text-left text-sm'
                                )}
                                onClick={() => setOpenEdit(true)}>
                                {t('supplier.header.edit-name')}
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                type="button"
                                className={clsx(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'block w-full px-4 py-2 text-left text-sm'
                                )}
                                onClick={() => switchActivation()}>
                                {supplier?.active
                                  ? t('global.actions.deactivate')
                                  : t('global.actions.activate')}
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
