import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { FieldValues, useForm } from 'react-hook-form';
import { PhotoIcon } from '@heroicons/react/24/outline';
import { useMutation } from '@apollo/client';
import { updateProductCombinationQuantityMutation } from 'graphql/Mutations/Product/ProductCombination/updateProductCombinationQuantity';
import { InputTextControl } from 'components/Common/InputTextControl';
import { ID } from 'types/Global';
import { yupResolver } from '@hookform/resolvers/yup';
import SlideOver from 'components/Common/SlideOver';
import useCheckUrlImage from 'components/Products/Hooks/useCheckUrlImage';
import useNotification from 'hooks/useNotification';
import * as yup from 'yup';

type Props = {
  constituentProductId: ID;
  combinationId?: ID;
  open: boolean;
  img?: string;
  title: string;
  quantity?: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const EditConstituent = ({
  setOpen,
  open,
  img,
  title,
  combinationId,
  quantity,
  constituentProductId,
}: Props) => {
  const { setNotification, setError } = useNotification();
  const isValidImage = useCheckUrlImage(img);
  const { t } = useTranslation();

  const [updateQuantity, { loading, error }] = useMutation(
    updateProductCombinationQuantityMutation
  );

  const schema = yup.object().shape({
    quantity: yup
      .number()
      .typeError(t('form.number.integer'))
      .required(t('product.ecotax.form.catergory-required')),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<FieldValues>({ resolver: yupResolver<FieldValues>(schema) });

  useEffect(() => {
    reset({
      quantity,
    });
  }, [reset, quantity]);

  const close = () => {
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    updateQuantity({
      variables: {
        id: combinationId,
        child_product_qty: parseInt(data.quantity),
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.product-combination.notif.edit-success'),
          type: 'success',
        });
        reset({ quantity: data.quantity });
        close();
      },
      onError: (error) => {
        setError(error);
        reset();
      },
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.product-combination.edit-title')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={close}
      loading={loading}
      isDirty={isDirty}
      error={error}>
      <div className="text-sm">
        <div className="text-dark">
          <Trans i18nKey="product.product-combination.edit-description">
            <span className="font-semibold" />
          </Trans>
        </div>
        <div className="my-10 flex items-center justify-start ">
          {isValidImage ? (
            <img src={img} className="mr-4 h-10 object-cover" />
          ) : (
            <PhotoIcon className="mr-4 h-10 w-10 text-secondary" />
          )}
          <span className="w-full text-dark">
            {title} - {constituentProductId}
          </span>
        </div>
        <InputTextControl
          control={control}
          name="quantity"
          label={t('product.product-combination.quantity')}
        />
      </div>
    </SlideOver>
  );
};
export default EditConstituent;
