import React from 'react';

const Skeleton = () => {
  return (
    <>
      {[...Array(3)].map((x, i) => (
        <tr key={i} className="border-b">
          <td className="px-3 py-3.5 2xl:table-cell">
            <div className="skeleton h-6 w-52"></div>
          </td>
          <td className="hidden w-28 px-3 py-3.5 text-center text-sm font-semibold text-secondary lg:table-cell">
            <div className="flex justify-center">
              <div className="skeleton h-6 w-10"></div>
            </div>
          </td>
          <td className="hidden w-32 p-3 2xl:table-cell">
            <div className="skeleton h-6 w-24"></div>
          </td>
          <td className="hidden w-32 p-3 2xl:table-cell">
            <div className="skeleton h-6 w-28"></div>
          </td>
          <td className="hidden w-52 p-3 lg:table-cell">
            <div className="skeleton h-6"></div>
          </td>
          <td className="w-32 lg:table-cell">
            <div className="flex justify-end space-x-4">
              <div className="skeleton h-6  w-10"></div>
              <div className="skeleton h-6  w-10"></div>
              <div className="skeleton h-6  w-10"></div>
            </div>
          </td>
        </tr>
      ))}
    </>
  );
};

export default Skeleton;
