import React, { SetStateAction, useEffect, useState } from 'react';
import { OptionProps } from 'react-select';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { allVariantsQuery } from 'graphql/Queries/Product/Packaging/allVariants';
import { importPackagingInformationsMutation } from 'graphql/Mutations/Product/Packaging/importPackagingInformations';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import { ImportPackagingQuery } from 'graphql/Queries/Product/Packaging/importPackaging';
import { ExportPackagingQuery } from 'graphql/Queries/Product/Packaging/exportPackaging';
import { productTagsQuery } from 'graphql/Queries/Product/Packaging/productTags';
import { ID } from 'types/Global';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';
import Select from 'components/Common/Select';
import Checkbox from 'components/Common/Checkbox';
import { Product } from 'types/Product';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<SetStateAction<boolean>>;
  setHasVariant: React.Dispatch<SetStateAction<boolean>>;
};

type ImportElement = {
  [key: string]: boolean;
};

type VariantOption = {
  id: ID;
  label: string;
  value: string;
  hasPackagingElements: ImportElement;
} & OptionProps;

const ImportSlide = ({ open, setOpen, setHasVariant }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [importElements, setImportElements] = useState<ImportElement | null>(null);
  const [selectedVariant, setSelectedVariant] = useState<VariantOption | null>(null);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  const { data, error, loading } = useQuery(allVariantsQuery, {
    variables: { id: params.productId },
    fetchPolicy: 'network-only',
  });

  const [importPackagingInformations, { loading: mutationLoading, error: mutationError }] =
    useMutation(importPackagingInformationsMutation);

  useEffect(() => {
    if (data?.product?.allVariants.length > 0) {
      setHasVariant(true);
    }
  }, [setHasVariant, data]);

  useEffect(() => {
    const checkIfAnyCheckboxIsChecked = () => {
      return !!importElements && Object.values(importElements).some((value) => value);
    };
    setIsDirty(checkIfAnyCheckboxIsChecked());
  }, [importElements]);

  const { handleSubmit } = useForm();

  const close = () => {
    setSelectedVariant(null);
    setOpen(false);
  };

  useEffect(() => {
    if (!selectedVariant) return;
    setImportElements(selectedVariant.hasPackagingElements);
  }, [selectedVariant, setImportElements]);

  useEffect(() => {
    if (!selectedVariant) return;

    const newImportElements: Record<string, boolean> = {};
    Object.keys(selectedVariant.hasPackagingElements).forEach((key) => {
      const isDisabled = selectedVariant.hasPackagingElements[key] === false;
      newImportElements[key] = !isDisabled;
    });

    setImportElements(newImportElements);
  }, [selectedVariant]);

  const options = data?.product?.allVariants.map((variant: Product) => {
    return {
      value: variant.id,
      label: variant?.currentTranslation?.internal_title,
      hasPackagingElements: variant.hasPackagingElements,
    };
  });

  const onChangeCheckbox = (value: boolean, key: string) => {
    setImportElements((prevElements: ImportElement | null) => {
      return {
        ...prevElements,
        [key]: value,
      };
    });
  };

  const onSubmit = handleSubmit(() => {
    if (!importElements) return;

    const filteredImportElements: { [key: string]: boolean } = {};
    Object.keys(importElements).forEach((key) => {
      if (isNaN(Number(key))) {
        filteredImportElements[key] = importElements[key];
      }
    });

    importPackagingInformations({
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
        {
          query: ImportPackagingQuery,
          variables: { id: params.productId },
        },
        {
          query: ExportPackagingQuery,
          variables: { id: params.productId },
        },
        {
          query: productTagsQuery,
          variables: { id: params.productId },
        },
      ],
      variables: {
        id: params.productId,
        from_product_id: selectedVariant?.value,
        packaging_import_element: filteredImportElements,
      },
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.import.success'),
          type: 'success',
        });
        close();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.editorial.import.title')}
      buttonText={t('global.actions.import')}
      onCancel={close}
      onSubmit={onSubmit}
      isDirty={isDirty}
      loading={mutationLoading}
      error={mutationError}>
      <div className="mt-6">
        <Select
          label={t('product.editorial.import.label')}
          options={options}
          loadingOptions={loading}
          apolloError={error}
          isClearable
          value={selectedVariant}
          onChange={(e) => {
            setSelectedVariant(e as VariantOption);
          }}
        />
        <div>
          {selectedVariant && (
            <>
              <div className="mb-6 mt-8 w-full border-t border-gray-300" />
              <div className="mb-6 font-semibold text-dark">{t('global.informations-choice')}</div>
            </>
          )}
          {selectedVariant && (
            <div className="mt-6 space-y-2">
              {importElements &&
                Object.entries(importElements).map(([key], index) => {
                  const variantData = data?.product?.allVariants.find(
                    (variant: VariantOption) => variant.id === selectedVariant.value
                  );
                  const isDisabled = variantData?.hasPackagingElements[key] === false;

                  return (
                    <Checkbox
                      key={index}
                      label={t(`product.packaging.import.checkbox.${key}`)}
                      checked={importElements[key]}
                      disabled={isDisabled}
                      onChange={(e) => onChangeCheckbox(e.target.checked, key)}
                    />
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </SlideOver>
  );
};
export default ImportSlide;
