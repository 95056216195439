import { gql } from 'graphql.macro';

export const supplierCoordinatesQuery = gql`
  query SupplierCoordinates($id: ID!) {
    supplier(id: $id) {
      id
      billing_name
      street
      complementary_address
      city
      zipcode
      vat_number
      country {
        id
        name
      }
    }
  }
`;
