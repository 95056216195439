import React, { createContext, SetStateAction, useState } from 'react';
import { UseAuthToken } from './AuthToken';
import AppRoutes from './AppRoutes';
import Echo from 'laravel-echo';
import './assets/tailwind.css';
import { useLocation } from 'react-router-dom';
import { fr } from 'date-fns/locale/fr';
import { registerLocale, setDefaultLocale } from 'react-datepicker';

interface UserContextProps {
  user: string | null;
  setUser: React.Dispatch<SetStateAction<string | null>>;
}

export const UserContext = createContext<UserContextProps>({
  user: null,
  setUser: () => {
    throw new Error();
  },
});

declare global {
  interface Window {
    Pusher: NodeRequire;
    Echo: Echo;
  }
}

export default function App() {
  registerLocale('fr', fr);
  setDefaultLocale('fr');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [authToken, setToken, removeAuthToken, isLogged] = UseAuthToken();
  const [user, setUser] = useState(localStorage.getItem('user'));

  const routing = AppRoutes();

  if (isLogged()) {
    window.Pusher = require('pusher-js');
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_PUSHER_APP_KEY,
      cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
      encrypted: true,
      authEndpoint: `${process.env.REACT_APP_API_URL}/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: authToken ? `Bearer ${authToken}` : null,
        },
      },
    });
  }

  const location = useLocation();
  if (location.pathname && location.pathname !== '/login') {
    localStorage.setItem('previousUrl', location.pathname);
  }

  return <UserContext.Provider value={{ user, setUser }}>{routing}</UserContext.Provider>;
}
