import { gql } from 'graphql.macro';

export const updatePrincipalContactMutation = gql`
  mutation updatePrincipalContact($supplier_id: ID!, $new_principal_contact: ID!) {
    updatePrincipalContact(
      supplier_id: $supplier_id
      new_principal_contact: $new_principal_contact
    ) {
      id
    }
  }
`;
