import { gql } from 'graphql.macro';

export const productQuery = gql`
  query product($id: ID!) {
    product(id: $id) {
      id
      supplier {
        id
        productionSites {
          id
          country {
            id
            name
            iso
          }
        }
      }
      primaryImage {
        clientUrl
      }
      branding {
        id
        label
        logoFullPath
      }
      madeIn {
        id
        label
        logoFullPath
      }
    }
  }
`;
