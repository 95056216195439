import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { Product } from 'types/Product';
import { getCurrencySymbol } from 'global';
import { InformationAlert } from 'components/Common/Alerts';
import Card from 'components/Common/Card';

type Props = {
  loading: boolean;
  product?: Product;
};

const Supplying = ({ loading, product }: Props) => {
  const { t } = useTranslation();

  return (
    <Card title={t('product.preview.supplying.title')}>
      {!loading && !product?.currentSupplying ? (
        <InformationAlert>{t('product.preview.supplying.no-supplier')}</InformationAlert>
      ) : (
        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-6 text-dark">
            <div>
              {loading ? (
                <>
                  <div className="skeleton h-8 w-44" />
                  <div className="skeleton mt-2 h-5 w-40" />
                </>
              ) : (
                <>
                  <p className="flex items-center gap-3 text-2xl font-medium text-primary-500">
                    <span className="truncate">{product?.currentSupplying?.supplier.name}</span>
                    <Link to={`/suppliers/${product?.currentSupplying?.supplier.id}`}>
                      <ArrowTopRightOnSquareIcon className="w-6 cursor-pointer text-secondary hover:text-dark" />
                    </Link>
                  </p>
                  <p className="text-sm">
                    <span className="font-medium">{t('product.preview.supplying.ref')}</span>
                    {' : '}
                    <span className="font-semibold">
                      {product?.currentSupplying?.supplier_product_id}
                    </span>
                  </p>
                </>
              )}
            </div>
            <div>
              {loading ? (
                <>
                  <div className="skeleton h-5 w-40" />
                  <div className="skeleton mt-2 h-5 w-40" />
                </>
              ) : (
                <>
                  <p className="text-sm">
                    <span className="font-medium">{t('product.preview.supplying.moq')}</span>
                    {' : '}
                    <span className="font-semibold text-primary-500">
                      {product?.minimum_order_quantity ?? '-'}
                    </span>
                  </p>
                  <p className="text-sm">
                    <span className="font-medium">{t('product.preview.supplying.moq-model')}</span>
                    {' : '}
                    <span className="font-semibold text-primary-500">
                      {product?.model?.minimum_order_quantity ?? '-'}
                    </span>
                  </p>
                </>
              )}
            </div>
          </div>
          <div className="flex justify-end">
            <div className="flex flex-col items-end">
              {loading ? (
                <>
                  <div className="skeleton h-5 w-40" />
                  <div className="skeleton mt-2 h-8 w-44" />
                </>
              ) : (
                <>
                  <p className="text-sm">
                    <span className="font-medium">{t('product.preview.supplying.price')}</span>
                    {' : '}
                  </p>
                  <p className="text-2xl font-medium text-secondary">
                    {product?.currentSupplying?.price && product?.currentSupplying?.currency
                      ? `${product.currentSupplying.price} ${getCurrencySymbol(
                          product.currentSupplying.currency.code
                        )}`
                      : '-'}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </Card>
  );
};
export default Supplying;
