import React, { ChangeEvent, ComponentPropsWithoutRef, ReactElement } from 'react';
import Creatable, { CreatableProps } from 'react-select/creatable';
import { Control, Controller, useController } from 'react-hook-form';
import { customStyle, errorCustomStyle, theme } from 'components/Style/customStyle';
import {
  ComponentProps,
  UseAsyncPaginateParams,
  withAsyncPaginate,
} from 'react-select-async-paginate';
import { useLazyQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { productCollectionQuery } from 'graphql/Queries/Product/SupplierAndTaxonomy/productCollection';
import { ID } from 'types/Global';
import { GroupBase } from 'react-select';

type Props = {
  control: Control;
  loading: boolean;
  updateDisplayProduct: () => void;
  isInactive: boolean;
} & ComponentPropsWithoutRef<'select'>;

const limit = 30;

type Additional =
  | {
      page: number;
    }
  | undefined;

type AsyncPaginateCreatableProps<
  OptionProps,
  Group extends GroupBase<OptionProps>,
  Additional,
  IsMulti extends boolean,
> = CreatableProps<OptionProps, IsMulti, Group> &
  UseAsyncPaginateParams<OptionProps, Group, Additional> &
  ComponentProps<OptionProps, Group, IsMulti>;

type AsyncPaginateCreatableType = <
  OptionProps,
  Group extends GroupBase<OptionProps>,
  Additional,
  IsMulti extends boolean = false,
>(
  props: AsyncPaginateCreatableProps<OptionProps, Group, Additional, IsMulti>
) => ReactElement;

const CreatableAsyncPaginate = withAsyncPaginate(Creatable) as AsyncPaginateCreatableType;

const CollectionSelectCreatable = ({ control, loading, updateDisplayProduct, ...props }: Props) => {
  const { t } = useTranslation();
  const { field } = useController({ name: 'collection', control });
  const { onChange, value } = field;

  const [collections, { loading: loadingQuery, error }] = useLazyQuery(productCollectionQuery);

  const searchCollections = (inputValue: string, page: number) => {
    return new Promise<{
      collection: { id: ID; label: string; active: boolean }[];
      currentPage: number;
      lastPage: number;
    }>((resolve) => {
      collections({
        fetchPolicy: 'network-only',
        variables: { keyword: inputValue, page, limit },
        onCompleted: (data) => {
          resolve(data.productCollectionByKeyword);
        },
      });
    });
  };

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e);
    props.onChange?.(e);
    getStyles(true);
    props.isInactive = false;
    updateDisplayProduct();
  };

  const getStyles = (clear = false) => {
    if (clear || !(error || props.isInactive)) {
      return customStyle;
    }
    return errorCustomStyle;
  };

  const loadPageOptions = async (search: string, _: unknown, additional: Additional) => {
    if (error || !additional) {
      return {
        options: [],
        hasMore: false,
      };
    }

    const page = additional.page;
    const collectionData = await searchCollections(search, page);
    const supplierColors = collectionData.collection.map((collection) => {
      return {
        label: collection.label ? collection.label : '-',
        value: collection.id,
        isDisabled: !collection.active,
      };
    });

    return {
      options: supplierColors,
      hasMore: collectionData.currentPage !== collectionData.lastPage,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <>
      <label className="truncate text-sm font-medium text-dark">
        {t('product.model-style.collection')}
      </label>
      <div className="relative">
        <Controller
          name={'collection'}
          control={control}
          render={() => (
            <CreatableAsyncPaginate
              styles={getStyles()}
              theme={theme}
              loadOptions={loadPageOptions}
              placeholder={loading ? t('global.form.loading') : t('global.form.select')}
              noOptionsMessage={() => t('global.form.no-option')}
              loadingMessage={() => t('global.form.loading')}
              formatCreateLabel={(inputText: string) => {
                return `${t('global.actions.create')} : "${inputText}"`;
              }}
              additional={{
                page: 1,
              }}
              value={value}
              onChange={onChangeSelect}
              isLoading={loading || loadingQuery}
              isDisabled={loading}
              menuPlacement="auto"
            />
          )}
        />
        <div className="pointer-events-none absolute inset-y-0 right-[36px] flex items-center pr-3">
          {(error || props.isInactive) && (
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" />
          )}
        </div>
      </div>
      {props.isInactive && (
        <p className="mt-2 text-sm text-red-600">
          {t('product.taxonomy.form.collection-inactive')}
        </p>
      )}
      {error && error.graphQLErrors[0]?.extensions?.category === 'custom' ? (
        <p className="mt-2 text-sm text-red-600">{error.graphQLErrors[0].message}</p>
      ) : (
        error && <p className="mt-2 text-sm text-red-600">{t('global.error-msg')}</p>
      )}
    </>
  );
};

export default CollectionSelectCreatable;
