import React from 'react';
import { Disclosure } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import MultiSelect from './FormUtils/MultiSelect';
import FieldLegend from './FormUtils/FieldLegend';
import { Control } from 'react-hook-form';

type Props = {
  control: Control;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  typeFilterBucket: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  universeFilterBucket: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categoryFilterBucket: any;
};

const TaxonomyFilter = ({
  control,
  typeFilterBucket,
  universeFilterBucket,
  categoryFilterBucket,
}: Props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectCategories = categoryFilterBucket.map((category: any, index: number) => {
    return {
      key: index,
      value: category.key,
      label: category.category.hits.hits[0]._source.category.label,
      minilabel: category.category.hits.hits[0]._source.category.label,
    };
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectUniverses = universeFilterBucket.map((universe: any, index: number) => {
    return {
      key: index,
      value: universe.key,
      label: universe.universe.hits.hits[0]._source.universe.label,
      minilabel: universe.universe.hits.hits[0]._source.universe.label,
    };
  });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const selectTypes = typeFilterBucket.map((type: any, index: number) => {
    return {
      key: index,
      value: type.key,
      label: type.type.hits.hits[0]._source.type.label,
      minilabel: type.type.hits.hits[0]._source.type.label,
    };
  });
  return (
    <div className="border-b-2 border-solid border-gray-200 py-2">
      <Disclosure as="div">
        {({ open }) => (
          <fieldset>
            <div>
              <FieldLegend open={open}>{t('products.filters.taxonomy.filter-label')}</FieldLegend>
            </div>
            <Disclosure.Panel>
              {selectUniverses.length != 0 && (
                <>
                  <div className="mb-1 mt-3 pl-1">
                    {t('products.filters.taxonomy.filter-universe')}
                  </div>
                  <MultiSelect name="universe" control={control} options={selectUniverses} />
                </>
              )}
              {selectCategories.length != 0 && (
                <>
                  <div className="mb-1 mt-3 pl-1">
                    {t('products.filters.taxonomy.filter-category')}
                  </div>
                  <MultiSelect name="category" control={control} options={selectCategories} />
                </>
              )}
              {selectTypes.length != 0 && (
                <>
                  <div className="mb-1 mt-3 pl-1">{t('products.filters.taxonomy.filter-type')}</div>
                  <MultiSelect name="type" control={control} options={selectTypes} />
                </>
              )}
            </Disclosure.Panel>
          </fieldset>
        )}
      </Disclosure>
    </div>
  );
};

export default TaxonomyFilter;
