import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ApolloError, useQuery } from '@apollo/client';
import AsyncCreatablePaginate from './FormTools/AsyncCreatablePaginate';
import { allMaterialsQuery } from 'graphql/Queries/Product/MaterialsAndColors/allMaterials';
import { allColorsQuery } from 'graphql/Queries/Product/MaterialsAndColors/allColors';
import { DisplayProducts, DisplayProductsProps, FormatedValues } from './Index';
import { ProductColor, ProductMaterial } from 'types/Product';
import { SelectControl } from 'components/Common/SelectControl';
import { ApolloErrorAlert } from 'components/Common/Alerts';
import { Button } from 'components/Common/Button';
import { GroupBase, SingleValueProps, components } from 'react-select';
import { OptionProps } from 'types/Global';

type ColorOption = {
  value: string;
  label: string;
  isDisabled: boolean;
  hex_code: string;
};

type ColorDisplayProps = {
  hexCode: string;
  label: string;
};

type Props = {
  loading: boolean;
  mutationError?: ApolloError;
  defaultValues: FormatedValues;
  updateProduct: (data: FormatedValues) => void;
  mutationLoading: boolean;
  setDisplayProducts: React.Dispatch<React.SetStateAction<DisplayProductsProps>>;
};

const MaterialAndColorEdit = ({
  loading,
  mutationError,
  defaultValues,
  updateProduct,
  mutationLoading,
  setDisplayProducts,
}: Props) => {
  const [allMaterials, setAllMaterials] = useState([]);
  const [allColors, setAllColors] = useState<ColorOption[]>([]);
  const { t } = useTranslation();

  const { error: materialsError, loading: materialsLoading } = useQuery(allMaterialsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAllMaterials(
        data.materials?.map((material: ProductMaterial) => {
          return {
            value: material.id,
            label: material.label,
            isDisabled: !material.is_active,
          };
        })
      );
    },
  });

  const { error: colorsError, loading: colorsLoading } = useQuery(allColorsQuery, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      setAllColors(
        data.colors?.map((color: ProductColor) => {
          return {
            value: color.id,
            label: color.label,
            isDisabled: !color.is_active,
            hex_code: color.hex_code,
          };
        })
      );
    },
  });

  const {
    formState: { isDirty },
    reset,
    getValues,
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = handleSubmit((values) => {
    updateProduct(values as FormatedValues);
  });

  useEffect(() => {
    reset(defaultValues);
  }, [reset, defaultValues]);

  const updateDisplayProductOnMainMaterial = () => {
    setDisplayProducts({
      id: getValues('material1').value,
      type: DisplayProducts.mainMaterial,
      elementLabel: getValues('material1').label,
    });
  };
  const updateDisplayProductOnSecondaryMaterial = () => {
    setDisplayProducts({
      id: getValues('material2').value,
      type: DisplayProducts.secondaryMaterial,
      elementLabel: getValues('material2').label,
    });
  };
  const updateDisplayProductOnColor = () => {
    setDisplayProducts({
      id: getValues('color').value,
      type: DisplayProducts.color,
      elementLabel: getValues('color').label,
    });
  };
  const updateDisplayProductOnSupplierColor = () => {
    setDisplayProducts({
      id: getValues('supplier_color').value,
      type: DisplayProducts.supplierColor,
      elementLabel: getValues('supplier_color').label,
    });
  };

  const ColorDisplay = ({ hexCode, label }: ColorDisplayProps) => {
    return (
      <div className="flex items-center">
        <div className="h-5 w-5 rounded-full" style={{ backgroundColor: `#${hexCode}` }} />
        <span className="ml-2 whitespace-nowrap">{label}</span>
      </div>
    );
  };

  const SingleValue: React.ComponentType<
    SingleValueProps<OptionProps, false, GroupBase<OptionProps>>
  > = (e) => {
    const data = e.data as ColorOption;
    return (
      <components.SingleValue {...e}>
        <ColorDisplay hexCode={data.hex_code} label={data.label} />
      </components.SingleValue>
    );
  };

  return (
    <form className="flex h-full flex-col justify-between" onSubmit={onSubmit}>
      <div className="space-y-6">
        <div>
          <AsyncCreatablePaginate
            control={control}
            name="supplier_color"
            loading={loading}
            onChange={updateDisplayProductOnSupplierColor}
          />
          {getValues('supplier_color') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnSupplierColor}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
        <div>
          <SelectControl
            control={control}
            name={'material1'}
            label={t('product.materials-colors.main-material')}
            options={allMaterials}
            loadingOptions={materialsLoading || loading}
            apolloError={materialsError}
            isInactive={getValues('material1')?.isActive === false}
            onChange={updateDisplayProductOnMainMaterial}
          />
          {getValues('material1') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnMainMaterial}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
        <div>
          <SelectControl
            name={'color'}
            control={control}
            label={t('product.materials-colors.color')}
            options={allColors}
            loadingOptions={colorsLoading || loading}
            apolloError={colorsError}
            isInactive={getValues('color')?.isActive === false}
            onChange={updateDisplayProductOnColor}
            addHTML={(option) => {
              const color = option as ColorOption;
              return <ColorDisplay hexCode={color.hex_code} label={color.label} />;
            }}
            SingleValue={SingleValue}
          />
          {getValues('color') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnColor}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
        <div>
          <SelectControl
            control={control}
            name={'material2'}
            label={t('product.materials-colors.secondary-material')}
            options={allMaterials}
            loadingOptions={materialsLoading || loading}
            apolloError={materialsError}
            isInactive={getValues('material2')?.isActive === false}
            onChange={updateDisplayProductOnSecondaryMaterial}
          />
          {getValues('material2') && (
            <div className="mt-2 flex justify-end">
              <span
                className="cursor-pointer text-right text-sm font-semibold text-blue-500 hover:text-blue-600"
                onClick={updateDisplayProductOnSecondaryMaterial}>
                {t('product.materials-colors.show-products')}
              </span>
            </div>
          )}
        </div>
      </div>

      {mutationError && <ApolloErrorAlert error={mutationError} />}

      <div className="flex flex-col justify-end">
        <div className="mt-6 flex justify-end">
          <Button
            type="submit"
            loading={mutationLoading}
            disabled={
              !isDirty ||
              mutationLoading ||
              getValues('material1')?.isActive === false ||
              getValues('material2')?.isActive === false
            }>
            {t('global.actions.save')}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default MaterialAndColorEdit;
