import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useProductContextType } from 'components/Contexts/ProductContext';
import { ArrowDownOnSquareStackIcon } from '@heroicons/react/24/outline';
import { Button } from 'components/Common/Button';
import Export from './Export/Index';
import Import from './Import/Index';
import Tags from './Tags';
import Title from 'components/Common/Title';
import ImportSlide from './ImportSlide';

const Index = () => {
  const { t } = useTranslation();

  const { productExists } = useProductContextType();

  const [hasVariant, setHasVariant] = useState<boolean>(false);
  const [importSlide, setImportSlide] = useState<boolean>(false);

  if (!productExists) return <></>;

  return (
    <>
      <ImportSlide setOpen={setImportSlide} open={importSlide} setHasVariant={setHasVariant} />
      <Title
        title={t('product.packaging.title')}
        addOnEnd={
          hasVariant && (
            <Button theme="secondary" onClick={() => setImportSlide(true)}>
              <>
                <ArrowDownOnSquareStackIcon className="mr-3 h-6 w-6" />
                <span className="leading-6">{t('global.import')}</span>
              </>
            </Button>
          )
        }
      />
      <div className="space-y-6">
        <Import />
        <Export />
        <Tags />
      </div>
    </>
  );
};

export default Index;
