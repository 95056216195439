import { ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { UseAuthToken } from './AuthToken';
import { ApolloProvider, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

export default function CustomApolloProvider(props) {
  // eslint-disable-next-line no-unused-vars
  const [authToken, _, removeAuthToken] = UseAuthToken();

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: authToken ? `Bearer ${authToken}` : '',
      },
    };
  });

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          case 'UNAUTHENTICATED':
            removeAuthToken();
            break;
        }
      }
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  });

  const uploadLink = createUploadLink({
    // eslint-disable-next-line no-undef
    uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  });
  const client = new ApolloClient({
    link: ApolloLink.from([authLink, errorLink, uploadLink]),
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={client} {...props} />;
}
