import { gql } from 'graphql.macro';

export const updateConsultingMaintenanceMutation = gql`
  mutation UpdateConsultingMaintenance($id: ID!, $maintenance_description: String) {
    updateProductTranslation(id: $id, maintenance_description: $maintenance_description) {
      id
      maintenance_description
    }
  }
`;
