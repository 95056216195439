import React from 'react';
import { ID } from 'types/Global';
import { ProductCustomsNomenclature } from 'types/Product';
import clsx from 'clsx';

type Props = {
  hierarchy: ProductCustomsNomenclature[];
  elements: ProductCustomsNomenclature[];
  currentId: ID | null;
};

const List = ({ hierarchy, elements, currentId }: Props) => {
  const findInHierarchy = (hierarchy: ProductCustomsNomenclature[], id: ID) => {
    return hierarchy.find((value) => value.id === id);
  };

  return (
    <ul className="grid grid-cols-1 gap-1 pl-6">
      {elements.map((element) => {
        const found = findInHierarchy(hierarchy, element.id);
        const isCurrentCode = parseInt(element.id as string) === parseInt(currentId as string);
        return (
          <div key={element.id} className="space-y-1">
            <li
              className={clsx(
                found || isCurrentCode ? 'text-dark' : 'text-secondary',
                isCurrentCode ? '-ml-2 bg-light pl-2' : '',
                'w-full truncate'
              )}>
              {`${element.code} - ${element.description}`}
            </li>
            {found && (
              <List hierarchy={hierarchy} elements={found.children} currentId={currentId} />
            )}
          </div>
        );
      })}
    </ul>
  );
};

export default List;
