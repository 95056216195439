import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { addNoticeCommentMutation } from 'graphql/Mutations/Product/Documents/Notices/addNoticeComment';
import { noticesQuery } from 'graphql/Queries/Product/Documents/Notices/notices';
import { useForm } from 'react-hook-form';
import { Document } from 'types/Product';
import useNotification from 'hooks/useNotification';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  document: Document | null;
};

const AddCommentForm = ({ open, setOpen, document }: Props) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();
  const { handleSubmit, register, reset: resetForm } = useForm({});

  useEffect(() => {
    if (!document) return;
    resetForm({
      comment: document.comment,
    });
  }, [document, resetForm]);

  const [addComment, { loading, error, reset }] = useMutation(addNoticeCommentMutation);

  const close = () => {
    resetForm();
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    addComment({
      variables: {
        id: document?.id,
        comment: data.comment,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: noticesQuery,
          variables: {
            product_id: params.productId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.documents.notices.add-notice'),
          type: 'success',
        });
        reset();
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.documents.notices.comments')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={close}
      loading={loading}
      error={error}>
      <>
        <div className="text-sm text-dark">
          <Trans i18nKey="product.documents.notices.add-or-update-comment" />
        </div>
        <label htmlFor="email" className=" mt-8 block text-sm font-medium leading-6 text-dark">
          {t('product.documents.notices.comment')}
        </label>
        <textarea
          rows={10}
          className="mb-3 mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 disabled:bg-gray-100 sm:text-sm"
          // defaultValue={document?.comment}
          {...register('comment')}
        />
      </>
    </SlideOver>
  );
};
export default AddCommentForm;
