import Collections from './Collections';
import Header from './Header';
import Models from './Models';
import Styles from './Styles';

const Index = () => {
  return (
    <>
      <Header></Header>

      <div className="space-y-6 p-4">
        <Models />
        <Collections />
        <Styles />
      </div>
    </>
  );
};

export default Index;
