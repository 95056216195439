import { gql } from 'graphql.macro';

export const secondaryMaterialProductsQuery = gql`
  query secondaryMaterialProducts($id: ID!) {
    material(id: $id) {
      lastProducts2 {
        id
        primaryImage {
          clientUrl
        }
        currentTranslation {
          internal_title
        }
      }
    }
  }
`;
