import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { ExportPackagingQuery } from 'graphql/Queries/Product/Packaging/exportPackaging';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import CreatePackageSize from './CreatePackageSize';
import PackageSize from './PackageSize';
import Card from 'components/Common/Card';
import { Button } from 'components/Common/Button';
import { PlusIcon } from '@heroicons/react/24/outline';
import { ProductPackageSize } from 'types/Product';
import UpdatePackageSize from './UpdatePackageSize';
import DeletePackageSizeModal from './DeletePackageSizeModal';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import Tippy from '@tippyjs/react';

const Index = () => {
  const params = useParams();
  const { t } = useTranslation();
  const [openCreatePackageSize, setOpenCreatePackageSize] = useState(false);
  const [openUpdatePackageSize, setOpenUpdatePackageSize] = useState(false);
  const [openDeletePackageSize, setOpenDeletePackageSize] = useState(false);
  const [packageSize, setPackageSize] = useState<ProductPackageSize | null>(null);

  const { loading, data, error } = useQuery(ExportPackagingQuery, {
    variables: { id: params.productId },
  });

  if (error) return <ApolloErrorAlert error={error} />;

  const product = data?.product;

  const stats = [
    {
      id: 1,
      name: t('product.packaging.export.total-weight'),
      stat: product?.weight >= 1000 ? `${product?.weight / 1000} kg` : `${product?.weight} g`,
    },
    {
      id: 2,
      name: t('product.packaging.export.total-volume'),
      stat: `${product?.volume ?? 0} m³`,
    },
    {
      id: 3,
      name: t('product.packaging.export.package-number'),
      stat: product?.packageSizes.length,
    },
  ];

  return (
    <>
      <CreatePackageSize open={openCreatePackageSize} setOpen={setOpenCreatePackageSize} />
      <UpdatePackageSize
        open={openUpdatePackageSize}
        setOpen={setOpenUpdatePackageSize}
        product={product}
        packageSizeId={packageSize?.id}
      />
      <DeletePackageSizeModal
        open={openDeletePackageSize}
        setOpen={setOpenDeletePackageSize}
        product={product}
        packageSize={packageSize}
      />
      <Card title={t('product.packaging.export.title')}>
        <>
          <div className="flex flex-col-reverse gap-5 2xl:flex-row 2xl:justify-between">
            <div className="grid w-full grid-cols-3 gap-6">
              {stats.map((item) => (
                <div key={item.id} className="">
                  <dt>
                    <p className="truncate text-sm font-medium text-gray-500">{item.name}</p>
                  </dt>
                  <dd className="flex items-baseline">
                    {loading ? (
                      <div className="skeleton mt-2 h-6 w-full"></div>
                    ) : (
                      <p className="text-2xl font-semibold text-primary-500">{item.stat}</p>
                    )}
                  </dd>
                </div>
              ))}
            </div>
            <div className="flex w-full items-center justify-end gap-4">
              <Button onClick={() => setOpenCreatePackageSize(true)}>
                <div className="flex items-center gap-2">
                  <PlusIcon className="w-6" />
                  <span>{t('product.packaging.add')}</span>
                </div>
              </Button>
            </div>
          </div>

          {!data?.product?.packageSizes?.length && !loading ? (
            <div className="mt-6">
              <InformationAlert>{t('product.packaging.no-package')}</InformationAlert>
            </div>
          ) : (
            <div className="-mx-4 mt-8 sm:-mx-0">
              <table className="min-w-full divide-y divide-gray-300 text-sm">
                <thead className="text-left font-semibold uppercase text-secondary">
                  <tr>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.packaging.export.package')}
                    </th>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.packaging.export.name')}
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 xl:table-cell">
                      <div className="flex gap-2">
                        <span>{t('product.packaging.export.dimensions')}</span>
                        <Tippy content={t('product.packaging.export.dimensions-infos')}>
                          <InformationCircleIcon className="h-5 w-5 text-gray-300 hover:text-gray-600" />
                        </Tippy>
                      </div>
                    </th>
                    <th scope="col" className="px-3 py-3.5  ">
                      {t('product.packaging.export.volume')}
                    </th>
                    <th scope="col" className="px-3 py-3.5">
                      {t('product.packaging.export.weight')}
                    </th>
                    <th scope="col" className="hidden px-3 py-3.5 2xl:table-cell">
                      {t('product.packaging.export.pallet')}
                    </th>
                    <th scope="col" className="hidden truncate px-3 py-3.5 2xl:table-cell">
                      {t('product.packaging.export.need-repack')}
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 text-left font-medium text-dark">
                  {data?.product?.packageSizes.map((packageSize: ProductPackageSize, i: number) => {
                    return (
                      <tr key={i}>
                        <PackageSize
                          number={i + 1}
                          product={data.product}
                          packageSize={packageSize}
                          setPackageSize={setPackageSize}
                          setOpenUpdatePackageSize={setOpenUpdatePackageSize}
                          setOpenDeletePackageSize={setOpenDeletePackageSize}
                        />
                      </tr>
                    );
                  })}
                  {loading && (
                    <tr>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="px-3 py-3.5">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                      <td className="hidden px-3 py-3.5 2xl:table-cell">
                        <div className="skeleton h-6" />
                      </td>
                      <td />
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </>
      </Card>
    </>
  );
};

export default Index;
