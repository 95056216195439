import { gql } from 'graphql.macro';

export const createProductionSiteMutation = gql`
  mutation CreateProductionSite(
    $supplierId: Int!
    $countryId: Int!
    $name: String
    $street: String
    $complementaryAddress: String
    $city: String
    $zipcode: String
  ) {
    createSupplierProductionSite(
      supplier_id: $supplierId
      name: $name
      street: $street
      complementary_address: $complementaryAddress
      city: $city
      zipcode: $zipcode
      country_id: $countryId
    ) {
      id
    }
  }
`;
