import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Control, FieldValues, UseFormGetValues, UseFormWatch } from 'react-hook-form';
import { useQuery } from '@apollo/client';
import { InputTextControl } from 'components/Common/InputTextControl';
import CheckboxControl from 'components/Common/CheckboxControl';
import { SelectControl } from 'components/Common/SelectControl';
import { OptionProps } from 'types/Global';
import { PalletType } from 'types/Product';
import { palletTypesQuery } from 'graphql/Queries/Product/Packaging/palletTypes';

type Props = {
  control: Control;
  getValues: UseFormGetValues<FieldValues>;
  watch?: UseFormWatch<FieldValues>;
};

const FormContent = ({ control, getValues, watch }: Props) => {
  const { t } = useTranslation();

  const [volume, setVolume] = useState(0);
  const [palletTypes, setPalletTypes] = useState<OptionProps[]>([]);

  const { data, loading, error } = useQuery(palletTypesQuery);

  useEffect(() => {
    if (!data) return;

    setPalletTypes(
      data.palletTypes?.map((palletType: PalletType) => {
        return {
          value: palletType.legacy_code,
          label: palletType.label,
        };
      })
    );
  }, [data, setPalletTypes]);

  const calculateVolume = useCallback(() => {
    if (!getValues('depth') || !getValues('height') || !getValues('width')) {
      setVolume(0);
      return;
    }
    const newVolume = (
      (getValues('depth') / 100) *
      (getValues('height') / 100) *
      (getValues('width') / 100)
    ).toFixed(3);
    setVolume(parseFloat(newVolume));
  }, [setVolume, getValues]);

  useEffect(() => {
    if (!watch) return;
    // Appeler calculateVolume une fois lors du premier affichage
    calculateVolume();

    // S'abonner aux changements dans les valeurs des champs du formulaire
    const subscription = watch(() => {
      calculateVolume();
    });

    // Nettoyer l'abonnement lorsque le composant est démonté ou lorsque les valeurs changent
    return () => subscription.unsubscribe();
  }, [calculateVolume, getValues, watch]); // Ajoutez getValues comme dépendance

  return (
    <div className="space-y-6">
      <InputTextControl control={control} name="name" label={t('product.packaging.export.name')} />
      <div className="grid grid-cols-6 gap-6">
        <div className="col-span-2">
          <InputTextControl
            control={control}
            name="depth"
            label={t('product.packaging.export.depth')}
            addOnEnd={'cm'}
            onKeyUp={calculateVolume}
          />
        </div>
        <div className="col-span-2">
          <InputTextControl
            control={control}
            name="width"
            label={t('product.packaging.export.width')}
            addOnEnd={'cm'}
            onKeyUp={calculateVolume}
          />
        </div>
        <div className="col-span-2">
          <InputTextControl
            control={control}
            name="height"
            label={t('product.packaging.export.height')}
            addOnEnd={'cm'}
            onKeyUp={calculateVolume}
          />
        </div>
        <div className="col-span-6">
          <div className="block text-sm font-medium text-dark">
            {t('product.packaging.export.volume')}
          </div>
          <span className="mt-1 inline-flex items-center rounded-md bg-gray-100 px-2.5 py-0.5 text-sm font-medium text-dark">
            {`${volume} m³`}
          </span>
        </div>
      </div>
      <InputTextControl
        control={control}
        name="weight"
        label={t('product.packaging.export.weight')}
        addOnEnd={'g'}
      />
      <SelectControl
        name="category"
        control={control}
        label={t('product.packaging.export.category')}
        options={palletTypes}
        loadingOptions={loading}
        apolloError={error}
      />
      <InputTextControl
        control={control}
        name="packageCount"
        label={t('product.packaging.export.quantity')}
      />
      <CheckboxControl
        name="isNeedRepack"
        control={control}
        label={t('product.packaging.export.need-repack')}
      />
    </div>
  );
};

export default FormContent;
