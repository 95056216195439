import { useState } from 'react';

const useCheckUrlImage = (url?: string) => {
  const [isValidImage, setIsValidImage] = useState(false);
  if (!url) return false;

  const img = new Image();
  img.src = url;
  img.onload = () => {
    setIsValidImage(true);
  };
  img.onerror = () => {
    setIsValidImage(false);
  };

  return isValidImage;
};
export default useCheckUrlImage;
