import { gql } from 'graphql.macro';

export const availableLanguagesQuery = gql`
  query AvailableLanguages($id: ID!) {
    product(id: $id) {
      id
      availableLanguagesExceptFrench {
        id
        name
        iso
      }
    }
  }
`;
