import numeral from 'numeral';

const usePriceFormatter = () => {
  const formatPrice = (value: number) => {
    const formattedValue = numeral(value).format('0.0[0]');

    if (/\.0$/.test(formattedValue)) {
      return formattedValue.slice(0, -2);
    }

    return formattedValue;
  };

  return { formatPrice };
};

export default usePriceFormatter;
