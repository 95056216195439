import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { countriesQuery } from 'graphql/Queries/countries';
import { useQuery } from '@apollo/client';
import { Country } from 'types/Global';
import { SelectControl } from 'components/Common/SelectControl';
import SlideOver from 'components/Common/SlideOver';

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  setIsActive: (isActive: boolean | null) => void;
  setCountryId: (supplierId: number) => void;
  isActive: boolean | null;
};

const SuppliersFilter = ({ open, isActive, setOpen, setIsActive, setCountryId }: Props) => {
  const { t } = useTranslation();
  const { loading, error, data } = useQuery(countriesQuery);

  const { handleSubmit, reset, control } = useForm();

  const options = data?.countries?.map((country: Country) => {
    return {
      value: country.id,
      label: country.name,
    };
  });

  const filters = [
    {
      name: t('suppliers.status'),
      content: (
        <fieldset className="">
          <div className="space-y-4">
            <div className="flex items-center">
              <Controller
                control={control}
                name={'active'}
                render={({ field }) => (
                  <input
                    {...field}
                    name="notification-is-active"
                    type="radio"
                    value=""
                    className="h-4 w-4 border-gray-300 text-primary-500 focus:ring-primary-500"
                    defaultChecked={isActive === null}
                  />
                )}
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                {t('suppliers.all-status')}
              </label>
            </div>
            <div className="flex items-center">
              <Controller
                control={control}
                name={'active'}
                render={({ field }) => (
                  <input
                    {...field}
                    name="notification-is-active"
                    type="radio"
                    value={1}
                    className="h-4 w-4 border-gray-300 text-primary-500 focus:ring-primary-500"
                    defaultChecked={isActive === true}
                  />
                )}
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                {t('global.active')}
              </label>
            </div>
            <div className="flex items-center">
              <Controller
                control={control}
                name={'active'}
                render={({ field }) => (
                  <input
                    {...field}
                    name="notification-is-active"
                    type="radio"
                    value={0}
                    className="h-4 w-4 border-gray-300 text-primary-500 focus:ring-primary-500"
                    defaultChecked={isActive === false}
                  />
                )}
              />
              <label className="ml-3 block text-sm font-medium leading-6 text-gray-900">
                {t('global.inactive')}
              </label>
            </div>
          </div>
        </fieldset>
      ),
    },
    {
      name: t('suppliers.country'),
      content: (
        <>
          <SelectControl
            control={control}
            name="country"
            options={options}
            loadingOptions={loading}
            apolloError={error}
          />
        </>
      ),
    },
  ];

  const clearInput = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = handleSubmit((data) => {
    if (data.active === '') {
      setIsActive(null);
    }

    if (data.active) {
      const isActive = data.active === '1' ? true : false;
      setIsActive(isActive);
    }

    if (data?.country?.value) {
      setCountryId(parseInt(data.country.value, 10));
    }

    setOpen(false);
  });

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('suppliers.slide-over.title')}
      buttonText={t('global.apply')}
      onSubmit={onSubmit}
      onCancel={clearInput}
      loading={loading}>
      <div className="mt-4 border-t">
        {filters.map((filter) => (
          <Disclosure key={filter.name} as="div" className="border-b border-gray-200 px-4 py-6">
            {({ open }) => (
              <>
                <h3 className="-mx-2 -my-3 flow-root">
                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-gray-400 hover:text-gray-500">
                    <span className="font-medium text-dark">{filter.name}</span>
                    <span className="ml-6 flex items-center">
                      {open ? (
                        <MinusIcon className="h-5 w-5" aria-hidden="true" />
                      ) : (
                        <PlusIcon className="h-5 w-5" aria-hidden="true" />
                      )}
                    </span>
                  </Disclosure.Button>
                </h3>
                <Disclosure.Panel className="pt-6">{filter.content}</Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </SlideOver>
  );
};
export default SuppliersFilter;
