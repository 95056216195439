import React, { useEffect } from 'react';
import { Button } from 'components/Common/Button';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { availableLanguagesQuery } from 'graphql/Queries/Product/Editorial/availableLanguages';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { ApolloErrorAlert, InformationAlert } from 'components/Common/Alerts';
import { ID, Language } from 'types/Global';
import { sendTranslationRequestsMutation } from 'graphql/Mutations/Product/Editorial/sendTranslationRequests';
import useNotification from 'hooks/useNotification';
import { areEquals } from 'utils/array';
import { LanguageIcon } from '@heroicons/react/24/outline';
import SlideOver from 'components/Common/SlideOver';
import { useForm } from 'react-hook-form';
import Checkbox from 'components/Common/Checkbox';

interface TranslationRequestProps {
  disabled: boolean;
}

const TranslationRequest = ({ disabled }: TranslationRequestProps) => {
  const { t } = useTranslation();
  const params = useParams();
  const { setNotification } = useNotification();

  const [open, setOpen] = useState(false);
  const [languages, setLanguages] = useState<ID[]>([]);
  const [modificationIds, setModificationIds] = useState<number[]>([1, 2, 3, 4]);
  const [availableLanguages, setAvailableLanguages] = useState<Language[]>([]);

  const { data, loading, error } = useQuery(availableLanguagesQuery, {
    fetchPolicy: 'network-only',
    variables: { id: params.productId },
    skip: !open,
  });

  const [
    sendTranslationRequests,
    { loading: loadingMutation, error: errorMutation, reset: resetMutation },
  ] = useMutation(sendTranslationRequestsMutation);

  useEffect(() => {
    setOpen(false);
  }, [disabled]);

  useEffect(() => {
    if (!data) return;
    const languageIds: ID[] = [];
    data?.product?.availableLanguagesExceptFrench?.map((language: Language) => {
      languageIds.push(language.id);
    });
    setLanguages(languageIds);
    setAvailableLanguages(data.product?.availableLanguagesExceptFrench ?? []);
  }, [data, setLanguages]);

  const onClickLanguageCheckbox = (e: React.ChangeEvent<HTMLInputElement>, languageId: ID) => {
    if (e.target.checked) {
      if (!languages.includes(languageId)) {
        setLanguages([...languages, languageId]);
      }
    } else {
      if (languages.includes(languageId)) {
        const newLanguages = languages.filter((l) => l !== languageId);
        setLanguages(newLanguages);
      }
    }
  };

  const onClickModificationCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    modificationId: number
  ) => {
    if (e.target.checked) {
      if (!modificationIds.includes(modificationId)) {
        setModificationIds([...modificationIds, modificationId]);
      }
    } else {
      if (modificationIds.includes(modificationId)) {
        const newModificationIds = modificationIds.filter((m) => m !== modificationId);
        setModificationIds(newModificationIds);
      }
    }
  };

  const { handleSubmit } = useForm();

  const onSubmit = handleSubmit(() => {
    let allModifications = false;
    if (areEquals(modificationIds, [1, 2, 3, 4])) {
      allModifications = true;
    }

    sendTranslationRequests({
      variables: {
        product_id: params.productId,
        languages,
        modificationIds: allModifications ? [0] : modificationIds,
      },
      onCompleted: () => {
        setNotification({
          type: 'success',
          title: t('global.success'),
          message: t('product.editorial.translation-request.success'),
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    setOpen(false);
    resetMutation();
  };

  return (
    <>
      <div className="relative space-y-6">
        <Button type="button" onClick={() => setOpen((open) => !open)} disabled={disabled}>
          <div className="flex items-center space-x-3">
            <LanguageIcon className="w-6 text-white" />
            <span>{t('product.editorial.translation-request.action')}</span>
          </div>
        </Button>
        <SlideOver
          title={t('product.editorial.translation-request.title')}
          open={open}
          setOpen={setOpen}
          buttonText={t('product.editorial.translation-request.send')}
          onSubmit={onSubmit}
          onCancel={onCancel}
          loading={loadingMutation}
          isDirty={languages.length > 0 && modificationIds.length > 0 && !loading}
          error={errorMutation}>
          <div className="flex flex-col justify-between">
            <div className="space-y-6">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-dark">
                  {t('product.editorial.translation-request.languages')}
                </legend>
                {error ? (
                  <div className="mt-4">
                    <ApolloErrorAlert error={error} />
                  </div>
                ) : !loading && !availableLanguages.length && !error ? (
                  <InformationAlert>
                    {t('product.editorial.translation-request.no-language')}
                  </InformationAlert>
                ) : (
                  <div className="mt-4 divide-y divide-gray-200 border-b border-t border-gray-200">
                    {loading
                      ? [...Array(3)].map((_, i: number) => (
                          <div key={i} className="py-4">
                            <div className="skeleton h-8" />
                          </div>
                        ))
                      : availableLanguages.map((language: Language, i: number) => (
                          <div key={i} className="py-4">
                            <Checkbox
                              label={language.name}
                              iso={language.iso}
                              onChange={(e) => onClickLanguageCheckbox(e, language.id)}
                              defaultChecked
                            />
                          </div>
                        ))}
                  </div>
                )}
              </fieldset>
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-dark">
                  {t('product.editorial.translation-request.infos')}
                </legend>
                <div className="mt-4 space-y-3">
                  <Checkbox
                    label={t('product.editorial.title-edit.title')}
                    onChange={(e) => onClickModificationCheckbox(e, 1)}
                    defaultChecked
                  />
                  <Checkbox
                    label={t('product.editorial.sales-description.title')}
                    onChange={(e) => onClickModificationCheckbox(e, 2)}
                    defaultChecked
                  />
                  <Checkbox
                    label={t('product.editorial.maintenance-description.title')}
                    onChange={(e) => onClickModificationCheckbox(e, 3)}
                    defaultChecked
                  />
                  <Checkbox
                    label={t('product.editorial.designer-word.title')}
                    onChange={(e) => onClickModificationCheckbox(e, 4)}
                    defaultChecked
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </SlideOver>
      </div>
    </>
  );
};

export default TranslationRequest;
