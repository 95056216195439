import React from 'react';
import SidebarContent from './SidebarContent';

interface SidebarProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleMouseEnter: () => void;
}

export default function Sidebar({ open, setOpen, handleMouseEnter }: SidebarProps) {
  return (
    <>
      <div
        className={`animate-easing-emphasized-decelerate -ml-72 hidden w-72 bg-primary-500 transition-all duration-700 xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:flex-col ${
          open && 'sm:ml-0'
        }`}>
        <SidebarContent open={open} setOpen={setOpen} handleMouseEnter={handleMouseEnter} />
      </div>
    </>
  );
}
