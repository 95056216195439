import { gql } from 'graphql.macro';

export const designersQuery = gql`
  query Designers {
    designers {
      id
      name
      photoClientUrl
      is_active
      isUsed
      created_at
      updated_at
    }
  }
`;
