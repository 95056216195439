import { gql } from 'graphql.macro';

export const allActiveBrandQuery = gql`
  query allActiveBrand {
    allActiveBrand {
      id
      label
      logoFullPath
    }
  }
`;
