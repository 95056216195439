import React from 'react';
import { getCurrencySymbol } from 'global';
import { CurrentSupplying } from 'types/Supplier';
import moment from 'moment';
import Tippy from '@tippyjs/react';

type Props = {
  productSupplierHistory: CurrentSupplying[];
};

function Infos({ productSupplierHistory }: Props) {
  return (
    <>
      {productSupplierHistory?.map((history: CurrentSupplying) => {
        return (
          <tr key={history?.id} className="text-sm text-dark">
            <td className="overflow-hidden whitespace-nowrap px-2 py-4 font-semibold">
              <div className="line-clamp-1">
                {history?.user?.firstname} {history?.user?.lastname}
              </div>
            </td>
            <td className="hidden overflow-hidden whitespace-nowrap px-2 2xl:table-cell">
              <div className="line-clamp-1 w-fit">
                {history?.supplier ? (
                  <Tippy content={history?.supplier?.name}>
                    <div className="w-36 truncate">{history?.supplier?.name}</div>
                  </Tippy>
                ) : (
                  '-'
                )}
              </div>
            </td>

            <td className="hidden overflow-hidden whitespace-nowrap px-2 2xl:table-cell">
              <div className="w-fit">
                <Tippy content={history?.supplier_product_id}>
                  <div className="w-24 truncate">
                    {history?.supplier_product_id ? history?.supplier_product_id : '-'}
                  </div>
                </Tippy>
              </div>
            </td>

            <td className="overflow-hidden whitespace-nowrap px-2">
              <span>
                {history?.price
                  ? history.price + ' ' + (getCurrencySymbol(history.currency?.code) ?? '-')
                  : '-'}
              </span>
            </td>
            <td className="overflow-hidden whitespace-nowrap px-2">
              <div className="w-fit">
                <Tippy content={history?.comment}>
                  <div className="w-20 truncate md:w-80">
                    {history?.comment ? history?.comment : '-'}
                  </div>
                </Tippy>
              </div>
            </td>
            <td className="hidden overflow-hidden whitespace-nowrap text-right 2xl:table-cell">
              {moment(history?.created_at).format('Do MMMM YYYY HH:mm')}
            </td>
          </tr>
        );
      })}
    </>
  );
}

export default Infos;
