import { gql } from 'graphql.macro';

export const deleteProductDatasheetMutation = gql`
  mutation DeleteProductDatasheet($id: ID!) {
    deleteProductDatasheet(id: $id) {
      id
      name
    }
  }
`;
