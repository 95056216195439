import { ProductCategory, ProductUniverse } from 'types/Product';

type UseTitleTranslationProps = {
  selectedUniverse: ProductUniverse | null;
  selectedCategory: ProductCategory | null;
  t: (key: string) => string;
};

const useTitleTranslation = ({
  selectedUniverse,
  selectedCategory,
  t,
}: UseTitleTranslationProps): string => {
  const getTitleTranslation = (): string => {
    if (!selectedUniverse) {
      return t('settings.taxonomy.universe.title');
    } else if (selectedUniverse && !selectedCategory) {
      return t('settings.taxonomy.category.title');
    } else {
      return t('settings.taxonomy.type.title');
    }
  };

  return getTitleTranslation();
};

export default useTitleTranslation;
