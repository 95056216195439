import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

type Props = {
  title: string;
};

function DummySelect({ title }: Props) {
  const { t } = useTranslation();

  return (
    <>
      <div className="mt-3 sm:col-span-3">
        <label
          htmlFor="productType"
          className="justify-between text-sm font-medium text-blue-gray-900">
          {title}
          <span className="text-red-700">*</span>
        </label>
        <div className="mt-1">
          <Select
            placeholder={t('global.form.select')}
            className="block w-full min-w-0 flex-1 
              rounded-none rounded-r-md border-gray-300 focus:border-blue-500 
              focus:ring-blue-500 sm:text-sm"
            isDisabled={true}
          />
        </div>
      </div>
    </>
  );
}

export default DummySelect;
