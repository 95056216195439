import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import useNotification from 'hooks/useNotification';
import { createPackageSizeMutation } from 'graphql/Mutations/Product/createPackageSize';
import { ExportPackagingQuery } from 'graphql/Queries/Product/Packaging/exportPackaging';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import SlideOver from 'components/Common/SlideOver';
import FormContent from './FormContent';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const CreatePackageSize = ({ open, setOpen }: Props) => {
  const params = useParams();
  const { t } = useTranslation();
  const { setNotification } = useNotification();

  const [createProductPackageSize, { loading, error }] = useMutation(createPackageSizeMutation);

  const schema = yup.object().shape({
    name: yup.string().required(t('form.required')),
    depth: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    height: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    width: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    weight: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    packageCount: yup
      .number()
      .integer(t('form.number.integer'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    isNeedRepack: yup.boolean().required(),
  });

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      name: '',
      depth: '',
      height: '',
      width: '',
      weight: '',
      packageCount: '',
      isNeedRepack: false,
      category: null,
    },
  });

  const onSubmit = handleSubmit((data) => {
    createProductPackageSize({
      variables: {
        id: params.productId,
        packageSize: data,
      },
      refetchQueries: [
        {
          query: ExportPackagingQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: {
            id: params.productId,
          },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.export.success'),
          type: 'success',
        });
        setOpen(false);
        reset();
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    setOpen(false);
    reset();
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.packaging.export.add-title')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDirty={isDirty}
      loading={loading}
      error={error}>
      <FormContent control={control} getValues={getValues} />
    </SlideOver>
  );
};

export default CreatePackageSize;
