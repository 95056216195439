import { gql } from 'graphql.macro';

export const productPackagingQuery = gql`
  query productPackaging($id: ID!) {
    product(id: $id) {
      id
      packageSizes {
        number
        height
        width
        depth
        weight
        volume
      }
    }
  }
`;
