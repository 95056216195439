import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ApolloError } from '@apollo/client';
import { TRANSPARENT_ERRORS } from 'global';
import { NotificationProps } from 'components/Common/Notifications';

interface NotificationProviderProps {
  children: JSX.Element | JSX.Element[];
}

interface NotificationContextProps {
  notifications: NotificationProps[];
  setNotification: (notification: NotificationProps) => void;
  setError: (error: ApolloError) => void;
  removeNotification: (id: number) => void;
}

const NotificationContext = createContext<NotificationContextProps>({
  notifications: [],
  setNotification: () => {
    throw new Error();
  },
  setError: () => {
    throw new Error();
  },
  removeNotification: () => {
    throw new Error();
  },
});

const NotificationProvider = ({ children }: NotificationProviderProps) => {
  const { t } = useTranslation();
  const [notifications, setNotifications] = useState<NotificationProps[]>([]);

  const setNotification = ({ title, message, type }: NotificationProps) => {
    setNotifications((notifications: NotificationProps[]) => [
      ...notifications,
      {
        title,
        message,
        type,
        id: notifications.length !== 0 ? (notifications.at(-1)?.id ?? 0) + 1 : 0,
      },
    ]);
  };

  const setError = (error: ApolloError) => {
    let message = '';
    const errorCategory = String(error.graphQLErrors[0]?.extensions?.category);
    if (TRANSPARENT_ERRORS.includes(errorCategory)) {
      if (errorCategory === 'validation') {
        const extensionsValidation = JSON.parse(String(error.graphQLErrors[0].extensions));
        Object.keys(extensionsValidation).forEach((element) => {
          extensionsValidation[element].forEach((information: string) => {
            message += information;
          });
        });
      } else {
        message = error.graphQLErrors[0].message;
      }
    } else {
      message = t('global.error-msg');
      if (errorCategory === 'authentication') {
        message = t('global.error-auth');
      }
    }
    setNotifications((notifications) => [
      ...notifications,
      {
        title: t('global.error'),
        message: message,
        type: 'failure',
        id: notifications.length !== 0 ? (notifications.at(-1)?.id ?? 0) + 1 : 0,
      },
    ]);
  };

  useEffect(() => {
    const toRemoveIds = notifications.map((elem) => {
      if (elem.type === 'success') {
        return elem.id;
      }
    });
    if (toRemoveIds.length > 0) {
      setTimeout(() => removeManyNotifications(toRemoveIds), 6000);
    }
  }, [notifications]);

  const removeManyNotifications = (id: (number | undefined)[]) => {
    setNotifications((notifications) => notifications.filter((elem) => !id.includes(elem.id)));
  };

  const removeNotification = (id: number) => {
    setNotifications((notifications) => notifications.filter((elem) => elem.id !== id));
  };

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        setNotification,
        setError,
        removeNotification,
      }}>
      {children}
    </NotificationContext.Provider>
  );
};

NotificationProvider.propTypes = {
  children: PropTypes.any,
};

export { NotificationProvider, NotificationContext };
