import { gql } from 'graphql.macro';

export const productHistoryQuery = gql`
  query ProductHistory($id: ID!) {
    product(id: $id) {
      id
      productHistory {
        id
        value
        field
        created_at
        user {
          id
          firstname
          lastname
        }
      }
    }
  }
`;
