import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import useNotification from 'hooks/useNotification';
import { updatePackageSizeMutation } from 'graphql/Mutations/Product/updatePackageSize';
import { productPackagingQuery } from 'graphql/Queries/Product/Packaging/productPackaging';
import SlideOver from 'components/Common/SlideOver';
import { Product } from 'types/Product';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FieldValues, useForm } from 'react-hook-form';
import { productHeaderQuery } from 'graphql/Queries/Product/Header/productHeader';
import FormContent from './FormContent';
import { DEFAULT_PACKAGE_NAME } from 'global';
import { ID } from 'types/Global';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  product?: Product;
  packageSizeId?: ID;
};

const UpdatePackageSize = ({ open, setOpen, product, packageSizeId }: Props) => {
  const params = useParams();
  const { t } = useTranslation();
  const { setNotification } = useNotification();

  const [updateProductPackageSize, { loading, error, reset: resetMutation }] =
    useMutation(updatePackageSizeMutation);

  const schema = yup.object().shape({
    name: yup.string().required(t('form.required')),
    depth: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    height: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    width: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    weight: yup
      .number()
      .min(1, t('form.required'))
      .required(t('form.required'))
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    packageCount: yup
      .number()
      .integer(t('form.number.integer'))
      .nullable()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .typeError(t('form.type-error.number')),
    isNeedRepack: yup.boolean().required(),
  });

  const {
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm<FieldValues>({
    resolver: yupResolver<FieldValues>(schema),
    defaultValues: {
      name: '',
      depth: '',
      height: '',
      width: '',
      weight: '',
      packageCount: '',
      isNeedRepack: false,
      category: null,
    },
  });

  useEffect(() => {
    if (!packageSizeId || !product) return;

    const packageSize = product.packageSizes.find(
      (packageSize) => packageSize.id === packageSizeId
    );
    if (!packageSize) return;

    let name;
    if (product?.isSinglePackage) {
      name = DEFAULT_PACKAGE_NAME;
    } else {
      name = packageSize.package?.name;
    }

    let weight;
    if (product?.isSinglePackage) {
      weight = product?.weight;
    } else {
      weight = packageSize.package?.weight;
    }
    const category = packageSize.type?.legacy_code
      ? {
          value: packageSize.type.legacy_code,
          label: packageSize.type.label,
        }
      : null;

    reset({
      name: name ?? '',
      depth: packageSize.depth ?? '',
      height: packageSize.height ?? '',
      width: packageSize.width ?? '',
      weight: weight ?? '',
      packageCount: packageSize.quantity_by_pallet ?? '',
      isNeedRepack: packageSize.to_repack,
      category,
    });
  }, [product, packageSizeId, reset]);

  const onSubmit = handleSubmit((data) => {
    if (!packageSizeId) return;
    updateProductPackageSize({
      variables: {
        id: params.productId,
        packageSize: {
          id: packageSizeId,
          ...data,
        },
      },
      refetchQueries: [
        {
          query: productPackagingQuery,
          variables: { id: params.productId },
        },
        {
          query: productHeaderQuery,
          variables: { id: params.productId },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setOpen(false);
        setNotification({
          title: t('global.success'),
          message: t('product.packaging.export.success'),
          type: 'success',
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  const onCancel = () => {
    setOpen(false);
    reset();
    resetMutation();
  };

  return (
    <SlideOver
      open={open}
      setOpen={setOpen}
      title={t('product.packaging.export.update-title')}
      buttonText={t('global.actions.save')}
      onSubmit={onSubmit}
      onCancel={onCancel}
      isDirty={isDirty}
      loading={loading}
      error={error}>
      <FormContent control={control} getValues={getValues} watch={watch} />
    </SlideOver>
  );
};

export default UpdatePackageSize;
