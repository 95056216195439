import { gql } from 'graphql.macro';

export const addDatasheetCommentMutation = gql`
  mutation addDatasheetComment($id: ID!, $comment: String!) {
    addDatasheetComment(id: $id, comment: $comment) {
      id
      name
      comment
    }
  }
`;
