import { gql } from 'graphql.macro';

export const updateProductBrandMadeInMutation = gql`
  mutation updateProductBrandMadeIn($id: ID!, $made_in_id: ID!, $brand_id: ID!) {
    updateProductBrandMadeIn(id: $id, made_in_id: $made_in_id, brand_id: $brand_id) {
      id
      branding {
        id
        label
        url
      }
      madeIn {
        id
        label
      }
    }
  }
`;
