import React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation, Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { allSupplierContactsQuery } from 'graphql/Queries/Supplier/Contacts/allSupplierContacts';
import { deleteSupplierContactMutation } from 'graphql/Mutations/Supplier/Contacts/deleteSupplierContact';
import { TrashIcon } from '@heroicons/react/24/outline';
import { SupplierContact } from 'types/Supplier';
import useNotification from 'hooks/useNotification';
import Modal from 'components/Common/Modal';

type Props = {
  contact: SupplierContact | null;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const DeleteContact = ({ open, setOpen, contact }: Props) => {
  const { setNotification } = useNotification();
  const { t } = useTranslation();
  const params = useParams();

  const [deleteContact, { loading, error }] = useMutation(deleteSupplierContactMutation);

  const { handleSubmit } = useForm();

  const onSubmit = handleSubmit(() => {
    if (!contact) return;

    deleteContact({
      variables: {
        id: contact.id,
      },
      awaitRefetchQueries: true,
      refetchQueries: [
        {
          query: allSupplierContactsQuery,
          variables: {
            supplier_id: params.supplierId,
          },
        },
      ],
      onCompleted: () => {
        setNotification({
          title: t('global.success'),
          message: t('supplier.contacts.delete-success'),
          type: 'success',
        });
        setOpen(false);
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onError: () => {},
    });
  });

  return (
    <Modal
      theme="danger"
      title={t('supplier.contacts.delete-contact')}
      buttonText={t('global.actions.delete')}
      open={open}
      loading={loading}
      error={error}
      onClose={() => setOpen(false)}
      icon={<TrashIcon className="h-6 w-6 text-red-600" aria-hidden="true" />}
      onSubmit={onSubmit}>
      <div className="mt-6 space-y-6">
        <Trans
          i18nKey="supplier.contacts.delete-confirmation"
          values={{
            name: contact?.name,
          }}
        />
      </div>
    </Modal>
  );
};

export default DeleteContact;
