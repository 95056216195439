import React from 'react';
import { useQuery } from '@apollo/client';
import { estimatedEcoMobilierAmountQuery } from 'graphql/Queries/Ecotax/EstimatedEcoMobilierAmount';

type Props = {
  code: string;
  changeFutureEcoMobilierAmount: (amount: number) => void;
};

const EcomobilierAmount: React.FC<Props> = ({ code, changeFutureEcoMobilierAmount }: Props) => {
  useQuery(estimatedEcoMobilierAmountQuery, {
    variables: { code: code },
    onCompleted: (data) => {
      changeFutureEcoMobilierAmount(data.estimatedEcoMobilierAmount.including_taxes_value);
    },
  });

  return <React.Fragment />;
};

export default EcomobilierAmount;
