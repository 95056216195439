import { gql } from 'graphql.macro';

export const createModelMutation = gql`
  mutation CreateModel($name: String!) {
    createProductModel(name: $name) {
      id
      name
      active
      isUsed
    }
  }
`;
